import { Routes } from '@angular/router';
import { UserPageListComponent } from '@console/user-managements';
import { RegisterComponent } from '@console/user-managements/sections/register/register.component';
import { UserProfileComponent } from '@console/user-managements/user-profile/user-profile.component';
import { AnalyticsComponent } from 'projects/analytics/src/lib/analytics.component';
import { AccountManagerComponent } from 'projects/view/src/lib/account-manager/account-manager.component';
import { AccountManagerUsersComponent } from 'projects/view/src/lib/account-manager/components/account-manager-users/account-manager-users.component';
import { AuditLogComponent } from 'projects/view/src/lib/audit-log/audit-log.component';
import { ChakaAccountsComponent } from 'projects/view/src/lib/chaka-accounts/chaka-accounts.component';
import { BlogEditComponent } from 'projects/view/src/lib/content-management/blog/blog-edit/blog-edit.component';
import { BlogComponent } from 'projects/view/src/lib/content-management/blog/blog.component';
import { CollectionsEditComponent } from 'projects/view/src/lib/content-management/collections/collections-edit/collections-edit.component';
import { NewsEditComponent } from 'projects/view/src/lib/content-management/news/news-edit/news-edit.component';
import { CscsReportComponent } from 'projects/view/src/lib/cscs-report/cscs-report.component';
import { DwAccountComponent } from 'projects/view/src/lib/dw-account/dw-account.component';
import { DwHistoryEditComponent } from 'projects/view/src/lib/dw-history/dw-history-edit/dw-history-edit.component';
import { DwHistoryListComponent } from 'projects/view/src/lib/dw-history/dw-history-list/dw-history-list.component';
import { ExchangesEditComponent } from 'projects/view/src/lib/exchanges/exchanges-edit/exchanges-edit.component';
import { MandateDetailsComponent } from 'projects/view/src/lib/mandate/components/mandate-details/mandate-details.component';
import { MandateListComponent } from 'projects/view/src/lib/mandate/mandate-list.component';
import { OrdersEditComponent } from 'projects/view/src/lib/orders/orders-edit/orders-edit.component';
import { PaymentComponent } from 'projects/view/src/lib/payment/payment-list/payment.component';
import { PaymentsEditComponent } from 'projects/view/src/lib/payment/payments-edit/payments-edit.component';
import { ReferralSettingsComponent } from 'projects/view/src/lib/referral-settings/referral-settings.component';
import { RolesComponent } from 'projects/view/src/lib/roles/roles.component';
import { AppSettingEditComponent } from 'projects/view/src/lib/settings/application-setting/app-setting-edit/app-setting-edit.component';
import { ApplicationSettingComponent } from 'projects/view/src/lib/settings/application-setting/application-setting.component';
import { BankEditComponent } from 'projects/view/src/lib/settings/banks/bank-edit/bank-edit.component';
import { BanksComponent } from 'projects/view/src/lib/settings/banks/banks.component';
import { ChannelEditComponent } from 'projects/view/src/lib/settings/channels/channel-edit/channel-edit.component';
import { ChannelsComponent } from 'projects/view/src/lib/settings/channels/channels.component';
import { MobileAppsComponent } from 'projects/view/src/lib/settings/mobile-apps/mobile-apps.component';
import { EditRejectionReasonComponent } from 'projects/view/src/lib/settings/rejection-email-settings/components/edit-rejection-reason/edit-rejection-reason.component';
import { RejectionEmailSettingsComponent } from 'projects/view/src/lib/settings/rejection-email-settings/rejection-email-settings.component';
import { SupportedDocumentsComponent } from 'projects/view/src/lib/supported-documents/supported-documents.component';
import { TransactionsEditComponent } from 'projects/view/src/lib/transactions/transaction-edit/transactions-edit.component';
import { AuthUsersComponent } from 'projects/view/src/lib/user-management/kyc/components/auth-users/auth-users.component';
import { KycComponent } from 'projects/view/src/lib/user-management/kyc/kyc.component';
import { WithdrawalEditComponent } from 'projects/view/src/lib/withdrawals/withdrawals-edit/withdrawals-edit.component';
import { WithdrawalsComponent } from 'projects/view/src/lib/withdrawals/withdrawals.component';
import { CollectionsComponent } from '../../../projects/view/src/lib/content-management/collections/collections.component';
import { LearnComponent } from '../../../projects/view/src/lib/content-management/learn/learn.component';
import { NewsComponent } from '../../../projects/view/src/lib/content-management/news/news.component';
import { ExchangeRateEditComponent } from '../../../projects/view/src/lib/exchange-rates/exchange-rates-edit/exchange-rates-edit.component';
import { ExchangeRatesComponent } from '../../../projects/view/src/lib/exchange-rates/exchange-rates.component';
import { ExchangesComponent } from '../../../projects/view/src/lib/exchanges/exchanges.component';
import { InstrumentEditComponent } from '../../../projects/view/src/lib/instrument/instrument-edit/instrument-edit.component';
import { InstrumentComponent } from '../../../projects/view/src/lib/instrument/instrument.component';
import { MerchantDetailsComponent } from '../../../projects/view/src/lib/merchants/merchant-details/merchant-details.component';
import { MerchantListComponent } from '../../../projects/view/src/lib/merchants/merchant-list/merchant-list.component';
import { OrdersComponent } from '../../../projects/view/src/lib/orders/orders-list/orders.component';
import { ReconciliationsComponent } from '../../../projects/view/src/lib/reconciliations/reconciliations.component';
import { RolesEditorComponent } from '../../../projects/view/src/lib/roles/role-edit/roles-edit.component';
import { TransactionsComponent } from '../../../projects/view/src/lib/transactions/transactions-list/transactions.component';

export const equityRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: AnalyticsComponent,
  },
  {
    path: 'users',
    component: UserPageListComponent,
  },
  {
    path: 'payments',
    component: PaymentComponent,
  },
  {
    path: 'payments/:id',
    component: PaymentsEditComponent,
  },
  {
    path: 'kyc',
    component: KycComponent,
  },
  {
    path: 'client-list',
    component: AuthUsersComponent,
  },
  {
    path: 'cscs-report',
    component: CscsReportComponent,
  },
  {
    path: 'instruments',
    component: InstrumentComponent,
  },
  {
    path: 'instruments/symbol/:symbol',
    component: InstrumentEditComponent,
  },
  {
    path: 'new-users',
    component: RegisterComponent,
  },
  {
    path: 'users/:id',
    component: UserProfileComponent,
  },
  {
    path: 'withdrawals',
    component: WithdrawalsComponent,
  },
  {
    path: 'withdrawals/:id',
    component: WithdrawalEditComponent,
  },
  {
    path: 'reconcillations',
    component: ReconciliationsComponent,
  },
  {
    path: 'transaction',
    component: TransactionsComponent,
  },
  {
    path: 'transaction/:id',
    component: TransactionsEditComponent,
  },
  {
    path: 'dw-history',
    component: DwHistoryListComponent,
  },
  {
    path: 'dw-history/:id',
    component: DwHistoryEditComponent,
  },
  {
    path: 'exchanges',
    component: ExchangesComponent,
  },
  {
    path: 'exchanges/:id',
    component: ExchangesEditComponent,
  },
  {
    path: 'orders',
    component: OrdersComponent,
  },
  {
    path: 'orders/:id',
    component: OrdersEditComponent,
  },
  {
    path: 'collection',
    component: CollectionsComponent,
  },
  {
    path: 'collection/:id',
    component: CollectionsEditComponent,
  },
  {
    path: 'learn',
    component: LearnComponent,
  },
  {
    path: 'news',
    component: NewsComponent,
  },
  {
    path: 'news/:id',
    component: NewsEditComponent,
  },
  {
    path: 'blog',
    component: BlogComponent,
  },
  {
    path: 'blog/:id',
    component: BlogEditComponent,
  },
  {
    path: 'rates',
    component: ExchangeRatesComponent,
  },
  {
    path: 'rates/:id',
    component: ExchangeRateEditComponent,
  },
  {
    path: 'merchant',
    component: MerchantListComponent,
    children: [
      { path: 'create', component: MerchantDetailsComponent },
      { path: ':id', component: MerchantDetailsComponent },
    ],
  },

  {
    path: 'roles',
    component: RolesComponent,
  },
  {
    path: 'roles/create',
    component: RolesEditorComponent,
  },
  {
    path: 'roles/:id',
    component: RolesEditorComponent,
  },
  {
    path: 'settings',
    component: ApplicationSettingComponent,
    children: [
      { path: 'create', component: AppSettingEditComponent },
      { path: ':id', component: AppSettingEditComponent },
    ],
  },
  {
    path: 'global-account',
    component: DwAccountComponent,
  },

  {
    path: 'channels',
    component: ChannelsComponent,
    children: [
      { path: 'create', component: ChannelEditComponent },
      { path: ':id', component: ChannelEditComponent },
    ],
  },

  {
    path: 'rejection-email-config',
    component: RejectionEmailSettingsComponent,
    children: [
      { path: 'create',component: EditRejectionReasonComponent },
      { path: ':id',component: EditRejectionReasonComponent}
    ]
  },

  {
    path: 'banks',
    component: BanksComponent,
    children: [
      { path: 'create', component: BankEditComponent },
      { path: ':id', component: BankEditComponent },
    ],
  },

  {
    path: 'mobile-versions',
    component: MobileAppsComponent,
  },
  {
    path: 'mandate',
    component: MandateListComponent,
  },
  {
    path: 'mandate/:id',
    component: MandateDetailsComponent,
  },
  {
    path: 'chaka-accounts',
    component: ChakaAccountsComponent,
  },
  {
    path: 'referral-settings',
    component: ReferralSettingsComponent,
  },
  {
    path: 'account-manager',
    component: AccountManagerComponent,
  },
  {
    path: 'supported-documents',
    component: SupportedDocumentsComponent,
  },
  {
    path: 'account-manager/:id',
    component: AccountManagerUsersComponent,
  },
  {
    path: 'audit-log',
    component: AuditLogComponent,
  },
];
