
import {
  ChangeDetectionStrategy,
  Component,
  OnInit
} from '@angular/core';
import { CollectionQuery } from './services/content.interface';
import { ConsoleRouterService } from '../../../../../../projects/shared/src/public-api';
import { CollectionListStateService } from './collections.state';
import { PageEvent } from '@console/shared/interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InstrumentListStateService } from '../../instrument/instrument.state';
import { InstrumentSymbolsStateService } from '../../instrument/instrument-symbols.state';
import { CollectionEditorStateService } from './collections-edit/collection-edit.state';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { formMethods } from '@console/shared/utils/form-control-methods';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionsComponent implements OnInit {
  childRoute = false;

  filter: Partial<CollectionQuery> = {
    pageSize: 12,
  };

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'symbol',
    textField: 'name',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  imgURL: any;

  public collectionForm: FormGroup;
  public formMethods;

  imageFile: File = null;

  constructor(
    public collectionList: CollectionListStateService,
    private console: ConsoleRouterService,
    public instruments: InstrumentListStateService,
    public symbols: InstrumentSymbolsStateService,
    public collection: CollectionEditorStateService,

  ) {}

  preview(event): void {
    this.collectionForm.get('image').setErrors({ invalidImage: null });
    this.collectionForm.get('image').updateValueAndValidity();

    this.imgURL = '';
    this.imageFile = null;

    if (event.length === 0) {
      return;
    }

    if (event[0].type.split('/')[0] !== 'image') {
      this.collectionForm.get('image').setErrors({ invalidImage: true });
      return;
    }

    this.imageFile = (event as HTMLInputElement)[0];

    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }


  search(query: string): void {
    this.filter.q = query;
    this.collectionList.find(this.filter);
    if (this.childRoute) {
      this.console.productNavigate(['content', 'collections']);
    }
  }

  paginate($event: PageEvent): void {
    this.collectionList.find({
      ...this.filter,
      ...$event
    });
  }

  parentNavigate(): void {
    this.childRoute = false;
    this.collectionList.find(this.filter);
  }

  navigate(collectionId?: string): void {
    if (collectionId) {
      this.console.productNavigate(['collection', collectionId]);
      return;
    }
    // this.console.productNavigate(['equity', 'collection']);

    this.childRoute = true;
  }

  ngOnInit(): void {
    this.collectionList.find(this.filter);
    this.instruments.find({});

    this.createForm();
  }

  private createForm() {
    this.collectionForm = new FormGroup({
      title: new FormControl('', Validators.required),
      instruments: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      image: new FormControl('', {
        validators: Validators.required
      })
    });

    this.formMethods = formMethods(this.collectionForm)
  }

  onInstrumentAdded() {
    const value = this.collectionForm
      .get('instruments')?.value || '';
    this.pickedInstruments(value);
  }

  pickedInstruments(symbols: string) {
    if (typeof symbols === 'string' && symbols !== '') {
      this.symbols.fetchSymbols(symbols, { pageSize: 300 });
      return;
    }
    this.symbols.reset();
  }

  create() {
    this.childRoute = true;
  }

  view() {
    this.childRoute = false;
    this.collectionList.find(this.filter);
  }


  save(): void {
    if (!this.collectionForm.valid) {
      this.collectionForm.markAllAsTouched();
      return;
    }


    if (!this.imageFile) {
      this.collectionForm.get('image').setErrors({ invalidImage: true });
      return;
    }

    const dataToSubmit = {
      ...this.collectionForm.value,
      image: this.imageFile
    };

    this.collection.create(dataToSubmit, () => {
      this.view();
    });

    // setTimeout(() => {
    //   this.view();
    // }, 3000);
  }

  onItemSelect(item: any) {
  }

  ngOnDestroy() {
    this.collectionList.reset();
  }

}
