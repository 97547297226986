<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <ngx-spinner
      [name]="order.spinnerName"
      bdColor="#4e73df66"
      size="medium"
      color="#fff"
      type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">
        {{ (order.state | async)?.message }}
      </p>
    </ngx-spinner>
    <div class="card-header bg-light py-3">
      <ng-container *ngIf="!(order.state | async).loading">
        <a
          *ngIf="order.canBeQueried$ | async"
          class="btn btn-primary"
          style="color: white"
          href="javascript:;"
          matTooltip="Re-Query Order"
          (click)="requery()">
          <i class="fa fa-undo" aria-hidden="true"></i>
          <span class="kt-hidden-mobile">Re-Query</span>
        </a>
        <a
          *ngIf="order.canBeCancel$ | async"
          class="btn btn-danger kt-margin-r-10 ml-2"
          style="color: white"
          href="javascript:;"
          matTooltip="Cancel Order"
          (click)="cancel()">
          <i class="las la-redo"></i>
          <span class="kt-hidden-mobile">Cancel</span>
        </a>
      </ng-container>



      <a
        *ngIf="!orderId"
        style="color: white"
        (click)="goBack()"
        class="btn btn-primary float-right"
        matTooltip="Back to the users list">
        <i class="la la-arrow-left"></i>
        <span style="color: white" class="kt-hidden-mobile">Back</span>
      </a>

    </div>

    <div class="container container-fluid mt-4">
      <div class="kt-form">
        <div *ngIf="(order.state | async)?.order" class="row">
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>User</h6>
            <h5 (click)="goToUserProfile()">
              <!-- <a class="pretty-link">{{ (order.state | async)?.order?.modifiedBy || "-" }}</a> -->
              {{ (order.state | async)?.order?.modifiedBy || "-" }}
            </h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Symbol</h6>
            <h5>{{ (order.state | async)?.order?.symbol || "-" }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Total Amount</h6>
            <h5>
              {{
                formatToCurrency(
                  (order.state | async)?.order?.currency,
                  (order.state | async)?.order?.totalAmount
                )
              }}
            </h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Chaka Fees</h6>
            <h5>
              {{
                formatToCurrency(
                  (order.state | async)?.order?.currency,
                  (order.state | async)?.order?.chakaFees
                )
              }}
            </h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Order Price</h6>
            <h5>
              {{
                (order.state | async)?.order?.amountCash
?
                formatToCurrency(
                  (order.state | async)?.order?.currency,
                  (order.state | async)?.order?.amountCash
                )
                :
                '-'
              }}
            </h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Quantity</h6>
            <h5>{{ (order.state | async)?.order?.quantity || "-" }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Order No</h6>
            <h5>{{ (order.state | async)?.order?.orderNo || "-" }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Reference</h6>
            <h5>{{ (order.state | async)?.order?.reference || "-" }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Order Type</h6>
            <h5>{{ (order.state | async)?.order?.orderType || "-" }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Order Side</h6>
            <h5>{{ (order.state | async)?.order?.orderSide || "-" }}</h5>
          </div>
          <!-- <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Status Data</h6>
            <h5>{{ (order.state | async)?.order?.statusData || "-" }}</h5>
          </div> -->
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Date</h6>
            <h5>
              {{ (order.state | async)?.order?.dateCreated | date: "medium" }}
            </h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Status</h6>
            <h5
              class="kyc-status"
              [ngClass]="getStatusColor((order.state | async)?.order?.status)">
              {{ (order.state | async)?.order?.status || "-" }}
            </h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Comment</h6>
            <h5>
              {{ (order.state | async)?.order?.statusData }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
