import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ORDER_STATUS } from '@console/shared';
import { OpenOrdersModel } from '@console/user-managements/models/open-orders.interface';
import {
  CancelOpenOrderEvent, RequeryOpenOrderEvent
} from './open-orders.interface';

@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss'],
})
export class OpenOrdersComponent implements OnInit {
  @Input() order: OpenOrdersModel;

  @Output() private cancelReq = new EventEmitter<string>();
  @Output() private requeryReq = new EventEmitter<string>();

  get status(): { color: string; data: ORDER_STATUS } {
    const { CANCELED, FILLED, NEW, REJECTED } = ORDER_STATUS;
    const data = this.order.orderStatus as ORDER_STATUS;
    switch (data) {
      case NEW:
        return { color: 'accent', data };
      case FILLED:
        return { color: 'success', data };
      case CANCELED:
      case REJECTED:
        return { color: 'danger', data };
      default:
        return { color: 'warning', data };
    }
  }

  get canRequery(): boolean {
    const { CANCELED, FILLED, REJECTED } = ORDER_STATUS;
    const status = this.order.orderStatus as ORDER_STATUS;
    return ![CANCELED, FILLED, REJECTED].includes(status);
  }

  cancel() {
    this.cancelReq.emit(this.order.orderID);
  }

  requery() {
    this.requeryReq.emit(this.order.orderID);
  }

  ngOnInit() {}
}
