<!--begin: Search Form-->
<div class="mb-7">
  <div class="row align-items-center">
    <div class="col-lg-12">
      <form [formGroup]="searchForm">
        <div class="row align-items-center">

          <div
            class="col-md-3"
            *ngIf="isSuperAdmin">
            <div class="form-group">
              <label>Merchant</label>
              <ng-select
                formControlName="merchantId"
                [clearable]="false"
                [virtualScroll]="true"
                [loading]="this.merchants?.loading">
                <ng-option value="">All</ng-option>
                <ng-option
                  [value]="merchant?.id"
                  *ngFor="let merchant of merchants?.list">
                  {{ merchant?.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>From</label>
              <input class="form-control" type="date" formControlName="from">
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>To</label>
              <input class="form-control" type="date" formControlName="to">
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label class="d-block">&nbsp;</label>
              <button
                type="button"
                class="btn btn-light-primary font-weight-bold"
                (click)="handleQuery()">Search</button>
            </div>
          </div>

        </div>
      </form>

    </div>
  </div>
</div>
<!--end::Search Form-->

<!--begin: Datatable-->

<div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

  <div class="mt-5 mb-5">
    <div class="d-flex align-items-center">
      <div class="font-weight-bold">
        Selected {{selectedItems.size}} {{selectedItems.size>1 ?'record': 'records'}}:
        <button
          [disabled]="selectedItems.size <= 0"
          (click)="downloadFile()"
          class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
          <i class="far fa-arrow-alt-circle-down"></i> Download
        </button>
      </div>
    </div>
  </div>


  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="check" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single checkbox-all">
              <input
                (change)="onAllChecked($event.target.checked)"
                type="checkbox"
                [checked]="allSelected">&nbsp;<span></span>
            </label>
          </span>
        </th>

        <td
          mat-cell
          *matCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single">
              <input
                type="checkbox"
                (change)="onItemChecked($event.target.checked, row)"
                [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
            </label>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="finTranID">
        <th mat-header-cell *matHeaderCellDef> Tran ID </th>
        <td mat-cell *matCellDef="let row">
          {{row?.finTranID || "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="accountAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.accountAmount ? formatToCurrency("USD", row?.accountAmount) : "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="accountBalance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Balance </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.accountBalance ? formatToCurrency("USD", row?.accountBalance) : "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="comment">
        <th mat-header-cell *matHeaderCellDef> Comment </th>
        <td mat-cell *matCellDef="let row">
          {{row?.comment || "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="tranWhen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let row">
          {{row?.tranWhen | date: 'medium' || "-"}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
        *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>

  <mat-paginator
    class="mat-paginator-sticky"
    [length]="count"
    [pageSizeOptions]="[10, 50, 100, 150, 200]"
    (page)="pageEvent=$event; paginate($event)"
    showFirstLastButtons></mat-paginator>
</div>

<div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
  <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
  <p class="no-data-text">No data</p>
</div>


<!--end: Datatable-->
