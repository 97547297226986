<h2 mat-dialog-title>{{ title }}</h2>
<form [formGroup]="accountForm" (ngSubmit)="save()">
  <mat-dialog-content>
    <div class="form-group mb-8">
      <lib-messages></lib-messages>
    </div>

    <div class="form-group">
      <label>Bank Name <span class="text-danger">*</span></label>
      <ng-select formControlName="bankName" (ngModelChange)="onBankChange()">
        <ng-option [value]="bank.name" *ngFor="let bank of bankList">{{
          bank.name
        }}</ng-option>
      </ng-select>
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('bankName')"
      >
        <div *ngIf="formMethods.getControl('bankName').hasError('required')">
          Required input.
        </div>
      </small>
    </div>

    <div class="form-group">
      <label>Account Number <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        formControlName="accountNumber"
        accountNameValidator
        [bankId]="bankId"
      />
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('accountNumber')"
      >
        <div
          *ngIf="formMethods.getControl('accountNumber').hasError('required')"
        >
          Required input.
        </div>
        <div
          *ngIf="formMethods.getControl('accountNumber').hasError('pattern')"
        >
          Account number must be 10 digits.
        </div>
        <div
          *ngIf="
            formMethods.getControl('accountNumber').hasError('invalidAccount')
          "
        >
          {{ accountForm.get("accountNumber").errors?.invalidAccount }}
        </div>
      </small>

      <small
        class="mt-2 d-block"
        *ngIf="accountForm.get('accountNumber')['fetchingAccount']"
      >
        <i class="fas fa-spinner fa-pulse fa-sm mr-1"></i> Fetching account
        details, please wait...
      </small>

      <small *ngIf="validAccountName">
        Account Name: <strong>{{ validAccountName }}</strong>
      </small>
    </div>

    <div class="form-group">
      <label>Currency <span class="text-danger">*</span></label>
      <ng-select formControlName="currency">
        <ng-option [value]="currency" *ngFor="let currency of currencyList">{{
          currency
        }}</ng-option>
      </ng-select>
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('currency')"
      >
        <div *ngIf="formMethods.getControl('currency').hasError('required')">
          Required input.
        </div>
      </small>
    </div>

    <div class="form-group" *ngIf="currencyControl.value === currencyEnum.USD">
      <label>Swift Code <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        formControlName="swiftCode"
        (ngModelChange)="onCurrencyChange($event)"
      />
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('swiftCode')"
      >
        <div *ngIf="formMethods.getControl('swiftCode').hasError('required')">
          Required input.
        </div>
      </small>
    </div>

    <div class="form-group row">
      <label class="col-2 col-form-label">Enabled?</label>
      <div class="col-3">
        <span class="switch">
          <label>
            <input type="checkbox" formControlName="isEnabled" />
            <span></span>
          </label>
        </span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    mat-dialog-actions
    class="d-flex align-items-center justify-content-between"
  >
    <button type="button" class="btn btn-secondary" (click)="close()">
      Close
    </button>
    <button type="submit" class="btn btn-primary">Save</button>
  </mat-dialog-actions>
</form>
