import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { AdvisoryPortfolioService } from '../services/advisory-portfolio.service';

import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList,
} from '../../../../../api/src/public-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IAdvisoryPortfolio } from '../interfaces/IAdvisoryPortfolio';

export interface IAdvisoryPortfolioListState {
  loading: boolean;
  count?: number;
  portfolios: IAdvisoryPortfolio[];
  error?: string;
}

const initialState: IAdvisoryPortfolioListState = {
  loading: false,
  portfolios: [],
};

@Injectable()
export class AdvisoryPortfolioListStateService {
  private subject$ = new BehaviorSubject<IAdvisoryPortfolioListState>(
    initialState
  );

  state$ = this.subject$.asObservable();

  private messageId: number;

  constructor(
    private advisoryPortfolioService: AdvisoryPortfolioService,
    private messagesService: MessagesService
  ) {}

  get loading$(): Observable<boolean> {
    return this.subject$.pipe(map((state) => state.loading));
  }

  find(query: object): void {
    this.loading();

    this.showMessage();

    this.advisoryPortfolioService
      .fetchPortfolios(query)
      .pipe(
        take(1),
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        })
      )
      .subscribe({
        next: this.updateAdvisoryPortfolios.bind(this),
      });
  }

  private updateAdvisoryPortfolios(
    response: PaginatedList<IAdvisoryPortfolio>
  ): void {
    this.subject$.next({
      loading: false,
      portfolios: response?.data,
      count: response?.count,
    });

    if (response?.data?.length > 0) {
      this.messagesService.update(
        {
          id: this.messageId,
          type: MessagesEnum.success,
          message: `Successfully retrieved ${
            response.data.length === 1 ? 'portfolio' : 'portfolios'
          }`,
        },
        { timeOut: 3000 }
      );
    } else {
      this.messagesService.hide(this.messageId);
    }
  }

  private handleError(res: ChakaAPIError): void {
    const errorMessage = cleanChakaAPIError(res) || 'An Error occurred';

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false,
    });

    this.messagesService.update({
      id: this.messageId,
      type: MessagesEnum.danger,
      message: errorMessage,
    });
  }

  reset(): void {
    this.subject$.next(initialState);
  }

  private showMessage(): void {
    !!this.messageId && this.messagesService.hide(this.messageId);
    const { id } = this.messagesService.open(
      MessagesEnum.loading,
      'Fetching portfolios...'
    );
    this.messageId = id;
  }

  private loading(): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true,
    });
  }
}
