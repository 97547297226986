import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APICONFIG } from './api.constants';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseAuthService {
  constructor(
    private config: APICONFIG,
    private http: HttpClient,
    private baseHTTP: BaseApiService
  ) {}

  post<T>(
    endpoint: string,
    body: Record<string, any> = {},
    queryParams: Record<string, any> = {}
  ): Observable<T> {
    const url = this.baseHTTP.cleanUrl(this.config.authURL, endpoint);
    return this.http.post<T>(url, body, {
      headers: this.baseHTTP.createAuthorizationHeader(),
      params: {
        ...queryParams,
        productType: this.baseHTTP.getProductType(),
      },
    });
  }

  put<T>(
    endpoint: string,
    body: Record<string, any> = {},
    params: Record<string, any> = {}
  ): Observable<T> {
    const url = this.baseHTTP.cleanUrl(this.config.authURL, endpoint);
    return this.http.put<T>(url, body, {
      headers: this.baseHTTP.createAuthorizationHeader(),
      params: { ...params, productType: this.baseHTTP.getProductType() },
    });
  }

  patch<T>(
    endpoint: string,
    data: Record<string, any> = {},
    params: Record<string, any> = {}
  ): Observable<T> {
    const url = this.baseHTTP.cleanUrl(this.config.authURL, endpoint);
    return this.http.patch<T>(url, data, {
      headers: this.baseHTTP.createAuthorizationHeader(),
      params: { ...params, productType: this.baseHTTP.getProductType() },
    });
  }


  get<T>(endpoint: string, params: Record<string, any> = {}): Observable<T> {
    const url = this.baseHTTP.cleanUrl(this.config.authURL, endpoint);

    console.log('url that got here', url);

    return this.http.get<T>(url, {
      headers: this.baseHTTP.createAuthorizationHeader(),
      params: {
        ...params,
        productType: this.baseHTTP.getProductType(),
      },
    });
  }

  delete<T>(
    endpoint: string,
    body: Record<string, any> = {},
    params: Record<string, any> = {}
  ): Observable<T> {
    const url = this.baseHTTP.cleanUrl(this.config.authURL, endpoint);
    return this.http.delete<T>(url, {
      headers: this.baseHTTP.createAuthorizationHeader(),
      params: { ...params, productType: this.baseHTTP.getProductType() },
      body: body || {},
    } as any) as any;
  }
}
