import { Injectable } from '@angular/core';
import { AuthService } from '@console/authentication/providers/authentication.service';
import { NotificationService } from '@console/shared/services/notification-service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  APIResponse, ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList
} from '../../../../../api/src/lib/api.interface';
import { KYCData, KYCListQueryParam } from './kyc.interface';
import { UsersKycService } from './kyc.service';


export interface KYCListState {
  data: KYCData[];
  count: number;
  loading: boolean;
  error?: string;
  deleted?: boolean;
}

export interface ApprovalResult {
  approved: number;
  failed: number;
}

@Injectable({ providedIn: 'root' })
export class KYCListStateService {
  state = new BehaviorSubject<KYCListState>({
    loading: false,
    count: 0,
    data: [],
  });

  lastQuery: Record<string, any> = {};

  constructor(
    private userSvc: UsersKycService,
    private auth: AuthService,
    private notificationService: NotificationService) { }

  loadKYCList(query: Partial<KYCListQueryParam>): void {
    this.lastQuery = query;
    this.state.next({ ...this.state.getValue(), loading: true });
    this.userSvc.KycLists(query).subscribe({
      next: this.onKYCListLoaded.bind(this),
      error: this.onKYCListError.bind(this),
    });
  }

  loadAuthServer(query: Partial<KYCListQueryParam>): void {
    this.lastQuery = query;
    this.state.next({ ...this.state.getValue(), loading: true });
    this.userSvc.authUsers(query).subscribe({
      next: this.onKYCListLoaded.bind(this),
      error: this.onKYCListError.bind(this),
    });
  }

  approve(kycs: KYCData[]): Observable<ApprovalResult> {
    this.state.next({ ...this.state.getValue(), loading: true });
    return combineLatest(kycs.map((k) => this.handleApproval(k)))
      .pipe(
        tap(() => this.state.next({ ...this.state.getValue(), loading: false }))
      )
      .pipe(
        map((res: APIResponse<string>[]) => this.collateApprovalResult(res))
      );
  }

  reset() {
    this.state.next({} as any);
  }

  private handleApproval(k: KYCData): Observable<APIResponse<string>> {
    return this.userSvc
      .updateKycStatus({
        kycCountry: k.country,
        kycStatus: k.status,
        userId: k.userId,
      })
      .pipe(catchError((error) => of(error)));
  }

  private collateApprovalResult(results: APIResponse<string>[]) {
    const result: ApprovalResult = {
      approved: 0,
      failed: 0,
    };
    for (let res of results) {
      if (((res as any) as ChakaAPIError).error) {
        result.failed += 1;
      } else {
        result.approved += 1;
      }
    }
    return result;
  }

  private onKYCListLoaded({ data, count }: PaginatedList<KYCData>): void {
    this.state.next({ data, count, loading: false });
  }

  private onKYCListError({ message }: { message: string }): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error: message,
    });
  }

  toggleAccountLock(userId: string, lockStatus: boolean, searchFilter): void {
    console.log(userId, lockStatus);

    this.loadAuthServer(searchFilter);
    this.auth.toggleAccountLock(userId, lockStatus)
      .subscribe({
        next: () => this.loadAuthServer(searchFilter),
        error: (err) => {
          const message = cleanChakaAPIError(err);
          this.notificationService.error(message);
        }
      });
  }
}
