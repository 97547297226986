import { DwAccountComponent } from 'projects/view/src/lib/dw-account/dw-account.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    DwAccountComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DwAccountComponent
  ]
})

export class DwAccountModule { }