import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ORDER_SIDE, ORDER_TYPE } from '@console/shared';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Currency } from '@console/shared/_enums/currency.enum';
import { formatToCurrency } from '@console/shared/utils/formatter';

interface OrderForm {
  orderType: ORDER_TYPE;
  price: number;
  quantity: number;
  currency: Currency;
}

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
})
export class OrderFormComponent implements OnInit {
  @Input() currency = Currency.USD;

  @Input() marketPrice = 0;
  @Input() owned = 0;

  @Input() private orderType = ORDER_TYPE.MARKET;
  @Input() public orderSide = ORDER_SIDE.BUY;

  @Output() preOrder = new EventEmitter();

  orderForm: FormGroup;

  orderSides = [
    { label: 'Buy', value: ORDER_SIDE.BUY },
    { label: 'Sell', value: ORDER_SIDE.SELL },
  ];

  orderTypes = [
    { label: 'Market', value: ORDER_TYPE.MARKET },
    { label: 'Limit', value: ORDER_TYPE.LIMIT },
    { label: 'Stop', value: ORDER_TYPE.STOP },
  ];

  constructor(private fb: FormBuilder) { }

  onSelectAll($event) {
    console.log($event);
    if ($event.target.checked === true) {
      const quantityField = this.orderForm.get('quantity');
      quantityField.setValue(this.owned);
    }
  }

  onOrderSideChange(_: Event) {
    const quantityField = this.orderForm.get('quantity');
    quantityField.setValue(0);

    const priceField = this.orderForm.get('price');
    priceField.setValue(0);
  }

  onOrderTypeChange($event: Event) {
    const target: HTMLSelectElement = $event.target as any;

    const quantityField = this.orderForm.get('quantity');
    quantityField.setValue(0);

    if (target.value === ORDER_TYPE.MARKET) {
      this.disablePriceInputForMarketOrder();
      return;
    }

    this.enablePriceInputForOtherOrderTypes();
  }

  createForm() {
    const { required } = Validators;
    return this.fb.group({
      price: [0, required],
      quantity: [0, required],
      orderSide: [this.orderSide, required],
      orderType: [this.orderType, required],
    });
  }

  submitForm($event: OrderForm) {

    $event.currency = this.currency;

    if ($event.orderType === ORDER_TYPE.MARKET) {
      delete $event.price;
      ($event as any).amountCash = $event.quantity * this.marketPrice;
    }

    this.preOrder.emit($event);
  }

  private disablePriceInputForMarketOrder() {
    const priceField = this.orderForm.get('price');
    priceField?.setValue(this.marketPrice);
    priceField.disable();
  }

  private enablePriceInputForOtherOrderTypes() {
    const priceField = this.orderForm.get('price');
    priceField.enable();
  }

  get marketPriceEquivalent() {
    const orderTypeField = this?.orderForm.get('orderType')?.value;

    if (orderTypeField !== ORDER_TYPE.MARKET) return null;

    const quantityValue = this.orderForm.get('quantity')?.value || 0;

    return formatToCurrency(this.currency, quantityValue * this.marketPrice);
  }

  get currencyDisplay() {
    return formatToCurrency(this.currency, 0)[0];
  }

  get isASellOrder() {
    return this?.orderForm.get('orderSide')?.value === ORDER_SIDE.SELL;
  }

  get isLocalBuyMarketOrder():boolean {
      return (this.orderForm.get('orderType')?.value === ORDER_TYPE.MARKET && 
      this.orderForm.get('orderSide')?.value === ORDER_SIDE.BUY  && 
      this.currency === Currency.NGN);
  }

  ngOnInit() {
    this.orderForm = this.createForm();
  }
}
