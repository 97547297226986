import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Collection } from '../../services/content.interface';

@Component({
  selector: 'kt-collections-card',
  templateUrl: './collections-card.component.html',
  styleUrls: ['./collections-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionsCardComponent implements OnInit {
  @Input() collection: Collection;

  @Input() status = 'success';

  @Output() btnClick = new EventEmitter<Collection>();


  constructor() { }

  ngOnInit() { }
}
