<div class="row">
  <div class="col-12">
    <form [formGroup]="investmentForm">
      <div class="form-row">
        <div class="col-lg-6 form-group">
          <label for="investmentObjective">Investment Objective</label>
          <ng-select   formControlName="investmentObjective">
            <ng-option   *ngFor="let objective of investmentObjectives"
            [value]="objective"
          >
            {{ objective }} </ng-option>
         </ng-select>

        </div>
        <div class="col-lg-6 form-group">
          <label for="investmentExperience"> Investment Experience </label>
          <ng-select   formControlName="investmentExperience">
            <ng-option   *ngFor="let exp of investmentExperiences" [value]="exp">
              {{ exp }}</ng-option>
         </ng-select>

        </div>
        <div class="col-lg-6 form-group">
          <label for="riskTolerance"> Risk Tolerance </label>
          <ng-select   formControlName="riskTolerance">
            <ng-option   *ngFor="let tolerance of riskTolerances"
            [value]="tolerance"
          >
            {{ tolerance }} </ng-option>
         </ng-select>

        </div>
        <div class="col-lg-6 form-group">
          <label for="annualIncome"> Annual Income </label>
          <ng-select   formControlName="annualIncome">
            <ng-option   *ngFor="let income of incomeBrackets"
            [value]="income.value"
          >
            {{ income.label }} </ng-option>
         </ng-select>

        </div>
        <div class="col-lg-6 form-group">
          <label for="networthTotal"> Networth Total </label>
          <ng-select   formControlName="networthTotal">
            <ng-option   *ngFor="let income of incomeBrackets"
            [value]="income.value"
          >
            {{ income.label }} </ng-option>
         </ng-select>

        </div>
        <div class="col-lg-6 form-group">
          <label for="networthLiquid"> Networth Liquid </label>
          <ng-select   formControlName="networthLiquid">
            <ng-option   *ngFor="let income of incomeBrackets"
            [value]="income.value"
          >
            {{ income.label }} </ng-option>
         </ng-select>

        </div>
        <div class="col-lg-6 form-group">
          <label for="portfolioType">Portfolio Type</label>
          <ng-select   formControlName="portfolioType">
            <ng-option   *ngFor="let portfolioType of portFolioTypes"
            [value]="portfolioType"
          >
            {{ portfolioType }} </ng-option>
         </ng-select>

        </div>
      </div>
    </form>
  </div>
</div>
