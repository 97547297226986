import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRODUCT_TYPE_ENUM } from '@console/shared/_enums';
import { LoginResponse } from './api.interface';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  static AUTH_CREDENTIALS = `auth-token-location`;
  static TOKEN_LOCATION = `auth-token-location`;

  constructor() {}

  token() {
    return localStorage.getItem(BaseApiService.TOKEN_LOCATION) || '';
  }

  updateToken(token: string) {
    localStorage.setItem(BaseApiService.TOKEN_LOCATION, token);
  }

  deleteToken() {
    localStorage.removeItem(BaseApiService.TOKEN_LOCATION);
  }

  get credentials(): LoginResponse {
    return JSON.parse(
      localStorage.getItem(BaseApiService.AUTH_CREDENTIALS) || '{}'
    );
  }

  updateLoginCredentials(login: LoginResponse) {
    localStorage.setItem(
      BaseApiService.AUTH_CREDENTIALS,
      JSON.stringify(login || {})
    );
  }

  createAuthorizationHeader(headers?: HttpHeaders) {
    return {
      'Authorization': `Bearer ${this.token()}`
    }
  }

  cleanUrl(baseUrl: string, url: string) {
    let suffix = url[0] === '/' ? url.substr(1) : url;
    return `${baseUrl}/${suffix}`;
  }

  getProductType(): PRODUCT_TYPE_ENUM {
    const route = location.pathname;

    if (route.startsWith(`/${PRODUCT_TYPE_ENUM.advisory}`)) {
      return PRODUCT_TYPE_ENUM.advisory.toUpperCase() as PRODUCT_TYPE_ENUM;
    }

    return PRODUCT_TYPE_ENUM.equity.toUpperCase() as PRODUCT_TYPE_ENUM;
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(BaseApiService.TOKEN_LOCATION);
  }

}
