import { Injectable } from '@angular/core';
import { BlogService } from './services/blog.service';
import { BlogContent } from './services/blog.model';
import { CollectionQuery } from './../collections/services/content.interface';
import { PaginatedList, ReqFailureResponse } from '../../../../../../projects/api/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

export interface BlogListState {
  loading: boolean;
  count?: number;
  posts: BlogContent[];
  error?: string;
  message?: string;

}

const initialState: BlogListState = {
  loading: false,
  posts: [],
};

@Injectable({ providedIn: 'root' })
export class BlogListStateService {
  state = new BehaviorSubject<BlogListState>(initialState);

  constructor(private blogSvc: BlogService,
    private spinner: NgxSpinnerService,) { }


  find(query: Partial<CollectionQuery>) {
    this.loading();
    this.blogSvc
      .getBlogContent(query)
      .pipe(first())
      .subscribe({
        next: this.updatePosts.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updatePosts(data: PaginatedList<BlogContent>) {
    this.state.next({
      loading: false,
      posts: data.data,
      count: data.count,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private onError(error: ReqFailureResponse) {
    this.state.next({
      ...this.state.getValue(),
      error: error.message,
      loading: false,
      message:"An Error Occurred"
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading..."
    });
  }
}
