import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  ReqSuccessResponse,
  PaginatedList,
} from '../../../../../api/src/public-api';
import { PaymentTxnsModel } from './payment.model';
import { CURRENCY } from '../../../../../shared/src/public-api';
import { LoadingService } from '@console/shared/components/loading/loading.service';

const API_PAYMENTS = 'api/v1/payments';

@Injectable()
export class PaymentTxnService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  getPaymentTxns(searchParams?) {
    const payments$ = this.http.get<PaginatedList<PaymentTxnsModel>>(
      API_PAYMENTS,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(payments$);
  }

  getPaymentTxnsByUserId(searchParams?) {
    return this.http.get<PaginatedList<PaymentTxnsModel>>(
      API_PAYMENTS,
      searchParams
    );
  }

  getPaymentTxnById(
    id: number
  ): Observable<ReqSuccessResponse<PaymentTxnsModel>> {
    const url = API_PAYMENTS + `/${id}`;
    return this.http.get<ReqSuccessResponse<PaymentTxnsModel>>(url);
  }

  cancelPayment(id: string): Observable<ReqSuccessResponse<PaymentTxnsModel>> {
    const url = `${API_PAYMENTS}/cancel/${id}`;
    return this.http.put<ReqSuccessResponse<PaymentTxnsModel>>(url, {});
  }

  requeryPayment(payload: {
    id: string;
    currency: CURRENCY;
  }): Observable<ReqSuccessResponse<PaymentTxnsModel>> {
    const url = `${API_PAYMENTS}/status/${payload.id}`;
    return this.http.get<ReqSuccessResponse<PaymentTxnsModel>>(url, {});
  }

  requery(payload: {
    paymentReference: string;
  }): Observable<ReqSuccessResponse<PaymentTxnsModel>> {
    const url = `${API_PAYMENTS}/requery/${payload.paymentReference}`;
    return this.http.get<ReqSuccessResponse<PaymentTxnsModel>>(url, {});
  }

  authorizePayment(content: {
    paymentReference?: string;
    paymentReferences?: string[];
    otp: string;
  }): Observable<ReqSuccessResponse<PaymentTxnsModel>> {
    const url =
      content.hasOwnProperty('paymentReferences') &&
      Array.isArray(content?.paymentReferences)
        ? `${API_PAYMENTS}/authorize_multiple`
        : `${API_PAYMENTS}/authorize`;

    return this.http.post<ReqSuccessResponse<PaymentTxnsModel>>(url, content);
  }

  confirmPayment(content: {
    transactionReference: string;
  }): Observable<ReqSuccessResponse<PaymentTxnsModel>> {
    const url = `${API_PAYMENTS}/confirm`;
    return this.http.post<ReqSuccessResponse<PaymentTxnsModel>>(url, content);
  }

  deletePayment(
    paymentIDs: string[]
  ): Observable<ReqSuccessResponse<PaymentTxnsModel>> {
    const url = `${API_PAYMENTS}`;
    return this.http.delete<ReqSuccessResponse<PaymentTxnsModel>>(
      url,
      paymentIDs
    );
  }
}
