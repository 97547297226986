import { MandateService } from '../services/mandate.service';
import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { IMandate } from '../interface/IMandate';
import { ICardList } from '../interface/ICardList';

export interface MandateListState {
  loading: boolean;
  count?: number;
  mandates: IMandate[];
  error?: string;
}

const initialState: MandateListState = {
  loading: false,
  mandates: [],
};

@Injectable()
export class MandateListStateService {
  private subject$ = new BehaviorSubject<MandateListState>(initialState);

  state$ = this.subject$.asObservable();

  constructor(
    private mandateService: MandateService,
    private messagesService: MessagesService
  ) {}

  get loading$(): Observable<boolean> {
    return this.subject$.pipe(map((state) => state.loading));
  }

  find(query: object): void {
    this.loading();

    this.mandateService
      .fetchMandates(query)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        })
      )
      .subscribe({
        next: this.onSuccess.bind(this),
      });
  }

  getUserCards(userId: string): Observable<PaginatedList<ICardList>> {
    return this.mandateService.fetchUserCards(userId);
  }

  private onSuccess(response: PaginatedList<IMandate>): void {
    this.subject$.next({
      loading: false,
      mandates: response.data,
      count: response.count,
    });
  }

  saveMandate(dataToSubmit: any): Observable<ReqSuccessResponse<IMandate>> {
    const isUpdate = dataToSubmit.hasOwnProperty('id');

    const obs$ = isUpdate
      ? this.mandateService.updateMandate(dataToSubmit)
      : this.mandateService.saveMandate(dataToSubmit);

    return obs$.pipe(
      // we handle error in the child component instance
      catchError((error) => throwError(error)),
      map((response) => {
        // We show the success message on the parent component instance and return the observable
        this.messagesService.open(
          MessagesEnum.success,
          `Successfully ${isUpdate ? 'updated' : 'added'} mandate`,
          { hideAll: true }
        );

        return response;
      })
    );
  }

  private handleError(res: ChakaAPIError): void {
    const errorMessage = cleanChakaAPIError(res) || 'An Error occurred';

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false,
    });

    this.messagesService.open(MessagesEnum.danger, errorMessage);
  }

  reset(): void {
    this.subject$.next(initialState);
  }

  private loading(): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true,
    });
  }
}
