import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { MerchantsModel } from '../../merchants.interface';


@Component({
  selector: 'kt-merchant-card',
  templateUrl: './merchant-card.component.html',
  styleUrls: ['./merchant-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MerchantCardComponent implements OnInit {

	@Input() merchant:MerchantsModel;

	@Output() view = new EventEmitter<MerchantsModel>();

	constructor() {}

	onView() {
		this.view.emit(this.merchant);
	}

	ngOnInit() {}
}

