import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { AuthRoleStateService } from '../../../../../authentication/src/lib/auth-permission';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { WALLET_TRANSACTION_STATUS } from '../../../../../shared/src/lib/_enums/wallet-transaction-status.enum';
import { OrderStateService } from './order.state';
import { formatToCurrency } from '../../../../../shared/src/lib/utils/formatter';

@Component({
  selector: 'kt-orders-edit',
  templateUrl: './orders-edit.component.html',
  styleUrls: ['./orders-edit.component.scss'],
})
export class OrdersEditComponent implements OnInit, OnDestroy {
  @Input() orderId: string;

  formatToCurrency = formatToCurrency;

  constructor(
    public order: OrderStateService,
    private route: ActivatedRoute,
    private router: ConsoleRouterService,
    private role: AuthRoleStateService
  ) {}

  getStatusColor(status: WALLET_TRANSACTION_STATUS) {
    if (status === WALLET_TRANSACTION_STATUS.PENDING) {
      return 'amber';
    }
    if (status === WALLET_TRANSACTION_STATUS.INITIATED) {
      return 'brand';
    }
    if (
      status === WALLET_TRANSACTION_STATUS.WAITING ||
      status === WALLET_TRANSACTION_STATUS.PROCESSING
    ) {
      return 'white';
    }
    if (
      status === WALLET_TRANSACTION_STATUS.CANCELLED ||
      status === WALLET_TRANSACTION_STATUS.FAILED ||
      status === WALLET_TRANSACTION_STATUS.REJECTED
    ) {
      return 'danger';
    }
    return 'success';
  }

  // get canBeQueried$() {
  //   const { FILLED, CANCELED } = ORDER_STATUS;
  //   const canApprove$ = this.role.hasPermission$('ORDER_UPDATE');
  //   const shouldBeApproved$ = this.order.state
  //     .pipe(map(state => state.order?.status === FILLED || state.order?.status === CANCELED))
  //     .pipe(map(approved => !approved));
  //   return combineLatest(canApprove$, shouldBeApproved$)
  //     .pipe(map(([canApprove, shouldApprove]) => canApprove && shouldApprove));
  // }

  // get canBeCancel$() {
  //   const { FILLED, CANCELED } = ORDER_STATUS;
  //   const canApprove$ = this.role.hasPermission$('ORDER_UPDATE');
  //   const shouldBeApproved$ = this.order.state
  //     .pipe(
  //       tap(state => console.log(state)),
  //       map(
  //         state => (
  //           state.order?.status === FILLED ||
  //           state.order?.status === CANCELED
  //         )
  //       )
  //     )
  //     .pipe(map(approved => !approved));

  //   return combineLatest(canApprove$, shouldBeApproved$)
  //     .pipe(map(([canApprove, shouldApprove]) => {
  //       console.log('here can');
  //       console.log('can', canApprove);
  //       console.log('app', shouldApprove);
  //       return canApprove && shouldApprove;
  //     }));
  // }

  goToUserProfile() {
    this.order.state
      .pipe(
        first(),
        map((state) => state?.order?.userId)
      )
      .subscribe((id) => {
        this.router.productNavigate(['users', id]);
      });
  }

  cancel() {
    this.order.state.pipe(first()).subscribe((state) => {
      if (state.order) {
        this.order.cancel(state.order.id);
      }
    });
  }

  requery() {
    this.order.state.pipe(first()).subscribe((state) => {
      if (state.order) {
        this.order.requery(state.order.id, state.order.currency);
      }
    });
  }

  retreiveOrder() {
    if (this.orderId) {
      return this.order.retrieve(this.orderId);
    }

    this.route.params.pipe(map((params) => params.id)).subscribe((id) => {
      if (id) {
        this.order.retrieve(id);
      }
    });
  }

  goBack() {
    this.router.goBack();
    // this.router.productNavigate(['orders']);
  }

  ngOnInit() {
    this.role.hasPermission$('ORDER_VIEW').subscribe((authorized) => {
      if (!authorized) {
        return this.router.productNavigate(['orders']);
      }
      this.retreiveOrder();
    });
  }

  ngOnChanges() {
    if (this.orderId) {
      this.order.retrieve(this.orderId);
    }
  }

  ngOnDestroy() {
    this.order.resetState();
  }
}
