import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsoleRouterService } from '@console/shared';
import { first, skip } from 'rxjs/operators';
import { InstrumentStateService } from '../states/single-instrument--state.service';
import { RequeryInstrument } from '../services/instrument-interface';

@Component({
  selector: 'app-requery-instrument',
  templateUrl: './requery-instrument.component.html',
  styleUrls: ['./requery-instrument.component.scss']
})
export class RequeryInstrumentComponent implements OnInit {
  requeryForm: FormGroup;

  constructor(
    private router: ConsoleRouterService,
    private fb: FormBuilder,
    public instrument: InstrumentStateService,
  ) { }


  createForm() {
    return this.fb.group({
      symbol: ['', Validators.required],
      currencyCode: ['', Validators.required],
    });
  }

  requery(form: RequeryInstrument) {
    this.instrument.requeryInstrument(form);

    this.instrument.state.pipe(skip(1), first())
      .subscribe((state) => {
        if (state.instrument) {
          this.router.productNavigate(['instruments', 'symbol', state.instrument?.symbol]);
        }
      })

  }

  ngOnInit(): void {
    this.requeryForm = this.createForm();

  }

}
