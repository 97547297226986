import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { HttpService } from "../../http/http.service";
import { EnterpriseAPIService, ReqSuccessResponse, PaginatedList } from '../../../../../api/src/public-api';

import {
  ReconciliationsModel
} from './reconciliation.model';


const RECONCILIATION = 'api/v1/reconciliation/';

@Injectable()
export class ReconciliationService {
  constructor(private https: HttpClient, private http: EnterpriseAPIService) { }

  getReconciliations(searchParams?) {
    return this.http.get<PaginatedList<ReconciliationsModel>>(
      RECONCILIATION,
      searchParams
    );
  }

  getReconciliationById(id: number): Observable<ReqSuccessResponse<ReconciliationsModel>>  {
    const url = RECONCILIATION + `/${id}`;
    return this.http.get<ReqSuccessResponse<ReconciliationsModel>>(url);
  }


 }
