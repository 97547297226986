import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '../../../../../../../shared/src/lib/interface';
import { Statement, StatementTypes } from '../interface';
import { CURRENCY } from '../../../../../../../shared/src/lib/_enums/currency.enum';
import subWeeks from 'date-fns/subWeeks'

@Component({
  selector: 'app-statements-table',
  templateUrl: './statements-table.component.html',
  styleUrls: ['./statements-table.component.scss'],
  providers: [],
})
export class StatementsTableComponent implements OnInit {

  /**
   * amount od transactions to show
   */
  @Input() pageSize = 10;

  /**
   * total amount of data being returned
   */
  @Input() count = 0;

  /**
   * statements to display
   */
  @Input() statements: Statement[] = [];

  /**
   * loading controller
   */
  @Input() loading = false;

  /**
   * Error message for the table
   */
  @Input() error;

  /**
   * Query emitter to parent component
   */
  @Output() query = new EventEmitter<object>();

  /**
   * emit query selected from the table
   */

  displayedColumns = [
    'source',
    'type',
    'amount',
    'walletType',
    'date_created',
    'status',
    'actions',
  ];

  currencies = [
    {
      name: 'Global',
      value: CURRENCY.DOLLAR,
    },
    {
      name: 'Local',
      value: CURRENCY.NAIRA,
    }
  ];

  statementTypes = [
    {
      name: 'STATEMENT',
      value: StatementTypes.STATEMENT,
    },
    {
      name: 'TAX',
      value: StatementTypes.TAX,
    },
    {
      name: 'CONFIRMATION',
      value: StatementTypes.CONFIRMATION,
    },
  ];

  private filter: Record<string, any> = {
    // from: subWeeks(new Date(), 2).toISOString(),
    // to: new Date().toISOString()
  };

  constructor() { }

  handleQuery(key, value, isDate = false) {
    if (isDate) {
      value = new Date(value).toISOString();
    }
    this.filter[key] = value;
    this.query.emit(this.filter);
  }

  /**
   * handles pagination events
   */
  paginate($event: PageEvent) {
    this.handleQuery('pageNumber', $event.pageNumber);
  }

  ngOnInit() {
    this.filter.pageSize = this.pageSize;
  }
}
