import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvisoryFeesComponent } from './advisory-fees.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '@console/shared';
import { PaginationModule } from 'projects/_shared/Pagination/pagination.module';



@NgModule({
  declarations: [AdvisoryFeesComponent],
  imports: [
    NgxSpinnerModule,
    PaginationModule,
    SharedModule,
    CommonModule
  ],
  exports: [
    AdvisoryFeesComponent
  ]
})
export class AdvisoryFeesModule { }
