export enum EXCHANGE_ACTIONS {
	ALL = "",
	// DEBIT = "DEBIT",
	// CREDIT = "CREDIT",
	// EXCHANGE = "EXCHANGE",
	// WITHDRAW = "WITHDRAW",

	CSR = "CSR",
	JNLC = "JNLC",
	CSD = "CSD",
	FEE = "FEE",
}
