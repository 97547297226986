import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Component({
  selector: 'section-search-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @Input() section = 'SectionText';
  @Input() actionText: string;
  @Input() count: string;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() placeholder: string;

  @Output() onSearch = new EventEmitter<string>();
  @Output() actionBtn = new EventEmitter();

  constructor(private dashboardService: DashboardService) {}

  onSubmit($event): void {
    $event.preventDefault();
  }

  ngOnInit(): void {
    this.dashboardService.showSubHeader();
  }

  ngOnDestroy(): void {
    this.dashboardService.hideSubHeader();
  }
}
