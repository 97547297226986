import { Injectable } from '@angular/core';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { WALLET_PROVIDERS } from '@console/shared/_enums';
import { DwAccount } from '@console/user-managements';
import { Observable } from 'rxjs';
import {
  CreateUserWallet,
  VirtualAccount,
  Wallet,
  WalletTransaction,
  WalletTransfer,
  WalletWithdraw,
} from './wallet.interface';

const API_WALLET = 'api/v1/wallets';
const API_VIRTUAL_ACCOUNT = 'api/v1/vaccounts';

@Injectable()
export class WalletService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  wallets(userId: string): Observable<ReqSuccessResponse<Wallet[]>> {
    const url = `${API_WALLET}/user/${userId}`;
    return this.http.get<ReqSuccessResponse<Wallet[]>>(url);
  }

  exchange(
    transfer: WalletTransfer
  ): Observable<ReqSuccessResponse<WalletTransaction>> {
    return this.http.post<ReqSuccessResponse<WalletTransaction>>(
      `${API_WALLET}/exchange`,
      transfer
    );
  }

  previousWithdrawals(
    query: Record<string, any>
  ): Observable<PaginatedList<WalletTransaction>> {
    return this.http.get<PaginatedList<WalletTransaction>>(
      `/api/v1/withdrawals`,
      query
    );
  }

  withdrawal(
    transfer: WalletWithdraw
  ): Observable<ReqSuccessResponse<WalletTransaction>> {
    return this.http.post<ReqSuccessResponse<WalletTransaction>>(
      `/api/v1/withdrawals`,
      transfer
    );
  }

  create(wallet: CreateUserWallet): Observable<ReqSuccessResponse<Wallet[]>> {
    return this.http.post<ReqSuccessResponse<Wallet[]>>(API_WALLET, wallet);
  }

  clearPendingCash(
    userId: string,
    provider: WALLET_PROVIDERS
  ): Observable<ReqSuccessResponse<Wallet[]>> {
    return this.http.post<ReqSuccessResponse<Wallet[]>>(
      `${API_WALLET}/clear_pending_cash`,
      { userId, provider }
    );
  }

  getDwAccount(params: { userId }): Observable<ReqSuccessResponse<DwAccount>> {
    const url = `${API_WALLET}/dw-account-info`;
    const dwAccounts$ = this.http.get<ReqSuccessResponse<DwAccount>>(
      url,
      params
    );

    return this.loadingService.showLoaderUntilCompleted(dwAccounts$);
  }

  fetchVirtualAccounts(
    walletId: number
  ): Observable<ReqSuccessResponse<VirtualAccount[]>> {
    const wallets$ = this.http.get<ReqSuccessResponse<VirtualAccount[]>>(
      `${API_VIRTUAL_ACCOUNT}/find_by_wallet_id_ordered_by_default/${walletId}`
    );

    return this.loadingService.showLoaderUntilCompleted(wallets$);
  }
}
