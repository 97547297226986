
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseApiService } from '@console/api';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private baseApiService: BaseApiService
  ) {}

  canActivate():
    boolean |
    UrlTree |
    Observable<boolean | UrlTree> |
    Promise<boolean | UrlTree> {

    return this.baseApiService.isAuthenticated() ?
      true :
      this.router.createUrlTree(['/']);
  }
}