import { Injectable } from '@angular/core';
import { ReqSuccessResponse, ChakaAPIError } from '@console/api';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { LiteInstrument } from '../models/instrument.interface';

import { InstrumentService } from '../services/instrument.service';

export interface SimilarInstrumentListState {
  loading: boolean;
  instruments: LiteInstrument[];
  error?: string;
}

const initialState: SimilarInstrumentListState = {
  loading: false,
  instruments: [],
};

@Injectable({ providedIn: 'root' })
export class SimilarInstrumentListStateService {
  state = new BehaviorSubject<SimilarInstrumentListState>(initialState);

  constructor(private InstrumentSvc: InstrumentService) {}

  retrive(symbol: string) {
    this.loading();
    this.InstrumentSvc.getSimilarInstrument(symbol)
      .pipe(first())
      .subscribe({
        next: this.updateInstruments.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateInstruments({data}: ReqSuccessResponse<LiteInstrument[]>) {
    this.state.next({
      loading: false,
      instruments: data
    });
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: res.error.message,
      loading: false,
    });
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
  }
}
