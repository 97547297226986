import { IFee } from './../fees.interface';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';
import { MerchantCredentials, MerchantsModel } from '../merchants.interface';
import { MerchantService } from '../services/merchant.service';
import {
  ReqSuccessResponse,
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList,
  ReqFailureResponse,
} from '../../../../../api/src/lib/api.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { FeeService } from '../services/fee.service';

export interface MerchantState {
  loading: boolean;
  merchant?: MerchantsModel;
  clientCredentials?: MerchantCredentials;
  fees?: IFee;
  deleted?: boolean;
  error?: string;
  edit?: boolean;
}

const getUUid = (() => {
  let id = 0;

  return () => ++id;
})();

const initalState: MerchantState = {
  loading: false,
  error: 'test-error',
};

@Injectable({
  providedIn: 'root',
})
export class MerchantStateService {
  state = new BehaviorSubject<MerchantState>(initalState);

  constructor(
    private merchantSvc: MerchantService,
    private spinner: NgxSpinnerService,
    private messagesService: MessagesService,
    private feeService: FeeService
  ) {}

  loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  newMerchant() {
    this.state.next({
      loading: false,
      clientCredentials: undefined,
      merchant: {},
      edit: true,
    } as any);
  }

  toggleEditMode() {
    const state = this.state.getValue();
    this.state.next({
      ...state,
      edit: !state.edit,
    });
  }

  retrieve(id: number) {
    this.merchantSvc
      .getMerchantById(id)
      .pipe(first())
      .pipe(
        exhaustMap((res) =>
          combineLatest(
            of(res),
            this.merchantSvc.getMerchantCredentialsById(res.data.clientId)
          )
        )
      )
      .subscribe({
        next: this.onRetrieved.bind(this),
        error: () => this.onError.bind(this),
      });
  }

  retrieveFeeSettingsById(merchantId: number): void {
    this.feeService
      .getFeeById(merchantId)
      .pipe(first())
      .subscribe(
        (response: ReqSuccessResponse<IFee>) => {
          this.state.next({
            ...this.state.getValue(),
            fees: response.data,
            loading: false,
            error: undefined,
          });
        },
        (error: ChakaAPIError) => {
          let message = cleanChakaAPIError(error);

          const noMerchant = message.includes("doesn't exist");

          message = noMerchant
            ? message + ', please create a settings'
            : message;

          this.messagesService.open(
            noMerchant ? MessagesEnum.info : MessagesEnum.danger,
            message
          );
        }
      );
  }

  saveFee(dataToSubmit): Observable<ReqSuccessResponse<IFee>> {
    return this.feeService.saveFee(dataToSubmit);
  }

  deleteFeeBand(bandId: number): Observable<ReqSuccessResponse<IFee>> {
    return this.feeService.deleteFeeBand(bandId);
  }

  update(updatedMerchant: Partial<MerchantsModel>) {
    // this.updateLoading();

    this.messagesService.open(
      MessagesEnum.loading,
      'Saving merchant details, please wait...',
      { hideAll: true }
    );

    this.merchantSvc
      .updateMerchant({
        ...this.state.getValue().merchant,
        ...updatedMerchant,
      })
      .pipe(first())
      .subscribe({
        next: this.onUpdated.bind(this),
        error: this.onError.bind(this),
      });
  }

  private onUpdated(merchantRes: ReqSuccessResponse<MerchantsModel>) {
    this.state.next({
      ...this.state.getValue(),
      merchant: merchantRes.data,
      loading: false,
      edit: false,
      error: undefined,
    });

    this.messagesService.open(
      MessagesEnum.success,
      'Merchant saved successfully',
      { hideAll: true }
    );
  }

  delete(id: number) {
    this.loading();
    this.merchantSvc
      .deleteMerchant(id)
      .pipe(first())
      .subscribe({
        next: this.onDelete.bind(this),
        error: this.onError.bind(this),
      });
  }

  clearError() {
    const state = this.state.getValue();
    this.state.next({
      ...state,
      error: undefined,
    });
  }

  reset() {
    this.state.next(initalState);
  }

  private onRetrieved([merchantRes, credientials]: [
    ReqSuccessResponse<MerchantsModel>,
    ReqSuccessResponse<MerchantCredentials>
  ]) {
    this.state.next({
      ...this.state.getValue(),
      merchant: merchantRes.data,
      clientCredentials: credientials.data,
      loading: false,
      edit: false,
      error: undefined,
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error,
    });

    this.messagesService.open(MessagesEnum.danger, error);
  }

  private onDelete({}: ReqSuccessResponse<MerchantsModel>) {
    const state = this.state.getValue();
    this.state.next({
      ...state,
      loading: false,
      deleted: true,
    });
    this.spinner.hide();
  }

  private loading(): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error: undefined,
    });
    // this.spinner.show();
  }
}
