<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">

    <div class="container container-fluid mt-4">
      <div class="kt-form">
        <div *ngIf="(dwAccountState.state | async).DwAccount as _dwAccount" class="row">
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Global ID</h6>
            <h5>{{ _dwAccount.id }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Account Number</h6>
            <h5>{{ _dwAccount.accountNo }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>BOD Money Market</h6>
            <h5>{{ formatToCurrency('USD', _dwAccount?.bod?.moneyMarket)}}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>BOD Equity Value</h6>
            <h5>{{ formatToCurrency('USD', _dwAccount?.bod?.equityValue)}}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>BOD Cash Available For Withdrawal</h6>
            <h5>{{ formatToCurrency('USD', _dwAccount?.bod?.cashAvailableForWithdrawal)}}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>BOD Cash Available For Trading</h6>
            <h5>{{ formatToCurrency('USD', _dwAccount?.bod?.cashAvailableForTrading)}}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>BOD Updated When</h6>
            <h5>{{ _dwAccount?.bod?.updatedWhen | date:'medium'}}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>BOD Cash Balance</h6>
            <h5>{{ formatToCurrency('USD', _dwAccount?.bod?.cashBalance)}}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Leverage</h6>
            <h5>{{ _dwAccount.leverage }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Nick Name</h6>
            <h5>{{ _dwAccount.nickname }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Commission ID</h6>
            <h5>{{ _dwAccount.commissionID }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>User ID</h6>
            <h5>{{ _dwAccount.userID }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Restricted</h6>
            <h5>{{ _dwAccount.restricted }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Good Faith Violations</h6>
            <h5>{{ _dwAccount.goodFaithViolations }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Pattern Day Trade</h6>
            <h5>{{ _dwAccount.patternDayTrades }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Free Trade Balance</h6>
            <h5>{{ _dwAccount.freeTradeBalance }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Free Trade Balance</h6>
            <h5>{{ _dwAccount.gfvPdtExempt }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Buying Power Override</h6>
            <h5>{{ _dwAccount.buyingPowerOverride }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>sweepInd</h6>
            <h5>{{ _dwAccount.sweepInd }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Interest Free</h6>
            <h5>{{ _dwAccount.interestFree }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Created When</h6>
            <h5>{{ _dwAccount.createdWhen | date:'medium' }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Opened When</h6>
            <h5>{{ _dwAccount.openedWhen | date:'medium' }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Account Type ID</h6>
            <h5>{{ _dwAccount?.accountType?.id }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Account Type Name</h6>
            <h5>{{ _dwAccount?.accountType?.name }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Account Type Description</h6>
            <h5>{{ _dwAccount?.accountType?.description }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Account Management Type ID</h6>
            <h5>{{ _dwAccount?.accountMgmtType?.id }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Account Management Type Name</h6>
            <h5>{{ _dwAccount?.accountMgmtType?.name }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Account Management Type Description</h6>
            <h5>{{ _dwAccount?.accountMgmtType?.description }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Status ID</h6>
            <h5>{{ _dwAccount?.status?.id }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Status Name</h6>
            <h5>{{ _dwAccount?.status?.name }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Status Description</h6>
            <h5>{{ _dwAccount?.status?.description }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Trading Type</h6>
            <h5>{{ _dwAccount?.tradingType?.id }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Trading Name</h6>
            <h5>{{ _dwAccount?.tradingType?.name }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Trading Description</h6>
            <h5>{{ _dwAccount?.tradingType?.description }}</h5>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
