<div *ngIf="instrument" class="stock-performance-banner">

  <div class="stock-name-info">

    <h3 class="stock-name"> {{ marketTitle}} </h3>
    <div class="stock-watchlist-container">
    </div>

  </div>

  <p class="stock-price"> {{ price }} </p>

  <div class="market-information">
    <p class="market-performance">
      <span class="positive"> {{ changeText }} ({{ changePercentText }}) </span> {{ intervalText }}
    </p>

    <div class="market-hours">
      <p> Market Open From </p>
      <p *ngIf="instrument.currency === 'NGN'"> (9:30AM - 2:30PM) </p>
      <p *ngIf="instrument.currency === 'USD'"> (2:30PM - 9:00PM) </p>
      <p> WAT </p>
    </div>

  </div>



</div>
