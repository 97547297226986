import { MandateService } from '../services/mandate.service';
import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from '../../../../../api/src/public-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, startWith, take, tap } from 'rxjs/operators';
import { IMandate } from '../interface/IMandate';
import { MessagesService } from '../../../../../shared/src/lib/components/messages/messages.service';
import { MessagesEnum } from '../../../../../shared/src/lib/components/messages/enums/messages.enums';
import { ConsoleRouterService } from '@console/shared';

export interface IMandateDetailsState {
  loading: boolean;
  mandate: IMandate;
  error?: string;
}

const initialState: IMandateDetailsState = {
  loading: false,
  mandate: null
};

@Injectable()
export class MandateDetailsStateService {
  private subject$ = new BehaviorSubject<IMandateDetailsState>(initialState);

  state$ = this.subject$.asObservable();

  private messageId: number;

  constructor(
    private mandateService: MandateService,
    private messagesService: MessagesService,
    private consoleRouter: ConsoleRouterService
  ) { }


  get loading$(): Observable<boolean> {
    return this.subject$.pipe(map((state) => state.loading));
  }

  get mandate$(): Observable<IMandate> {
    return this.subject$.pipe(
      map((state) => state.mandate)
    );
  }


  findById(mandateId: string): void {
    this.loading();

    this.showMessage('Fetching mandate...');

    this.mandateService
      .fetchMandateById(mandateId)
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        )
      )
      .subscribe({
        next: this.onSuccess.bind(this)
      });
  }

  delete(redemptionId: string): void {
    this.loading();

    this.showMessage('Deleting mandate...');

    this.mandateService
      .deleteMandateById(redemptionId)
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        ),
        tap(() => {
          this.messagesService.update(
            {
              id: this.messageId,
              type: MessagesEnum.success,
              message: 'Successfully deleted mandate...'
            },
            {
              timeOut: 5000
            }
          );

          this.consoleRouter.productNavigate(['mandate']);
        })
      )
      .subscribe();
  }

  private onSuccess(data: ReqSuccessResponse<IMandate>): void {
    this.subject$.next({
      loading: false,
      mandate: data.data
    });

    this.messagesService.update(
      {
        id: this.messageId,
        type: MessagesEnum.success,
        message: 'Successfully retrieved mandate...'
      },
      {
        timeOut: 3000
      }
    );
  }

  private handleError(res: ChakaAPIError): void {
    const errorMessage = cleanChakaAPIError(res) || 'An Error occurred';

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false
    });

    this.messagesService.update({
      id: this.messageId,
      type: MessagesEnum.danger,
      message: errorMessage
    });
  }

  reset(): void {
    this.subject$.next(initialState);
  }

  private showMessage(message: string): void {
    !!this.messageId && this.messagesService.hide(this.messageId);
    const { id } = this.messagesService.open(MessagesEnum.loading, message);
    this.messageId = id;
  }

  private loading(): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true
    });
  }
}
