import { Injectable } from '@angular/core';
import { EnterpriseAPIService, PaginatedList } from '@console/api';
import { MerchantStat } from './analytics.interface';

@Injectable({providedIn: 'root'})
export class AnalyticService {

  constructor(
    private client: EnterpriseAPIService
  ) { }

  users(query: Record<string, string> = {}) {
    return this.client.get<PaginatedList<MerchantStat>>(`/api/v1/stats/users`, query);
  }

  transactions(query: Record<string, string> = {}) {
    return this.client.get<PaginatedList<MerchantStat>>(`/api/v1/stats/transactions`, query);
  }

  orders(query: Record<string, string> = {}) {
    return this.client.get<PaginatedList<MerchantStat>>(`/api/v1/stats/orders`, query);
  }

  payments(query: Record<string, string> = {}) {
    return this.client.get<PaginatedList<MerchantStat>>(`/api/v1/stats/payments`, query);
  }

  // () {
  //   return this.client.get<PaginatedList<>>(`/api/stats/`);
  // }

}
