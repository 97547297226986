import { Injectable } from '@angular/core';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from '../../../../../api/src/public-api';
import { WalletTxnDetailsModel } from '../services/transactions.model';
import { WalletTxnService } from '../services/transactions.service';

export interface TransactionState {
  loading: boolean;
  count?: number;
  transaction?: WalletTxnDetailsModel;
  error?: string;
  message?: string;
}

const initialState: TransactionState = {
  loading: false,

};

@Injectable({ providedIn: 'root' })
export class TransactionStateService {
  state = new BehaviorSubject<TransactionState>(initialState);

  constructor(
    private transactionSvc: WalletTxnService,
    private spinner: NgxSpinnerService,
    private messagesService: MessagesService
  ) { }

  findById(id: number): void {
    this.loading();
    this.transactionSvc
      .getWalletTxnById(id)
      .pipe(first())
      .subscribe({
        next: this.updateTransactions.bind(this),
        error: this.onError.bind(this),
      });
  }

  approve(id: number) {
    // this.loading();

  }


  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateTransactions(data: ReqSuccessResponse<WalletTxnDetailsModel>) {
    this.state.next({
      loading: false,
      transaction: data.data,
      message: 'Loading Successful'
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: cleanChakaAPIError(res)
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading ...'
    });
  }

  deleteTransaction(transactionId: number, reason: string, callback: (success: boolean) => void): void {

    this.messagesService.hideAll();

    this.messagesService.open(MessagesEnum.loading, 'Deleting transaction...');

    this.transactionSvc
      .deleteTransaction(transactionId, reason)
      .subscribe({
        next: (response) => {
          console.log(response);
          if (response.success) {
            this.messagesService.update(
              {
                type: MessagesEnum.success,
                message: `Transaction deleted successfully`
              },
              {
                timeOut: 10000
              });
          } else {
            this.handleError(new Error('Failed to delete transaction.'), callback);
            return;
          }

          !!callback && callback(true);
        },
        error: (err: ChakaAPIError) => {
          this.handleError(err, callback);
        }
      })

  }

  private handleError(err, callback: (success: boolean) => void): void {
    const message = cleanChakaAPIError(err);

    this.messagesService
      .update({ type: MessagesEnum.danger, message });

    !!callback && callback(false);
  }
}
