import { MessagesEnum } from './../../../../shared/src/lib/components/messages/enums/messages.enums';
import { CscsSecondLoginStateService } from './../../../../view/src/lib/user-management/kyc/components/cscs-second-login/cscs-second-login-state.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, first, map, skip, switchMap, tap } from 'rxjs/operators';
import { BaseApiService } from '../../../../api/src/lib/base-api.service';
import { AuthCredential } from '../authentication.interface';
import { AuthProfileStateService } from '../profile-state';
import { LoginResponse } from '../services/authentication.service';
import { UserService } from '../../../../user-managements/src/lib/services/user.service';
import { ReqSuccessResponse } from '../../../../api/src/lib/api.interface';
import { deleteProductTypeFromStorage } from '@console/shared/utils/productFromStorage';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { USER_ROLES_ENUM } from '@console/shared/_enums';

export interface ILoginData {
  clientId: string,
  username: string,
  password: string
}
export interface AuthLoginState {
  loading: boolean;
  error?: string;
  details?: LoginResponse;
  loginData?: ILoginData
}

const initialState: AuthLoginState = {
  loading: false,
};

const ErrorTimeOut = 3000;

@Injectable({ providedIn: 'root' })
export class AuthLoginStateService {

  state = new BehaviorSubject<AuthLoginState>(initialState);
  constructor(
    private profile: AuthProfileStateService,
    private baseApi: BaseApiService,
    private router: Router,
    private user: UserService,
    private cscsSecondLoginState: CscsSecondLoginStateService,
    private messagesService: MessagesService
  ) {}

  validate(loginData: ILoginData) {
    return this.user.validateLogin(loginData).pipe(
      tap((response) => {
        if (!response.data) {
          return;
        }

        this.state.next({
          ...this.state.getValue(),
          loginData
        });

        this.router.navigate(['auth', 'login-otp']);
      })
    );
  }


  get loginData$(): Observable<ILoginData> {
    return this.state.pipe(map((state) => state.loginData));
  }

  fetchLoginOtp(clientId: string, email: string): Observable<ReqSuccessResponse<
    { code: string; message: string; success: boolean; }>> {
    return this.user.sendAdminOtp({ clientId, email });
  }

  login(
    credentials: AuthCredential & {
      otp: string,
      client_id: string
    },
    isNewUser = false) {
    this.loading();
    this.user
      .login(credentials)
      .pipe(first())
      .subscribe({
        next: this.loginSuccessful.bind(this)(isNewUser),
        error: this.loginError.bind(this),
      });
  }

  logOut() {
    this.baseApi.deleteToken();
    this.cscsSecondLoginState.deleteLoginDetails();
    deleteProductTypeFromStorage();
    this.profile.reset();
    this.state.next(initialState);
    this.router.navigateByUrl('');
  }

  get message$() {
    return this.state.pipe(distinctUntilChanged()).pipe(
      map((state) => {
        if (state.loading) { return 'Logging In...'; }
        if (state.error) { return state.error; }
        if (state.details) {
          return `Your login was successful. Please wait while we fetch your profile.`;
        }
        return;
      })
    );
  }

  private loginSuccessful() {
    return (res: ReqSuccessResponse<LoginResponse>) => {
      this.state.next({
        ...this.state.getValue(),
        details: res.data,
        loading: false,
      });

      this.messagesService.open(MessagesEnum.success, 'You are now successfully authenticated.', { hideAll: true });

      this.baseApi.updateToken(res.data.access_token);
      this.loginInNavigation();
    };
  }

  private loginInNavigation() {
    this.profile.getProfile();

    this.profile.state.pipe(skip(1), first())
      .subscribe((state) => {

        if (state.error) {
          this.loginError(new Error(state.error));
          return;
        }

        if (state.authProfile &&
          state.authProfile.role === USER_ROLES_ENUM.ADMIN &&
          !state.authProfile.accountLocked) {
          this.router.navigate(['equity', 'dashboard']);
          location.reload();
          return;
        }

        this.loginError(new Error(`Unauthorized User please contact Chaka Admin`));
      });
  }

  private loginError(res: ChakaAPIError | Error) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
    });

    this.messagesService.open(MessagesEnum.danger, error, { hideAll: true });
  }



  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      error: undefined
    });

    this.messagesService.open(MessagesEnum.loading, 'Verifying OTP...', { hideAll: true });
  }
}
