import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmploymentDetails } from './employment-details';
import {
  directors,
  employedByBroker,
  employmentPositions,
  employmentStatus,
  employmentTypes,
  investmentExperiences,
  investmentObjectives,
  riskTolerances
} from './options';
import { KYCFormControl } from '../kyc-form-control';

@Component({
  selector: 'lib-user-employment-form',
  templateUrl: './user-employment-form.component.html',
  styleUrls: ['./user-employment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEmploymentFormComponent extends KYCFormControl<EmploymentDetails> implements OnInit {
  @Input() employmentDetails: EmploymentDetails;
  employmentForm: FormGroup;

  // View options
  employmentTypes = employmentTypes;
  employmentPositions = employmentPositions;
  investmentExperiences = investmentExperiences;
  investmentObjectives = investmentObjectives;
  riskTolerances = riskTolerances;
  employmentStatus = employmentStatus;
  employedByBroker = employedByBroker;
  directors = directors;

  constructor(private fb: FormBuilder) {
    super();
  }

  private createForm(details: Partial<EmploymentDetails> = {}) {
    return this.fb.group({
      employmentStatus: [details.employmentStatus || ''],
      employmentCompany: [details.employmentCompany || ''],
      employmentType: [details.employmentType || ''],
      employmentPosition: [details.employmentPosition || ''],
      directorOfPublicCo: [details.directorOfPublicCo || ''],
      employedByBroker: [details.employedByBroker + '' || ''],
    });
  }

  ngOnInit(): void {
    this.employmentForm = this.createForm(this.employmentDetails);
    if (this.disabled) {
      this.employmentForm.disable()
    }
    this.listen(this.employmentForm);
  }
}
