import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import {
  AuthSignUp,
  AuthUserDetails,
} from '@console/authentication/authentication.interface';
import { AuthService } from '@console/authentication/providers/authentication.service';
import { USER_ROLES_ENUM } from '@console/shared/_enums';
import { UserKYCService } from '@console/user-managements/services/user-kyc.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';

export interface AuthSignUpState {
  loading: boolean;
  error?: string;
  details?: AuthUserDetails;
  message?: string;
}

const initialState: AuthSignUpState = {
  loading: false,
};

const ErrorTimeOut = 3000;

@Injectable({ providedIn: 'root' })
export class AuthSignUpStateService {
  spinnerName = 'Register-spinner';

  state = new BehaviorSubject<AuthSignUpState>(initialState);

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private console: ConsoleRouterService,
    private user: UserKYCService
  ) {}

  signUp(credentials: AuthSignUp) {
    credentials.username = credentials.email;
    if (credentials.isAdmin) {
      (credentials as any).role = USER_ROLES_ENUM.ADMIN;
      (credentials as any).admin = true;
      delete (credentials as any).isAdmin;
      this.loading();
      this.auth
        .register(credentials)
        .pipe(first())
        .subscribe({
          next: this.onRegistered.bind(this)(credentials),
          error: this.onError.bind(this),
        });
    } else {
      this.loading();
      this.auth
        .register(credentials)
        .pipe(first())
        .subscribe({
          next: this.onRegistered.bind(this)(credentials),
          error: this.onError.bind(this),
        });
    }
  }

  reset() {
    this.loading();
    this.state.next(initialState);
  }

  get message$() {
    return this.state
      .pipe(distinctUntilChanged())
      .pipe(map((state) => state.message));
  }

  private onRegistered(credential: AuthSignUp) {
    return ({ data, message }: ReqSuccessResponse<AuthUserDetails>) => {
      this.state.next({
        ...this.state.getValue(),
        details: data,
        message: `Authentication Credential Created`,
        loading: false,
      });
      this._snackBar.open(message, `Close`);
        this.console.productNavigate(['users', data.id]);
    };
  }

  private loginAndOnboard(auth: AuthUserDetails): void {
    this.user
      .createUserKyc({ userId: auth.id, email: auth.email })
      .pipe(first())
      .subscribe(
        ({ data }) => {
          this.spinner.hide();
          this.state.next({ ...this.state.getValue(), loading: false });
          this.console.productNavigate(['users', data.userId]);
        },
        (error) => {
          this.state.next({ loading: true, error: cleanChakaAPIError(error) });
          let cleanError = cleanChakaAPIError(error);
          this._snackBar.open(cleanError, `Close`);
          setTimeout(() => {
            this.spinner.hide();
          }, 3000);
        }
      );
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      message: error,
      loading: false,
    });
    this._snackBar.open(error, `Close`);
    this.alertError();
  }

  private alertError() {
    setTimeout(() => {
      this.spinner.hide();
    }, ErrorTimeOut);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading....',
    });
    this.spinner.show();
  }
}
