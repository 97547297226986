import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { map, first, exhaustMap } from 'rxjs/operators';
import {
  NipPaymentResponse,
  PaymentReference,
  NipPaymentRequest,
} from '../providers/payment-interface';
import { PaymentService } from '../providers/payment-service';
import { ReqSuccessResponse } from '../../../../../../api/src/lib/api.interface';
import { NotificationService } from '@console/shared/services/notification-service';

export interface NipPaymentState {
  payment?: NipPaymentResponse;
  reference?: string;
  loading: boolean;
  error?: string;
}

const initialState: NipPaymentState = { loading: false };

@Injectable({ providedIn: 'root' })
export class NipPaymentStateService {
  state = new BehaviorSubject<NipPaymentState>(initialState);

  spinnerName = 'NIP-payment-Spinner';

  constructor(
    private spinner: NgxSpinnerService,
    private payment: PaymentService,
    private notificationService: NotificationService
  ) { }

  generateReference(body: PaymentReference): void {
    this.loading();
    this.payment
      .generateReference(body)
      .pipe(first())
      .subscribe({
        next: this.onReferenceGenerated.bind(this),
        error: this.onUserNipPaymentError.bind(this),
      });
  }

  payWithNip(body: Omit<NipPaymentRequest, 'transactionReference'>): void {
    this.loading();
    this.state
      .pipe(
        first(),
        exhaustMap((state) =>
          this.payment.nip({ ...body, transactionReference: state.reference })
        )
      )
      .subscribe({
        next: this.onUserNipPaymentLoaded.bind(this),
        error: this.onUserNipPaymentError.bind(this),
      });
  }

  reverseViaNIP(body: Omit<NipPaymentRequest, 'transactionReference'>): void {
    this.loading();
    this.payment.reverseViaNIP(body as any)
      .subscribe({
        next: this.onUserNipPaymentLoaded.bind(this),
        error: this.onUserNipPaymentError.bind(this),
      });
  }

  resetState(): void {
    this.state.next(initialState);
  }

  resetError(): void {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(): void {
    this.state.next({
      ...this.state.getValue(),
      loading: true
    });
    this.spinner.show(this.spinnerName);
  }

  private onReferenceGenerated({ data }: ReqSuccessResponse<Record<string, any>>): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      reference: data.reference,
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.success(`Successfully generated payment reference ${data.reference}`);
  }

  private onUserNipPaymentLoaded({
    data,
  }: ReqSuccessResponse<NipPaymentResponse>): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      payment: data,
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.success('NIP transaction created successfully');
  }

  private onUserNipPaymentError(res: ChakaAPIError): void {
    const error = cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.error(error);
  }
}
