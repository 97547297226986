import { MatSidenav } from '@angular/material/sidenav';
import { Component, Input, OnInit } from '@angular/core';
import { formatToCurrency } from '@console/shared/utils/formatter';
import {
  IAdvisoryPortfolioHolding,
  IPortfolioHoldingChildHoldings,
} from '../../interfaces/IAdvisoryPortfolio';

@Component({
  selector: 'portfolio-holding-drawer',
  templateUrl: './portfolio-holding-drawer.component.html',
  styleUrls: ['./portfolio-holding-drawer.component.scss'],
})
export class PortfolioHoldingDrawerComponent implements OnInit {
  @Input() drawer: MatSidenav;
  @Input() activePortfolioHolding: IAdvisoryPortfolioHolding;
  formatToCurrency = formatToCurrency;
  @Input() tradeCurrency;

  ngOnInit(): void {
    this.drawer.openedChange.subscribe((isOpened) => {
      if (!isOpened) {
        this.activePortfolioHolding = null;
      }
    });
  }

  get portfolioChildHoldings(): IPortfolioHoldingChildHoldings[] {
    return this.activePortfolioHolding?.holdings || [];
  }

  closeDrawer(): void {
    this.drawer.close();
  }
}
