import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApiModule } from '../../../api/src/lib/api.module';
import { PermissionService } from '../../../view/src/lib/roles/services/permission.service';
import { AuthRoleStateService } from './auth-permission';
import { AuthLoginStateService } from './login/login.state';
import { AuthProfileStateService } from './profile-state';
import { AccountService } from './providers/account.service';
import { AuthService } from './providers/authentication.service';

@NgModule({
  providers: [
    AccountService,
    AuthService,
    AuthLoginStateService,
    AuthProfileStateService,
    AuthRoleStateService,
    PermissionService,
  ],
  imports: [
    NgxSpinnerModule,
    ApiModule,
  ],
})
export class AuthProvidersModule {}
