import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InstrumentCardComponent } from '../componenet/instrument-card/instrument-card.component';
import { InstrumentListComponent } from '../componenet/instrument-list/instrument-list.component';
import { CommonModule } from '@angular/common';
import { CompanyInformationComponent } from './company-information/company-information.component';
import { MarketInformationComponent } from './market-information/market-information.component';
import { SimilarInstrumentsComponent } from './similar-instruments/similar-instruments.component';
import { StockMarketBannerComponent } from './stock-market-banner/stock-market-banner.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
    InstrumentCardComponent,
    InstrumentListComponent,
    CompanyInformationComponent,
    MarketInformationComponent,
    SimilarInstrumentsComponent,
    StockMarketBannerComponent,
    LineChartComponent,
  ],
  imports: [
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts: import('echarts'),
    }),
  ],
  exports: [
    InstrumentCardComponent,
    InstrumentListComponent,
    CompanyInformationComponent,
    MarketInformationComponent,
    SimilarInstrumentsComponent,
    StockMarketBannerComponent,
    LineChartComponent,
    RouterModule,
  ],
})
export class InstrumentSharedModule { }
