import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ConsoleRouterService } from '../../../../../projects/shared/src/public-api';
import { InstrumentListStateService } from './instrument.state';
import { InstrumentPagination } from './services/instrument.service';
import { PageEvent } from '../../../../shared/src/lib/interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { InstrumentStateService } from './states/single-instrument--state.service';

const userManagementPermissionId = 2;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-instrument',
  templateUrl: './instrument.component.html',
  styleUrls: ['./instrument.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})


export class InstrumentComponent implements OnInit {
  // used for retrieving and persiting component state.
  static INSTRUMENT_LIST_STATE_KEY = 'INSTRUMENT/LIST/STATE_KEY';
  // Page State.
  childRoute = false;

  query: Partial<InstrumentPagination> = {
    pageSize: 8
  };

  requeryModalName = `requery-instruement-modal`;

  constructor(
    public instrument: InstrumentListStateService,
    public singleInstrument: InstrumentStateService,
    private router: ConsoleRouterService,
    private modal: NgxSmartModalService
  ) { }

  /**
   * dynamically allocate the query keys from the UI
   * and returns a new server side query
   */
  private handleQuery(queryKey: keyof InstrumentPagination, value: any) {
    if (queryKey === 'q' && this.query[queryKey] !== value) {
      return this.instrument.find({ [queryKey]: value });
    }
    return this.instrument.find({ ...this.query, [queryKey]: value });
  }

  /**
   * handles search events.
   */
  // tslint:disable-next-line: typedef
  onSearch(query: string) {
    if (this.childRoute) {
      this.router.productNavigate(['instruments']);
    }
    this.handleQuery('q', query);
  }

  /**
   * handles pagination events
   */
  paginate($event: PageEvent) {
    this.handleQuery('pageSize', $event.pageSize);
    this.handleQuery('pageNumber', $event.pageNumber);
  }

  /**
   * shows the full details of the instrument selected
   */
   navigateToInstrument(symbol: string) {
     this.router.productNavigate(['instruments', 'symbol', symbol]);
  }

  openRequeryModal() {
    this.modal.open(this.requeryModalName);

    setTimeout(() => {

      const content = document.querySelector('.nsm-content') as HTMLDivElement;
      content.style.maxWidth = '25vw';
      content.style.padding = '0%';
      content.style.backgroundColor = 'white!important';

    }, 20);

  }

  ngOnInit() {
    this.instrument.find(this.query);
  }
}

