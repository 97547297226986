import { Injectable } from '@angular/core';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { Observable } from 'rxjs';
import { PaginatedList } from '../../../../../../api/src/lib/api.interface';
import { EnterpriseAPIService } from '../../../../../../api/src/lib/enterprise.service';
import { Statement, StatementQueryParam } from './interface';

@Injectable({ providedIn: 'root' })
export class StatementService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) { }

  find(query: Partial<StatementQueryParam & { userId: string }>): Observable<PaginatedList<Statement>> {
    const { userId, ...filters } = query;
    let endpoint = `api/v1/assets/statements`;
    if (userId) {
      endpoint = `${endpoint}/${userId}`;
    }
    const request$ = this.http.get<PaginatedList<Statement>>(endpoint, filters);

    return this.loadingService.showLoaderUntilCompleted(request$);
  }
}
