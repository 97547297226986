<div class="row">
  <div class="col-lg-8 mx-auto">
    <form [formGroup]="cscsForm">
      <div *ngIf="cssDetails?.comment" class="alert alert-info mb-2">
        {{ cssDetails?.comment }}
      </div>

      <div class="form-row">
        <div class="col-md-6 form-group">
          <label for="cscsNumber"> CSCS Number </label>
          <input
            id="cscsNumber"
            class="form-control"
            formControlName="cscsNumber"
          />
        </div>
        <div class="col-md-6 form-group">
          <label for="cscsCHN"> CSCS CHN </label>
          <input class="form-control" formControlName="cscsCHN" />
        </div>
        <div class="col-md-6 form-group">
          <label for="uploadStatus"> CSCS Upload Status </label>
          <select
            formControlName="uploadStatus"
            class="form-control"
            id="uploadStatus"
          >
            <option *ngFor="let item of cscsUploadStatus" [value]="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-6 form-group">
          <label for="cscsSent"> Is CSCS sent? </label>
          <select formControlName="cscsSent" class="form-control" id="cscsSent">
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <button
          class="btn btn-primary"
          type="button"
          [disabled]="!cscsForm.valid"
          (click)="submit(cscsForm.value)"
        >
          Submit
        </button>

        <button
          (click)="submitInfoware(cscsForm.value)"
          *ngIf="showUpdateButton"
          [disabled]="!cscsForm.valid"
          class="btn btn-sm btn-secondary font-weight-bold ml-3"
        >
          Update Infoware CSCS Details
        </button>
      </div>
    </form>
  </div>
</div>
