import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '../../../../api/src/public-api';
import { 	ExchangeRateModel, } from '../transactions/services/transactions.model';
import { WalletTxnService } from '../transactions/services/transactions.service';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";

export interface ExchangeRateListState {
  loading: boolean;
  count?: number;
  exchangerate: 	ExchangeRateModel[];
  error?: string;
  message?: string;
}

const initialState: ExchangeRateListState = {
  loading: false,
  exchangerate: [],
};

@Injectable({ providedIn: 'root' })
export class ExchangeRateListStateService {
  state = new BehaviorSubject<ExchangeRateListState>(initialState);

  constructor(private exchangerateSvc: WalletTxnService,
    private spinner: NgxSpinnerService) { }

  find(query: object) {
    this.loading();
    this.exchangerateSvc
      .getRates(query)
      .pipe(first())
      .subscribe({
        next: this.updateExchangeRate.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateExchangeRate(data: PaginatedList<ExchangeRateModel>) {
    this.state.next({
      loading: false,
      exchangerate: data.data,
      count: data.count,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message:"Error occurred"
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading"
    });
  }
}
