import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { CscsSecondLoginStateService } from './cscs-second-login-state.service';

@Component({
  selector: 'app-cscs-second-login',
  templateUrl: './cscs-second-login.component.html',
  styleUrls: ['./cscs-second-login.component.scss']
})
export class CscsSecondLoginComponent {

  showPassword = false;

  cscsLoginForm: FormGroup;

  formMethods;

  constructor(
    private dialogRef: MatDialogRef<CscsSecondLoginComponent>,
    private cscsSecondLoginState: CscsSecondLoginStateService
  ) {

    this.cscsLoginForm = new FormGroup({
      cscsUsername: new FormControl('', Validators.required),
      cscsPassword: new FormControl('', Validators.required)
    });

    this.formMethods = formMethods(this.cscsLoginForm);
  }

  loginCscs(): void {

    if (!this.cscsLoginForm.valid) {
      this.cscsLoginForm.markAllAsTouched();
      return;
    }

    this.cscsSecondLoginState.loginDetails = this.cscsLoginForm.value;

    this.close(true);
  }

  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }

}
