import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ConsoleRouterService } from '@console/shared';

import { isBoolean } from '@console/shared/utils/isBoolean';
import { Subscription } from 'rxjs';
import { AppSetting } from '../services/settings.interface';
import {
  AppSettingListState,
  AppSettingListStateService,
} from './application-setting-list.state';

@Component({
  selector: 'app-application-setting',
  templateUrl: './application-setting.component.html',
  styleUrls: ['./application-setting.component.scss'],
})
export class ApplicationSettingComponent implements OnInit, OnDestroy {
  expandedIds = new Set<number>();

  filter: Record<string, any> = {};

  private readonly defaultPageEvent = {
    pageSize: 8,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  settingSub$: Subscription;

  settings: AppSetting[] = [];
  count: number;
  loading: boolean;

  isBoolean = isBoolean;

  childRoute = false;

  constructor(
    private setting: AppSettingListStateService,
    private consoleRouter: ConsoleRouterService
  ) {}

  ngOnInit() {
    this.filter.pageSize = this.pageEvent.pageSize;
    this.search();

    this.listenOnState();
  }

  private listenOnState(): void {
    this.settingSub$ = this.setting.state$.subscribe(
      (state: AppSettingListState) => {
        this.settings = state.settings;
        this.count = state.count;
        this.loading = state.loading;
      }
    );
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.search();
  }

  search(query?: string) {
    if (query) {
      this.filter.q = query;
    }

    this.setting.find(this.filter);

    if (this.childRoute) {
      this.consoleRouter.productNavigate(['settings']);
    }
  }

  toggleExpanded(settingId: number) {
    this.expandedIds.has(settingId)
      ? this.expandedIds.delete(settingId)
      : this.expandedIds.add(settingId);
  }

  createSetting(): void {
    this.consoleRouter.productNavigate(['settings', 'create']);
  }

  viewSetting(settingId: number): void {
    this.consoleRouter.productNavigate(['settings', settingId]);
  }

  ngOnDestroy(): void {
    this.settingSub$ && this.settingSub$.unsubscribe();
  }
}
