<ng-container *ngIf="drawer.opened">
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="merchant-drawer-header">
        <span class="text-dark">Configure Fee Bands</span>
      </h3>

      <a
        href="javascript:;"
        class="btn btn-icon btn-xs btn-circle btn-light"
        (click)="closeDrawer()">
        <span class="svg-icon svg-icon-dark svg-icon-sm">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                <rect x="0" y="7" width="16" height="2" rx="1" />
                <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
              </g>
            </g>
          </svg>
        </span>
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-messages></lib-messages>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="feeSettingsForm">
          <ng-template matStepLabel>Fee Settings</ng-template>

          <form [formGroup]="feeSettingsForm">
            <div class="form-group">
              <label>Name <span class="text-danger">*</span></label>
              <input
                class="form-control"
                formControlName="name">
              <small
                class="form-text text-danger"
                *ngIf="!feeSettingsForm.get('name').valid && 
                        feeSettingsForm.get('name').touched">
                <div *ngIf="feeSettingsForm.get('name').hasError('required')">Required input</div>
              </small>
            </div>

            <div class="form-group">
              <label>Value <span class="text-danger">*</span></label>
              <input
                type="number"
                class="form-control"
                formControlName="value">
              <small
                class="form-text text-danger"
                *ngIf="!feeSettingsForm.get('value').valid && 
                        feeSettingsForm.get('value').touched">
                <div *ngIf="feeSettingsForm.get('value').hasError('required')">Required input</div>
                <div *ngIf="feeSettingsForm.get('value').hasError('pattern')">Value must be a positive number</div>
              </small>
            </div>

            <div class="form-group">
              <label>Description <span class="text-danger">*</span></label>
              <textarea
                class="form-control"
                rows="3"
                formControlName="description"></textarea>
              <small
                class="form-text text-danger"
                *ngIf="!feeSettingsForm.get('description').valid && 
                        feeSettingsForm.get('description').touched">
                <div *ngIf="feeSettingsForm.get('description').hasError('required')">Required input</div>
              </small>
            </div>

            <div class="form-group form-row">
              <label class="col-form-label mr-2">Percentage?</label>
              <div>
                <span class="switch">
                  <label>
                    <input type="checkbox" formControlName="percentage">
                    <span></span>
                  </label>
                </span>
              </div>
            </div>


          </form>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Fee Bands</ng-template>

          <form [formGroup]="feeBandsForm">
            <div
              class="fee-band-form-wrapper"
              formArrayName="feeBands"
              #feeBandsDiv
              [ngStyle]="{'height': formHeight}">
              <div
                class="row"
                *ngFor="let feeBand of feeBandsControls; 
                                    let i = index"
                [formGroupName]="i">
                <div class="col-12">
                  <div class="control-column">
                    <div class="form-group">
                      <label>Min Value <span class="text-danger">*</span></label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        formControlName="minTxnSize"
                        (input)="onMinValueChange()">
                      <small
                        class="form-text text-danger"
                        *ngIf="!feeBand.get('minTxnSize').valid && 
                                feeBand.get('minTxnSize').touched">
                        <div *ngIf="feeBand.get('minTxnSize').hasError('required')">Required input</div>
                        <div *ngIf="feeBand.get('minTxnSize').hasError('invalidMinValue')">
                          {{ feeBand.get('minTxnSize').errors?.invalidMinValue }}
                        </div>
                      </small>
                    </div>
                  </div>

                  <div class="control-column">
                    <div class="form-group">
                      <label>Max Value <span class="text-danger">*</span></label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        formControlName="maxTxnSize"
                        (input)="onMaxValueChange()">

                      <small
                        class="form-text text-danger"
                        *ngIf="!feeBand.get('maxTxnSize').valid && 
                                feeBand.get('maxTxnSize').touched">
                        <div *ngIf="feeBand.get('maxTxnSize').hasError('required')">Required input</div>
                        <div *ngIf="feeBand.get('maxTxnSize').hasError('invalidMaxValue')">
                          {{ feeBand.get('maxTxnSize').errors?.invalidMaxValue }}
                        </div>
                      </small>
                    </div>
                  </div>

                  <div class="control-column">
                    <div class="form-group">
                      <label>Percent Fee <span class="text-danger">*</span></label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        formControlName="percentFee">

                      <small
                        class="form-text text-danger"
                        *ngIf="!feeBand.get('percentFee').valid && 
                                feeBand.get('percentFee').touched">
                        <div *ngIf="feeBand.get('percentFee').hasError('required')">Required input</div>
                      </small>
                    </div>
                  </div>

                  <div class="control-column">
                    <div class="form-group">
                      <label>Min. Comm. Fee <span class="text-danger">*</span></label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        formControlName="minCommissionFee">

                      <small
                        class="form-text text-danger"
                        *ngIf="!feeBand.get('minCommissionFee').valid && 
                                feeBand.get('minCommissionFee').touched">
                        <div *ngIf="feeBand.get('minCommissionFee').hasError('required')">Required input</div>
                      </small>
                    </div>
                  </div>

                  <div class="control-column d-flex align-items-center">
                    <p *ngIf="i === (feeBandsControls.length - 1)">
                      <a
                        href="javascript:;"
                        class="btn btn-icon btn-light btn-circle btn-xs mr-2"
                        (click)="onAddFeeBand(feeBand)">
                        <i class="fas fa-plus"></i>
                      </a>

                      <a
                        *ngIf="this.feeBandsControls.length > 1"
                        href="javascript:;"
                        class="btn btn-icon btn-light-danger btn-circle btn-xs"
                        (click)="onRemoveFeeBand()">
                        <i class="fas fa-minus"></i>
                      </a>
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>

  </div>

  <!-- <hr class="divider" /> -->
  <div class="row position-static">
    <div class="col-12 d-flex justify-content-between">
      <button
        *ngIf="stepper.selectedIndex === 0"
        type="button"
        class="btn btn-light"
        (click)="nextStep()">Next</button>

      <button
        *ngIf="stepper.selectedIndex === 1"
        type="button"
        class="btn btn-light"
        (click)="backStep()">Back</button>

      <button
        type="button"
        class="btn btn-primary"
        (click)="onSave()">Save</button>

    </div>
  </div>

</ng-container>
