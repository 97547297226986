import { RedemptionModule } from './../../../view/src/lib/redemptions/redemption.module';
import { CscsSecondLoginComponent } from 'projects/view/src/lib/user-management/kyc/components/cscs-second-login/cscs-second-login.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InstrumentListStateService } from 'projects/view/src/lib/instrument/instrument.state';
import { InstrumentService } from 'projects/view/src/lib/instrument/services/instrument.service';
import { OrderTxnService } from 'projects/view/src/lib/orders/services/orders.service';
import { AuthProvidersModule } from '../../../authentication/src/lib/auth-providers.module';
import { OrderTxnModule } from '../../../view/src/lib/orders/orders.module';
import { PaymentTxnModule } from '../../../view/src/lib/payment/payments.module';
import { PaginationModule } from '../../../_shared/Pagination/pagination.module';
import { SearchBarModule } from '../../../_shared/search-bar/search-bar.module';
import { UserComponentsModule } from './components/users-shared.module';
import { UserTransactionHistoryModule } from './sections/transactions/user-transactions.module';
import { BankStateService } from './sections/user-kyc-editor/bank-state.service';
import { UserKYCComponentsModule } from './sections/user-kyc-editor/components/user-kyc-shared.module';
import { KYCUtilService } from './sections/user-kyc-editor/kyc-util.service';
import { UserKycEditorComponent } from './sections/user-kyc-editor/user-kyc-editor.component';
import { UserKycStateService } from './sections/user-kyc-editor/user-kyc.state';
import { PortfolioComponentsModule } from './sections/user-portfolio/components/user-portfolio-overview/portfolio-components.module';
import { OpenOrderStateService } from './sections/user-portfolio/user-portfolio/user-open-order.state';
import { UserPortfolioComponent } from './sections/user-portfolio/user-portfolio/user-portfolio.component';
import { PortfolioStateService } from './sections/user-portfolio/user-portfolio/user-portfolio.state';
import { UserProvidersModule } from './services/users-providers.module';
import { KYCVerificationStateService } from './user-profile/kyc-verification.state';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserRoutesModule } from './user-routes.module';
import { UserPageListComponent } from './users-list/user-managements.component';
import { UserListStateService } from './users-list/users-list.state';
import { KycVerificationComponent } from '../../../view/src/lib/user-management/kyc/components/kyc-verification/kyc-verification.component';
import { AccountsModule } from './sections/accounts/accounts.module';
import { KycActionModalComponent } from 'projects/view/src/lib/user-management/kyc/components/kyc-action-modal/kyc-action-modal.component';
import { CscsDataFormComponent } from './sections/user-kyc-editor/cscs-data-form/cscs-data-form.component';
import { CountriesListStateService } from './sections/user-kyc-editor/countries-state';
import { NgxUploaderModule } from 'ngx-uploader';
import { RegisterComponent } from './sections/register/register.component';
import { AuthService } from '@console/authentication/providers/authentication.service';
import { ConsoleRouterService, SharedModule } from '@console/shared';
import { UserKYCService } from './services/user-kyc.service';
import { ReactiveFormsModule } from '@angular/forms';
import { InstrumentOrderComponent } from './sections/user-portfolio/instrument-order/instrument-order.component';
import { QuoteStateService } from './sections/user-portfolio/instrument-order/states/quote.state';
import { PreOrderStateService } from './sections/user-portfolio/instrument-order/states/pre-order.state';
import { OrderStateService } from 'projects/view/src/lib/orders/orders-edit/order.state';
import { InstrumentStateService } from '../../../view/src/lib/instrument/states/single-instrument--state.service';
import { InstrumentSharedModule } from '../../../view/src/lib/instrument/componenet/instrument-shared.module';
import { InstrumentGraphStateService } from 'projects/view/src/lib/instrument/states/graph-state.service';
import { CSCSStateService } from './sections/user-kyc-editor/cscs-data-form/cscs.state';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { DwAccountModule } from 'projects/view/src/lib/dw-account/dw-account.module';
import { MandateModule } from 'projects/view/src/lib/mandate/mandate.module';
import { ManageHVUComponent } from './sections/user-portfolio/components/manage-hvu/manage-hvu.component';
import { SuitabilityQuestionsModule } from 'projects/view/src/lib/suitability-questions/suitability-questions.module';
import { AdvisoryPortfolioModule } from 'projects/view/src/lib/advisory-portfolios/advisory-portfolio.module';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AdvisoryFeesModule } from 'projects/view/src/lib/advisory-fees/advisory-fees.module';

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    UserRoutesModule,
    UserComponentsModule,
    UserProvidersModule,
    UserKYCComponentsModule,
    PortfolioComponentsModule,
    SearchBarModule,
    PaginationModule,
    AuthProvidersModule,
    UserTransactionHistoryModule,
    PaymentTxnModule,
    OrderTxnModule,
    AccountsModule,
    NgxUploaderModule,
    ReactiveFormsModule,
    InstrumentSharedModule,
    PdfViewerModule,
    NgxSmartModalModule,
    DwAccountModule,
    SharedModule,
    MatSnackBarModule,
    RedemptionModule,
    MandateModule,
    SuitabilityQuestionsModule,
    AdvisoryFeesModule,
    AdvisoryPortfolioModule
  ],
  declarations: [
    UserPageListComponent,
    KycActionModalComponent,
    KycVerificationComponent,
    UserProfileComponent,
    UserKycEditorComponent,
    UserPortfolioComponent,
    CscsDataFormComponent,
    RegisterComponent,
    InstrumentOrderComponent,
    CscsSecondLoginComponent,
    ManageHVUComponent,
  ],
  providers: [
    UserListStateService,
    UserKycStateService,
    KYCVerificationStateService,
    PortfolioStateService,
    InstrumentService,
    InstrumentListStateService,
    InstrumentStateService,
    BankStateService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
      duration: 3800,
      horizontalPosition: 'right',
      verticalPosition:'top',
    }},
    KYCUtilService,
    OpenOrderStateService,
    OrderTxnService,
    CountriesListStateService,
    AuthService,
    ConsoleRouterService,
    UserKYCService,
    OrderStateService,
    PreOrderStateService,
    QuoteStateService,
    InstrumentGraphStateService,
    CSCSStateService,
  ],
  exports: [
    CommonModule,
    KycActionModalComponent,
    KycVerificationComponent,
    CscsSecondLoginComponent,
  ],
})
export class UserManagementModule {}
