import { Component, OnInit, Input } from '@angular/core';
import { ConsoleRouterService } from '@console/shared';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DwTransaction } from '../../transactions/services/transactions.model';
import { DwHistoryStateService } from './dw-history.state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dw-history',
  templateUrl: './dw-history-list.component.html',
  styleUrls: ['./dw-history-list.component.scss']
})
export class DwHistoryListComponent implements OnInit {
  @Input() userId: string;

  filter: Record<string, any> = {
    pageSize: 10,
  };

  dwHistoryId: string;
  dwTransaction: DwTransaction;

  constructor(
    public console: ConsoleRouterService,
    public router: Router,
    public dwHistoryList: DwHistoryStateService,
    public modal: NgxSmartModalService
  ) { }

  viewTransaction(transaction: DwTransaction) {
    // if (this.userId) {
    //   this.transactionId = payment.id;
    //   return this.modal.open('transactionModal');
    // }
    this.dwTransaction = transaction;
    this.dwHistoryId = transaction.finTranID;
    // this.console.productNavigate(['dw-history', transaction.finTranID]);
    this.router.navigate(['/dw-history', transaction.finTranID], { state: { data: { ...transaction } } });
  }

  query($event: Record<string, any>) {
    if (this.userId) {
      $event.userId = this.userId;
    }
    // check from and to dates, if present
    //TODO: Ideally use a date range picker component to avoid gymnastics
    // or not trigger query on every date point selection
    // if ($event.from && !$event.to) {
    //   var from_date = new Date($event.from);
    //   var new_date = from_date.setDate(from_date.getDate() + 7);
    //   $event.to = new Date(new_date).toISOString();
    // }
    // else if ($event.to && !$event.from) {
    //   var to_date = new Date($event.to);
    //   var new_date = to_date.setDate(to_date.getDate() + 7);
    //   $event.from = new Date(new_date).toISOString();
    // }
    this.dwHistoryList.find($event);
  }

  // downloadFile($event: Record<string, any>) {
  //   this.query({ ...$event, pageSize: 50 });
  //   this.dwHistoryList.state.pipe(skip(1), first()).subscribe((state) => {
  //     const name = fileNameFormatter(
  //       this.userId ? this.userId + '_DW_History' : 'Chaka_DW_History',
  //     );
  //     exportAndDownload(name, state.transactions);
  //   });
  // }

  ngOnInit(): void {
    if (this.dwHistoryList) {
      this.dwHistoryList.reset();
    }
    console.log('Do something');
    this.query(this.filter);
  }
}

