import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { ConsoleRouterService } from '../../../../../../../projects/shared/src/public-api';
import { BankSetting } from '../../services/settings.interface';
import { BankState, BankStateService } from './bank-state';

enum CrudState {
  Retrieve = 'Retrieve',
  Retrieved = 'Retrieved',
  Create = 'Create',
  Created = 'Created',
  Update = 'Update',
  Updated = 'Updated',
  Delete = 'Delete',
  Deleted = 'Deleted',
  Initial = 'Initial',
  Error = 'Error',
}

@Component({
  selector: 'app-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.scss'],
})
export class BankEditComponent implements OnInit {
  bankForm: FormGroup;
  hasFormErrors = false;
  localState: CrudState = CrudState.Initial;

  constructor(
    private activatedRoute: ActivatedRoute,
    private form: FormBuilder,
    public bankState: BankStateService,
    private consoleRouter: ConsoleRouterService
  ) {}

  toggleEditMode() {
    this.localState = CrudState.Update;
  }

  get isEditModeDisabled() {
    if (!this.bankForm) {
      return true;
    }
    const isDisabled = !(
      this.localState === CrudState.Create ||
      this.localState === CrudState.Update
    );
    if (isDisabled) {
      this.bankForm.disable();
    } else {
      this.bankForm.enable();
    }
    return isDisabled;
  }

  setupFormWithState(newsId?: number) {
    this.bankForm = this.createForm();
    if (!newsId) {
      this.localState = CrudState.Create;
      return;
    }
    this.bankState.retrieve(newsId);
    this.bankState.state
      .pipe(take(2))
      .subscribe(this.instanceFormWithData.bind(this));
  }

  private instanceFormWithData(state: BankState) {
    if (state.bank) {
      this.bankForm = this.createForm(state.bank);
      this.localState = CrudState.Retrieved;
      this.bankForm.disable();
    }
  }

  private createForm(bank: Partial<BankSetting> = {}) {
    return this.form.group({
      id: [bank.id],
      name: [bank.name, Validators.required],
      code: [bank.code, Validators.required],
      countryCode: [bank.countryCode, Validators.required],
    });
  }

  delete() {
    const { id } = this.bankForm.value;
    this.bankState.delete(id);
  }

  get bank$() {
    return this.bankState.state.pipe(map((state) => state.bank));
  }

  update() {
    this.bankState.update(this.bankForm.value);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];
      if (id) {
        this.bankState.retrieve(id);
        this.setupFormWithState(id);
      } else {
        this.consoleRouter.productNavigate(['banks']);
      }
    });
  }
}
