import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { ConsoleRouterService } from '@console/shared';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { isBoolean } from '@console/shared/utils/isBoolean';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppSettingStateService } from './app-setting.state';

enum ValueTypesEnum {
  TEXT = 'TEXT',
  SWITCH = 'SWITCH',
}

@Component({
  selector: 'app-setting-edit',
  templateUrl: './app-setting-edit.component.html',
  styleUrls: ['./app-setting-edit.component.scss'],
})
export class AppSettingEditComponent implements OnInit {
  // hasFormErrors = false;
  // localState: CrudState = CrudState.Initial;

  formMethods;
  settingForm: FormGroup;

  valueTypeControl: FormControl;
  valueTypesEnum = ValueTypesEnum;
  settingsSub$: Subscription;
  lastTextValue = '';
  lastSwitchValue = false;

  saving = false;
  deleting = false;

  @Output() saveSuccess = new EventEmitter();

  settingId: number;

  constructor(
    private appSettingState: AppSettingStateService,
    private activatedRoute: ActivatedRoute,
    private consoleRouter: ConsoleRouterService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeValueControl();

    this.retrieveSetting();
  }

  retrieveSetting(): void {
    this.activatedRoute.params.subscribe((params) => {
      const { id } = params;

      id && this.appSettingState.findById(id);

      this.listenOnState();
    });
  }

  initializeForm() {
    this.settingForm = new FormGroup({
      name: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
      settingsGroup: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      encrypted: new FormControl(false),
    });

    this.formMethods = formMethods(this.settingForm);
  }

  private initializeValueControl() {
    this.valueTypeControl = new FormControl(
      ValueTypesEnum.TEXT,
      Validators.required
    );

    this.valueTypeControl.valueChanges.subscribe((value: ValueTypesEnum) => {
      const valueControl = this.settingForm.get('value');

      switch (value) {
        case ValueTypesEnum.SWITCH:
          this.lastTextValue = valueControl.value;
          valueControl.removeValidators(Validators.required);
          valueControl.setValue(!!this.lastSwitchValue);
          break;

        default:
          this.lastSwitchValue = valueControl.value;

          valueControl.addValidators(Validators.required);
          valueControl.setValue(this.lastTextValue);
          break;
      }

      valueControl.updateValueAndValidity();
    });
  }

  private listenOnState() {
    this.settingsSub$ = this.appSettingState.state$
      .pipe(filter(({ setting }) => !!setting))
      .subscribe((state) => {
        const { setting } = state;

        const { value, id } = setting;

        this.settingForm.addControl('id', new FormControl(id));

        this.settingId = id;

        isBoolean(value)
          ? this.valueTypeControl.setValue(ValueTypesEnum.SWITCH)
          : this.valueTypeControl.setValue(ValueTypesEnum.TEXT);

        this.settingForm.patchValue({
          ...setting,
          value: isBoolean(value) ? (value == 'true' ? true : false) : value,
        });
      });
  }

  saveSetting() {
    if (!this.settingForm.valid) {
      this.settingForm.markAllAsTouched();
      return;
    }

    const { value } = this.settingForm;

    this.saving = true;

    this.appSettingState.saveSetting(value, (success) => {
      this.saving = false;
    });
  }

  goBack() {
    this.consoleRouter.goBack();
  }
}
