import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessagesEnum } from './enums/messages.enums';
import { IMessage } from './interface/IMessage';

interface IMessageOptions {
  id?: number;
  hideAll?: boolean;
  timeOut?: number;
  callBack?: Function;
}
@Injectable({ providedIn: 'root' })
export class MessagesService {
  private _messages: IMessage[] = [];
  private subject = new BehaviorSubject<IMessage[]>(this._messages);

  getUUid = (() => {
    let id = 0;

    return () => {
      ++id;
      const ids = this._messages.map(({ id }) => id);
      return ids.includes(id) ? this.getUUid() : id;
    };
  })();

  messages$: Observable<IMessage[]> = this.subject
    .asObservable()
    .pipe(filter((messages) => !!messages.length));

  open(type: MessagesEnum, msg: string, options?: IMessageOptions): IMessage {
    const message = this.set(type, msg, options);
    this.show(message.id);

    return message;
  }

  set(type: MessagesEnum, msg: string, options?: IMessageOptions): IMessage {
    const id = this.getUUid();

    const message = {
      id,
      type,
      message: msg,
      created: new Date(),
      updated: new Date(),
      isOpened: false,
    };

    this._messages.push(message);

    if (options) {
      options.id = id;
    }

    this.emitMessages(options);

    return message;
  }

  // for now, if you must use options while update, please provide the message id
  update(changes: Partial<IMessage>, options?: IMessageOptions): IMessage {
    const messages = this.subject.getValue();

    const messageId = changes.id || null;
    // if the message id is passed with the changes update it, else we update the last set/opened;
    const index = messageId
      ? messages.findIndex(({ id }) => id === messageId)
      : messages.length - 1;

    const newMessage: IMessage = {
      ...messages[index],
      ...changes,
      updated: new Date(),
    };

    this._messages = messages.slice(0);
    this._messages[index] = newMessage;

    if (options) {
      options.id = newMessage.id;
    }

    this.emitMessages(options);

    return newMessage;
  }

  show(messageId: number): IMessage {
    const index = this._messages.findIndex(({ id }) => id === messageId);
    this._messages[index].isOpened = true;

    this.emitMessages();

    return this._messages[index];
  }

  hide(messageId: number): IMessage {
    const index = this._messages.findIndex(({ id }) => id === messageId);

    this._messages[index].isOpened = false;

    this.emitMessages();

    return this._messages[index];
  }

  showAll(): IMessage[] {
    this._messages = this._messages.map((message) => ({
      ...message,
      isOpened: true,
    }));

    this.emitMessages();

    return this._messages;
  }

  hideAll(): IMessage[] {
    if (!this._messages.length) {
      return;
    }

    this._messages = this._messages.map((message) => ({
      ...message,
      isOpened: false,
    }));

    this.emitMessages();

    return this._messages;
  }

  get totalOpened(): number {
    const messages = this._messages.slice();
    const count = messages.filter(({ isOpened }) => isOpened)?.length || 0;

    return count;
  }

  isOpened(messageId: number): boolean {
    return this._messages.slice()[messageId].isOpened;
  }

  private emitMessages(options?: IMessageOptions): void {
    // a copy of the object   ---avoid object access by reference troubles
    const messages = this._messages.slice();

    options?.hideAll && this.hideAll();
    options?.timeOut &&
      setTimeout(() => this.hide(options.id), options?.timeOut);
    options?.callBack && options?.callBack();

    this.subject.next(messages);
  }
}
