<section-search-header [section]="'Rejection Email Settings'" (actionBtn)="openDialog()"
  [actionText]="'Add Rejection Reason'"></section-search-header>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="table-responsive" [hidden]="(dataSource && dataSource.length <= 0)">

        <div class="table-container">
          <table class="table table-head-custom table-head-bg table-vertical-center table-borderless" mat-table
            [dataSource]="dataSource">

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-dark-75">Status</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-dark-75 d-block font-size-lg">{{row?.mappedStatus}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sendgridTemplateId">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-dark-75">Send Grid Template ID</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-dark-75 d-block font-size-lg">
                  {{row.sendgridTemplateId.length > 20 ? (row.sendgridTemplateId | slice:0:19) +
                    '...' : row.sendgridTemplateId}}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="reasonForRejection">
              <th mat-header-cell *matHeaderCellDef>
                <span class="text-dark-75">Rejection Reason</span>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="text-dark-75 d-block font-size-lg">
                  {{
                    row.reasonForRejection.length > 20 ? (row.reasonForRejection | slice:0:19) +
                      '...' : row.reasonForRejection
                   }}
                  </span>
              </td>
            </ng-container>


            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                  <span class="text-dark-75">Action</span>
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                  <span style="overflow: visible; position: relative; width: 125px;">
                    <a href="javascript:;" (click)="openDialog(row)" routerLinkActive="router-link-active"  class="btn btn-sm btn-clean btn-icon mr-2" title="Edit">
                      <span class="svg-icon svg-icon-md">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                            <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                          </g>
                        </svg>
                      </span>
                    </a>

                    <a href="javascript:;" (click)="deleteRejectionReason(row.id)" class="btn btn-sm btn-clean btn-icon" title="Delete">
                      <span class="svg-icon svg-icon-md"> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
                            <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </span>
                </td>
              </ng-container>

            <thead>
              <tr class="bg-gray-100 text-left" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            </thead>

            <tbody>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </tbody>
          </table>
        </div>

        <mat-paginator class="mat-paginator-sticky" [length]="count" [pageSizeOptions]="[10, 50, 100, 150, 200]"
          (page)="pageEvent=$event; paginate($event)" showFirstLastButtons></mat-paginator>

      </div>

      <div *ngIf="dataSource.length <= 0 && !loading"
        class="no-data-container d-flex flex-column justify-content-center align-items-center">
        <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
        <p class="no-data-text">No data</p>
      </div>
    </div>

  </div>
</div>
