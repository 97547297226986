import { Routes } from '@angular/router';
import { UserPageListComponent } from '@console/user-managements';
import { RegisterComponent } from '@console/user-managements/sections/register/register.component';
import { UserProfileComponent } from '@console/user-managements/user-profile/user-profile.component';
import { AnalyticsComponent } from 'projects/analytics/src/public-api';
import { AccountManagerComponent } from 'projects/view/src/lib/account-manager/account-manager.component';
import { AccountManagerUsersComponent } from 'projects/view/src/lib/account-manager/components/account-manager-users/account-manager-users.component';
import { AdvisoryFeesComponent } from 'projects/view/src/lib/advisory-fees/advisory-fees.component';
import { AdvisoryPortfolioDetailsComponent } from 'projects/view/src/lib/advisory-portfolios/components/advisory-portfolio-details/advisory-portfolio-details.component';
import { AuditLogComponent } from 'projects/view/src/lib/audit-log/audit-log.component';
import { AutoRebalanceDetailsComponent } from 'projects/view/src/lib/auto-rebalance/components/auto-rebalance-details/auto-rebalance-details.component';
import { ChakaAccountsComponent } from 'projects/view/src/lib/chaka-accounts/chaka-accounts.component';
import { CscsReportComponent } from 'projects/view/src/lib/cscs-report/cscs-report.component';
import { DwAccountComponent } from 'projects/view/src/lib/dw-account/dw-account.component';
import { DwHistoryListComponent } from 'projects/view/src/lib/dw-history/dw-history-list/dw-history-list.component';
import { ExchangesEditComponent } from 'projects/view/src/lib/exchanges/exchanges-edit/exchanges-edit.component';
import { MandateDetailsComponent } from 'projects/view/src/lib/mandate/components/mandate-details/mandate-details.component';
import { MandateListComponent } from 'projects/view/src/lib/mandate/mandate-list.component';
import { MerchantFeeSettingsComponent } from 'projects/view/src/lib/merchants/components/merchant-fees/components/fee-settings.component';
import { MerchantFeesComponent } from 'projects/view/src/lib/merchants/components/merchant-fees/merchant-fee.component';
import { OrdersEditComponent } from 'projects/view/src/lib/orders/orders-edit/orders-edit.component';
import { PaymentComponent } from 'projects/view/src/lib/payment/payment-list/payment.component';
import { PaymentsEditComponent } from 'projects/view/src/lib/payment/payments-edit/payments-edit.component';
import { RedemptionDetailsComponent } from 'projects/view/src/lib/redemptions/components/redemption-details/redemption-details.component';
import { RedemptionListComponent } from 'projects/view/src/lib/redemptions/redemption-list.component';
import { ReferralSettingsComponent } from 'projects/view/src/lib/referral-settings/referral-settings.component';
import { RolesComponent } from 'projects/view/src/lib/roles/roles.component';
import { AppSettingEditComponent } from 'projects/view/src/lib/settings/application-setting/app-setting-edit/app-setting-edit.component';
import { ApplicationSettingComponent } from 'projects/view/src/lib/settings/application-setting/application-setting.component';
import { ChannelEditComponent } from 'projects/view/src/lib/settings/channels/channel-edit/channel-edit.component';
import { ChannelsComponent } from 'projects/view/src/lib/settings/channels/channels.component';
import { SupportedDocumentsComponent } from 'projects/view/src/lib/supported-documents/supported-documents.component';
import { TransactionsEditComponent } from 'projects/view/src/lib/transactions/transaction-edit/transactions-edit.component';
import { AuthUsersComponent } from 'projects/view/src/lib/user-management/kyc/components/auth-users/auth-users.component';
import { KycComponent } from 'projects/view/src/lib/user-management/kyc/kyc.component';
import { WithdrawalEditComponent } from 'projects/view/src/lib/withdrawals/withdrawals-edit/withdrawals-edit.component';
import { WithdrawalsComponent } from 'projects/view/src/lib/withdrawals/withdrawals.component';
import { ExchangeRateEditComponent } from '../../../projects/view/src/lib/exchange-rates/exchange-rates-edit/exchange-rates-edit.component';
import { ExchangeRatesComponent } from '../../../projects/view/src/lib/exchange-rates/exchange-rates.component';
import { ExchangesComponent } from '../../../projects/view/src/lib/exchanges/exchanges.component';
import { MerchantDetailsComponent } from '../../../projects/view/src/lib/merchants/merchant-details/merchant-details.component';
import { MerchantListComponent } from '../../../projects/view/src/lib/merchants/merchant-list/merchant-list.component';
import { OrdersComponent } from '../../../projects/view/src/lib/orders/orders-list/orders.component';
import { ReconciliationsComponent } from '../../../projects/view/src/lib/reconciliations/reconciliations.component';
import { RolesEditorComponent } from '../../../projects/view/src/lib/roles/role-edit/roles-edit.component';
import { TransactionsComponent } from '../../../projects/view/src/lib/transactions/transactions-list/transactions.component';
import { AdvisoryPortfolioListComponent } from './../../../projects/view/src/lib/advisory-portfolios/advisory-portfolio-list.component';
import { AutoRebalanceListComponent } from './../../../projects/view/src/lib/auto-rebalance/auto-rebalance-list.component';
import { SuitabilityQuestionsComponent } from './../../../projects/view/src/lib/suitability-questions/suitability-questions.component';

export const advisoryRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: AnalyticsComponent,
  },
  {
    path: 'users',
    component: UserPageListComponent,
  },

  {
    path: 'new-users',
    component: RegisterComponent,
  },

  {
    path: 'payments',
    component: PaymentComponent,
  },
  {
    path: 'payments/:id',
    component: PaymentsEditComponent,
  },
  {
    path: 'kyc',
    component: KycComponent,
  },
  {
    path: 'client-list',
    component: AuthUsersComponent,
  },
  {
    path: 'cscs-report',
    component: CscsReportComponent,
  },
  {
    path: 'users/:id',
    component: UserProfileComponent,
  },
  {
    path: 'withdrawals',
    component: WithdrawalsComponent,
  },
  {
    path: 'withdrawals/:id',
    component: WithdrawalEditComponent,
  },
  // {
  //   path: 'tags',
  //   component: TagsComponent,
  // },
  // {
  //   path: 'tags/:id',
  //   component: TagEditComponent,
  // },
  {
    path: 'reconcillations',
    component: ReconciliationsComponent,
  },
  {
    path: 'transaction',
    component: TransactionsComponent,
  },
  {
    path: 'transaction/:id',
    component: TransactionsEditComponent,
  },
  {
    path: 'exchanges',
    component: ExchangesComponent,
  },
  {
    path: 'exchanges/:id',
    component: ExchangesEditComponent,
  },
  {
    path: 'orders',
    component: OrdersComponent,
  },
  {
    path: 'orders/:id',
    component: OrdersEditComponent,
  },
  {
    path: 'fees',
    component: AdvisoryFeesComponent,
  },
  {
    path: 'rates',
    component: ExchangeRatesComponent,
  },
  {
    path: 'rates/:id',
    component: ExchangeRateEditComponent,
  },
  {
    path: 'merchant',
    component: MerchantListComponent,
    children: [
      { path: 'create', component: MerchantDetailsComponent },
      { path: ':id', component: MerchantDetailsComponent },
    ],
  },

  {
    path: 'roles',
    component: RolesComponent,
  },
  {
    path: 'roles/create',
    component: RolesEditorComponent,
  },
  {
    path: 'roles/:id',
    component: RolesEditorComponent,
  },
  {
    path: 'suitability-questions',
    component: SuitabilityQuestionsComponent,
  },
  {
    path: 'redemptions',
    component: RedemptionListComponent,
  },
  {
    path: 'redemptions/:id',
    component: RedemptionDetailsComponent,
  },
  {
    path: 'auto-rebalance',
    component: AutoRebalanceListComponent,
  },
  {
    path: 'auto-rebalance/:id',
    component: AutoRebalanceDetailsComponent,
  },
  {
    path: 'mandate',
    component: MandateListComponent,
  },
  {
    path: 'mandate/:id',
    component: MandateDetailsComponent,
  },
  {
    path: 'chaka-accounts',
    component: ChakaAccountsComponent,
  },
  {
    path: 'referral-settings',
    component: ReferralSettingsComponent,
  },
  {
    path: 'account-manager',
    component: AccountManagerComponent,
  },
  {
    path: 'supported-documents',
    component: SupportedDocumentsComponent,
  },
  {
    path: 'account-manager/:id',
    component: AccountManagerUsersComponent,
  },
  {
    path: 'dw-history',
    component: DwHistoryListComponent,
  },
  {
    path: 'audit-log',
    component: AuditLogComponent,
  },
  {
    path: 'channels',
    component: ChannelsComponent,
    children: [
      { path: 'create', component: ChannelEditComponent },
      { path: ':id', component: ChannelEditComponent },
    ],
  },
  {
    path: 'portfolios',
    component: AdvisoryPortfolioListComponent,
  },
  {
    path: 'portfolios/:id',
    component: AdvisoryPortfolioDetailsComponent,
  },
  {
    path: 'settings',
    component: ApplicationSettingComponent,
    children: [
      { path: 'create', component: AppSettingEditComponent },
      { path: ':id', component: AppSettingEditComponent },
    ],
  },
  {
    path: 'global-account',
    component: DwAccountComponent,
  },
];
