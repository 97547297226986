import { formatToCurrency } from './../../../../shared/src/lib/utils/formatter';
import { Input } from '@angular/core';
import { DwAccountStateService } from './dw-account.state';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dw-account',
  templateUrl: './dw-account.component.html',
  styleUrls: ['./dw-account.component.scss']
})
export class DwAccountComponent implements OnInit {

  @Input()
  userId = '';

  formatToCurrency = formatToCurrency;

  constructor(
    public dwAccountState: DwAccountStateService
  ) { }

  ngOnInit(): void {
    this.dwAccountState.retrieve(this.userId);
  }

}
