import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { EmailSettingsService } from '../../../services/email-settings.service';
import { EmailSetting } from '../../../services/settings.interface';

@Component({
  selector: 'app-edit-rejection-reason',
  templateUrl: './edit-rejection-reason.component.html',
  styleUrls: ['./edit-rejection-reason.component.scss'],
  providers: [
    MessagesService,
    LoadingService
  ]
})
export class EditRejectionReasonComponent implements OnInit {

  title = '';

  rejectionReason: EmailSetting;

  rejectionReasonForm: FormGroup;

  statusList = ['REJECTED_PHOTO_ID', 'REJECTED_ADDRESS_PROOF'];

  constructor(
    private dialogRef: MatDialogRef<EditRejectionReasonComponent>,
    @Inject(MAT_DIALOG_DATA) componentData: { rejectionReason: EmailSetting },
    private emailSettingsService: EmailSettingsService,
    private messagesService: MessagesService,
  ) { 
    const { rejectionReason } = componentData;

    this.title = `${rejectionReason?.id ? 'Update' : 'Add'} Rejection Reason`;

    this.rejectionReason = rejectionReason;
  }

  ngOnInit(): void {
    this.setupForm();
  }
  
  private setupForm(): void {
    this.rejectionReasonForm = new FormGroup({
      reasonForRejection: new FormControl(this.rejectionReason?.reasonForRejection || '', Validators.required),
      sendgridTemplateId: new FormControl(this.rejectionReason?.sendgridTemplateId || '', Validators.required),
      status: new FormControl(this.rejectionReason?.mappedStatus || '', Validators.required)
    });

    !!this.rejectionReason?.id && this.rejectionReasonForm.addControl('id', new FormControl(this.rejectionReason?.id));

    // this.formMethods = formMethods(this.referralPolicyForm);
  }

  save(): void {
    if (!this.rejectionReasonForm.valid) {
      this.rejectionReasonForm.markAllAsTouched();
      return;
    }

    const { value } = this.rejectionReasonForm;
    const message = `${value.id ? 'Updating' : 'Saving'} rejection reason...`;

    this.messagesService.open(
      MessagesEnum.loading,
      message,
      { hideAll: true }
    );

    this.emailSettingsService
      .saveRejectionReason(value)
      .subscribe({
        next: () => {
          this.close(true);
        },
        error: (error: ChakaAPIError) => {
          const errorMessage = cleanChakaAPIError(error);

          this.messagesService.update(
            {
              type: MessagesEnum.danger,
              message: errorMessage
            }
          );
        }
      });
  }
  
  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }
}
