import { Injectable } from '@angular/core';

@Injectable()
export class APICONFIG {
  authURL: string;
  enterpriseURL: string;
  authKey: string;
}

export const TEST_CONFIG: APICONFIG = {
  authURL: '',
  enterpriseURL: '',
  authKey: '',
}
