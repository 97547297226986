import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessagesService } from '../components/messages/messages.service';
import { setProductTypeOnStorage } from '../utils/productFromStorage';
import { PRODUCT_TYPE_ENUM } from '../_enums';

@Injectable({
  providedIn: 'root',
})
export class ConsoleRouterService {
  /**
   * contains the app routes history
   *
   */
  private history = [];

  private currentRoute = new BehaviorSubject<string>('');

  currentRoute$: Observable<string> = this.currentRoute.asObservable();

  constructor(
    private router: Router,
    private messagesService: MessagesService
  ) {}

  /**
   * navigate relative to the current product.
   */
  public productNavigate(
    subRoute: (string | number)[],
    extras?: NavigationExtras
  ): void {
    this.messagesService.hideAll();

    this.router.navigate([this.product, ...subRoute], extras);
  }

  public async changeProduct(product: PRODUCT_TYPE_ENUM) {
    let routePaths = [...this.router.url.split('/')];

    routePaths.splice(0, 2); // we remove the first two i.e '' and current product

    const allowedSubRoute = ['users', 'news'];

    if (routePaths.length > 1 && !allowedSubRoute.includes(routePaths[0])) {
      routePaths = [routePaths[0]];
    }

    const isNavigated = await this.router.navigate([product, ...routePaths]);

    if (isNavigated) {
      setProductTypeOnStorage(product);

      window.requestAnimationFrame(() => {
        location.reload();
      });
    }
  }

  /**
   * returns the current product i.e equity or advisory
   */
  public get product(): string {
    return window.location.href.includes(PRODUCT_TYPE_ENUM.advisory)
      ? PRODUCT_TYPE_ENUM.advisory
      : PRODUCT_TYPE_ENUM.equity;
  }

  public isDisabledIn(products: string[]): boolean {
    return !products.includes(this.product);
  }

  /**
   * it listens on events and add url to its histories
   */
  public loadRouting(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
        this.currentRoute.next(urlAfterRedirects);
      });
  }

  public goBack(): void {
    window.history.back();
    // this.router.navigateByUrl(this.getPreviousUrl());
  }

  /**
   * it returns all the url histories
   */
  public getHistory(): string[] {
    return this.history;
  }

  /**
   * returns the previous url
   */
  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] ?? '';
  }

  /**
   * current location of the application
   */
  public currentUrl(): string {
    return this.history[this.history.length - 1] || '';
  }

  // tslint:disable-next-line: typedef
  public clear() {
    this.history = [];
  }
}
