import { AutoRebalanceDetailsComponent } from './components/auto-rebalance-details/auto-rebalance-details.component';
import { AutoRebalanceCreateComponent } from './components/auto-rebalance-create/auto-rebalance-create.component';
import { AutoRebalanceListStateService } from './state/auto-rebalance-list-state.service';
import { AutoRebalanceDetailsStateService } from './state/auto-rebalance-details-state.service';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoRebalanceService } from './services/auto-rebalance.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/src/public-api';
import { AutoRebalanceListComponent } from './auto-rebalance-list.component';

@NgModule({
  declarations: [
    AutoRebalanceListComponent,
    AutoRebalanceDetailsComponent,
    AutoRebalanceCreateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    AutoRebalanceService,
    AutoRebalanceListStateService,
    AutoRebalanceDetailsStateService
  ]

})
export class AutoRebalanceModule { }
