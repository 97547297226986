<!--begin::Search Form-->
<div class="mb-7">
  <div class="row align-items-center">
    <div class="col-lg-12">
      <form [formGroup]="searchForm">
        <div class="row align-items-center">
          <div class="col-md-3">
            <label>Search all feeds</label>
            <input
              type="text"
              class="form-control"
              [formControlName]="authUsers ? 'q' : 'search'">
          </div>

          <div *ngIf="!authUsers" class="col-md-3">
            <label>Country</label>
            <ng-select formControlName="country" [clearable]="false">
              <ng-option
                [value]="country.value"
                *ngFor="let country of kycCountry">
                {{country.name}}
              </ng-option>
            </ng-select>
          </div>

          <div *ngIf="!authUsers" class="col-md-3">
            <label>Status</label>
            <ng-select formControlName="status" [clearable]="false">
              <ng-option [value]="status.value" *ngFor="let status of kycStatus">
                {{ status.name }}
              </ng-option>
            </ng-select>
          </div>

          <div
            class="col-md-3"
            *ngIf="isSuperAdmin">
            <label>Merchant</label>
            <ng-select
              formControlName="merchantId"
              [clearable]="false"
              [virtualScroll]="true"
              [loading]="this.merchants?.loading"
              (scrollToEnd)="loadMoreMerchant()">
              <ng-option value="">All</ng-option>
              <ng-option
                [value]="merchant?.id"
                *ngFor="let merchant of merchants?.list">
                {{ merchant?.name }}
              </ng-option>
            </ng-select>
          </div>

          <div *ngIf="!authUsers" class="col-md-3">
            <label>From</label>
            <input class="form-control" type="date" formControlName="from">
          </div>

          <div *ngIf="!authUsers" class="col-md-3">
            <label>To</label>
            <input class="form-control" type="date" formControlName="to">
          </div>

          <div *ngIf="!authUsers" class="col-md-2">

            <label class="d-block">&nbsp;</label>
            <div class="checkbox-list">
              <label class="checkbox">
                <input type="checkbox" formControlName="isHvu">
                <span></span>HVU Users</label>
            </div>
          </div>

          <div class="col-md-2">
            <label class="d-block">&nbsp;</label>
            <a
              type="button"
              href="javascript:;"
              class="btn btn-light-primary px-6 font-weight-bold"
              (click)="handleQuery()">Search</a>
          </div>

        </div>
      </form>

    </div>
  </div>

</div>
<!--end::Search Form-->

<!--begin: Datatable-->

<div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

  <div class="mt-5 mb-5" *ngIf="!authUsers">
    <div class="d-flex align-items-center">
      <div class="font-weight-bold">
        Selected {{selectedItems.size}} {{selectedItems.size<=1 ?'record': 'records'}}:

        <button [disabled]="selectedItems.size <= 0" (click)="downloadFile()" class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
          <i class="far fa-arrow-alt-circle-down"></i> Download
        </button>
      </div>
    </div>
  </div>


  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matMultiSort>

      <ng-container matColumnDef="check" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single checkbox-all">
              <input
                (change)="onAllChecked($event.target.checked)"
                type="checkbox"
                [checked]="allSelected">&nbsp;<span></span>
            </label>
          </span>
        </th>

        <td
          mat-cell
          *matCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single">
              <input
                type="checkbox"
                (change)="onItemChecked($event.target.checked, row)"
                [checked]="selectedItems.has(formedUniqueId(row))">&nbsp;<span></span>
            </label>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="firstName"> Full Name </th>
        <td mat-cell *matCellDef="let row">
          <a
            *ngIf="row?.userId else noUser"
            href="javascript:void(0)"
            (click)="viewUser(row?.userId)">
            {{ row?.firstName || '-' |titlecase  }} {{ row?.lastName || '-' |titlecase  }}</a>

          <ng-template #noUser>{{ row?.firstName || '-' |titlecase  }} {{ row?.lastName || '-' |titlecase  }}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="email"> Email </th>
        <td mat-cell *matCellDef="let row">{{ row?.email || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let row">{{ row?.phone || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef> Country </th>
        <td mat-cell *matCellDef="let row">{{ row?.country  }}</td>
      </ng-container>

      <ng-container matColumnDef="chakaId">
        <th mat-header-cell *matHeaderCellDef> Chaka ID </th>
        <td mat-cell *matCellDef="let row">{{ row?.chakaId }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let row">{{ row?.status || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="dateCreated"> Date Created </th>
        <td mat-cell *matCellDef="let row">
          {{row?.dateCreated | date: 'medium' || "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="dateModified">
        <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="dateModified"> Date Updated </th>
        <td mat-cell *matCellDef="let row">
          {{row?.dateModified | date: 'medium' || "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row">
          <a
            href="javascript:;"
            (click)="toggleAccountLock(row?.accountLocked, row?.userId)">
            <i class="fas"
              [ngClass]="row?.accountLocked ? 'fa-lock-open':'fa-lock'"></i>
            {{row?.accountLocked ? 'Unlock ' : 'Lock '}} Account</a>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
        *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>

  <mat-paginator
    class="mat-paginator-sticky"
    [length]="count"
    [pageSizeOptions]="[10, 50, 100, 150, 200]"
    (page)="pageEvent=$event; paginate($event)"
    showFirstLastButtons></mat-paginator>
</div>

<div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
  <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
  <p class="no-data-text">No data</p>
</div>

<!--end: Datatable-->
