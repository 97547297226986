
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { debounceCall } from '@console/shared/utils/debounceCall';
import { UserListStateService } from '@console/user-managements';
import { MessagesEnum } from '../../../../../../shared/src/lib/components/messages/enums/messages.enums';
import { MessagesService } from '../../../../../../shared/src/lib/components/messages/messages.service';
import { AccountManagerService } from '../../service/account-manager.service';



@Component({
  templateUrl: './account-manager-create.component.html',
  styleUrls: ['./account-manager-create.component.scss'],
  providers: [
    MessagesService,
    UserListStateService,
    LoadingService,
  ]
})

export class CreateAccountManagerComponent {

  constructor(
    private dialogRef: MatDialogRef<CreateAccountManagerComponent>,
    private accountManagerService: AccountManagerService,
    private messagesService: MessagesService,
    public userList$: UserListStateService
  ) { }
  title = 'Add Account Manager';

  selectedUser: {
    id: number,
    userId: string,
    fullName: string,
    profileImageUrl: string,
    email: string,
    firstName: string,
    lastName: string
  } = null;

  userControl: FormControl = new FormControl();

  debounceSearchUser = debounceCall(function(event) {
    const { value } = event.target;

    if (value.length > 2) {
      this.userList$.find({ q: value, pageSize: 10 });
    }

  }, 1000);

  assignUser($event): void {
    if (!$event) {
      return;
    }

    const {
      id,
      userId,
      email,
      fullName,
      profileImageUrl,
      firstName,
      lastName
    } = $event;

    this.selectedUser = {
      id,
      userId,
      email,
      fullName,
      profileImageUrl,
      firstName,
      lastName
    };

    this.userControl.reset();
  }

  deleteUser(): void {
    this.selectedUser = null;
  }



  save(): void {

    if (!this.selectedUser) {
      this.messagesService.open(MessagesEnum.danger, 'Please select a user', {
        timeOut: 3000,
        hideAll: true
      });

      return;
    }

    const message = `Creating account manager...`;

    this.messagesService.open(
      MessagesEnum.loading,
      message,
      { hideAll: true }
    );

    const dataToSubmit = {
      enabled: true,
      name: this.selectedUser.fullName,
      userId: this.selectedUser.userId
    };

    this.accountManagerService
      .saveAccountManager(dataToSubmit)
      .subscribe({
        next: () => {
          this.close(true);
        },
        error: (error: ChakaAPIError) => {
          const errorMessage = cleanChakaAPIError(error);

          this.messagesService.update(
            {
              type: MessagesEnum.danger,
              message: errorMessage
            }
          );
        }
      });
  }

  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }
}

