import { LearnContentGridComponent } from './learn-content-grid/learn-content-grid.component';
import { LearnContentCardComponent } from './learn-content-card/learn-content-card.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [
    LearnContentGridComponent,
    LearnContentCardComponent

  ],
  imports: [
    CommonModule
  ],
  exports: [LearnContentGridComponent
, LearnContentCardComponent
,RouterModule],
})
export class LearnSharedModule {}
