import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { NotificationService } from '@console/shared/services/notification-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApiService } from '../../../../../../api/src/lib/base-api.service';
import { CURRENCY } from '../../../../../../shared/src/lib/_enums/currency.enum';
import { WALLET_TYPES } from '../../../../../../shared/src/lib/_enums/wallet-types.enum';
import { VirtualAccountService } from '../providers/virtual-account.service';
import { VirtualAccount, Wallet } from '../providers/wallet.interface';
import { WalletService } from '../providers/wallet.service';
import { WALLET_PROVIDERS } from './../../../../../../shared/src/lib/_enums/wallet-providers.enum';

export interface Portfoliotate {
  wallets: Wallet[];
  loading: boolean;
  error?: string;
}

const initialState: Portfoliotate = { loading: false, wallets: [] };

@Injectable({ providedIn: 'root' })
export class WalletListStateService {
  state = new BehaviorSubject<Portfoliotate>(initialState);

  spinnerName = 'Wallet-List';

  constructor(
    private wallet: WalletService,
    private spinner: NgxSpinnerService,
    private base: BaseApiService,
    private notificationService: NotificationService,
    private virtualAccountService: VirtualAccountService
  ) {}

  retrieve(userId: string) {
    this.loading();
    this.wallet.wallets(userId).subscribe({
      next: this.onUserPortfolioLoaded.bind(this),
      error: this.onUserPortfolioError.bind(this),
    });
  }

  createWallet(userId: string, walletTypes: WALLET_TYPES[]) {
    this.loading();
    this.wallet.create({ userId, walletTypes }).subscribe({
      next: () => {
        this.onUserPortfolioLoaded.bind(this);
        this.notificationService.success('Wallet was created successfully.');
      },
      error: this.onUserPortfolioError.bind(this),
    });
  }

  createVirtualAccount(userId: string): void {
    this.loading();
    this.virtualAccountService.create(userId).subscribe({
      next: () => {
        this.spinner.hide(this.spinnerName);
        this.notificationService.success(
          'Virtual account was created successfully.'
        );

        this.retrieve(userId);
      },
      error: this.onUserPortfolioError.bind(this),
    });
  }

  clearPendingCash(userId: string, provider: WALLET_PROVIDERS) {
    this.loading();
    this.wallet.clearPendingCash(userId, provider).subscribe({
      next: () => {
        this.spinner.hide(this.spinnerName);
        this.notificationService.success('Pending cash cleared successfully.');

        this.retrieve(userId);
      },
      error: this.onUserPortfolioError.bind(this),
    });
  }

  select$(equityType: CURRENCY | string) {
    return this.state.pipe(
      map((state) => {
        return state.wallets.find((wallet) => wallet.currency === equityType);
      })
    );
  }

  fetchVirtualAccounts(
    walletId: number
  ): Observable<ReqSuccessResponse<VirtualAccount[]>> {
    return this.wallet.fetchVirtualAccounts(walletId).pipe(
      catchError((error: ChakaAPIError) => {
        this.onUserPortfolioError.bind(this, error);

        return throwError(error);
      })
    );
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(): void {
    this.state.next({ ...this.state.getValue(), loading: true });
    this.spinner.show(this.spinnerName);
  }

  private onUserPortfolioLoaded({ data }: ReqSuccessResponse<Wallet[]>): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      wallets: data.filter(
        (wallet) => wallet.productType === this.base.getProductType()
      ),
    });

    this.spinner.hide(this.spinnerName);
  }

  private onUserPortfolioError(res: ChakaAPIError): void {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.error(error);
  }
}
