import * as Papa from 'papaparse';

export function csvExport<T>(data: T[]) {
  return Papa.unparse(JSON.stringify(data), {
    quotes: false,
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
    skipEmptyLines: false,
    columns: null
  });
}

export function downloadFile(filename: string, data: string) {
  const hiddenElement = document.createElement('a');

  hiddenElement.href = 'data:attachment/text,' + encodeURI(data);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${filename}.csv`;
  hiddenElement.click();
}

export function downloadFileFromLink(filename: string, link: string): void {
  const hiddenElement = document.createElement('a');

  hiddenElement.href = encodeURI(link);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${filename}.csv`;
  hiddenElement.click();
}

export function exportAndDownload<T>(name: string, data: T[]) {
  downloadFile(name, csvExport(data));
}

export function fileNameFormatter(name: string, from?: string, to?: string) {
  return `${name}_${from || ''}_${to || ''}`;
}
