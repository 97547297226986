<div class="card card-custom gutter-b">
  <div class="card-body">
    <div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

      <div class="mt-5 mb-5">
        <div class="d-flex align-items-center">
          <div class="font-weight-bold">
            Selected {{selectedItems.size}} {{selectedItems.size<=1 ?'record': 'records'}}:

            <button [disabled]="selectedItems.size <= 0" (click)="downloadFile()" class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
              <i class="far fa-arrow-alt-circle-down"></i> Download
            </button>
          </div>
        </div>
      </div>


      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matMultiSort>

          <ng-container matColumnDef="check" sticky>
            <th
              mat-header-cell
              *matHeaderCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single checkbox-all">
                  <input
                    (change)="onAllChecked($event.target.checked)"
                    type="checkbox"
                    [checked]="allSelected">&nbsp;<span></span>
                </label>
              </span>
            </th>

            <td
              mat-cell
              *matCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single">
                  <input
                    type="checkbox"
                    (change)="onItemChecked($event.target.checked, row)"
                    [checked]="selectedItems.has(row.id)">&nbsp;<span></span>
                </label>
              </span>
            </td>
          </ng-container>


          <ng-container matColumnDef="photo">
            <th mat-header-cell *matHeaderCellDef> Photo </th>
            <td mat-cell *matCellDef="let row">
              <div *ngIf="row?.profileImageUrl else initials" class="symbol symbol-circle symbol-lg-50">
                <img [src]="row?.profileImageUrl" [alt]="row?.fullName">
              </div>

              <ng-template #initials>
                <div class="symbol symbol-lg-50 symbol-circle symbol-primary">
                  <span class="symbol-label font-size-h3 font-weight-bold">
                    {{row.firstName ? row.firstName[0].toUpperCase() : "" }}
                    {{row.lastName ? row.lastName[0].toUpperCase() : ""}}
                  </span>
                </div>
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="fullName"> Full Name </th>
            <td mat-cell *matCellDef="let row">
              <a
                *ngIf="row?.userId else noUser"
                href="javascript:void(0)"
                (click)="selected.emit(row)">
                {{ row.fullName | titlecase  }}</a>

              <ng-template #noUser>{{ row.fullName | titlecase  }}</ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="gender"> Gender </th>
            <td mat-cell *matCellDef="let row">{{ row?.gender }}</td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let row">{{ row?.phone }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="email"> Email </th>
            <td mat-cell *matCellDef="let row">{{ row?.email }}</td>
          </ng-container>

          <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let row">{{ row?.country }}</td>
          </ng-container>

          <ng-container matColumnDef="dob">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="dob"> Date of Birth </th>
            <td mat-cell *matCellDef="let row">{{ row?.dob | date: 'medium' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row
            *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>

      <mat-paginator
        class="mat-paginator-sticky"
        [length]="count"
        [pageSizeOptions]="[10, 50, 100, 150, 200]"
        (page)="pageEvent=$event; paginate($event)"
        showFirstLastButtons></mat-paginator>
    </div>

    <div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
      <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
      <p class="no-data-text">No data</p>
    </div>

  </div>
</div>
