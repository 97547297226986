import { CURRENCY } from '../_enums/currency.enum';

/**
 * format price to currency string
 */
export const formatToCurrency = (
  currency: string | CURRENCY,
  amount: number
) => {
  const { format } = currency?.toUpperCase() === CURRENCY.NAIRA ?
    Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: CURRENCY.NAIRA,
      minimumFractionDigits: 2,
    })
    :
    Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: CURRENCY.DOLLAR
    });
  return format(amount);
}


export const formatToCompactCurrency = (currency: string | CURRENCY,
  amount: number) => {
  if (currency.toUpperCase() === CURRENCY.NAIRA) {
    return Intl.NumberFormat('en-NG', {
      style: 'currency',
      notation: "compact",
      currency: CURRENCY.DOLLAR
    } as any).format(amount)
  }
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    notation: "compact",
    currency: CURRENCY.DOLLAR
  } as any).format(amount)
}

export const formatToCompactNumber = (
  amount: number) => {
  return Intl.NumberFormat('en-US', {
    notation: "compact",
  } as any).format(amount);
}
