import { SuitabilityService } from './services/suitability-questions.service';
import { QuestionDrawerComponent } from './components/question-drawer/question-drawer.component';
import { SuitabilityQuestionsComponent } from './suitability-questions.component';

import { SharedModule } from './../../../../shared/src/lib/shared.module';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { SuitabilityResponseComponent } from './components/user-suitability-response/suitability-response.component';


@NgModule({
  declarations: [
    SuitabilityQuestionsComponent,
    QuestionDrawerComponent,
    SuitabilityResponseComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    MatCardModule,
    MatExpansionModule
  ],
  providers: [
    SuitabilityService
  ],
  exports: [SuitabilityResponseComponent]
})

export class SuitabilityQuestionsModule { }
