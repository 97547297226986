import { BaseApiService } from './../../../api/src/lib/base-api.service';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';

import { AuthService } from './providers/authentication.service';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '../../../api/src/lib/api.interface';
import { AuthUserDetails } from './authentication.interface';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';

export interface AuthProfileState {
  loading: boolean;
  error?: string;
  authProfile?: AuthUserDetails;
}

const initialState: AuthProfileState = {
  loading: false,
};


@Injectable({ providedIn: 'root' })
export class AuthProfileStateService {
  state = new BehaviorSubject<AuthProfileState>(initialState);

  constructor(
    private auth: AuthService,
    private baseApiService: BaseApiService) {}

  getProfile() {
    if (!this.baseApiService.isAuthenticated()) {
      return;
    }

    this.auth
      .getUserByToken()
      .subscribe({
        next: this.onProfileLoaded.bind(this),
        error: this.onError.bind(this),
      });
  }

  isInternalFunctionality$() {
    return this.state.pipe(map(state => state.authProfile?.email.includes('@chaka.ng')));
  }

  get firstName$(): Observable<string> {
    return this.state.pipe(
      distinctUntilChanged(),
      map((state) => {
        const getFromEmail = () => state.authProfile?.email.split('@')[0];
        return (state.authProfile?.firstName) || getFromEmail();
      })
    );
  }

  get isSuperAdmin$(): Observable<boolean> {
    return this.state
      .pipe(
        distinctUntilChanged(),
        map(state => state?.authProfile?.superAdmin)
      );
  }

  reset() {
    this.state.next(initialState);
  }

  get message$() {
    return this.state.pipe(distinctUntilChanged()).pipe(
      map((state) => {
        if (state.loading) return 'getting user profile';
        if (state.authProfile) return 'Successfully Retrieved User Profile';
        return state.error;
      })
    );
  }

  private onProfileLoaded({ data }: ReqSuccessResponse<AuthUserDetails>) {
    this.state.next({
      ...this.state.getValue(),
      authProfile: data,
      loading: false,
    });
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);

    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
    });

  }

}
