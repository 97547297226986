import { Injectable } from '@angular/core';
import { NotificationService } from '@console/shared/services/notification-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '../../../../../../api/src/public-api';
import { ConsoleRouterService } from '../../../../../../shared/src/lib/services/console-router.service';
import { ChannelSettingService } from '../../services/channel.service';
import { ChannelSetting } from '../../services/settings.interface';

export interface ChannelState {
  channel?: ChannelSetting;
  loading: boolean;
  error?: string;
  message?: string;
}

const initalState: ChannelState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class ChannelStateService {
  state = new BehaviorSubject<ChannelState>(initalState);

  constructor(
    private svc: ChannelSettingService,
    private console: ConsoleRouterService,
    private notificationService: NotificationService
  ) {}

  create(channel: Partial<ChannelSetting>) {
    this.loading();
    this.svc.create(channel).subscribe({
      next: this.saveChannel.bind(this),
      error: this.onChannelError.bind(this),
    });
  }

  retrieve(settingId: number) {
    this.loading();
    this.svc
      .retrieve(settingId)
      .pipe(first())
      .subscribe({
        next: this.updateChannel.bind(this),
        error: this.onChannelError.bind(this),
      });
  }

  update(channel: Partial<ChannelSetting>) {
    this.loading();
    this.svc.update(channel).subscribe({
      next: this.updateSavedChannel.bind(this),
      error: this.onChannelError.bind(this),
    });
  }

  delete(settingId: number) {
    this.loading();
    this.svc.remove(settingId).subscribe({
      next: this.deleteChannel.bind(this),
      error: this.onChannelError.bind(this),
    });
  }

  resetState() {
    this.state.next(initalState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateChannel(data: ReqSuccessResponse<ChannelSetting>) {
    this.state.next({
      loading: false,
      channel: data.data,
      message: 'Loading Successful',
    });
  }

  private saveChannel(data: ReqSuccessResponse<ChannelSetting>) {
    this.state.next({
      loading: false,
      channel: data.data,
      message: 'Loading Successful',
    });
    this.notificationService.success('Channel Created Successfully');
    this.console.productNavigate(['channels']);
  }

  private updateSavedChannel(data: ReqSuccessResponse<ChannelSetting>) {
    this.state.next({
      loading: false,
      channel: data.data,
      message: 'Channel Updated Successfully',
    });

    this.notificationService.success('Channel Updated Successfully');

    this.console.productNavigate(['channels']);
  }

  private deleteChannel(data: ReqSuccessResponse<ChannelSetting>) {
    this.state.next({
      loading: false,
      channel: data.data,
      message: 'Channel Deleted Successfully',
    });
    this.notificationService.success('Channel Deleted Successfully');

    this.console.productNavigate(['channels']);
  }

  private onChannelError(res: ChakaAPIError) {
    const message = cleanChakaAPIError(res);
    this.state.next({
      loading: false,
      error: message,
    });

    this.notificationService.error(message);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading',
    });
  }

  get channel$(): Observable<ChannelSetting> {
    return this.state.pipe(map((state) => state.channel));
  }
}
