export const removeNumberPrefix = (value: string, prefix: string): string => {
  try {
    const disallowedPrefix: string[] = [];

    // Check if the prefix does not begin with a +
    if (!prefix.startsWith('+')) {
      disallowedPrefix.push(`+${prefix}`);
      disallowedPrefix.push(prefix);
    } else {
      disallowedPrefix.push(prefix);
      disallowedPrefix.push(prefix.substring(1));
    }

    disallowedPrefix.push('+', '0');
    // i.e const disallowedPrefix = ['+234', '234', '+', '0'];  //order matters

    let formattedValue = value;
    disallowedPrefix.forEach(
      item => {
        formattedValue = formattedValue.startsWith(item) ?
          formattedValue.substring(item.length) :
          formattedValue;
      }
    );

    return formattedValue;

  } catch (error) {
    console.error(error);
  }

};
