import {
  ChangeDetectionStrategy, Component,
  EventEmitter,
  Input, OnInit,
  Output
} from "@angular/core";
import { NewsContent } from "../../services/news.model";

@Component({
  selector: "kt-content-news-card",
  templateUrl: "./news-card.component.html",
  styleUrls: ["./news-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsCardComponent implements OnInit {
  @Input() news: NewsContent;

  @Output() btnClick = new EventEmitter<NewsContent>();

  constructor() {}

  get date() {
    const date = (this.news && this.news.dateCreated) ? new Date(this.news.dateCreated) : new Date();
    return new Intl.DateTimeFormat('en-NG', {
      year: 'numeric', month: 'short', day: 'numeric'
    }).format((date))
  }


  ngOnInit() {}
}
