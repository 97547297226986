import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  EnterpriseAPIService,
  ReqSuccessResponse,
  PaginatedList,
} from '../../../../../api/src/public-api';

import {
  OrderTxnsModel,
  BuyInstrumentModel,
  OrderInstrumentModel,
  PreOrder,
  ConsolidatedQuote,
  IOrderDownload,
} from './orders.model';
import { CURRENCY } from '../../../../../shared/src/public-api';
import { LoadingService } from '@console/shared/components/loading/loading.service';

const API_ORDERS = 'api/v1/orders';

@Injectable()
export class OrderTxnService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) { }

  getOrderTxns(searchParams?): Observable<PaginatedList<OrderTxnsModel>> {
    const orders$ = this.http.get<PaginatedList<OrderTxnsModel>>(
      API_ORDERS,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(orders$);
  }

  getOrderTxnsByUserId(searchParams?): Observable<PaginatedList<OrderTxnsModel>> {
    return this.http.get<PaginatedList<OrderTxnsModel>>(
      API_ORDERS,
      searchParams
    );
  }

  getOrderTxnById(id: string): Observable<ReqSuccessResponse<OrderTxnsModel>> {
    const url = API_ORDERS + `/${id}`;
    return this.http.get<ReqSuccessResponse<OrderTxnsModel>>(url);
  }

  buyInstrument(instrument: BuyInstrumentModel): Observable<ReqSuccessResponse<OrderInstrumentModel>> {
    const url = `${API_ORDERS}/buy`;
    return this.http.post<ReqSuccessResponse<OrderInstrumentModel>>(
      url,
      instrument
    );
  }

  sellInstrument(instrument: BuyInstrumentModel): Observable<ReqSuccessResponse<OrderInstrumentModel>> {
    const url = `${API_ORDERS}/sell`;
    return this.http.post<ReqSuccessResponse<OrderInstrumentModel>>(
      url,
      instrument
    );
  }

  cancelOrder(id: string): Observable<ReqSuccessResponse<OrderTxnsModel>> {
    const url = `${API_ORDERS}/cancel/${id}`;
    return this.http.put<ReqSuccessResponse<OrderTxnsModel>>(url, {});
  }

  preOrder(instrument: BuyInstrumentModel): Observable<ReqSuccessResponse<PreOrder>> {
    const url = `${API_ORDERS}/pre-order`;
    return this.http.post<ReqSuccessResponse<PreOrder>>(url, instrument);
  }

  quote(symbol: string): Observable<ReqSuccessResponse<ConsolidatedQuote>> {
    const url = `api/v1/instruments/quote/${symbol}`;
    return this.http.get<ReqSuccessResponse<ConsolidatedQuote>>(url);
  }

  reQueryOrder(payload: {
    orderId: string;
    currency: CURRENCY;
  }): Observable<ReqSuccessResponse<OrderTxnsModel>> {
    const url = `${API_ORDERS}/status/${payload.orderId}`;
    return this.http.get<ReqSuccessResponse<OrderTxnsModel>>(url, {});
  }

  batchReQuery(orderIds: string[]): Observable<ReqSuccessResponse<OrderTxnsModel[]>> {
    const csvOrderIds = orderIds.join(',');
    const url = `${API_ORDERS}/status/query_batch?orderIds=${csvOrderIds}`;
    return this.http.get<ReqSuccessResponse<OrderTxnsModel[]>>(url);
  }

  batchCancel(orderIds: string[]): Observable<ReqSuccessResponse<OrderTxnsModel[]>> {
    const csvOrderIds = orderIds.join(',');
    const url = `${API_ORDERS}/cancel_batch?orderIds=${csvOrderIds}`;

    return this.http.put<ReqSuccessResponse<OrderTxnsModel[]>>(url, {});
  }

  downloadAll(): Observable<ReqSuccessResponse<IOrderDownload>> {
    const url = `${API_ORDERS}/get-all-orders`;
    return this.http.get<ReqSuccessResponse<IOrderDownload>>(url, { pageSize: 10000 });
  }


  downloadAllPositions(): Observable<ReqSuccessResponse<IOrderDownload>> {
    const url = `api/v1/assets/get-all-positions`;
    return this.http.get<ReqSuccessResponse<IOrderDownload>>(url, { pageSize: 10000 });
  }


}
