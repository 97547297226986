import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import {
  ReqSuccessResponse,
  ChakaAPIError,
  cleanChakaAPIError,
} from '@console/api';
import {
  WalletTransaction,
  WalletTransfer,
} from '../providers/wallet.interface';
import { WalletService } from '../providers/wallet.service';
import { PaymentService } from '../providers/payment-service';

export interface RequeryPaymentState {
  transaction?: WalletTransaction;
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: RequeryPaymentState = { loading: false };

@Injectable({ providedIn: 'root' })
export class RequeryPaymentStateService {
  state = new BehaviorSubject<RequeryPaymentState>(initialState);

  spinnerName = 'Wallet-Exchange-Spinner';

  constructor(
    private payment: PaymentService,
    private spinner: NgxSpinnerService
  ) {}

  confirm(body: WalletTransfer) {
    this.loading();
    this.payment.confirm(body).subscribe({
      next: this.onUserRequeryPaymentSLoaded.bind(this),
      error: this.onUserRequeryPaymentSError.bind(this),
    });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Please wait, while payment is been requeried',
    });
    this.spinner.show(this.spinnerName);
  }

  private onUserRequeryPaymentSLoaded({
    data,
  }: ReqSuccessResponse<WalletTransaction>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      message: `Payment was successful requeried`,
      transaction: data,
    });
    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2000);
  }

  private onUserRequeryPaymentSError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false,
      message: error,
    });
    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2000);
  }
}
