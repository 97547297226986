import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '../../../../api/src/public-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { WalletTxnDetailsModel } from '../transactions/services/transactions.model';
import { WalletTxnService } from '../transactions/services/transactions.service';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';


export interface WithdrawalListState {
  loading: boolean;
  count?: number;
  withdrawals: WalletTxnDetailsModel[];
  error?: string;
  message?: string;
}

const initialState: WithdrawalListState = {
  loading: false,
  withdrawals: [],
};

@Injectable({ providedIn: 'root' })
export class WithdrawalListStateService {
  state = new BehaviorSubject<WithdrawalListState>(initialState);

  constructor(
    private withdrawalSvc: WalletTxnService,
    private spinner: NgxSpinnerService,
    private messagesService: MessagesService
  ) { }

  find(query: object): void {
    this.loading();
    this.withdrawalSvc
      .getWithdrawals(query)
      .pipe(first())
      .subscribe({
        next: this.updateWithdrawals.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateWithdrawals(data: PaginatedList<WalletTxnDetailsModel>): void {
    this.state.next({
      loading: false,
      withdrawals: data.data,
      count: data.count,
      message: 'Loading Successful',
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError): void {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: 'An Error occurred',
    });
    this.spinner.hide();
  }

  reset(): void {
    this.state.next(initialState);
  }

  private loading(): void {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading...',
    });
  }

  batchApprove(transactionIds: { transactionId: number }[], searchFilter, callback: (success: boolean) => void): void {
    const length = transactionIds.length;

    const loadingMessage = `Approving ${length} ${length <= 1 ? 'withdrawal' : 'withdrawals'}`;

    !!this.messagesService.totalOpened && this.messagesService.hideAll();

    this.messagesService.open(MessagesEnum.loading, loadingMessage);

    this.withdrawalSvc
      .batchApprove(transactionIds)
      .subscribe({
        next: (response) => {
          this.find(searchFilter);

          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: response.message
            });

          !!callback && callback(true);
        },
        error: (err: ChakaAPIError) => {
          this.approveFailed(err, callback);
        }
      });
  }

  private approveFailed(err: ChakaAPIError, callback: (success: boolean) => void): void {
    const message = cleanChakaAPIError(err);

    this.messagesService
      .update({ type: MessagesEnum.danger, message });

    !!callback && callback(false);
  }
}
