import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EnterpriseAPIService, ReqSuccessResponse } from '@console/api';
import { Observable } from 'rxjs';
import { PaymentOtpModalComponent } from './payment-otp-modal.component';

const API_PAYMENTS = 'api/v1/payments';

@Injectable({ providedIn: 'root' })
export class PaymentOtpModalService {
  constructor(private dialog: MatDialog, private http: EnterpriseAPIService) {}

  public requestOtp(): Observable<ReqSuccessResponse<null>> {
    return this.http.get<ReqSuccessResponse<null>>(
      API_PAYMENTS + '/authorization-code'
    );
  }

  public async openModal(
    payload: string | string[],
    callback?: (result: boolean) => void
  ): Promise<void> {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = payload;

    const dialogRef = this.dialog.open(PaymentOtpModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      !!callback && callback(result);
    });
  }
}
