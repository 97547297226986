import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BankModel } from '@console/user-managements/services/interface';
import { BankDetailForm } from './bank-form.interface';
import { KYCFormControl } from '../kyc-form-control';

@Component({
  selector: 'lib-user-bank-form',
  templateUrl: './user-bank-form.component.html',
  styleUrls: ['./user-bank-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserBankFormComponent extends KYCFormControl<BankDetailForm> implements OnInit {
  @Input() bankDetails: BankDetailForm;
  @Input() banks: BankModel[] = [];

  bankForm: FormGroup;

  constructor(private fb: FormBuilder) {
    super();
  }

  private createForm(data: Partial<BankDetailForm> = {}) {
    return this.fb.group({
      bvn: [data.bvn || ''],
      bankAcctName: [data.bankAcctName || ''],
      bankAcctNo: [data.bankAcctNo || ''],
      bankId: [data.bankId || ''],
    });
  }

  ngOnInit(): void {
    this.bankForm = this.createForm(this.bankDetails);
    if (this.disabled) {
      this.bankForm.disable()
    }
    this.listen(this.bankForm);
  }
}
