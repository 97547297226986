<div class="card card-custom">
  <!--begin::Header-->
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder">
        {{ data.currency === "USD" ? "Global" : "Local" }} Wallet
        {{ nubanCheck ? "Account(s)" : "Balance" }}
      </span>
    </h3>
    <div class="card-toolbar">
      <button
        mat-button
        (click)="nubanCheck = false"
        class="btn font-weight-bolder font-size-sm"
        [ngClass]="!nubanCheck ? 'btn-text-primary' : 'btn-text-secondary'"
      >
        Balance
      </button>

      <button
        *ngIf="virtualAccountList.length > 0"
        mat-button
        (click)="nubanCheck = true"
        class="btn font-weight-bolder font-size-sm"
        [ngClass]="nubanCheck ? 'btn-text-primary' : 'btn-text-secondary'"
      >
        Virtual Account(s)
      </button>
    </div>
  </div>

  <!--end::Header-->
  <!--begin::Body-->
  <div class="card-body pt-0 pb-3">
    <lib-messages></lib-messages>

    <div class="row" *ngIf="!nubanCheck; else showVirtualAccount">
      <div class="col-12 table-responsive">
        <table class="table table-hover">
          <thead class="border-top-light">
            <tr style="border-top: 0px !important">
              <th scope="col" class="table-title">Field</th>
              <th scope="col" class="table-title">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table-text">Equity Value</td>
              <td class="table-text">
                {{ formatToCurrency(data.currency, data?.equityValue || 0) }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Available to trade</td>
              <td class="table-text">
                {{ formatToCurrency(data.currency, data.availableToTrade) }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Available to Withdraw</td>
              <td class="table-text">
                {{ formatToCurrency(data.currency, data.availableToWithdraw) }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Available Balance</td>
              <td class="table-text">
                {{ formatToCurrency(data.currency, data.availableBalance) }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Pending Cash</td>
              <td class="table-text">
                {{ formatToCurrency(data.currency, data.pendingCash) }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Unsettled Cash</td>
              <td class="table-text">
                {{ formatToCurrency(data.currency, data.unsettledCash) }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Total Balance</td>
              <td class="table-text">
                {{ formatToCurrency(data.currency, data.totalBalance) }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Total Account Balance</td>
              <td class="table-text">
                {{
                  formatToCurrency(
                    data.currency,
                    (data?.totalBalance || 0) + (data?.equityValue || 0)
                  )
                }}
              </td>
            </tr>
            <tr>
              <td class="table-text">Identifier</td>
              <td class="table-text">
                {{ data?.identifier }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 text-right">
        <button (click)="clearPendingCash()" class="btn btn-link text-danger">
          Clear Pending Cash
        </button>
      </div>
    </div>

    <ng-template #showVirtualAccount>
      <div class="row">
        <div class="col-12">
          <mat-accordion
            multi
            expanded="true"
            *ngIf="virtualAccountList.length > 0; else noVirtualAccountFound"
          >
            <mat-expansion-panel
              class="expansion-card"
              *ngFor="let _virtualAccount of virtualAccountList; let i = index"
              [expanded]="i === 0"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <strong>
                    {{ i + 1 }}. {{ _virtualAccount?.bankName }}
                  </strong>

                  <small *ngIf="i === 0" class="text-primary pl-4"
                    >default</small
                  >
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="expansion-card-content">
                <div class="row">
                  <div class="col-12 table-responsive">
                    <table class="table table-hover">
                      <thead class="border-top-light">
                        <tr style="border-top: 0px !important">
                          <th scope="col" class="table-title">Field</th>
                          <th scope="col" class="table-title">Value</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td class="table-text">Bank Name</td>
                          <td class="table-text">
                            {{ _virtualAccount?.bankName }}
                          </td>
                        </tr>
                        <tr>
                          <td class="table-text">Bank Code</td>
                          <td class="table-text">
                            {{ _virtualAccount?.bankCode }}
                          </td>
                        </tr>
                        <tr>
                          <td class="table-text">Account Name</td>
                          <td class="table-text">
                            {{ _virtualAccount?.accountName }}
                          </td>
                        </tr>
                        <tr>
                          <td class="table-text">Account Number</td>
                          <td class="table-text">
                            {{ _virtualAccount?.accountNumber }}
                          </td>
                        </tr>
                        <tr>
                          <td class="table-text">Account Reference</td>
                          <td class="table-text">
                            {{ _virtualAccount?.accountReference }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <ng-template #noVirtualAccountFound>
                      <lib-no-data
                        message="No virtual account for user"
                      ></lib-no-data>
                    </ng-template>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </ng-template>
  </div>
  <!--end::Body-->
</div>
