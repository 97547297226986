import { Injectable } from '@angular/core';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ChakaAPIError,
  cleanChakaAPIError,
} from '../../../../../../api/src/public-api';
import { SettingService } from '../../services/app.service';
import { AppSetting } from '../../services/settings.interface';

export interface AppSettingState {
  setting?: AppSetting;
  loading: boolean;
  error?: string;
}

const initialState: AppSettingState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class AppSettingStateService {
  private subject$ = new BehaviorSubject<AppSettingState>(initialState);
  state$ = this.subject$.asObservable();

  private messageId: number;

  constructor(
    private settingService: SettingService,
    private messagesService: MessagesService
  ) {}

  saveSetting(
    setting: Partial<AppSetting>,
    callback: (success: boolean) => void
  ) {
    this.loading();

    const { id } = setting;

    this.showMessage(`${id ? 'Updating' : 'Creating'} setting...`);

    const subscription$ = id
      ? this.settingService.update(setting)
      : this.settingService.create(setting);

    subscription$
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error, callback);
          return throwError(error);
        })
      )
      .subscribe({
        next: (response) => {
          this.subject$.next({
            ...this.subject$.getValue(),
            loading: false,
            setting: response.data,
          });

          this.showMessage(
            `Successfully ${id ? 'updated' : 'created'} setting...`,
            MessagesEnum.success
          );

          callback && callback(true);
        },
      });
  }

  delete(settingId: number, callback: (success: boolean) => void) {
    this.loading();

    this.showMessage(`Deleting setting...`);

    this.settingService
      .remove(settingId)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error, callback);
          return throwError(error);
        })
      )
      .subscribe({
        next: (response) => {
          this.subject$.next({
            ...this.subject$.getValue(),
            loading: false,
            setting: response.data,
          });

          this.showMessage(
            `Successfully deleted setting...`,
            MessagesEnum.success
          );

          callback && callback(true);
        },
      });
  }

  findById(settingId: number) {
    this.loading();
    this.showMessage('Fetching setting...');

    this.settingService
      .retrieve(settingId)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        })
      )
      .subscribe({
        next: (response) => {
          this.subject$.next({
            ...this.subject$.getValue(),
            loading: false,
            setting: response.data,
          });

          this.showMessage(
            'Successfully retrieved setting',
            MessagesEnum.success
          );
        },
      });
  }

  private handleError(
    res: ChakaAPIError,
    callback?: (success: boolean) => void
  ): void {
    const errorMessage = cleanChakaAPIError(res) || 'An Error occurred';

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false,
    });

    this.showMessage(errorMessage, MessagesEnum.danger);

    callback && callback(false);
  }

  resetState() {
    this.subject$.next(initialState);
  }

  get loading$() {
    return this.subject$.pipe(map((state) => state.loading));
  }

  private loading() {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true,
      error: '',
    });
  }

  private showMessage(message: string, type = MessagesEnum.loading): void {
    if (this.messageId) {
      this.messagesService.update({
        id: this.messageId,
        type,
        message,
        isOpened: true,
      });
      return;
    }

    const { id } = this.messagesService.open(type, message);

    this.messageId = id;
  }
}
