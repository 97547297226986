import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList,
} from '../../../../../api/src/public-api';
import { PaymentTxnsModel } from '../services/payment.model';
import { PaymentTxnService } from '../services/payment.service';

export interface PaymentListState {
  loading: boolean;
  count?: number;
  payments: PaymentTxnsModel[];
  error?: string;
  message?: string;
}

const initialState: PaymentListState = {
  loading: false,
  payments: [],
};

@Injectable({ providedIn: 'root' })
export class PaymentListStateService {
  state = new BehaviorSubject<PaymentListState>(initialState);

  constructor(
    private paymentSvc: PaymentTxnService,
    private spinner: NgxSpinnerService
  ) { }

  find(query: object) {
    this.loading();
    this.paymentSvc
      .getPaymentTxns(query)
      .pipe(first())
      .subscribe({
        next: this.updatePayments.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updatePayments(data: PaginatedList<PaymentTxnsModel>) {
    this.state.next({
      loading: false,
      payments: data.data,
      count: data.count,
      // message: 'Loading Successful',
    });
    // this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: error
    });
    // this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading(): void {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      // message: 'Loading...',
    });
  }
}
