import { CscsSecondLoginStateService } from './../../projects/view/src/lib/user-management/kyc/components/cscs-second-login/cscs-second-login-state.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APICONFIG, ApiModule } from '@console/api';
import { UserManagementModule } from '@console/user-managements';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ViewsModule } from '../../projects/view/src/lib/views.module';
import { SearchBarModule } from '../../projects/_shared/search-bar/search-bar.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { InstrumentsModule } from '../../projects/instruments/src/lib/instruments.module';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MerchantModule } from '../../projects/view/src/lib/merchants/merchant.module';
import { serverRoutes } from './route.config';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AnalyticsModule } from '../../projects/analytics/src/lib/analytics.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MerchantDetailStateService } from './dashboard/merchant-details.state';
import { BrowserModule } from '@angular/platform-browser';


import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    DashboardModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    ViewsModule,
    UserManagementModule,
    BrowserAnimationsModule,
    ApiModule,
    NgxSpinnerModule,
    SearchBarModule,
    MerchantModule,
    AnalyticsModule
  ],
  providers: [
    CscsSecondLoginStateService,
    MerchantDetailStateService,
    // Replace this config for production
    {
      provide: APICONFIG,
      useValue: {
        ...serverRoutes(),
      },
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
