import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '../../../../api/src/public-api';
import { 	Role, } from './services/roles.interface';
import { RoleService } from './services/role.service';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';


export interface RolesListState {
  loading: boolean;
  count?: number;
  roles: 	Role[];
  error?: string;
  message?: string;
}

const initialState: RolesListState = {
  loading: false,
  roles: [],
};

@Injectable({ providedIn: 'root' })
export class RoleListStateService {
  state = new BehaviorSubject<RolesListState>(initialState);

  constructor(private rolesSvc: RoleService,
              private spinner: NgxSpinnerService,) { }

  find(query: object) {
    this.loading();
    this.rolesSvc
      .roles(query)
      .pipe(first())
      .subscribe({
        next: this.updateRoles.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateRoles(data: PaginatedList<Role>) {
    this.state.next({
      loading: false,
      roles: data.data,
      count: data.count,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message:"An Error occurred"
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading..."
    });
    this.spinner.hide();
  }
}
