<h2 mat-dialog-title>{{ title }}</h2>

<mat-dialog-content class="content">

  <lib-loading></lib-loading>

  <div class="form-group mb-8">
    <lib-messages></lib-messages>
  </div>

  <div class="form-group" *ngIf="!selectedUser">
    <label>Search for a user <span class="text-danger">*</span></label>

    <ng-select [items]="(userList$.state | async).users"
      bindLabel="fullName"
      autofocus
      bindValue="userId"
      [formControl]="userControl"
      (input)="debounceSearchUser($event)"
      (change)="assignUser($event)">
    </ng-select>
  </div>

  <div class="form-group" *ngIf="!!selectedUser">

    <!--begin::Item-->
    <div class="d-flex flex-wrap align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
        <div *ngIf="selectedUser?.profileImageUrl else initials" class="symbol symbol-circle symbol-lg-50">
          <img [src]="selectedUser?.profileImageUrl" [alt]="selectedUser?.fullName">
        </div>

        <ng-template #initials>
          <div class="symbol symbol-lg-50 symbol-circle symbol-primary">
            <span class="symbol-label font-size-h4">
              {{selectedUser?.firstName ? selectedUser.firstName[0].toUpperCase() : "" }}
              {{selectedUser?.lastName ? selectedUser.lastName[0].toUpperCase() : ""}}
            </span>
          </div>
        </ng-template>
      </div>

      <!--end::Symbol-->
      <!--begin::Title-->
      <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
        <a href="javascript:;" class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{{selectedUser?.fullName}}</a>
        <span class="text-muted font-weight-bold">{{selectedUser?.email}}</span>
      </div>
      <!--end::Title-->
      <!--begin::Section-->
      <div class="d-flex align-items-center mt-lg-0 mt-3">
        <!--begin::Btn-->
        <a
          style="cursor: pointer;"
          class="btn btn-icon btn-light btn-sm"
          (click)="this.deleteUser()">
          <span class="svg-icon svg-icon-md">
            <i class="fas fa-times"></i>
          </span>
        </a>
        <!--end::Btn-->
      </div>
      <!--end::Section-->
    </div>
    <!--end::Item-->
  </div>

</mat-dialog-content>

<mat-dialog-actions mat-dialog-actions class="d-flex justify-content-between">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="close()">Close</button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="save()">Save</button>
</mat-dialog-actions>
