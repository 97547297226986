<div class="card card-custom card-stretch gutter-b">
  <!--begin::Header-->
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Latest {{tabDetails.name}}</span>
    </h3>
    <div class="card-toolbar">
      <ul class="nav nav-pills nav-pills-sm nav-dark-75">

        <li class="nav-item" *ngFor="let tab of analyticsTabs">
          <a
            class="nav-link py-2 px-4"
            [ngClass]="tab.value === this.activeReport ? 'active no-pointer' : ''"
            data-toggle="tab"
            href="javascript:;"
            (click)="switchTabs(tab.value)">{{tab.name}}</a>
        </li>

      </ul>
    </div>
  </div>
  <!--end::Header-->
  <!--begin::Body-->
  <div class="card-body py-0">
    <!--begin::Table-->
    <div class="table-responsive">
      <table *ngIf="!reportLoading && !!tableData" class="table table-head-custom table-vertical-center">
        <thead>
          <tr>
            <th>Name</th>

            <th *ngIf="!isUserReport">Reference</th>
            <th *ngIf="!isUserReport">Amount</th>

            <th *ngIf="isOrderReport">Symbol</th>

            <th *ngIf="isPaymentReport">Approved</th>



            <th *ngIf="isUserReport">Email</th>
            <th *ngIf="isUserReport">Country</th>
            <th *ngIf="isUserReport">Phone</th>

            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tableData">
            <td>
              <a
                href="javascript:void(0)"
                (click)="viewUser.emit(data?.userId)">
                {{userName(data) | truncateWords:20:'...' }}</a>
            </td>

            <td *ngIf="!isUserReport">
              <a
                href="javascript:void(0)"
                (click)="viewById.emit(data?.id)">
                {{referenceNumber(data)}}
              </a>
            </td>

            <td *ngIf="!isUserReport">
              {{resolveAmount(data)}}
            </td>

            <td *ngIf="isOrderReport" class="text-primary">
              {{ data?.symbol || "-" }}
            </td>
            <td *ngIf="isOrderReport" [ngClass]="getOrderRowClass(data?.status)">
              {{ data?.status | titlecase }}
            </td>

            <td *ngIf="isPaymentReport" [ngClass]="data?.authorizationStatus ? 'text-success': 'text-warning'">
              {{data?.authorizationStatus ? "Yes" : "No" || "-"}}
            </td>
            <td *ngIf="isPaymentReport" [class]="getStatusClass(data?.paymentStatus)">
              {{data?.paymentStatus | titlecase}}
            </td>

            <td *ngIf="isTransactionReport" [class]="getStatusClass(data?.paymentStatus)">
              {{data?.status || "-" | titlecase}}
            </td>

            <td *ngIf="isUserReport">
              {{data?.email}}
            </td>
            <td *ngIf="isUserReport">
              {{data?.country}}
            </td>
            <td *ngIf="isUserReport">
              {{data?.phone}}
            </td>

            <td *ngIf="isUserReport" [ngClass]="getStatusClass(data?.status)">
              {{ data?.status | titlecase }}
            </td>
            <td>
              {{data?.dateCreated | date: 'mediumDate' || "-"}}
            </td>
          </tr>

        </tbody>
      </table>

    </div>
    <!--end::Table-->
  </div>
  <!--end::Body-->
</div>
