<div class="row">
  <div
    class="col-xl-4 col-lg-4 col-md-6 mb-5"
    style="word-break: break-word;"
    *ngFor="let merchant of merchants">
    <!--begin::Iconbox-->
    <kt-merchant-card
      [merchant]="merchant"
      (view)="onSelect.emit($event)"></kt-merchant-card>

    <!--end::Iconbox-->

  </div>
  <!--end::Row-->
</div>
