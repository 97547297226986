<div class="d-flex flex-column-fluid">
  <div class="container" *ngIf="autoRebalance">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-toolbar w-100 justify-content-between">
          <div>
            <button
              *ngIf="canBeRequeried"
              (click)="requery(autoRebalance?.id)"
              class="btn btn-dark border"
              [disabled]="loading">
              <i [ngClass]="loading && currentClicked ==='REQUERY' ? 'fas fa-spinner fa-spin': 'fas fa-sync-alt'"></i> Re-query
            </button>
          </div>

          <button
            (click)="back()"
            class="btn btn-transparent border">
            <i class="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div class="card-body">

        <lib-messages></lib-messages>

        <div class="row">
          <div class="col-12">
            <div class="details-pane">
              <p class="details-pane__content">
                <span>Allocations Outcome:</span>
                <strong>{{ autoRebalance?.allocations?.outcome || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Allocation Submitted:</span>
                <strong>{{ autoRebalance?.allocations?.submitted || '_' }}</strong>
              </p>

              <p class="details-pane__content">
                <span>ID:</span>
                <strong>{{ autoRebalance?.id || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Money Movement:</span>
                <strong>{{ autoRebalance?.moneyMovement|| '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Order Amount Buys:</span>
                <strong>{{ autoRebalance?.orders.amountBuys || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Order Amount Sells:</span>
                <strong>{{ autoRebalance?.orders.amountSells|| '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Order Submitted:</span>
                <strong>
                  <a [href]="autoRebalance?.orders.submitted" target="_blank">View report</a>
                  <!-- {{ autoRebalance?.orders.submitted || '_'}} -->
                </strong>
              </p>

              <p class="details-pane__content">
                <span>Order Total:</span>
                <strong>{{ autoRebalance?.orders.total|| '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Regular Rebalance:</span>
                <strong>{{ autoRebalance?.regularRebalance || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Review Only:</span>
                <strong>{{ autoRebalance?.reviewOnly || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>RIA ID:</span>
                <strong>{{autoRebalance?.riaID || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Status:</span>
                <strong>{{ autoRebalance?.status || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Sub Accounts Error:</span>
                <strong>{{ autoRebalance?.subAccounts?.error || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Sub Accounts Outcome:</span>
                <strong>{{ autoRebalance?.subAccounts?.outcome || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Sub Accounts Rebalanced:</span>
                <strong>{{ autoRebalance?.subAccounts?.rebalanced || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Sub Accounts Total:</span>
                <strong>{{ autoRebalance?.subAccounts?.total || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Date Created:</span>
                <strong>{{ (autoRebalance?.created | date:'medium') || '_' }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Date Updated:</span>
                <strong>{{ (autoRebalance?.updated | date:'medium') || '_'}}</strong>
              </p>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
