import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@console/shared';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApiModule } from '../../../../api/src/lib/api.module';
import { WalletTxnService } from './services/transactions.service';
import { TransactionStateService } from './transaction-edit/transaction-edit-state';
import { TransactionsEditComponent } from './transaction-edit/transactions-edit.component';
import { TransactionListStateService } from './transactions-list/transaction-state';
import { TransactionsComponent } from './transactions-list/transactions.component';
import { TransactionsTableModule } from './transactions-table/transactions-table.module';

@NgModule({
  imports: [
    CommonModule,
    ApiModule,
    NgxSpinnerModule,
    TransactionsTableModule,
    NgxSmartModalModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [TransactionsEditComponent, TransactionsComponent],
  declarations: [TransactionsEditComponent, TransactionsComponent],
  providers: [
    TransactionStateService,
    WalletTxnService,
    TransactionListStateService
  ],
})
export class WalletTransactionModule { }
