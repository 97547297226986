import { Injectable } from '@angular/core';
import { ReqSuccessResponse, ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExchangeRate } from '../providers/payment-interface';
import { PaymentService } from '../providers/payment-service';


export interface ExchangeRateState {
  rates?: ExchangeRate;
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: ExchangeRateState = { loading: false };

@Injectable({ providedIn: 'root' })
export class ExchangeRateStateService {
  state = new BehaviorSubject<ExchangeRateState>(initialState);

  constructor(private payment: PaymentService) {}

  retrieve() {
    this.loading();
    this.payment.exchangeRate().subscribe({
      next: this.onUserExchangeRateLoaded.bind(this),
      error: this.onUserExchangeRateError.bind(this),
    });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({ ...this.state.getValue(), loading: true });
  }

  private onUserExchangeRateLoaded({ data }: ReqSuccessResponse<ExchangeRate>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      rates: data,
    });
  }

  private onUserExchangeRateError(res: ChakaAPIError) {
    this.state.next({
      loading: false,
      error: cleanChakaAPIError(res),
    });
  }
}
