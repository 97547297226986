<!--begin::Mixed Widget 4-->
<div class="card card-custom bg-radial-gradient-primary gutter-b">
  <!--begin::Header-->
  <div class="card-header border-0 py-5">
    <h3 class="card-title font-weight-bolder text-white">{{tabDetails.name}}</h3>
  </div>
  <!--end::Header-->
  <!--begin::Body-->
  <div class="card-body d-flex flex-column p-0" style="position: relative;">
    <!--begin::Chart-->
    <div
      class="chart-container"
      [ngStyle]="{'height': '200px'}">
      <canvas #chartDiv></canvas>
    </div>
    <!--end::Chart-->
    <!--begin::Stats-->
    <div class="card-spacer bg-white card-rounded flex-grow-1">
      <!--begin::Row-->
      <div class="row m-0">
        <div *ngFor="let detail of details" class="col-md-6 px-6 py-4">
          <div class="font-size-sm text-muted font-weight-bold">{{detail.label}}</div>
          <div class="font-size-h4 font-weight-bolder">{{detail.value}}</div>
        </div>
      </div>
      <!--end::Row-->

    </div>
    <!--end::Stats-->
  </div>
  <!--end::Body-->
</div>
<!--end::Mixed Widget 4-->
