import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { PerformanceIntervals } from '../../models/instrument.interface';
import {
  GraphPoint,
  pickProperty
} from '../../states/graph-utils/graph-cleaner';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  @Input() symbol!: string;
  @Input() currency!: string;
  @Input() charts: GraphPoint[] = [];

  @Output() intervalChanged = new EventEmitter();


  intervals = [
    {
      label: 'LIVE',
      interval: PerformanceIntervals.Day
    },
    {
      label: '1W',
      interval: PerformanceIntervals.Week
    },
    {
      label: '1M',
      interval: PerformanceIntervals.Month
    },
    {
      label: '3M',
      interval: PerformanceIntervals.Quarter
    },
    {
      label: '6M',
      interval: PerformanceIntervals.HalfYear
    },
    {
      label: '1Y',
      interval: PerformanceIntervals.Year
    },
    {
      label: '5Y',
      interval: PerformanceIntervals.FiveYear
    },
  ]

  constructor() {}

  get chartOption(): any {
    let { charts,  currency} = this;
    charts = charts.filter(p =>  p.close > 0 );
    // console.table(charts);
    // console.log(Math.min.apply(this, pickProperty(charts, 'close') as number[]), Math.max.apply(this, pickProperty(charts, 'close') as number[]))
    return {
      axisPointer: {
        lineStyle: {
          color: 'black'
        }
      },
    tooltip: {
        trigger: 'axis',
        backgroundColor: 'black',
        formatter: function ([param]: any) {
          return  param.axisValue + '<br>' + formatToCurrency(currency, param.data) ;
        },
        textStyle: {
          fontFamily: 'Helvetica'
        },
        axisPointer: {
            animation: false
        }
    },
    xAxis: {
      show: false,
      type: 'category',
      splitLine: {
        show: true
      },
      data: pickProperty(charts, 'label'),
    },
    yAxis: {
      show: false,
        type: 'value',
        min: Math.min.apply(this, pickProperty(charts, 'close') as number[]),
        max: Math.max.apply(this, pickProperty(charts, 'close') as number[]),
        boundaryGap: [0, '100%'],
        splitLine: {
            show: false
        },
    },
    series: [{
        name: 'Performance Chart',
        type: 'line',
        showSymbol: false,
        hoverAnimation: false,
        lineStyle: {
          color: '#0275D8',
          width: 2
      },
        data: pickProperty(charts, 'close')
    } as any]
    };
  }

  ngOnInit() {}
}
