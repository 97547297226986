import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { KYC_UPLOADS } from '@console/shared';

@Component({
  selector: 'lib-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit {
  // Public properties
  @Input() userId: string;
  @Input() existingImage: string;
  @Input() loading: boolean;
  @Input('uploadType') type: KYC_UPLOADS;
  @Input() label: string;
  @Output() upload = new EventEmitter<FormData>();

  image: any;
  file: File;
  editImage: boolean;
  isNewImage: boolean;

  ngOnInit() {
    if (this.existingImage) {
      this.isNewImage = false;
      this.editImage = false;
      this.image = this.existingImage;
      return true;
    }
    this.isNewImage = true;
    this.editImage = true;
    this.image = undefined;
  }

  uploadImage() {
    const formData = new FormData();
    formData.append('uploadType', this.type);
    formData.append('file', this.image);
    formData.append('userId', this.userId);
    this.upload.emit(formData);
  }

  uploadFile({ target }) {
    this.file = target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.image = reader.result;
      this.editImage = false;
    };
    reader.onerror = (e) => {
    };
    reader.readAsDataURL(this.file);
  }

  changeImage(value: boolean) {
    if (value) {
      this.editImage = true;
      this.image = undefined;
    }
  }
}
