import { MessagesEnum } from './../../../../../../../shared/src/lib/components/messages/enums/messages.enums';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { PaymentStateService } from '../../payments-edit-state';
import { PaymentOtpModalService } from './payment-otp-modal.service';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { interval, Observable, timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
  templateUrl: './payment-otp-modal.component.html',
  styleUrls: ['./payment-otp-modal.component.scss'],
  providers: [MessagesService],
})
export class PaymentOtpModalComponent {
  otpControl: FormControl;
  payload: string | string[];
  isSending = false;
  countdown: number;

  constructor(
    private dialogRef: MatDialogRef<PaymentOtpModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    componentData: string | string[],
    private messagesService: MessagesService,
    private paymentOtpModalService: PaymentOtpModalService,
    private paymentState: PaymentStateService
  ) {
    this.payload = componentData;

    this.otpControl = new FormControl('', [Validators.required]);

    console.log(this.payload);
    console.log('constructed');

    this.sendOtpCode();
  }

  sendOtpCode(): void {
    if (this.countdown || this.isSending) {
      return;
    }

    this.isSending = true;

    const { id } = this.messagesService.open(
      MessagesEnum.loading,
      'Sending OTP code...'
    );

    this.paymentOtpModalService.requestOtp().subscribe({
      next: () => {
        this.messagesService.update(
          {
            id,
            type: MessagesEnum.success,
            message: 'OTP code sent successfully.',
          },
          { timeOut: 5000 }
        );

        const times = 31;

        interval(1000)
          .pipe(take(times))
          .pipe(
            tap((val) => {
              this.countdown = times - val - 1;
            })
          )
          .subscribe();

        this.isSending = false;
      },
      error: (error: ChakaAPIError) => {
        const errorMessage = cleanChakaAPIError(error);

        this.messagesService.update({
          id,
          type: MessagesEnum.danger,
          message: errorMessage,
        });

        this.isSending = false;
      },
    });
  }

  submit(): void {
    if (!this.otpControl.valid) {
      this.otpControl.markAllAsTouched();
      return;
    }

    const payload = {
      [Array.isArray(this.payload) ? 'paymentReferences' : 'paymentReference']:
        this.payload,
      otp: this.otpControl.value,
    };

    this.paymentState.approve(payload, (success) => {
      success && this.close(success);
    });
  }

  close(success?: boolean): void {
    this.dialogRef.close(success);
  }
}
