import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

import { BlogEditorStateService,BlogEditorState, CrudState } from './blog-edit.state';
import { BlogContent, BlogContentDetails } from '../services/blog.model';
import { BlogService } from '../services/blog.service';
import { ConsoleRouterService } from '../../../../../../../projects/shared/src/public-api';
import { map, take } from 'rxjs/operators';
// @Component({
//   selector: 'app-blog-edit',
//   templateUrl: './blog-edit.component.html',
//   styleUrls: ['./blog-edit.component.scss']
// })

@Component({
  selector: 'kt-blog-edit',
  templateUrl: './blog-edit.component.html',
  styles: [
    `
			.dot--none {
				background-color: #c4c4c4;
			}
			.dot--pending {
				background-color: #ffb822;
			}
			.dot--approved {
				background-color: #22a4ef;
			}
		`,
  ],
})
export class BlogEditComponent implements OnInit {

  post: BlogContent;
  oldPost: BlogContent;
  blogForm: FormGroup;
  hasFormErrors = false;
  localState: CrudState = CrudState.Initial;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private form: FormBuilder,
    public blogState: BlogEditorStateService,
    private consoleRouter: ConsoleRouterService,
  ) { }

  toggleEditMode() {
    this.localState = CrudState.Update;
  }

  get isEditModeDisabled() {
    if (!this.blogForm) { return true; }
    const isDisabled = !(
      this.localState === CrudState.Create ||
      this.localState === CrudState.Update
    );
    if (isDisabled) {
      this.blogForm.disable();
    } else {
      this.blogForm.enable();
    }
    return isDisabled;
  }

  setupFormWithState(postId?: number) {
    this.blogForm = this.createForm();
    if (!postId) {
      this.localState = CrudState.Create;
      return;
    }
    this.blogState.retrieve(postId);
    this.blogState.state
      .pipe(take(2))
      .subscribe(this.instanceFormWithData.bind(this));
  }

  private instanceFormWithData(state: BlogEditorState) {
    if (state.blog) {
      this.blogForm = this.createForm(state.blog);
      // this.pickedInstruments(state.collection.instruments);
      this.localState = CrudState.Retrieved;
      this.blogForm.disable();
    }
  }

  private createForm(post: Partial<any> = {}) {
    return this.form.group({
      id: [post.id, Validators.required],
      title: [post.title, Validators.required],
      category: [post.category, Validators.required],
      pageUrl: [post.pageUrl, Validators.required],
      imageUrl: [post.imageUrl, Validators.required],
      summary: [post.summary, Validators.required],
      content: [post.content, Validators.required],

    });
  }

  delete() {
    const { id } = this.blogForm.value;
    this.blogState.delete(id);
  }

  get news$(){
    return this.blogState.state.pipe(
      map((state=> state.blog))
    )
  }

  updateNews(){
      this.blogState.update(this.blogForm.value)
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params) => {
        const id = params["id"];
        if (id) {
          this.blogState.retrieve(id);
          this.setupFormWithState(id)
        } else {
          this.consoleRouter.productNavigate(["blog"]);
        }
      }
    );
  }

}
