import { Injectable } from '@angular/core';
import {
  EnterpriseAPIService,
  ReqSuccessResponse,
} from '@console/api';
import { Observable } from 'rxjs';
import { IVirtualAccount } from './virtual-account.interface';

const API_VIRTUAL_ACCOUNT = 'api/v1/vaccounts/create';

@Injectable()
export class VirtualAccountService {
  constructor(
    private http: EnterpriseAPIService
  ) { }

  create(userId: string): Observable<ReqSuccessResponse<IVirtualAccount[]>> {
    return this.http.post<ReqSuccessResponse<IVirtualAccount[]>>(API_VIRTUAL_ACCOUNT, { userId });
  }

}
