import { Router } from '@angular/router';
import { ConsoleRouterService } from './../../../../../../shared/src/lib/services/console-router.service';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  Collection,
  CollectionInput,
} from '.././services/content.interface';
import { CollectionsService } from '../services/collections.service';
import { ChakaAPIError, ReqSuccessResponse } from '../../../../../../api/src/public-api';
import { NgxSpinnerService } from 'ngx-spinner';

export enum CrudState {
  Retrieve = 'Retrieve',
  Retrieved = 'Retrieved',
  Create = 'Create',
  Created = 'Created',
  Update = 'Update',
  Updated = 'Updated',
  Delete = 'Delete',
  Deleted = 'Deleted',
  Initial = 'Initial',
  Error = 'Error',
}


export interface CollectionEditorState {
  collection?: Collection;
  loading: boolean;
  error?: string;
  // status: CrudState;
  message?: string;
}

const initalState: CollectionEditorState = {
  loading: false,
  // status: CrudState.Initial,
};


@Injectable({ providedIn: 'root' })
export class CollectionEditorStateService {
  state = new BehaviorSubject<CollectionEditorState>(initalState);

  constructor(private svc: CollectionsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private route: Router) { }

  create(collection: CollectionInput, callBack?: () => any) {
    this.loading();
    this.svc.create(collection).subscribe({
      next: (data) => {
        this.saveCollection(data, callBack)
      },
      error: this.onCollectionError.bind(this),
    });
  }

  retrieve(collectionId: string) {
    this.loading();
    this.svc.retrieve(collectionId)
      .pipe(first())
      .subscribe({
        next: this.updateCollection.bind(this),
        error: this.onCollectionError.bind(this),
      });
  }

  update(collection: CollectionInput) {
    this.loading();
    this.svc.update(collection).subscribe({
      next: this.updateSavedCollection.bind(this),
      error: this.onCollectionError.bind(this),
    });
  }

  delete(collectionId: string) {
    this.loading();
    this.svc.delete(collectionId).subscribe({
      next: this.deleteCollection.bind(this),
      error: this.onCollectionError.bind(this),
    });
  }

  resetState() {
    this.state.next(initalState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
      // status: CrudState.Initial,
    });
  }

  get loading$() {
    return this.state.pipe(map(state => state.loading));
  }

  private updateCollection(data: ReqSuccessResponse<Collection>) {
    this.state.next({
      loading: false,
      collection: data.data,
      message: "Loading Successful"
    });
    this.spinner.hide();

    // this.route.navigate(['/equity/collection']);
  }

  private saveCollection(data: ReqSuccessResponse<Collection>, callBack?: () => any) {
    this.state.next({
      loading: false,
      collection: data.data,
      message: "Loading Successful"
    });
    this.toastr.success('Collection Created Successfully', 'Success', {
      timeOut: 3000
    })
    this.spinner.hide();


    callBack && callBack();
  }

  private updateSavedCollection(data: ReqSuccessResponse<Collection>) {
    this.state.next({
      loading: false,
      collection: data.data,
      message: "Collection Updated Successfully",

    });
    this.toastr.success('Collection Updated Successfully', 'Success', {
      positionClass: 'toast-bottom-center',
      timeOut: 3000
    })
    this.spinner.hide();
    this.route.navigate(['/equity/collection']);

  }

  private deleteCollection(data: ReqSuccessResponse<Collection>) {
    this.state.next({
      loading: false,
      collection: data.data,
      message: "Collection Deleted Successfully",

    });
    this.toastr.success('Collection Deleted Successfully', 'Success', {
      positionClass: 'toast-bottom-center',
      timeOut: 3000
    })
    this.spinner.hide();
    this.route.navigate(['/equity/collection']);

  }


  private onCollectionError(res: ChakaAPIError) {
    this.state.next({
      loading: false,
      error: res.error.message,
      // status: CrudState.Error,
    });
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: "Loading"
    });
  }
}
