import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSetting } from '../services/settings.interface';
import { SettingService } from '../services/app.service';
import {
  ReqSuccessResponse,
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList,
} from '../../../../../api/src/public-api';

export interface AppSettingListState {
  settings: AppSetting[];
  loading: boolean;
  error?: string;
  count?: number;
}

const initialState: AppSettingListState = {
  loading: false,
  settings: [],
};

@Injectable({ providedIn: 'root' })
export class AppSettingListStateService {
  private subject$ = new BehaviorSubject<AppSettingListState>(initialState);

  state$ = this.subject$.asObservable();

  constructor(private setting: SettingService) {}

  find(query: Record<string, any> = {}) {
    this.loading();
    this.setting.find(query).subscribe({
      next: this.onSettingsLoaded.bind(this),
      error: this.onSettingsError.bind(this),
    });
  }

  reset() {
    this.subject$.next(initialState);
  }

  get loading$() {
    return this.subject$.pipe(map((state) => state.loading));
  }

  private loading() {
    this.subject$.next({ ...this.subject$.getValue(), loading: true });
  }

  private onSettingsLoaded({ data, count }: PaginatedList<AppSetting>) {
    this.subject$.next({
      settings: data,
      count,
      loading: false,
    });
  }

  private onSettingsError(res: ChakaAPIError) {
    this.subject$.next({
      ...this.subject$.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
    });
  }
}
