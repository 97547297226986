<div class="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader">
  <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Details-->
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ section }}</h5>
      <!--end::Title-->
      <!--begin::Separator-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
      <!--end::Separator-->
      <!--begin::Search Form-->
      <div class="d-flex align-items-center" id="kt_subheader_search">
        <span *ngIf="count" class="text-dark-50 font-weight-bold" id="kt_subheader_total">{{ count }} Total</span>
        <form class="ml-5" (submit)="onSubmit($event)">
          <div class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <input
              type="text"
              class="form-control"
              id="kt_subheader_search_form"
              [placeholder]="placeholder ? placeholder :  section+' Search'"
              [disabled]="disabled"
              (keyup)="onSearch.emit($event.target.value)">
            <div class="input-group-append">
              <span class="input-group-text">
                <span class="svg-icon">
                  <i class="fas fa-search"></i>
                </span>
              </span>
            </div>
          </div>
        </form>
      </div>
      <!--end::Search Form-->

    </div>
    <!--end::Details-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Button-->
      <a href="javascript:;"
        class="btn btn-light-primary font-weight-bold ml-2"
        (click)="actionBtn.emit($event)">{{ actionText }}</a>
      <!--end::Button-->

    </div>
    <!--end::Toolbar-->
  </div>
</div>
