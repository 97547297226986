/**
 * UtilService
 *
 * UtilService contains the other external endpoints used by the
 * user management
 *
 * Oyegoke Abiodun 02-09-2020
 */

import { Injectable } from '@angular/core';
import { EnterpriseAPIService, ReqSuccessResponse } from '@console/api';
import { CountryModel } from '../models/user.model';
import { BankModel } from './interface';

const API_COUNTRIES = '/api/v1/language/countries';
const API_BANKS = '/api/v1/banks';

@Injectable()
export class UtilService {
  constructor(private http: EnterpriseAPIService) {}

  /**
   * returns a list of countries
   */
  countries(searchParams?) {
    return this.http.get<ReqSuccessResponse<CountryModel[]>>(
      API_COUNTRIES,
      searchParams
    );
  }

  banks(searchParams?) {
    return this.http.get<ReqSuccessResponse<BankModel[]>>(
      API_BANKS,
      {...searchParams, pageSize: 1000}
    );
  }
}
