import { AccountDialogComponent } from './components/account-dialog/account-dialog.component';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { BankStateService } from '@console/user-managements/sections/user-kyc-editor/bank-state.service';
import { Bank } from '@console/user-managements/sections/user-kyc-editor/kyc-util.interface';
import { IMerchantBankAccount } from './interface/IChakaAccount';
import { ChakaAccountService } from './service/chaka-account.service';
import { Currency } from '@console/shared/_enums/currency.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  templateUrl: './chaka-accounts.component.html',
  styleUrls: ['./chaka-accounts.component.scss'],
})
export class ChakaAccountsComponent implements OnInit, OnDestroy {
  existingAccounts: IMerchantBankAccount[];
  bankList: Bank[];

  currencyEnum = Currency;

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private chakaAccountsService: ChakaAccountService,
    private dialog: MatDialog,
    private banks: BankStateService,
    private messagesService: MessagesService
  ) {}

  ngOnInit(): void {
    this.fetchAccounts();
    this.fetchBanks();
  }

  fetchAccounts(): void {
    this.chakaAccountsService.fetchAccounts().subscribe((response) => {
      this.existingAccounts = response.data;
    });
  }

  fetchBanks(): void {
    this.banks.list();

    this.banks.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((bank) => {
        this.bankList = bank.banks;
      });
  }

  resolveBankName(bankId: number): string {
    try {
      return this.bankList.find((bank) => bank.id === bankId).name;
    } catch (error) {
      return '';
    }
  }

  deleteAccount(accountId: number): void {
    const check = confirm('Do you want to delete this account?');

    if (!check) {
      return;
    }

    this.chakaAccountsService.deleteAccount(accountId).subscribe({
      next: () => this.fetchAccounts(),
    });
  }

  openDialog(account?: IMerchantBankAccount): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.panelClass = 'custom-dialog-container';

    const data: { bankList: Bank[]; account?: IMerchantBankAccount } = {
      bankList: this.bankList,
    };

    if (account) {
      data.account = account;
    }

    dialogConfig.data = data;

    this.messagesService.hideAll();

    const dialogRef = this.dialog.open(AccountDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      result && this.fetchAccounts();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();
  }
}
