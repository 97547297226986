import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICONFIG, EnterpriseAuthService, BaseApiService, ReqSuccessResponse, EnterpriseAPIService } from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';

import {
  AuthCredential,
  AuthUserDetails,
  LoginResponse,
} from '../authentication.interface';

@Injectable()
export class AuthService {
  private API_LOGIN_URL = 'oauth/token';

  constructor(
    private config: APICONFIG,
    private http: HttpClient,
    private client: EnterpriseAuthService,
    private baseClient: BaseApiService,
    private enterpriseApiService: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  login(credential: AuthCredential) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic Y2hha2Etc2VydmljZTpzZWNyZXQ=`,
    };

    const body = new URLSearchParams({
      scope: 'profile',
      grant_type: 'password',
      ...credential,
    });

    const url = this.baseClient.cleanUrl(
      this.config.authURL,
      this.API_LOGIN_URL
    );

    return this.http.post<LoginResponse>(
      url,
      body.toString(), {
      headers,
    });
  }

  getUserByToken(): Observable<ReqSuccessResponse<AuthUserDetails>> {
    return this.client.get<ReqSuccessResponse<AuthUserDetails>>('api/v1/users/me');
  }

  register(user: AuthCredential) {
    return this.enterpriseApiService.post<ReqSuccessResponse<AuthUserDetails>>(`/api/v1/users/signup`,
      user
    );
  }

  registerAdmin(user: AuthCredential) {
    return this.client.post<ReqSuccessResponse<AuthUserDetails>>(`/api/v1/users/signup`,
      user
    );
  }

  toggleAccountLock(userId: string, lockStatus: boolean) {
    const request$ = this.client.put<ReqSuccessResponse<AuthUserDetails>>(
      `/api/v1/users/account/${userId}?locked=${lockStatus}`
    );

    return this.loadingService.showLoaderUntilCompleted(request$);
  }
}
