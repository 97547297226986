<section-search-header
  [section]="title"
  (onSearch)="search($event)"
  [count]="(userList.state | async).count"
  [loading]="(userList.state | async).loading"
  [actionText]="'Create  Customer'"
  (actionBtn)="createNewUser()"></section-search-header>
<div>
  <router-outlet
    (activate)="childRoute = true"
    (deactivate)="childRoute = false"></router-outlet>
</div>



<div class="d-flex flex-column-fluid">

  <!--begin::Container-->
  <ng-container *ngIf="!childRoute">
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-md-12 text-right">
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-secondary"
              [ngClass]="currentView === viewTypes.GRID ? 'active' : ''"
              (click)="changeView(viewTypes.GRID)">
              <i class="fas fa-th"></i> Grid
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              [ngClass]="currentView === viewTypes.LIST ? 'active' : ''"
              (click)="changeView(viewTypes.LIST)">
              <i class="fas fa-list"></i> List
            </button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="currentView === viewTypes.GRID else listView">
        <!--begin::Row-->
        <kt-settings-users-list
          [users]="(userList.state | async).users"
          (selected)="editUser($event.userId)"></kt-settings-users-list>
        <!--end::Row-->
        <lib-pagination (paginate)="paginate($event)" [count]="(userList.state | async).count"></lib-pagination>
      </ng-container>

      <ng-template #listView>
        <app-user-table
          (selected)="editUser($event.userId)"></app-user-table>
      </ng-template>
    </div>

  </ng-container>
  <!--end::Container-->
</div>
