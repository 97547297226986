export const extractSearchParams = (formValues: {}): Record<string, any> => {
  const searchFilter = {};

  for (const [key, value] of Object.entries(formValues)) {
    if (value === null || value === '') {
      continue;
    }

    if (['from', 'to'].includes(key)) {

      if (key === 'from') {
        searchFilter[key] = new Date(value as Date);
      }

      if (key === 'to') {
        const today = new Date();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

        searchFilter[key] = new Date(`${value} ${time}`);
      }

      searchFilter[key] = searchFilter[key].toISOString();

      continue;
    }

    if (typeof value === 'string') {
      searchFilter[key] = value.trim();
    }

    searchFilter[key] = value;
  }

  return searchFilter;
};
