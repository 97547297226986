import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, EnterpriseAPIService, PaginatedList, ReqSuccessResponse } from '@console/api';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Observable, throwError } from 'rxjs';
import { IAuditLog } from '../interface/IAuditLog';
import { catchError, finalize } from 'rxjs/operators';

const AUDIT_LOG_URL = 'api/v1/audit_logs';
@Injectable()
export class AuditLogService {

  constructor(
    private messagesService: MessagesService,
    private http: EnterpriseAPIService) {}

  public fetchLogs(searchQuery: object): Observable<PaginatedList<IAuditLog>> {

    const { id } = this.messagesService.open(MessagesEnum.loading, 'Fetching logs...', { hideAll: true });

    return this.http.get<PaginatedList<IAuditLog>>(AUDIT_LOG_URL, searchQuery)
      .pipe(
        finalize(() => this.messagesService.hide(id)),
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        ));
  }

  public fetchLogById(logId: number): Observable<ReqSuccessResponse<IAuditLog>> {
    return this.http.get<ReqSuccessResponse<IAuditLog>>(`${AUDIT_LOG_URL}/find_by_id/${logId}`);
  }

  private handleError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);

    this.messagesService.open(MessagesEnum.danger, message);
  }
}
