import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToPercent',
})
export class DecimalToPercentPipe implements PipeTransform {
  transform(value: number, precision: number = 2): string {
    if (!value && value !== 0) {
      return;
    }

    return (Number(value) * 100).toFixed(precision) + '%';
  }
}
