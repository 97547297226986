<div class="row">
  <ngx-spinner
  [color]="'#ffffff'"
  [name]="requery.spinnerName"
  [fullScreen]="false"
  type="ball-clip-rotate-multiple"
  size="medium"
>
  <p class="loading">{{ (requery.state | async).message }}</p>
</ngx-spinner>
  <div class="col-lg-12">
    <form [formGroup]="transferForm">
      <div class="form-row">
        <div class="col-lg-12 form-group">
          <label for="amount">Transaction Reference </label>
          <input type="text" id="amount" class="form-control" formControlName="transactionReference" />
        </div>
        <div class="col-lg-6 form-group">
          <label for="from">Processor </label>
          <select class="form-control" id="from" formControlName="source">
            <option
            [value]="item.value"
            *ngFor="let item of processors"
            >{{ item.name }}
          </option>
          </select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="from"> Destination </label>
          <select class="form-control" id="from" formControlName="currency">
            <option *ngFor="let wallet of wallets" [value]="wallet.value">
              {{ wallet.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-4 kt-margin-bottom-20-mobile">
          <button
            [disabled]="!transferForm.valid"
            href="javascript:;"
            class="btn btn-primary w-100"
            (click)="onSubmit()"
          >
            Requery
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
