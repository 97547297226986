<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"
            >Manage Supported Documents</span
          >
          <span class="text-muted mt-3 font-weight-bold font-size-sm"
            >Add, edit and update supported documents</span
          >
        </h3>
        <div class="card-toolbar">
          <button
            href="javascript:;"
            mat-button
            (click)="openDialog()"
            class="btn btn-primary font-weight-bolder font-size-sm"
          >
            <span class="svg-icon svg-icon-md svg-icon-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect
                    fill="#000000"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                </g>
              </svg> </span
            >Add Supported Document
          </button>
        </div>
      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <lib-messages></lib-messages>

        <!--begin::Table-->
        <div class="row">
          <div class="col-12">
            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="
                  table
                  table-head-custom
                  table-head-bg
                  table-vertical-center
                  table-borderless
                "
              >
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th>
                      <span class="text-dark-75"> Name</span>
                    </th>

                    <th>
                      <span class="text-dark-75">Type</span>
                    </th>

                    <th>
                      <span class="text-dark-75">Description</span>
                    </th>

                    <th class="text-center">
                      <span class="text-dark-75">Action</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let supportedDocument of supportedDocuments">
                    <td>
                      {{ supportedDocument?.name }}
                    </td>
                    <td>
                      {{ supportedDocument?.documentType }}
                    </td>
                    <td>
                      {{ supportedDocument?.description }}
                    </td>

                    <td class="text-center">
                      <span
                        style="
                          overflow: visible;
                          position: relative;
                          width: 125px;
                        "
                      >
                        <a
                          href="javascript:;"
                          (click)="openDialog(supportedDocument)"
                          class="btn btn-sm btn-clean btn-icon mr-2"
                        >
                          <i class="fas fa-edit"></i>
                        </a>

                        <a
                          href="javascript:;"
                          (click)="
                            deleteSupportedDocument(supportedDocument.id)
                          "
                          class="btn btn-sm btn-clean btn-icon"
                        >
                          <i class="fas fa-trash"></i>
                        </a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->

            <ng-template #noData>
              <div
                *ngIf="!loading"
                class="
                  no-data-container
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <img
                  class="no-data-img"
                  src="assets/images/empty.svg"
                  alt="empty"
                />
                <p class="no-data-text">No data</p>
              </div>
            </ng-template>
          </div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
    </div>
  </div>
  <!--end::Container-->
</div>
