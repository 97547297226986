<div class="row">
  <ngx-spinner
    [color]="'#ffffff'"
    [name]="exchange.spinnerName"
    [fullScreen]="false"
    type="ball-clip-rotate-multiple"
    size="medium">
    <!-- <p class="loading">{{ (exchange.state | async).message }}</p> -->
  </ngx-spinner>
  <div class="col-lg-12">
    <form [formGroup]="transferForm">
      <div class="form-row">
        <div class="col-lg-12 form-group">
          <label for="amount">Amount </label>
          <input min="0" id="amount" class="form-control" formControlName="amount" />
        </div>
        <div class="col-lg-6 form-group">
          <label for="from">From </label>
          <select class="form-control" id="from" formControlName="from">
            <option *ngFor="let wallet of wallets" [value]="wallet.value">
              {{ wallet.label }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="from">To </label>
          <select class="form-control" id="from" formControlName="to">
            <option *ngFor="let wallet of wallets" [value]="wallet.value">
              {{ wallet.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-4 kt-margin-bottom-20-mobile">
          <button
            [disabled]="!transferForm.valid"
            href="javascript:;"
            class="btn btn-primary w-100"
            (click)="onSubmit()">
            Exchange
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
