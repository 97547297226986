import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DashboardService } from './../dashboard.service';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AuthUserDetails } from '@console/authentication/authentication.interface';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';

/** Custom options the configure the tooltip's default show/hide delays. */
export const customTooltipDefault: Partial<MatTooltipDefaultOptions> = {
  touchendHideDelay: 1000,
};
@Component({
  selector: 'app-quick-user',
  templateUrl: './quick-user.component.html',
  styleUrls: ['./quick-user.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: customTooltipDefault,
    },
  ],
})
export class QuickUserComponent implements OnInit, OnDestroy {
  @Input() loginProfile: AuthUserDetails;
  @ViewChild('userLogoutDiv') userLogoutDiv: ElementRef;

  tooltipText = 'Copy';

  @Output() logOut = new EventEmitter();

  destroyed$: Subject<boolean> = new Subject();

  showOverlay = false;

  constructor(
    private renderer: Renderer2,
    public dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.dashboardService.logoutDiv$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: boolean) => {
        event ? this.showLogoutDiv() : this.hideLogoutDiv();
      });
  }

  getFirstName(): string {
    try {
      const getFromEmail = () => this.loginProfile?.email.split('@')[0];

      const firstName = this.loginProfile?.firstName || getFromEmail();
      return firstName;
    } catch (error) {
      return '';
    }
  }

  getFullName(): string {
    try {
      const { firstName, lastName } = this.loginProfile;

      let fullName = '';
      if (!!firstName && !!lastName) {
        fullName = firstName + ' ' + lastName;
      }

      return fullName || this.getFirstName();
    } catch (error) {
      return '';
    }
  }

  getRole(): string {
    return this.loginProfile?.role || '';
  }

  getEmail(): string {
    return this.loginProfile?.email || '';
  }

  resetToolTipText(): void {
    this.tooltipText = 'Copy';
  }

  copyEmail(): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.getEmail();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.tooltipText = 'Copied!';

    setTimeout(() => {
      this.resetToolTipText();
    }, 3000);
  }

  showLogoutDiv(): void {
    const userLogoutDiv = this.userLogoutDiv.nativeElement;
    const isShown = userLogoutDiv.classList.contains('offcanvas-on');

    if (!isShown) {
      this.renderer.addClass(userLogoutDiv, 'offcanvas-on');

      this.showOverlay = true;
    }
  }

  hideLogoutDiv(): void {
    const userLogoutDiv = this.userLogoutDiv.nativeElement;
    const isShown = userLogoutDiv.classList.contains('offcanvas-on');

    if (isShown) {
      this.renderer.removeClass(userLogoutDiv, 'offcanvas-on');

      this.showOverlay = false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
