<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <span class="card-icon">
        <i class="flaticon2-chat-1 text-primary"></i>
      </span>
      <h3 class="card-label">KYC VERIFICATION</h3>
    </div>
    <div class="card-toolbar">
      <button *ngIf="(kycVerification.state | async).kyc.NGA === 'VERIFIED'" (click)="kycVerification.iwUpload(userId)"
        class="btn btn-sm btn-secondary font-weight-bold">
        <i class="fas fa-chevron-circle-up"></i> IW Details Update
      </button>

      <button *ngIf="(kycVerification.state | async).kyc.US === 'VERIFIED'" (click)="kycVerification.dwUpload(userId)"
        class="btn btn-sm btn-secondary font-weight-bold ml-4">
        <i class="fas fa-cloud-upload-alt"></i> DW Upload
      </button>

      <button (click)="openBvnSideNav()" class="btn btn-sm btn-secondary font-weight-bold ml-4">
        <i class="fas fa-search"></i> Search BVN
      </button>

      <button (click)="openNinDrawer()" class="btn btn-sm btn-secondary font-weight-bold ml-4">
        <i class="fas fa-search"></i> NIN Verification
      </button>
    </div>
  </div>

  <div class="card-body">
    <div *ngIf="(kycVerification.state | async).kyc as state" class="row">
      <div class="col-md-6">
        <label for="inputState">Global {{ state.US }}</label>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <select [formControl]="globalKycStatus" class="form-control" placeholder="--Select--">
                <option *ngFor="let status of allKycStatus" [value]="status?.value">
                  {{ status?.description }}
                </option>
              </select>
              <small *ngIf="!globalKycStatus.valid && globalKycStatus.touched" class="form-text text-danger">Global KYC
                status is required</small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <button (click)="approve('US', globalKycStatus.value)" type="button" class="btn btn-primary">
                Update Global Status
              </button>
            </div>
          </div>

          <div *ngIf="renderRejectionEmailBtn()" class="col-md-6">
            <div class="form-group">
              <button (click)="openTableDialog()" type="button" class="btn btn-primary">
                Send Rejection Email
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <label for="inputState">Local {{ state.NGA }}</label>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <select [formControl]="localKycStatus" class="form-control">
                <option *ngFor="let status of allKycStatus" [value]="status.value">
                  {{ status?.description }}
                </option>
              </select>
              <small *ngIf="!localKycStatus.valid && localKycStatus.touched" class="form-text text-danger">Local KYC
                status is required</small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <button (click)="approve('NGA', localKycStatus.value)" type="button" class="btn btn-primary">
                Update Local Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- fields -->
      <div class="col-md-6" *ngIf="!(kyc.state | async).loading">
        <lib-user-biodata-form [biodata]="(kyc.state | async).kyc" [disabled]="true"></lib-user-biodata-form>

        <lib-user-address-form [disabled]="true" [address]="(kyc.state | async).kyc"></lib-user-address-form>
      </div>
      <!-- Photo ID verfication -->
      <div class="col-md-6">
        <div>
          <label for="inputState">CSCS TOKEN</label>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input id="css-token-input" class="form-control" />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <button type="button" (click)="submitCSCSToken()" class="btn btn-success">
                  Submit
                </button>
              </div>
            </div>
          </div>

          <!-- <p *ngIf="!(cscs.state | async).loading" style="color: red"> {{ (cscs.state | async).message }} </p> -->
        </div>

        <div style="max-height: 500px; overflow: auto" *ngIf="!(kyc.state | async).loading">
          <div class="row">
            <div class="col-12">
              <img style="max-width: 600px" [src]="(kyc.state | async).kyc?.profileImageUrl" alt="" />

              <input ngFileSelect type="file" [options]="options" (uploadOutput)="onUploadOutput($event, 'PASSPORT')"
                [uploadInput]="uploadInput" />
              <!-- <p>ADDRESS FILE URL: {{ (kyc.state | async).kyc?.addressProofUrl }} </p> -->
              <p>Passport Image.</p>
            </div>

            <div class="col-12">
              <img *ngIf="!(selectPdf$('photoIdUrl') | async)" style="max-width: 600px"
                [src]="(kyc.state | async).kyc?.photoIdUrl" alt="" />
              <button *ngIf="selectPdf$('photoIdUrl') | async" (click)="openPdF('photoIdUrl')" class="btn btn-primary">
                OPEN PHOTO ID PDF
              </button>
            </div>

            <div class="col-12">
              <input ngFileSelect type="file" [options]="options" (uploadOutput)="onUploadOutput($event, 'PHOTO_ID')"
                [uploadInput]="uploadInput" />
              <!-- <p>PHOTO FILE URL: {{ (kyc.state | async).kyc?.photoIdUrl }}  </p> -->

              <p>Photo ID proof</p>
            </div>

            <div class="col-12">
              <img *ngIf="!(selectPdf$('addressProofUrl') | async)" style="max-width: 600px"
                [src]="(kyc.state | async).kyc?.addressProofUrl" alt="" />
              <button *ngIf="selectPdf$('addressProofUrl') | async" (click)="openPdF('addressProofUrl')"
                class="btn btn-success">
                OPEN ADDRESS PROOF PDF
              </button>
            </div>
            <div class="col-12">
              <input ngFileSelect type="file" [options]="options"
                (uploadOutput)="onUploadOutput($event, 'ADDRESS_PROOF')" [uploadInput]="uploadInput" />
              <p>Address proof</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- #kycActionModal -->
  <ngx-smart-modal [identifier]="pdfModal" ariaLabel="pdfModal">
    <pdf-viewer [src]="pdfLink" [render-text]="true" style="display: block"></pdf-viewer>
  </ngx-smart-modal>

  <!-- side nav -->

  <mat-sidenav #bvnSideNav mode="over" position="end" (closed)="closeBvnSideNav()">
    <div class="card card-custom">
      <div class="card-header">
        <h3 class="card-title">Search BVN</h3>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <a href="javascript:;" (click)="this.closeBvnSideNav()" class="btn btn-sm">
              <i class="far fa-times-circle"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div *ngIf="bvnError" class="form-group">
          <div class="alert alert-custom alert-default" role="alert">
            <div class="alert-text">
              {{ bvnError }}
            </div>
            <div class="alert-close">
              <button type="button" class="close" data-dismiss="alert">
                <i class="far fa-times-circle"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>BVN Number <span class="text-danger">*</span></label>
          <input type="text" class="form-control" [formControl]="bvnSearch" (ngModelChange)="debounceBvnChange()" />
          <small class="form-text text-danger" *ngIf="!bvnSearch.valid && bvnSearch.touched">
            <div *ngIf="bvnSearch.hasError('required')">Input is required</div>

            <div *ngIf="bvnSearch.hasError('pattern')">
              BVN must be 11 digits
            </div>
          </small>
        </div>

        <div *ngIf="bvnDetails" class="pt-2">
          <!--begin::User-->
          <div class="text-center">
            <div class="symbol symbol-100">
              <img *ngIf="bvnDetails?.passport; else noPassport"
                [src]="'data:image/png;base64,' + bvnDetails?.passport | safe" [alt]="bvnDetails?.firstName" />

              <ng-template #noPassport>
                <i class="fas fa-user fa-4x"></i>
              </ng-template>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Contact-->
          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">First Name:</span>
              <span class="text-muted">{{
                bvnDetails?.firstName | titlecase
                }}</span>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Middle Name:</span>
              <span class="text-muted">{{
                bvnDetails?.middleName | titlecase
                }}</span>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Last Name:</span>
              <span class="text-muted">{{
                bvnDetails?.lastName | titlecase
                }}</span>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">BVN:</span>
              <span class="text-muted">{{ bvnDetails?.bvn }}</span>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Date of Birth:</span>
              <span class="text-muted">{{ bvnDetails?.dob }}</span>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Phone Number:</span>
              <span class="text-muted">{{ bvnDetails?.phoneNumber }}</span>
            </div>

            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">Gender:</span>
              <span class="text-muted text-hover-primary">{{
                bvnDetails?.gender | titlecase
                }}</span>
            </div>
          </div>
          <!--end::Contact-->
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- NIN DRAWER -->
  <mat-drawer #ninDrawer mode="over">
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">NIN Verification</span>
        </h3>
        <div class="card-toolbar">
          <button class="close" mat-button (click)="ninDrawer.close()">
            X
          </button>
        </div>
      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <div #scrollDiv style="height: 700px; overflow: auto" class="scrolling-div">
          <div class="form-group">
            <label>NIN Number <span class="text-danger">*</span></label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" [formControl]="ninSearch" minlength="11" maxlength="11" />
              <div class="input-group-append">
                <button [disabled]="!ninSearch.valid" class="btn btn-primary" type="button" (click)="onNinSubmit()">
                  Verify
                </button>
              </div>
            </div>

            <small class="form-text text-danger" *ngIf="!ninSearch.valid && ninSearch.touched">
              <div *ngIf="ninSearch.hasError('required')">
                Input is required
              </div>

              <div *ngIf="ninSearch.hasError('pattern')">
                NIN must be 11 digits
              </div>
            </small>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </mat-drawer>
</div>