<div *ngIf="instrument" class="company-information">
  <h3 class="content-title"> Company Information </h3>
  <p class="company-description"> {{ description }} </p>
  <p class="company-profile">
    <span class="label2"> CEO </span>
    <span class="value"> {{ CEO }} </span>
  </p>
  <p class="company-profile">
    <span class="label2"> Founded </span>
    <span class="value"> {{ founded }} </span>
  </p>
  <p class="company-profile">
    <span class="label2"> HeadQuaters </span>
    <span class="value"> {{ headquarters }} </span>
  </p>
</div>
