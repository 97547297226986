import { Currency } from './../../../../../shared/src/lib/_enums/currency.enum';
import { ReportEnum } from './../../enums/ReportEnum';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Chart, ChartType } from 'src/assets/js/Chart.min.js';
import { AnalyticTabs } from '../../enums/analyticsTabs';
import { MerchantAnalyticState, MerchantAnalyticStateService } from '../../states/analytics.state';
import { DatePipe } from '@angular/common';
import { formatToCurrency } from '@console/shared/utils/formatter';

@Component({
  selector: 'analytics-mixed-graph',
  templateUrl: './analytics-mixed-graph.component.html',
  styleUrls: ['./analytics-mixed-graph.component.scss'],
  providers: [DatePipe]
})

export class AnalyticsMixedGraphComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  loading = false;
  activeReportTab = '';

  analyticsTabs = AnalyticTabs;

  stats;

  @ViewChild('chartDiv', { static: true }) chartDiv!: ElementRef;

  chartInstance: any;

  chartType: ChartType = 'bar';

  details: { label: string, value: string | number }[] = [];

  constructor(
    private analytics: MerchantAnalyticStateService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.listenOnState();
  }

  private listenOnState(): void {
    // this.loading = true;
    this.analytics.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: MerchantAnalyticState) => {
          this.loading = state.loading;
          this.activeReportTab = state.activeReport;

          this.stats = state.stats;

          //weak implementation fix later
          this.extractData(state.stats);
        }
      );
  }

  get tabDetails(): { name: string, value: string } {
    return this.analyticsTabs.find(tab => tab.value === this.activeReportTab);
  }

  private extractData(statData): void {

    const { orders, payments, transactions, users } = statData;

    if (orders.length < 1 || payments.length < 1 || transactions.length < 1 || users.length < 1) {
      return;
    }

    // console.log(orders);
    // console.log(payments);
    // console.log(transactions);
    // console.log(users);

    let labelData: string[] = [];

    let dataset: Chart.ChartDataSets[] = [];

    const barThickness = 8;
    const borderRadius = 10;

    switch (this.activeReportTab) {
      case ReportEnum.ORDERS:
        labelData = orders.map(({ dateCreated }) => new Date(dateCreated).toDateString());

        dataset = [
          {
            label: 'Buy Count',
            data: orders.map(({ buyCount }) => buyCount),
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            barThickness,
            borderRadius
          },
          {
            label: 'Sell Count',
            data: orders.map(({ sellCount }) => sellCount),
            backgroundColor: 'white',
            barThickness,
            borderRadius
          }
        ];

        this.details = [
          { label: 'Buy Count', value: orders[0].buyCount },
          { label: 'Sell Count', value: orders[0].sellCount },
          { label: 'Most Purchased Global Instrument', value: orders[0].mostPurchasedGlobalInstrument },
          { label: 'Most Purchased Local Instrument', value: orders[0].mostPurchasedLocalInstrument },
          { label: 'Date', value: this.datePipe.transform(orders[0].dateCreated, 'mediumDate') }
        ];
        break;

      case ReportEnum.PAYMENTS:
        labelData = payments.map(({ dateCreated }) => new Date(dateCreated).toDateString());

        dataset = [
          {
            label: 'NGN Funding Count',
            data: payments.map(({ ngnFundingCount }) => ngnFundingCount),
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            barThickness,
            borderRadius
          },
          {
            label: 'USD Funding Count',
            data: payments.map(({ usdFundingCount }) => usdFundingCount),
            backgroundColor: 'white',
            barThickness,
            borderRadius
          }
        ];

        this.details = [
          { label: 'NGN Funding Count', value: payments[0].ngnFundingCount },
          { label: 'NGN Funding Value', value: formatToCurrency(Currency.NGN, payments[0].ngnFundingValue) },
          { label: 'USD Funding Count', value: payments[0].usdFundingCount },
          { label: 'USD Funding Value', value: formatToCurrency(Currency.USD, payments[0].usdFundingValue) },
          { label: 'Date', value: this.datePipe.transform(payments[0].dateCreated, 'mediumDate') }
        ];
        break;

      case ReportEnum.TRANSACTIONS:
        labelData = transactions.map(({ dateCreated }) => new Date(dateCreated).toDateString());

        dataset = [
          {
            label: 'Total Global Credit Count',
            data: transactions.map(({ totalGlobalCreditCount }) => totalGlobalCreditCount),
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            barThickness,
            borderRadius
          },
          {
            label: 'Total Global Debit Count',
            data: transactions.map(({ totalGlobalDebitCount }) => totalGlobalDebitCount),
            backgroundColor: 'rgba(255, 255, 255, 0.45)',
            barThickness,
            borderRadius
          },
          {
            label: 'Total Local Credit Count',
            data: transactions.map(({ totalLocalCreditCount }) => totalLocalCreditCount),
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            barThickness,
            borderRadius
          },
          {
            label: 'Total Local Debit Count',
            data: transactions.map(({ totalLocalDebitCount }) => totalLocalDebitCount),
            backgroundColor: 'white',
            barThickness,
            borderRadius
          },
        ];

        this.details = [
          { label: 'Total Global Credit', value: formatToCurrency(Currency.USD, transactions[0].totalGlobalCredit) },
          { label: 'Total Global Debit', value: formatToCurrency(Currency.USD, transactions[0].totalGlobalDebit) },
          { label: 'Total Local Credit', value: formatToCurrency(Currency.NGN, transactions[0].totalLocalCredit) },
          { label: 'Total Local Debit', value: formatToCurrency(Currency.NGN, transactions[0].totalLocalDebit) },
          { label: 'Date', value: this.datePipe.transform(payments[0].dateCreated, 'mediumDate') }
        ];
        break;

      case ReportEnum.USERS:
        labelData = users.map(({ dateCreated }) => new Date(dateCreated).toDateString());

        dataset = [
          {
            label: 'Total Inactive Users',
            data: users.map(({ totalInActiveUser }) => totalInActiveUser),
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            barThickness,
            borderRadius
          },
          {
            label: 'Total Completed KYC User',
            data: users.map(({ totalCompletedKycUser }) => totalCompletedKycUser),
            backgroundColor: 'rgba(255, 255, 255, 0.45)',
            barThickness,
            borderRadius
          },
          {
            label: 'Total Verified Users',
            data: users.map(({ totalVerifiedUser }) => totalVerifiedUser),
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            barThickness,
            borderRadius
          },
          {
            label: 'Total Active Users',
            data: users.map(({ totalActiveUser }) => totalActiveUser),
            backgroundColor: 'white',
            barThickness,
            borderRadius
          },
        ];

        this.details = [
          { label: 'Total Active Users', value: users[0].totalActiveUser },
          { label: 'Total Completed Kyc Users', value: users[0].totalCompletedKycUser },
          { label: 'Total Inactive Users', value: users[0].totalInActiveUser },
          { label: 'Total New Users', value: users[0].totalNewUser },
          { label: 'Total Verified Users', value: users[0].totalVerifiedUser },
          { label: 'Date', value: this.datePipe.transform(users[0].dateCreated, 'mediumDate') }
        ];
        break;

      default:
        break;
    }

    this.renderChart(labelData, dataset);
  }

  renderChart(labelData: string[], dataSets: Chart.ChartDataSets[]): void {
    Chart.platform.disableCSSInjection = true;

    const chartContext = this.chartDiv.nativeElement.getContext('2d');

    // to fix flickering issue
    if (this.chartInstance && this.chartInstance.chart !== null) {
      this.chartInstance.destroy();
    }

    this.chartInstance = new Chart(chartContext, {
      type: this.chartType,
      data: {
        labels: labelData,
        datasets: dataSets,
      },
      options: {
        animation: {
          duration: 0 // general animation time
        },
        hover: {
          animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          gridLines: {
            display: true
          },
          yAxes: [{
            display: false,
          }],
          xAxes: [{
            display: false
          }]
        },
        elements: {
          point: {
            radius: 0
          },
          line: {
            tension: 0 // disables bezier curves
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

}
