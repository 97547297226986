import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DocumentPreviewComponent } from './document-preview.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentPreviewService {
  dialogRef!: MatDialogRef<any>;

  constructor(public dialog: MatDialog) {}
  open(previewData: File) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'document-preview';

    dialogConfig.data = previewData;

    this.dialogRef = this.dialog.open(DocumentPreviewComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe((result: any) => {
      //we can use a call back here
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
