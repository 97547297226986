import { MessagesEnum } from './../../../../../shared/src/lib/components/messages/enums/messages.enums';
import { Injectable } from '@angular/core';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList
} from '../../../../../api/src/public-api';
import { OrderTxnsModel } from '../services/orders.model';
import { OrderTxnService } from '../services/orders.service';
import { downloadFile, downloadFileFromLink, exportAndDownload } from 'projects/_shared/csv-downloader/csv-downloader';
import { Papa } from 'ngx-papaparse';

export interface OrderListState {
  loading: boolean;
  count?: number;
  orders: OrderTxnsModel[];
  error?: string;
  message?: string;
}

const initialState: OrderListState = {
  loading: false,
  orders: [],
};

@Injectable({ providedIn: 'root' })
export class OrderListStateService {

  state = new BehaviorSubject<OrderListState>(initialState);

  spinnerName = 'Order-List';

  constructor(
    private orderSvc: OrderTxnService,
    private messagesService: MessagesService) { }

  find(query: object): void {
    this.loading();
    this.orderSvc
      .getOrderTxns(query)
      .pipe(first())
      .subscribe({
        next: this.updateOrders.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateOrders(data: PaginatedList<OrderTxnsModel>): void {
    this.state.next({
      loading: false,
      orders: data.data,
      count: data.count
    });
    // this.spinner.hide(this.spinnerName);
  }

  private onError(res: ChakaAPIError): void {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
      message: error,
    });
    // this.spinner.hide(this.spinnerName);
  }

  reset(): void {
    this.state.next(initialState);
  }

  private loading(): void {
    this.state.next({
      ...this.state.getValue(),
      loading: true
    });
    // this.spinner.show(this.spinnerName);
  }

  batchReQuery(orderIds, searchFilter, callback: (success: boolean) => void): void {
    const length = orderIds.length;
    const loadingMessage = `Re-querying ${length} ${length <= 1 ? 'order' : 'orders'} `;

    !!this.messagesService.totalOpened && this.messagesService.hideAll();

    this.messagesService.open(MessagesEnum.loading, loadingMessage);

    this.orderSvc
      .batchReQuery(orderIds)
      .subscribe({
        next: (response) => {
          this.find(searchFilter);

          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: `${response.data.length} orders was successfully re-queried`
            });

          !!callback && callback(true);
        },
        error: (err: ChakaAPIError) => {
          this.handleError(err, callback);
        }
      });
  }

  batchCancel(orderIds, searchFilter, callback: (success: boolean) => void): void {
    const length = orderIds.length;

    const loadingMessage = `Canceling ${length} ${length <= 1 ? 'order' : 'orders'}`;

    !!this.messagesService.totalOpened && this.messagesService.hideAll();

    this.messagesService.open(MessagesEnum.loading, loadingMessage);

    this.orderSvc
      .batchCancel(orderIds)
      .subscribe({
        next: (response) => {
          this.find(searchFilter);

          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: `${response.data.length} orders was successfully canceled`
            });

          !!callback && callback(true);
        },
        error: (err: ChakaAPIError) => {
          this.handleError(err, callback);
        }
      });
  }

  downloadAll(callback: (success: boolean) => void): void {
    this.messagesService.open(MessagesEnum.loading, 'Retrieving orders, please wait...');
    this.orderSvc
      .downloadAll()
      .subscribe({
        next: (response) => {
          if (response.success && !!response.data) {
            downloadFileFromLink(response.data.displayName, response.data.downloadUrl);

            this.messagesService.update({
              type: MessagesEnum.success,
              message: `Order(s) downloaded successfully.`
            });
          } else {
            this.handleError(new Error('Failed to retrieve orders.'), callback);
            return;
          }

          !!callback && callback(true);
        },
        error: (err: ChakaAPIError) => {
          this.handleError(err, callback);
        }
      });
  }

  downloadAllPositions(callback: (success: boolean) => void): void {
    this.messagesService.open(MessagesEnum.loading, 'Retrieving all positions, please wait...');
    this.orderSvc
      .downloadAllPositions()
      .subscribe({
        next: (response) => {
          if (response.success && !!response.data) {
            downloadFileFromLink(response.data.displayName, response.data.downloadUrl);

            this.messagesService.update({
              type: MessagesEnum.success,
              message: `Position(s) downloaded successfully.`
            });
          } else {
            this.handleError(new Error('Failed to retrieve positions.'), callback);
            return;
          }

          !!callback && callback(true);
        },
        error: (err: ChakaAPIError) => {
          this.handleError(err, callback);
        }
      })
  }

  private handleError(err, callback: (success: boolean) => void): void {
    const message = cleanChakaAPIError(err);

    this.messagesService
      .update({ type: MessagesEnum.danger, message });

    !!callback && callback(false);
  }
}
