<!--begin::Search Form-->
<div class="mb-7">
  <div class="row align-items-center">
    <div class="col-lg-12">
      <form [formGroup]="searchForm">
        <div class="row align-items-center">
          <div class="col-md-4 my-2 my-md-0">
            <div class="form-group">
              <label>Search all feeds</label>
              <input
                type="text"
                class="form-control"
                formControlName="q"
                trimmedInput>
            </div>

          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>Type</label>
              <ng-select formControlName="type" [clearable]="false">
                <ng-option
                  [value]="order.value"
                  *ngFor="let order of orderTypes">
                  {{order.name}}
                </ng-option>
              </ng-select>
            </div>

          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>Side</label>
              <ng-select formControlName="side" [clearable]="false">
                <ng-option
                  [value]="side.value"
                  *ngFor="let side of orderSides">
                  {{ side.name }}
                </ng-option>
              </ng-select>
            </div>

          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>Status</label>
              <ng-select formControlName="status" [clearable]="false">
                <ng-option
                  [value]="status.value"
                  *ngFor="let status of orderStatus">
                  {{ status.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>Market</label>
              <ng-select formControlName="currency" [clearable]="false">
                <ng-option
                  [value]="market.value"
                  *ngFor="let market of marketTypes">
                  {{ market.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div
            class="col-md-2 my-2 my-md-0"
            *ngIf="isSuperAdmin">
            <div class="form-group">
              <label>Merchant</label>
              <ng-select
                formControlName="merchantId"
                [clearable]="false"
                [virtualScroll]="true"
                [loading]="this.merchants?.loading">
                <ng-option value="">All</ng-option>
                <ng-option
                  [value]="merchant?.id"
                  *ngFor="let merchant of merchants?.list">
                  {{ merchant?.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>From</label>
              <input class="form-control" type="date" formControlName="from">
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>To</label>
              <input class="form-control" type="date" formControlName="to">
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label class="d-block">&nbsp;</label>
              <button
                type="button"
                class="btn btn-light-primary font-weight-bold"
                (click)="handleQuery()">Search</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!--end::Search Form-->
<lib-messages></lib-messages>


<div
  [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

  <div class="mt-5 mb-5">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        Selected {{selectedItems.size}} {{selectedItems.size<=1 ?'record': 'records'}}:

        <button [disabled]="selectedItems.size <= 0" (click)="downloadFile()" class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
          <i class="far fa-arrow-alt-circle-down"></i> Download
        </button>

        <button *ngIf="(canUpdateOrder$ | async)" [disabled]="btnLoading.reQuery || this.selectedItems.size < 1" (click)="reQuerySelected()" class="btn btn-dark btn-sm font-weight-bold mr-3">
          <i class="fas fa-sync-alt" [ngClass]="btnLoading.reQuery ? 'fa-spin':''"></i> Re-query
        </button>

        <button *ngIf="(canUpdateOrder$ | async)" [disabled]="btnLoading.cancel || this.selectedItems.size < 1" (click)="cancelSelected()" class="btn btn-danger btn-sm font-weight-bold">
          <i class="fas fa-ban" [ngClass]="btnLoading.cancel ? 'fa-spin':''"></i> Cancel
        </button>
      </div>


      <div *ngIf="(canDownloadAll$| async)">
        <button [disabled]="btnLoading.downloadAll" (click)="downloadAll()" class="btn btn-white btn-sm font-weight-bold mr-3">
          <i [ngClass]="btnLoading.downloadAll ? 'fas fa-spinner fa-spin':'fas fa-cloud-download-alt'"></i> Download All Orders
        </button>

        <button [disabled]="btnLoading.positions" (click)="downloadAllPositions()" class="btn btn-white btn-sm font-weight-bold">
          <i [ngClass]="btnLoading.positions ? 'fas fa-spinner fa-spin':'fas fa-cloud-download-alt'"></i> Download All Positions
        </button>
      </div>


    </div>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="check" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single checkbox-all">
              <input
                (change)="onAllChecked($event.target.checked)"
                type="checkbox"
                [checked]="allSelected">&nbsp;<span></span>
            </label>
          </span>
        </th>

        <td
          mat-cell
          *matCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single">
              <input
                type="checkbox"
                (change)="onItemChecked($event.target.checked, row)"
                [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
            </label>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
        <td mat-cell *matCellDef="let row">
          <!-- {{row?.createdBy || "-"}} -->

          <a
            *ngIf="!userId else noUser"
            href="javascript:void(0)"
            (click)="viewUser(row?.userId)">
            {{row?.createdBy || "-"}}</a>

          <ng-template #noUser>{{row?.createdBy || "-"}}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Reference</th>
        <td mat-cell *matCellDef="let row">
          <a href="javascript:;" (click)="emit(row)">{{row?.reference || "-"}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let row">{{row?.orderType || "-"}}</td>
      </ng-container>

      <ng-container matColumnDef="orderSides">
        <th mat-header-cell *matHeaderCellDef> Order Sides </th>
        <td mat-cell *matCellDef="let row">{{ row?.orderSide || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef> Symbol </th>
        <td mat-cell *matCellDef="let row" class="text-primary">{{ row?.symbol || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="market">
        <th mat-header-cell *matHeaderCellDef> Market </th>
        <td mat-cell *matCellDef="let row">
          <span
            [ngClass]="row?.currency == 'USD' ? 'label-success': 'label-primary'"
            class="label label-dot mr-2"></span>
          <span class="font-weight-bold"
            [ngClass]="row?.currency == 'USD' ? 'text-success': 'text-primary'">
            {{ row?.currency === 'USD'? 'Global': 'Local' || "-" }}</span>

        </td>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef> Total Amount </th>
        <td mat-cell *matCellDef="let row">
          {{formatToCurrency(row.currency,row.totalAmount)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="chakaFees">
        <th mat-header-cell *matHeaderCellDef> Chaka Fees </th>
        <td mat-cell *matCellDef="let row">
          {{formatToCurrency(row.currency,row.chakaFees)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
        <td mat-cell *matCellDef="let row">{{ row?.quantity | number: "1.1-2" || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td
          mat-cell
          *matCellDef="let row"
          [ngClass]="[
                        row?.status == 'PENDING' ? 'text-info' : '',
                        row?.status == 'CANCELED' ? 'text-success' : '',
                        row?.status == 'REJECTED' ? 'text-danger' : '',
                        row?.status == 'PENDING_CANCEL' ? 'text-warning' : '',
                        row?.status == 'FILLED' ? 'text-primary' : '',
                        row?.status == 'PARTIALLY_FILLED' ? 'text-secondary' : '',
                        row?.status == 'NEW' ? 'text-dark' : ''
                    ]">
          {{ row?.status || "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
        <td mat-cell *matCellDef="let row">
          {{row?.dateCreated | date: 'medium' || "-"}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>

  <mat-paginator
    class="mat-paginator-sticky"
    [length]="count"
    [pageSizeOptions]="[10, 50, 100, 150, 200]"
    (page)="pageEvent=$event; paginate($event)"
    showFirstLastButtons></mat-paginator>
</div>


<div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
  <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
  <p class="no-data-text">No data</p>
</div>
