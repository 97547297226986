import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, first, map, skipWhile } from 'rxjs/operators';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '../../../api/src/lib/api.interface';
import { Role } from '../../../view/src/lib/roles/services/roles.interface';
import jwtDecode from 'jwt-decode';
import { BaseApiService } from '@console/api/base-api.service';
import { AuthTokenContent } from './authentication.interface';

export interface AuthRoleState {
  loading: boolean;
  error?: string;
  permissions: string[];
}

const initialState: AuthRoleState = {
  loading: false,
  permissions: [],
};

const ErrorTimeOut = 3000;

@Injectable({ providedIn: 'root' })
export class AuthRoleStateService {
  state = new BehaviorSubject<AuthRoleState>(initialState);

  constructor(
    private spinner: NgxSpinnerService,
    private baseAPi: BaseApiService
  ) {}

  hasPermission$(entity: string) {
    const permissions = this.extractRoles();
    // return of(true);
    return this.state.pipe(
      map((state) => new Set(permissions).has(entity)),
      skipWhile((permission) => !permission),
      first()
    );
  }

  reset() {
    this.state.next(initialState);
    return;
  }

  get message$() {
    return this.state.pipe(distinctUntilChanged()).pipe(
      map((state) => {
        if (state.loading)
          return 'please wait, we are setting up your workspace';
        return state.error;
      })
    );
  }

  private onRoleLoaded({ data }: ReqSuccessResponse<Role>) {
    const state = this.state.getValue();
    let a = '';
    for (const permission of data.permissions) {
      a = a + `${permission.entity}_${permission.action} \n`;
      state.permissions.push(`${permission.entity}_${permission.action}`);
    }
    // console.log(state.permissions);
    this.state.next({
      ...this.state.getValue(),
      permissions: state.permissions,
      loading: false,
    });

    this.alertSuccess();
  }

  private alertSuccess() {
    setTimeout(() => {
      this.spinner.hide();
    }, ErrorTimeOut);
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
    });
    this.alertError(error);
  }

  private alertError(error: string) {
    setTimeout(() => {
      this.spinner.hide();
    }, ErrorTimeOut);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
    this.spinner.show();
  }

  private extractRoles(): string[] {
    const clientId = this.extractTokenInformation().client_id;

    const permissions = [
      'PORTFOLIO_VIEW',
      'PERMISSION_CREATE',
      'PERMISSION_VIEW',
      'PERMISSION_UPDATE',
      'PERMISSION_DELETE',
      'USER_CREATE',
      'USER_VIEW',
      'USER_UPDATE',
      'USER_DELETE',
      'KYC_CREATE',
      'KYC_VIEW',
      'KYC_UPDATE',
      'KYC_DELETE',
      'WALLET_CREATE',
      'WALLET_VIEW',
      'WALLET_UPDATE',
      'WALLET_DELETE',
      'WALLET_FUND',
      'WALLET_EXCHANGE',
      'ORDER_CREATE',
      'ORDER_VIEW',
      'ORDER_UPDATE',
      'ORDER_DELETE',
      'PAYMENT_CREATE',
      'PAYMENT_VIEW',
      'PAYMENT_UPDATE',
      'PAYMENT_DELETE',
      'EXCHANGE_VIEW',
      'EXCHANGE_UPDATE',
      'WITHDRAWAL_VIEW',
      'ADVISORY_FEES',
    ];

    if (clientId.toLowerCase() === 'chaka-service') {
      permissions.push('EXCHANGE_RATE_VIEW');
      permissions.push('CONTENT_VIEW');
      permissions.push('ROLE_VIEW');
      permissions.push('MERCHANT_VIEW');
      permissions.push('ROLE_VIEW');
      permissions.push('SETTING_VIEW');
      permissions.push('ADVISORY_FEES');
    }

    return permissions;
  }

  public extractTokenInformation(): AuthTokenContent {
    const token = this.baseAPi.token();

    if (typeof token !== 'string' || token === '') {
      return null;
    }

    return jwtDecode(this.baseAPi.token()) as AuthTokenContent;
  }
}
