import { MatSidenav } from '@angular/material/sidenav';
import { FeeBand, IFee } from '../../fees.interface';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MerchantState, MerchantStateService } from '../../merchant-details/merchant-state.service';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MerchantsModel } from '../../merchants.interface';
@Component({
  selector: './merchant-fee',
  templateUrl: './merchant-fee.component.html',
  styleUrls: ['./merchant-fee.component.scss'],
})
export class MerchantFeesComponent implements OnInit, OnDestroy {
  @Input() merchant?: MerchantsModel;

  fees: IFee;
  loading = false;

  componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChild('drawer') drawer: MatSidenav;

  constructor(
    private merchantStateService: MerchantStateService,
    private messagesService: MessagesService
  ) { }

  ngOnInit(): void {
    this.merchantStateService.retrieveFeeSettingsById(this.merchant.id);

    this.listenToMerchantState();
  }

  private listenToMerchantState(): void {

    this.merchantStateService.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(Boolean),
        tap((
          {
            fees,
            loading
          }: MerchantState) => {
          this.fees = fees;
          this.loading = loading;
        })
      ).subscribe();
  }

  get feeBands(): FeeBand[] {
    if (this.fees && !!this.fees?.bands?.length) {
      return this.fees?.bands;
    } else {
      return [];
    }
  }

  openDrawer(): void {
    this.messagesService.hideAll();

    this.drawer.open();
  }

  ngOnDestroy(): void {
    this.messagesService.hideAll();

    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }


}
