<div class="row">
  <div class="col-md-6">
    <h5>{{ merchant?.name }} Credentials</h5>
    <br />
    <div class="row kt-margin-bottom-20">
      <div class="col-md-6">Client ID:</div>
      <div class="col-md-6">
        <strong class="kt-font-success">{{ credentials?.clientId }}</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">Secret Key:</div>
      <div class="col-md-6">
        <strong class="kt-font-success">{{
              credentials?.clientSecret
            }}</strong>
      </div>
    </div>
  </div>
</div>
