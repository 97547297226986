import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '@console/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DwTransaction } from '../../transactions/services/transactions.model';
import { WalletTxnService } from '../../transactions/services/transactions.service';


export interface DwHistoryListState {
  loading: boolean;
  count?: number;
  transactions: DwTransaction[];
  error?: string;
  message?: string;
}

const initialState: DwHistoryListState = {
  loading: false,
  transactions: [],
};


@Injectable({ providedIn: 'root' })
export class DwHistoryStateService {
  state = new BehaviorSubject<DwHistoryListState>(initialState);

  constructor(
    private transactionSvc: WalletTxnService,
    private spinner: NgxSpinnerService
  ) { }


  find(query: object): void {
    this.loading();
    this.transactionSvc
      .getDwHistory(query)
      .pipe(first())
      .subscribe({
        next: this.updateList.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateList(data: PaginatedList<DwTransaction>) {
    this.state.next({
      loading: false,
      transactions: data.data,
      count: data.count,
      message: 'Data Load Successful',
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: cleanChakaAPIError(res),
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading...',
    });
  }
}
