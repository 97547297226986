import { Currency } from './../../../../shared/src/lib/_enums/currency.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ExchangeListStateService,
  ExchangesListState,
} from './exchanges-state';
import { ExchangesModel } from './services/exchanges.model';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
// import { PageEvent } from '../../../../shared/src/lib/interface';

import { EXCHANGE_ACTIONS } from '../../../../shared/src/public-api';
import { FormControl, FormGroup } from '@angular/forms';
import {
  exportAndDownload,
  fileNameFormatter,
} from 'projects/_shared/csv-downloader/csv-downloader';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  skip,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { ExchangeStateService } from './exchanges-edit/exchanges-edit-state';
import { NotificationService } from '@console/shared/services/notification-service';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { MatMultiSort } from 'ngx-mat-multi-sort';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MerchantsModel } from '../merchants/merchants.interface';
import {
  MerchantListState,
  MerchantListStateService,
} from '../merchants/merchant-list/merchant-list.state';
import {
  AuthProfileState,
  AuthProfileStateService,
} from '@console/authentication/profile-state';

@Component({
  selector: 'app-exchanges',
  templateUrl: './exchanges.component.html',
  styleUrls: ['./exchanges.component.scss'],
})
export class ExchangesComponent implements OnInit, OnDestroy {
  public filter: Record<string, any> = {};

  userId: string;

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  orderSides = [
    {
      name: 'ALL',
      value: EXCHANGE_ACTIONS.ALL,
    },
    {
      name: 'DEPOSIT',
      value: EXCHANGE_ACTIONS.CSR,
    },
    {
      name: 'WITHDRAWAL',
      value: EXCHANGE_ACTIONS.CSD,
    },
    {
      name: 'FEE',
      value: EXCHANGE_ACTIONS.FEE,
    },
    {
      name: 'JOURNAL',
      value: EXCHANGE_ACTIONS.JNLC,
    },
  ];

  statusList: { name: string; value: string | boolean }[] = [
    {
      name: 'ALL',
      value: EXCHANGE_ACTIONS.ALL,
    },
    {
      name: 'APPROVED',
      value: 'true',
    },
    {
      name: 'PENDING',
      value: 'false',
    },
  ];

  // MAT TABLE HERE
  displayedColumns: string[] = [
    'check',
    'createdBy',
    'amount',
    'reference',
    'accountNumber',
    'approved',
    'processed',
    'approvalStatus',
    'dateCreated',
  ];

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  dataSource;
  count: number;
  // selection = new SelectionModel<ExchangesModel>(true, []);

  componentDestroyed$: Subject<boolean> = new Subject();
  loading: boolean;

  activeExchange: ExchangesModel = null;

  searchForm: FormGroup;

  selectedItems = new Map();

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  btnLoading = {
    approve: false,
  };

  errorIds: { approve: number } = {
    approve: 0,
  };

  errors: { approve: boolean } = {
    approve: false,
  };

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[];
    loading: boolean;
    total: number;
    pageSize: number;
    pageNumber: number;
  } = {
    list: [],
    loading: false,
    total: 0,
    pageSize: 1000,
    pageNumber: 0,
  };

  constructor(
    private consoleRouter: ConsoleRouterService,
    public exchangeList: ExchangeListStateService,
    public exchangeState: ExchangeStateService,
    private notificationService: NotificationService,
    private messagesService: MessagesService,
    public authProfile: AuthProfileStateService,
    public merchantListState: MerchantListStateService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.listenOnSearchForm();

    this.filter.pageSize = this.pageEvent.pageSize;
    this.query(this.filter);

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      q: new FormControl(''),
      status: new FormControl(''),
      transactionType: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(),
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.query(this.filter);
  }

  private listenOnState(): void {
    this.loading = true;
    this.exchangeList.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: ExchangesListState) => {
        this.dataSource = new MatTableDataSource<ExchangesModel>(
          state.exchanges
        );
        this.dataSource.sort = this.sort;
        this.count = state.count;
        this.loading = state.loading;
      });
  }

  listenOnAuthProfile(): void {
    this.authProfile.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: AuthProfileState) => {
        this.isSuperAdmin = state?.authProfile?.superAdmin;

        this.isSuperAdmin
          ? this.merchantListState.find({
              pageSize: this.merchants.pageSize,
              pageNumber: this.merchants.pageNumber,
            })
          : null;
      });
  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe((state: MerchantListState) => {
        this.merchants.loading = state?.loading;
        this.merchants.total = state?.count;
        this.merchants.list = [...this.merchants.list, ...state?.merchants];
      });
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  query($event: Record<string, any>): void {
    if (this.userId) {
      $event.userId = this.userId;
    }
    this.exchangeList.find($event);
  }

  openExchange(exchange: ExchangesModel): void {
    this.activeExchange = exchange;
  }

  onBackToSearch(): void {
    this.activeExchange = null;
  }

  approve(transactionId): void {
    this.exchangeState.approve(transactionId);
  }

  refresh(transactionId): void {
    this.exchangeState.refresh(transactionId).subscribe((response) => {
      this.notificationService.success('Successfully refreshed exchange');
      this.activeExchange.accountNo = response.data || '';
    });
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach((exchange) => {
      this.updateCheckedSet(checked, exchange);
    });
  }

  onItemChecked(checked: boolean, exchange: ExchangesModel): void {
    this.updateCheckedSet(checked, exchange);
  }

  updateCheckedSet(checked: boolean, exchange: ExchangesModel): void {
    if (checked) {
      this.selectedItems.set(exchange.id, exchange);
    } else {
      this.selectedItems.delete(exchange.id);
    }
  }

  get selectedExchanges(): ExchangesModel[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      this.userId || 'Exchanges',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedExchanges);
  }

  showNotAllowedRow(row: ExchangesModel): boolean {
    return (
      this.errors.approve &&
      row.authorizationStatus &&
      this.selectedItems.has(row?.id)
    );
  }

  approveSelected(): void {
    try {
      this.errors.approve = false;

      const cannotProcess = this.selectedExchanges.some(
        ({ authorizationStatus }) => authorizationStatus
      );

      if (cannotProcess) {
        this.errors.approve = true;
        throw new Error(
          'You can ONLY approve exchanges that are not yet authorized.'
        );
      }

      if (this.selectedItems.size > 10) {
        throw new Error('You can only approve a maximum of 10 exchanges');
      }

      const check = confirm(
        `Do you want to approve ${
          this.selectedItems.size === 1 ? 'this exchange' : 'these exchanges'
        }?`
      );

      if (check) {
        this.btnLoading.approve = true;

        const transactions = this.selectedExchanges.map(({ id }) => ({
          transactionId: id,
          currency: Currency.USD,
        }));

        this.exchangeList.batchApprove(
          transactions,
          this.filter,
          (success: boolean) => {
            this.btnLoading.approve = false;

            success && this.selectedItems.clear();
          }
        );
      }
    } catch (error) {
      if (Boolean(this.errorIds.approve)) {
        this.messagesService.update({
          id: this.errorIds.approve,
          message: error.message,
          isOpened: true,
        });

        return;
      }

      const { id } = this.messagesService.open(
        MessagesEnum.danger,
        error.message
      );

      this.errorIds.approve = id;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.merchantListState.reset();
  }
}
