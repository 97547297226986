import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../../shared/src/lib/shared.module';
import { SupportedDocumentDialogComponent } from './components/supported-document-dialog/supported-document-dialog.component';
import { SupportedDocumentsService } from './service/support-documents.service';
import { SupportedDocumentsComponent } from './supported-documents.component';

@NgModule({
  declarations: [SupportedDocumentsComponent, SupportedDocumentDialogComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  providers: [SupportedDocumentsService],
})
export class SupportedDocumentsModule {}
