import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { WALLET_TRANSACTION_STATUS } from '../../../../../shared/src/public-api';
import { ConsoleRouterService } from './../../../../../shared/src/lib/services/console-router.service';
import { TransactionStateService } from './transaction-edit-state';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { MessagesService } from '@console/shared/components/messages/messages.service';

@Component({
  selector: 'kt-transactions-edit',
  templateUrl: './transactions-edit.component.html',
  styleUrls: ['./transactions-edit.component.scss'],
})
export class TransactionsEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() transactionId: number;

  private intialId: number;

  reasonControl: FormControl;

  btnLoading: { delete: boolean } = {
    delete: false,
  };

  statusEnum = WALLET_TRANSACTION_STATUS;

  constructor(
    private activatedRoute: ActivatedRoute,
    public transactionState: TransactionStateService,
    private consoleRouter: ConsoleRouterService,
    private dialog: MatDialog,
    private messagesService: MessagesService
  ) {}

  back() {
    this.messagesService.hideAll();
    return this.consoleRouter.goBack();
  }

  goToUserProfile() {
    this.transactionState.state
      .pipe(
        first(),
        map((state) => state?.transaction?.userId)
      )
      .subscribe((id) => {
        this.consoleRouter.productNavigate(['users', id]);
      });
  }

  approve() {
    this.transactionState.state.pipe(first()).subscribe((state: any) => {
      this.transactionState.approve(state.transaction?.id);
    });
  }

  getStatusColor(status: WALLET_TRANSACTION_STATUS): string {
    if (status === WALLET_TRANSACTION_STATUS.PENDING) {
      return 'warning';
    }
    if (status === WALLET_TRANSACTION_STATUS.INITIATED) {
      return 'primary';
    }
    if (
      status === WALLET_TRANSACTION_STATUS.WAITING ||
      status === WALLET_TRANSACTION_STATUS.PROCESSING
    ) {
      return 'secondary';
    }
    if (
      status === WALLET_TRANSACTION_STATUS.CANCELLED ||
      status === WALLET_TRANSACTION_STATUS.FAILED ||
      status === WALLET_TRANSACTION_STATUS.REJECTED
    ) {
      return 'danger';
    }
    return 'success';
  }

  get tsn$() {
    return this.transactionState.state.pipe(map((state) => state.transaction));
  }

  retreiveById(transactionId: number) {
    if (transactionId !== this.intialId) {
      this.transactionState.reset();
      this.transactionState.findById(transactionId);
      this.intialId = transactionId;
    }
  }

  retrieveByRoute() {
    this.activatedRoute.params.pipe(first()).subscribe((params) => {
      const id = params.id;
      if (id) {
        this.transactionState.findById(id);
      } else {
        this.consoleRouter.productNavigate(['transaction']);
      }
    });
  }

  ngOnInit() {
    if (this.transactionId) {
      this.retreiveById(this.transactionId);
      return;
    }
    this.retrieveByRoute();

    this.reasonControl = new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(255),
    ]);
  }

  ngOnChanges() {
    if (this.transactionId) {
      this.retreiveById(this.transactionId);
    }
  }

  openDeleteDialog(templateRef): void {
    this.dialog.open(templateRef, {
      width: '500px',
      autoFocus: false,
    });
  }

  closeDeleteDialog(): void {
    this.messagesService.hideAll();
    this.dialog.closeAll();
  }

  deleteTransaction(transactionId: number): void {
    if (!this.reasonControl.valid) {
      this.reasonControl.markAsTouched();
      return;
    }

    this.btnLoading.delete = true;

    this.transactionState.deleteTransaction(
      transactionId,
      this.reasonControl.value,
      (success) => {
        this.btnLoading.delete = false;

        if (success) {
          this.dialog.closeAll();
          this.consoleRouter.goBack();
        }
      }
    );
  }

  ngOnDestroy() {
    this.transactionState.reset();
  }
}
