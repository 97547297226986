import { Injectable } from '@angular/core';
import { ReqSuccessResponse, ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConsolidatedQuote } from 'projects/view/src/lib/orders/services/orders.model';
import { OrderTxnService } from 'projects/view/src/lib/orders/services/orders.service';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';


export interface QuoteState {
  loading: boolean;
  order?: ConsolidatedQuote;
  error?: string;
  message?: string;
}

const initialState: QuoteState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class QuoteStateService {
  state = new BehaviorSubject<QuoteState>(initialState);

  constructor(
    private orderSvc: OrderTxnService,
    private spinner: NgxSpinnerService
  ) { }

  retrieve(symbol: string) {
    this.loading();
    this.orderSvc
      .quote(symbol)
      .pipe(first())
      .subscribe({
        next: this.updateQuote.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateQuote({ data }: ReqSuccessResponse<ConsolidatedQuote>) {
    this.state.next({
      loading: false,
      order: data,
      message: `Loading ${data.symbol} quote Successful`,
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: cleanChakaAPIError(res),
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading...',
    });
  }
}
