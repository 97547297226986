import { FormGroup, AbstractControl } from '@angular/forms';

export const formMethods = (formGroup: FormGroup) => ({
  formGroup,
  getControl(formControl: string): AbstractControl {
    return formGroup.get(formControl) as AbstractControl;
  },
  touchedAndInvalid(formControl: string): boolean {
    return (
      !this.getControl(formControl).valid &&
      this.getControl(formControl).touched
    );
  },
  isRequired(formControl: string): boolean {
    try {
      const validator = this.getControl(formControl)?.validator(
        {} as AbstractControl
      );
      return validator && validator.required;
    } catch (error) {
      return false;
    }
  },
  value(formControl: string): any {
    return this.getControl(formControl)?.value;
  },
});
