import { TagGridComponent } from './tag-grid/tag-grid.component';
import { TagCardComponent } from './/tag-card/tag-card.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [
    TagCardComponent,
    TagGridComponent

  ],
  imports: [
    CommonModule
  ],
  exports: [TagCardComponent,
    TagGridComponent
,RouterModule],
})
export class TagSharedModule {}
