import { ISuitabilityQuestion } from './interface/ISuitabilityQuestions';
import { MatDrawer } from '@angular/material/sidenav';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SuitabilityService } from './services/suitability-questions.service';
import { MessagesService } from '@console/shared/components/messages/messages.service';
@Component({
  selector: 'lib-suitability-questions',
  templateUrl: './suitability-questions.component.html',
  styleUrls: ['./suitability-questions.component.scss'],
})
export class SuitabilityQuestionsComponent implements OnInit, OnDestroy {
  suitabilityQuestions: ISuitabilityQuestion[];
  loading = false;

  componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChild('drawer') drawer: MatDrawer;

  categories: string[] = [];

  private filter: Record<string, any> = {};

  activeQuestion: ISuitabilityQuestion;

  constructor(
    private suitabilityService: SuitabilityService,
    private messagesService: MessagesService
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.getQuestions();
  }

  getCategories(): void {
    this.suitabilityService
      .fetchCategories()
      .subscribe(response => this.categories = response.data);
  }

  getQuestions(): void {
    this.suitabilityService
      .fetchQuestions(this.filter)
      .subscribe(response => {
        this.suitabilityQuestions = response.data;
      });
  }

  openDrawer(question?: ISuitabilityQuestion): void {
    this.activeQuestion = !!{ ...question } ? { ...question } : null;

    this.drawer.open();
  }

  deleteSuitabilityQuestion(questionId: number): void {
    const check = confirm('Do you want to delete this suitability question?');

    if (!check) {
      return;
    }

    this.suitabilityService
      .deleteSuitabilityQuestion(questionId)
      .subscribe({
        next: () => {
          this.getQuestions();
        }
      });
  }

  onSaveSuccess(): void {
    this.getQuestions();
  }

  ngOnDestroy(): void {
    this.messagesService.hideAll();

    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

}
