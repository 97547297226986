import { MatMultiSort } from 'ngx-mat-multi-sort';
import {
  IAdvisoryPortfolioListState,
  AdvisoryPortfolioListStateService,
} from './state/advisory-portfolio-list-state.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
import { FormControl, FormGroup } from '@angular/forms';
import {
  fileNameFormatter,
  exportAndDownload,
} from 'projects/_shared/csv-downloader/csv-downloader';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MerchantsModel } from '../merchants/merchants.interface';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import {
  MerchantListState,
  MerchantListStateService,
} from '../merchants/merchant-list/merchant-list.state';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { Currency } from '@console/shared/_enums/currency.enum';
import { IAdvisoryPortfolio } from './interfaces/IAdvisoryPortfolio';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-advisory-portfolio-list',
  templateUrl: './advisory-portfolio-list.component.html',
  styleUrls: ['./advisory-portfolio-list.component.scss'],
})
export class AdvisoryPortfolioListComponent implements OnInit, OnDestroy {
  filter: Record<string, any> = {
    pageSize: 10,
  };

  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'name',
    'description',
    'equity',
    'lastPortfolioRebalance',
    'nextPortfolioRebalance',
    'totalDrift',
    'rebalanceRequired',
    'action',
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  loading = false;

  count = 0;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[];
    loading: boolean;
    total: number;
    pageSize: number;
    pageNumber: number;
  } = {
    list: [],
    loading: false,
    total: 0,
    pageSize: 1000,
    pageNumber: 0,
  };

  formatToCurrency = formatToCurrency;

  currencyEnum = Currency;

  constructor(
    private consoleRouter: ConsoleRouterService,
    private portfolioListState: AdvisoryPortfolioListStateService,
    private messagesService: MessagesService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService
  ) {}

  ngOnInit(): void {
    this.filter.pageSize = this.pageEvent.pageSize;
    this.handleQuery();

    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(''),
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.portfolioListState.state$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: IAdvisoryPortfolioListState) => {
        this.dataSource = new MatTableDataSource<IAdvisoryPortfolio>(
          state?.portfolios
        );
        this.dataSource.sort = this.sort;
        this.count = state?.count;
        this.loading = state?.loading;
      });
  }

  listenOnAuthProfile(): void {
    this.authProfile.isSuperAdmin$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((isSuperAdmin) => isSuperAdmin)
      )
      .subscribe((isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;

        this.isSuperAdmin
          ? this.merchantListState.find({
              pageSize: this.merchants.pageSize,
              pageNumber: this.merchants.pageNumber,
            })
          : null;
      });
  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe((state: MerchantListState) => {
        this.merchants.loading = state?.loading;
        this.merchants.total = state?.count;
        this.merchants.list = [...this.merchants.list, ...state?.merchants];
      });
  }

  viewPortfolio(portfolioId: number): void {
    this.consoleRouter.productNavigate(['portfolios', portfolioId]);
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.portfolioListState.find(this.filter);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach((withdrawal) => {
      this.updateCheckedSet(checked, withdrawal);
    });
  }

  onItemChecked(checked: boolean, portfolio: IAdvisoryPortfolio): void {
    this.updateCheckedSet(checked, portfolio);
  }

  updateCheckedSet(checked: boolean, portfolio: IAdvisoryPortfolio): void {
    if (checked) {
      this.selectedItems.set(portfolio.id, portfolio);
    } else {
      this.selectedItems.delete(portfolio.id);
    }
  }

  get selectedPortfolios(): IAdvisoryPortfolio[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Portfolios',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedPortfolios);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();

    this.merchantListState.reset();
  }
}
