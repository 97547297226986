<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">
        {{ (exchangeState.state | async)?.message }}
      </p>
    </ngx-spinner>

    <div class="card-header bg-light py-3">
      <a
        [routerLink]="['../']"
        class="btn btn-secondary float-right"
        matTooltip="Back to the users list">
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Back</span>
      </a>

      <span *ngIf="ex$ | async as exchange">
        {{!exchange.hasBeenProcessed}}
        <a
          class="btn btn-success kt-margin-r-10 mr-2"
          style="color: white"
          href="javascript:;"
          matTooltip="Approve Exchange"
          (click)="approve(exchange?.id)"
          *ngIf="!exchange.hasBeenProcessed">
          <i class="fa fa-check"></i>
          <span class="kt-hidden-mobile">Approve</span>
        </a>
      </span>

      <ng-container *ngIf="(ex$ | async)  as exchange">
        <span *ngIf="!exchange.accountNo">
          <a
            class="btn btn-dark kt-margin-r-10"
            style="color: white"
            href="javascript:;"
            matTooltip="Refresh Exchange"
            (click)="refresh(exchange?.id)"
            *ngIf="!exchange.accountNo">
            <!-- <i class="fas fa-reload"></i> -->
            <span class="kt-hidden-mobile">Refresh Exchange</span>
          </a>
        </span>

      </ng-container>


    </div>

    <div class="kt-form px-4 py-4">
      <div class="kt-form">
        <div class="kt-form__section kt-form__section--first">
          <ng-container *ngIf="ex$ | async as exchange">
            <div class="kt-form">
              <div class="form-group kt-form__group row pb-0">
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>User</h6>
                  <h5
                    (click)="goToUserProfile()">
                    <a class="pretty-link"> {{ exchange?.createdBy || "-"}}</a>
                  </h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Account Number</h6>
                  <h5>{{ exchange?.accountNo || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Originating Bank</h6>
                  <h5>{{ exchange?.originatingBankName || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Bank Name</h6>
                  <h5>{{ exchange?.bankName || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Has Been Processed?</h6>
                  <h5>
                    {{ exchange?.hasBeenProcessed ? "Yes" : "No" }}
                  </h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Reference</h6>
                  <h5>{{ exchange?.transactionReference || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Batch Code</h6>
                  <h5>{{ exchange?.batchCode || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Amount</h6>
                  <h5>
                    {{ exchange?.amount | currency: "USD" }}
                  </h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Transaction Type</h6>
                  <h5>{{ exchange?.transactionType }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Date</h6>
                  <h5>{{ exchange?.dateCreated | date: "medium" }}</h5>
                </div>
                <div class="col-12 kt-margin-bottom-20">
                  <h6>Comment</h6>
                  <h5>{{ exchange?.comment || "-" }}</h5>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
