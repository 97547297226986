<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">KYC List</h3>
        </div>

        <div class="card-toolbar">
          <a *ngIf="batchToActOn.length > 0" href="javascript:;" (click)="openModal('kycActionModal')" class="btn btn-success font-weight-bold ml-3">
            <i class="fas fa-cog"></i> Process {{ batchToActOn.length }} {{ batchToActOn.length<=1 ?'KYC': 'KYCs'  }} </a>

        </div>
      </div>

      <div class="card-body">
        <app-kyc-list-table
          (query)="kycState.loadKYCList($event)"
          (batchKyc)="batchToActOn = $event"></app-kyc-list-table>
      </div>
    </div>

  </div>
</div>

<!-- <ngx-smart-modal
  *ngIf="batchToActOn.length > 0"
  #kycActionModal
  identifier="kycActionModal"
  ariaLabel="kycActionModal">
  <app-kyc-action-modal [kycList]="batchToActOn"></app-kyc-action-modal>
</ngx-smart-modal> -->
