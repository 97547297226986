import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent {
  isPdf = false;
  fileName: string;
  resourceUrl: SafeResourceUrl;

  constructor(
    private dialogRef: MatDialogRef<DocumentPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) previewData: File,
    private _sanitizer: DomSanitizer
  ) {
    this.isPdf = previewData.type.includes('pdf');

    this.fileName = previewData?.name;

    this.resourceUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(previewData)
    );
  }

  close() {
    this.dialogRef.close();
  }
}
