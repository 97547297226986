import { FooterComponent } from './footer/footer.component';
import { QuickUserComponent } from './quick-user/quick-user.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { TopBarMobileComponent } from './top-bar-mobile/top-bar-mobile.component';
import { DashboardComponent } from './dashboard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@console/shared';

@NgModule({
  declarations: [
    DashboardComponent,
    TopBarMobileComponent,
    SideBarComponent,
    TopBarComponent,
    QuickUserComponent,
    FooterComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [
    DashboardComponent,
    TopBarMobileComponent,
    SideBarComponent,
    TopBarComponent,
    QuickUserComponent,
    FooterComponent,
  ],
})
export class DashboardModule {}
