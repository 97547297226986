import { Currency } from "../../../../../../shared/src/lib/_enums/currency.enum";
import { GraphPoint } from "./graph-cleaner";

const { NGN, USD } = Currency;

export const formatToCompactCurrency = (currency: string | Currency,
	amount: number) => {
	if (currency.toUpperCase() === NGN) {
	  return Intl.NumberFormat('en-NG', {
		style: 'currency',
		notation: "compact",
		currency: NGN
	  } as any).format(amount)
	}
	return Intl.NumberFormat('en-US', {
	  style: 'currency',
	  notation: "compact",
	  currency: USD
	} as any).format(amount)
  }


/**
 * format price to currency string
 */
export const formatToCurrency = (
	currency: string | Currency,
	amount: number
) => {
	const { format } =
		currency.toUpperCase() === NGN
			? Intl.NumberFormat("en-NG", {
					style: "currency",
					currency: NGN,
					minimumFractionDigits: 2,
			  })
			: Intl.NumberFormat("en-US", {
					style: "currency",
					minimumFractionDigits: 2,
					currency: USD,
			  });
	return format(amount);
};

// defaults.global.defaultFontSize = '24px';

interface GraphSetting {
	canvasID: string;
	mappedPositions: GraphPoint[];
	currency: Currency;
}


interface GraphSetting {
	canvasID: string;
	mappedPositions: GraphPoint[];
	currency: Currency;
}

