<button
  class="close"
  mat-button
  (click)="close()">X</button>

<h2 mat-dialog-title>Manage HVU</h2>

<form
  [formGroup]="hvuForm"
  (ngSubmit)="saveHVU()">
  <mat-dialog-content class="content">
    <div class="form-group row">
      <label class="col-3 col-form-label">Is HVU?</label>
      <div class="col-9">
        <span class="switch">
          <label>
            <input type="checkbox" formControlName="hvu" (ngModelChange)="isHVUChanged($event)">
            <span></span>
          </label>
        </span>
      </div>
    </div>

    <hr *ngIf="showAccountManager">

    <div class="form-group" *ngIf="showAccountManager">
      <label>Select Account Manager <span *ngIf="formMethods.isRequired('accountManagerId')" class="text-danger">*</span></label>

      <select class="form-control" formControlName="accountManagerId">
        <option value="">--Select--</option>
        <option *ngFor="let accountManager of accountManagers" [value]="accountManager.id">{{accountManager.name}}</option>
      </select>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions mat-dialog-actions class="d-flex justify-content-center">
    <button type="submit" class="btn btn-primary">Save</button>
  </mat-dialog-actions>
</form>
