<button class="close" mat-button (click)="close()">X</button>

<h2 mat-dialog-title>Confirm OTP </h2>
<form>

  <lib-messages></lib-messages>

  <mat-dialog-content>
    <div class="form-group mt-5">
      <label>Please enter the OTP code to approve this payment(s)<span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        [formControl]="otpControl">
      <small
        class="form-text text-danger"
        *ngIf="otpControl.touched && !otpControl.valid">
        <div *ngIf="otpControl.hasError('required')">
          Required input.
        </div>
      </small>
      <p class="mt-2"> <a
          [ngStyle]="{'cursor': countdown || isSending ? 'not-allowed' : 'pointer'}"
          class="btn-link"
          (click)="sendOtpCode()">Resend OTP</a> <span *ngIf="countdown"> in 00:{{countdown <10 ? '0'+countdown : countdown}}</span></p>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions mat-dialog-actions class="d-flex justify-content-center">
    <button
      type="button"
      (click)="submit()"
      class="btn btn-primary btn-block"
      [disabled]="isSending">Approve</button>
  </mat-dialog-actions>
</form>
