import { Injectable } from '@angular/core';
import { EnterpriseAuthService, ReqSuccessResponse } from '@console/api';
import { ChangePasswordRequest, RequestPasswordRequest } from '../authentication.interface';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(private client: EnterpriseAuthService) {}

  verifyToken() {
    throw new Error('Not implemented');
  }

  changePassword(request: ChangePasswordRequest) {
    return this.client.post<ReqSuccessResponse<string>>(`change-password`, request);
  }

  resetPassword(request: RequestPasswordRequest) {
    return this.client.post<ReqSuccessResponse<string>>(`reset-password`, request);
  }
}
