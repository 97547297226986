import { NotificationService } from './../../../../shared/src/lib/services/notification-service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { ClientUserModel } from '../models/user.model';
import { PaginatedList, ChakaAPIError, cleanChakaAPIError } from '@console/api';
export interface UserListState {
  loading: boolean;
  count?: number;
  users: ClientUserModel[];
  error?: string;
}

const initialState: UserListState = {
  loading: false,
  users: [],
};

@Injectable({ providedIn: 'root' })
export class UserListStateService {
  state = new BehaviorSubject<UserListState>(initialState);

  constructor(
    private userSvc: UserService,
    private notificationService: NotificationService
  ) { }

  find(query: object): void {
    this.loading();
    this.userSvc
      .getClientUsers(query)
      .pipe(
        first()
      )
      .subscribe({
        next: this.updateUsers.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateUsers(data: PaginatedList<ClientUserModel>): void {
    this.state.next({
      loading: false,
      users: data.data,
      count: data.count,
    });
  }

  private onError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);
    this.state.next({
      ...this.state.getValue(),
      error: message,
      loading: false,
    });

    this.notificationService.error(message);
  }

  reset(): void {
    this.state.next(initialState);
  }

  private loading(): void {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
  }
}
