import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { RoleService } from '../services/role.service';
import { TreeRole, UserRoleUpdate } from '../services/roles.interface';

export interface EditedRoleState {
  loading: boolean;
  count?: number;
  role?: TreeRole;
  error?: string;
  message?: string;
}

const initialState: EditedRoleState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class EditedRoleStateService {
  spinnerName = 'edited-role-spinner';

  state = new BehaviorSubject<EditedRoleState>(initialState);

  constructor(
    private roleSvc: RoleService,
    private spinner: NgxSpinnerService
  ) {}

  findById(id: number) {
    this.loading();
    this.roleSvc
      .roleById(id)
      .pipe(first())
      .subscribe({
        next: this.updateRoles.bind(this),
        error: this.onError.bind(this),
      });
  }

  createRole(Role: TreeRole) {
    this.loading();
    this.roleSvc
      .create(Role)
      .pipe(first())
      .subscribe({
        next: this.updateRoles.bind(this),
        error: this.onError.bind(this),
      });
  }

  updateRole(Role: TreeRole) {
    this.loading();
    this.roleSvc
      .update(Role)
      .pipe(first())
      .subscribe({
        next: this.updateRoles.bind(this),
        error: this.onError.bind(this),
      });
  }

  attachUserToRole(update: UserRoleUpdate) {
    this.loading();
    this.roleSvc
      .attachUserToRole(update)
      .pipe(first())
      .subscribe({
        next: this.onUserAttached.bind(this),
        error: this.onError.bind(this),
      });
  }

  private onUserAttached({ data }: ReqSuccessResponse<string>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      message: data,
    });

    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2500);
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateRoles({ data }: ReqSuccessResponse<TreeRole>) {
    this.state.next({
      loading: false,
      role: data,
      message: 'Loading Successful',
    });

    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2500);
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: 'Error occurred',
    });

    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2500);
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show(this.spinnerName);
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading',
    });
  }
}
