import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { finalize } from 'rxjs/operators';
import { EmailSettingsService } from '../../../services/email-settings.service';
import { EmailSetting } from '../../../services/settings.interface';

@Component({
  selector: 'app-send-rejection-email',
  templateUrl: './send-rejection-email.component.html',
  styleUrls: ['./send-rejection-email.component.scss']
})
export class SendRejectionEmailComponent implements OnInit {

  loading = false;

  dataSource: EmailSetting[] = [];

  displayedColumns: string[] = ['status', 'sendgridTemplateId', 'reasonForRejection', 'action'];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;
  count = 0;

  userId = null;
  selectedStatus = null;

  private filter: Record<string, any> = {};

  constructor(
    private dialogRef: MatDialogRef<SendRejectionEmailComponent>,
    @Inject(MAT_DIALOG_DATA) componentData: { userId: string | number, status: string },
    private emailSettingsService: EmailSettingsService,
    private messagesService: MessagesService
  ) {
    this.userId = componentData.userId;
    this.selectedStatus = componentData.status;
  }

  ngOnInit(): void {
    this.getRejectionEmails();
  }

  getRejectionEmails(params?: object): void {
    this.loading = true;
    this.filter.status = this.selectedStatus;

    this.emailSettingsService.getRejectionEmails(this.filter)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      ).subscribe(
        resp => {
          console.log(resp);
          this.dataSource = resp.data;
          this.count = resp.count;
        }
      );
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.getRejectionEmails();
  }

  sendRejectionEmail(reason: EmailSetting) {
    const check = confirm(`Do you want to send this rejection email with reason: ${reason.reasonForRejection}?`);

    if (!check) {
      return;
    }

    const rejectionEmailRequest: { reasonId: number, userId: string | number } = {
      reasonId: reason.id,
      userId: this.userId
    };

    const message = `Sending rejection email...`;

    this.messagesService.open(
      MessagesEnum.loading,
      message,
      { hideAll: true }
    );

    this.emailSettingsService
      .sendRejectionEmail(rejectionEmailRequest)
      .subscribe({
        next: () => {
          this.close(true);
        },
        error: (error: ChakaAPIError) => {
          const errorMessage = cleanChakaAPIError(error);

          this.messagesService.update(
            {
              type: MessagesEnum.danger,
              message: errorMessage
            }
          );
        }
      });
  }

  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }
}
