<div *ngIf="profile" class="card card-custom gutter-b card-stretch">
  <!-- {{ profile | json }} -->
  <!--begin::Body-->
  <div class="card-body pt-4">
    <!--begin::User-->
    <div class="d-flex align-items-center mb-7">
      <!--begin::Pic-->

      <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
        <div *ngIf="profile?.profileImageUrl else initials" class="symbol symbol-circle symbol-lg-50">
          <img [src]="profile?.profileImageUrl" [alt]="profile?.fullName">
        </div>

        <ng-template #initials>
          <div class="symbol symbol-lg-75 symbol-circle symbol-primary">
            <span class="symbol-label font-size-h3 font-weight-boldest">
              {{profile.firstName ? profile.firstName[0].toUpperCase() : "" }}
              {{profile.lastName ? profile.lastName[0].toUpperCase() : ""}}
            </span>
          </div>
        </ng-template>
      </div>

      <!--end::Pic-->
      <!--begin::Title-->
      <div class="d-flex flex-column">
        <a
          href="javascript:;"
          (click)="onView()"
          class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">
          {{ profile.fullName | titlecase  }}</a>
      </div>
      <!--end::Title-->
    </div>
    <!--end::User-->

    <!--begin::Info-->
    <div class="mb-7">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">Gender:</span>
        <span class="text-muted text-hover-primary">{{ profile?.gender }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-cente my-2">
        <span class="text-dark-75 font-weight-bolder mr-2">Phone:</span>
        <span class="text-muted text-hover-primary">{{ profile?.phone }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-cente my-2">
        <span class="text-dark-75 font-weight-bolder mr-2">Email:</span>
        <span class="text-muted text-hover-primary">{{ profile?.email }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">Country:</span>
        <span class="text-muted font-weight-bold">{{ profile?.country }}</span>
      </div>
    </div>
    <!--end::Info-->
    <a href="javascript:;"
      class="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4"
      (click)="onView()">View Profile</a>
  </div>
  <!--end::Body-->
</div>
