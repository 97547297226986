<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Configure Referral Policy</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Add, edit and update referral policy</span>
        </h3>
        <div class="card-toolbar">
          <button href="javascript:;" mat-button (click)="openDialog()" class="btn btn-primary font-weight-bolder font-size-sm">
            <span class="svg-icon svg-icon-md svg-icon-white">

              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
                  <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" />
                </g>
              </svg>

            </span>Add Referral Policy</button>
        </div>
      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <div class="row align-items-center mb-5">
          <div class="col-lg-8">
            <form [formGroup]="searchForm">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>From</label>
                    <input class="form-control" type="date" formControlName="from">
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>To</label>
                    <input class="form-control" type="date" formControlName="to">
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label class="d-block">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-light-primary font-weight-bold"
                      (click)="fetchReferralPolicies()">Search</button>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label class="d-block">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-link font-weight-bold"
                      (click)="clearFilters()">Clear All</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>

        <lib-messages></lib-messages>

        <!--begin::Table-->
        <div class="row">
          <div class="col-12">
            <div
              class="table-responsive"
              [hidden]="(dataSource.data && dataSource.data?.length <= 0)">

              <div class="table-container">
                <table
                  class="table table-head-custom table-head-bg table-vertical-center table-borderless"
                  mat-table [dataSource]="dataSource">

                  <ng-container matColumnDef="minCount">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Min Count</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.minCount}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="maxCount">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Max Count</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.maxCount}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="merchantId">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Merchant</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{resolveMerchantName(row?.merchantId) }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="refereeAmount">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Referee Amount</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.refereeAmount }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="referrerAmount">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Referrer Amount</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.referrerAmount }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dateCreated">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Date</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.dateCreated | date:'mediumDate'}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                      <span class="text-dark-75">Action</span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      <span style="overflow: visible; position: relative; width: 125px;">
                        <a href="javascript:;" (click)="openDialog(row)" class="btn btn-sm btn-clean btn-icon mr-2" title="Edit">
                          <span class="svg-icon svg-icon-md">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                                <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                              </g>
                            </svg>
                          </span>
                        </a>

                        <a href="javascript:;" (click)="deleteReferralPolicy(row.id)" class="btn btn-sm btn-clean btn-icon" title="Delete">
                          <span class="svg-icon svg-icon-md"> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
                                <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                              </g>
                            </svg>
                          </span>
                        </a>
                      </span>
                    </td>
                  </ng-container>


                  <thead>
                    <tr class="bg-gray-100 text-left" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  </thead>

                  <tbody>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                  </tbody>
                </table>
              </div>

              <mat-paginator
                class="mat-paginator-sticky"
                [length]="count"
                [pageSizeOptions]="[10, 50, 100, 150, 200]"
                (page)="pageEvent=$event; paginate($event)"
                showFirstLastButtons></mat-paginator>


            </div>

            <div *ngIf="dataSource.data?.length <= 0 && !loading" class="no-data-container d-flex flex-column justify-content-center align-items-center">
              <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
              <p class="no-data-text">No data</p>
            </div>
          </div>

        </div>

        <!--end::Table-->
      </div>
      <!--end::Body-->
    </div>

  </div>
  <!--end::Container-->
</div>
