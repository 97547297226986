import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClientUserModel, UserListState, UserListStateService } from '@console/user-managements';
import { MatMultiSort } from 'ngx-mat-multi-sort';
import { exportAndDownload, fileNameFormatter } from 'projects/_shared/csv-downloader/csv-downloader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})

export class UserTableComponent implements OnInit, OnDestroy {
  @Output() selected = new EventEmitter<ClientUserModel>();

  dataSource;

  selectedItems = new Map();

  displayedColumns: string[] = [
    'check',
    'photo',
    'fullName',
    'gender',
    'phone',
    'email',
    'country',
    'dob'
  ];

  count = 0;

  loading = false;

  componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  private filter: Record<string, any> = {};

  constructor(
    private userList: UserListStateService
  ) { }

  ngOnInit(): void {
    this.listenOnState();
  }

  private listenOnState(): void {
    this.userList.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: UserListState) => {
          this.dataSource = new MatTableDataSource<ClientUserModel>(state.users);
          this.dataSource.sort = this.sort;
          this.count = state.count;
          this.loading = state.loading;
        }
      );
  }

  paginate($event: PageEvent): void {

    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = ++$event.pageIndex;

    this.userList.find(this.filter);
  }

  get selectedUsers(): ClientUserModel[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter('Users');

    exportAndDownload(name, this.selectedUsers);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach(user => {
      this.updateCheckedSet(checked, user);
    });
  }

  onItemChecked(checked: boolean, user: ClientUserModel): void {
    this.updateCheckedSet(checked, user);
  }

  updateCheckedSet(checked: boolean, user: ClientUserModel): void {
    if (checked) {
      this.selectedItems.set(user.id, user);
    } else {
      this.selectedItems.delete(user.id);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

}
