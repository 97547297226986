<div class="card">
  <div class="card-header bg-white">
    <span class="card-header-title-text">Retrieved
    </span>
    <span class="float-right">
      <span>
        <a [routerLink]="['../']" class="btn btn-sm  btn-transparent border mr-3"><i class="fas fa-arrow-left"></i> Back</a>
      </span>
      <span
        *ngIf="
					(newsState.state | async).news &&
					(newsState.state | async).news?.id
				">
        <a (click)="delete()" class="btn btn-sm  btn-danger text-light border-0 mr-3"><i class="fas fa-trash-alt"></i> Delete</a>
      </span>
      <span
        *ngIf="
					(newsState.state | async).news &&
					(newsState.state | async).news.id
					&& isEditModeDisabled
					">
        <a (click)="toggleEditMode()" class="btn btn-sm  btn-primary text-light border-0 mr-3"><i class="fas fa-trash-alt"></i> Edit</a>
      </span>
      <span>
        <a *ngIf="!isEditModeDisabled"
          href="javascript:;"
          class="btn btn-success kt-margin-r-10"
          color="primary"
          (click)="updateNews(newsForm.value)"
          matTooltip="Save & Continue">Save</a>
      </span>
    </span>
  </div>
  <div class="card-body">
    <form [formGroup]="newsForm">
      <div class="alert alert-primary"
        *ngIf="(newsState.state | async).error"
        type="warn"
        (close)="newsState.resetError()">
        {{ (newsState.state | async).error }}
      </div>

      <div class="row border-bottom py-4">
        <div class="col">
          <input type="text" class="form-control" placeholder="Title" formControlName="title">
          <small align="start" class="text-primary" style="font-size: 14px;">
            <strong>Title</strong> is required
          </small>
        </div>
        <div class="col">
          <input type="textarea" class="form-control" placeholder="category" formControlName="category">
          <small align="start" class="text-primary" style="font-size: 14px;">
            <strong>Please</strong> enter category
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="Page url" formControlName="pageUrl">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>pageUrl</strong>
          </small>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Image url" formControlName="imageUrl">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>imageUrl</strong>
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="summary" formControlName="summary">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>summary</strong>
          </small>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Content" formControlName="content">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>content</strong>
          </small>
        </div>
      </div>
    </form>
  </div>

</div>
