export enum CrudState {
	Retrieve = "Retrieve",
	Retrieved = "Retrieved",
	Create = "Create",
	Created = "Created",
	Update = "Update",
	Updated = "Updated",
	Delete = "Delete",
	Deleted = "Deleted",
	Initial = "Initial",
	Error = "Error",
}
