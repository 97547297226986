import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiModule } from '../../../../../api/src/lib/api.module';
import { StatementListStateService } from './statements/statement-list.state';
import { StatementService } from './statements/statement.service';
import { StatementsTableModule } from './statements/statements-table/statements-table.module';
import { UserStatementsComponent } from './statements/statements.component';
import { WalletTransactionModule } from '../../../../../view/src/lib/transactions/wallet-transaction.module';
import { DwHistoryModule } from '../../../../../view/src/lib/dw-history/dw-history.module';
import { NgxSpinnerModule } from 'ngx-spinner';

const components = [UserStatementsComponent];

@NgModule({
  imports: [
    CommonModule,
    ApiModule,
    StatementsTableModule,
    WalletTransactionModule,
    DwHistoryModule,
    NgxSpinnerModule,
  ],
  exports: [...components, DwHistoryModule, WalletTransactionModule],
  declarations: components,
  providers: [StatementListStateService, StatementService],
})
export class UserTransactionHistoryModule {}
