import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Collection } from '../../services/content.interface';

@Component({
  selector: 'kt-collections-grid',
  templateUrl: './collections-grid.component.html',
  styleUrls: ['./collections-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionsGridComponent implements OnInit {
  @Input() collections: Collection[] = [];

  @Output() collectionClick = new EventEmitter<Collection>();

  constructor() { }

  dynamicBtnStyle(index: number) {
    const reminder = (index + 1) % 3;
    if (reminder === 1) { return 'success'; }
    if (reminder === 2) { return 'danger'; }
    return 'primary';
  }

  ngOnInit() { }
}
