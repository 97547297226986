<div class="row mt-3 mb-3">
  <div class="row mt-5 mb-5 d-none d-md-block">
    <div class="col-12 table-responsive">
      <table class="table table-hover">
        <thead class="border-top-light">
          <tr class="" style="border-top: 0px !important">
            <th scope="col" class="table-title">No</th>
            <th scope="col" class="table-title">Symbol</th>
            <th scope="col" class="table-title">Quantity</th>
            <th scope="col" class="table-title">Current Value</th>
            <th scope="col" class="table-title">Net Earnings</th>
            <th scope="col" class="table-title">Earnings %</th>
            <th scope="col" class="table-title">Stock Price</th>
            <th scope="col" class="table-title">Average Price</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let position of positions; let i = index">
            <td class="table-text">
              {{ i + 1 }}
            </td>
            <td class="table-text">{{ position?.symbol || "-" }}</td>
            <td class="table-text">{{ formatNumber(position?.availableForTradingQty) }}</td>
            <td class="table-text">{{ formatNumber(position?.marketValue) }}</td>
            <td>{{ formatAmount(position?.unrealizedPL) }}</td>
            <td class="table-text">
              {{ formatNumber(position?.netEarningsPercentage) }}
            </td>
            <td class="table-text">
              {{ formatAmount(position?.mktPrice) }}
            </td>
            <td
              class="table-text text-info">
              {{ formatAmount(position?.avgPrice) }}
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row mb-3 d-sm-block d-md-none">
    <div class="col-12 mt-3">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Type</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody *ngFor="let position of positions;  let i = index">
            <tr
              class="accordion-toggle collapsed"
              id="accordion3"
              data-toggle="collapse"
              data-parent="#accordion3"
              href="#collapseThree">
              <td class="expand-button"></td>
              <td>
                <i class="fas fa-circle kt-badge--primary"></i>
                <small class="kt-font-primary px-2">{{
                      position?.symbol || "-"
                    }}</small>
              </td>
              <td>
                <i class="fas fa-cog flaticon2 px-2"></i><i class="fas fa-file flaticon2 px-2"></i><i class="fas fa-times flaticon2 px-2"></i>
              </td>
            </tr>
            <tr class="hide-table-padding">
              <td></td>
              <td colspan="4">
                <div id="collapseThree" class="collapse in p-3">
                  <div class="row">
                    <div class="col-sm-10 px-3">
                      <ul class="list-group">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center">
                          <span
                            class="mobile-table-list font-weight-bold">Symbol</span>
                          <span>{{ position?.symbol || "-" }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center">
                          <span
                            class="mobile-table-list font-weight-bold">Quanitity</span>
                          <span>{{ formatNumber(position?.availableForTradingQty) }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center">
                          <span
                            class="mobile-table-list font-weight-bold">Current Price</span>
                          <span>{{formatNumber(position?.marketValue) }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center">
                          <span
                            class="mobile-table-list font-weight-bold">Net Earnings</span>
                          <span>{{ formatAmount(position?.unrealizedPL) }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center">
                          <span
                            class="mobile-table-list font-weight-bold">Earnings %</span>
                          <span>{{ formatNumber(position?.netEarningsPercentage) }}</span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center">
                          <span
                            class="mobile-table-list font-weight-bold">Stock Price</span>
                          <span> {{ formatAmount(position?.mktPrice) }} </span>
                        </li>
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center">
                          <span
                            class="mobile-table-list font-weight-bold">Average Price</span>
                          <span class="text-info"> {{ formatAmount(position?.avgPrice) }} </span>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
