import { KYC_COUNTRY } from './../../../../../shared/src/lib/_enums/kyc-country.enum';
import { Component, OnInit } from '@angular/core';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { CscsSecondLoginStateService } from './components/cscs-second-login/cscs-second-login-state.service';
import { KYCListStateService } from './kyc-list-state';
import { KYCData } from './kyc.interface';
import { KycActionModalComponent } from './components/kyc-action-modal/kyc-action-modal.component';
import { INgxSmartModalOptions } from 'ngx-smart-modal/src/config/ngx-smart-modal.config';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss'],
  providers: [KYCListStateService]
})
export class KycComponent implements OnInit {
  kycStatus = [
    'PENDING',
    'PENDING_REVALIDATION',
    'PENDING_APPROVAL',
    'STARTED',
    'UPLOADED_DOCUMENTS',
    'REJECTED_PHOTO_ID',
    'REJECTED_ADDRESS_PROOF',
    'FAILED_CSCS_TOKEN',
    'SUBMITTED',
    'VERIFIED',
  ];
  kycCountry = ['NGA', 'US'];

  filter: any = {
    // q: '',
    pageSize: 10,
    pageNumber: 1,
    // country: '',
    // status: '',
  };

  batchToActOn: KYCData[] = [];

  constructor(
    public kycState: KYCListStateService,
    private console: ConsoleRouterService,
    private role: AuthRoleStateService,
    private modal: NgxSmartModalService,
    private cscsSecondLoginState: CscsSecondLoginStateService
  ) { }

  onSearch(value: string) {
    this.filter.q = value;
    this.kycState.loadKYCList(this.filter);
  }

  onChange() {
    this.kycState.loadKYCList(this.filter);
  }

  openModal(modalName: string): void {
    const someLocal = this.batchToActOn.some(({ country }) => country === KYC_COUNTRY.NGA);

    if (!someLocal) {
      this.openKycVerificationModal(modalName);
      return;
    }

    this.cscsSecondLoginState.isLoggedIn ?
      this.openKycVerificationModal(modalName) :
      this.cscsSecondLoginState
        .openModal(
          (success) => {
            success && this.modal.open(modalName);
          }
        );
  }

  private openKycVerificationModal(modalName: string): void {

    console.log('batchToAcON', this.batchToActOn);
    this.modal
      .create(modalName, KycActionModalComponent)
      .setData(this.batchToActOn).open();
  }

  ngOnInit(): void {
    this.role.hasPermission$('KYC_VIEW').subscribe((authorized) => {
      if (!authorized) {
        return this.console.productNavigate(['']);
      }
      this.kycState.loadKYCList(this.filter);
    });
  }
}
