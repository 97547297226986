import { jsPDF } from "jspdf";

import 'jspdf-autotable';
import { ClientUserModel } from 'projects/user-managements/src/lib/models/user.model';
import { MerchantDetailsModel } from 'projects/view/src/lib/merchants/merchants.interface';
import { setHeight } from './table-printer';


export interface DocumentHeaderParam {
  merchant: MerchantDetailsModel;
  user: ClientUserModel;
}

export interface PrinterDocHeader {
  doc: any;
  height: number;
}

export function documentHeader({
  merchant,
  user,
}: DocumentHeaderParam): PrinterDocHeader {
  const moveHeight = setHeight(10);

  const doc = new jsPDF();

  // Merchant Credentials BLOCK
  doc.addImage('' || merchant.logoUrl, 'JPEG', 10, moveHeight(0), 32, 32);
  doc
  .setFontSize(10)
  .text(merchant?.url || 'www.chaka.ng', 10, moveHeight(5))
  .text(merchant?.email || 'support@chaka.ng', 10, moveHeight(5))
  .text(merchant?.phoneNo || '+234-987-654-132', 10, moveHeight(5));


  // User Details Block
  const moveUserBlock = setHeight(10);

  doc.setFontSize(9).text(`Client information`, 130, moveUserBlock(3));
  let line = moveUserBlock(3);

  doc.line(130, line, 200, line);
  doc.setFontSize(12);

  line = moveUserBlock(5);
  doc
    .text(`Name`, 132, line)
    .text(`${user.fullName}`, 160, line);
  line = moveUserBlock(3);
  doc.line(130, line, 200, line);
  
  line = moveUserBlock(5);
  doc
    .text(`Merchant`, 132, line)
    .text(`${merchant.name}`, 160, line);
  line = moveUserBlock(3);
  doc.line(130, line, 200, line);

  line = moveUserBlock(5);
  doc
    .text(`ClientID`, 132, line)
    .text(`-`, 160, line);

  line = moveUserBlock(3);
  doc.line(130, line, 200, line);

  return { doc, height: moveHeight(20) } as PrinterDocHeader;
}
