<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <ngx-spinner bdColor="#4e73df66" size="medium" color="#fff" type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">{{(exchangeState.state|async)?.message}}</p>
    </ngx-spinner>
    <div class="card-header bg-light py-3">
      <div class="row">
        <div class="col-md-6">
          <h6 class="m-0 font-weight-bold text-primary">Exchange Rates</h6>
        </div>
        <div class="col-md-4">
          <h6 class="m-0 font-weight-bold text-primary"></h6>
        </div>
        <div *ngIf="!createNewRate" class="col-md-2">
          <button class="m-0 font-weight-bold text-primary border-0" (click)="create()">New Rate</button>
        </div>
        <div *ngIf="createNewRate" class="col-md-2">
          <button class="m-0 font-weight-bold text-primary border-0" (click)="allRates()">View Rates</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div>
        <ng-container *ngIf="createNewRate; else showRate">
          <div class="kt-form">
            <form #RateForm="ngForm">
              <div class="card-mobile__body">

                <b class="question__description mb-3">
                  Please enter your buy and sell rate
                </b>

                <div class="my-4">
                  <p class="question__description mb-2">
                    Buy rate
                  </p>
                  <input class="amount__input border form-control" type="number" name="firstname" placeholder="Enter buy rate"
                    [(ngModel)]="newRate.buy" #buy="ngModel" required />
                  <div *ngIf="buy?.invalid && (buy?.dirty || buy?.touched)" class="space text-danger">
                    <div *ngIf="buy.errors?.required">
                      Buy rate is required.
                    </div>
                  </div>
                </div>
                <div class="my-2">
                  <p class="question__description mb-2">
                    Sell rate
                  </p>
                  <input class="amount__input border form-control" type="number" name="sell" placeholder="Enter sell rate"
                    [(ngModel)]="newRate.sell" #sell="ngModel" required />
                  <div *ngIf="sell?.invalid && (sell?.dirty || sell?.touched)" class="space text-danger">
                    <div *ngIf="sell.errors?.required">
                      Sell rate is required.
                    </div>
                  </div>
                </div>

              </div>
              <div class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border">
                <div class="kt-form__actions kt-form__actions--solid row">
                  <div class="col-lg-12 text-center">
                    <button class="btn btn-danger" (click)="RateForm.reset()" type="button" matTooltip="Cancel changes">
                     Cancel
                    </button>&nbsp;
                    <button type="button" class="btn btn-primary px-4 text-white" [disabled]="!RateForm.form.valid"
                      (click)="createRate()" matTooltip="Save changes">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </ng-container>
      </div>
      <ng-template #showRate>
        <div class="">
          <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div class="row mt-3 mb-3">
              <div class="col-sm-12 col-md-12 mb-3">
                <div class="kt-input-icon kt-input-icon--left">
                  <input (keyup)="exchangeRateList.find({q:$event.target.value})" type="search"
                    class="form-control border-top-0 border-left-0 border-right-0 border-primary"
                    placeholder="Search All Feeds By Reference" id="generalSearch">
                  <span class="kt-input-icon__icon kt-input-icon__icon--left">
                    <span><i class="la la-search"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-5 d-none d-md-block">
              <div class="col-sm-12 table-responsive">
                <table class="table table-hover">
                  <thead class="border-top-light">
                    <tr style="border-top: 0px !important;">
                      <th scope="col" class="table-title">User Name</th>
                      <th scope="col" class="table-title">Buy Rate</th>
                      <th scope="col" class="table-title">Sell Rate</th>
                      <!-- <th scope="col" class="table-title">Date</th> -->
                      <th scope="col" class="table-title">Date</th>
                      <!-- <th scope="col" class="table-title">Actions</th> -->

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let exchange of (exchangeRateList.state | async ).exchangerate">
                      <td class="table-text" (click)="viewRate(exchange.id)"><a
                          href="javascript:void(0)">{{exchange.createdBy || "-"}}</a></td>
                      <td class="table-text">{{exchange?.buy || "-"}}</td>
                      <td> {{exchange?.sell || "-"}}</td>
                      <td class="table-text">{{exchange?.dateCreated |date: 'medium' || "-"}}</td>
                      <!-- <td class="table-text px-4" (click)="viewRate(exchange?.id)"><i
                          class="fas fa-location-arrow text-primary fa-lg"></i></td> -->

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row  mb-3 d-sm-block d-md-none">
              <div class="col-lg-12 mt-3">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">
                          <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                          </div>
                        </th>
                        <th scope="col">Type</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody class="mb-0" *ngFor="let  exchange of (exchangeRateList.state | async ).exchangerate">

                      <tr class="accordion-toggle collapsed" id="accordion3" data-toggle="collapse"
                        data-parent="#accordion3" href="#collapseThree">
                        <td class="expand-button"></td>
                        <td>
                          <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input"
                              style="background-color: #ccc !important; color: #6b7188 !important;" id="exampleCheck1">
                          </div>
                        </td>
                        <td><i class="fas fa-circle kt-badge--primary"></i> <small
                            class="kt-font-primary px-2">{{exchange.createdBy}}</small></td>
                        <td><i class="fas fa-cog flaticon2 px-2"></i><i class="fas fa-file flaticon2 px-2"></i><i
                            class="fas fa-times flaticon2 px-2"></i></td>

                      </tr>
                      <tr class="hide-table-padding ">
                        <td></td>
                        <td colspan=" 4">
                          <div id="collapseThree" class="collapse in p-3">
                            <div class="row">
                              <div class="col-sm-10 px-3">
                                <ul class="list-group">
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="mobile-table-list font-weight-bold">User Name</span>
                                    <span (click)="viewRate(exchange.id)">{{exchange.createdBy}}</span>
                                  </li>
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="mobile-table-list font-weight-bold">Buy Rate</span>
                                    <span>{{exchange?.buy| number: "1.1-2" || "-"}}</span>
                                  </li>
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="mobile-table-list font-weight-bold">Sell Rate</span>
                                    <span>{{exchange?.sell || "-"}}</span>
                                  </li>
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="mobile-table-list font-weight-bold">Date</span>
                                    <span class="shaddow"
                                      style="border-radius: 1rem; background-color: #5867dd; font-size: 0.8rem; color: #fff;">{{exchange?.dateCreated |date:'fullDate'  || "-"}}</span>
                                  </li>

                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

            </div>
          <lib-pagination (paginate)="paginate($event)" [count]="count"  [pageSize]="pageSize" ></lib-pagination>
          </div>

        </div>
      </ng-template>
    </div>
  </div>
</div>
