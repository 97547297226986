import { Injectable } from '@angular/core';
import {
  PaginatedList,
  ReqFailureResponse,
  ChakaAPIError
} from './../../../../../projects/api/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { InstrumentModel } from './models/instrument.interface';
import { InstrumentService } from './services/instrument.service';


export interface InstrumentListState {
  loading: boolean;
  count?: number;
  Instruments: InstrumentModel[];
  error?: string;
  page: number;
}

const initialState: InstrumentListState = {
  loading: false,
  Instruments: [],
  page: 0
};

@Injectable({ providedIn: 'root' })
export class InstrumentListStateService {
  state = new BehaviorSubject<InstrumentListState>(initialState);

  constructor(private InstrumentSvc: InstrumentService) { }

  find(query: object) {
    this.loading();
    this.InstrumentSvc
      .getInstruments(query)
      .pipe(first())
      .subscribe({
        next: this.updateInstruments.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateInstruments(data: PaginatedList<InstrumentModel>) {
    this.state.next({
      loading: false,
      Instruments: data.data,
      count: data.count,
      page: data.pageNumber
    });
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: res.error.message,
      loading: false,
    });
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
  }
}
