<ngx-spinner
  [name]="instrument.spinnerName"
  bdColor="#4e73df66"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen]="true"
>
  <p style="font-size: 20px; color: white">
    {{ (instrument.state | async)?.message }}
  </p>
</ngx-spinner>

<div class="container">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="requeryForm">
        <div class="form-row">
          <div class="col-md-12 form-group">
            <label for="symbol"> Symbol </label>
            <input id="symbol" class="form-control" formControlName="symbol" />
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 form-group">
            <label for="currencyCode"> Currency Code </label>
            <select
            formControlName="currencyCode"
            class="form-control"
            id="currencyCode"
          >
            <option value="USD">Global</option>
            <option value="NGN">LOcal</option>
          </select>
          </div>
        </div>
        <div class="form-row">
          <button
            class="btn btn-primary w-100"
            [disabled]="!requeryForm.valid || ((instrument.state | async)?.loading)"
            (click)="requery(requeryForm.value)"
          >
            {{ (instrument.state | async)?.loading ? 'Requerying.......': 'Requery Instrument' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
