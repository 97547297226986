export interface LoginResponse {
  access_token: string;
  token_type: string;
  scope: string;
  AUTHORITIES: string[];
  chakaId: string;
  ENTITIES: string[];
  userId: string;
  email: string;
  jti: string;
}

/**
 * APIResponse format from Chaka API's
 */
export type APIResponse<T> =
  | ReqSuccessResponse<T>
  | PaginatedList<T>
  | ReqFailureResponse;

/**
 * Clean a paginated response
 */
export interface PaginatedList<T> {
  /**
   * stock details list
   */
  data: T[];
  /**
   * the current page number
   */
  pageNumber: number;
  /**
   * the amount of stocks in the list
   */
  pageSize: number;
  /**
   * total number of data available
   */
  count: number;
}

/**
 * Succesful API response for chaka servers
 */
export interface ReqSuccessResponse<T> {
  /**
   * status is always true for successful request
   */
  status: number;
  /**
   * response data object type
   */
  data: T;
  /**
   * pagination data for paginated responses
   */
  pagination?: unknown;

  success?: boolean;
  message?: string
}

/**
 * Failure response sent by chaka server
 * for error during operations
 */
export interface ReqFailureResponse<T = null> {
  /**
   * status is false foe failed operations
   */
  status: number;
  /**
   * Error message from the server
   * during processing and operations
   */
  message: string;
  errors: T;
}

/**
 * details about form field errors
 */
export interface FormError {
  message: string;
  fieldName: string;
}

export interface ChakaAPIError extends Error {
  error: ReqFailureResponse;
}

// tslint:disable-next-line: typedef
export function cleanChakaAPIError(error: Error | ChakaAPIError) {
  if ((error as ChakaAPIError).error) {
    return (error as ChakaAPIError).error.message;
  }

  return error.message;
}
