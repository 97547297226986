import { nonNegativeNumber } from '../../../../../../../shared/src/lib/utils/nonNegativeNumber';
import { IFee } from './../../../fees.interface';
import { MatSidenav } from '@angular/material/sidenav';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MerchantsModel } from '../../../merchants.interface';
import { MerchantState, MerchantStateService } from '../../../merchant-details/merchant-state.service';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { MatStepper } from '@angular/material/stepper';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'merchant-fee-settings',
  templateUrl: './fee-settings.component.html',
  styleUrls: ['./fee-settings.component.scss'],
  providers: [MessagesService]
})

export class MerchantFeeSettingsComponent implements OnInit, OnDestroy, AfterViewChecked {

  fee: IFee;
  @Input() merchant?: MerchantsModel;
  @Input() drawer: MatSidenav;

  @ViewChild('feeBandsDiv') feeBandsDiv: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  feeSettingsForm: FormGroup;
  feeBandsForm: FormGroup;

  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(
    public messagesService: MessagesService,
    private merchantStateService: MerchantStateService
  ) { }

  ngAfterViewChecked(): void {
    const lastIndex = this.feeBandsControls.length - 1;

    if (this.feeBandsControls.length > 1) {
      this.feeBandsControls[lastIndex].get('maxTxnSize').reset();
      this.feeBandsControls[lastIndex].get('maxTxnSize').setValue(null);
      this.feeBandsControls[lastIndex].get('maxTxnSize').disable();

      this.feeBandsControls[lastIndex - 1].get('maxTxnSize').enable();

      this.feeBandValidator();

      // this.feeBandsControls[lastIndex - 1].get('maxTxnSize').updateValueAndValidity();
      this.feeBandsControls[lastIndex - 1].get('maxTxnSize').markAsTouched();
    }
  }

  ngOnInit(): void {
    this.initFeeSettingsForm();
    this.initFeeBandsForm();

    this.drawer.openedChange.subscribe(isOpened => {
      isOpened ?
        this.listenToMerchantState() :
        this.stopListeningToMerchantState();
    });
  }

  initFeeSettingsForm(): void {
    this.feeSettingsForm = new FormGroup({
      name: new FormControl('', Validators.required),
      value: new FormControl(null, [
        Validators.required,
        Validators.pattern(nonNegativeNumber)
      ]),
      description: new FormControl('', Validators.required),
      percentage: new FormControl(false),
      merchantId: new FormControl(this.merchant.id, Validators.required)
    });
  }

  initFeeBandsForm(): void {
    this.feeBandsForm = new FormGroup({
      feeBands: new FormArray([
        new FormGroup({
          minTxnSize: new FormControl(0, [Validators.required]),
          maxTxnSize: new FormControl(null, [Validators.required]),
          percentFee: new FormControl(null, [Validators.required]),
          minCommissionFee: new FormControl(null, [Validators.required]),
        })
      ])
    });

    // this.feeBandsControls[0].setValidators(this.feeBandValidator());

    this.feeBandValidator();
  }

  private listenToMerchantState(): void {

    this.merchantStateService.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        tap((
          { fees }: MerchantState) => {
          this.fee = fees;

          if (this.fee && this.fee.id) {
            this.fillForms(this.fee);
          }
        })
      ).subscribe();
  }

  fillForms(feeDetails: IFee): any {
    this.feeSettingsForm.addControl('id', new FormControl(feeDetails.id));

    this.feeSettingsForm.patchValue({
      id: feeDetails?.id,
      name: feeDetails?.name,
      value: feeDetails?.value,
      description: feeDetails?.description,
      percentage: feeDetails?.isPercentage,
      merchantId: feeDetails?.merchantId
    });

    const feeBands = new FormArray([]);

    if (feeDetails?.bands && feeDetails?.bands.length > 0) {
      for (const fee of feeDetails.bands) {
        const group = new FormGroup({
          id: new FormControl(fee.id),
          minTxnSize: new FormControl(fee.minTxnSize, [Validators.required]),
          maxTxnSize: new FormControl(fee.maxTxnSize, [Validators.required]),
          percentFee: new FormControl(fee.percentFee, [Validators.required]),
          minCommissionFee: new FormControl(fee.minCommissionFee, [Validators.required]),
        });

        feeBands.push(group);
      }

      this.feeBandsForm.setControl('feeBands', feeBands);

      this.feeBandValidator();
    }

  }

  private stopListeningToMerchantState(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public feeBandValidator(): ValidatorFn | null {
    // return (group: FormGroup): ValidationErrors => {

    const feeBandsLength = this.feeBandsControls.length;
    const maxError = { invalidMaxValue: 'Max value must be greater than min value' };
    const minError = { invalidMinValue: 'Min value cannot be greater than or equal to previous max value' };


    for (let i = 0; i < feeBandsLength; i++) {

      // only when not disabled
      if (!this.feeBandsControls[i].get('maxTxnSize').disabled) {
        if (this.feeBandsControls[i].get('maxTxnSize').value <= this.feeBandsControls[i].get('minTxnSize').value) {

          this.feeBandsControls[i].get('maxTxnSize').setErrors(maxError);
          this.feeBandsControls[i].get('maxTxnSize').markAsTouched();
        }
      }

      if (i > 0) {
        if (this.feeBandsControls[i].get('minTxnSize').value < this.feeBandsControls[i - 1].get('maxTxnSize').value) {
          this.feeBandsControls[i].get('minTxnSize').setErrors(minError);
          this.feeBandsControls[i].get('minTxnSize').markAsTouched();
        }
      }


    }

    return;

  }

  get feeBandsControls(): AbstractControl[] {
    return (this.feeBandsForm.get('feeBands') as FormArray).controls;
  }

  onAddFeeBand(): any {
    if (!this.feeBandsForm.valid) {
      this.feeBandsForm.markAllAsTouched();
      return;
    }

    const { value } = this.feeBandsForm.get('feeBands');

    const lastMaxFee = value[value.length - 1].maxTxnSize;

    const group = new FormGroup({
      minTxnSize: new FormControl(lastMaxFee, [Validators.required]),
      maxTxnSize: new FormControl(null),
      percentFee: new FormControl(null, [Validators.required]),
      minCommissionFee: new FormControl(null, [Validators.required]),
    });

    // group.setValidators(this.feeBandValidator());

    (this.feeBandsForm.get('feeBands') as FormArray).push(group);

    this.feeBandValidator();

    // this.autoScroll();
  }

  onRemoveFeeBand(): void {
    const lastIndex = this.feeBandsControls.length - 1;

    const bandId = this.feeBandsControls[lastIndex].get('id')?.value;

    const isUpdateMode = !!this.feeBandsControls[lastIndex] && !!bandId;

    if (!isUpdateMode) {
      this.removeAFeeControl(lastIndex);
    } else {
      // we call endpoint to delete first
      this.messagesService.open(MessagesEnum.loading, 'Deleting fee band, please wait...');
      this.merchantStateService
        .deleteFeeBand(bandId)
        .subscribe(
          (response) => {
            console.log(response);
            this.messagesService.update({
              type: MessagesEnum.success,
              message: 'Fee band deleted successfully.'
            }, {
              callBack: () => {
                this.removeAFeeControl(lastIndex);
              }
            });
          },
          (error) => {
            const message = cleanChakaAPIError(error);
            this.messagesService.update({ type: MessagesEnum.danger, message });
          }
        );
    }
  }


  private removeAFeeControl(lastIndex: number): void {
    (this.feeBandsForm
      .get('feeBands') as FormArray)
      .removeAt(lastIndex);

    this.ngAfterViewChecked();
  }

  autoScroll(): void {
    // New message element
    const $newMessage = this.feeBandsDiv.nativeElement.lastElementChild;

    // Height of new messages
    const newMessageStyles = getComputedStyle($newMessage);
    const newMessageMargin = parseInt(newMessageStyles.marginBottom);
    const newMessageHeight = $newMessage.offsetHeight + newMessageMargin;

    // Visible Height
    const visibleHeight = this.feeBandsDiv.nativeElement.offsetHeight;

    // Height of messages container
    const containerHeight = this.feeBandsDiv.nativeElement.scrollHeight;

    // How far have i scrolled
    const scrollOffset = this.feeBandsDiv.nativeElement.scrollTop + visibleHeight;

    if (containerHeight - newMessageHeight <= scrollOffset) {
      setTimeout(() => {
        this.feeBandsDiv.nativeElement.scrollTop = this.feeBandsDiv.nativeElement.scrollHeight;
      }, 0);
    }
  }

  onMinValueChange(): void {
    // this.feeBandValidator();
    for (let control of this.feeBandsControls) {
      control.get('minTxnSize').markAsTouched();

      control.get('maxTxnSize').updateValueAndValidity();
      control.get('maxTxnSize').markAsTouched();
    }
  }

  onMaxValueChange(): void {
    // this.feeBandValidator();
    for (let control of this.feeBandsControls) {
      control.get('minTxnSize').updateValueAndValidity();
      control.get('minTxnSize').markAsTouched();

      control.get('maxTxnSize').markAsTouched();
    }
  }

  get formHeight(): string {
    const totalMessages = this.messagesService.totalOpened;
    const gap: number = 75 - (totalMessages * 5.25);

    return gap + 'vh';
  }

  get selectedIndex(): number {
    return this.stepper.selectedIndex;
  }

  set selectedIndex(value: number) {
    this.stepper.selectedIndex = value;
  }

  backStep(): void {
    this.stepper.previous();
  }

  nextStep(): void {
    if (!this.feeSettingsForm.valid) {
      this.feeSettingsForm.markAllAsTouched();
      return;
    }

    this.stepper.next();
  }

  onSave(): void {
    if (!this.feeSettingsForm.valid) {
      if (this.selectedIndex === 1) {
        this.selectedIndex = 0;
      }

      this.feeSettingsForm.markAllAsTouched();
      return;
    }

    const dataToSubmit: IFee = {
      ...this.feeSettingsForm.value,
    };

    if (!this.feeBandsForm.valid) {
      if (this.selectedIndex === 0) {
        this.selectedIndex = 1;
      }

      this.feeBandsForm.markAllAsTouched();
      return;
    } else {
      dataToSubmit.bands = this.feeBandsForm.get('feeBands').value;
    }

    this.submitFeeBand(dataToSubmit);
  }

  private submitFeeBand(dataToSubmit: IFee): void {
    const isUpdate = dataToSubmit.hasOwnProperty('id');

    const saveMessage = `${isUpdate ? 'Updating' : 'Saving'} fee settings...`;
    const successMessage = `Successfully ${isUpdate ? 'updated' : 'saved'} fee settings.`;

    this.messagesService.open(
      MessagesEnum.loading, saveMessage);

    this.merchantStateService
      .saveFee(dataToSubmit)
      .subscribe({
        next: (response) => {
          this.messagesService.update({
            type: MessagesEnum.success,
            message: successMessage
          });

          this.merchantStateService.retrieveFeeSettingsById(response.data.merchantId);
        },
        error: (error: ChakaAPIError) => {
          const message = cleanChakaAPIError(error);

          this.messagesService.update({ type: MessagesEnum.danger, message });
        }
      });

  }

  closeDrawer(): void {
    this.messagesService.hideAll();

    this.merchantStateService.retrieveFeeSettingsById(this.merchant.id);

    this.drawer.close();
  }

  ngOnDestroy(): void {
    this.stopListeningToMerchantState();
  }

}
