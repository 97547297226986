<!--begin::Card-->
<div class="card card-custom gutter-b">
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">Recurring Payments</h3>
    </div>

    <div class="card-toolbar">
      <button href="javascript:;" mat-button (click)="openDrawer()" class="btn btn-primary btn-sm">
        <span class="svg-icon svg-icon-md svg-icon-white">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
              <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" />
            </g>
          </svg>

        </span>Add Recurring Payment</button>
    </div>
  </div>

  <div class="card-body">

    <!--begin: Search Form-->
    <div class="mb-5">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <form [formGroup]="searchForm">
            <div class="row align-items-center">

              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>Search all feeds</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="q"
                    trimmedInput>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label> Status</label>
                  <ng-select formControlName="status" [clearable]="false">
                    <ng-option [value]="status.value" *ngFor="let status of redemptionStatusList">{{ status.label }}</ng-option>
                  </ng-select>
                </div>
              </div>

              <div
                class="col-xl-2 col-lg-4 col-md-6"
                *ngIf="isSuperAdmin">
                <div class="form-group">
                  <label>Merchant</label>
                  <ng-select
                    formControlName="merchantId"
                    [clearable]="false"
                    [virtualScroll]="true"
                    [loading]="this.merchants?.loading">
                    <ng-option value="">All</ng-option>
                    <ng-option
                      [value]="merchant?.id"
                      *ngFor="let merchant of merchants?.list">
                      {{ merchant?.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>From</label>
                  <input class="form-control" type="date" formControlName="from">
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>To</label>
                  <input class="form-control" type="date" formControlName="to">
                </div>
              </div>

              <div class="col-xl-1 col-lg-4 col-md-6">
                <div class="form-group">
                  <label class="d-block">&nbsp;</label>
                  <button
                    type="button"
                    class="btn btn-light-primary font-weight-bold"
                    (click)="handleQuery()">Search</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end::Search Form-->

    <!--begin: Datatable-->

    <lib-messages></lib-messages>

    <div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

      <div class="mt-5 mb-5">
        <div class="d-flex align-items-center">
          <div class="font-weight-bold">
            Selected {{selectedItems.size}} {{selectedItems.size <= 1 ?'record': 'records'}}:
            <button
              [disabled]="selectedItems.size <= 0"
              (click)="downloadFile()"
              class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
              <i class="far fa-arrow-alt-circle-down"></i> Download
            </button>
          </div>
        </div>
      </div>


      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matMultiSort aria-label="Redemption table">

          <ng-container matColumnDef="check" sticky>
            <th
              mat-header-cell
              *matHeaderCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single checkbox-all">
                  <input
                    (change)="onAllChecked($event.target.checked)"
                    type="checkbox"
                    [checked]="allSelected">&nbsp;<span></span>
                </label>
              </span>
            </th>

            <td
              mat-cell
              *matCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single">
                  <input
                    type="checkbox"
                    (change)="onItemChecked($event.target.checked, row)"
                    [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
                </label>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="customerName"> Name </th>
            <td mat-cell *matCellDef="let row">
              <a
                *ngIf="(!this.fromUserComponent || !row?.userId) else noUser"
                href="javascript:void(0)"
                (click)="viewUser(row?.userId)">
                {{row?.customerName || "-"}}</a>

              <ng-template #noUser>{{row?.customerName || "-"}}</ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="customerPhone">
            <th mat-header-cell *matHeaderCellDef> Phone </th>
            <td mat-cell *matCellDef="let row">
              {{row?.customerPhone}}
            </td>
          </ng-container>

          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="source"> Source</th>
            <td mat-cell *matCellDef="let row">
              {{row?.source}}
            </td>
          </ng-container>

          <ng-container matColumnDef="notificationType">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="notificationType"> Notification Type</th>
            <td mat-cell *matCellDef="let row">
              {{row?.notificationType}}
            </td>
          </ng-container>

          <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="currency"> Currency</th>
            <td mat-cell *matCellDef="let row">
              {{row?.currency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="frequency"> Frequency</th>
            <td mat-cell *matCellDef="let row">
              {{row?.frequency}}
            </td>
          </ng-container>

          <ng-container matColumnDef="productType">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="productType"> Product Type</th>
            <td mat-cell *matCellDef="let row">
              {{row?.productType}}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="dateCreated"> Date </th>
            <td mat-cell *matCellDef="let row">
              {{row?.dateCreated | date:'mediumDate' || "-"}}
            </td>
          </ng-container>

          <ng-container matColumnDef="action" stickyEnd>
            <th
              mat-header-cell
              *matHeaderCellDef class="text-center">
              Action
            </th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <button
                class="btn btn-icon btn-sm btn-circle mr-1"
                (click)="openDrawer(row)">
                <i class="fas fa-edit"></i>
              </button>

              <button
                class="btn btn-icon btn-sm btn-circle"
                (click)="viewMandate(row.id)">
                <i class="fas fa-eye"></i>
              </button>

            </td>
          </ng-container>

          <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true">
          </tr>
          <tr mat-row
            *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>

      <mat-paginator
        class="mat-paginator-sticky"
        [length]="count"
        [pageSizeOptions]="[10, 50, 100, 150, 200]"
        (page)="pageEvent=$event; paginate($event)"
        showFirstLastButtons></mat-paginator>
    </div>

    <div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
      <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
      <p class="no-data-text">No data</p>
    </div>


    <!--end: Datatable-->
  </div>
</div>
<!--end::Card-->


<mat-drawer #drawer mode="over">
  <lib-mandate-edit-drawer
    [drawer]="drawer"
    (saveSuccess)="handleQuery()"
    [userInfo]="userInfo"
    [activeMandate]="activeMandate"></lib-mandate-edit-drawer>
</mat-drawer>
