export enum CURRENCY {
	NAIRA = "NGN",
	DOLLAR = "USD",
}

export enum Currency {
  USD = 'USD',
  NGN = 'NGN'
}

