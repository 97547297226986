import { ORDER_SIDE, ORDER_TYPE } from '@console/shared';
export class OpenOrdersModel {
    orderID:                    string;
    orderNo:                    string;
    createdWhen:                string;
    symbol:                     string;
    cumQty:                     number;
    orderStatus:                string;
    orderType:                  ORDER_TYPE;
    orderQty:                   number;
    isoTimeRestingOrderExpires: string;
    limitPrice:                 number;
    side:                       ORDER_SIDE;
    orderCashAmt:               number;
    stopPrice:                  number;
}
