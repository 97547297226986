import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

type AppSettingsModel = Record<string, any>;

@Component({
  selector: 'kt-merchant-config-editor',
  templateUrl: './merchant-config-editor.component.html',
  styleUrls: ['./merchant-config-editor.component.scss']
})
export class MerchantConfigEditorComponent implements OnInit {

  configForm: FormGroup;

	@Input() config: AppSettingsModel;
	@Input() disabled = false;
	@Input() settingsGroups = [];

	@Output() save = new EventEmitter<AppSettingsModel>();
	@Output() delete = new EventEmitter<number>();
	@Output() cancel = new EventEmitter();

  constructor(private fb: FormBuilder) { }

  createForm(settings: Partial<AppSettingsModel> = {}) {
    return this.fb.group({
      name: [settings.name || '', Validators.required],
      value: [settings.value || '', Validators.required],
      settingsGroup: [settings.name || '', Validators.required],
      description: [settings.description || '', Validators.required],
      encrypted: [settings.encrypted || '', Validators.required],
    })
  }

  ngOnInit() {
    this.configForm = this.createForm(this.config);
  }

}
