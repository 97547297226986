import { InstrumentModel } from "../../models/instrument.interface";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { CURRENCY } from "../../../../../../shared/src/public-api";

@Component({
  selector: "kt-instrument-card1",
  templateUrl: "./instrument-card.component.html",
  styleUrls: ["./instrument-card.component.scss"],
})
export class InstrumentCardComponent implements OnInit {
  @Input() instrument: InstrumentModel;

  @Output() view = new EventEmitter<InstrumentModel>();

  constructor() { }

	/**
	 * remap the data and add index
	 */
  cleanName(instrument: InstrumentModel) {
    const [name1, name2] = instrument.name.split(" ");
    return `${name1} ${name2 || ""}`;
  }

  formatImgUrl(instrument: InstrumentModel) {
    if (instrument.currency.toUpperCase() === CURRENCY.DOLLAR) {
      return `https://drivewealth.imgix.net/symbols/${instrument.symbol.toLowerCase()}.png?fit=fillmax&w=125&h=125&bg=FFFFFF`;
    }
    return instrument.image;
  }

  onView() {
    this.view.emit(this.instrument);
  }

  ngOnInit() { }
}
