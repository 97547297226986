<h2 mat-dialog-title>{{ title }}</h2>
<form [formGroup]="referralPolicyForm" (ngSubmit)="save()">

  <mat-dialog-content>
    <div class="form-group mb-8">
      <lib-messages></lib-messages>
    </div>

    <div class="form-group">
      <label>Merchant <span class="text-danger">*</span></label>
      <ng-select
        formControlName="merchantId">
        <ng-option
          [value]="merchant.id"
          *ngFor="let merchant of merchantList">{{ merchant.name }}</ng-option>
      </ng-select>
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('merchantId')">
        <div *ngIf="formMethods.getControl('merchantId').hasError('required')">
          Required input.
        </div>
      </small>
    </div>

    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Min Count <span class="text-danger">*</span></label>
          <input
            type="number"
            class="form-control"
            formControlName="minCount">
          <small
            class="form-text text-danger"
            *ngIf="formMethods.touchedAndInvalid('minCount')">
            <div *ngIf="formMethods.getControl('minCount').hasError('required')">
              Required input.
            </div>
          </small>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Max Count <span class="text-danger">*</span></label>
          <input
            type="number"
            class="form-control"
            formControlName="maxCount">
          <small
            class="form-text text-danger"
            *ngIf="formMethods.touchedAndInvalid('maxCount')">
            <div *ngIf="formMethods.getControl('maxCount').hasError('required')">
              Required input.
            </div>
            <div *ngIf="formMethods.getControl('maxCount').hasError('invalidMaxCount')">
              Max count must be greater than min count.
            </div>
          </small>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Referee Amount <span class="text-danger">*</span></label>
      <input
        type="number"
        class="form-control"
        formControlName="refereeAmount">
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('refereeAmount')">
        <div *ngIf="formMethods.getControl('refereeAmount').hasError('required')">
          Required input.
        </div>
        <div *ngIf="formMethods.getControl('refereeAmount').hasError('pattern')">Value must be greater than zero</div>
      </small>
    </div>

    <div class="form-group">
      <label>Referrer Amount <span class="text-danger">*</span></label>
      <input
        type="number"
        class="form-control"
        formControlName="referrerAmount">
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('referrerAmount')">
        <div *ngIf="formMethods.getControl('referrerAmount').hasError('required')">
          Required input.
        </div>
        <div *ngIf="formMethods.getControl('referrerAmount').hasError('pattern')">Value must be greater than zero</div>
      </small>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions mat-dialog-actions class="d-flex align-items-center justify-content-between">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="submit" class="btn btn-primary">Save</button>
  </mat-dialog-actions>
</form>
