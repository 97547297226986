import { UsersKycService } from './../../../../view/src/lib/user-management/kyc/kyc.service';
import { Injectable } from '@angular/core';
import {
  cleanChakaAPIError, PaginatedList
} from '@console/api';
import { DateUtil } from '@console/shared/utils/dateUtil';
import { ClientUserModel } from '@console/user-managements/models/user.model';
import { OrderTxnsModel } from 'projects/view/src/lib/orders/services/orders.model';
import { OrderTxnService } from 'projects/view/src/lib/orders/services/orders.service';
import { PaymentTxnsModel } from 'projects/view/src/lib/payment/services/payment.model';
import { PaymentTxnService } from 'projects/view/src/lib/payment/services/payment.service';
import { WalletTxnDetailsModel } from 'projects/view/src/lib/transactions/services/transactions.model';
import { WalletTxnService } from 'projects/view/src/lib/transactions/services/transactions.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { ReportEnum } from '../enums/ReportEnum';
import { IOrderStat, IPaymentStat, ITransactionStat, IUserStat, MerchantStat } from '../services/analytics.interface';
import { AnalyticService } from '../services/analytics.service';
import { KYCData } from 'projects/view/src/lib/user-management/kyc/kyc.interface';


export interface MerchantAnalytics {
  users: IUserStat[];
  orders: IOrderStat[];
  payments: IPaymentStat[];
  transactions: ITransactionStat[];
}

export interface AnalyticsReport {
  [ReportEnum.ORDERS]: OrderTxnsModel[];
  [ReportEnum.PAYMENTS]: PaymentTxnsModel[];
  [ReportEnum.TRANSACTIONS]: WalletTxnDetailsModel[];
  [ReportEnum.USERS]: KYCData[];
}

export interface MerchantAnalyticState {
  stats: MerchantAnalytics;
  reports: AnalyticsReport;
  activeReport: ReportEnum;
  loading: boolean;
  reportLoading: boolean;
  message?: string;
}

const initialState: MerchantAnalyticState = {
  loading: false,
  reportLoading: true,
  stats: { orders: [], payments: [], transactions: [], users: [] },
  reports: {
    [ReportEnum.ORDERS]: [],
    [ReportEnum.PAYMENTS]: [],
    [ReportEnum.TRANSACTIONS]: [],
    [ReportEnum.USERS]: []
  },
  activeReport: ReportEnum.ORDERS
};

const filter: Record<string, any> = {
  pageSize: 8,
  from: DateUtil.subtractDaysFromDate(new Date(), 7).toISOString(),
  to: new Date().toISOString()
};

@Injectable({ providedIn: 'root' })
export class MerchantAnalyticStateService {
  state = new BehaviorSubject<MerchantAnalyticState>(initialState);

  spinnerName = 'Analytic-Spinner';

  constructor(
    private analytics: AnalyticService,
    private orderSvc: OrderTxnService,
    private paymentSvc: PaymentTxnService,
    private transactionSvc: WalletTxnService,
    private userKycSvc: UsersKycService,
  ) {}

  loadStatsRange(query: Record<string, string> = {}): void {
    this.loading();

    const $payments = this.wrap$(this.analytics.payments(query));
    const $orders = this.wrap$(this.analytics.orders(query));
    const $transactions = this.wrap$(this.analytics.transactions(query));
    const $users = this.wrap$(this.analytics.users(query));

    combineLatest([$users, $payments, $orders, $transactions])
      .pipe(
        (
          map(
            (
              [
                users,
                payments,
                orders,
                transactions
              ]
            ) => ({ users, payments, orders, transactions }))
        )
      )
      .subscribe(this.onStatSLoaded.bind(this));
  }

  resetState(): void {
    this.state.next(initialState);
  }

  get loading$(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  private wrap$<T>($: Observable<PaginatedList<T>>): Observable<string | T[]> {
    return $.pipe(map(res => res.data), catchError(err => of(cleanChakaAPIError(err))));
  }

  private loading(): void {
    this.state.next({
      ...this.state.getValue(),
      loading: true
    });
  }
  private onStatSLoaded(data: MerchantAnalytics): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      stats: data
    });
  }

  private loadingReport(reportType: ReportEnum): void {
    this.state.next({
      ...this.state.getValue(),
      reportLoading: true,
      activeReport: reportType
    });
  }

  getReportAnalytics(reportType: ReportEnum): void {
    this.loadingReport(reportType);

    const reportMap = {
      [ReportEnum.ORDERS]: this.orderSvc.getOrderTxns(filter),
      [ReportEnum.PAYMENTS]: this.paymentSvc.getPaymentTxns(filter),
      [ReportEnum.TRANSACTIONS]: this.transactionSvc.getWalletTxns(filter),
      [ReportEnum.USERS]: this.userKycSvc.KycLists(filter),
    };

    const observer$: Observable<unknown> = reportMap[reportType];

    observer$.pipe(
      first(),
    ).subscribe({
      next: ({ data }) => this.onReportedLoad(data, reportType),
      error: (error) => {
        console.log(error);
      }
    });
  }

  private onReportedLoad(
    data: OrderTxnsModel[] |
      PaymentTxnsModel[] |
      WalletTxnDetailsModel[]
      | KYCData[],
    reportType: ReportEnum): void {

    this.state.next({
      ...this.state.getValue(),
      reports: {
        ...this.state.getValue().reports,
        [reportType]: data,
      },
      reportLoading: false
    });

  }

}
