<div class="row">
  <div class="col-12">
    <form [formGroup]="employmentForm">
      <div class="form-group kt-form__group row">
        <div class="col-lg-12">
          <label for="nextOfKin"> Next of kin name </label>
          <input class="form-control" formControlName="nextOfKin" />
        </div>
        <div class="col-lg-12">
          <label for="nextOfKinPhone"> Next of kin number </label>
          <input
            class="form-control"
            type="tel"
            formControlName="nextOfKinPhone"
          />
        </div>
        <div class="col-lg-12">
          <label for="maidenName">Maiden Name</label>
          <input
            class="form-control"
            type="text"
            formControlName="maidenName"
          />
        </div>
      </div>
    </form>
  </div>
</div>
