import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, EnterpriseAPIService, PaginatedList, ReqSuccessResponse } from '@console/api';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { IAccountManager } from '../interface/IAccountManager';
import { ClientUserModel } from '@console/user-managements';

const ACCOUNT_MANAGER_URL = 'api/v1/account_manager';

@Injectable()
export class AccountManagerService {

  constructor(
    private messagesService: MessagesService,
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {
  }

  public fetchAccountManagers(showLoader: boolean = false): Observable<ReqSuccessResponse<IAccountManager[]>> {

    const accountManagers$ = this.http.get<ReqSuccessResponse<IAccountManager[]>>(ACCOUNT_MANAGER_URL);

    const accountManagersObs$ = showLoader ?
      this.loadingService.showLoaderUntilCompleted(accountManagers$) :
      accountManagers$;

    return accountManagersObs$
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        )
      );
  }

  public saveAccountManager(dataToSubmit: Partial<IAccountManager>): Observable<ReqSuccessResponse<IAccountManager>> {

    return this.http.post<ReqSuccessResponse<IAccountManager>>(`${ACCOUNT_MANAGER_URL}/`, dataToSubmit)
      .pipe(
        // we handle error in the child component instance
        catchError(error => throwError(error)),
        map(response => {
          // We show the success message on the parent component instance and return the observable
          this.messagesService
            .open(
              MessagesEnum.success,
              `Successfully created account manager.`,
              { hideAll: true }
            );

          return response;
        })
      );
  }

  public deleteAccountManager(accountManagerId: number):
    Observable<ReqSuccessResponse<boolean>> {

    const { id } = this.messagesService.open(MessagesEnum.loading, 'Deleting account manager...', { hideAll: true });

    return this.http.delete<ReqSuccessResponse<boolean>>(`${ACCOUNT_MANAGER_URL}/${accountManagerId}`)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.messagesService.hide(id);
          this.handleError(error);
          return throwError(error);
        }),
        tap((response: ReqSuccessResponse<boolean>) => {
          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: 'Account manager deleted successfully.'
            });

          return response;
        })
      );
  }

  public toggleAccountManager(accountManagerId: number, isEnabled: boolean):
    Observable<ReqSuccessResponse<boolean>> {

    const message = `${isEnabled ? 'Disabling' : 'Enabling'} account manager.`;

    const { id } = this.messagesService.open(MessagesEnum.loading, message, { hideAll: true });

    const url = `${ACCOUNT_MANAGER_URL}/${isEnabled ? 'disable' : 'enable'}/${accountManagerId}`;

    return this.http.put<ReqSuccessResponse<boolean>>(url)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        }),
        map((response: ReqSuccessResponse<boolean>) => {
          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: `Successfully ${isEnabled ? 'disabled' : 'enabled'} account manager.`
            });

          return response;
        }),
        finalize(() => {
          this.messagesService.hide(id);
        })
      );
  }

  public fetchAccountManagerById(accountManagerId: number): Observable<ReqSuccessResponse<IAccountManager>> {
    return this.http.get<ReqSuccessResponse<IAccountManager>>(`${ACCOUNT_MANAGER_URL}/${accountManagerId}`);
  }

  public fetchAccountManagerUsers(searchQuery: object): Observable<PaginatedList<ClientUserModel>> {
    const url = ACCOUNT_MANAGER_URL + '/users';
    const users$ = this.http.get<PaginatedList<ClientUserModel>>(url, searchQuery);

    return this.loadingService.showLoaderUntilCompleted(users$)
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        )
      );
  }

  private handleError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);

    this.messagesService.open(MessagesEnum.danger, message);
  }
}
