import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { OrderTxnsModel } from 'projects/view/src/lib/orders/services/orders.model';
import { OrderTxnService } from 'projects/view/src/lib/orders/services/orders.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

export interface OpenOrderState {
  order?: OrderTxnsModel;
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: OpenOrderState = { loading: false };

@Injectable({ providedIn: 'root' })
export class OpenOrderStateService {
  state = new BehaviorSubject<OpenOrderState>(initialState);

  spinnerName = 'Open-Order';

  constructor(private order: OrderTxnService, private spinner: NgxSpinnerService) {}

  cancel(orderId: string) {
    this.loading(`Please wait, while we cancel the open orders`);
    this.order.cancelOrder(orderId).subscribe({
      next: this.onUserOpenOrderSLoaded(`Thanks, Order cancelling is in progress.`).bind(this),
      error: this.onUserOpenOrderSError.bind(this),
    });
  }

  requery(orderId: string) {
    this.loading(`Please wait, Order requerying is inprogress`);
    this.order.cancelOrder(orderId).subscribe({
      next: this.onUserOpenOrderSLoaded(`Order was successfully requeryed`).bind(this),
      error: this.onUserOpenOrderSError.bind(this),
    });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(message: string) {
    this.state.next({ ...this.state.getValue(), loading: true, message });
    this.spinner.show(this.spinnerName);
  }

  private onUserOpenOrderSLoaded(message: string) {
    return ({ data }: ReqSuccessResponse<OrderTxnsModel>) => {
      this.state.next({
        ...this.state.getValue(),
        loading: false,
        order: data,
        message: `${data.orderId} status is now ${data.status}`
      });
      setTimeout(() => {
        this.spinner.hide(this.spinnerName);
      }, 2000)
    }
  }

  private onUserOpenOrderSError(res: ChakaAPIError) {
    const error =  cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false,
      message: error
    });
    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2000)
  }
}
