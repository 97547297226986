import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { AuthRoleStateService } from '../../../../../authentication/src/lib/auth-permission';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { MerchantsModel } from '../merchants.interface';
import { MerchantStateService } from './merchant-state.service';

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['./merchant-details.component.scss'],
})
export class MerchantDetailsComponent implements OnInit, OnDestroy {
  selectedTab = 0;
  currentTabIndex = 0;

  constructor(
    public route: ActivatedRoute,
    public merchant: MerchantStateService,
    public console: ConsoleRouterService,
    public role: AuthRoleStateService
  ) { }

  updateMerchant(): void {
    this.merchant.toggleEditMode();
    this.selectedTab = 0;
  }

  goBack(): void {
    this.console.productNavigate(['merchant']);
  }

  deleteMerchant(merchant: MerchantsModel): void {
    this.merchant.delete(merchant.id);
  }

  retrieveMerchant(): void {
    this.route.params
      .pipe(map((params) => params.id))
      .pipe(first())
      .subscribe((id) => {
        if (id === undefined) {
          this.merchant.newMerchant();
        } else {
          this.merchant.retrieve(id);
        }
      });
  }

  setTab(index: number): void {
    this.currentTabIndex = index;
  }


  ngOnInit(): void {

    this.role.hasPermission$('MERCHANT_VIEW')
      .subscribe((authorized) => {
        if (!authorized) {
          this.console.productNavigate(['users']);
        }
        this.retrieveMerchant();
      });

  }

  ngOnDestroy(): void {
    this.merchant.reset();
  }
}
