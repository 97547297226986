import { Injectable } from '@angular/core';
import { PaginatedList, ReqFailureResponse } from '../../../../api/src/public-api';
import { TagModel } from './services/tags.model';
import {  TagService } from './services/tags.service';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

export interface InstrumentTagListState {
  loading: boolean;
  count?: number;
  tags: TagModel[];
  error?: string;
}

const initialState: InstrumentTagListState = {
  loading: false,
  tags: [],
};

@Injectable({ providedIn: 'root' })
export class InstrumentTagListStateService {
  state = new BehaviorSubject<InstrumentTagListState>(initialState);

  constructor(private instrumentSvc: TagService) { }

  find(query: object) {
    this.loading();
    this.instrumentSvc
      .getTags(query)
      .pipe(first())
      .subscribe({
        next: this.updateInstrumentTags.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateInstrumentTags(data: PaginatedList<TagModel>) {
    this.state.next({
      loading: false,
      tags: data.data,
      count: data.count,
    });
  }

  private onError(error: ReqFailureResponse) {
    this.state.next({
      ...this.state.getValue(),
      error: error.message,
      loading: false,
    });
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
  }
}
