<div class="row">
  <div class="col-12">
    <div class="row">
      <!-- Equity Details -->
    </div>
    <div class="row">
      <!-- Open Orders Details -->
    </div>
  </div>
</div>
