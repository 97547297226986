import { CscsReportStateService } from './state/cscs-report-state.service';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@console/shared';
import { CscsReportComponent } from './cscs-report.component';
import { CscsReportService } from './services/cscs-report.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CscsReportComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  providers: [CscsReportService, CscsReportStateService],
})
export class CscsReportModule {}
