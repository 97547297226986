import { AdvisoryPortfolioListStateService } from './state/advisory-portfolio-list-state.service';
import { AdvisoryPortfolioDetailStateService } from './state/advisory-portfolio-details-state.service';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@console/shared';
import { AdvisoryPortfolioService } from './services/advisory-portfolio.service';
import { CommonModule } from '@angular/common';
import { AdvisoryPortfolioListComponent } from './advisory-portfolio-list.component';
import { AdvisoryPortfolioDetailsComponent } from './components/advisory-portfolio-details/advisory-portfolio-details.component';
import { PortfolioHoldingDrawerComponent } from './components/portfolio-holding-drawer/portfolio-holding-drawer.component';

@NgModule({
  declarations: [
    AdvisoryPortfolioListComponent,
    AdvisoryPortfolioDetailsComponent,
    PortfolioHoldingDrawerComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  providers: [
    AdvisoryPortfolioService,
    AdvisoryPortfolioListStateService,
    AdvisoryPortfolioDetailStateService,
  ],
  exports: [AdvisoryPortfolioDetailsComponent],
})
export class AdvisoryPortfolioModule {}
