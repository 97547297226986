import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { cleanChakaAPIError } from '@console/api';
import { ConsoleRouterService } from '@console/shared';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { ClientUserModel } from '@console/user-managements';
import { finalize } from 'rxjs/operators';
import { IAccountManager } from '../../interface/IAccountManager';
import { AccountManagerService } from '../../service/account-manager.service';

@Component({
  selector: 'lib-account-manager-users',
  templateUrl: './account-manager-users.component.html',
  styleUrls: ['./account-manager-users.component.scss'],
  providers: [MessagesService],
})
export class AccountManagerUsersComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<ClientUserModel>();

  private filter: Record<string, any> = {};

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  displayedColumns: string[] = ['fullName', 'gender', 'phone', 'email'];

  count = 0;

  loading = false;

  accountManager: IAccountManager;

  constructor(
    private accountManagerService: AccountManagerService,
    private consoleRouter: ConsoleRouterService,
    private activatedRoute: ActivatedRoute,
    private messagesService: MessagesService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;

      this.filter.accountManagerId = id;

      this.fetchAccountManagerById(id);

      this.fetchAccountManagerUsers();
    });
  }

  fetchAccountManagerById(id: number): void {
    this.accountManagerService.fetchAccountManagerById(id).subscribe({
      next: (response) => {
        this.accountManager = response.data;
      },
      error: (error) => {
        const message = cleanChakaAPIError(error);

        this.messagesService.open(MessagesEnum.danger, message, {
          hideAll: true,
        });
      },
    });
  }

  fetchAccountManagerUsers(): void {
    this.loading = true;

    this.accountManagerService
      .fetchAccountManagerUsers(this.filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource<ClientUserModel>(
            response.data
          );
          this.count = response.count;
        },
      });
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.fetchAccountManagerUsers();
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  back(): void {
    this.consoleRouter.goBack();
  }

  ngOnDestroy(): void {
    this.messagesService.hideAll();
  }
}
