<ng-container *ngIf="drawer.opened">
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">View Log</span>
      </h3>
      <div class="card-toolbar">
        <button class="close" mat-button (click)="closeDrawer()">X</button>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <lib-messages></lib-messages>

      <div class="row" *ngIf="!!log else noData">
        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Name:</span>
              <strong>
                {{log?.userFullName || '_'}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Service:</span>
              <strong>
                {{log?.serviceName || '_'}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Method:</span>
              <strong>
                {{log?.methodName || '_'}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Parameters:</span>
              <strong>
                {{log?.parameters || '_'}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Execution Time:</span>
              <strong>
                {{formattedExecutionTime}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Execution Duration:</span>
              <strong>
                {{log?.executionDuration || '_'}}
              </strong>
            </div>

          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Client Ip Address:</span>
              <strong>
                {{log?.clientIpAddress || '_'}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Client Name:</span>
              <strong>
                {{log?.clientName || '_'}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Browser Info:</span>
              <strong>
                {{log?.browserInfo || '_'}}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Exception:</span>
              <strong>

                <!-- {{log?.exception || '_' | prettyjson: [true, 3] }} -->
                <pre [innerHTML]="log?.exception | prettyjson: [true, 3]"></pre>

              </strong>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Merchant:</span>
              <strong>
                {{resolveMerchantName(log?.merchantId) || '_'}}
              </strong>
            </div>
          </div>
        </div>

      </div>

      <ng-template #noData *ngIf="!loading">
        <lib-no-data></lib-no-data>
      </ng-template>

    </div>
    <!--end::Body-->
  </div>


</ng-container>
