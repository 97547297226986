<div *ngIf="learn" class="example-1 card">
	<div [ngStyle]="{'background': style()}" class="wrapper">
		<div class="date">
			<span *ngFor="let datePart of dateParts" [class]="datePart.type">
				{{ datePart.value }}
			</span>
		</div>
		<div class="data">
			<div class="content">
				<span class="author">{{ learn.createdBy }}</span>
				<h1 class="title">
					<a (click)="btnClick.emit(learn)">{{ learn.title }}</a>
				</h1>
				<p class="text">
					{{ learn.summary ? learn.summary.slice(0, 50) : "" }}
				</p>
				<label for="show-menu" class="menu-button"><span></span></label>
			</div>
		</div>
	</div>
</div>
