import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';

import { MatSidenav } from '@angular/material/sidenav';
import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MerchantsModel } from '../../../merchants/merchants.interface';
import { AuditLogService } from '../../service/audit-log.service';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { IAuditLog } from '../../interface/IAuditLog';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'log-drawer',
  templateUrl: './log-drawer.component.html',
  styleUrls: ['./log-drawer.component.scss'],
  providers: [MessagesService]
})

export class LogDrawerComponent implements OnInit, OnChanges {
  @Input() drawer: MatSidenav;

  @Input() merchantList: MerchantsModel[];
  @Input() activeLogId: number = null;

  log: IAuditLog;

  loading = false;

  formattedExecutionTime = '';

  constructor(
    private messagesService: MessagesService,
    private auditLogService: AuditLogService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.drawer.openedChange
      .subscribe(isOpened => {
        if (isOpened) {
          !!this.activeLogId && this.getLogById();
        } else {
          this.activeLogId = null;
          this.log = null;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.firstChange) {
      return;
    }

    if (!changes?.activeLogId) {
      return;
    }

    this.activeLogId = changes.activeLogId.currentValue;
  }

  resolveMerchantName(merchantId: number): string {
    try {
      return this.merchantList.find(({ id }) => id === merchantId).name;
    } catch (error) {
      return '';
    }
  }

  getLogById(): any {
    this.loading = true;

    const { id } = this.messagesService.open(MessagesEnum.loading, 'Fetching log...', { hideAll: true });

    this.auditLogService.fetchLogById(this.activeLogId)
      .subscribe({
        next: (response) => {
          this.log = response.data;

          this.formatExecutionTime(this.log.executionTime);

          this.messagesService.hide(id);

          this.loading = false;
        },
        error: (error: ChakaAPIError) => {
          const message = cleanChakaAPIError(error);

          this.messagesService.update(
            { id, message, type: MessagesEnum.danger },
            { hideAll: true }
          );

          this.loading = false;
        }
      })
  }

  formatExecutionTime(executionTime): void {
    if (!executionTime) {
      this.formattedExecutionTime = '_';
      return;
    }

    this.formattedExecutionTime = `${this.datePipe.transform(this.log.executionTime, 'medium')}\u00A0\u00A0\u00A0\u00A0---->\u00A0\u00A0\u00A0\u00A0${moment(executionTime).fromNow()}`;
  }

  closeDrawer(): void {
    this.messagesService.hideAll();

    this.drawer.close();
  }


}
