import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@console/shared';
import { PaginationModule } from 'projects/_shared/Pagination/pagination.module';
import { TransactionsTableComponent } from './transactions-table.component';


@NgModule({
  declarations: [TransactionsTableComponent],
  imports: [
    CommonModule,
    PaginationModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [TransactionsTableComponent, RouterModule],
})
export class TransactionsTableModule { }
