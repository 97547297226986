import { Injectable } from '@angular/core';
import {
  PaginatedList, ReqSuccessResponse
} from '../../../../../api/src/lib/api.interface';
import { EnterpriseAPIService } from '../../../../../api/src/lib/enterprise.service';
import { Observable } from 'rxjs';
import { IFee } from '../fees.interface';
import { LoadingService } from '@console/shared/components/loading/loading.service';

const API_FEES = 'api/v1/fees';

@Injectable()
export class FeeService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) { }

  getFeeById(merchantId: number): Observable<ReqSuccessResponse<IFee>> {
    const feeSetting$ = this.http.get<ReqSuccessResponse<IFee>>(`${API_FEES}/merchant/${merchantId}`);

    return this.loadingService.showLoaderUntilCompleted(feeSetting$);
  }

  saveFee(dataToSubmit): Observable<ReqSuccessResponse<IFee>> {
    if (dataToSubmit.hasOwnProperty('id') && dataToSubmit.id) {
      return this.http.put<ReqSuccessResponse<IFee>>(`${API_FEES}`, dataToSubmit);
    }

    return this.http.post<ReqSuccessResponse<IFee>>(`${API_FEES}`, dataToSubmit);
  }

  deleteFeeBand(bandId: number): Observable<ReqSuccessResponse<IFee>> {
    return this.http.delete<ReqSuccessResponse<IFee>>(`${API_FEES}/feeband/${bandId}`);
  }
}
