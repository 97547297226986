import { Injectable, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  private logoutDiv = new Subject<boolean>();
  logoutDiv$ = this.logoutDiv.asObservable();

  private mobileSidebar = new Subject<boolean>();
  mobileSidebar$ = this.mobileSidebar.asObservable();

  private subHeader = new Subject<boolean>();
  public subHeader$ = this.subHeader.asObservable();

  hideLogoutDiv(): void {
    this.logoutDiv.next(false);
  }

  showLogoutDiv(): void {
    this.logoutDiv.next(true);
  }

  hideMobileSidebar(): void {
    this.mobileSidebar.next(false);
  }

  showMobileSidebar(): void {
    this.mobileSidebar.next(true);
  }

  showSubHeader(): void {
    this.subHeader.next(true);
  }

  hideSubHeader(): void {
    this.subHeader.next(false);
  }
}
