import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ConsoleRouterService } from '../../../../../../projects/shared/src/public-api';
import { BlogListStateService } from './blog-list.state';
import { BlogEditorStateService } from './blog-edit/blog-edit.state';
import { PageEvent } from '../../../../../shared/src/lib/interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogComponent implements OnInit {

  childRoute = false;

  filter: Record<string, any> = {
    pageNumber: 0,
    pageSize: 10,
  };

  editorConfig: AngularEditorConfig = {
      editable: true,
        spellcheck: true,
        height: 'auto',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
          {class: 'arial', name: 'Arial'},
          {class: 'times-new-roman', name: 'Times New Roman'},
          {class: 'calibri', name: 'Calibri'},
          {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['bold', 'italic'],
        ['fontSize']
      ]
  };

  constructor(
    private router: ConsoleRouterService,
    public blogList: BlogListStateService,
    private blogState:BlogEditorStateService
  ) { }

  search(query: string) {
    this.filter.q = query;
    this.blogList.find(this.filter);
  }

  paginate($event: PageEvent) {
    this.blogList.find({...this.filter, ...$event });
  }

  blogForm = new FormGroup({
    id: new FormControl(''),
    title: new FormControl(''),
    category: new FormControl('',Validators.required),
    pageUrl: new FormControl('',Validators.required),
    imageUrl: new FormControl('',Validators.required),
    summary: new FormControl('',Validators.required),
    content: new FormControl('',Validators.required)
  })

  parentNavigate() {
    this.childRoute = false;
    this.blogList.find(this.filter);
  }

  navigate(id?: string) {
    if (id) {
      this.router.productNavigate(['blog', id]);
      return;
    }
    this.router.productNavigate(['blog']);
  }

  ngOnInit(): void {
    this.blogList.find(this.filter);
  }

  create(){
    this.childRoute = true;
  }

  view(){
    this.childRoute = false;
    this.blogList.find(this.filter);
  }

  save(){
    this.blogState.create(this.blogForm.value);
    setTimeout(() => {
      this.view();
    }, 3000);
    }

  ngOnDestroy() {
    this.blogList.reset();
  }

}
