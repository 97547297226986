import { Injectable } from "@angular/core";
import { EnterpriseAuthService, PaginatedList, ReqSuccessResponse } from '../../../../../api/src/public-api';
import { LiteRoleDetail, Role, TreeRole, UserRoleUpdate } from "./roles.interface";

export type RolePaginationQuery = {
	pageNumber?: number;
	pageSize?: number;
};

export interface AttachRolePermission {
	id?: number;
	roleId: number;
	permissionId: number;
}

export interface DeleteRolePermission {
	roleId: number;
	permissionId: number;
}

const API_ROLES = "api/v1/roles";

@Injectable({
	providedIn: "root",
})
export class RoleService {
	constructor(
		private http: EnterpriseAuthService,
	) {}

	roles(query?: Partial<RolePaginationQuery>) {
		return this.http.get<PaginatedList<Role[]>>(
			API_ROLES,
			query,
		);
	}

	roleById(id: number) {
		return this.http.get<ReqSuccessResponse<TreeRole>>(
			`api/v1/roles/tree/${id}`,
			null,
		);
	}

	create(role: Partial<TreeRole>) {
		return this.http.post<ReqSuccessResponse<TreeRole>>(
			`api/v1/roles/tree`,
			role,
			null,
		);
	}

	update(role: Partial<TreeRole>) {
		return this.http.put<ReqSuccessResponse<TreeRole>>(
			`api/v1/roles/tree`,
			role,
			null,
		);
	}

	attachUserToRole(update: UserRoleUpdate) {
		return this.http.put<ReqSuccessResponse<string>>(
			`api/v1/roles/attach`,
			update,
			null,
		);
	}

}
