<section-search-header
	[section]="'News'"
	[actionText]="'Create News'"
	(actionBtn)="navigate()"
	(onSearch)="search($event)"
	[count]="(learnList.state | async).count"
	[loading]="(learnList.state | async).loading"
></section-search-header>

<div>
  <ngx-spinner bdColor="#4e73df66" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{(learnList.state|async)?.message}}</p>
  </ngx-spinner>
	<router-outlet
		(activate)="childRoute = true"
		(deactivate)="parentNavigate()"
	></router-outlet>
</div>

<div class="row container-fluid chaka-route-content" *ngIf="!childRoute">
	<div class="col-12">
		<kt-learn-content-grid
			(learnClick)="navigate($event.id)"
			[learns]="(learnList.state | async).collections"
    ></kt-learn-content-grid>
    <lib-pagination (paginate)="paginate($event)" [count]="(learnList.state | async).count"></lib-pagination>

	</div>
</div>
