/**
 * User Permformance details from DW
 */
export class UserPerformanceModel {
  /**
   * DW or IW  unique ID
   */
  accountID: string;
  /**
   *  DW or IW  account number
   */
  accountNo: string;
  /**
   * start date for caluclation of the performance
   */
  startDate: string;
  /**
   * end date used for calculation of the performance
   */
  endDate: string;
  /**
   * last date, it was calculated
   */
  lastUpdated: string;
  /**
   * breakdown for all instruments performance over the period of startDate to endDate
   */
  performance: UserInstrumentPerformance[];
}

/**
 * Instrument performance with relative to the user portfolio
 */
export class UserInstrumentPerformance {
  /**
   * cash profit or loss for the trading day for the user
   */
  realizedDayPL: number;
  unrealizedDayPL: number;
  cumRealizedPL: number;
  date: string;
  equity: number;
  cash: number;
  deposits: number;
  withdrawals: number;
  fees: number;
}
