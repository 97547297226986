import { Component, Input, OnInit } from '@angular/core';
import { CscsDetails } from '../components/user-css-form/cssForm.interface';
import { CSCSStateService } from './cscs.state';

@Component({
  selector: 'app-cscs-data-form',
  templateUrl: './cscs-data-form.component.html',
  styleUrls: ['./cscs-data-form.component.scss'],
})
export class CscsDataFormComponent implements OnInit {
  @Input() userId: string;

  constructor(public cscs: CSCSStateService) {}

  submit(form: CscsDetails): void {
    form.userId = this.userId;
    this.cscs.update(form as any);
  }

  submitInfowareCscs(form: CscsDetails): void {
    form.userId = this.userId;
    this.cscs.updateInfowareCscs(form as any);
  }

  ngOnInit(): void {
    this.cscs.retrieve(this.userId);
  }
}
