import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, EnterpriseAPIService, PaginatedList, ReqSuccessResponse } from '@console/api';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Observable, throwError } from 'rxjs';
import { IReferralPolicy } from '../interface/IReferralPolicy';
import { catchError, map, filter, tap, finalize } from 'rxjs/operators';
import { LoadingService } from '@console/shared/components/loading/loading.service';

const REFERRAL_POLICY_URL = 'api/v1/referral_policies';
@Injectable()
export class ReferralService {
  private messageIds = {
    retrieve: 0,
    delete: 0
  };

  constructor(
    private messagesService: MessagesService,
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {
  }

  public fetchReferralPolicies(searchQuery: object): Observable<PaginatedList<IReferralPolicy>> {

    const referralPolicies$ = this.http.get<PaginatedList<IReferralPolicy>>(REFERRAL_POLICY_URL, searchQuery);

    return this.loadingService
      .showLoaderUntilCompleted(referralPolicies$)
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        ));
  }

  public saveReferralPolicy(accountInfo: Partial<IReferralPolicy>): Observable<ReqSuccessResponse<IReferralPolicy>> {
    const isUpdate = !!accountInfo.id;

    const saveAccount$ = isUpdate ?
      this.http.put<ReqSuccessResponse<IReferralPolicy>>(`${REFERRAL_POLICY_URL}/`, accountInfo) :
      this.http.post<ReqSuccessResponse<IReferralPolicy>>(`${REFERRAL_POLICY_URL}/`, accountInfo);

    return saveAccount$
      .pipe(
        // we handle error in the child component instance
        catchError(error => throwError(error)),
        map(response => {
          // We show the success message on the parent component instance and return the observable
          this.messagesService
            .open(
              MessagesEnum.success,
              `Successfully ${isUpdate ? 'updated' : 'added'} referral policy.`,
              { hideAll: true }
            );

          return response;
        })
      );
  }

  public deleteReferralPolicy(accountId: number): Observable<ReqSuccessResponse<boolean>> {
    !!this.messageIds.delete && this.messagesService.hide(this.messageIds.delete);

    const { id } = this.messagesService.open(MessagesEnum.loading, 'Deleting referral policy...');

    const deleteAccount$ = this.http.delete<ReqSuccessResponse<boolean>>(`${REFERRAL_POLICY_URL}/${accountId}`);

    return deleteAccount$
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        }),
        map((response: ReqSuccessResponse<boolean>) => {
          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: 'Referral policy deleted successfully.'
            });

          return response;
        }),
        finalize(() => this.messagesService.hide(id))
      );
  }

  private handleError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);

    this.messagesService.open(MessagesEnum.danger, message);
  }
}
