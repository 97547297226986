import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersTableComponent } from './orders-table.component';
import { PaginationModule } from '../../../../../_shared/Pagination/pagination.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@console/shared';
@NgModule({
  imports: [
    CommonModule,
    PaginationModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [OrdersTableComponent],
  declarations: [OrdersTableComponent]
})
export class OrdersTableModule { }
