import { Injectable } from '@angular/core';
import { ReqSuccessResponse, ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Bank } from './kyc-util.interface';
import { KYCUtilService } from './kyc-util.service';

export interface BankState {
  banks: Bank[];
  loading: boolean;
  error?: string;
}

const initalState: BankState = {
  loading: false,
  banks: []
};

@Injectable({ providedIn: 'root' })
export class BankStateService {

  state = new BehaviorSubject<BankState>(initalState);

  constructor(private util: KYCUtilService) {}

  list() {
    this.loading();
    this.util.banks().subscribe({
      next: this.onBankLoaded.bind(this),
      error: this.onBankError.bind(this),
    });
  }

  reset() {
    this.state.next(initalState);
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({ ...this.state.getValue(), loading: true });
  }

  private onBankLoaded({ data }: ReqSuccessResponse<Bank[]>) {
    this.state.next({
      banks: data,
      loading: false,
    });
  }

  private onBankError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
    });
  }
}
