import { Component, Input, OnInit } from '@angular/core';
import { ChartConfig, ColorScheme } from '../config.interface';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  option: any;

  @Input() config: ChartConfig<any>;
  @Input() isVertical = true;

  constructor() { }

  createChart() {
    const option: any = {
      color: [ColorScheme.green, ColorScheme.pink, ColorScheme.blue],
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
      },
      grid: {
        left: '0%',
        top: '0%',
      },
      legend: {
        bottom: '0%',
        left: '0%',
        width: '500px',
        orient: 'horizontal',
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
      },
      toolbox: {
          show: false,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
              mark: {show: true},
              dataView: {show: true, readOnly: false},
              magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
              restore: {show: true},
              saveAsImage: {show: true}
          }
      },

      series: [
          {
              name: 'Forest',
              type: 'bar',
              barGap: 0,
              // label: labelOption,
              data: [320, 332, 301, 334, 390, 10]
          },
          {
              name: 'Steppe',
              type: 'bar',
              // label: labelOption,
              data: [220, 182, 191, 234, 290, 10]
          },
          {
              name: 'Desert',
              type: 'bar',
              // label: labelOption,
              data: [150, 232, 201, 154, 190, 10]
          }
      ]
  };

  option.xAxis = [
    {
      show: false,
        type: 'category',
        axisTick: {show: false},
        data: ['2012', '2013', '2014', '2015', '2016']
    }
];

option.yAxis = [
    {
      show: false,
        type: 'value'
    }
];

  return option;
  }

  ngOnInit() {
    this.option = this.createChart();
  }

}
