import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NipPaymentRequest } from '../../providers/payment-interface';
import { NipPaymentStateService } from '../../states/nip.state';
import { formMethods } from '../../../../../../../shared/src/lib/utils/form-control-methods';
import { shouldBeGreaterThanZero } from './../../../../../../../shared/src/lib/validators/shouldBeGreaterThanZero';
import { Currency } from '@console/shared/_enums/currency.enum';
import { ConsoleRouterService } from '@console/shared';
import { PRODUCT_TYPE_ENUM } from '@console/shared/_enums';

@Component({
  selector: 'app-fund-reversal',
  templateUrl: './fund-reversal.component.html',
  styleUrls: ['./fund-reversal.component.scss'],
})
export class FundReversalComponent implements OnInit {
  @Input() userId: string;

  fundForm: FormGroup;

  public formMethods;

  currencyList = [
    {
      description: '--Select--',
      value: '',
    },
    {
      description: 'Naira',
      value: Currency.NGN,
    },
    {
      description: 'Dollar',
      value: Currency.USD,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private nip: NipPaymentStateService,
    private router: ConsoleRouterService
  ) {}

  createForm(): void {
    this.fundForm = this.fb.group({
      amount: [0, [Validators.required, shouldBeGreaterThanZero]],
      description: ['', Validators.required],
      currency: ['', Validators.required],
    });

    this.formMethods = formMethods(this.fundForm);

    if (this.router.product === PRODUCT_TYPE_ENUM.advisory) {
      this.currencyList = [
        {
          description: 'Dollar',
          value: Currency.USD,
        },
      ];

      this.fundForm.get('currency').setValue(Currency.USD);
      this.fundForm.get('currency').updateValueAndValidity();

      return;
    }
  }

  createNipReversal(
    form: Omit<NipPaymentRequest, 'transactionReference' | 'userId'>
  ): void {
    if (!this.fundForm.valid) {
      this.fundForm.markAllAsTouched();
      return;
    }

    this.nip.reverseViaNIP({
      ...form,
      userId: this.userId,
    });
  }

  onEnterReference($event: MouseEvent): void {
    if (this.fundForm.valid) {
      $event.stopPropagation();
      return;
    }

    setTimeout(() => {
      this.fundForm.markAllAsTouched();
    }, 1000);
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.nip.resetState();
  }
}
