import { InstrumentModel } from '../../models/instrument.interface';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { formatToCurrency } from '@console/shared/utils/formatter';

@Component({
  selector: 'app-market-information',
  templateUrl: './market-information.component.html',
  styleUrls: ['./market-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketInformationComponent implements OnInit {
  @Input() instrument: InstrumentModel;

  constructor() { }

  get dayLowRange() {
    const price = this.instrument?.fundamentalDataModel?.lowPrice;
    const currency = this.instrument.currency;
    if (!price) return '-';
    return formatToCurrency(currency, price || 0);
  }

  get dayHighRange() {
    const price = this.instrument?.fundamentalDataModel?.highPrice;
    const currency = this.instrument.currency;
    if (!price) return '-';
    return formatToCurrency(currency, price || 0);
  }

  get yearLowRange() {
    const price = this.instrument?.fundamentalDataModel?.fiftyTwoWeekLowPrice;
    const currency = this.instrument?.currency;
    if (!price) return '-';
    return formatToCurrency(currency, price || 0);
  }

  get yearHighRange() {
    const price = this.instrument?.fundamentalDataModel?.fiftyTwoWeekHighPrice;
    const currency = this.instrument?.currency;
    if (!price) return '-';
    return formatToCurrency(currency, price || 0);
  }

  get close() {
    const price = this.instrument?.close;
    const currency = this.instrument?.currency;
    if (!price) return '-';
    return formatToCurrency(currency, price || 0);
  }

  get open() {
    const price = this.instrument?.fundamentalDataModel?.openPrice;
    const currency = this.instrument?.currency;
    if (!price) return '-';
    return formatToCurrency(currency, price || 0);
  }

  ngOnInit() { }
}
