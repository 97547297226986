<div class="container-fluid container-fluid-margin ">
  <div class="col-md-6 mx-auto">
    <div class="card merchant-card">
      <div class="card-header bg-white">
        <span class="card-header-title-text">New Customer </span>
        <span class="float-right">
          <span>
            <a
              [routerLink]="['../users']"
              class="btn btn-sm btn-transparent border mr-3">
              <i class="fas fa-arrow-left"></i> Back</a>
          </span>
        </span>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <form
              [formGroup]="registerForm"
              autocomplete="off"
              (ngSubmit)="signUp()">
              <input autocomplete="false" name="hidden" type="text" style="display:none;">
              <div class="form-group">
                <label for="email"> Email Address: <span class="text-danger" *ngIf="formMethods.isRequired('password')">*</span></label>
                <input
                  class="form-control"
                  formControlName="email"
                  type="email"
                  autocomplete="off" />
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('email')">
                  <div *ngIf="formMethods.getControl('email').hasError('required')">
                    Email is required.
                  </div>
                  <div *ngIf="formMethods.getControl('email').hasError('email')">
                    Please provide a valid email address.
                  </div>
                </small>
              </div>

              <div class="form-group">
                <label for="password">Password: <span class="text-danger" *ngIf="formMethods.isRequired('password')">*</span></label>
                <input
                  class="form-control"
                  formControlName="password"
                  type="password"
                  placeholder="Password"
                  autocomplete="off" />
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('password')">
                  <div *ngIf="formMethods.getControl('password').hasError('required')">
                    Password is required.
                  </div>
                  <div *ngIf="formMethods.getControl('password').hasError('minlength')">
                    Password must be at least {{formMethods.getControl('password')?.errors?.minlength?.requiredLength}} characters.
                  </div>
                </small>
              </div>

              <div class="form-group form-row">
                <label class="col-form-label mr-2">Is the user an admin?</label>
                <div>
                  <span class="switch">
                    <label>
                      <input
                        type="checkbox"
                        formControlName="isAdmin"
                        (ngModelChange)="onIsAdmin($event)">
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>

              <div class="form-group" *ngIf="formMethods.value('isAdmin')">
                <label for="password">Phone number: <span class="text-danger" *ngIf="formMethods.isRequired('mobileNumber')">*</span></label>
                <div class="input-group">
                  <div class="input-group-prepend">

                    <button
                      class="btn btn-outline-secondary"
                      [ngClass]="formMethods.touchedAndInvalid('mobileNumber') ? 'has-error-border':''"
                      type="button"
                      id="number-button"
                      disabled><strong class="mr-2">+234</strong> <i class="fas fa-angle-down fa-sm"></i></button>
                  </div>
                  <input
                    class="form-control no-left-border"
                    formControlName="mobileNumber"
                    placeholder="Phone number"
                    [appCleanPhoneNumber]="prefix"
                    aria-describedby="number-button">
                </div>

                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('mobileNumber')">
                  <div *ngIf="formMethods.getControl('mobileNumber').hasError('required')">
                    Phone number is required.
                  </div>
                  <div *ngIf="formMethods.getControl('mobileNumber').hasError('pattern')">
                    Please provide a valid phone number.
                  </div>
                </small>
              </div>

              <div class="form-group">
                <button
                  type="submit"
                  class="btn btn-block btn-primary">
                  Create
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
