import { LoadingService } from '@console/shared/components/loading/loading.service';
import { Injectable } from '@angular/core';
import {
  APIResponse,
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { AppSetting } from './settings.interface';

@Injectable()
export class SettingService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  find(searchParams?) {
    const settings$ = this.http.get<PaginatedList<AppSetting>>(
      `/api/v1/settings`,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(settings$);
  }

  retrieve(id: number) {
    const setting$ = this.http.get<ReqSuccessResponse<AppSetting>>(
      `/api/v1/settings/${id}`
    );

    return this.loadingService.showLoaderUntilCompleted(setting$);
  }

  update(content: Partial<AppSetting>) {
    const setting$ = this.http.put<ReqSuccessResponse<AppSetting>>(
      `/api/v1/settings`,
      content
    );

    return this.loadingService.showLoaderUntilCompleted(setting$);
  }

  create(content: Partial<AppSetting>) {
    const setting$ = this.http.post<ReqSuccessResponse<AppSetting>>(
      `/api/v1/settings`,
      content
    );

    return this.loadingService.showLoaderUntilCompleted(setting$);
  }

  remove(id: number) {
    return this.http.delete<ReqSuccessResponse<AppSetting>>(
      `/api/v1/settings/${id}`
    );
  }

  groups() {
    return this.http.get<PaginatedList<AppSetting>>(`/api/v1/settings/groups`);
  }
}
