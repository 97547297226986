<!-- <section-search-header *ngIf="childRoute" [section]="'View Collections'" (click)="view()" [actionText]="'View Collection'" (actionBtn)="navigate()"
  (onSearch)="search($event)" [count]="(collectionList.state | async).count"
  [loading]="(collectionList.state | async).loading"></section-search-header> -->

<section-search-header
  *ngIf="!childRoute"
  [section]="'Collections'"
  [actionText]="'New Collection'"
  (actionBtn)="navigate()"
  (onSearch)="search($event)"
  [count]="(collectionList.state | async).count"
  [loading]="(collectionList.state | async).loading"></section-search-header>


<ngx-spinner bdColor="#4e73df66" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{(collectionList.state|async)?.message}}</p>
</ngx-spinner>


<!-- <div>
  <router-outlet (activate)="childRoute = true" (deactivate)="parentNavigate()"></router-outlet>
</div> -->

<div class="row container-fluid chaka-route-content" *ngIf="!childRoute">
  <div class="col-12">
    <kt-collections-grid (collectionClick)="navigate($event.id)"
      [collections]="(collectionList.state | async).collections"></kt-collections-grid>
    <lib-pagination (paginate)="paginate($event)" [count]="(collectionList.state | async).count"></lib-pagination>
  </div>
</div>

<!-- Create collection -->
<div *ngIf="childRoute" class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card">
    <div class="card-body">
      <span class="float-right">
        <span>
          <a (click)="view()" class="btn btn-md  btn-transparent border mr-3"><i class="fas fa-arrow-left"></i>
            Back</a>
        </span>
        <span>
          <a (click)="save()" class="btn btn-md  btn-success border mr-3">Save</a>
        </span>
      </span>

      <form [formGroup]="collectionForm">
        <div class="row border-bottom py-4 mt-5">
          <div class="col-md-6">
            <div class="form-group">
              <label>Title <small class="text-danger">*</small></label>
              <input type="text" class="form-control" formControlName="title">
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('title')">
                <div *ngIf="formMethods.getControl('title').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Description <small class="text-danger">*</small></label>
              <input type="textarea" class="form-control" formControlName="description">
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('description')">
                <div *ngIf="formMethods.getControl('description').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Image <small class="text-danger">*</small></label>
              <input
                type="file"
                class="form-control"
                formControlName="image"
                accept="image/*"
                #imagePath
                (change)="preview(imagePath.files)">
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('image')">
                <div *ngIf="formMethods.getControl('image').hasError('required')">
                  Required input
                </div>
                <div *ngIf="formMethods.getControl('image').hasError('invalidImage')">
                  Please provide a valid image
                </div>
              </small>
            </div>

            <div
              class="image-preview"
              *ngIf="imgURL && this.collectionForm.get('image').valid">
              <img [src]="imgURL | safe" [alt]="collectionForm?.value?.title">
            </div>
          </div>


          <div class="col-md-6">
            <div class="form-group">
              <label>Symbols <small class="text-danger">*</small> <small>seperated by comma (,)</small></label>
              <input
                (change)="onInstrumentAdded($event)"
                type="text"
                class="form-control"
                formControlName="instruments" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('instruments')">
                <div *ngIf="formMethods.getControl('instruments').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>
        </div>

        <kt-settings-instrument-list [instruments]="(symbols.state | async).Instruments"></kt-settings-instrument-list>

      </form>
    </div>
  </div>

</div>
