import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { CrudState } from '@console/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, shareReplay, take } from 'rxjs/operators';
import { ClientUserModel } from '../../models/user.model';
import { UserKYCService } from '../../services/user-kyc.service';
import { UserService } from '../../services/user.service';
import { FileUpload } from '../../services/interface';
import { NotificationService } from '@console/shared/services/notification-service';

export interface UserKycEditorState {
  kyc?: ClientUserModel;
  loading: boolean;
  error?: string;
  status: CrudState;
}

const initalState: UserKycEditorState = {
  loading: false,
  status: CrudState.Initial,
};
@Injectable({ providedIn: 'root' })
export class UserKycStateService {
  state = new BehaviorSubject<UserKycEditorState>(initalState);

  private notificationId;

  constructor(
    private svc: UserService,
    private kyc: UserKYCService,
    private notificationService: NotificationService
  ) {}

  retrieve(UserKycId: string): void {
    this.loading(CrudState.Retrieve);
    this.svc.getClientUserProfile(UserKycId)
      .pipe(
        shareReplay()
      )
      .subscribe({
        next: this.onUserKycLoaded.bind(this)(CrudState.Retrieved),
        error: this.onUserKycError.bind(this)
      });
  }

  update(UserKyc: Partial<ClientUserModel>): void {
    this.loading(CrudState.Update);
    this.kyc.updateUserKyc(this.cleanMaskFields(UserKyc)).subscribe({
      next: this.onUserKycLoaded.bind(this)(CrudState.Updated),
      error: this.onUserKycError.bind(this),
    });
  }

  uploadFile(file: FileUpload): void {
    this.loading(CrudState.Update);
    this.kyc
      .uploadDocument(file)
      .pipe(first())
      .subscribe(() => {
        this.retrieve(file.userId);
      }, this.onUserKycError.bind(this));
  }

  disable(UserKyc: ClientUserModel): void {
    this.loading(CrudState.Update);
    this.svc.disableUser({ userId: UserKyc.id as any }).subscribe({
      next: this.onUserKycLoaded.bind(this)(CrudState.Updated),
      error: this.onUserKycError.bind(this),
    });
  }

  delete(UserKycId: string): void {
    this.loading(CrudState.Retrieve);
    this.svc.deleteClientUser(UserKycId).subscribe({
      next: this.onUserKycDeleted.bind(this),
      error: this.onUserKycError.bind(this),
    });
  }

  resetState(): void {
    this.state.next(initalState);
  }

  resetError(): void {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
      status: CrudState.Initial,
    });
  }

  get loading$(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(status: CrudState): void {
    this.state.next({
      ...this.state.getValue(),
      status,
      loading: true
    });
    // this.spinner.show(this.spinnerName);
  }

  private onUserKycLoaded(finalStatus: CrudState): ({ data }: ReqSuccessResponse<ClientUserModel>) => void {
    return ({ data }: ReqSuccessResponse<ClientUserModel>) => {
      const { kyc } = this.state.getValue();
      this.state.next({
        kyc: { ...kyc, ...data },
        loading: false,
        status: finalStatus
      });

      let notificationMessage = '';
      switch (finalStatus) {
        case CrudState.Retrieved:
          notificationMessage = 'User information retrieved successfully.';
          break;

        case CrudState.Updated:
          notificationMessage = 'User information was successfully updated.';
          break;

        default:
          break;
      }

      this.notificationId && this.notificationService.remove(this.notificationId);

      this.notificationId = this.notificationService.success(notificationMessage);

    };
  }

  private onUserKycDeleted({ data }: ReqSuccessResponse<boolean>): void {
    if (!data) { return; }
    this.state.next({
      kyc: undefined,
      loading: false,
      status: CrudState.Deleted,
    });

    this.notificationId && this.notificationService.remove(this.notificationId);

    this.notificationId = this.notificationService.success('User kyc information was successfully deleted');
  }

  private onUserKycError(res: ChakaAPIError): void {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error,
      status: CrudState.Error,
    });

    this.notificationId && this.notificationService.remove(this.notificationId);

    this.notificationService.error(error);
  }

  // private hideSpinner() {
  //   setTimeout(() => {
  //     this.spinner.hide(this.spinnerName);
  //   }, 3000);
  // }

  private cleanMaskFields(profile: Partial<ClientUserModel>) {
    const cleaned: Partial<ClientUserModel> = {};
    if (profile.dob?.toLowerCase().includes('NaN'.toLowerCase())) {
      delete profile.dob;
    }
    Object.keys(profile).forEach((k) => {
      if (!`${(profile as any)[k]}`?.includes('*')) {
        (cleaned as any)[k] = (profile as any)[k];
      }
    });
    return cleaned;
  }


}
