import { Injectable } from '@angular/core';
import { ReqSuccessResponse, ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { BuyInstrumentModel, PreOrder } from 'projects/view/src/lib/orders/services/orders.model';
import { OrderTxnService } from 'projects/view/src/lib/orders/services/orders.service';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';


export interface PreOrderState {
  loading: boolean;
  order?: any;//PreOrder;
  error?: string;
  message?: string;
}

const initialState: PreOrderState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class PreOrderStateService {
  state = new BehaviorSubject<PreOrderState>(initialState);

  spinnerName = 'preOrderSpinner';

  constructor(
    private orderSvc: OrderTxnService,
    private spinner: NgxSpinnerService
  ) {}

  placePreOrder(query: BuyInstrumentModel) {
    this.loading();
    this.orderSvc
      .preOrder(query)
      .pipe(first())
      .subscribe({
        next: this.updateOrders.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateOrders({ data }: ReqSuccessResponse<PreOrder>) {
    this.state.next({
      loading: false,
      order: data,
      message: 'Preordering Successful',
    });
    this.spinner.hide(this.spinnerName);
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
      message: error,
    });
    this.spinner.hide(this.spinnerName);
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show(this.spinnerName);
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Pre-ordering your request for transaction fees',
    });
  }
}
