import { Injectable } from '@angular/core';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { Observable } from 'rxjs';
import { EnterpriseAPIService, ReqSuccessResponse, PaginatedList } from '../../../../../api/src/public-api';
import { ExchangesModel } from './exchanges.model';



const API_EXCHANGES = 'api/v1/exchanges';

@Injectable()
export class ExchangesService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) { }

  getExchanges(searchParams?) {
    const exchanges$ = this.http.get<PaginatedList<ExchangesModel>>(
      API_EXCHANGES,
      searchParams
    );

    return this.loadingService
      .showLoaderUntilCompleted(exchanges$);
  }

  getUserExchanges(searchParams?) {
    return this.http.get<PaginatedList<ExchangesModel>>(
      API_EXCHANGES,
      searchParams
    );
  }

  getExchangeById(id: number): Observable<ReqSuccessResponse<ExchangesModel>> {
    const url = API_EXCHANGES + `/${id}`;
    return this.http.get<ReqSuccessResponse<ExchangesModel>>(url);
  }

  approveExchange(payload: {
    transactionId: string;
  }): Observable<ReqSuccessResponse<ExchangesModel>> {
    const url = `${API_EXCHANGES}/approval`;
    const approveExchange$ = this.http.post<ReqSuccessResponse<ExchangesModel>>(url, payload);

    return this.loadingService
      .showLoaderUntilCompleted(approveExchange$);
  }

  refreshExchange(transactionId: string): Observable<ReqSuccessResponse<string>> {
    const url = `${API_EXCHANGES}/refresh_exchange/${transactionId}`;
    const refreshExchange$ = this.http.get<ReqSuccessResponse<string>>(url);

    return this.loadingService
      .showLoaderUntilCompleted(refreshExchange$);
  }

  batchApprove(transactionIds: { transactionId: number }[]): Observable<ReqSuccessResponse<ExchangesModel[]>> {

    const url = `${API_EXCHANGES}/approve_multiple`;

    return this.http.post<ReqSuccessResponse<ExchangesModel[]>>(url, {
      transactions: transactionIds
    });
  }
}
