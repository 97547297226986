<!--begin::Card-->
<div class="card card-custom gutter-b">
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">Advisory Portfolio</h3>
    </div>
  </div>

  <div class="card-body">
    <!--begin: Search Form-->
    <div class="mb-5">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <form [formGroup]="searchForm">
            <div class="row align-items-center">
              <div class="col-xl-2 col-lg-4 col-md-6" *ngIf="isSuperAdmin">
                <div class="form-group">
                  <label>Merchant</label>
                  <ng-select
                    formControlName="merchantId"
                    [clearable]="false"
                    [virtualScroll]="true"
                    [loading]="this.merchants?.loading"
                  >
                    <ng-option value="">All</ng-option>
                    <ng-option
                      [value]="merchant?.id"
                      *ngFor="let merchant of merchants?.list"
                    >
                      {{ merchant?.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>From</label>
                  <input
                    class="form-control"
                    type="date"
                    formControlName="from"
                  />
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>To</label>
                  <input
                    class="form-control"
                    type="date"
                    formControlName="to"
                  />
                </div>
              </div>

              <div class="col-xl-1 col-lg-4 col-md-6">
                <div class="form-group">
                  <label class="d-block">&nbsp;</label>
                  <button
                    type="button"
                    class="btn btn-light-primary font-weight-bold"
                    (click)="handleQuery()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end::Search Form-->

    <!--begin: Datatable-->

    <lib-messages></lib-messages>

    <div
      [hidden]="dataSource.data && dataSource.data?.length <= 0"
      class="table-responsive"
    >
      <div class="mt-5 mb-5">
        <div class="d-flex align-items-center">
          <div class="font-weight-bold">
            Selected {{ selectedItems.size }}
            {{ selectedItems.size <= 1 ? "record" : "records" }}:
            <button
              [disabled]="selectedItems.size <= 0"
              (click)="downloadFile()"
              class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3"
            >
              <i class="far fa-arrow-alt-circle-down"></i> Download
            </button>
          </div>
        </div>
      </div>

      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          matMultiSort
          aria-label="Redemption table"
        >
          <ng-container matColumnDef="check" sticky>
            <th mat-header-cell *matHeaderCellDef="let row">
              <span style="width: 20px">
                <label class="checkbox checkbox-single checkbox-all">
                  <input
                    (change)="onAllChecked($event.target.checked)"
                    type="checkbox"
                    [checked]="allSelected"
                  />&nbsp;<span></span>
                </label>
              </span>
            </th>

            <td mat-cell *matCellDef="let row">
              <span style="width: 20px">
                <label class="checkbox checkbox-single">
                  <input
                    type="checkbox"
                    (change)="onItemChecked($event.target.checked, row)"
                    [checked]="selectedItems.has(row?.id)"
                  />&nbsp;<span></span>
                </label>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="name">
              Name
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-multi-sort-header="description"
            >
              Description
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row?.description }}
            </td>
          </ng-container>

          <ng-container matColumnDef="equity">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-multi-sort-header="equity"
            >
              Equity
            </th>
            <td mat-cell *matCellDef="let row">
              {{ formatToCurrency(currencyEnum.USD, row?.equity) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastPortfolioRebalance">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-multi-sort-header="lastPortfolioRebalance"
            >
              Last Portfolio Rebalance
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row?.lastPortfolioRebalance }}
            </td>
          </ng-container>

          <ng-container matColumnDef="nextPortfolioRebalance">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-multi-sort-header="nextPortfolioRebalance"
            >
              Next Portfolio Rebalance
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row?.nextPortfolioRebalance }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rebalanceRequired">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Rebalance Required
            </th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <span class="text-dark-75 d-block font-size-lg">
                <i
                  [ngClass]="
                    row?.rebalanceRequired
                      ? 'fas fa-check text-success'
                      : 'far fa-window-close text-danger'
                  "
                ></i>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="totalDrift">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-multi-sort-header="totalDrift"
            >
              Total Drift
            </th>
            <td mat-cell *matCellDef="let row">
              {{ formatToCurrency(currencyEnum.USD, row?.totalDrift) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>

            <td class="text-center" mat-cell *matCellDef="let row">
              <a
                href="javascript:;"
                (click)="viewPortfolio(row?.id)"
                class="btn btn-sm btn-clean btn-icon mr-2"
              >
                <i class="far fa-eye"></i>
              </a>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <mat-paginator
        class="mat-paginator-sticky"
        [length]="count"
        [pageSizeOptions]="[10, 50, 100, 150, 200]"
        (page)="pageEvent = $event; paginate($event)"
        showFirstLastButtons
      ></mat-paginator>
    </div>

    <div
      *ngIf="!loading && dataSource.data?.length <= 0"
      class="
        no-data-container
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
    >
      <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
      <p class="no-data-text">No data</p>
    </div>

    <!--end: Datatable-->
  </div>
</div>
<!--end::Card-->
