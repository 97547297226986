import { Router } from '@angular/router';
import { ConsoleRouterService } from '../../../../../../shared/src/lib/services/console-router.service';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { NewsContent, NewsContentDetails } from '../services/news.model';
import { NewsService } from '../services/news.service';
import { ChakaAPIError, ReqSuccessResponse } from '../../../../../../api/src/public-api';
import { NgxSpinnerService } from 'ngx-spinner';

export enum CrudState {
  Retrieve = 'Retrieve',
  Retrieved = 'Retrieved',
  Create = 'Create',
  Created = 'Created',
  Update = 'Update',
  Updated = 'Updated',
  Delete = 'Delete',
  Deleted = 'Deleted',
  Initial = 'Initial',
  Error = 'Error',
}

export interface NewsEditorState {
  news?: NewsContentDetails;
  // newsCont:NewsContent;
  loading: boolean;
  error?: string;
  // status: CrudState;
  message?: string;
}

const initalState: NewsEditorState = {
  loading: false,
  // status: CrudState.Initial,
};


@Injectable({ providedIn: 'root' })
export class NewsEditorStateService {
  state = new BehaviorSubject<NewsEditorState>(initalState);

  constructor(private svc: NewsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private console: ConsoleRouterService,
    private route:Router) { }

  create(news: NewsContentDetails) {
    this.loading();
    this.svc.createNewsContent(news).subscribe({
      next: this.saveNews.bind(this),
      error: this.onNewsError.bind(this),
    });
  }

  retrieve(newsId: number) {
    this.loading();
    this.svc.getNewsContentById(newsId)
    .pipe(first())
    .subscribe({
      next: this.updateNews.bind(this),
      error: this.onNewsError.bind(this),
    });
  }

  update(content: NewsContent) {
    this.loading();
    this.svc.updateNewsContent(content).subscribe({
      next: this.updateSavedNews.bind(this),
      error: this.onNewsError.bind(this),
    });
  }

  delete(newsId: number) {
    this.loading();
    this.svc.deleteNewsContent(newsId).subscribe({
      next: this.deleteNews.bind(this),
      error: this.onNewsError.bind(this),
    });
  }

  resetState() {
    this.state.next(initalState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
      // status: CrudState.Initial,
    });
  }

  get loading$() {
    return this.state.pipe(map(state => state.loading));
  }


  private updateNews(data: ReqSuccessResponse<NewsContentDetails>) {
    this.state.next({
      loading: false,
      news: data.data,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private saveNews(data: ReqSuccessResponse<NewsContentDetails>) {
    this.state.next({
      loading: false,
      news: data.data,
      message:"Loading Successful"
    });
    this.toastr.success('News Created Successfully','Success',{
      positionClass: 'toast-bottom-center',
      timeOut:3000
    })
    this.spinner.hide();

  }

  private updateSavedNews(data: ReqSuccessResponse<NewsContentDetails>) {
    this.state.next({
      loading: false,
      news: data.data,
      message:"News Updated Successfully",

    });
    this.toastr.success('News Updated Successfully','Success',{
      positionClass: 'toast-bottom-center',
      timeOut:3000
    })
    this.spinner.hide();
    this.route.navigate(['/equity/news']);

  }

  private deleteNews(data: ReqSuccessResponse<NewsContentDetails>) {
    this.state.next({
      loading: false,
      news: data.data,
      message:"News Deleted Successfully",

    });
    this.toastr.success('News Deleted Successfully','Success',{
      positionClass: 'toast-bottom-center',
      timeOut:3000
    })
    this.spinner.hide();
    this.route.navigate(['/equity/news']);

  }


  private onNewsError(res: ChakaAPIError) {
    this.state.next({
      loading: false,
      error: res.error.message,
      // status: CrudState.Error,
    });
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading"
    });
  }
}
