import { RedemptionStatusEnum } from '../../../../../../shared/src/lib/_enums/RedemptionStatus.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Subscription } from 'rxjs';
import { ConsoleRouterService } from '../../../../../../shared/src/public-api';
import { IRedemption } from '../../interface/IRedemption';
import {
  IRedemptionState,
  RedemptionStateService,
} from '../../state/redemption-state.service';

@Component({
  selector: 'lib-redemption-details',
  templateUrl: './redemption-details.component.html',
  styleUrls: ['./redemption-details.component.scss'],
})
export class RedemptionDetailsComponent implements OnInit, OnDestroy {
  pageTitle = 'View Redemption';

  redemption: IRedemption;

  loading = false;

  redemptionSub$: Subscription;

  currentClicked: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private consoleRouter: ConsoleRouterService,
    private redemptionState: RedemptionStateService,
    private messagesService: MessagesService
  ) {}

  ngOnInit(): void {
    this.retrieveRedemption();
  }

  retrieveRedemption(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;

      this.redemptionState.findById(id);

      this.listenOnState();
    });
  }

  private listenOnState(): void {
    this.redemptionSub$ = this.redemptionState.state$.subscribe(
      (state: IRedemptionState) => {
        this.redemption = state.redemption;
        this.loading = state.loading;
      }
    );
  }

  back(): void {
    this.consoleRouter.goBack();
  }

  gotUserProfile(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  get fullName(): string {
    try {
      return (
        this.redemption?.userDetails?.firstName +
        ' ' +
        this.redemption?.userDetails?.lastName
      ).trim();
    } catch (error) {
      return '';
    }
  }

  get amount(): string {
    try {
      return (
        this.redemption?.currency?.symbol +
        ' ' +
        this.redemption?.amount
      ).trim();
    } catch (error) {
      return '';
    }
  }

  get canBeRequeried(): boolean {
    return this.redemptionState.canBeRequeried(
      this.redemption?.status?.message
    );
  }

  get canBeApproved(): boolean {
    return this.redemptionState.canBeApproved(this.redemption?.status?.message);
  }

  get canBeRejected(): boolean {
    return this.redemptionState.canBeRejected(this.redemption?.status?.message);
  }

  requery(redemptionId: string): void {
    this.currentClicked = 'REQUERY';
    this.redemptionState.requery(redemptionId);
  }

  approveOrReject(redemptionId: string, action: 'APPROVE' | 'REJECT'): void {
    const check = confirm(
      `Do you want to ${action.toLowerCase()} this redemption?`
    );

    if (!check) {
      return;
    }

    this.currentClicked = action;

    this.redemptionState.batchApproveOrReject(
      {
        redemptionIDs: [redemptionId],
        status:
          action === 'APPROVE'
            ? RedemptionStatusEnum.RIA_APPROVED
            : RedemptionStatusEnum.RIA_REJECTED,
      },
      (success: boolean) => {
        success && this.requery(redemptionId);
      }
    );
  }

  ngOnDestroy(): void {
    this.redemptionSub$ && this.redemptionSub$.unsubscribe();

    this.redemptionState.reset();

    this.messagesService.hideAll();
  }
}
