import { Injectable } from '@angular/core';
import { NotificationService } from '@console/shared/services/notification-service';
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from '../../../../../api/src/public-api';
import { ExchangesModel } from '../services/exchanges.model';
import { ExchangesService } from '../services/exchanges.service';


export interface ExchangeState {
  loading: boolean;
  count?: number;
  exchange?: ExchangesModel;
  error?: string;
  message?: string;
}

const initialState: ExchangeState = {
  loading: false,

};

@Injectable({ providedIn: 'root' })
export class ExchangeStateService {
  state = new BehaviorSubject<ExchangeState>(initialState);

  constructor(
    private exchangeSvc: ExchangesService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService) { }

  findById(id: number): void {
    this.loading();
    this.exchangeSvc
      .getExchangeById(id)
      .pipe(first())
      .subscribe({
        next: this.updateExchanges.bind(this),
        error: this.onError.bind(this),
      });
  }

  approve(transactionId: string): void {
    // this.loading();
    this.exchangeSvc
      .approveExchange({ transactionId })
      .pipe(first())
      .subscribe({
        next: () => {
          this.notificationService.success('Update was successful');
        },
        error: this.onError.bind(this),
      });
  }

  refresh(transactionId): Observable<ReqSuccessResponse<string>> {
    // this.loading();
    return this.exchangeSvc
      .refreshExchange(transactionId)
      .pipe(
        catchError(error => {
          let message = 'Error occurred';
          if (error.error.message) {
            message = error.error.message;
          }

          this.notificationService.error(message);

          return throwError(error);
        }),
        first()
      );
  }


  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateExchanges(data: ReqSuccessResponse<ExchangesModel>) {
    this.state.next({
      loading: false,
      exchange: data.data,
      message: "Loading Successful"
    });
    // this.spinner.hide();

    this.notificationService.success('Update was successful');
  }

  private onError(res: ChakaAPIError): void {
    let message = 'Error occurred';
    if (res.error.message) {
      message = res.error.message;
    }

    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message
    });

    //hmm
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 3000)
    this.spinner.hide();

    this.notificationService.error(message);

  }

  reset(): void {
    this.state.next(initialState);
  }

  private loading(): void {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: "Loading"
    });
  }
}
