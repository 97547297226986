import { FormControl } from '@angular/forms';

const isNumeric = (str: string) => {
  if (typeof str !== 'string') { return false; } // we only process strings!
  return !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};


export const shouldBeGreaterThanZero = (control: FormControl): { [s: string]: boolean } => {
  try {
    const value = isNumeric(control.value) ?
      control.value :
      Number.parseFloat(control.value);

    if (value <= 0) {
      return { shouldBeGreaterThanZero: true };
    }

    return null;
  } catch (error) {
    return null;
  }
};
