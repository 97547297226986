import { extractSearchParams } from './../../../../../shared/src/lib/utils/extractSearchParams';
import { ConsoleRouterService } from './../../../../../shared/src/lib/services/console-router.service';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
// import { PageEvent } from '../../../../../shared/src/lib/interface';
import { PAYMENT_PROCESSOR } from '../../../../../shared/src/public-api';
import { PaymentTxnsModel } from '../services/payment.model';
import { formatToCurrency } from '../../../../../shared/src/lib/utils/formatter';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  PaymentListState,
  PaymentListStateService,
} from '../payment-list/payment-state';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import {
  exportAndDownload,
  fileNameFormatter,
} from 'projects/_shared/csv-downloader/csv-downloader';
import { MatMultiSort } from 'ngx-mat-multi-sort';
import { PaymentStateService } from '../payments-edit/payments-edit-state';
import {
  AuthProfileState,
  AuthProfileStateService,
} from '@console/authentication/profile-state';
import {
  MerchantListState,
  MerchantListStateService,
} from '../../merchants/merchant-list/merchant-list.state';
import { MerchantsModel } from '../../merchants/merchants.interface';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { PaymentOtpModalService } from '../payments-edit/components/payment-otp-modal/payment-otp-modal.service';
@Component({
  selector: 'app-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss'],
})
export class PaymentsTableComponent implements OnInit, OnDestroy {
  @Input() userId: string;

  count = 0;

  @Output() query = new EventEmitter<object>();

  /**
   * emit query selected from the table
   */
  @Output() payment = new EventEmitter<PaymentTxnsModel>();

  paymentStatusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Successful',
      value: 'SUCCESSFUL',
    },
    {
      name: 'Pending',
      value: 'PENDING',
    },
    {
      name: 'Failed',
      value: 'FAILED',
    },
    {
      name: 'Partial Success',
      value: 'PARTIAL_SUCCESS',
    },
    {
      name: 'Suspicious Fraud',
      value: 'SUSPICIOUS_FRAUD',
    },
    {
      name: 'Rejected',
      value: 'REJECTED',
    },
  ];

  processors = [
    {
      name: 'All',
      value: PAYMENT_PROCESSOR.ALL,
    },
    {
      name: 'Interswitch',
      value: PAYMENT_PROCESSOR.INTERSWITCH,
    },
    {
      name: 'Paystack',
      value: PAYMENT_PROCESSOR.PAYSTACK,
    },
    {
      name: 'Monnify',
      value: PAYMENT_PROCESSOR.MONNIFY,
    },
    {
      name: 'Okra',
      value: PAYMENT_PROCESSOR.OKRA,
    },
    {
      name: 'NIP',
      value: PAYMENT_PROCESSOR.NIP,
    },
  ];

  sourceTypes = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Card',
      value: 'CARD',
    },
    {
      name: 'Bank Account',
      value: 'BANK_ACCOUNT',
    },
    {
      name: 'QR',
      value: 'QR',
    },
    {
      name: 'Transfer',
      value: 'TRANSFER',
    },
    {
      name: 'USSD',
      value: 'USSD',
    },
    {
      name: 'Broker',
      value: 'BROKER',
    },
  ];

  @Output() batch = new EventEmitter<PaymentTxnsModel[]>();

  formatToCurrency = formatToCurrency;

  // here
  loading = false;

  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'fullName',
    'amount',
    'reference',
    'source',
    'virtualAccountNo',
    'processor',
    'dateCreated',
    'authorizationStatus',
    'paymentStatus',
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  private filter: Record<string, any> = {};

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[];
    loading: boolean;
    total: number;
    pageSize: number;
    pageNumber: number;
  } = {
    list: [],
    loading: false,
    total: 0,
    pageSize: 1000,
    pageNumber: 0,
  };

  constructor(
    private consoleRouter: ConsoleRouterService,
    private paymentList: PaymentListStateService,
    private ref: ChangeDetectorRef,
    private paymentState: PaymentStateService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService,
    private messagesService: MessagesService,
    private paymentOtpModal: PaymentOtpModalService
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      q: new FormControl(''),
      processor: new FormControl(''),
      status: new FormControl(''),
      source: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(''),
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.paymentList.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: PaymentListState) => {
        this.dataSource = new MatTableDataSource<PaymentTxnsModel>(
          state.payments
        );
        this.dataSource.sort = this.sort;
        this.count = state.count;
        this.loading = state.loading;

        this.ref.detectChanges();
      });
  }

  listenOnAuthProfile(): void {
    this.authProfile.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: AuthProfileState) => {
        this.isSuperAdmin = state?.authProfile?.superAdmin;

        this.isSuperAdmin
          ? this.merchantListState.find({
              pageSize: this.merchants.pageSize,
              pageNumber: this.merchants.pageNumber,
            })
          : null;
      });
  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe((state: MerchantListState) => {
        this.merchants.loading = state?.loading;
        this.merchants.total = state?.count;
        this.merchants.list = [...this.merchants.list, ...state?.merchants];
      });
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = ++$event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.query.emit(this.filter);
  }

  emit(payment: PaymentTxnsModel): void {
    this.payment.emit(payment);
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach((payment) => {
      this.updateCheckedSet(checked, payment);
    });
  }

  onItemChecked(checked: boolean, payment: PaymentTxnsModel): void {
    this.updateCheckedSet(checked, payment);
  }

  updateCheckedSet(checked: boolean, payment: PaymentTxnsModel): void {
    if (checked) {
      this.selectedItems.set(payment.id, payment);
    } else {
      this.selectedItems.delete(payment.id);
    }
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Payments',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, Array.from(this.selectedItems.values()));
  }

  paymentStatusClass(paymentStatus: string): string {
    const statusMapper = {
      SUCCESSFUL: 'text-success',
      ['FAILED' || 'REJECTED']: 'text-danger',
    };

    return statusMapper[paymentStatus] || '';
  }

  deletePayments(): void {
    const check = confirm(
      `Do you want to delete ${
        this.selectedItems.size === 1 ? 'this payment' : 'these payments'
      }? `
    );

    if (check) {
      const paymentIds = Array.from(this.selectedItems.keys());
      this.paymentState.delete(paymentIds, this.filter);
      this.selectedItems.clear();
    }
  }

  batchApprove(): void {
    try {
      const cannotApprove = this.selectedPayments.some(
        ({ authorizationStatus }) => authorizationStatus
      );

      if (cannotApprove) {
        throw new Error(
          'You can ONLY authorize payments that are not yet processed.'
        );
      }

      this.paymentOtpModal.openModal(
        this.selectedPayments.map(({ paymentReference }) => paymentReference),
        (result: boolean) => {
          if (result) {
            this.selectedItems.clear();
            this.handleQuery();
          }
        }
      );
    } catch (error) {
      this.messagesService.open(MessagesEnum.danger, error.message, {
        hideAll: true,
      });
    }

    // const payload = Array.from(this.selectedItems.values()).filter(
    //   (item) => !item.status
    // );

    // console.log(payload);
    // .filter((i) => !i.status)
    // .forEach((t) => {
    //   // this.paymentState.approve(t.paymentReference);
    // });
  }

  private get selectedPayments() {
    return Array.from(this.selectedItems.values());
  }

  loadMoreMerchant(): void {
    // will be implemented later
    return;
    // console.log('scroll to end');
    if (
      this.merchants.total >
      this.merchants.pageSize * this.merchants.pageNumber + 1
    ) {
      console.log('got here');
      return;
    }

    this.merchantListState.find({
      pageSize: this.merchants.pageSize,
      pageNumber: ++this.merchants.pageNumber,
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.merchantListState.reset();
  }
}
