import { HttpErrorResponse } from '@angular/common/http';
import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { cleanChakaAPIError, ReqSuccessResponse } from '@console/api';
import { Observable, Observer, of } from 'rxjs';
import { IBankNameLookup } from '../interface/IBankNameLookup';
import { IBankNameSearch } from '../interface/IBankNameSearch';
import { ChakaAccountService } from '../service/chaka-account.service';

@Directive({
  selector: '[accountNameValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: AccountNameValidator,
      multi:
        true
    }]
})

export class AccountNameValidator implements AsyncValidator {
  @Input() bankId: number;

  constructor(private chakaAccountService: ChakaAccountService) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    control['accountName'] = null;

    if (!this.bankId) {
      return of({ invalidAccount: 'Please select a bank' });
    }

    const accountNumber = control.value;

    const searchParams: IBankNameSearch = {
      bankId: this.bankId,
      accountNumber,
      bankAcctNo: accountNumber
    };

    control['fetchingAccount'] = true;

    const observer$ = new Observable((observer: Observer<ValidationErrors | null>) => {
      this.chakaAccountService
        .getBankAccountName(searchParams)
        .subscribe(
          (response: ReqSuccessResponse<IBankNameLookup>) => {
            control['fetchingAccount'] = false;

            if (response.success &&
              response.data &&
              response.data.accountName) {
              control['accountName'] = response.data.accountName;

              observer.next(null);
              observer.complete();
              // return of(null);
            }

            // return of({ invalidAccount: 'Invalid account number' });
            observer.next({ invalidAccount: 'Invalid account number' });
            observer.complete();
          },
          (error: HttpErrorResponse) => {
            control['fetchingAccount'] = false;

            const errorMessage = cleanChakaAPIError(error);
            observer.next({ invalidAccount: errorMessage });
            observer.complete();
          }
        )
    });

    return observer$;

  }
}