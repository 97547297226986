import { DwAccount } from './../../../../user-managements/src/lib/models/wallet.model';

import { map } from 'rxjs/operators';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from './../../../../api/src/lib/api.interface';
import { WalletService } from './../../../../user-managements/src/lib/sections/accounts/providers/wallet.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationService } from '@console/shared/services/notification-service';


export interface DwAccountState {
  DwAccount?: DwAccount;
  loading: boolean;
}

const initialState: DwAccountState = { loading: false };

@Injectable({ providedIn: 'root' })

export class DwAccountStateService {

  state = new BehaviorSubject<DwAccountState>(initialState);
  notificationId: number;

  constructor(
    private walletService: WalletService,
    private notificationService: NotificationService) {}

  retrieve(userId: string): void {
    this.loading();

    this.walletService
      .getDwAccount({ userId })
      .subscribe({
        next: (response) => this.onUserDwAccountsLoaded(response),
        error: (error: ChakaAPIError) => this.onUserDwAccountsError(error)
      });
  }

  get loading$(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(): void {
    this.state.next({ ...this.state.getValue(), loading: true });
  }

  private onUserDwAccountsLoaded(response: ReqSuccessResponse<DwAccount>): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      DwAccount: response?.data
    });

    !!this.notificationId && this.notificationService.remove(this.notificationId);
    this.notificationId = this.notificationService.success('DwAccount successfully loaded.');
  }

  private onUserDwAccountsError(res: ChakaAPIError): void {
    const error = cleanChakaAPIError(res);

    this.state.next({
      loading: false
    });

    !!this.notificationId && this.notificationService.remove(this.notificationId);
    this.notificationId = this.notificationService.error(error);
  }
}
