<div>
  <ngx-spinner
    [color]="'#ffffff'"
    [name]="walletState.spinnerName"
    [fullScreen]="false"
    type="ball-clip-rotate-multiple"
    size="medium"
  >
    <!-- <p class="loading">{{ (walletState.state | async).message }}</p> -->
  </ngx-spinner>
  <div class="row row-full-height">
    <div
      class="col-12 mb-8"
      *ngFor="let wallet of (walletState.state | async).wallets"
    >
      <kt-wallet-card [data]="wallet" [noPadding]="true"></kt-wallet-card>
    </div>
  </div>

  <div class="row">
    <button (click)="createAllWallets()" class="btn btn-primary btn-block">
      Create Both Wallets
    </button>

    <button (click)="createGlobalWallet()" class="btn btn-secondary btn-block">
      Create Global Wallet
    </button>

    <button (click)="createLocalWallet()" class="btn btn-primary btn-block">
      Create Local Wallet
    </button>

    <button
      (click)="createVirtualAccount()"
      class="btn btn-secondary btn-block"
    >
      Create Virtual Account
    </button>

    <!-- <button
      *ngIf="
        !(walletState.select$('NGN') | async) &&
        !(walletState.select$('USD') | async)
      "
      (click)="createAllWallets()"
      class="btn btn-primary btn-block">
      Create Both Wallets
    </button> -->

    <!-- <button
      *ngIf="!(walletState.select$('USD') | async)"
      (click)="createGlobalWallet()"
      class="btn btn-secondary btn-block">
      Create Global Wallet
    </button>

    <button
      *ngIf="!(walletState.select$('NGN') | async)"
      (click)="createLocalWallet()"
      class="btn btn-primary btn-block">
      Create Local Wallet
    </button> -->
  </div>
</div>
