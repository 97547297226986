<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card">
    <div class="card-header bg-white">
      <span class="card-header-title-text">Channel </span>
      <span class="float-right">
        <span>
          <a
            [routerLink]="['../']"
            class="btn btn-sm btn-transparent border mr-3"
            ><i class="fas fa-arrow-left"></i> Back</a
          >
        </span>

        <span
          *ngIf="
            isUpdateMode &&
            (channelState.state | async).channel &&
            (channelState.state | async).channel.id
          "
        >
          <a
            (click)="delete()"
            class="btn btn-sm btn-danger text-light border-0 mr-3"
            ><i class="fas fa-trash-alt"></i> Delete</a
          >
        </span>
      </span>
    </div>

    <div class="card-body" *ngIf="formCreated">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <form [formGroup]="channelForm" (ngSubmit)="save()">
            <lib-messages></lib-messages>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Name <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="formMethods.touchedAndInvalid('name')"
                  >
                    <div
                      *ngIf="
                        formMethods.getControl('name').hasError('required')
                      "
                    >
                      Required input.
                    </div>
                  </small>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label
                      >Channel Type <span class="text-danger">*</span></label
                    >
                    <ng-select formControlName="channelType">
                      <ng-option value="">--Select--</ng-option>
                      <ng-option
                        [value]="channelType.value"
                        *ngFor="let channelType of channelTypeList"
                        >{{ channelType.label }}</ng-option
                      >
                    </ng-select>
                    <small
                      class="form-text text-danger"
                      *ngIf="formMethods.touchedAndInvalid('channelType')"
                    >
                      <div
                        *ngIf="
                          formMethods
                            .getControl('channelType')
                            .hasError('required')
                        "
                      >
                        Required input.
                      </div>
                    </small>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Currency <span class="text-danger">*</span></label>
                    <ng-select formControlName="currencyCode">
                      <ng-option
                        [value]="currency"
                        *ngFor="let currency of currencyList"
                        >{{ currency }}</ng-option
                      >
                    </ng-select>
                    <small
                      class="form-text text-danger"
                      *ngIf="formMethods.touchedAndInvalid('currencyCode')"
                    >
                      <div
                        *ngIf="
                          formMethods
                            .getControl('currencyCode')
                            .hasError('required')
                        "
                      >
                        Required input.
                      </div>
                    </small>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Rank <span class="text-danger">*</span></label>
                    <input
                      type="number"
                      formControlName="rank"
                      class="form-control"
                      (ngModelChange)="onRankChanged($event)"
                      min="1"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="formMethods.touchedAndInvalid('rank')"
                    >
                      <div
                        *ngIf="
                          formMethods.getControl('rank').hasError('required')
                        "
                      >
                        Required input.
                      </div>
                      <div
                        *ngIf="formMethods.getControl('rank').hasError('min')"
                      >
                        Rank must be greater than 0
                      </div>
                      <div
                        *ngIf="
                          formMethods.getControl('rank').hasError('rankIsTaken')
                        "
                      >
                        Rank is already taken
                      </div>
                    </small>
                  </div>

                  <div class="form-group col-md-6">
                    <div class="row mt-8">
                      <label class="col-4 col-form-label">Enabled?</label>
                      <div class="col-8">
                        <span class="switch">
                          <label>
                            <input type="checkbox" formControlName="status" />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Image Url <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="imageUrl"
                  />
                  <small
                    class="form-text text-danger"
                    *ngIf="formMethods.touchedAndInvalid('imageUrl')"
                  >
                    <div
                      *ngIf="
                        formMethods.getControl('imageUrl').hasError('required')
                      "
                    >
                      Required input.
                    </div>
                    <div
                      *ngIf="
                        formMethods
                          .getControl('imageUrl')
                          .hasError('invalidImage')
                      "
                    >
                      Enter a valid image url
                    </div>
                  </small>
                </div>

                <div class="form-group" [hidden]="!this.imageLoaded">
                  <img
                    [src]="channelForm.get('imageUrl').value"
                    (error)="imageError($event)"
                    (load)="imageSuccess($event)"
                    style="width: 100px"
                  />
                </div>

                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    formControlName="description"
                  ></textarea>
                  <small
                    class="form-text text-danger"
                    *ngIf="formMethods.touchedAndInvalid('description')"
                  >
                    <div
                      *ngIf="
                        formMethods
                          .getControl('description')
                          .hasError('required')
                      "
                    >
                      Required input.
                    </div>
                  </small>
                </div>

                <div class="form-group text-center">
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
