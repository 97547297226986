<div class="kt-portlet">
	<div class="kt-portlet__head">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">
				{{ tag.name }}
			</h3>
		</div>
	</div>
	<div class="kt-portlet__body">

		<!--begin::Section-->
		<div class="kt-section kt-section--last">
			<span class="kt-section__info">
				{{
					tag.description
						? tag.description.slice(0, 20) + "..."
						: ""
				}}
			</span>
			<div class="kt-section__content">
				<a
					(click)="btnClick.emit(tag)" [class]="'white-text float-right text-light btn btn-custom btn-bold btn-upper btn-font-sm btn-' + status" data-toggle="modal" data-target="#kt_scrollable_modal_1">Full Details</a>
			</div>
		</div>

		<!--end::Section-->
	</div>
</div>
<!--
<div [class]="'kt-portlet kt-callout chaka-callout'">
	<div class="kt-portlet__body">
		<div class="kt-callout__body">
			<div class="">
				<h3 class="kt-callout__title">{{ tag.name }}</h3>
				<p class="kt-callout__desc">
					{{
						tag.description
							? tag.description.slice(0, 20) + "..."
							: ""
					}}
				</p>
			</div>
			<div class="kt-callout__action">
				<a
					(click)="btnClick.emit(tag)"
					[class]="'white-text btn btn-custom btn-bold btn-upper btn-font-sm btn-' + status"
					>Full Details</a
				>
			</div>
		</div>
	</div>
</div> -->
