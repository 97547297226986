import { Injectable } from '@angular/core';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import {
  APIResponse,
  EnterpriseAPIService,
} from '../../../../../api/src/public-api';
import { ChannelSetting } from './settings.interface';

@Injectable()
export class ChannelSettingService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  find(searchParams?) {
    return this.http.get<APIResponse<ChannelSetting>>(
      `/api/v1/channels`,
      searchParams
    );
  }

  retrieve(id: number) {
    const channel$ = this.http.get<APIResponse<ChannelSetting>>(
      `/api/v1/channels/${id}`
    );

    return this.loadingService.showLoaderUntilCompleted(channel$);
  }

  update(content: Partial<ChannelSetting>) {
    const updatedChannel$ = this.http.put<APIResponse<ChannelSetting>>(
      `/api/v1/channels`,
      content
    );

    return this.loadingService.showLoaderUntilCompleted(updatedChannel$);
  }

  create(content: Partial<ChannelSetting>) {
    const createdChannel$ = this.http.post<APIResponse<ChannelSetting>>(
      `/api/v1/channels`,
      content
    );

    return this.loadingService.showLoaderUntilCompleted(createdChannel$);
  }

  remove(id: number) {
    return this.http.delete<APIResponse<ChannelSetting>>(
      `/api/v1/channels/${id}`
    );
  }
}
