import { RedemptionStatusEnum } from '../../enums/RedemptionStatus.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Subscription } from 'rxjs';
import { ConsoleRouterService } from '../../../../../../shared/src/public-api';
import { IAutoRebalance } from '../../interface/IAutoRebalance';
import {
  AutoRebalanceDetailsStateService,
  IAutoRebalanceDetailsState,
} from '../../state/auto-rebalance-details-state.service';

@Component({
  selector: 'lib-auto-rebalance-details',
  templateUrl: './auto-rebalance-details.component.html',
  styleUrls: ['./auto-rebalance-details.component.scss'],
})
export class AutoRebalanceDetailsComponent implements OnInit, OnDestroy {
  pageTitle = 'View Auto-Rebalance';

  autoRebalance: IAutoRebalance;

  loading = false;

  autoRebalanceSub$: Subscription;

  currentClicked: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private consoleRouter: ConsoleRouterService,
    private autoRebalanceDetailsState: AutoRebalanceDetailsStateService,
    private messagesService: MessagesService
  ) {}

  ngOnInit(): void {
    this.retrieveAutoRebalance();
  }

  retrieveAutoRebalance(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;

      this.autoRebalanceDetailsState.findById(id);

      this.listenOnState();
    });
  }

  private listenOnState(): void {
    this.autoRebalanceSub$ = this.autoRebalanceDetailsState.state$.subscribe(
      (state: IAutoRebalanceDetailsState) => {
        this.autoRebalance = state.autoRebalance;
        this.loading = state.loading;
      }
    );
  }

  back(): void {
    this.consoleRouter.goBack();
  }

  gotUserProfile(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  get canBeRequeried(): boolean {
    const allowedStatus: string[] = [
      RedemptionStatusEnum.STARTED,
      RedemptionStatusEnum.PENDING,
      RedemptionStatusEnum.OTHER,
      RedemptionStatusEnum.RIA_PENDING,
      RedemptionStatusEnum.RIA_APPROVED,
      RedemptionStatusEnum.APPROVED,
      RedemptionStatusEnum.On_Hold,
    ].map((item) => item.toUpperCase());

    // return allowedStatus.includes(this.autoRebalance?.status?.message.toUpperCase());
    return true;
  }

  requery(redemptionId: string): void {
    this.currentClicked = 'REQUERY';
    this.autoRebalanceDetailsState.requery(redemptionId);
  }

  ngOnDestroy(): void {
    this.autoRebalanceSub$ && this.autoRebalanceSub$.unsubscribe();

    this.messagesService.hideAll();
  }
}
