// import { TransactionsEditComponent } from 'projects/view/src/lib/transactions/transaction-edit/transactions-edit.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TreeModule } from '@circlon/angular-tree-component';
import { ApiModule } from '@console/api';
import { SharedModule } from '@console/shared';
import { UserManagementModule } from '@console/user-managements';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthUsersComponent } from 'projects/view/src/lib/user-management/kyc/components/auth-users/auth-users.component';
import { PaginationModule } from '../../../_shared/Pagination/pagination.module';
import { SearchBarModule } from '../../../_shared/search-bar/search-bar.module';
import { CollectionsService } from '../../src/lib/content-management/collections/services/collections.service';
import { LearnService } from '../../src/lib/content-management/learn/services/learn.service';
import { TagService } from '../../src/lib/tags/services/tags.service';
import { InstrumentTagListStateService } from '../lib/tags/tag-list.state';
import { AccountManagerModule } from './account-manager/account-manager.module';
import { AdvisoryPortfolioModule } from './advisory-portfolios/advisory-portfolio.module';
import { AuditLogModule } from './audit-log/audit-log.module';
import { AutoRebalanceModule } from './auto-rebalance/auto-rebalance.module';
import { ChakaAccountsModule } from './chaka-accounts/chaka-accounts.module';
import { BlogEditComponent } from './content-management/blog/blog-edit/blog-edit.component';
import { BlogListStateService } from './content-management/blog/blog-list.state';
import { BlogComponent } from './content-management/blog/blog.component';
import { BlogSharedModule } from './content-management/blog/components/blog.shared.module';
import { BlogService } from './content-management/blog/services/blog.service';
import { CollectionEditorStateService } from './content-management/collections/collections-edit/collection-edit.state';
import { CollectionsEditComponent } from './content-management/collections/collections-edit/collections-edit.component';
import { CollectionsComponent } from './content-management/collections/collections.component';
import { CollectionListStateService } from './content-management/collections/collections.state';
import { CollectionSharedModule } from './content-management/collections/components/collections.shared.module';
import { LearnSharedModule } from './content-management/learn/components/learn.shared.module';
import { LearnContentListStateService } from './content-management/learn/learn-list.state';
import { LearnComponent } from './content-management/learn/learn.component';
import { NewsSharedModule } from './content-management/news/components/news.shared.module';
import { NewsEditComponent } from './content-management/news/news-edit/news-edit.component';
import { NewsEditorStateService } from './content-management/news/news-edit/news-edit.state';
import { NewsListStateService } from './content-management/news/news-list.state';
import { NewsComponent } from './content-management/news/news.component';
import { NewsService } from './content-management/news/services/news.service';
import { CscsReportModule } from './cscs-report/cscs-report.module';
import { DwAccountModule } from './dw-account/dw-account.module';
import { DwHistoryTableModule } from './dw-history/dw-history-table/dw-history-table.module';
import { DwHistoryModule } from './dw-history/dw-history.module';
import { ExchangeRateStateService } from './exchange-rates/exchange-rates-edit/exchange-rates-edit-state';
import { ExchangeRateEditComponent } from './exchange-rates/exchange-rates-edit/exchange-rates-edit.component';
import { ExchangeRateListStateService } from './exchange-rates/exchange-rates-state';
import { ExchangeRatesComponent } from './exchange-rates/exchange-rates.component';
import { ExchangeStateService } from './exchanges/exchanges-edit/exchanges-edit-state';
import { ExchangesEditComponent } from './exchanges/exchanges-edit/exchanges-edit.component';
import { ExchangeListStateService } from './exchanges/exchanges-state';
import { ExchangesComponent } from './exchanges/exchanges.component';
import { ExchangesService } from './exchanges/services/exchanges.service';
import { HolidayMaintenanceComponent } from './holiday-maintenance/holiday-maintenance.component';
import { InstrumentSharedModule } from './instrument/componenet/instrument-shared.module';
import { InstrumentEditComponent } from './instrument/instrument-edit/instrument-edit.component';
import { InstrumentSymbolsStateService } from './instrument/instrument-symbols.state';
import { InstrumentComponent } from './instrument/instrument.component';
import { InstrumentListStateService } from './instrument/instrument.state';
import { RequeryInstrumentComponent } from './instrument/requery-instrument/requery-instrument.component';
import { InstrumentService } from './instrument/services/instrument.service';
import { InstrumentGraphStateService } from './instrument/states/graph-state.service';
import { SimilarInstrumentListStateService } from './instrument/states/similar-instrument.state';
import { InstrumentStateService } from './instrument/states/single-instrument--state.service';
import { MandateModule } from './mandate/mandate.module';
import { MerchantModule } from './merchants/merchant.module';
import { OrderTxnModule } from './orders/orders.module';
import { PaymentTxnModule } from './payment/payments.module';
import { ReconciliationListStateService } from './reconciliations/reconciliations-state';
// import { TagEditComponent } from './tags/tag-edit/tag-edit.component';
import { ReconciliationsComponent } from './reconciliations/reconciliations.component';
import { ReconciliationService } from './reconciliations/services/reconciliations.service';
import { RedemptionModule } from './redemptions/redemption.module';
import { ReferralSettingsModule } from './referral-settings/referral-settings.module';
import { PermissionEditorComponent } from './roles/permission-editor/permission-editor.component';
import { RoleAttachmentComponent } from './roles/role-attachment/role-attachment.component';
import { EditedRoleStateService } from './roles/role-edit/edited-role.state';
import { RoleCreatorStateService } from './roles/role-edit/role-creator.state';
import { RolesEditorComponent } from './roles/role-edit/roles-edit.component';
import { RoleListStateService } from './roles/roles-state';
import { RolesComponent } from './roles/roles.component';
import { PermissionService } from './roles/services/permission.service';
import { RoleService } from './roles/services/role.service';
import { AppSettingEditComponent } from './settings/application-setting/app-setting-edit/app-setting-edit.component';
import { AppSettingStateService } from './settings/application-setting/app-setting-edit/app-setting.state';
import { AppSettingListStateService } from './settings/application-setting/application-setting-list.state';
import { ApplicationSettingComponent } from './settings/application-setting/application-setting.component';
import { BankEditComponent } from './settings/banks/bank-edit/bank-edit.component';
import { BankStateService } from './settings/banks/bank-edit/bank-state';
import { BankListStateService } from './settings/banks/bank-list-state.service';
import { BanksComponent } from './settings/banks/banks.component';
import { ChannelEditComponent } from './settings/channels/channel-edit/channel-edit.component';
import { ChannelStateService } from './settings/channels/channel-edit/channel.state';
import { ChannelsComponent } from './settings/channels/channels.component';
import { ChannelListStateService } from './settings/channels/channels.state';
import { MobileAppsComponent } from './settings/mobile-apps/mobile-apps.component';
import { SettingService } from './settings/services/app.service';
import { BankSettingService } from './settings/services/banks.service';
import { ChannelSettingService } from './settings/services/channel.service';
import { FeeSettingService } from './settings/services/fee.service';
import { SuitabilityQuestionsModule } from './suitability-questions/suitability-questions.module';
import { SupportedDocumentsModule } from './supported-documents/supported-documents.module';
import { TagSharedModule } from './tags/components/tag.shared.module';
import { TagsComponent } from './tags/tags.component';
import { TransactionsTableModule } from './transactions/transactions-table/transactions-table.module';
import { WalletTransactionModule } from './transactions/wallet-transaction.module';
import { KycListTableModule } from './user-management/kyc/components/kyc-list-table/kyc-list-table.module';
import { KYCListStateService } from './user-management/kyc/kyc-list-state';
import { KycComponent } from './user-management/kyc/kyc.component';
import { UsersKycService } from './user-management/kyc/kyc.service';
import { ViewsComponent } from './views.component';
import { WithdrawalStateService } from './withdrawals/withdrawals-edit/withdrawal.state';
import { WithdrawalEditComponent } from './withdrawals/withdrawals-edit/withdrawals-edit.component';
import { WithdrawalListStateService } from './withdrawals/withdrawals-state';
import { WithdrawalsComponent } from './withdrawals/withdrawals.component';
import { RejectionEmailSettingsComponent } from './settings/rejection-email-settings/rejection-email-settings.component';
import { EditRejectionReasonComponent } from './settings/rejection-email-settings/components/edit-rejection-reason/edit-rejection-reason.component';
import { SendRejectionEmailComponent } from './settings/rejection-email-settings/components/send-rejection-email/send-rejection-email.component';

@NgModule({
  declarations: [
    ViewsComponent,
    KycComponent,
    InstrumentComponent,
    InstrumentEditComponent,
    TagsComponent,
    ReconciliationsComponent,
    ExchangesComponent,
    CollectionsComponent,
    NewsComponent,
    LearnComponent,
    ExchangeRatesComponent,
    RolesComponent,
    ApplicationSettingComponent,
    HolidayMaintenanceComponent,
    CollectionsEditComponent,
    ExchangesEditComponent,
    ExchangeRatesComponent,
    ExchangeRateEditComponent,
    MobileAppsComponent,
    RolesEditorComponent,
    RolesComponent,
    NewsEditComponent,
    WithdrawalsComponent,
    PermissionEditorComponent,
    WithdrawalEditComponent,
    BlogComponent,
    BlogEditComponent,
    RequeryInstrumentComponent,
    RoleAttachmentComponent,
    AuthUsersComponent,
    AppSettingEditComponent,
    BankEditComponent,
    BanksComponent,
    ChannelEditComponent,
    ChannelsComponent,
    MobileAppsComponent,
    RejectionEmailSettingsComponent,
    EditRejectionReasonComponent,
    SendRejectionEmailComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    InstrumentSharedModule,
    CollectionSharedModule,
    LearnSharedModule,
    ApiModule,
    NewsSharedModule,
    BlogSharedModule,
    TagSharedModule,
    NgxSpinnerModule,
    TransactionsTableModule,
    NgMultiSelectDropDownModule,
    NgxSmartModalModule,
    // UserManagemmentModule,
    SearchBarModule,
    KycListTableModule,
    WalletTransactionModule,
    DwHistoryTableModule,
    DwHistoryModule,
    PaginationModule,
    OrderTxnModule,
    PaymentTxnModule,
    MerchantModule,
    UserManagementModule,
    PdfViewerModule,
    TreeModule,
    DwAccountModule,
    SharedModule,
    ChakaAccountsModule,
    ReferralSettingsModule,
    SuitabilityQuestionsModule,
    RedemptionModule,
    AutoRebalanceModule,
    MandateModule,
    AccountManagerModule,
    AuditLogModule,
    AdvisoryPortfolioModule,
    CscsReportModule,
    SupportedDocumentsModule,
  ],
  exports: [ViewsComponent, RouterModule],
  providers: [
    InstrumentService,
    InstrumentListStateService,
    InstrumentSymbolsStateService,
    CollectionsService,
    CollectionListStateService,
    CollectionEditorStateService,
    LearnService,
    LearnContentListStateService,
    NewsService,
    NewsListStateService,
    TagService,
    InstrumentTagListStateService,
    ExchangeListStateService,
    ExchangesService,
    ExchangeStateService,
    ExchangeRateListStateService,
    ExchangeRateStateService,
    RoleListStateService,
    RoleService,
    RoleCreatorStateService,
    EditedRoleStateService,
    UsersKycService,
    KYCListStateService,
    NewsEditorStateService,
    WithdrawalListStateService,
    ReconciliationListStateService,
    ReconciliationService,
    PermissionService,
    WithdrawalStateService,
    BlogService,
    BlogListStateService,
    InstrumentStateService,
    SimilarInstrumentListStateService,
    InstrumentGraphStateService,
    FeeSettingService,
    ChannelSettingService,
    BankSettingService,
    SettingService,
    ChannelListStateService,
    BankListStateService,
    AppSettingStateService,
    AppSettingListStateService,
    BankStateService,
    ChannelStateService,
  ],
})
export class ViewsModule {}
