<div class="d-flex flex-column-fluid">
  <div class="container">
    <div class="card card-custom" *ngIf="mandate else noData">
      <div class="card-header">
        <div class="card-toolbar w-100 justify-content-between">
          <div>
            <button
              (click)="deleteMandate(mandate?.id)"
              class="btn btn-danger border"
              [disabled]="loading">
              <i [ngClass]="loading && currentClicked ==='DELETE' ? 'fas fa-spinner fa-spin': 'fas fa-trash'"></i> Delete
            </button>
          </div>

          <button
            (click)="back()"
            class="btn btn-transparent border">
            <i class="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div class="card-body">
        <lib-messages></lib-messages>
        <div class="row">
          <div class="col-12">
            <div class="details-pane">
              <p class="details-pane__content">
                <span>Customer Name:</span>
                <strong>
                  <a (click)="gotUserProfile(mandate?.userId)" class="pretty-link">
                    {{mandate?.customerName || '_'}}
                  </a></strong>
              </p>

              <p class="details-pane__content">
                <span>Customer Card ID:</span>
                <strong>{{ mandate?.customerCardId || '_' }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Customer Email:</span>
                <strong>{{ mandate?.customerEmail || '_' }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Customer Phone:</span>
                <strong>{{ mandate?.customerPhone || '_' }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Currency:</span>
                <strong>{{mandate?.currency || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Amount:</span>
                <strong>{{mandate?.amount | number: "1.2-4" || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Fee:</span>
                <strong>{{ mandate?.fee || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Frequency:</span>
                <strong>{{ mandate?.frequency || '_'}}</strong>
              </p>


              <p class="details-pane__content">
                <span>Notification Type:</span>
                <strong>{{ mandate?.notificationType || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Product Type:</span>
                <strong>{{ mandate?.productType || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Source:</span>
                <strong>{{ mandate?.source || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Status:</span>
                <strong>{{ mandate?.status || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Created By:</span>
                <strong>{{ mandate?.createdBy || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Modified By:</span>
                <strong>{{ mandate?.modifiedBy || '_'}}</strong>
              </p>


              <p class="details-pane__content">
                <span>Start Date:</span>
                <strong>{{ mandate?.startDate || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>End Date:</span>
                <strong>{{ mandate?.endDate | date:'medium' || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Date Created:</span>
                <strong>{{ mandate?.dateCreated | date:'medium' || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Date Modified:</span>
                <strong>{{ mandate?.dateModified | date:'medium' || '_'}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Comment:</span>
                <strong>{{ mandate?.comment || '_'}}</strong>
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noData>
      <div class="card card-custom" *ngIf="!loading">
        <div class="card-body">
          <lib-messages></lib-messages>

          <div
            class="no-data-container d-flex flex-column justify-content-center align-items-center">
            <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
            <p class="no-data-text">No data</p>

            <div>
              <button
                class="btn btn-primary mr-3"
                (click)="retrieveMandate();">Retry</button>
              <button
                (click)="back()"
                class="btn btn-transparent border">
                <i class="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div>

      </div>
    </ng-template>

  </div>
</div>
