<div class="card-deck">
  <div *ngIf="news" class="card">
    <img [src]="news.imageUrl" class="card-img-top" alt="..." style="height:200px !important;">
    <div class="card-body">
      <h5
        (click)="btnClick.emit(news)"
        class="card-title"
        style="cursor: pointer;">{{ news.title.slice(0, 29) }}..</h5>

      <p class="card-text">{{ news.summary.slice(0, 38) }}..</p>
      <a (click)="btnClick.emit(news)" class="btn btn-primary text-light">Edit news</a>
    </div>
  </div>
</div>
