import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  ChakaAPIError,
  ReqSuccessResponse,
} from '../../../../../../api/src/public-api';
import { ConsoleRouterService } from '../../../../../../shared/src/lib/services/console-router.service';
import { BankSetting } from '../../services/settings.interface';
import { BankSettingService } from '../../services/banks.service';

export interface BankState {
  bank?: BankSetting;
  loading: boolean;
  error?: string;
  message?: string;
}

const initalState: BankState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class BankStateService {
  state = new BehaviorSubject<BankState>(initalState);

  constructor(
    private svc: BankSettingService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private console: ConsoleRouterService
  ) {}

  create(setting: Partial<BankSetting>) {
    this.loading();
    this.svc.create(setting).subscribe({
      next: this.saveBank.bind(this),
      error: this.onBankError.bind(this),
    });
  }

  retrieve(settingId: number) {
    this.loading();
    this.svc
      .retrieve(settingId)
      .pipe(first())
      .subscribe({
        next: this.updateBank.bind(this),
        error: this.onBankError.bind(this),
      });
  }

  update(setting: Partial<BankSetting>) {
    this.loading();
    this.svc.update(setting).subscribe({
      next: this.updateSavedBank.bind(this),
      error: this.onBankError.bind(this),
    });
  }

  delete(settingId: number) {
    this.loading();
    this.svc.remove(settingId).subscribe({
      next: this.deleteBank.bind(this),
      error: this.onBankError.bind(this),
    });
  }

  resetState() {
    this.state.next(initalState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
      // status: CrudState.Initial,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateBank(data: ReqSuccessResponse<BankSetting>) {
    this.state.next({
      loading: false,
      bank: data.data,
      message: 'Loading Successful',
    });
    this.spinner.hide();
  }

  private saveBank(data: ReqSuccessResponse<BankSetting>) {
    this.state.next({
      loading: false,
      bank: data.data,
      message: 'Loading Successful',
    });
    this.toastr.success('Bank Created Successfully', 'Success', {
      positionClass: 'toast-bottom-center',
      timeOut: 3000,
    });
    this.spinner.hide();
  }

  private updateSavedBank(data: ReqSuccessResponse<BankSetting>) {
    this.state.next({
      loading: false,
      bank: data.data,
      message: 'Bank Updated Successfully',
    });
    this.toastr.success('Bank Updated Successfully', 'Success', {
      positionClass: 'toast-bottom-center',
      timeOut: 3000,
    });
    this.spinner.hide();
    this.console.productNavigate(['banks']);
  }

  private deleteBank(data: ReqSuccessResponse<BankSetting>) {
    this.state.next({
      loading: false,
      bank: data.data,
      message: 'Bank Deleted Successfully',
    });
    this.toastr.success('Bank Deleted Successfully', 'Success', {
      positionClass: 'toast-bottom-center',
      timeOut: 3000,
    });
    this.spinner.hide();
    this.console.productNavigate(['banks']);
  }

  private onBankError(res: ChakaAPIError) {
    this.state.next({
      loading: false,
      error: res.error.message,
      // status: CrudState.Error,
    });
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading',
    });
  }
}
