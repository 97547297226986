/**
 * UserComponentsModule
 * This module, contains the re-usable components for the users
 * management module
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileCardComponent } from './user-profile-card/user-profile-card.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UserTableComponent } from './user-table/user-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMultiSortModule } from 'ngx-mat-multi-sort';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatMultiSortModule
  ],
  exports: [
    UserProfileCardComponent,
    UsersListComponent,
    UserTableComponent
  ],
  declarations: [
    UserProfileCardComponent,
    UsersListComponent,
    UserTableComponent
  ]
})
export class UserComponentsModule { }
