export enum KYC_STATUS_ENUM {
  UPLOADED_DOCUMENTS = 'UPLOADED_DOCUMENTS',
  VERIFIED = 'VERIFIED',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  PENDING_REVALIDATION = 'PENDING_REVALIDATION',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED_PHOTO_ID = 'REJECTED_PHOTO_ID',
  REJECTED_ADDRESS_PROOF = 'REJECTED_ADDRESS_PROOF',
  FAILED_CSCS_TOKEN = 'FAILED_CSCS_TOKEN',
  SUBMITTED = 'SUBMITTED'
}
