import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { HttpService } from "../../http/http.service";
import {
  EnterpriseAPIService,
  ReqSuccessResponse,
  PaginatedList,
} from '../../../../../api/src/public-api';

import {
  WalletTxnsModel,
  WalletTxnDetailsModel,
  ExchangeRateModel,
  DwTransaction,
} from '../services/transactions.model';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import {
  IAdvisoryDailyFees,
} from '../../advisory-fees/services/advisory-fees.model';

const WALLETS = '/api/v1/wallets';
const API_TRANSACTIONS = 'api/v1/wallets/transactions';
const EXCHANGE_TRANSACTIONS = 'api/v1/wallets/exchanges';
const API_RATES = 'api/v1/rates';
const WITHDRAWALS = 'api/v1/withdrawals';
const FEES = 'api/v1/fees';

@Injectable()
export class WalletTxnService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  getWalletTxns(searchParams?) {
    const walletTransactions$ = this.http.get<PaginatedList<WalletTxnsModel>>(
      API_TRANSACTIONS,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(walletTransactions$);
  }

  getUserExchanges(searchParams?) {
    return this.http.get<PaginatedList<WalletTxnsModel>>(
      EXCHANGE_TRANSACTIONS,
      searchParams
    );
  }

  getUserWalletTxns(searchParams?) {
    return this.http.get<PaginatedList<WalletTxnsModel>>(
      API_TRANSACTIONS,
      searchParams
    );
  }

  getDwHistory(searchParams?): Observable<PaginatedList<DwTransaction>> {
    const url = WALLETS + `/dw-wallet-history`;
    const dwHistory$ = this.http.get<PaginatedList<DwTransaction>>(
      url,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(dwHistory$);
  }

  requeryTxnById(id: number): Observable<ReqSuccessResponse<WalletTxnsModel>> {
    const url = API_TRANSACTIONS + `/${id}`;
    return this.http.get<ReqSuccessResponse<WalletTxnsModel>>(url);
  }

  getWalletTxnById(
    id: number
  ): Observable<ReqSuccessResponse<WalletTxnsModel>> {
    const url = API_TRANSACTIONS + `/${id}`;
    const transaction$ =
      this.http.get<ReqSuccessResponse<WalletTxnsModel>>(url);

    return this.loadingService.showLoaderUntilCompleted(transaction$);
  }

  updateWalletTxn(
    content: WalletTxnsModel
  ): Observable<ReqSuccessResponse<WalletTxnsModel>> {
    return this.http.put<ReqSuccessResponse<WalletTxnsModel>>(
      API_TRANSACTIONS,
      content
    );
  }

  createWalletTxn(
    content: WalletTxnDetailsModel
  ): Observable<ReqSuccessResponse<WalletTxnsModel>> {
    return this.http.post<ReqSuccessResponse<WalletTxnsModel>>(
      API_TRANSACTIONS,
      content
    );
  }

  deleteWalletTxn(id: number): Observable<ReqSuccessResponse<WalletTxnsModel>> {
    const url = API_TRANSACTIONS + `/${id}`;
    return this.http.delete<ReqSuccessResponse<WalletTxnsModel>>(url);
  }

  getRates(searchParams?) {
    return this.http.get<PaginatedList<ExchangeRateModel>>(
      API_RATES,
      searchParams
    );
  }

  getRateById(id: number): Observable<ReqSuccessResponse<ExchangeRateModel>> {
    const url = `${API_RATES}/${id}`;
    return this.http.get<ReqSuccessResponse<ExchangeRateModel>>(url);
  }

  createRate(rate: {
    buy: number;
    sell: number;
  }): Observable<ReqSuccessResponse<ExchangeRateModel>> {
    return this.http.post<ReqSuccessResponse<ExchangeRateModel>>(
      API_RATES,
      rate
    );
  }

  getWithdrawals(searchParams?) {
    const getWithdrawals$ = this.http.get<PaginatedList<WalletTxnsModel>>(
      WITHDRAWALS,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(getWithdrawals$);
  }

  getWithdrawalTxnById(withdrawalId: string) {
    return this.http.get<ReqSuccessResponse<WalletTxnsModel>>(
      `${WITHDRAWALS}/${withdrawalId}`
    );
  }

  approve(transactionId: string) {
    return this.http.put<ReqSuccessResponse<WalletTxnsModel>>(
      `${WITHDRAWALS}/approval`,
      { transactionId }
    );
  }

  batchApprove(
    transactionIds: { transactionId: number }[]
  ): Observable<ReqSuccessResponse<WalletTxnsModel[]>> {
    const url = `${WITHDRAWALS}/approve_multiple`;

    return this.http.put<ReqSuccessResponse<WalletTxnsModel[]>>(url, {
      withdrawals: transactionIds,
    });
  }

  deleteTransaction(transactionId: number, reason: string): any {
    const url = `${WALLETS}/transaction/${transactionId}`;

    return this.http.delete(url, {}, { reason });
  }

  getAdvisoryDailyFees(userId, searchParams?) {
    const getWithdrawals$ = this.http.get<PaginatedList<IAdvisoryDailyFees>>(
      FEES + '/user_advisory_daily_fees/' + userId,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(getWithdrawals$);
  }
}
