import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[trimmedInput]'
})
export class TrimmedInputDirective {
  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { }

  private isTrimmed(s) {
    return !/(^\s)|(\s$)/.test(s)
  }

  @HostListener('blur')
  @HostListener('paste')
  @HostListener('onpaste')
  handleEvent(): void {
    let value = this.elRef.nativeElement.value;

    if (this.isTrimmed(value)) {
      return;
    }

    this.renderer.setProperty(
      this.elRef.nativeElement,
      'value',
      value.trim()
    );
  }
}
