<div class="d-flex flex-wrap mr-3">
  <ul class="pagination">
    <li
      (click)="emit(0)"
      class="paginate_button border-0 page-item previous pr-2 disabled"
      id="dataTable_previous">
      <a
        aria-controls="dataTable"
        data-dt-idx="0" tabindex="0"
        class="page-link border-0">
        <i
          class="fas fa-angle-double-left"
          style="color: #93a2dd;"></i></a>
    </li>

    <li
      (click)="emit(currentPage - 1 < 0 ? 1 : currentPage - 1)"
      class="paginate_button border-0 page-item previous pr-2 disabled"
      id="dataTable_previous">
      <a
        aria-controls="dataTable"
        data-dt-idx="0"
        tabindex="0"
        class="page-link border-0">
        <i class="fas fa-angle-left"></i></a>
    </li>

    <li
      *ngFor="let pageIndex of paginatedIndexes"
      [class]="pageIndex !== currentPage ? 
                                'paginate_button page-item pr-2': 
                                'paginate_button page-item active pr-2'">
      <a
        (click)="emit(pageIndex)"
        aria-controls="dataTable"
        data-dt-idx="1"
        tabindex="0" class="page-link border-0">{{ pageIndex }}</a>
    </li>

    <li (click)="emit(((currentPage + 1) > lastPage) ? 
                          lastPage : 
                          currentPage + 1)"
      class="paginate_button border-0 page-item previous pr-2 disabled"
      id="dataTable_previous">
      <a
        aria-controls="dataTable"
        data-dt-idx="0"
        tabindex="0"
        class="page-link border-0">
        <i class="fas fa-angle-right"></i></a>
    </li>

    <li
      (click)="emit(lastPage)"
      class="paginate_button border-0 page-item previous pr-2 disabled"
      id="dataTable_previous">
      <a
        aria-controls="dataTable"
        data-dt-idx="0"
        tabindex="0"
        class="page-link border-0">
        <i class="fas fa-angle-double-right" style="color: #93a2dd;"></i></a>
    </li>
  </ul>
</div>
