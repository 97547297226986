<!--begin::Card-->
<div class="card card-custom gutter-b">
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">Payment List</h3>
    </div>

    <div class="card-toolbar">
      <!-- <a href="javascript:;" (click)="downloadFile(lastQuery)" class="btn btn-primary font-weight-bold">
            <i class="far fa-arrow-alt-circle-down"></i> Download</a> -->

      <!-- <a *ngIf="selectedPayments.length > 0" href="javascript:;" (click)="deletePayments(lastQuery)" class="btn btn-danger font-weight-bold ml-3">
            <i class="far fa-trash-alt"></i> Delete</a> -->

      <a *ngIf="selectedPayments.length > 0" href="javascript:;" (click)="batchApprove(selectedPayments)" class="btn btn-success font-weight-bold ml-3">
        <i class="far fa-check-circle"></i> Authorize</a>
    </div>
  </div>

  <div class="card-body">
    <app-payments-table
      (batch)="selectedPayments = $event"
      (query)="query($event)"
      (payment)="viewPayment($event?.id)"
      [userId]="userId"></app-payments-table>
  </div>

</div>
