import { Injectable } from '@angular/core';
import {
  PaginatedList,
  ReqSuccessResponse
} from '../../../../../api/src/lib/api.interface';
import { EnterpriseAuthService } from '../../../../../api/src/lib/auth-api.service';
import { Permission } from './roles.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private http: EnterpriseAuthService) {}

  rolePermission(roleId: number) {
    return this.http.get<PaginatedList<Permission[]>>(
      `api/v1/roles/${roleId}/permissions`,
      null,
    );
  }

  permissions() {
    return this.http.get<PaginatedList<Permission[]>>(
      `api/v1/permissions`,
      null,
    );
  }


  create(permission: Partial<Permission>) {
    return this.http.post<ReqSuccessResponse<Permission>>(
      `api/v1/permissions`,
      permission,
      null
    );
  }

  update(permission: Partial<Permission>) {
    return this.http.put<ReqSuccessResponse<Permission>>(
      `api/v1/permissions`,
      permission,
      null,
    );
  }
}
