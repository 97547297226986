import { BlogGridComponent } from './blog-grid/blog-grid.component';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BlogCardComponent,
    BlogGridComponent

  ],
  imports: [
    CommonModule
  ],
  exports: [BlogCardComponent
, BlogGridComponent
,RouterModule],
})
export class BlogSharedModule {}
