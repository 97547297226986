import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PEPDetails } from '../pep-status.interface';
import { KYCFormControl } from '../kyc-form-control';
import { AddressForm } from '../user-address-form/address-form.interface';
@Component({
  selector: 'lib-user-pep-status-form',
  templateUrl: './user-pep-status-form.component.html',
  styleUrls: ['./user-pep-status-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPepStatusFormComponent extends KYCFormControl<AddressForm> implements OnInit {
  @Input() private pepDetail: PEPDetails;

  pepForm: FormGroup;

  politicallyExposed = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  constructor(private fb: FormBuilder) {
    super();
  }

  private createForm(details: Partial<PEPDetails> = {}) {
    return this.fb.group({
      pepNames: [details.pepNames || ''],
      pepStatus: [!!details.pepStatus],
    });
  }

  ngOnInit(): void {
    this.pepForm = this.createForm(this.pepDetail);
    if (this.disabled) {
      this.pepForm.disable()
    }
    this.listen(this.pepForm);
  }
}
