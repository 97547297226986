import { Component, Input, OnInit } from '@angular/core';
import { InstrumentModel } from '../../models/instrument.interface';
import { formatToCurrency } from '../../../../../../shared/src/lib/utils/formatter';

@Component({
  selector: 'app-stock-market-banner',
  templateUrl: './stock-market-banner.component.html',
  styleUrls: ['./stock-market-banner.component.scss']
})
export class StockMarketBannerComponent implements OnInit {

  @Input() instrument: InstrumentModel;
  @Input() change = 0;
  @Input() changePercent = 0;
  @Input() currentPrice = 0;
  @Input() intervalText = 'Today';

  constructor() { }

  get marketTitle() {
    if (!this.instrument) { return '-'; }
    return `${this.instrument?.name || ''} (${this.instrument?.symbol || '-'})`;
  }

  get changeText() {
    const currency = this.instrument?.currency;

    const change = this.change || this.instrument?.fundamentalDataModel?.change;
    if (!change) { return formatToCurrency(currency, 0); }
    return `${(((change || 0) < 0) ? '' : '+')}${formatToCurrency(currency, change)}`;
  }

  get changePercentText() {
    const change = this.change || this.instrument?.fundamentalDataModel?.change;
    if (change) { return '+0%'; }
    return `${(change < 0 ? '' : '+')}${change || 0}%`;
  }

  get changeColor() {
    const change = this.change || this.instrument?.fundamentalDataModel?.change;
    if (change) { return 'postive'; }
    return `${(change < 0 ? 'negative' : 'positive')}`;
  }

  get price() {
    const price = this.instrument?.marketPrice || this.currentPrice;
    if (!price) { return '-'; }
    return `${formatToCurrency(this.instrument.currency, price)}`;
  }

  get priceColor() {
    const price = this.currentPrice || this.instrument?.marketPrice;
    if (!price) { return 'postive'; }
    return `${(price < 0 ? 'negative' : 'positive')}`;
  }

  get image() {
    return `https://chaka-storage.s3-eu-west-1.amazonaws.com/enterprise/images/global/${this.instrument.symbol}.png`;
  }


  ngOnInit() {
  }

}
