<div class="row permission-row">
	<div class="col-md-12">
		<h4> {{ entity }} </h4>
		<div *ngFor="let action of actions" class="row permission-row">
			<div class="col-md-8">{{ action.action }}</div>
			<div class="col-md-4">
				<input
        type="checkbox"
				[disabled]="disabled"
				(change)="onChange()"
				[(ngModel)]="permissionIdMap[action.id]"
				/>
			</div>
		</div>
	</div>
</div>
