import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { NotificationService } from '@console/shared/services/notification-service';
import { Currency } from '@console/shared/_enums/currency.enum';
import { OpenOrdersModel } from '@console/user-managements';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountSummary } from '../../../models/positions.interface';
import { UserAssetService } from '../../../services/portfolio.service';

export interface Portfoliotate {
  account?: AccountSummary;
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: Portfoliotate = { loading: false };

@Injectable({ providedIn: 'root' })
export class PortfolioStateService {
  state = new BehaviorSubject<Portfoliotate>(initialState);

  private notificationId;

  constructor(
    private portfolioSvc: UserAssetService,
    private notificationService: NotificationService
  ) { }

  retrieve(userId: string, currency: Currency = Currency.USD): void {
    this.loading();
    this.portfolioSvc.getPositions(userId, currency).subscribe({
      next: this.onUserPortfolioLoaded.bind(this),
      error: this.onUserPortfolioError.bind(this),
    });
  }

  resetState(): void {
    this.state.next(initialState);
  }

  resetError(): void {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  symbolOpenOrders$(symbol: string) {
    return this.state
      .pipe(map((state) => state.account?.orders ?? []))
      .pipe(map((orders) => orders.filter((o) => o.symbol === symbol)));
  }

  symbolEquity$(symbol: string) {
    return this.state
      .pipe(map((state) => state.account?.equity?.equityPositions ?? []))
      .pipe(map((equities) => equities.filter((eq) => eq.symbol === symbol)))
      .pipe(map(([equity]) => equity));
  }

  equityShares$(symbol: string): Observable<number> {
    return this.symbolEquity$(symbol).pipe(
      map((equity) => equity?.availableForTradingQty ?? 0)
    );
  }

  get loading$(): Observable<boolean> {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(): void {
    this.state.next({
      ...this.state.getValue(),
      loading: true
    });
  }

  private onUserPortfolioLoaded({ data }: ReqSuccessResponse<AccountSummary>): void {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      account: data,
    });

    !!this.notificationId && this.notificationService.remove(this.notificationId);

    this.notificationId = this.notificationService.success('Successfully retrieved user portfolio');
  }

  private onUserPortfolioError(res: ChakaAPIError): void {
    const error = cleanChakaAPIError(res);

    this.state.next({
      loading: false,
      error
    });


    !!this.notificationId && this.notificationService.remove(this.notificationId);

    this.notificationId = this.notificationService.success(error);
  }

}
