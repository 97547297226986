<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card">
    <div class="card-header bg-white">
      <span class="card-header-title-text">Setting </span>
      <span class="float-right">
        <span>
          <a
            href="javascript:;"
            (click)="goBack()"
            class="btn btn-sm btn-transparent border mr-3"><i class="fas fa-arrow-left"></i> Back</a>
        </span>

        <!-- <span *ngIf="settingId">
          <a
            (click)="deleteSetting()"
            class="btn btn-sm btn-danger text-light border-0 mr-3"><i class="fas fa-trash-alt"></i> Delete</a>
        </span> -->
      </span>
    </div>

    <div class="card-body">


      <div class="row">
        <div class="col-md-7 mx-auto">

          <lib-messages></lib-messages>

          <form [formGroup]="settingForm" (ngSubmit)="saveSetting()">
            <div class="form-group">
              <label>Name
                <span *ngIf="formMethods.isRequired('name')" class="text-danger">*</span></label>
              <input class="form-control" formControlName="name" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('name')">
                <div
                  *ngIf="formMethods.getControl('name').hasError('required')">
                  Required input.
                </div>
              </small>
            </div>

            <div class="form-group">
              <ng-container> </ng-container>
              <label class="d-flex justify-content-between">
                <span>Value
                  <span
                    *ngIf="formMethods.isRequired('value')"
                    class="text-danger">*</span></span>

                <div class="radio-inline font-sm">
                  <label class="radio radio-sm">
                    <input
                      type="radio"
                      [formControl]="valueTypeControl"
                      [value]="valueTypesEnum.TEXT" />
                    <span></span>Text</label>

                  <label class="radio radio-sm">
                    <input
                      type="radio"
                      [formControl]="valueTypeControl"
                      [value]="valueTypesEnum.SWITCH" />
                    <span></span>Switch</label>
                </div>
              </label>

              <ng-container [ngSwitch]="valueTypeControl.value">
                <span *ngSwitchCase="valueTypesEnum.SWITCH" class="switch">
                  <label>
                    <input type="checkbox" formControlName="value" />
                    <span></span>
                  </label>
                </span>

                <input
                  *ngSwitchDefault
                  class="form-control"
                  formControlName="value" />
              </ng-container>

              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('value')">
                <div
                  *ngIf="formMethods.getControl('value').hasError('required')">
                  Required input.
                </div>
              </small>
            </div>

            <div class="form-group">
              <label>Settings Group
                <span
                  *ngIf="formMethods.isRequired('settingsGroup')"
                  class="text-danger">*</span></label>
              <input class="form-control" formControlName="settingsGroup" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('settingsGroup')">
                <div
                  *ngIf="
                    formMethods.getControl('settingsGroup').hasError('required')
                  ">
                  Required input.
                </div>
              </small>
            </div>

            <div class="form-group row">
              <label class="col-3 col-form-label">Encrypted?</label>
              <div class="col-3">
                <span class="switch">
                  <label>
                    <input
                      type="checkbox"
                      class="form-control"
                      formControlName="encrypted" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="form-group">
              <label>Description
                <span
                  *ngIf="formMethods.isRequired('description')"
                  class="text-danger">*</span></label>
              <textarea
                class="form-control"
                formControlName="description"></textarea>
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('description')">
                <div
                  *ngIf="
                    formMethods.getControl('description').hasError('required')
                  ">
                  Required input.
                </div>
              </small>
            </div>

            <div class="form-group text-center">
              <button type="submit" class="btn btn-primary" [disabled]="saving">
                <i *ngIf="saving" class="fa fa-spinner fa-spin"></i>
                {{ saving ? "Saving" : "Save" }}
              </button>
            </div>

            <!--

            <button
              *ngIf="settingId"
              type="button"
              (click)="deleteSetting()"
              class="btn btn-danger"
              [disabled]="deleting"><i *ngIf="deleting" class="fa fa-spinner fa-spin"></i> {{deleting? 'Deleting':'Delete'}}</button> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
