import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { flatMap } from 'lodash';
import { KYCFormControl } from '../kyc-form-control';
import { AddressForm } from './address-form.interface';
import { NigeriaPreset } from './nigeria.states';
import { CountryModel } from '../../../../models/user.model';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

const names = Object.entries(NigeriaPreset).map(([key, val]) => {
  return val.lgas.map((l) => ({ label: l, value: `${l}--${key}` }));
});

@Component({
  selector: 'lib-user-address-form',
  templateUrl: './user-address-form.component.html',
  styleUrls: ['./user-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAddressFormComponent
  extends KYCFormControl<AddressForm>
  implements OnInit {

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @Input() address?: AddressForm;
  @Input() countries: CountryModel[] = [{ code: 'NGA', name: 'Nigeria' }];

  addressForm: FormGroup;

  localGovts = [...flatMap(names)].sort();

  options = {}

  constructor(private fb: FormBuilder) {
    super();
  }

  public handleAddressChange(address: Address) {
    this.addressForm.get('address').setValue(address.name);
    this.addressForm.get('city').setValue(address.vicinity);
  }

  private createForm(details: Partial<AddressForm> = {}) {
    return this.fb.group({
      city: [details.city || ''],
      address: [details.address || ''],
      country: [details.country || ''],
      lg: [details.lg || ''],
      postalCode: [details.postalCode || ''],
    });
  }

  ngOnInit(): void {
    this.addressForm = this.createForm(this.address);
    if (this.disabled) {
      this.addressForm.disable();
    }

    this.listen(this.addressForm, (form) => {
      const lg = (form.lg || '').split('--');
      (form as any).state = lg[lg.length - 1];
      return form;
    });
  }
}
