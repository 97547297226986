import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ConsoleRouterService } from '@console/shared';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { finalize } from 'rxjs/operators';
import { EmailSettingsService } from '../services/email-settings.service';
import { EmailSetting } from '../services/settings.interface';
import { EditRejectionReasonComponent } from './components/edit-rejection-reason/edit-rejection-reason.component';
import { EmailSettingsStateService } from './email-settings.state.service';

@Component({
  selector: 'app-rejection-email-settings',
  templateUrl: './rejection-email-settings.component.html',
  styleUrls: ['./rejection-email-settings.component.scss']
})
export class RejectionEmailSettingsComponent implements OnInit {

  loading = false;

  dataSource: EmailSetting[] = [];

  displayedColumns: string[] = ['status', 'sendgridTemplateId', 'reasonForRejection', 'action'];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  count = 0;

  private filter: Record<string, any> = {};

  constructor(
    private emailSettingsService: EmailSettingsService,
    private emailSettingsStateService: EmailSettingsStateService,
    private console: ConsoleRouterService,
    private messagesService: MessagesService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getRejectionEmails();
  }

  getRejectionEmails(params?: object): void {
    this.loading = true;
    let qParams = {};

    if(!!params) {
      qParams = params;
    }

    this.emailSettingsService.getRejectionEmails(qParams)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      ).subscribe(
        resp => {
          console.log(resp);
          this.dataSource = resp.data;
          this.count = resp.count;
        }
      );
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.getRejectionEmails(this.filter);
  }

  // navigate() {
  //   this.console.productNavigate(['rejection-email-settings', 'create']);
  // }

  // navigateToEditPage(rowId: number) {
  //   this.console.productNavigate(['rejection-email-settings', rowId]);
  // }

  deleteRejectionReason(reasonId: number): void {
    const check = confirm('Do you want to delete this rejection reason?');

    if (!check) {
      return;
    }

    this.emailSettingsService
      .deleteRejectionReason(reasonId)
      .subscribe({
        next: () => {
          this.dataSource = this.dataSource
            .filter(({ id }) => id !== reasonId);
        }
      });
  }

  openDialog(rejectionReason?: EmailSetting): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'custom-dialog-container';

    const data: { rejectionReason: EmailSetting } = {
      rejectionReason
    };

    if (rejectionReason) {
      data.rejectionReason = { ...rejectionReason };
    }

    dialogConfig.data = data;

    this.messagesService.hideAll();

    const dialogRef = this.dialog
      .open(EditRejectionReasonComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(
        (result: boolean) => {
          result && this.getRejectionEmails();
        });
  }
}
