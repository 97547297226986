import { Injectable } from '@angular/core';
import { EnterpriseAPIService, ReqSuccessResponse } from '@console/api';
import { Bank } from './kyc-util.interface';

@Injectable({ providedIn: 'root' })
export class KYCUtilService {
  constructor(private client: EnterpriseAPIService) {}

  banks() {
    return this.client.get<ReqSuccessResponse<Bank[]>>(`api/v1/banks`, { pageSize: 20000 });
  }
}
