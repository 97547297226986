import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
} from '@angular/core';
import { UserFilesState, UserFileUpload } from './documents.interface';
import { Output } from '@angular/core';

@Component({
  selector: 'lib-user-files',
  templateUrl: './user-files.component.html',
  styleUrls: ['./user-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFilesComponent implements OnInit {
  @Input() userId: string;
  @Input() files: UserFilesState = {
    addressProofUrl: { loading: false },
    profileImageUrl: { loading: false },
    photoIdUrl: { loading: false },
  };

  @Output() upload = new EventEmitter<UserFileUpload>();

  constructor() {}

  ngOnInit(): void {}
}
