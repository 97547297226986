import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ClientUserModel } from '../../models/user.model';

@Component({
  selector: "kt-user-profile-card",
  templateUrl: "./user-profile-card.component.html",
  styleUrls: ["./user-profile-card.component.scss"],
})
export class UserProfileCardComponent implements OnInit {
  @Input() profile: ClientUserModel;

  @Output() view = new EventEmitter<ClientUserModel>();

  constructor() { }

  ngOnInit() { }

  onView() {
    this.view.emit(this.profile);
  }

}
