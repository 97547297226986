<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <ngx-spinner bdColor="#4e73df66" size="medium" color="#fff" type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">{{(reconciliationList.state|async)?.message}}</p>
    </ngx-spinner>
    <div class="card-header bg-light py-3">
      <h6 class="m-0 font-weight-bold text-primary">Bank Reconciliation</h6>
    </div>
    <div class="card-body">
      <div class="">
        <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">
          <div class="row mt-3 mb-3">

            <div class="col-sm-12 col-md-12 mb-3">
              <input (keyup)="handleQuery('q', $event.target.value)" type="search"
                class="form-control border-top-0 border-left-0 border-right-0 border-primary"
                placeholder="Search By debit" id="generalSearch">
              <span class="kt-input-icon__icon kt-input-icon__icon--left">
                <span><i class="la la-search"></i></span>
              </span>
            </div>
            <div  class="col-sm-12 col-md-4 mb-3">
              <div id="dataTable_filter" class="dataTables_filter">
                <select (change)="handleQuery('bankId', $event.target.value)" id="inputState" class="form-control border-top-0 border-left-0 border-right-0 border-primary">
                  <option
                  [value]="bank.id"
                  *ngFor="let bank of (banks.state | async).banks"
                  >{{ bank.name }}
                </option>
                </select>
              </div>
            </div>
            <div  class="col-sm-12 col-md-4 mb-3">
              <div id="dataTable_filter" class="dataTables_filter">
                <input
                  (change)="handleQuery('from', $event.target.value, true)"
                  type="date"
                  class="form-control border-top-0 border-left-0 border-right-0 border-primary"
                  placeholder="Start Date"
                  id="generalSearch"
                />
              </div>
              <div class="kt-input-icon kt-input-icon--left"></div>
            </div>
            <div  class="col-sm-12 col-md-4 mb-3">
              <div id="dataTable_filter" class="dataTables_filter">
                <input
                  (chnage)="handleQuery('to', $event.target.value, true)"
                  type="date"
                  class="form-control border-top-0 border-left-0 border-right-0 border-primary"
                  placeholder="End Date"
                  id="generalSearch"
                />
              </div>
              <div class="kt-input-icon kt-input-icon--left"></div>
            </div>

          </div>
          <div class="row mt-5 mb-5 d-none d-md-block">
            <div class="col-sm-12 table-responsive">
              <table class="table table-hover">
                <thead class="border-top-light">
                  <tr style="border-top: 0px !important;">
                    <!-- <th scope="col">
                      <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      </div>
                    </th> -->
                    <th scope="col" class="table-title">Bank Name</th>
                    <!-- <th scope="col" class="table-title">Amount</th> -->
                    <th scope="col" class="table-title">Debit</th>
                    <th scope="col" class="table-title">Credit</th>
                    <th scope="col" class="table-title">Date</th>
                    <th scope="col" class="table-title">Reference</th>
                    <th scope="col" class="table-title">Branch</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let reconciliation of (reconciliationList.state | async ).reconciliations">
                    <!-- <td scope="row">
                      <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      </div>
                    </td> -->
                    <td class="table-text">{{reconciliation?.bankName || "-"}}</td>
                    <!-- <td class="table-text">{{reconciliation?.amount |currency:"NGN" || "-"}}</td> -->
                    <td> <a href="javascript:void(0)">{{ reconciliation?.debit ? formatToCurrency('NGN', reconciliation.debit) : "-"}}</a> </td>
                    <td class="table-text">{{ reconciliation.credit ? formatToCurrency('NGN', reconciliation.credit) : '_'}}</td>
                    <td class="table-text text-danger">{{reconciliation?.transDate |date:'medium' || "-"}}</td>
                    <td class=" table-text text-primary">{{reconciliation?.reference  || "-"}}</td>
                    <td class=" table-text text-primary">{{reconciliation?.originatingBranch  || "-"}}</td>

                    <!-- <td class="table-text px-4" (click)="viewreconciliation(reconciliation?.id)"><i
                      class="fas fa-location-arrow text-primary fa-lg"></i></td> -->
                  </tr>
                </tbody>
              </table>
              <p *ngIf="(reconciliationList.state|async)?.count == 0" class="text-center text-primary  mt-3">
                  No Reconciliation Records to display
              </p>
            </div>
          </div>
          <div class="row  mb-3 d-sm-block d-md-none">
            <div class="col-lg-12 mt-3">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        <div class="form-group form-check">
                          <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        </div>
                      </th>
                      <th scope="col">Type</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="mb-0" *ngFor="let  reconciliation of (reconciliationList.state | async ).reconciliations">

                    <tr class="accordion-toggle collapsed" id="accordion3" data-toggle="collapse"
                      data-parent="#accordion3" href="#collapseThree">
                      <td class="expand-button"></td>
                      <td>
                        <div class="form-group form-check">
                          <input type="checkbox" class="form-check-input"
                            style="background-color: #ccc !important; color: #6b7188 !important;" id="exampleCheck1">
                        </div>
                      </td>
                      <td><i class="fas fa-circle kt-badge--primary"></i> <small
                          class="kt-font-primary px-2">{{reconciliation.bankName}}</small></td>
                      <td><i class="fas fa-cog flaticon2 px-2"></i><i class="fas fa-file flaticon2 px-2"></i><i
                          class="fas fa-times flaticon2 px-2"></i></td>

                    </tr>
                    <tr class="hide-table-padding ">
                      <td></td>
                      <td colspan=" 4">
                        <div id="collapseThree" class="collapse in p-3">
                          <div class="row">
                            <div class="col-sm-10 px-3">
                              <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="mobile-table-list font-weight-bold">Bank Name</span>
                                  <span>{{reconciliation.bankName}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="mobile-table-list font-weight-bold">Amount</span>
                                  <span>{{reconciliation?.amount |currency:"NGN" || "-"}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="mobile-table-list font-weight-bold">Debit</span>
                                  <span>{{reconciliation?.debit || "-"}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="mobile-table-list font-weight-bold">Credit</span>
                                  <span>{{reconciliation.credit || "-"}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="mobile-table-list font-weight-bold">Date</span>
                                  <span>{{reconciliation?.dateCreated || "-"}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="mobile-table-list font-weight-bold">Reference</span>
                                  <span class="shaddow"
                                    style="border-radius: 1rem; background-color: #5867dd; font-size: 0.8rem; color: #fff;">{{reconciliation?.reference  || "-"}}</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  <span class="mobile-table-list font-weight-bold">Branch</span>
                                  <span class="shaddow"
                                    style="border-radius: 1rem; background-color: #5867dd; font-size: 0.8rem; color: #fff;">{{reconciliation?.originatingBranch  || "-"}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </div>
          <lib-pagination (paginate)="paginate($event)" [count]="count" [pageSize]="pageSize"></lib-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
