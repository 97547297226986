import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@console/shared';
import { PaginationModule } from '../../../../../../../_shared/Pagination/pagination.module';
import { StatementsTableComponent } from './statements-table.component';

@NgModule({
  imports: [CommonModule, PaginationModule, SharedModule],
  declarations: [StatementsTableComponent],
  exports: [StatementsTableComponent],
})
export class StatementsTableModule { }
