import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LearnContent, LearnContentDetails } from './../services/learn.model';
import { EnterpriseAPIService, ReqSuccessResponse, PaginatedList } from '../../../../../../../projects/api/src/public-api';


const API_LEARN = 'api/v1/learn';

@Injectable()
export class LearnService {
  constructor(private http: EnterpriseAPIService) { }

  getLearnContent(searchParams?) {
    return this.http.get<PaginatedList<LearnContent>>(API_LEARN, searchParams);
  }

  getLearnContentById(id: number): Observable<ReqSuccessResponse<LearnContent>> {
    const url = API_LEARN + `/${id}`;
    return this.http.get<ReqSuccessResponse<LearnContent>>(url);
  }

  updateLearnContent(
    content: LearnContent
  ): Observable<ReqSuccessResponse<LearnContent>> {
    return this.http.put<ReqSuccessResponse<LearnContent>>(API_LEARN, content);
  }

  createLearnContent(
    content: LearnContentDetails
  ): Observable<ReqSuccessResponse<LearnContent>> {
    return this.http.post<ReqSuccessResponse<LearnContent>>(API_LEARN, content);
  }

  deleteLearnContent(id: number): Observable<ReqSuccessResponse<LearnContent>> {
    const url = API_LEARN + `/${id}`;
    return this.http.delete<ReqSuccessResponse<LearnContent>>(url);
  }
}
