<div class="container-fluid container-fluid-margin chaka-route-content">

  <div class="card shadow mb-4">
    <ngx-spinner
      [name]="exchangeState.spinnerName"
      bdColor="#4e73df66"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">
        {{ (exchangeState.state | async)?.message }}
      </p>
    </ngx-spinner>
    <div class="card-header bg-light py-3">
      <a
        [routerLink]="['../']"
        class="btn btn-secondary float-right"
        matTooltip="Back to the users list"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Back</span>
      </a>
    </div>

    <div class="kt-form px-4 py-4">
      <div class="kt-form">
        <div class="kt-portlet__body">
          <div class="kt-portlet__body-progress">
            <!-- <mat-spinner [diameter]="20"></mat-spinner> -->
          </div>
          <div class="kt-form__section kt-form__section--first">
            <div
              class="form-group kt-form__group row"
              *ngIf="rate$ | async as rate"
            >
              <div class="col-md-6 col-sm-6 kt-margin-bottom-20">
                <p class="text-primary">Created By</p>
                <h5>{{ rate.createdBy || "-" }}</h5>
              </div>
              <div class="col-md-6 col-sm-6 kt-margin-bottom-20">
                <p class="text-primary">Buy Rate</p>
                <h5>{{ rate.buy || "-" }}</h5>
              </div>
              <div class="col-md-6 col-sm-6 kt-margin-bottom-20">
                <p class="text-primary">Sell Rate</p>
                <h5>{{ rate.sell || "-" }}</h5>
              </div>
              <div class="col-md-6 col-sm-6 kt-margin-bottom-20">
                <p class="text-primary">Date Created</p>
                <h5>{{ rate.dateCreated || "-" | date: "medium" }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

