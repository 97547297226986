<ng-container *ngIf="drawer.opened">
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Setup Question</span>
      </h3>
      <div class="card-toolbar">
        <button class="close" mat-button (click)="closeDrawer()">X</button>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">

      <div #scrollDiv style="height:700px; overflow: auto;" class="scrolling-div">
        <lib-messages></lib-messages>

        <mat-horizontal-stepper linear #stepper>
          <mat-step [stepControl]="questionForm">
            <ng-template matStepLabel>Question Settings</ng-template>

            <form [formGroup]="questionForm">

              <div class="form-group">
                <label>Category <span class="text-danger">*</span></label>
                <ng-select formControlName="category" [appendTo]="'body'">
                  <ng-option
                    [value]="category"
                    *ngFor="let category of categories">
                    {{ category }}
                  </ng-option>
                </ng-select>
                <small
                  class="form-text text-danger"
                  *ngIf="!questionForm.get('category').valid && 
                          questionForm.get('category').touched">
                  <div *ngIf="questionForm.get('category').hasError('required')">Required input</div>
                </small>
              </div>

              <div class="form-group">
                <label>Question <span class="text-danger">*</span></label>
                <textarea
                  class="form-control"
                  rows="3"
                  formControlName="question">
              </textarea>
                <small
                  class="form-text text-danger"
                  *ngIf="!questionForm.get('question').valid && 
                                    questionForm.get('question').touched">
                  <div *ngIf="questionForm.get('question').hasError('required')">Required input</div>
                </small>
              </div>

              <div class="form-group">
                <label>Subject Name <span class="text-danger">*</span></label>
                <input
                  class="form-control"
                  formControlName="subjectName">
                <small
                  class="form-text text-danger"
                  *ngIf="!questionForm.get('subjectName').valid && 
                                    questionForm.get('subjectName').touched">
                  <div *ngIf="questionForm.get('subjectName').hasError('required')">Required input</div>
                </small>
              </div>

            </form>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Options</ng-template>

            <form [formGroup]="questionOptionsForm">
              <div
                class="question-form-wrapper"
                formArrayName="questionOptions">
                <div
                  class="row"
                  *ngFor="let questionOption of questionsOptionsControls; 
                          let i = index"
                  [formGroupName]="i">
                  <div class="col-12">
                    <div class="control-column">
                      <div class="form-group">
                        <label>Text <span class="text-danger">*</span></label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          formControlName="text">
                        <small
                          class="form-text text-danger"
                          *ngIf="!questionOption.get('text').valid && 
                                            questionOption.get('text').touched">
                          <div *ngIf="questionOption.get('text').hasError('required')">Required input</div>
                        </small>
                      </div>
                    </div>

                    <div class="control-column">
                      <div class="form-group">
                        <label>Weight <span class="text-danger">*</span></label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          formControlName="weight">

                        <small
                          class="form-text text-danger"
                          *ngIf="!questionOption.get('weight').valid && questionOption.get('weight').touched">
                          <div *ngIf="questionOption.get('weight').hasError('required')">Required input</div>
                        </small>
                      </div>
                    </div>

                    <div class="control-column d-flex align-items-center">
                      <p *ngIf="i === (questionsOptionsControls.length - 1)">
                        <a
                          href="javascript:;"
                          class="btn btn-icon btn-light btn-circle btn-xs mr-2"
                          (click)="onAddQuestion()">
                          <i class="fas fa-plus"></i>
                        </a>

                        <a
                          *ngIf="this.questionsOptionsControls.length > 1"
                          href="javascript:;"
                          class="btn btn-icon btn-light-danger btn-circle btn-xs"
                          (click)="onRemoveQuestion()">
                          <i class="fas fa-minus"></i>
                        </a>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </div>

    </div>
    <!--end::Body-->

    <div class="card-footer d-flex justify-content-between">
      <button
        *ngIf="stepper.selectedIndex === 0"
        type="button"
        class="btn btn-light"
        (click)="nextStep()">Next</button>

      <button
        *ngIf="stepper.selectedIndex === 1"
        type="button"
        class="btn btn-light"
        (click)="backStep()">Back</button>

      <button
        type="button"
        class="btn btn-primary"
        (click)="onSave()">Save</button>
    </div>
  </div>


</ng-container>
