import { Injectable } from '@angular/core';
import { RedemptionStatusEnum } from '@console/shared/_enums/RedemptionStatus.enum';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { LoadingService } from '../../../../../shared/src/lib/components/loading/loading.service';

import { IRedemption } from '../interface/IRedemption';

const REDEMPTION_URL = '/api/v1/redemption';

@Injectable()
export class RedemptionService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  fetchRedemptions(searchParams?): Observable<PaginatedList<IRedemption>> {
    const redemptions$ = this.http.get<PaginatedList<IRedemption>>(
      REDEMPTION_URL,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(redemptions$);
  }

  fetchRedemptionById(id: string): Observable<ReqSuccessResponse<IRedemption>> {
    const redemption$ = this.http.get<ReqSuccessResponse<IRedemption>>(
      `${REDEMPTION_URL}/${id}`
    );

    return this.loadingService.showLoaderUntilCompleted(redemption$);
  }

  requeryRedemptionById(
    id: string
  ): Observable<ReqSuccessResponse<IRedemption>> {
    const redemption$ = this.http.get<ReqSuccessResponse<IRedemption>>(
      `${REDEMPTION_URL}/status/${id}`
    );

    return this.loadingService.showLoaderUntilCompleted(redemption$);
  }

  batchApproveOrReject(payload: {
    redemptionIDs: string[];
    status:
      | RedemptionStatusEnum.RIA_APPROVED
      | RedemptionStatusEnum.RIA_REJECTED;
  }) {
    const redemption$ = this.http.put<ReqSuccessResponse<IRedemption>>(
      `${REDEMPTION_URL}`,
      payload
    );

    return this.loadingService.showLoaderUntilCompleted(redemption$);
  }
}
