<div class="card">
  <div class="card-body">
    <div class="text-center">

      <img
      class=" mb-0"
      style="max-width: 10em !important; height: 6em !important; margin-top: 24px;"
      [src]="formatImgUrl(instrument)"
      alt="image"
      />
    </div>
    <div>
      <a (click)="onView()" class="text-center instrument-title">{{ cleanName(instrument) }}</a>
    </div>
    <div></div>
    <div class="text-center instrument-btn">
      <span (click)="onView()" class="btn btn-label-primary btn-sm"><a style="text-decoration: none !important; cursor: pointer;" href="javascript:void(0)">{{ instrument.symbol }}</a>  </span>
    </div>
  </div>
</div>
