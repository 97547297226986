import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConsoleRouterService } from '@console/shared';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SupportedDocumentDialogComponent } from './components/supported-document-dialog/supported-document-dialog.component';
import { ISupportedDocument } from './interface/ISupportedDocuments';
import { SupportedDocumentsService } from './service/support-documents.service';
@Component({
  templateUrl: './supported-documents.component.html',
  styleUrls: ['./supported-documents.component.scss'],
})
export class SupportedDocumentsComponent implements OnInit {
  supportedDocuments: ISupportedDocument[];

  componentDestroyed$: Subject<boolean> = new Subject();

  loading = false;

  constructor(
    private supportedDocumentService: SupportedDocumentsService,
    private dialog: MatDialog,
    private consoleRouter: ConsoleRouterService
  ) {}

  ngOnInit(): void {
    this.fetchSupportedDocuments();
  }

  fetchSupportedDocuments(): void {
    this.loading = true;

    this.supportedDocumentService
      .fetchSupportedDocuments(true)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (response) => {
          this.supportedDocuments = response.data;
        },
      });
  }

  deleteSupportedDocument(accountManagerId: number): void {
    const check = confirm('Do you want to delete this supported document?');

    if (!check) {
      return;
    }

    this.supportedDocumentService
      .deleteSupportedDocument(accountManagerId)
      .subscribe({
        next: () => {
          this.fetchSupportedDocuments();
        },
      });
  }

  openDialog(selectedDocument?: ISupportedDocument): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.panelClass = 'custom-dialog-container';

    if (selectedDocument) {
      dialogConfig.data = { ...selectedDocument };
    }

    const dialogRef = this.dialog.open(
      SupportedDocumentDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: boolean) => {
      result && this.fetchSupportedDocuments();
    });
  }
}
