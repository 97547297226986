import { MandateDetailsComponent } from './components/mandate-details/mandate-details.component';
import { MandateListStateService } from './state/mandate-list-state.service';
import { MandateDetailsStateService } from './state/mandate-details-state.service';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MandateListComponent } from './mandate-list.component';
import { MandateService } from './services/mandate.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/src/public-api';
import { MandateEditDrawerComponent } from './components/mandate-edit/mandate-edit.component';

@NgModule({
  declarations: [
    MandateListComponent,
    MandateDetailsComponent,
    MandateEditDrawerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    MandateService,
    MandateListStateService,
    MandateDetailsStateService
  ],
  exports: [
    MandateListComponent
  ]

})
export class MandateModule { }
