import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  EnterpriseAPIService,
  ReqSuccessResponse,
} from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ISupportedDocument } from '../interface/ISupportedDocuments';

const SUPPORTED_DOCUMENTS_URL = 'api/v1/kyc_document_types';

@Injectable()
export class SupportedDocumentsService {
  constructor(
    private messagesService: MessagesService,
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  public fetchSupportedDocuments(
    showLoader: boolean = false
  ): Observable<ReqSuccessResponse<ISupportedDocument[]>> {
    const supportedDocuments$ = this.http.get<
      ReqSuccessResponse<ISupportedDocument[]>
    >(SUPPORTED_DOCUMENTS_URL);

    const supportedDocumentsObs$ = showLoader
      ? this.loadingService.showLoaderUntilCompleted(supportedDocuments$)
      : supportedDocuments$;

    return supportedDocumentsObs$.pipe(
      catchError((error: ChakaAPIError) => {
        this.handleError(error);
        return throwError(error);
      })
    );
  }

  public saveSupportedDocument(
    supportedDocument: Partial<ISupportedDocument>
  ): Observable<ReqSuccessResponse<ISupportedDocument>> {
    const isUpdate = !!supportedDocument.id;

    const saveAccount$ = isUpdate
      ? this.http.put<ReqSuccessResponse<ISupportedDocument>>(
          `${SUPPORTED_DOCUMENTS_URL}/`,
          supportedDocument
        )
      : this.http.post<ReqSuccessResponse<ISupportedDocument>>(
          `${SUPPORTED_DOCUMENTS_URL}/`,
          supportedDocument
        );

    return saveAccount$.pipe(
      // we handle error in the child component instance
      catchError((error) => throwError(error)),
      map((response) => {
        // We show the success message on the parent component instance and return the observable
        this.messagesService.open(
          MessagesEnum.success,
          `Successfully ${isUpdate ? 'updated' : 'added'} supported document`,
          { hideAll: true }
        );

        return response;
      })
    );
  }

  public deleteSupportedDocument(
    supportedDocumentId: number
  ): Observable<ReqSuccessResponse<boolean>> {
    const { id } = this.messagesService.open(
      MessagesEnum.loading,
      'Deleting supported document...',
      { hideAll: true }
    );

    return this.http
      .delete<ReqSuccessResponse<boolean>>(
        `${SUPPORTED_DOCUMENTS_URL}/delete/${supportedDocumentId}`
      )
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.messagesService.hide(id);
          this.handleError(error);
          return throwError(error);
        }),
        tap((response: ReqSuccessResponse<boolean>) => {
          this.messagesService.update({
            type: MessagesEnum.success,
            message: 'Supported document deleted successfully.',
          });

          return response;
        })
      );
  }

  private handleError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);

    this.messagesService.open(MessagesEnum.danger, message);
  }
}
