import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ColorScheme } from '../config.interface';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  option: any;

  @Input() data: ChartData[] = [];

  constructor() { }


  createChart(data: ChartData[]) {
    console.log(data);
    const option: any = {
      color: [ColorScheme.green, ColorScheme.pink, ColorScheme.blue],
      angleAxis: {
        show: false
      },
      grid: {
        left: '0%',
        top: '0%',
        right: '0%',
      },
      legend: {
        left: 'right',
        top: 'center',
        orient: 'vertical',

      },
      radiusAxis: {
          show: false,
          type: 'category',
          data: ['周一', '周二', '周三', '周四'],
          z: 10
      },
      polar: {
      },
      tooltip: {
          show: true
      },
      series: data.map((d) => ({
        type: 'bar',
        data: d.values,
        coordinateSystem: 'polar',
        name: d.title,
        stack: 'a'
    }))
  };

  option.xAxis = [
    {
      show: false,
        type: 'category',
        axisTick: {show: false},
        data: ['2012', '2013', '2014', '2015', '2016']
    }
];

option.yAxis = [
    {
      show: false,
        type: 'value'
    }
];

  return option;
  }

  ngOnInit() {
    this.option = this.createChart(this.data);
  }

}
