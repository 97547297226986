import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@console/authentication/guards/auth.guards';
import { LoggedInGuard } from '@console/authentication/guards/logged-in.guards';
import { getProductTypeFromStorage } from '@console/shared/utils/productFromStorage';
import { PRODUCT_TYPE_ENUM } from '@console/shared/_enums';
import { UserManagementModule } from '@console/user-managements';
import { DashboardComponent } from './dashboard/dashboard.component';
import { advisoryRoutes } from './routes/advisory.routes';
import { equityRoutes } from './routes/equity.routes';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    canActivate: [LoggedInGuard],
    path: 'auth',
    loadChildren: () =>
      import(
        '../../projects/authentication/src/lib/authentication.module'
      ).then((m) => m.AuthenticationModule),
  },
  {
    canActivate: [AuthGuard],
    path: PRODUCT_TYPE_ENUM.equity,
    component: DashboardComponent,
    children: equityRoutes,
  },
  {
    canActivate: [AuthGuard],
    path: PRODUCT_TYPE_ENUM.advisory,
    component: DashboardComponent,
    children: advisoryRoutes,
  },
  { path: '**', redirectTo: getProductTypeFromStorage(), pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), UserManagementModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
