import { EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { exportAndDownload, fileNameFormatter } from 'projects/_shared/csv-downloader/csv-downloader';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { MerchantListState, MerchantListStateService } from '../../merchants/merchant-list/merchant-list.state';
import { MerchantsModel } from '../../merchants/merchants.interface';
import { DwTransaction } from '../../transactions/services/transactions.model';
import { DwHistoryListState, DwHistoryStateService } from '../dw-history-list/dw-history.state';

@Component({
  selector: 'app-dw-history-table',
  templateUrl: './dw-history-table.component.html',
  styleUrls: ['./dw-history-table.component.scss'],
  providers: [MerchantListStateService]
})
export class DwHistoryTableComponent implements OnInit, OnDestroy {

  constructor(
    private dwHistoryList: DwHistoryStateService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService
  ) { }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  get selectedDwHistory(): DwTransaction[] {
    return Array.from(this.selectedItems.values());
  }
  /**
   * show date filter
   */
  @Input() userId: string;



  /**
   * Query emitter to parent component
   */
  @Output() query = new EventEmitter<object>();

  /**
   * emit query selected from the table
   */
  @Output() transaction = new EventEmitter<DwTransaction>();

  formatToCurrency = formatToCurrency;

  // from here
  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'finTranID',
    'accountAmount',
    'accountBalance',
    'comment',
    'tranWhen'
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  private filter: Record<string, any> = {};

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  loading = false;

  count = 0;

  @ViewChild(MatSort) sort: MatSort;

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[],
    loading: boolean,
    total: number,
    pageSize: number,
    pageNumber: number
  } = {
      list: [],
      loading: false,
      total: 0,
      pageSize: 1000,
      pageNumber: 0
    };

  getUUid = (() => {
    let id = 0;
    return () => ++id;
  })();

  ngOnInit(): void {
    // this.filter.pageSize = this.pageSize

    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl('')
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues)
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.dwHistoryList.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: DwHistoryListState) => {
          const modifiedDwHistory = state.transactions
            .map((item) => ({ ...item, id: this.getUUid() }));

          this.dataSource = new MatTableDataSource<DwTransaction>(modifiedDwHistory);
          this.dataSource.sort = this.sort;
          this.count = state.count;
          this.loading = state.loading;
        }
      );
  }

  listenOnAuthProfile(): void {
    this.authProfile.isSuperAdmin$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((isSuperAdmin) => isSuperAdmin)
      )
      .subscribe((isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;

        this.isSuperAdmin ?
          this.merchantListState.find({
            pageSize: this.merchants.pageSize,
            pageNumber: this.merchants.pageNumber
          }) :
          null;
      });

  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe(
        (state: MerchantListState) => {
          this.merchants.loading = state?.loading;
          this.merchants.total = state?.count;
          this.merchants.list = [...this.merchants.list, ...state?.merchants];
        }
      );
  }

  paginate($event: PageEvent): void {

    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.query.emit(this.filter);
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach(dwHistory => {
      this.updateCheckedSet(checked, dwHistory);
    });
  }

  onItemChecked(checked: boolean, dwHistory: DwTransaction & { id: number }): void {
    this.updateCheckedSet(checked, dwHistory);
  }

  updateCheckedSet(checked: boolean, dwHistory: DwTransaction & { id: number }): void {
    if (checked) {
      this.selectedItems.set(dwHistory.id, dwHistory);
    } else {
      this.selectedItems.delete(dwHistory.id);
    }
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      this.userId ? this.userId + '_DW_History' : 'Chaka_DW_History',
    );

    exportAndDownload(name, this.selectedDwHistory);
  }

  emit(transaction: DwTransaction): void {
    this.transaction.emit(transaction);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.merchantListState.reset();
  }



}
