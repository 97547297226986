import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ConsoleRouterService } from '../../../../../../projects/shared/src/public-api';
import { LearnContentListStateService } from './learn-list.state';
import { PageEvent } from '../../../../../shared/src/lib/interface';
@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class LearnComponent implements OnInit {

  childRoute = false;

  filter: Record<string, any> = {
    pageSize: 12,
  };

  constructor(
    private router: ConsoleRouterService,
    public learnList: LearnContentListStateService
  ) { }

  search(query: string) {
    this.filter.q = query;
    this.learnList.find(this.filter);
  }

  paginate($event: PageEvent) {
    this.learnList.find({...this.filter, ...$event});
  }

  parentNavigate() {
    this.childRoute = false;
    this.learnList.find(this.filter);
  }

  navigate(id?: string) {
    if (id) {
      this.router.productNavigate(['content', 'learn', 'edit', id]);
      return;
    }
    this.router.productNavigate(['content', 'learn', 'edit']);
  }

  ngOnInit() {
    this.learnList.find(this.filter);
  }

  ngOnDestroy() {
    this.learnList.reset();
  }
}
