import { AuditLogComponent } from './audit-log.component';
import { SharedModule } from '../../../../shared/src/lib/shared.module';
import { AuditLogService } from './service/audit-log.service';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MerchantListStateService } from '../merchants/merchant-list/merchant-list.state';
import { LogDrawerComponent } from './components/log-drawer/log-drawer.component';

@NgModule({
  declarations: [
    AuditLogComponent,
    LogDrawerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    AuditLogService,
    MerchantListStateService,
    DatePipe
  ]
})

export class AuditLogModule {}