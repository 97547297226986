<div class="row">
  <!-- <ngx-spinner [color]="'#ffffff'" [name]="kyc.spinnerName" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading"> {{ (kyc.state | async).message }} </p>
  </ngx-spinner> -->
  <div class="col-md-3">
    <ul class="list-group">
      <li
        [class]="tabClass(section)"
        (click)="currentSection = section" *ngFor="let section of sections">
        {{ section }}
      </li>
    </ul>
  </div>

  <div class="col-md-9 col-sm-12">
    <div class="row">

      <div class="col-lg-12">
        <lib-user-biodata-form
          (formUpdate)="update($event)"
          [biodata]="(kyc.state | async).kyc"
          [disabled]="isFormDisable$ | async"
          *ngIf="currentSection === sections[0]"></lib-user-biodata-form>

        <lib-user-address-form
          (formUpdate)="update($event)"
          [disabled]="isFormDisable$ | async"
          [address]="(kyc.state | async).kyc"
          *ngIf="currentSection === sections[1]"></lib-user-address-form>

        <lib-user-bank-form
          [banks]="(banks.state | async).banks"
          (formUpdate)="update($event)"
          [disabled]="isFormDisable$ | async"
          [bankDetails]="(kyc.state | async).kyc"
          *ngIf="currentSection === sections[2]"></lib-user-bank-form>

        <lib-user-kin-form
          (formUpdate)="update($event)"
          [disabled]="isFormDisable$ | async"
          [NextOfKin]="(kyc.state | async).kyc"
          *ngIf="currentSection === sections[3]"></lib-user-kin-form>

        <lib-user-pep-status-form
          (formUpdate)="update($event)"
          [disabled]="isFormDisable$ | async"
          [pepDetail]="(kyc.state | async).kyc"
          *ngIf="currentSection === sections[4]"></lib-user-pep-status-form>

        <lib-user-employment-form
          (formUpdate)="update($event)"
          [disabled]="isFormDisable$ | async"
          [employmentDetails]="(kyc.state | async).kyc"
          *ngIf="currentSection === sections[5]"></lib-user-employment-form>

        <lib-user-investment-form
          (formUpdate)="update($event)"
          [disabled]="isFormDisable$ | async"
          [investmentDetails]="(kyc.state | async).kyc"
          *ngIf="currentSection === sections[6]"></lib-user-investment-form>
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 kt-margin-bottom-20-mobile">
            <button
              href="javascript:;"
              class="btn btn-primary w-100"
              (click)="save()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
