import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UserKycStateService } from './user-kyc.state';
import { ClientUserModel } from '../../models/user.model';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BankStateService } from './bank-state.service';
import { CountriesListStateService } from './countries-state';

@Component({
  selector: 'app-user-kyc-editor',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './user-kyc-editor.component.html',
  styleUrls: ['./user-kyc-editor.component.scss'],
})
export class UserKycEditorComponent implements OnInit {
  sections = [
    'Personal Biodata',
    'Address',
    'Bank Details',
    'Next of Kin',
    'PEP status',
    'Employment Information',
    'Investment Preference',
  ];

  currentSection = 'Personal Biodata';

  @Input() userId: string;

  private changes: Partial<ClientUserModel> = {}

  constructor(
    public banks: BankStateService,
    public kyc: UserKycStateService,
    private role: AuthRoleStateService,
    public countries: CountriesListStateService
  ) { }

  get isFormDisable$(): Observable<boolean> {
    return this.role.hasPermission$('USER_UPDATE')
      .pipe(map(enabled => !enabled));
  }

  tabClass(section: string): string {
    return `list-group-item ${(this.currentSection === section) ? 'active' : ''}`;
  }

  update($event: Partial<ClientUserModel>): void {
    this.changes = { ...this.changes, ...$event };
  }

  save(): void {
    this.kyc.update({ ...this.changes, userId: this.userId });
  }

  ngOnInit(): void {
    this.banks.list();
    this.countries.find({ pageSize: 2000 });
    // this.role.hasPermission$('USER_VIEW')
    //   .subscribe((authorized) => {
    //     if (authorized) {
    //       if (this.userId) {
    //         console.log('here in kyc editor')
    //         // this.kyc.retrieve(this.userId);
    //       }
    //     }
    //   });
  }

  // ngOnDestroy(): void {
  // this.kyc.resetState();
  // }
}
