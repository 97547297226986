import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITreeState, TreeComponent } from '@circlon/angular-tree-component';
import { ITreeNode } from '@circlon/angular-tree-component/lib/defs/api';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { first, map, skip, skipUntil, skipWhile } from 'rxjs/operators';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { LiteRoleDetail, TreeRole } from '../services/roles.interface';
import { EditedRoleStateService } from './edited-role.state';
import { RoleCreatorStateService } from './role-creator.state';

@Component({
  selector: 'kt-role-edit-dialog',
  templateUrl: './roles-edit.component.html',
  styleUrls: ['./roles-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RolesEditorComponent implements OnInit, OnDestroy {
  id: number;
  name = '';

  @ViewChild('tree') treeComponent: TreeComponent;

  modalId = 'role-attach-user-modal';

  state: ITreeState;

  options = {
    idField: 'id',
    useCheckbox: true,
  };

  constructor(
    private route: ActivatedRoute,
    private console: ConsoleRouterService,
    public editedState: EditedRoleStateService,
    public creatorState: RoleCreatorStateService,
    private modal: NgxSmartModalService
  ) {}

  saveRole() {
    this.creatorState
      .pickPermissionFromCreator(this.selectNodeIds(this.state))
      .pipe(first())
      .subscribe((permissionNodes) => {
        const preSavedRole: TreeRole = {
          id: this.id,
          name: this.name,
          permissionNodes,
        };

        if (preSavedRole.id) {
          this.editedState.updateRole(preSavedRole);
        } else {
          this.editedState.createRole(preSavedRole);
        }
      });
  }

  openAttachModal() {
    this.modal.open(this.modalId);

    setTimeout(() => {

      const content = document.querySelector('.nsm-content') as HTMLDivElement;
      content.style.maxWidth = '25vw';
      content.style.padding = '0%';
      // content.style.backgroundColor = 'white!important';

    }, 20);

  }

  onTreeInitalized({ node }: { node: ITreeNode }) {
    console.log(node);
    this.editedState.state.pipe(skipWhile(state => !(state.role)), first())
      .subscribe((state) => {
        this.activateNodes(state.role.permissionNodes);
      });
  }

  selectNodeIds($event: ITreeState) {
    const selectedNodes = Object.entries($event.selectedLeafNodeIds)
      .filter(([_, v]) => v)
      .map(([k]) => k);
    console.log(selectedNodes);
    return selectedNodes.map(Number);
  }


  goBack() {
    this.console.productNavigate(['roles', 'list']);
  }


  private activateNodes(permissions: LiteRoleDetail[]) {

    const tree = this.treeComponent.treeModel;

    for (const permission of permissions) {
      for (const node of permission.children) {
        this.treeComponent.treeModel.setSelectedNode(tree.getNodeById(node.id), true);
      }
    }

  }

  private localizedRole(id: number) {
    this.editedState.findById(id);
    this.editedState.state.pipe(skip(1), first()).subscribe((state) => {
      if (state.role) {
        this.name = state.role.name;
        this.id = state.role.id;
      }
    });
  }

  ngOnInit() {
    this.creatorState.retrieve();
    this.route.params
      .pipe(map((params) => params.id))
      .pipe(first())
      .subscribe((id) => {
        if (id) {
          this.localizedRole(id);
        }
      });
  }

  ngOnDestroy() {}
}
