import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MessagesEnum } from './enums/messages.enums';
import { IMessage } from './interface/IMessage';
import { MessagesService } from './messages.service';

@Component({
  selector: 'lib-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
})
export class MessagesComponent implements OnInit {
  // showMessages = false;
  messagesList$: Observable<IMessage[]>;

  messageTypes = MessagesEnum;
  constructor(public messagesService: MessagesService) {}

  resolveType(type: string): string {
    switch (type) {
      case MessagesEnum.loading:
        return 'light';

      case MessagesEnum.info:
        return 'secondary';

      default:
        return type;
    }
  }

  resolveIconType(type: string): string {
    // return type === MessagesEnum.loading ? 'dark' : 'light';

    switch (type) {
      case MessagesEnum.loading:
      case MessagesEnum.info:
        return 'dark';

      default:
        return 'light';
    }
  }

  ngOnInit(): void {
    this.messagesList$ = this.messagesService.messages$;
  }

  onClose(messageId: number): void {
    this.messagesService.hide(messageId);
  }
}
