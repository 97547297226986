import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InstrumentService } from '../services/instrument.service';
import { InstrumentModel } from '../models/instrument.interface';
import { ReqSuccessResponse, ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { RequeryInstrument } from '../services/instrument-interface';
import { NotificationService } from '@console/shared/services/notification-service';

export interface InstrumentState {
  instrument?: InstrumentModel;
  loading: boolean;
  error?: string;
  message?: string;
  deleted?: boolean;
}

const initalState = { loading: false };

@Injectable({ providedIn: 'root' })
export class InstrumentStateService {
  state = new BehaviorSubject<InstrumentState>(initalState);

  spinnerName = 'Instrument-State';

  constructor(
    private instrumentSvc: InstrumentService,
    private notificationService: NotificationService
  ) { }

  loadInstrument(symbol: string) {
    this.state.next({ ...this.state.getValue(), loading: true, message: `Loading ${symbol} details` });
    this.instrumentSvc.getInstrumentBySymbol(symbol).subscribe({
      next: this.onInstrumentLoaded.bind(this),
      error: this.onInstrumentError.bind(this)(symbol),
    });
  }

  requeryInstrument(form: RequeryInstrument) {
    this.state.next({ ...this.state.getValue(), loading: true, message: `Requerying-${form?.symbol} details` });
    this.instrumentSvc.addInstrumentBySymbol(form).subscribe({
      next: this.onInstrumentLoaded.bind(this),
      error: this.onInstrumentError.bind(this),
    });
  }

  reset() {
    this.state.next(initalState);
  }

  private onInstrumentLoaded({ data }: ReqSuccessResponse<InstrumentModel>) {
    this.state.next({ instrument: data, loading: false, message: `successfully loaded ${data.name} details.` });
  }

  private onInstrumentError(res: ChakaAPIError): void {
    const error = cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false
    });

    this.notificationService.error(error);
  }
}
