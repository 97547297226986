<div *ngIf="instruments.length > 0" class="similar-instruments">
  <h3 class="content-title"> Similar Instruments </h3>
  <kt-settings-instrument-list
  (view)="navigate.emit($event.symbol)"
  [instruments]="instruments"
>
</kt-settings-instrument-list>

</div>


