import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DwHistoryListComponent } from './dw-history-list/dw-history-list.component';
import { DwHistoryEditComponent } from './dw-history-edit/dw-history-edit.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApiModule } from '../../../../api/src/public-api';
import { DwHistoryStateService } from './dw-history-list/dw-history.state';
import { DwHistoryTableModule } from './dw-history-table/dw-history-table.module';



@NgModule({
  declarations: [DwHistoryListComponent, DwHistoryEditComponent],
  exports: [DwHistoryListComponent, DwHistoryEditComponent],
  imports: [
    CommonModule,
    ApiModule,
    NgxSpinnerModule,
    NgxSmartModalModule,
    DwHistoryTableModule
  ],
  providers: [
    DwHistoryStateService,
  ]
})
export class DwHistoryModule { }
