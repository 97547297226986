import { SuitabilityService } from './../../services/suitability-questions.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ISuitabilityResponse } from '../../interface/ISuitabilityResponse';
import {
  ISuitabilityQuestion,
  ISuitabilityQuestionOption,
} from '../../interface/ISuitabilityQuestions';
import { MatAccordion } from '@angular/material/expansion';
@Component({
  selector: 'app-suitability-response',
  templateUrl: './suitability-response.component.html',
  styleUrls: ['./suitability-response.component.scss'],
})
export class SuitabilityResponseComponent implements OnInit {
  @Input() userId: string;
  loading: boolean;
  categories: string[] = [];
  allResponses: ISuitabilityResponse[] = [];
  mappedResponse: {
    [s: string]: ISuitabilityResponse &
      { options: ISuitabilityQuestionOption[] }[];
  } = {};
  suitabilityQuestions: ISuitabilityQuestion[];

  @ViewChild(MatAccordion) accordion: MatAccordion;
  allExpanded = true;

  constructor(private suitabilityService: SuitabilityService) {}

  ngOnInit(): void {
    // this.getQuestions();
    this.fetchSuitabilityResponse();
  }

  async getQuestions(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.suitabilityService
        .fetchQuestions({})
        .toPromise();

      this.suitabilityQuestions = response.data;

      this.fetchSuitabilityResponse();
    } catch (error) {
      this.loading = false;
    }
  }

  fetchSuitabilityResponse(): void {
    this.loading = true;

    this.suitabilityService
      .fetchResponseByUserId(this.userId)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { data } = response;

          if (data?.length > 0) {
            this.allResponses = data;
            this.processResponseData(data);
          }
        },
      });
  }

  private processResponseData(data: ISuitabilityResponse[]): void {
    this.categories = Array.from(new Set(data.map(({ category }) => category)));

    const mappedResponse = {};

    this.categories.forEach((category) => {
      mappedResponse[category] = [...data].filter(
        (item) => item.category === category
      );
      // .map(item => (
      //   {
      //     ...item,
      //     options: this.suitabilityQuestions
      //       .find(({ id }) => id === item.questionId).options
      //   }));
    });

    this.mappedResponse = mappedResponse;

    setTimeout(() => {
      this.accordion?.openAll();
    }, 0);
  }

  toggleAccordion(): void {
    this.allExpanded = !this.allExpanded;

    !this.allExpanded ? this.accordion.closeAll() : this.accordion.openAll();
  }
}
