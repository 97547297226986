import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GENDER, MARITAL_STATUS } from '@console/shared';
import { KYCFormControl } from '../kyc-form-control';
import { BiodataForm } from './biodata-form.interface';

const MOBILE_PATTERN = /^[+]?[0-9]{9,15}$/;

@Component({
  selector: 'lib-user-biodata-form',
  templateUrl: './user-biodata-form.component.html',
  styleUrls: ['./user-biodata-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserBiodataFormComponent
  extends KYCFormControl<BiodataForm>
  implements OnInit, OnChanges {
  @Input() biodata?: BiodataForm;

  biodataForm: FormGroup;

  private currentUserId: string;

  genders = [
    {
      name: 'Male',
      value: GENDER.MALE,
    },
    {
      name: 'Female',
      value: GENDER.FEMALE,
    },

  ];

  maritalStatus = [
    {
      name: 'Married',
      value: MARITAL_STATUS.MARRIED,
    },
    {
      name: 'Single',
      value: MARITAL_STATUS.SINGLE,
    },
    {
      name: 'Divorced',
      value: MARITAL_STATUS.DIVORCED,
    },
    {
      name: 'Partner',
      value: MARITAL_STATUS.PARTNER,
    },
    {
      name: 'Widowed',
      value: MARITAL_STATUS.WIDOWED,
    },
  ];

  constructor(private fb: FormBuilder) {
    super();
  }

  selectChange(key: string, value: any) {
    if (this.biodataForm) {
      this.biodataForm.get('city').setValue(value);
    }
  }

  private createForm(biodata: Partial<BiodataForm> = {}) {
    this.currentUserId = biodata.userId;
    return this.fb.group({
      firstName: [biodata.firstName || ''],
      lastName: [biodata.lastName || ''],
      middleName: [biodata.middleName || ''],
      phone: [biodata.phone || '', [Validators.pattern(MOBILE_PATTERN)]],
      address: [biodata.address || ''],
      country: [biodata.country || ''],
      gender: [biodata.gender || ''],
      maritalStatus: [biodata.maritalStatus || ''],
      disclosureName: [biodata.disclosureName || ''],
      dob: [biodata.dob || ''],
    });
  }

  transformer(data: BiodataForm) {
    if (data.dob) {
      const date = new Date(data.dob);
      data.dob = `${date.getDate()}/${date.getMonth() + 1
        }/${date.getFullYear()}`;
    }
    return data;
  }

  reverseDate(inputDate) {
    const date = new Date(inputDate);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  ngOnInit(): void {
    this.biodataForm = this.createForm(this.biodata);
    if (this.disabled) {
      this.biodataForm.disable()
    }
    this.listen(this.biodataForm, this.transformer);
  }

  ngOnChanges() {
    if (this.biodata.userId !== this.currentUserId) {
      this.biodataForm = this.createForm(this.biodata);
    }
  }
}
