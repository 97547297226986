import { Injectable } from '@angular/core';
import { ReqSuccessResponse, ChakaAPIError } from '@console/api';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { InstrumentChartPayload } from '../models/instrument.interface';

import { Graph, InstrumentService } from '../services/instrument.service';
import { GraphPoint, cleanDataPoints, intervalText } from './graph-utils/graph-cleaner';

export interface GraphState {
  loading: boolean;
  graphPoints: GraphPoint[];
  pricePercentageChange?: number;
  priceChange?: number;
  currentPrice?: number;
  subtext?: string;
  error?: string;
  currency?: string;
}

@Injectable({ providedIn: 'root' })
export class InstrumentGraphStateService {
  state = new BehaviorSubject<GraphState>({ loading: false, graphPoints: [] });

  constructor(private instrumentSvc: InstrumentService) {}

  loadChart(payload: InstrumentChartPayload) {
    this.state.next({ ...this.state.getValue(), loading: true });
    this.instrumentSvc
      .getInstrumentChart(payload)
      .pipe(first())
      .subscribe({
        next: this.onChartLoaded(payload).bind(this),
        error: this.onChartError.bind(this),
      });
  }

  private onChartLoaded({ currency, interval }: InstrumentChartPayload) {
    return ({ data }: ReqSuccessResponse<Graph>) => {
      const graphPoints = cleanDataPoints(data.charts, interval);

      this.state.next({
        loading: false,
        graphPoints,
        currency,
        subtext: intervalText(interval),
        priceChange: data.derivedPrice,
        pricePercentageChange: data.derivedPricePercentage,
        currentPrice: data.currentPrice
      });
    };
  }

  private onChartError({ message }: ChakaAPIError) {
    this.state.next({ loading: false, error: message, graphPoints: [] });
  }
}
