import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { NotificationService } from '@console/shared/services/notification-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentService } from '../providers/payment-service';
import { PayoutAccount } from '../providers/wallet.interface';

export interface PayoutAccountState {
  account?: PayoutAccount;
  loading: boolean;
  error?: string;
  // message?: string;
}

const initialState: PayoutAccountState = { loading: false };

@Injectable({ providedIn: 'root' })
export class PayoutAccountStateService {
  state = new BehaviorSubject<PayoutAccountState>(initialState);

  spinnerName = 'Payout-Account-Spinner';

  constructor(
    private spinner: NgxSpinnerService,
    private payment: PaymentService,
    private notificationService: NotificationService
  ) { }

  retrive(userId: string) {
    this.loading();
    this.payment
      .accounts(userId, {})
      .pipe(map((res) => res.data[0]))
      .pipe(
        map((account: PayoutAccount) => {
          if (account) {
            return account;
          }
          throw new Error(
            `You don't have a payout account, please fill account details`
          );
        })
      )
      .subscribe({
        next: this.onUserPayoutAccountLoaded.bind(this),
        error: this.onUserPayoutAccountError.bind(this),
      });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true
    });
    this.spinner.show(this.spinnerName);
  }

  private onUserPayoutAccountLoaded(account: PayoutAccount) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      account,
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.success(`Successfully retrieved your payout account`);
  }

  private onUserPayoutAccountError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false
    });

    this.spinner.hide(this.spinnerName);
    this.notificationService.error(error);
  }
}
