<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">Auth Users List</h3>
        </div>
      </div>

      <div class="card-body">
        <app-kyc-list-table
          [authUsers]="true"
          (query)="kycState.loadAuthServer($event)"
          (kyc)="editUser($event.userId)"></app-kyc-list-table>
      </div>

    </div>

  </div>
</div>
