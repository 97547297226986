import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewChecked,
} from '@angular/core';
import { KYCData } from '@console/user-managements';
import { uniqBy } from 'lodash';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-kyc-action-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './kyc-action-modal.component.html',
  styleUrls: ['./kyc-action-modal.component.scss'],
})
export class KycActionModalComponent implements OnInit, OnDestroy {
  selected = '';

  kycList: KYCData[] = [];
  intervalId: ReturnType<typeof setTimeout>;

  constructor(private modal: NgxSmartModalService) {}

  // get kycs() {
  //   return uniqBy(this.kycList, 'userId');
  // }

  tabClass(section: string): string {
    return `list-group-item ${this.selected === section ? 'active' : ''}`;
  }

  ngOnInit(): void {
    console.log('created');

    const modalData = this.modal.getModalData('kycActionModal');

    let uniqueKyc: KYCData[] = [];

    modalData.forEach((c: KYCData) => {
      if (!uniqueKyc.includes(c)) {
        uniqueKyc.push(c);
      }
    });

    this.kycList = uniqueKyc;

    if (this.kycList.length > 0) {
      this.selected = this.kycList[0].userId;

      console.log(this.selected);

      try {
        (
          document.getElementsByClassName('overlay')[0] as HTMLElement
        ).style.left = '-50vw';
        (
          document.getElementsByClassName('nsm-body')[0] as HTMLElement
        ).style.minWidth = '95vw';
      } catch (error) {}
      // }, 2000);
    }
  }

  ngOnDestroy(): void {
    // clearInterval(this.intervalId);
    console.log('destroyed');

    // this.kycList = [];
    // this.selected = '';

    this.modal.removeModal('kycActionModal');
  }
}
