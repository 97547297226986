<section-search-header
  [section]="'Blog'"
  *ngIf="childRoute"
  [actionText]="'View All Post'"
  (click)="view()"
	(actionBtn)="navigate()"
	(onSearch)="search($event)"
	[count]="(blogList.state | async).count"
	[loading]="(blogList.state | async).loading"
></section-search-header>

<section-search-header
  [section]="'Blog'"
  *ngIf="!childRoute"
  [actionText]="'Create New Post'"
  (click)="create()"
	(actionBtn)="navigate()"
	(onSearch)="search($event)"
	[count]="(blogList.state | async).count"
	[loading]="(blogList.state | async).loading"
></section-search-header>

<div>
  <router-outlet (activate)="childRoute = true" (deactivate)="parentNavigate()"></router-outlet>
</div>

<div class="row container-fluid chaka-route-content" *ngIf="!childRoute">
  <ngx-spinner bdColor="#4e73df66" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{(blogList.state|async)?.message}}</p>
  </ngx-spinner>
  <div class="col-12">
    <kt-content-blog-grid (postClick)="navigate($event.id)" [posts]="(blogList.state | async).posts">
    </kt-content-blog-grid>
    <lib-pagination (paginate)="paginate($event)" [count]="(blogList.state | async).count"></lib-pagination>
  </div>
</div>

<!-- Create collection -->
<div *ngIf="childRoute" class="card"> 
  <div class="card-body">
    <span class="float-right">
      <span>
        <a (click)="view()" class="btn btn-md  btn-transparent border mr-3"><i class="fas fa-arrow-left"></i>
          Back</a>
      </span>
      <span>
        <a (click)="save(blogForm.value)" class="btn btn-md  btn-success border mr-3">Save</a>
      </span>
    </span>
    <form [formGroup]="blogForm">
      <div class="alert alert-primary"
      *ngIf="(blogState.state | async).error"
      type="warn"
      (close)="blogState.resetError()"
    >
    {{ (blogState.state | async).error }}
    </div>

      <div class="row border-bottom py-4">
        <div class="col">
          <input type="number" class="form-control" placeholder="Blog ID" formControlName="id">
          <small align="start" class="text-primary" style="font-size: 14px;">
            <strong>ID</strong> is required
          </small>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Title" formControlName="title">
          <small align="start" class="text-primary" style="font-size: 14px;">
            <strong>Title</strong> is required
          </small>
        </div>
        <div class="col">
          <input type="textarea" class="form-control" placeholder="category"  formControlName="category">
          <small align="start" class="text-primary" style="font-size: 14px;">
            <strong>Please</strong> enter category
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="Page url"  formControlName="pageUrl">
          <small align="start" class="text-primary" style="font-size: 14px;"
									>Please enter
									<strong>pageUrl</strong>
								</small>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Image url"  formControlName="imageUrl">
          <small align="start" class="text-primary" style="font-size: 14px;"
									>Please enter
									<strong>imageUrl</strong>
					</small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="summary"  formControlName="summary">
          <small align="start" class="text-primary" style="font-size: 14px;"
									>Please enter
									<strong>summary</strong>
								</small>
        </div>
      </div>
        <div class="row mt-3">
          <label>Content</label>
          <br>
          <angular-editor formControlName="content" [config]="editorConfig"></angular-editor>
          <!-- <input type="text" class="form-control" placeholder="Content"  formControlName="content">
          <small align="start" class="text-primary" style="font-size: 14px;"
									>Please enter
									<strong>content</strong>
					</small> -->
        </div>
    </form>
  </div>
</div>
