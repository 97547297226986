<div class="row">
  <!--begin::Col-->
  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6" *ngFor="let user of users">
    <!--begin::Card-->
    <kt-user-profile-card
      [profile]="user"
      (view)="selected.emit($event)"></kt-user-profile-card>
    <!--end::Card-->
  </div>
  <!--end::Col-->
</div>
