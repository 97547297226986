import { RedemptionStatusEnum } from './enums/RedemptionStatus.enum';
import { MatMultiSort } from 'ngx-mat-multi-sort';
import { AutoRebalanceListState, AutoRebalanceListStateService } from './state/auto-rebalance-list-state.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
// import { PageEvent } from '../../../../shared/src/lib/interface';
import { FormControl, FormGroup } from '@angular/forms';

import { filter, debounceTime, distinctUntilChanged, tap, takeUntil, map } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { MerchantListState, MerchantListStateService } from '../merchants/merchant-list/merchant-list.state';
import { IAutoRebalance } from './interface/IAutoRebalance';
import { MerchantsModel } from '../merchants/merchants.interface';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { exportAndDownload, fileNameFormatter } from 'projects/_shared/csv-downloader/csv-downloader';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'lib-auto-rebalance-list',
  templateUrl: './auto-rebalance-list.component.html',
  styleUrls: ['./auto-rebalance-list.component.scss']
})
export class AutoRebalanceListComponent implements OnInit, OnDestroy {

  filter: Record<string, any> = {
    pageSize: 10,
  };

  redemptionStatusList = [
    {
      label: 'All',
      value: ''
    },
    {
      label: 'Started',
      value: RedemptionStatusEnum.STARTED
    },
    {
      label: 'Pending',
      value: RedemptionStatusEnum.PENDING
    },
    {
      label: 'Successful',
      value: RedemptionStatusEnum.SUCCESSFUL
    },
    {
      label: 'Failed',
      value: RedemptionStatusEnum.FAILED
    },
    {
      label: 'Other',
      value: RedemptionStatusEnum.OTHER
    },
    {
      label: 'RIA Pending',
      value: RedemptionStatusEnum.RIA_PENDING
    },
    {
      label: 'RIA Approved',
      value: RedemptionStatusEnum.RIA_APPROVED
    },
    {
      label: 'RIA Rejected',
      value: RedemptionStatusEnum.RIA_REJECTED
    },
    {
      label: 'Approved',
      value: RedemptionStatusEnum.APPROVED
    },
    {
      label: 'Rejected',
      value: RedemptionStatusEnum.REJECTED
    },
    {
      label: 'On Hold',
      value: RedemptionStatusEnum.On_Hold
    }
  ];

  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'riaID',
    'moneyMovement',
    'regularRebalance',
    'reviewOnly',
    'status',
    'created'
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  loading = false;

  count = 0;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[],
    loading: boolean,
    total: number,
    pageSize: number,
    pageNumber: number
  } = {
      list: [],
      loading: false,
      total: 0,
      pageSize: 1000,
      pageNumber: 0
    };


  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private consoleRouter: ConsoleRouterService,
    private autoRebalanceState: AutoRebalanceListStateService,
    private messagesService: MessagesService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService,
  ) { }

  ngOnInit(): void {
    this.filter.pageSize = this.pageEvent.pageSize;
    this.handleQuery();

    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      q: new FormControl(''),
      status: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl()
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues)
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.autoRebalanceState.state$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: AutoRebalanceListState) => {
          this.dataSource = new MatTableDataSource<IAutoRebalance>(state.autoRebalance);
          this.dataSource.sort = this.sort;
          this.count = state.count;
          this.loading = state.loading;
        }
      );
  }

  listenOnAuthProfile(): void {
    this.authProfile.isSuperAdmin$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((isSuperAdmin) => isSuperAdmin)
      )
      .subscribe((isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;

        this.isSuperAdmin ?
          this.merchantListState.find({
            pageSize: this.merchants.pageSize,
            pageNumber: this.merchants.pageNumber
          }) :
          null;
      });

  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe(
        (state: MerchantListState) => {
          this.merchants.loading = state?.loading;
          this.merchants.total = state?.count;
          this.merchants.list = [...this.merchants.list, ...state?.merchants];
        }
      );
  }

  viewAutoRebalance(rebalanceId: string): void {
    this.consoleRouter.productNavigate(['auto-rebalance', rebalanceId]);
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.autoRebalanceState.find(this.filter);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach(withdrawal => {
      this.updateCheckedSet(checked, withdrawal);
    });
  }

  onItemChecked(checked: boolean, withdrawal: IAutoRebalance): void {
    this.updateCheckedSet(checked, withdrawal);
  }

  updateCheckedSet(checked: boolean, withdrawal: IAutoRebalance): void {
    if (checked) {
      this.selectedItems.set(withdrawal.id, withdrawal);
    } else {
      this.selectedItems.delete(withdrawal.id);
    }
  }

  get selectedRedemptions(): IAutoRebalance[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Auto-Rebalance',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedRedemptions);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();

    this.merchantListState.reset();
  }
}
