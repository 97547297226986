import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '../../../../api/src/public-api';
import { ExchangesModel, } from './services/exchanges.model';
import { ExchangesService } from './services/exchanges.service';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';

export interface ExchangesListState {
  loading: boolean;
  count?: number;
  exchanges: ExchangesModel[];
  error?: string;
  message?: string;
}

const initialState: ExchangesListState = {
  loading: false,
  exchanges: [],
};

@Injectable({ providedIn: 'root' })
export class ExchangeListStateService {
  state = new BehaviorSubject<ExchangesListState>(initialState);

  constructor(
    private exchangesSvc: ExchangesService,
    private spinner: NgxSpinnerService,
    private messagesService: MessagesService
  ) { }

  find(query: object) {
    this.loading();
    this.exchangesSvc
      .getExchanges(query)
      .pipe(first())
      .subscribe({
        next: this.updateExchanges.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateExchanges(data: PaginatedList<ExchangesModel>) {
    this.state.next({
      loading: false,
      exchanges: data.data,
      count: data.count,
      message: "Loading Successful"
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: "An Error occurred"
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: "Loading..."
    });
  }

  batchApprove(transactions: { transactionId: number }[], searchFilter, callback: (success: boolean) => void): void {
    const length = transactions.length;

    const loadingMessage = `Approving ${length} ${length <= 1 ? 'exchange' : 'exchanges'}`;

    !!this.messagesService.totalOpened && this.messagesService.hideAll();

    this.messagesService.open(MessagesEnum.loading, loadingMessage);

    this.exchangesSvc
      .batchApprove(transactions)
      .subscribe({
        next: (response) => {
          this.find(searchFilter);

          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: response.message
            });

          !!callback && callback(true);
        },
        error: (err: ChakaAPIError) => {
          this.approveFailed(err, callback);
        }
      });
  }

  private approveFailed(err: ChakaAPIError, callback: (success: boolean) => void): void {
    const message = cleanChakaAPIError(err);

    this.messagesService
      .update({ type: MessagesEnum.danger, message });

    !!callback && callback(false);
  }
}
