import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, EnterpriseAPIService, PaginatedList, ReqSuccessResponse } from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ISuitabilityQuestion } from '../interface/ISuitabilityQuestions';
import { ISuitabilityResponse } from '../interface/ISuitabilityResponse';

const SUITABILITY_URL = 'api/v1/suitability';
const SUITABILITY_CATEGORIES_URL = '/api/v1/language/enum';
const SUITABILITY_RESPONSE_URL = SUITABILITY_URL + '/response/user';

@Injectable()
export class SuitabilityService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService,
    private messagesService: MessagesService
  ) { }

  fetchCategories(): Observable<ReqSuccessResponse<string[]>> {
    const { id } = this.messagesService.open(MessagesEnum.loading, 'Fetching categories...');

    return this.http.get<ReqSuccessResponse<string>>(SUITABILITY_CATEGORIES_URL, { enumValue: 'SuitabilityCategory' })
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        ),
        map((response) => {
          return {
            ...response,
            data: JSON.parse(response.data)
          };
        }),
        finalize(() => this.messagesService.hide(id))
      );
  }

  public fetchQuestions(searchQuery: object): Observable<PaginatedList<ISuitabilityQuestion>> {

    const { id } = this.messagesService.open(MessagesEnum.loading, 'Fetching questions...', { hideAll: true });

    return this.http.get<PaginatedList<ISuitabilityQuestion>>(`${SUITABILITY_URL}/questions`, searchQuery)
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        ),
        finalize(() => this.messagesService.hide(id))
      );
  }

  saveQuestion(suitabilityQuestion: Partial<ISuitabilityQuestion>): Observable<ReqSuccessResponse<ISuitabilityQuestion>> {

    if (suitabilityQuestion.hasOwnProperty('id') && suitabilityQuestion.id) {
      return this.http.put<ReqSuccessResponse<ISuitabilityQuestion>>(SUITABILITY_URL, suitabilityQuestion);
    }

    return this.http.post<ReqSuccessResponse<ISuitabilityQuestion>>(SUITABILITY_URL, suitabilityQuestion);
  }

  deleteOption(optionId: number): Observable<ReqSuccessResponse<ISuitabilityQuestion>> {
    return this.http.delete<ReqSuccessResponse<ISuitabilityQuestion>>(SUITABILITY_URL + '/options/' + optionId);
  }

  public deleteSuitabilityQuestion(questionId: number):
    Observable<ReqSuccessResponse<boolean>> {

    const { id } = this.messagesService.open(MessagesEnum.loading, 'Deleting suitability question...', { hideAll: true });

    return this.http.delete<ReqSuccessResponse<boolean>>(`${SUITABILITY_URL}/questions/${questionId}`)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.messagesService.hide(id);
          this.handleError(error);
          return throwError(error);
        }),
        tap((response: ReqSuccessResponse<boolean>) => {
          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: 'Suitability question deleted successfully.'
            });

          return response;
        })
      );
  }

  fetchResponseByUserId(userId: string): Observable<ReqSuccessResponse<ISuitabilityResponse[]>> {
    const { id } = this.messagesService
      .open(
        MessagesEnum.loading,
        'Fetching suitability response.',
        { hideAll: true }
      );

    return this.http.get<ReqSuccessResponse<ISuitabilityResponse[]>>(`${SUITABILITY_RESPONSE_URL}/${userId}`)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error);

          return throwError(error);
        }),
        finalize(() => this.messagesService.hide(id))
      );
  }

  private handleError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);

    this.messagesService.open(MessagesEnum.danger, message, { hideAll: true });
  }
}
