<div class="row">
  <div class="col-md-3">
    <ul class="nav flex-column nav-pills">
      <li
        class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="currentIndex === tabList.WALLET ? 'active' : ''"
          (click)="changeIndex(tabList.WALLET)">
          <span class="nav-text">Wallet</span>
        </a>
      </li>

      <li
        class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="currentIndex === tabList.FUND ? 'active' : ''"
          (click)="changeIndex(tabList.FUND)">
          <span class="nav-text">Fund</span>
        </a>
      </li>

      <li
        class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="currentIndex === tabList.EXCHANGE ? 'active' : ''"
          (click)="changeIndex(tabList.EXCHANGE)">
          <span class="nav-text">Exchange</span>
        </a>
      </li>

      <li
        class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="currentIndex === tabList.WITHDRAW ? 'active' : ''"
          (click)="changeIndex(tabList.WITHDRAW)">
          <span class="nav-text">Withdraw</span>
        </a>
      </li>

      <li
        class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="currentIndex === tabList.REQUERY ? 'active' : ''"
          (click)="changeIndex(tabList.REQUERY)">
          <span class="nav-text">Requery</span>
        </a>
      </li>

      <li
        class="nav-item">
        <a
          class="nav-link"
          href="javascript:;"
          [ngClass]="currentIndex === tabList.REVERSAL ? 'active' : ''"
          (click)="changeIndex(tabList.REVERSAL)">
          <span class="nav-text">Reversal</span>
        </a>
      </li>

      <!-- 
      <button
        (click)="changeIndex(0)"
        class="button"
        [class.active]="currentIndex === 0">
        Wallet
      </button> -->
    </ul>
  </div>

  <div class="col-md-9">
    <div class="tab-content">
      <ng-container *ngIf="currentIndex === 0">
        <kt-user-accounts-wallet [userId]="userId"></kt-user-accounts-wallet>
      </ng-container>
      <ng-container *ngIf="currentIndex === 1">
        <kt-user-accounts-fund [userId]="userId"></kt-user-accounts-fund>
      </ng-container>
      <ng-container *ngIf="currentIndex === 2">
        <kt-user-accounts-exchange [userId]="userId"></kt-user-accounts-exchange>
      </ng-container>
      <ng-container *ngIf="currentIndex === 3">
        <kt-user-accounts-withdraw [userId]="userId"></kt-user-accounts-withdraw>
      </ng-container>
      <ng-container *ngIf="currentIndex === 4">
        <kt-user-accounts-requery [userId]="userId"></kt-user-accounts-requery>
      </ng-container>
      <ng-container *ngIf="currentIndex === 5">
        <app-fund-reversal [userId]="userId"></app-fund-reversal>
      </ng-container>
    </div>
  </div>
</div>
