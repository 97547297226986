import { CURRENCY } from '../../../../../shared/src/public-api';


export class InstrumentFundamentalDataModel {
  instrumentID: string;
  symbol: string;
  companyName: string;
  openPrice: number;
  bidPrice: number;
  askPrice: number;
  lowPrice: number;
  highPrice: number;
  fiftyTwoWeekLowPrice: number;
  fiftyTwoWeekHighPrice: number;
  cumulativeVolume: number;
  marketCap: number;
  peRatio: number;
  dividendYield: number;
  earningsPerShare: number;
  dividend: number;
  sharesOutstanding: number;
  timeLastUpdate: string;
  bookValuePerShare: number;
  cashFlowPerShare: number;
  operatingIncome: number;
  pbRatio: number;
  volumeMovingAverage10Day: number;
  volumeMovingAverage25Day: number;
  volumeMovingAverage50Day: number;
  priceMovingAverage50Day: number;
  priceMovingAverage150Day: number;
  priceMovingAverage200Day: number;
  roe: string;
  change: number;
  percentChange: number;
}

export class InstrumentModel {
  symbol: string;
  currency: CURRENCY;
  reutersPrimaryRic: string;
  name: string;
  description: string;
  sector: string;
  longOnly: boolean;
  marketPrice: number;
  orderSizeMax: number;
  orderSizeMin: number;
  orderSizeStep: number;
  exchangeNickelSpread: boolean;
  close: number;
  type: string;
  exchange: string;
  url: string;
  status: string;
  exchangeThresholdOverride: number;
  houseThresholdOverride: number;
  closePrior: number;
  image: string;
  id: string;
  fundamentalDataModel: InstrumentFundamentalDataModel;
}

export class InstrumentChartModel {
  minute: number;
  marketPrice: number;
  marketAverage: number;
  marketNotional: number;
  marketNumberOfTrades: number;
  marketHigh: number;
  marketLow: number;
  marketVolume: number;
  marketChangeOverTime: number;
  marketOpen: number;
  marketClose: number;
  average: number;
  notional: number;
  numberOfTrades: number;
  high: number;
  low: number;
  volume: number;
  date: string;
  open: number;
  close: number;
  unadjustedVolume: number;
  change: number;
  vwap: number;
  label: string;
  changeOverTime: number;
  simplifyFactor: string[];
  uOpen: number;
  uHigh: number;
  uLow: number;
  uClose: number;
  uVolume: number;
  changePercent: number;

}

/**
 * Positions intervals for the instrument or stock
 */
export enum PerformanceIntervals {
  Day = '1D',
  Week = '5dm',
  Month = '1M',
  Quarter = '3M',
  HalfYear = '6M',
  Year = '1Y',
  FiveYear = '5Y',
}

export class InstrumentChartPayload {
  symbol: string;
  interval: PerformanceIntervals;
  currency: CURRENCY;
}

export interface LiteInstrument {
  symbol:                 string;
  currency:               string;
  name:                   string;
  sector:                 string;
  derivedPrice:           number;
  derivedPricePercentage: number;
  currentPrice:           null;
  orderSizeMax:           number;
  marketPrice:            number;
  orderSizeMin:           number;
  orderSizeStep:          number;
  close:                  null;
  type:                   string;
  exchange:               string;
  url:                    string;
  status:                 string;
  closePrior:             number;
  image:                  string;
  countryCode:            string;
  id:                     string;
}
