import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { Currency } from '@console/shared/_enums/currency.enum';
import { UserEquityPosition } from '../../../../models/positions.interface';

@Component({
  selector: 'app-equity-postition',
  templateUrl: './equity-postition.component.html',
  styleUrls: ['./equity-postition.component.scss'],
})
export class EquityPostitionComponent implements OnInit {
  @Input() equity: UserEquityPosition;
  @Input() currency: Currency = Currency.USD;

  @Output() trade = new EventEmitter<string>();

  constructor() {}

  formatToCurrency(amount: number) {
    amount = Number(amount);

    if (!amount) {
      return '-';
    }

    return formatToCurrency(this.currency, amount);
  }

  ngOnInit() {}
}
