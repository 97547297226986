<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid" *ngIf="(kyc.state | async).kyc as kycStateInfo">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title w-100 justify-content-between">
          <h3 class="card-label">{{ kycStateInfo?.email || "Email" }}</h3>
          <div>
            <button
              (click)="goBack()"
              class="btn btn-sm btn-transparent border mr-3"
            >
              <i class="fas fa-arrow-left"></i> Back
            </button>

            <button (click)="loadKycInformation()" class="btn btn-success mr-3">
              <i class="fas fa-sync-alt"></i> Refresh
            </button>

            <button
              (click)="openHVUModal(kycStateInfo)"
              class="btn btn-sm btn-dark"
            >
              <i class="fas fa-user-tie"></i> Manage HVU
            </button>
          </div>
        </div>
        <div class="card-toolbar">
          <ul
            class="nav nav-tabs nav-tabs-line justify-content-center nav-tabs-line-3x"
          >
            <li class="nav-item">
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="this.currentTabIndex === tabList.KYC ? 'active' : ''"
                (click)="setTab(tabList.KYC)"
              >
                <span class="nav-icon">
                  <i class="fas fa-user-tag"></i>
                </span>
                <span class="nav-text">KYC Profile</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="hasPermissionFor$('WALLET_VIEW') | async"
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.WALLET ? 'active' : ''
                "
                (click)="setTab(tabList.WALLET)"
              >
                <span class="nav-icon">
                  <i class="fas fa-wallet"></i>
                </span>
                <span class="nav-text">Wallet</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="hasPermissionFor$('PORTFOLIO_VIEW') | async"
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.PORTFOLIO ? 'active' : ''
                "
                (click)="setTab(tabList.PORTFOLIO)"
              >
                <span class="nav-icon">
                  <i class="fas fa-briefcase"></i>
                </span>
                <span class="nav-text">Portfolio</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="router.isDisabledIn(['advisory'])">
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.CSCS ? 'active' : ''
                "
                (click)="setTab(tabList.CSCS)"
              >
                <span class="nav-icon">
                  <i class="fas fa-briefcase"></i>
                </span>
                <span class="nav-text">CSCS</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="
                (hasPermissionFor$('ORDER_VIEW') | async) &&
                router.isDisabledIn(['advisory'])
              "
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.ORDERS ? 'active' : ''
                "
                (click)="setTab(tabList.ORDERS)"
              >
                <span class="nav-icon">
                  <i class="fas fa-shopping-cart"></i>
                </span>
                <span class="nav-text">Orders</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="router.isDisabledIn(['equity'])">
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.REDEMPTION ? 'active' : ''
                "
                (click)="setTab(tabList.REDEMPTION)"
              >
                <span class="nav-icon">
                  <i class="fas fa-receipt"></i>
                </span>
                <span class="nav-text">Redemptions</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.MANDATE ? 'active' : ''
                "
                (click)="setTab(tabList.MANDATE)"
              >
                <span class="nav-icon">
                  <i class="fas fa-money-check"></i>
                </span>
                <span class="nav-text">Recurring Payments</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="hasPermissionFor$('PAYMENT_VIEW') | async"
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.PAYMENTS ? 'active' : ''
                "
                (click)="setTab(tabList.PAYMENTS)"
              >
                <span class="nav-icon">
                  <i class="fas fa-hand-holding-usd"></i>
                </span>
                <span class="nav-text">Payments</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="hasPermissionFor$('WALLET_VIEW') | async"
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.TRANSACTIONS ? 'active' : ''
                "
                (click)="setTab(tabList.TRANSACTIONS)"
              >
                <span class="nav-icon">
                  <i class="fas fa-coins"></i>
                </span>
                <span class="nav-text">Transactions</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="hasPermissionFor$('WALLET_VIEW') | async"
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.DW_HISTORY ? 'active' : ''
                "
                (click)="setTab(tabList.DW_HISTORY)"
              >
                <span class="nav-icon">
                  <i class="fas fa-coins"></i>
                </span>
                <span class="nav-text">DW History</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="hasPermissionFor$('WALLET_VIEW') | async"
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.STATEMENTS ? 'active' : ''
                "
                (click)="setTab(tabList.STATEMENTS)"
              >
                <span class="nav-icon">
                  <i class="fas fa-coins"></i>
                </span>
                <span class="nav-text">Statements</span>
              </a>
            </li>

            <li
              class="nav-item"
              *ngIf="hasPermissionFor$('KYC_UPDATE') | async"
            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.VERIFICATION ? 'active' : ''
                "
                (click)="setTab(tabList.VERIFICATION)"
              >
                <span class="nav-icon">
                  <i class="fas fa-user-check"></i>
                </span>
                <span class="nav-text">Verification</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="router.isDisabledIn(['equity'])">
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.SUITABILITY_RESPONSE
                    ? 'active'
                    : ''
                "
                (click)="setTab(tabList.SUITABILITY_RESPONSE)"
              >
                <span class="nav-icon">
                  <i class="fas fa-user-check"></i>
                </span>
                <span class="nav-text">Suitability Response</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="router.isDisabledIn(['equity'])"

            >
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="
                  this.currentTabIndex === tabList.ADVISORY_FEES ? 'active' : ''
                "
                (click)="setTab(tabList.ADVISORY_FEES)"
              >
                <span class="nav-icon">
                  <i class="fas fa-user-check"></i>
                </span>
                <span class="nav-text">Advisory Fees</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="card-body">
        <div class="tab-content">
          <div class="tab-pane fade active show" role="tabpanel">
            <ng-container [ngSwitch]="currentTabIndex">
              <app-user-kyc-editor
                *ngSwitchCase="tabList.KYC"
                [userId]="kycStateInfo?.userId"
              >
              </app-user-kyc-editor>

              <kt-user-accounts
                *ngSwitchCase="tabList.WALLET"
                [userId]="kycStateInfo?.userId"
              >
              </kt-user-accounts>

              <app-user-portfolio
                *ngSwitchCase="tabList.PORTFOLIO"
                [userId]="kycStateInfo?.userId"
              >
              </app-user-portfolio>

              <app-cscs-data-form
                *ngSwitchCase="tabList.CSCS"
                [userId]="kycStateInfo?.userId"
              ></app-cscs-data-form>

              <app-orders-list
                *ngSwitchCase="tabList.ORDERS"
                [userId]="kycStateInfo?.userId"
              ></app-orders-list>

              <app-redemption-list
                *ngSwitchCase="tabList.REDEMPTION"
                [userId]="kycStateInfo?.userId"
              ></app-redemption-list>

              <app-mandate-list
                *ngSwitchCase="tabList.MANDATE"
                [userInfo]="kycStateInfo"
              >
              </app-mandate-list>

              <app-payments-list
                *ngSwitchCase="tabList.PAYMENTS"
                [userId]="kycStateInfo?.userId"
              ></app-payments-list>

              <app-transactions
                *ngSwitchCase="tabList.TRANSACTIONS"
                [userId]="kycStateInfo?.userId"
              ></app-transactions>

              <app-dw-history
                *ngSwitchCase="tabList.DW_HISTORY"
                [userId]="kycStateInfo?.userId"
              ></app-dw-history>

              <app-user-statements
                *ngSwitchCase="tabList.STATEMENTS"
                [userId]="kycStateInfo?.userId"
              ></app-user-statements>

              <app-kyc-verification
                *ngSwitchCase="tabList.VERIFICATION"
                [userId]="(kyc.state | async).kyc?.userId"
              ></app-kyc-verification>

              <app-suitability-response
                *ngSwitchCase="tabList.SUITABILITY_RESPONSE"
                [userId]="(kyc.state | async).kyc?.userId"
              ></app-suitability-response>

              <app-advisory-fees *ngSwitchCase="tabList.ADVISORY_FEES"
              [userId]="(kyc.state | async).kyc?.userId"
              >
              </app-advisory-fees>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
