<div class="row">
  <div class="col-12">
    <form [formGroup]="bankForm">
      <div class="form-row">
        <div class="col-lg-12 form-group">
          <!-- onSelectChange('bankId', $event) -->
          <label for="bankId">Select Bank</label>

          <ng-select  formControlName="bankId">
            <ng-option *ngFor="let bank of banks" [value]="bank.id">
              {{ bank.name }} </ng-option>
         </ng-select>

        </div>
        <div class="col-lg-12 form-group">
          <label for="bankAcctName"> Account Name </label>
          <input
            id="bankAcctName"
            class="form-control"
            formControlName="bankAcctName"
          />
        </div>
        <div class="col-lg-12 form-group">
          <label for="bankAcctNo"> Account number </label>
          <input
            id="bankAcctNo"
            class="form-control"
            formControlName="bankAcctNo"
          />
        </div>
        <div class="col-lg-12 form-group">
          <label for="bvn"> BVN </label>
          <input class="form-control" formControlName="bvn" />
        </div>
      </div>
    </form>
  </div>
</div>
