import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { cleanChakaAPIError } from '@console/api';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { DocumentPreviewService } from '@console/shared/services/document-preview/document-preview.service';
import { NotificationService } from '@console/shared/services/notification-service';
import { SidenavService } from '@console/shared/services/sidenav.service';
import { debounceCall } from '@console/shared/utils/debounceCall';
import { IBVNDetails } from '@console/user-managements';
import { CSCSStateService } from '@console/user-managements/sections/user-kyc-editor/cscs-data-form/cscs.state';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {
  humanizeBytes,
  UploaderOptions,
  UploadInput,
  UploadOutput,
} from 'ngx-uploader';
import { SendRejectionEmailComponent } from 'projects/view/src/lib/settings/rejection-email-settings/components/send-rejection-email/send-rejection-email.component';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { KYC_COUNTRY } from '../../../../../../../shared/src/lib/_enums/kyc-country.enum';
import { UserKycStateService } from '../../../../../../../user-managements/src/lib/sections/user-kyc-editor/user-kyc.state';
import { KYCVerificationStateService } from '../../../../../../../user-managements/src/lib/user-profile/kyc-verification.state';
import { CscsSecondLoginStateService } from '../cscs-second-login/cscs-second-login-state.service';
import { KYC_STATUS_ENUM } from './../../enums/kyc-status.enum';
@Component({
  selector: 'app-kyc-verification',
  templateUrl: './kyc-verification.component.html',
  styleUrls: ['./kyc-verification.component.scss'],
})
export class KycVerificationComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('bvnSideNav') bvnSideNav: MatSidenav;
  @ViewChild('ninDrawer') ninDrawer: MatDrawer;

  kycSub: Subscription;

  constructor(
    public kyc: UserKycStateService,
    public verificationState: KYCVerificationStateService,
    public kycVerification: KYCVerificationStateService,
    public cscs: CSCSStateService,
    private modal: NgxSmartModalService,
    private sidenavService: SidenavService,
    private cscsSecondLoginState: CscsSecondLoginStateService,
    private notificationService: NotificationService,
    private documentPreviewService: DocumentPreviewService,
    private messagesService: MessagesService,
    private dialog: MatDialog
  ) {
    this.options = { concurrency: 1, maxUploads: 3, maxFileSize: 1000000 };

    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  @Input() userId!: string;

  private previousUserId: string;

  allKycStatus: { description: string; value: string }[] = [
    { description: '--Select--', value: '' },
    {
      description: 'UPLOADED DOCUMENTS',
      value: KYC_STATUS_ENUM.UPLOADED_DOCUMENTS,
    },
    { description: 'VERIFIED', value: KYC_STATUS_ENUM.VERIFIED },
    { description: 'STARTED', value: KYC_STATUS_ENUM.STARTED },
    { description: 'PENDING', value: KYC_STATUS_ENUM.PENDING },
    {
      description: 'PENDING REVALIDATION',
      value: KYC_STATUS_ENUM.PENDING_REVALIDATION,
    },
    {
      description: 'PENDING APPROVAL',
      value: KYC_STATUS_ENUM.PENDING_APPROVAL,
    },
    {
      description: 'REJECTED PHOTO ID',
      value: KYC_STATUS_ENUM.REJECTED_PHOTO_ID,
    },
    {
      description: 'REJECTED ADDRESS PROOF',
      value: KYC_STATUS_ENUM.REJECTED_ADDRESS_PROOF,
    },
    {
      description: 'FAILED CSCS TOKEN',
      value: KYC_STATUS_ENUM.FAILED_CSCS_TOKEN,
    },
    { description: 'SUBMITTED', value: KYC_STATUS_ENUM.SUBMITTED },
  ];

  // endpoint to trigger CSCS script manaually.
  // fetch passort from BVN to update passportURL.

  globalKycStatus: FormControl;
  localKycStatus: FormControl;

  options: UploaderOptions;
  formData!: FormData;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean | undefined;

  pdfModal = 'verifcation-pdf-modal';
  pdfLink: string | undefined;

  bvnSearch: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern('^[0-9]{11}$'),
  ]);

  ninSearch: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern('^[0-9]{11}$'),
  ]);

  debounceBvnChange = debounceCall(function () {
    this.onBvnChange();
  }, 500);

  bvnDetails: IBVNDetails;
  bvnError: string;

  approve(country: KYC_COUNTRY, status: string): void {
    if (country === KYC_COUNTRY.US && !this.globalKycStatus.valid) {
      this.globalKycStatus.markAsTouched();
      return;
    }

    if (country === KYC_COUNTRY.NGA && !this.localKycStatus.valid) {
      this.localKycStatus.markAsTouched();
      return;
    }

    this.kycVerification.approve({
      userId: this.userId,
      kycCountry: country,
      kycStatus: status,
    });
  }

  loadKycProfile(): void {
    this.presetKyc();

    this.kyc.retrieve(this.userId);
  }

  presetKyc(): void {
    this.kycSub = this.kyc.state.subscribe((state) => {
      if (state.kyc) {
        this.kycVerification.presetKyc(state.kyc);

        this.kycVerification.state.subscribe((kycState) => {
          this.localKycStatus?.setValue(kycState.kyc.NGA, { emitEvent: false });
          this.globalKycStatus?.setValue(kycState.kyc.US, { emitEvent: false });
        });
      }
    });
  }

  onUploadOutput(output: UploadOutput, uploadType: any): void {
    if (typeof output.file !== 'undefined') {
      this.kyc.state.pipe(first()).subscribe((state) => {
        this.kyc.uploadFile({
          file: output?.file?.nativeFile as File,
          uploadType,
          userId: state.kyc?.userId as string,
        });
      });
    }
  }

  submitCSCSToken(): void {
    const input = document.getElementById(
      'css-token-input'
    ) as HTMLInputElement;

    if (!!input.value) {
      this.cscsSecondLoginState.isLoggedIn
        ? this.sendCscsToken(input.value)
        : this.cscsSecondLoginState.openModal((success) => {
          success && this.sendCscsToken(input.value);
        });
    }
  }

  sendCscsToken(token: string): void {
    this.cscs.tokenUpload({
      token,
      userId: this.userId,
      ...this.cscsSecondLoginState.loginDetails,
    });
  }

  selectPdf$(key: string) {
    return this.kyc.state.pipe(
      map((state) => {
        if (state && state.kyc && state.kyc[key]) {
          const link = state.kyc[key];
          if (link && link.includes('.pdf')) {
            return link;
          }
        }

        return null;
      })
    );
  }

  openPdF(key: string): void {
    this.selectPdf$(key)
      .pipe(first())
      .subscribe((link) => {
        this.pdfLink = link;
        this.modal.open(this.pdfModal);
      });
  }

  onBvnChange(): void {
    this.bvnDetails = null;
    this.bvnError = null;

    if (!this.bvnSearch.valid) {
      this.bvnSearch.markAsTouched();
      return;
    }

    this.cscs.getBVNInfo(this.bvnSearch.value.trim()).subscribe(
      (response) => {
        if (response.data && response.success) {
          this.bvnDetails = response.data;
        } else {
          this.bvnError = 'No record found.';
        }
      },
      (error: HttpErrorResponse) => {
        this.bvnError = cleanChakaAPIError(error);
      }
    );
  }

  onNinSubmit(): void {
    if (!this.ninSearch.valid) {
      this.ninSearch.markAsTouched();
      return;
    }

    this.cscs.verifyNinInfo(this.ninSearch.value.trim(), this.userId).subscribe(
      (response) => {
        if (response.data && response.success) {
          this.notificationService.success(response.data);

          return;
        }

        this.notificationService.error(response.data);
      },
      (error: HttpErrorResponse) => {
        const message = cleanChakaAPIError(error);

        this.notificationService.error(message);
      }
    );
  }

  openBvnSideNav(): void {
    this.bvnSideNav.open();
  }

  closeBvnSideNav(): void {
    this.bvnSearch.reset();
    this.bvnSearch.updateValueAndValidity();
    this.bvnError = null;
    this.bvnDetails = null;

    this.sidenavService.close();
  }

  ngOnInit(): void {
    this.globalKycStatus = new FormControl('', Validators.required);
    this.localKycStatus = new FormControl('', Validators.required);
    this.loadKycProfile();
  }

  ngOnChanges(): void {
    console.log('called');

    if (this.previousUserId !== this.userId) {
      // this.ngOnInit();
      this.previousUserId = this.userId;
      this.loadKycProfile();
    }
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.bvnSideNav);
  }

  openNinDrawer(): void {
    this.ninDrawer.open();
  }

  renderRejectionEmailBtn() {
    return this.globalKycStatus.value === KYC_STATUS_ENUM.REJECTED_ADDRESS_PROOF || 
      this.globalKycStatus.value === KYC_STATUS_ENUM.REJECTED_PHOTO_ID;
  }

  openTableDialog(reasonStatus?: string): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';

    const data: { userId: string | number, status: string } = {
      userId: this.userId,
      status: this.globalKycStatus.value
    };

    dialogConfig.data = data;

    this.messagesService.hideAll();

    const dialogRef = this.dialog
      .open(SendRejectionEmailComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(
        (result: boolean) => {
          // result && this.getRejectionEmails();
        });
  }

  ngOnDestroy(): void {
    !!this.kycSub && this.kycSub.unsubscribe();
  }
}
