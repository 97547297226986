<!--begin::Card-->
<div class="card card-custom gutter-b">
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">CSCS Report List</h3>
    </div>
  </div>

  <div class="card-body">
    <!--begin: Search Form-->
    <div class="mb-5">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <form [formGroup]="searchForm">
            <div class="row align-items-center">

              <!-- <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>Search all feeds</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="q"
                    trimmedInput>
                </div>
              </div> -->

              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="form-group">
                  <label> Status</label>
                  <ng-select formControlName="status" [clearable]="false">
                    <ng-option [value]="status.value" *ngFor="let status of cscsStatusList">{{ status.label }}</ng-option>
                  </ng-select>
                </div>
              </div>
              <!--
              <div
                class="col-xl-3 col-lg-4 col-md-6"
                *ngIf="isSuperAdmin">
                <div class="form-group">
                  <label>Merchant</label>
                  <ng-select
                    formControlName="merchantId"
                    [clearable]="false"
                    [virtualScroll]="true"
                    [loading]="this.merchants?.loading">
                    <ng-option value="">All</ng-option>
                    <ng-option
                      [value]="merchant?.id"
                      *ngFor="let merchant of merchants?.list">
                      {{ merchant?.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div> -->
              <!--
              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>From</label>
                  <input class="form-control" type="date" formControlName="from">
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>To</label>
                  <input class="form-control" type="date" formControlName="to">
                </div>
              </div> -->

              <div class="col-xl-1 col-lg-4 col-md-6">
                <div class="form-group">
                  <label class="d-block">&nbsp;</label>
                  <button
                    type="button"
                    class="btn btn-light-primary font-weight-bold"
                    (click)="handleQuery()">Search</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end::Search Form-->

    <!--begin: Datatable-->

    <lib-messages></lib-messages>

    <div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

      <div class="mt-5 mb-5">
        <div class="d-flex align-items-center">
          <div class="font-weight-bold">
            Selected {{selectedItems.size}} {{selectedItems.size <= 1 ?'record': 'records'}}:
            <button
              [disabled]="selectedItems.size <= 0"
              (click)="downloadFile()"
              class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
              <i class="far fa-arrow-alt-circle-down"></i> Download
            </button>
          </div>
        </div>
      </div>


      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matMultiSort aria-label="CSCS Report table">
          <ng-container matColumnDef="check" sticky>
            <th
              mat-header-cell
              *matHeaderCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single checkbox-all">
                  <input
                    (change)="onAllChecked($event.target.checked)"
                    type="checkbox"
                    [checked]="allSelected">&nbsp;<span></span>
                </label>
              </span>
            </th>

            <td
              mat-cell
              *matCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single">
                  <input
                    type="checkbox"
                    (change)="onItemChecked($event.target.checked, row)"
                    [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
                </label>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="userId"> User ID </th>
            <td mat-cell *matCellDef="let row">
              <a
                href="javascript:void(0)"
                (click)="viewUser(row?.userId)">
                {{row?.userId || "-"}}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="cscsNumber">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="cscsNumber"> CSCS Number </th>
            <td mat-cell *matCellDef="let row">
              {{row?.cscsNumber || '--'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="cscsCHN">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="cscsCHN"> CHN Number</th>
            <td mat-cell *matCellDef="let row">
              {{row?.cscsCHN || '--'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="cscsSent">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="cscsSent"> Sent</th>
            <td mat-cell *matCellDef="let row">
              <i
                [ngClass]="row?.cscsSent ?
                           'fas fa-check text-success':
                            'far fa-window-close text-danger'"></i>
            </td>
          </ng-container>

          <ng-container matColumnDef="uploadStatus">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="uploadStatus"> Upload Status </th>
            <td mat-cell *matCellDef="let row">{{row?.uploadStatus}}</td>
          </ng-container>

          <ng-container matColumnDef="cscsRequestRefNo">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="cscsRequestRefNo"> Request Ref No </th>
            <td mat-cell *matCellDef="let row">{{row?.cscsRequestRefNo || '--'}}</td>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="dateCreated"> Date </th>
            <td mat-cell *matCellDef="let row">
              {{row?.dateCreated | date:'mediumDate' || "--"}}
            </td>
          </ng-container>

          <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true">
          </tr>
          <tr mat-row
            *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>

      <mat-paginator
        class="mat-paginator-sticky"
        [length]="count"
        [pageSizeOptions]="[10, 50, 100, 150, 200]"
        (page)="pageEvent=$event; paginate($event)"
        showFirstLastButtons></mat-paginator>
    </div>

    <lib-no-data
      *ngIf="!loading && dataSource.data?.length <= 0"
      message="No Data"></lib-no-data>

    <!--end: Datatable-->
  </div>
</div>
<!--end::Card-->
