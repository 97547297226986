import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { PaymentTxnsModel } from '../services/payment.model';
import { PaymentTxnService } from '../services/payment.service';
import { BehaviorSubject } from 'rxjs';
import { exhaustMap, first, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@console/shared/services/notification-service';
import { PaymentListStateService } from '../payment-list/payment-state';

export interface PaymentState {
  loading: boolean;
  count?: number;
  payment?: PaymentTxnsModel;
  error?: string;
  message?: string;
}

const initialState: PaymentState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class PaymentStateService {
  state = new BehaviorSubject<PaymentState>(initialState);

  constructor(
    private paymentSvc: PaymentTxnService,
    private notificationService: NotificationService,
    private paymentList: PaymentListStateService
  ) {}

  findById(id: number) {
    this.loading();
    this.paymentSvc
      .getPaymentTxnById(id)
      .pipe(first())
      .subscribe({
        next: this.updatePayments.bind(this),
        error: this.onError.bind(this),
      });
  }

  approve(
    payload: {
      paymentReference?: string;
      paymentReferences?: string[];
      otp: string;
    },
    callback: (success: boolean) => void
  ): void {
    this.loading();
    this.paymentSvc
      .authorizePayment(payload)
      .pipe(first())
      .subscribe({
        next: (response: ReqSuccessResponse<PaymentTxnsModel>) => {
          this.notificationService.success('Successfully approved payment(s).');

          this.updatePayments(response);

          callback(true);
        },
        error: (error: ChakaAPIError) => {
          this.onError(error);
          callback(false);
        },
      });
  }

  requery(paymentReference: string, id) {
    this.loading();
    this.paymentSvc
      .requery({ paymentReference })
      .pipe(first())
      .subscribe(
        (response) => {
          this.notificationService.success('Re-query was successful;');

          this.findById(id);
        },
        (error) => {
          this.onError(error);
        }
      );
  }

  confirm(transactionReference: string, id) {
    this.loading();
    this.paymentSvc
      .confirmPayment({ transactionReference })
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.notificationService.success(
            'Payment was confirmed successfully'
          );

          this.findById(id);
        },
        error: this.onError.bind(this),
      });
  }

  delete(paymentIDs: string[], query) {
    this.loading();
    this.paymentSvc
      .deletePayment(paymentIDs)
      .pipe(first())
      .subscribe({
        next: () => {
          this.state.next({
            loading: false,
          });

          this.notificationService.success('Payment(s) deleted.');

          this.paymentList.find(query);
        },
        error: this.onError.bind(this),
      });
  }

  retry(paymentReference: string) {
    this.loading();
    this.paymentSvc
      .requery({ paymentReference })
      .pipe(first())
      .subscribe({
        next: this.updatePayments.bind(this),
        error: this.onError.bind(this),
      });
  }

  retryPayment() {
    this.state
      .pipe(
        exhaustMap((state) => this.paymentSvc.requeryPayment(state.payment))
      )
      .pipe(first())
      .subscribe({
        next: this.updatePayments.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updatePayments(data: ReqSuccessResponse<PaymentTxnsModel>) {
    this.state.next({
      loading: false,
      payment: data.data,
      // message: "Successful"
    });

    this.notificationService.success('Successful fetched payment');
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
      // message: error
    });

    this.notificationService.error(error);
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading',
    });
  }
}
