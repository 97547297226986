import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WalletTypes } from '../../providers/wallet.interface';
import { WalletExchangeStateService } from '../../states/wallet-exchange.state';

@Component({
  selector: 'kt-user-accounts-exchange',
  templateUrl: './exchange.component.html',
})
export class UserAccountsExchangeComponent implements OnInit, OnDestroy {
  transferForm: FormGroup;

  @Input() private userId: string;

  wallets = [
    {
      label: 'Local Wallet (NGN)',
      value: WalletTypes.LOCAL,
    },
    {
      label: 'Dollar Wallet (USD)',
      value: WalletTypes.GLOBAL,
    },
  ];

  constructor(
    private userFB: FormBuilder,
    public exchange: WalletExchangeStateService
  ) {}

  createForm() {
    this.transferForm = this.userFB.group({
      from: [WalletTypes.GLOBAL, Validators.required],
      to: [WalletTypes.LOCAL, Validators.required],
      amount: [0, Validators.required],
    });
  }

  onSubmit() {
    const form = this.transferForm.value;
    form.userId = this.userId;
    this.exchange.exchange(form);
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.exchange.resetState();
  }
}
