import { APICONFIG } from '../../projects/api/src/lib/api.constants';

export function serverRoutes(): APICONFIG {
  const isHttps = true;

  const productionDomains = ['mssp.chaka.com'];

  const live = productionDomains.includes(window.location.hostname);

  return {
    enterpriseURL: `http${isHttps ? 's' : ''}://${live ? 'enterprise.chaka' : 'enterprise-staging.chaka'
      }.com`,
    authURL: `http${isHttps ? 's' : ''}://${live ? 'auth.chaka' : 'auth-staging.chaka'
      }.com`,
    authKey: 'auth-key'
  };

}
