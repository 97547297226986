import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { MerchantsModel } from '../../merchants.interface';

@Component({
  selector: 'kt-merchant-grid',
  templateUrl: './merchant-grid.component.html',
  styleUrls: ['./merchant-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MerchantGridComponent implements OnInit {

	@Input() merchants: MerchantsModel[] = [];
	@Output() onSelect = new EventEmitter<MerchantsModel>();

	constructor() {}

	ngOnInit() {}
}
