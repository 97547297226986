import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserPageListComponent } from './users-list/user-managements.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RegisterComponent } from './sections/register/register.component';

const routes: Routes = [
  {
    path: 'users',
    component: UserPageListComponent,
  },
  {
    path: 'users/register',
    component: RegisterComponent
  },
  {
    path: 'users/:id',
    component: UserProfileComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutesModule {}
