import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { CscsReportService } from '../services/cscs-report.service';

import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { ICscsReport } from '../interface/ICscsReport';
import { RedemptionStatusEnum } from '@console/shared/_enums/RedemptionStatus.enum';

export interface ICscsReportState {
  loading: boolean;
  count?: number;
  cscsReports: ICscsReport[];
  error?: string;
}

const initialState: ICscsReportState = {
  loading: false,
  cscsReports: [],
};

@Injectable()
export class CscsReportStateService {
  private subject$ = new BehaviorSubject<ICscsReportState>(initialState);

  state$ = this.subject$.asObservable();

  private messageId: number;

  constructor(
    private cscsReportService: CscsReportService,
    private messagesService: MessagesService
  ) {}

  get loading$(): Observable<boolean> {
    return this.subject$.pipe(map((state) => state.loading));
  }

  find(query: object): void {
    this.loading();

    this.showLoadingMessage('Fetching cscs report...');

    this.cscsReportService
      .findByStatus(query)
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        })
      )
      .subscribe({
        next: this.onFindSuccess.bind(this),
      });
  }

  reset(): void {
    this.subject$.next(initialState);
  }

  private onFindSuccess(response: PaginatedList<ICscsReport>): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: false,
      cscsReports: response?.data,
      count: response?.count,
    });

    if (response?.data?.length > 0) {
      this.messagesService.update({
        id: this.messageId,
        type: MessagesEnum.success,
        message: `Successfully retrieved cscs report`,
      });
      return;
    }

    this.messagesService.hide(this.messageId);
  }

  private handleError(
    res: ChakaAPIError,
    callback?: (success: boolean) => void
  ): void {
    const errorMessage = cleanChakaAPIError(res) || 'An Error occurred';

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false,
    });

    this.messagesService.update({
      id: this.messageId,
      type: MessagesEnum.danger,
      message: errorMessage,
    });

    !!callback && callback(false);
  }

  private showLoadingMessage(message: string): void {
    if (this.messageId) {
      this.messagesService.update({
        id: this.messageId,
        type: MessagesEnum.loading,
        message,
        isOpened: true,
      });
      return;
    }

    const { id } = this.messagesService.open(MessagesEnum.loading, message);

    this.messageId = id;
  }

  private loading(): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true,
    });
  }
}
