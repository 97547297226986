import { ChakaAccountService } from './../../service/chaka-account.service';
import { Currency } from './../../../../../../shared/src/lib/_enums/currency.enum';
import { Bank } from './../../../../../../user-managements/src/lib/sections/user-kyc-editor/kyc-util.interface';
import { LoadingService } from './../../../../../../shared/src/lib/components/loading/loading.service';
import { MessagesService } from './../../../../../../shared/src/lib/components/messages/messages.service';

import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMerchantBankAccount } from '../../interface/IChakaAccount';
import { MessagesEnum } from '../../../../../../shared/src/lib/components/messages/enums/messages.enums';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';

@Component({
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss'],
  providers: [MessagesService, LoadingService],
})
export class AccountDialogComponent {
  accountForm: FormGroup;

  bankList: Bank[];
  currencyList = [Currency.NGN, Currency.USD];
  currencyEnum = Currency;

  formMethods;

  title: string;

  constructor(
    private dialogRef: MatDialogRef<AccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    componentData: { bankList: Bank[]; account?: IMerchantBankAccount },
    private chakaAccountsService: ChakaAccountService,
    private messagesService: MessagesService
  ) {
    const { bankList, account } = componentData;

    this.title = `${account?.id ? 'Update' : 'Add'} Account`;

    this.accountForm = new FormGroup({
      bankName: new FormControl(account?.bankName || '', Validators.required),
      accountNumber: new FormControl(account?.accountNumber || '', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$'),
      ]),
      currency: new FormControl(
        account?.currency || Currency.NGN,
        Validators.required
      ),
      swiftCode: new FormControl(account?.swiftCode || ''),
      isEnabled: new FormControl(account?.isEnabled || false),
    });

    !!account?.id &&
      this.accountForm.addControl('id', new FormControl(account?.id));

    this.formMethods = formMethods(this.accountForm);

    this.bankList = bankList;
  }

  get bankId(): number | null {
    try {
      const bankName = this.accountForm.get('bankName').value;
      const bankId = this.bankList.find(({ name }) => bankName === name).id;
      return bankId;
    } catch (error) {
      return null;
    }
  }

  public get accountNumberControl(): AbstractControl {
    return this.accountForm.get('accountNumber');
  }

  public get validAccountName(): string | null {
    const validAccountName =
      this.accountNumberControl.valid &&
      this.accountNumberControl['accountName']
        ? this.accountNumberControl['accountName']
        : null;

    return validAccountName;
  }

  onBankChange(): void {
    setTimeout(() => {
      this.accountNumberControl.updateValueAndValidity();
    }, 500);
  }

  get swiftControl(): AbstractControl {
    return this.accountForm.get('swiftCode');
  }

  get currencyControl(): AbstractControl {
    return this.accountForm.get('currency');
  }

  onCurrencyChange(currency: Currency) {
    if (currency === Currency.USD) {
      this.swiftControl.addValidators(Validators.required);
    } else {
      this.swiftControl.removeValidators(Validators.required);
      this.swiftControl.patchValue('');
    }

    this.swiftControl.updateValueAndValidity();
  }

  save(): void {
    if (!this.accountForm.valid) {
      this.accountForm.markAllAsTouched();
      return;
    }

    const { value } = this.accountForm;
    const message = `${value.id ? 'Updating' : 'Saving'} account...`;

    this.messagesService.open(MessagesEnum.loading, message, { hideAll: true });

    value.accountName = this.validAccountName;

    this.chakaAccountsService.saveAccount(value).subscribe({
      next: () => {
        this.close(true);
      },
      error: (error: ChakaAPIError) => {
        const errorMessage = cleanChakaAPIError(error);

        this.messagesService.update({
          type: MessagesEnum.danger,
          message: errorMessage,
        });
      },
    });
  }

  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }
}
