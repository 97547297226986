import { SharedModule } from './../../../shared/src/lib/shared.module';
import { NgModule } from '@angular/core';
import { AnalyticsComponent } from './analytics.component';
import { ApiModule } from '../../../api/src/lib/api.module';
import { AnalyticService } from './services/analytics.service';
import { MerchantAnalyticStateService } from './states/analytics.state';
import { AnalyticComponentModule } from './components/analytics-component.module';
import { CommonModule } from '@angular/common';
import { AnalyticsTableComponent } from './components/analytics-table/analytics-table.component';
import { AnalyticsMixedGraphComponent } from './components/analytics-mixed-graph/analytics-mixed-graph.component';


@NgModule({
  declarations: [
    AnalyticsComponent,
    AnalyticsTableComponent,
    AnalyticsMixedGraphComponent
  ],
  imports: [
    ApiModule,
    AnalyticComponentModule,
    CommonModule,
    SharedModule
  ],
  exports: [AnalyticsComponent],
  providers: [AnalyticService, MerchantAnalyticStateService]
})
export class AnalyticsModule { }
