import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceString'
})

export class ReplaceStringPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    if (!value) {
      return '';
    }

    if (args?.length < 2) {
      return value;
    }

    return value.replace(args[0], args[1]);
  }
}
