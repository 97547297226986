import { MerchantsModel } from './../../../merchants/merchants.interface';
import { ReferralService } from '../../service/referral-service.service';

import { LoadingService } from '../../../../../../shared/src/lib/components/loading/loading.service';
import { MessagesService } from '../../../../../../shared/src/lib/components/messages/messages.service';

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IReferralPolicy } from '../../interface/IReferralPolicy';
import { MessagesEnum } from '../../../../../../shared/src/lib/components/messages/enums/messages.enums';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { nonNegativeNumber } from '@console/shared/utils/nonNegativeNumber';


@Component({
  templateUrl: './referral-dialog.component.html',
  styleUrls: ['./referral-dialog.component.scss'],
  providers: [
    MessagesService,
    LoadingService
  ]
})

export class ReferralDialogComponent implements OnInit {
  referralPolicyForm: FormGroup;
  formMethods;

  title = '';

  merchantList: MerchantsModel[];
  referralPolicy: IReferralPolicy;

  constructor(
    private dialogRef: MatDialogRef<ReferralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) componentData: { merchantList: MerchantsModel[], referralPolicy?: IReferralPolicy },
    private referralService: ReferralService,
    private messagesService: MessagesService
  ) {

    const { merchantList, referralPolicy } = componentData;

    this.title = `${referralPolicy?.id ? 'Update' : 'Add'} Referral Policy`;

    this.merchantList = merchantList;
    this.referralPolicy = referralPolicy;
  }



  ngOnInit(): void {
    this.setupForm();
  }

  validMaxCount(control: FormControl): { [s: string]: boolean } {
    const minCountValue = this.referralPolicyForm?.get('minCount').value;
    const maxCountValue = control.value;

    if (minCountValue >= maxCountValue) {
      return { invalidMaxCount: true };
    }

    return null;
  }

  private setupForm(): void {
    this.referralPolicyForm = new FormGroup({
      minCount: new FormControl(this.referralPolicy?.minCount || 0, Validators.required),
      maxCount: new FormControl(this.referralPolicy?.maxCount || 0, [
        Validators.required,
        this.validMaxCount.bind(this)
      ]),
      merchantId: new FormControl(this.referralPolicy?.merchantId || null, Validators.required),
      refereeAmount: new FormControl(this.referralPolicy?.refereeAmount || null, [
        Validators.required,
        Validators.pattern(nonNegativeNumber)
      ]),
      referrerAmount: new FormControl(this.referralPolicy?.referrerAmount || null, [
        Validators.required,
        Validators.pattern(nonNegativeNumber)])
    });

    !!this.referralPolicy?.id && this.referralPolicyForm.addControl('id', new FormControl(this.referralPolicy?.id));

    this.formMethods = formMethods(this.referralPolicyForm);
  }



  save(): void {

    if (!this.referralPolicyForm.valid) {
      this.referralPolicyForm.markAllAsTouched();
      return;
    }

    const { value } = this.referralPolicyForm;
    const message = `${value.id ? 'Updating' : 'Saving'} referral policy...`;

    this.messagesService.open(
      MessagesEnum.loading,
      message,
      { hideAll: true }
    );

    this.referralService
      .saveReferralPolicy(value)
      .subscribe({
        next: () => {
          this.close(true);
        },
        error: (error: ChakaAPIError) => {
          const errorMessage = cleanChakaAPIError(error);

          this.messagesService.update(
            {
              type: MessagesEnum.danger,
              message: errorMessage
            }
          );
        }
      });
  }

  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }
}

