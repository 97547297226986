

	<!--begin::Portlet-->
	<div class="kt-portlet kt-portlet--solid-brand kt-portlet--height-fluid">
		<div class="kt-portlet__head">
			<div class="kt-portlet__head-label">
				<span class="kt-portlet__head-icon"><i class="fas fa-coins"></i></span>
				<h3 class="kt-portlet__head-title">{{ collection.title }}</h3>
			</div>
			<div class="kt-portlet__head-toolbar">
				{{ collection.instruments }}
			</div>
		</div>
		<div class="kt-portlet__body">
			<div class="kt-portlet__content">
				{{
					collection.description
						? collection.description.slice(0, 20) + "..."
						: ""
				}}
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__foot--sm kt-align-right">
			<a (click)="btnClick.emit(collection)" class="btn btn-font-light btn-outline-hover-light">View Details</a>
		</div>
	</div>
