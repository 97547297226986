import { Pipe, PipeTransform } from '@angular/core';
import { removeNumberPrefix } from '../utils/removeNumberPrefix';

@Pipe({
  name: 'formatPhoneNumber'
})

export class FormatPhoneNumberPipe implements PipeTransform {

  transform(value: string, prefix: string): string {
    if (!value || !prefix) {
      return;
    }

    return prefix + removeNumberPrefix(value, prefix);
  }
}
