import { Injectable } from '@angular/core';
import {
  ReqSuccessResponse,
  ChakaAPIError,
  cleanChakaAPIError,
} from '@console/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChannelSettingService } from '../services/channel.service';
import { ChannelSetting } from '../services/settings.interface';

export interface ChannelState {
  channels: ChannelSetting[];
  ranks: number[];
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: ChannelState = { loading: false, channels: [], ranks: [] };

@Injectable({ providedIn: 'root' })
export class ChannelListStateService {
  state = new BehaviorSubject<ChannelState>(initialState);

  constructor(public channels: ChannelSettingService) {}

  find(query: Record<string, any> = {}) {
    this.loading();
    this.channels.find(query).subscribe({
      next: this.onUserChannelLoaded.bind(this),
      error: this.onUserChannelError.bind(this),
    });
  }

  resetState() {
    this.state.next(initialState);
  }

  isEnable$(channel: string) {
    const activateChannels = (state: ChannelState) =>
      state.channels.filter((c) => c.status).map((c) => c.name.toUpperCase());
      
    return this.state.pipe(
      map(activateChannels),
      map((c) => c.includes(channel.toUpperCase()))
    );
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  private loading() {
    this.state.next({ ...this.state.getValue(), loading: true });
  }

  private onUserChannelLoaded({ data }: ReqSuccessResponse<ChannelSetting[]>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      channels: data,
      ranks: data.map(({ rank }) => rank).filter((rank) => Boolean(rank)),
    });
  }

  private onUserChannelError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error: cleanChakaAPIError(res),
    });
  }

  get ranks$(): Observable<number[]> {
    return this.state.pipe(map((state) => state.ranks));
  }
}
