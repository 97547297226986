import { Injectable } from '@angular/core';
import { ReqFailureResponse, ReqSuccessResponse } from '@console/api';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { RoleService } from '../services/role.service';
import { LiteRoleDetail, TreeRole } from '../services/roles.interface';

export interface RoleCreatorState {
  loading: boolean;
  role?: TreeRole;
  error?: string;
  deleted?: boolean;
  message?: string;
}

const initialState: RoleCreatorState = {
  loading: false,
};

@Injectable({ providedIn: 'root' })
export class RoleCreatorStateService {
  spinnerName = 'role-spinner';

  state = new BehaviorSubject<RoleCreatorState>(initialState);

  constructor(
    private auth: AuthRoleStateService,
    private role: RoleService,
    private spinner: NgxSpinnerService
  ) {}

  retrieve() {
    const { roleId } = this.auth.extractTokenInformation();
    this.loading();

    this.role
      .roleById(roleId)
      .pipe(first())
      .subscribe({
        next: this.onLoaded.bind(this),
        error: this.onError.bind(this),
      });
  }

  resetState() {
    this.state.next(initialState);
  }

  public pickPermissionFromCreator(permissionIds: number[]) {
    return this.state.pipe(map((state) => state.role?.permissionNodes || [])).pipe(
      map((permissions: LiteRoleDetail[]) => {
        return permissions
          .reduce((prev, cur) => [...prev, ...cur.children], [])
          .filter((p: LiteRoleDetail) => permissionIds.includes(p.id)) as LiteRoleDetail[];
      })
    );
  }

  private onLoaded({ data }: ReqSuccessResponse<TreeRole>) {
    this.state.next({
      role: data,
      loading: false,
      error: undefined,
      message: `Done.....`,
    });

    this.spinner.hide(this.spinnerName);
  }

  private onError({ message }: ReqFailureResponse) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error: message,
      message,
    });

    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2000);
  }

  private loading() {
    this.state.next({
      loading: true,
      message: `Loading....`,
    });

    this.spinner.show(this.spinnerName);
  }
}
