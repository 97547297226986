import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holiday-maintenance',
  templateUrl: './holiday-maintenance.component.html',
  styleUrls: ['./holiday-maintenance.component.scss']
})
export class HolidayMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
