import { EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DateUtil } from './../../../../../shared/src/lib/utils/dateUtil';
import { Component, OnInit } from '@angular/core';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { MerchantAnalyticStateService } from '../../states/analytics.state';
import { ReportEnum } from '../../enums/ReportEnum';
import { AnalyticTabs } from '../../enums/analyticsTabs';

@Component({
  selector: 'lib-analytics-table',
  templateUrl: './analytics-table.component.html',
  styleUrls: ['./analytics-table.component.scss']
})

export class AnalyticsTableComponent implements OnInit {
  analyticsTabs = AnalyticTabs;

  reportEnums = ReportEnum;

  filter: Record<string, any> = {
    pageSize: 8,
    from: DateUtil.subtractDaysFromDate(new Date(), 7).toISOString(),
    to: new Date().toISOString()
  };

  formatToCurrency = formatToCurrency;

  @Input() reportLoading = false;

  @Input() tableData: unknown = [];

  @Input() activeReport: ReportEnum = ReportEnum.ORDERS;

  @Output() viewUser = new EventEmitter<string>();

  @Output() viewById = new EventEmitter<string>();

  constructor(
    private analytics: MerchantAnalyticStateService
  ) { }

  ngOnInit(): void {
    this.analytics.getReportAnalytics(this.activeReport);
  }

  switchTabs(tab: ReportEnum): void {
    this.activeReport = tab;

    this.analytics.getReportAnalytics(this.activeReport);
  }

  get tabDetails(): { name: string, value: string } {
    return this.analyticsTabs.find(tab => tab.value === this.activeReport);
  }

  userName(data): string {
    const reference = this.activeReport === ReportEnum.USERS ?
      data?.firstName + ' ' + data?.lastName :
      data?.createdBy;

    return reference || '';
  }

  referenceNumber(data): string {
    const reference = this.activeReport === ReportEnum.PAYMENTS ?
      data?.paymentReference :
      data?.reference;

    return reference || '';
  }

  resolveAmount(data): string {
    const amount = this.activeReport === ReportEnum.ORDERS ?
      data?.totalAmount :
      data?.amount;

    return formatToCurrency(data.currency, amount);
  }

  get isUserReport(): boolean {
    return this.activeReport === ReportEnum.USERS;
  }

  get isOrderReport(): boolean {
    return this.activeReport === ReportEnum.ORDERS;
  }

  get isPaymentReport(): boolean {
    return this.activeReport === ReportEnum.PAYMENTS;
  }

  get isTransactionReport(): boolean {
    return this.activeReport === ReportEnum.TRANSACTIONS;
  }

  getOrderRowClass(status: string): string {
    const classMap = {
      PENDING: 'text-info',
      CANCELED: 'text-danger',
      REJECTED: 'text-danger',
      PENDING_CANCEL: 'text-warning',
      FILLED: 'text-primary',
      PARTIALLY_FILLED: 'text-secondary',
      NEW: 'text-dark'
    };

    return classMap[status] || '';
  }

  getStatusClass(paymentStatus: string): string {
    const statusMapper = {
      SUCCESSFUL: 'text-success',
      ['FAILED' || 'REJECTED']: 'text-danger',
      PENDING: 'text-warning',
    };

    return statusMapper[paymentStatus] || '';
  }

}
