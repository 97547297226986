import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { AuditLogService } from './service/audit-log.service';
import {
  MerchantListState,
  MerchantListStateService,
} from '../merchants/merchant-list/merchant-list.state';
import { MerchantsModel } from '../merchants/merchants.interface';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IAuditLog } from './interface/IAuditLog';
import { ConsoleRouterService } from '@console/shared';
import { MatDrawer } from '@angular/material/sidenav';
@Component({
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  providers: [MerchantListStateService],
})
export class AuditLogComponent implements OnInit, OnDestroy {
  merchantList: MerchantsModel[];

  componentDestroyed$: Subject<boolean> = new Subject();

  searchForm: FormGroup;

  dataSource = new MatTableDataSource<IAuditLog>();

  private filter: Record<string, any> = {};

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  displayedColumns: string[] = [
    'userFullName',
    'clientName',
    'clientIpAddress',
    'serviceName',
    'merchantId',
    'action',
  ];

  count = 0;

  loading = false;

  activeLogId: number;

  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private auditLogService: AuditLogService,
    private messagesService: MessagesService,
    private merchantListState: MerchantListStateService,
    private consoleRouter: ConsoleRouterService
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.getAuditLogs();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      from: new FormControl(),
      to: new FormControl(),
    });

    this.listenOnSearchForm();
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          console.log(formValues);
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.getAuditLogs();
        })
      )
      .subscribe();
  }

  clearFilters(): void {
    this.searchForm.reset();
  }

  getAuditLogs(): void {
    this.loading = true;

    this.auditLogService
      .fetchLogs(this.filter)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource<IAuditLog>(response.data);
          this.count = response.count;
        },
      });
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.getAuditLogs();
  }

  listenOnMerchants(): void {
    this.merchantListState.find({
      pageSize: 1000,
    });

    this.merchantListState.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: MerchantListState) => {
        this.merchantList = state?.merchants;
      });
  }

  resolveMerchantName(merchantId: number): string {
    try {
      return this.merchantList.find(({ id }) => id === merchantId).name;
    } catch (error) {
      return '';
    }
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  viewLog(logId: number): void {
    this.activeLogId = logId;

    this.drawer.open();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();
  }
}
