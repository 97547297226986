<section-search-header
  [section]="'Banks'"
  (onSearch)="search($event)"
  [count]="(bank.state | async).count"
  [loading]="(bank.state | async).loading"
  (actionBtn)="navigate()"
  [actionText]="'Create Bank'"
></section-search-header>

<div>
  <router-outlet
    (activate)="childRoute = true"
    (deactivate)="childRoute = false"
  ></router-outlet>
</div>

<div class="container-fluid chaka-route-content" *ngIf="!childRoute">
  <div class="row">
    <div
      class="col-xl-3 col-md-3 px-3"
      *ngFor="let _bank of (bank.state | async).banks"
    >
      <div
        (click)="navigate(_bank.id)"
        class="kt-portlet kt-portlet--height-fluid"
      >
        <div class="kt-portlet__head kt-portlet__head--noborder">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title"></h3>
          </div>
          <div class="kt-portlet__head-toolbar"></div>
        </div>
        <div class="kt-portlet__body">
          <!--begin::Widget -->
          <div class="kt-widget kt-widget--user-profile-2">
            <div class="kt-widget__head">
              <div class="kt-widget__media">
                <!-- <img
                  class="kt-widget__img kt-hidden-"
                  [src]="_channel.imageUrl"
                  alt="image"
                /> -->
              </div>
              <div class="kt-widget__info">
                <a class="kt-widget__username">
                  {{ _bank.name }}
                </a>
              </div>
            </div>
            <div class="kt-widget__body">
              <div class="kt-widget__item">
                <div class="kt-widget__contact">
                  <span class="kt-widget__label">Code:</span>
                  <a class="kt-widget__data"> {{ _bank.code }} </a>
                </div>
              </div>
            </div>
            <div class="kt-widget__footer">
              <button
                (click)="navigate(_bank.id)"
                type="button"
                class="btn btn-label-success btn-lg btn-upper"
              >
                View Details
              </button>
            </div>
          </div>

          <!--end::Widget -->
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 text-left">
      <lib-pagination
        (paginate)="paginate($event)"
        [count]="(bank.state | async).count"
      ></lib-pagination>
    </div>
  </div>
</div>
