import { Injectable } from '@angular/core';
import {
  APIResponse,
  EnterpriseAPIService
} from '../../../../../api/src/public-api';
import { BankSetting } from './settings.interface';

@Injectable()
export class BankSettingService {
  constructor(private http: EnterpriseAPIService) {}

  getBankSettings(searchParams?) {
    return this.http.get<APIResponse<BankSetting>>(
      `/api/v1/banks`,
      searchParams
    );
  }

  retrieve(id: number) {
    return this.http.get<APIResponse<BankSetting>>(`/api/v1/banks/${id}`);
  }

  update(bank: Partial<BankSetting>) {
    return this.http.put<APIResponse<BankSetting>>(`/api/v1/banks`, bank);
  }

  create(bank: Partial<BankSetting>) {
    return this.http.post<APIResponse<BankSetting>>(`/api/v1/banks`, bank);
  }

  remove(id: number) {
    return this.http.delete<APIResponse<BankSetting>>(`/api/v1/banks/${id}`);
  }
}
