import { InstrumentModel } from '../../models/instrument.interface';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "kt-settings-instrument-list",
  templateUrl: "./instrument-list.component.html",
  styleUrls: ["./instrument-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstrumentListComponent implements OnInit {
  @Input() instruments: InstrumentModel[];

  @Output() view = new EventEmitter<InstrumentModel>();

  constructor() { }

  // tslint:disable-next-line: typedef
  ngOnInit() { }
}
