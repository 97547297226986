import { NewsGridComponent } from './news-grid/news-grid.component';
import { NewsCardComponent } from './news-card/news-card.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    NewsCardComponent,
    NewsGridComponent

  ],
  imports: [
    CommonModule
  ],
  exports: [NewsCardComponent
, NewsGridComponent
,RouterModule],
})
export class NewsSharedModule {}
