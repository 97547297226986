import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsoleRouterService } from '@console/shared';
import { PRODUCT_TYPE_ENUM } from '@console/shared/_enums';
import { Currency } from '@console/shared/_enums/currency.enum';
import { first, skipWhile } from 'rxjs/operators';
import { WalletTypes } from '../../providers/wallet.interface';
import { PayoutAccountStateService } from '../../states/payout-account.state';
import { WalletWithdrawalStateService } from '../../states/withdrawal.state';

@Component({
  selector: 'kt-user-accounts-withdraw',
  templateUrl: './withdraw.component.html',
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  withdrawalForm: FormGroup;

  wallets = [
    {
      label: 'Local Wallet (NGN)',
      value: WalletTypes.LOCAL,
    },
    {
      label: 'Dollar Wallet (USD)',
      value: WalletTypes.GLOBAL,
    },
  ];

  @Input() userId: string;

  constructor(
    private userFB: FormBuilder,
    public withdrawalState: WalletWithdrawalStateService,
    public payout: PayoutAccountStateService,
    private consoleRouter: ConsoleRouterService
  ) {}

  ngOnInit() {
    if (this.consoleRouter.product === PRODUCT_TYPE_ENUM.advisory) {
      this.wallets = this.wallets.filter(
        ({ value }) => value !== WalletTypes.LOCAL
      );
    }

    this.createForm();
    this.payout.retrive(this.userId);
  }

  onSubmit() {
    const { value } = this.withdrawalForm;
    value.userId = this.userId;

    if (this.consoleRouter.product === PRODUCT_TYPE_ENUM.advisory) {
      value.currency = Currency.USD;
    }else {
      value.currency = Currency.NGN;
    }

    this.payout.state
      .pipe(
        skipWhile((state) => !state.account),
        first()
      )
      .subscribe((state) => {
        this.withdrawalState.withdrawFromAccount(value, state.account);
      });
  }

  private createForm() {
    this.withdrawalForm = this.userFB.group({
      from: [WalletTypes.GLOBAL, Validators.required],
      amount: [0, Validators.required],
    });
  }

  ngOnDestroy() {
    this.payout.resetState();
    this.withdrawalState.resetState();
  }
}
