<div class="row">
  <div class="col-12">
    <form [formGroup]="pepForm">
      <div class="form-row">
        <div class="col-lg-12 form-group">
          <label for="pepStatus">Are you politically Exposed?</label>
          <select
          formControlName="pepStatus"
           class="form-control" id="pepStatus">
            <option
            *ngFor="let item of politicallyExposed"
            [value]="item.value"
            >{{ item.name }}</option>
          </select>
        </div>
        <div class="col-lg-12 form-group">
          <label for="pepNames"> Name of Politically Exposed Person(s) </label>
          <input
            class="form-control"
            type="text"
            formControlName="pepNames"
          />
        </div>
      </div>
    </form>
  </div>
</div>
