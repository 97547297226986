import { Injectable } from '@angular/core';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { Observable } from 'rxjs';
import {
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/lib/api.interface';
import { EnterpriseAPIService } from '../../../../../api/src/lib/enterprise.service';
import {
  MerchantCredentials,
  MerchantDetailsModel,
  MerchantsModel,
} from '../merchants.interface';

const API_MERCHANTS = 'api/v1/merchants';

@Injectable()
export class MerchantService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  getMerchants(searchParams?): Observable<PaginatedList<MerchantsModel>> {
    const merchants$ = this.http.get<PaginatedList<MerchantsModel>>(
      API_MERCHANTS,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(merchants$);
  }

  getMerchantById(id: number): Observable<ReqSuccessResponse<MerchantsModel>> {
    const url = API_MERCHANTS + `/${id}`;
    const merchant$ = this.http.get<ReqSuccessResponse<MerchantsModel>>(url);

    return this.loadingService.showLoaderUntilCompleted(merchant$);
  }

  getMerchantCredentials(): Observable<
    ReqSuccessResponse<MerchantCredentials>
  > {
    const url = `${API_MERCHANTS}/client-details`;
    return this.http.get<ReqSuccessResponse<MerchantCredentials>>(url);
  }

  getMerchantCredentialsById(
    id: string
  ): Observable<ReqSuccessResponse<MerchantCredentials>> {
    const url = `${API_MERCHANTS}/client-details/${id}`;
    const request$ =
      this.http.get<ReqSuccessResponse<MerchantCredentials>>(url);

    return this.loadingService.showLoaderUntilCompleted(request$);
  }

  updateMerchant(
    merchant: MerchantsModel
  ): Observable<ReqSuccessResponse<MerchantCredentials>> {
    return this.http.put<ReqSuccessResponse<MerchantCredentials>>(
      API_MERCHANTS,
      merchant
    );
  }

  createMerchant(
    merchant: MerchantDetailsModel
  ): Observable<ReqSuccessResponse<MerchantCredentials>> {
    return this.http.post<ReqSuccessResponse<MerchantCredentials>>(
      API_MERCHANTS,
      merchant
    );
  }

  deleteMerchant(
    id: number
  ): Observable<ReqSuccessResponse<MerchantCredentials>> {
    const url = API_MERCHANTS + `/${id}`;
    return this.http.delete<ReqSuccessResponse<MerchantCredentials>>(url);
  }
}
