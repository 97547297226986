<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <ngx-spinner
      [name]="withdrawalState.spinnerName"
      bdColor="#4e73df66"
      size="medium"
      color="#fff"
      type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">
        {{ (withdrawalState.state | async)?.message }}
      </p>
    </ngx-spinner>
    <div class="card-header bg-light py-3">
      <a
        *ngIf="!withdrawalId"
        (click)="goBack()"
        style="color: white;"
        class="btn btn-primary float-right"
        matTooltip="Back to the users list">
        <i class="la la-arrow-left"></i>
        <span style="color: white" class="kt-hidden-mobile">Back</span>
      </a>
      <a
        *ngIf="canApprove$ | async"
        class="btn btn-success float"
        style="color: white"
        href="javascript:;"
        (click)="approve()">
        <i class="las la-check"></i>
        <span class="kt-hidden-mobile">Approve</span>
      </a>
    </div>

    <div class="container container-fluid">
      <div class="kt-form mt-2">
        <div class="kt-form__section kt-form__section--first">
          <div *ngIf="(withdrawalState.state | async).withdrawal" class="kt-form">
            <div class="row">
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>User</h6>
                <h5
                  (click)="goToUserProfile()">
                  <a class="pretty-link">{{ (withdrawalState.state | async).withdrawal?.modifiedBy || "-" }}</a>
                </h5>
              </div>

              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Product Type</h6>
                <h5>{{ (withdrawalState.state | async).withdrawal?.productType || "-" }}</h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Reference</h6>
                <h5>{{ (withdrawalState.state | async).withdrawal?.reference || "-" }}</h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Source</h6>
                <h5>{{ (withdrawalState.state | async).withdrawal?.source || "-" }}</h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Amount</h6>
                <h5>
                  {{
                     formatToCurrency((withdrawalState.state | async).withdrawal?.currency, (withdrawalState.state | async).withdrawal?.amount)
                  }}
                </h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Date</h6>
                <h5>{{ (withdrawalState.state | async).withdrawal?.dateCreated | date: "medium" }}</h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Email</h6>
                <h5>{{ (withdrawalState.state | async).withdrawal?.email  }}</h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>userId</h6>
                <h5>{{ (withdrawalState.state | async).withdrawal?.userId }}</h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Approved Status</h6>
                <h5
                  class="kyc-status"
                  [ngClass]="getStatusColor((withdrawalState.state | async).withdrawal?.authorizationStatus)">
                  {{ (withdrawalState.state | async).withdrawal?.authorizationStatus ? 'Approved' : 'Pending' }}
                </h5>
              </div>
              <div class="col-sm-6 kt-margin-bottom-20">
                <h6>Processing Status</h6>
                <h5
                  class="kyc-status"
                  [ngClass]="getStatusColor((withdrawalState.state | async).withdrawal?.status)">
                  {{ (withdrawalState.state | async).withdrawal?.hasBeenProcessed ? 'Processed' : 'Pending' }}
                </h5>
              </div>
              <div class="col-12 kt-margin-bottom-20">
                <h6>Description</h6>
                <h5>{{ (withdrawalState.state | async).withdrawal?.reason || "-" }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
