
/**
 * Portfolio Service
 *  This service handles the performance details and positions
 * of the user.
 */

import { Injectable } from '@angular/core';
import { EnterpriseAPIService, ReqSuccessResponse } from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { Currency } from '@console/shared/_enums/currency.enum';
import { AccountSummary } from '../models/positions.interface';

const API_POSITIONS = 'api/v1/assets';

@Injectable()
export class UserAssetService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService) { }

  /**
   * returns the DW account summary of the user.
   */
  getPositions(userId: string, currencyCode = Currency.USD) {
    const url = `${API_POSITIONS}/positions/${userId}?currencyCode=${currencyCode}`;
    const positions$ = this.http.get<ReqSuccessResponse<AccountSummary>>(url);

    return this.loadingService.showLoaderUntilCompleted(positions$);
  }

  /**
   * returns the performance of instruments over a particular
   * period
   */
  getPerformance(params) {
    const userId = params.userId;
    delete params.userId;
    const url = `${API_POSITIONS}/performance/${userId}`;
    return this.http.get<ReqSuccessResponse<AccountSummary>>(url, params);
  }


}
