import { ReferralSettingsComponent } from './referral-settings.component';
import { SharedModule } from '../../../../shared/src/lib/shared.module';
import { ReferralDialogComponent } from './components/referral-dialog/referral-dialog.component';
import { ReferralService } from './service/referral-service.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MerchantListStateService } from '../merchants/merchant-list/merchant-list.state';

@NgModule({
  declarations: [
    ReferralSettingsComponent,
    ReferralDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    ReferralService,
    MerchantListStateService
  ]
})

export class ReferralSettingsModule { }