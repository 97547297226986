<div class="d-flex flex-column-fluid">
  <div class="container-fluid">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-toolbar w-100 justify-content-between">
          <div>
            <ng-container *ngIf="accountManager">Manager: {{accountManager.name}}</ng-container>
          </div>

          <button
            (click)="back()"
            class="btn btn-transparent border">
            <i class="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div class="card-body">
        <lib-messages></lib-messages>

        <div class="row">
          <div class="col-12">
            <div
              class="table-responsive"
              [hidden]="(dataSource.data && dataSource.data?.length <= 0)">

              <div class="table-container">
                <table
                  class="table table-head-custom table-head-bg table-vertical-center table-borderless"
                  mat-table [dataSource]="dataSource">

                  <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Name</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">
                        <a
                          href="javascript:void(0)"
                          (click)="viewUser(row?.userId)">
                          {{row?.fullName || "-"}}</a>
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Gender</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.gender}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Phone</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.phone }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Email</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.email }}</span>
                    </td>
                  </ng-container>

                  <thead>
                    <tr class="bg-gray-100 text-left" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  </thead>

                  <tbody>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                  </tbody>
                </table>
              </div>

              <mat-paginator
                class="mat-paginator-sticky"
                [length]="count"
                [pageSizeOptions]="[10, 50, 100, 150, 200]"
                (page)="pageEvent=$event; paginate($event)"
                showFirstLastButtons></mat-paginator>

            </div>

            <div *ngIf="dataSource.data?.length <= 0 && !loading" class="no-data-container d-flex flex-column justify-content-center align-items-center">
              <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
              <p class="no-data-text">No data</p>
            </div>
          </div>
        </div>


      </div>
    </div>

    <ng-template #noData>
      <div class="card card-custom" *ngIf="!loading">
        <div class="card-body">
          <lib-messages></lib-messages>

          <div
            class="no-data-container d-flex flex-column justify-content-center align-items-center">
            <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
            <p class="no-data-text">No data</p>

            <div>
              <button
                class="btn btn-primary mr-3"
                (click)="fetchAccountManagerUsers()">Retry</button>
              <button
                (click)="back()"
                class="btn btn-transparent border">
                <i class="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div>

      </div>
    </ng-template>

  </div>
</div>
