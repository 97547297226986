import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InvestmentDetail } from './investment-form.inteface';
import {
  incomeBrackets,
  investmentExperiences,
  investmentObjectives,
  portFolioTypes,
  riskTolerances,
} from './investment.options';
import { KYCFormControl } from '../kyc-form-control';

@Component({
  selector: 'lib-user-investment-form',
  templateUrl: './user-investment-form.component.html',
  styleUrls: ['./user-investment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInvestmentFormComponent extends KYCFormControl<InvestmentDetail> implements OnInit {
  @Input() private investmentDetails: InvestmentDetail;
  investmentForm: FormGroup;

  investmentExperiences = investmentExperiences;
  investmentObjectives = investmentObjectives;
  portFolioTypes = portFolioTypes;
  riskTolerances = riskTolerances;

  incomeBrackets = incomeBrackets;

  constructor(private fb: FormBuilder) {
    super();
  }

  private createForm(details: Partial<InvestmentDetail> = {}) {
    const form =  this.fb.group({
      investmentObjective: [details.investmentObjective || ''],
      investmentExperience: [details.investmentExperience || ''],
      riskTolerance: [details.riskTolerance || ''],
      annualIncome: [details.annualIncome || ''],
      networthTotal: [details.networthTotal || ''],
      networthLiquid: [details.networthLiquid || ''],
      portfolioType: [details.portfolioType || ''],
    });
    if (!details.portfolioType) {
      form.get('portfolioType').disable();
    }
    return form;
  }

  ngOnInit(): void {
    this.investmentForm = this.createForm(this.investmentDetails);
    if (this.disabled) {
      this.investmentForm.disable()
    }
    this.listen(this.investmentForm);
  }
}
