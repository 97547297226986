import { CURRENCY } from '@console/shared';

/**
 * Payemnt gateways
 */
export enum PaymentGateway {
  INTERSWITCH = 'INTERSWITCH',
  PAYSTACK = 'PAYSTACK',
  MONNIFY = 'MONNIFY',
  OKRA = 'OKRA',
  NIP = 'NIP',
}

/**
 * current platform which it's been used, note NODEJS
 */
export enum Platform {
  WEB = 'WEB',
  API = 'API',
  MOBILE = 'MOBILE',
  NODEJS = 'API',
}

/**
 * Generates a payment reference before paying on the platform
 */
export interface PaymentReference {
  /**
   * the payment gateway for the payment
   */
  processor: PaymentGateway;
  /**
   * the platform which the request was made
   */
  source: Platform;

  amount: number;
  currency: CURRENCY;
  description?: string;
  userId: string;
}

/**
 * Sends a transactional history to the server.
 */
export interface ConfirmPayment {
  /**
   * The generated payment reference
   */
  transactionReference: string;
  /**
   * amount paid
   */
  amount: number;
  /**
   * payment gateway used
   */
  source: PaymentGateway;
  /**
   * Payment currency used
   */
  currency: CURRENCY;
  status?: boolean;
  statusCode?: string;
}

export interface ConfirmedPayment {
  amount: number;
  authorizationStatus: true;
  channel: Platform;
  convertedCurrencyCode: CURRENCY;
  createdBy: string;
  currencyCode: CURRENCY;
  dateCreated: string;
  email: string;
  fee: number;
  id: number;
  maskedCardPan: string;
  paymentReference: string;
  processor: PaymentGateway;
  productType: string;
  responseCode: string;
  responseDescription: string;
  source: string;
  status: string;
  transactionReference: string;
}

/**
 * Exchange rate breakdown.
 */
export interface ExchangeRate {
  id: number;
  buy: number;
  sell: number;
  merchantID: number;
  createdBy: string;
  modifiedBy: string;
  dateCreated: string;
  dateModified: string;
}

export interface NipPaymentRequest {
  transactionReference: string;
  amount: number;
  currency: CURRENCY;
  userId: string;
  description: string;
}


export interface NipPaymentResponse {
  amount: number;
  responseMessage: number;
  responseCode: number;
  transactionDate: string;
}
