import { ISuitabilityQuestionOption } from './../../interface/ISuitabilityQuestions';
import { MatSidenav } from '@angular/material/sidenav';
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewChecked, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { MatStepper } from '@angular/material/stepper';
import { ISuitabilityQuestion } from '../../interface/ISuitabilityQuestions';
import { SuitabilityService } from '../../services/suitability-questions.service';
@Component({
  selector: 'question-drawer',
  templateUrl: './question-drawer.component.html',
  styleUrls: ['./question-drawer.component.scss'],
  providers: [MessagesService]
})

export class QuestionDrawerComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() drawer: MatSidenav;

  @ViewChild('scrollDiv') scrollDiv: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  questionForm: FormGroup;
  questionOptionsForm: FormGroup;

  @Input() categories = [];
  @Input() activeQuestion: ISuitabilityQuestion;

  @Output() saveSuccess = new EventEmitter();

  constructor(
    public messagesService: MessagesService,
    private suitabilityService: SuitabilityService
  ) { }

  ngOnInit(): void {

    this.initQuestionForm();
    this.initFeeBandsForm();

    this.drawer.openedChange
      .subscribe(isOpened => {
        if (isOpened) {
          this.initQuestionForm();
          this.initFeeBandsForm();
          !!this.activeQuestion && this.fillForms();
        } else {
          this.questionForm.reset();
          this.questionOptionsForm.reset();
          this.activeQuestion = null;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.firstChange) {
      return;
    }

    if (!changes?.activeQuestion) {
      return;
    }

    this.activeQuestion = changes.activeQuestion.currentValue;
  }

  initQuestionForm(): void {
    this.questionForm = new FormGroup({
      id: new FormControl(''),
      category: new FormControl('', Validators.required),
      question: new FormControl('', Validators.required),
      subjectName: new FormControl('', Validators.required)
    });
  }

  initFeeBandsForm(): void {
    this.questionOptionsForm = new FormGroup({
      questionOptions: new FormArray([
        new FormGroup({
          id: new FormControl(''),
          text: new FormControl(
            null,
            Validators.required
          ),
          weight: new FormControl(
            null,
            Validators.required
          ),
        })
      ])
    });
  }

  fillForms(): any {
    this.patchQuestionForm();

    this.patchQuestionOptionsForm();
  }

  private patchQuestionForm(): void {
    this.questionForm.patchValue({
      id: this.activeQuestion?.id,
      category: this.activeQuestion?.category,
      question: this.activeQuestion?.question,
      subjectName: this.activeQuestion?.subjectName
    });

    this.questionForm.updateValueAndValidity();
  }

  private patchQuestionOptionsForm(): void {
    const questionOptions = new FormArray([]);

    if (this.activeQuestion?.options && this.activeQuestion?.options.length > 0) {
      for (const option of this.activeQuestion?.options) {
        const group = new FormGroup({
          id: new FormControl(option?.id),
          text: new FormControl(option?.text, [Validators.required]),
          weight: new FormControl(option?.weight, [Validators.required])
        });

        questionOptions.push(group);
      }

      this.questionOptionsForm.setControl('questionOptions', questionOptions);
    }

    this.questionOptionsForm.updateValueAndValidity();
  }

  get questionsOptionsControls(): AbstractControl[] {
    return (this.questionOptionsForm.get('questionOptions') as FormArray).controls;
  }

  onAddQuestion(): any {
    if (!this.questionOptionsForm.valid) {
      this.questionOptionsForm.markAllAsTouched();
      return;
    }

    const group = new FormGroup({
      text: new FormControl('',
        Validators.required
      ),
      weight: new FormControl(null,
        Validators.required
      )
    });

    (this.questionOptionsForm.get('questionOptions') as FormArray).push(group);
  }

  onRemoveQuestion(): void {
    const lastIndex = this.questionsOptionsControls.length - 1;

    const optionId = this.questionsOptionsControls[lastIndex].get('id')?.value;

    const isUpdateMode = !!this.questionsOptionsControls[lastIndex] && !!optionId;

    if (!isUpdateMode) {
      this.removeOptionControl(lastIndex);
      return;
    }

    // We get here if we are in update mode
    const check = confirm(`Do you want remove this question option?`);

    if (!check) {
      return;
    }

    // we call endpoint to delete first
    this.messagesService
      .open(
        MessagesEnum.loading,
        'Deleting question option, please wait...',
        { hideAll: true });

    this.suitabilityService
      .deleteOption(optionId)
      .subscribe(
        {
          next: () => {
            this.messagesService.update(
              {
                type: MessagesEnum.success,
                message: 'Question option deleted successfully.'
              },
              {
                callBack: () => this.removeOptionControl(lastIndex)
              }
            );
          },
          error: (error: ChakaAPIError) => {
            const message = cleanChakaAPIError(error);

            this.messagesService.update({
              type: MessagesEnum.danger,
              message
            });
          }
        }

      );
  }


  private removeOptionControl(index: number): void {
    (this.questionOptionsForm
      .get('questionOptions') as FormArray)
      .removeAt(index);
  }

  get selectedIndex(): number {
    return this.stepper.selectedIndex;
  }

  set selectedIndex(value: number) {
    this.stepper.selectedIndex = value;
  }

  backStep(): void {
    this.stepper.previous();
  }

  nextStep(): void {
    if (!this.questionForm.valid) {
      this.questionForm.markAllAsTouched();
      return;
    }

    this.stepper.next();
  }

  onSave(): void {
    if (!this.questionForm.valid) {
      if (this.selectedIndex === 1) {
        this.selectedIndex = 0;
      }

      this.questionForm.markAllAsTouched();
      return;
    }

    const { id: questionId, ...theRestQuestion } = this.questionForm.value;

    const dataToSubmit: Partial<ISuitabilityQuestion> = questionId ?
      { id: questionId, ...theRestQuestion } :
      { ...theRestQuestion };

    if (!this.questionOptionsForm.valid) {
      if (this.selectedIndex === 0) {
        this.selectedIndex = 1;
      }

      this.questionOptionsForm.markAllAsTouched();
      return;
    } else {
      const questionOptionsValue = [...this.questionOptionsForm.get('questionOptions').value];

      const questionOptions: ISuitabilityQuestionOption[] = questionOptionsValue
        .map(({ id: questionOptionId, ...theRestQuestionOption }) => {

          return questionOptionId ?
            { id: questionOptionId, ...theRestQuestionOption } :
            { ...theRestQuestionOption };
        });

      dataToSubmit.options = [...questionOptions];
    }

    this.submitQuestion(dataToSubmit);
  }

  private submitQuestion(dataToSubmit: Partial<ISuitabilityQuestion>): void {
    const isUpdate = dataToSubmit.hasOwnProperty('id');

    const saveMessage = `${isUpdate ? 'Updating' : 'Saving'} suitability question...`;

    const successMessage = `Successfully ${isUpdate ? 'updated' : 'saved'} suitability question.`;

    this.messagesService.open(MessagesEnum.loading, saveMessage, { hideAll: true });

    this.suitabilityService
      .saveQuestion(dataToSubmit)
      .subscribe({
        next: (response) => {

          this.activeQuestion = response.data;

          this.patchQuestionForm();
          this.patchQuestionOptionsForm();

          this.messagesService.update({
            type: MessagesEnum.success,
            message: successMessage
          });

          this.saveSuccess.emit();
        },
        error: (error: ChakaAPIError) => {
          const message = cleanChakaAPIError(error);

          this.messagesService.update({
            type: MessagesEnum.danger,
            message
          });
        }
      });
  }

  closeDrawer(): void {
    this.messagesService.hideAll();

    this.drawer.close();
  }

  scrollToBottom(): void {
    try {
      this.scrollDiv.nativeElement.scrollTop = this.scrollDiv.nativeElement.scrollHeight;
    } catch (err) { }
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

}
