import { TagModel } from './../../services/tags.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'kt-tag-grid',
  templateUrl: './tag-grid.component.html',
  styleUrls: ['./tag-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagGridComponent implements OnInit {
  @Input() tags: TagModel[] = [];

  @Output() tagClick = new EventEmitter<TagModel>();

  constructor() { }

  dynamicBtnStyle(index: number) {
    const reminder = (index + 1) % 3;
    if (reminder === 1) { return 'success'; }
    if (reminder === 2) { return 'danger'; }
    return 'primary';
  }

  ngOnInit() { }
}
