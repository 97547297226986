import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { WalletTxnService } from '../../transactions/services/transactions.service';
import {
  ReqSuccessResponse,
  ChakaAPIError,
  cleanChakaAPIError,
} from '../../../../../api/src/public-api';
import { WalletTxnDetailsModel, WalletTxnsModel } from '../../transactions/services/transactions.model';

export interface WithdrawalState {
  withdrawal?: WalletTxnsModel;
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: WithdrawalState = { loading: false };

@Injectable({ providedIn: 'root' })
export class WithdrawalStateService {
  state = new BehaviorSubject<WithdrawalState>(initialState);

  spinnerName = 'Withdrawal-State';

  constructor(
    private Withdrawal: WalletTxnService,
    private spinner: NgxSpinnerService
  ) {}

  retrieve(WithdrawalId: string) {
    this.loading(`Please wait, while we are retrieve Withdrawal transaction`);
    this.Withdrawal.getWithdrawalTxnById(WithdrawalId).subscribe({
      next: this.onUserOpenWithdrawalSLoaded(
        `Withdrawal successfully retrieved`
      ).bind(this),
      error: this.onUserOpenWithdrawalSError.bind(this),
    });
  }

  approve(WithdrawalId: string | number) {
    this.loading(`Please wait, while we approve the open Withdrawals`);
    this.Withdrawal.approve(WithdrawalId as string).subscribe({
      next: this.onUserOpenWithdrawalSLoaded(
        `Thanks, Withdrawal approval is in progress.`
      ).bind(this),
      error: this.onUserOpenWithdrawalSError.bind(this),
    });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(message: string) {
    this.state.next({ ...this.state.getValue(), loading: true, message });
    this.spinner.show(this.spinnerName);
  }

  private onUserOpenWithdrawalSLoaded(message: string) {
    return ({ data }: ReqSuccessResponse<WalletTxnsModel>) => {
      this.state.next({
        ...this.state.getValue(),
        loading: false,
        withdrawal: data,
        message,
      });
      this.spinner.hide(this.spinnerName);
    };
  }

  private onUserOpenWithdrawalSError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      loading: false,
      message: error,
    });
    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2000);
  }
}
