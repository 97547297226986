import { Component, OnInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { WithdrawalStateService } from './withdrawal.state';
import { WALLET_TRANSACTION_STATUS } from '../../../../../shared/src/lib/_enums/wallet-transaction-status.enum';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { formatToCurrency } from '../../../../../shared/src/lib/utils/formatter';

@Component({
  selector: 'kt-withdrawals-edit',
  templateUrl: './withdrawals-edit.component.html',
  styleUrls: ['./withdrawals-edit.component.scss'],
})
export class WithdrawalEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() withdrawalId: string;

  formatToCurrency = formatToCurrency;

  constructor(
    private route: ActivatedRoute,
    private router: ConsoleRouterService,
    public withdrawalState: WithdrawalStateService
  ) {}

  approve() {
    this.withdrawalState.state
      .pipe(first())
      .subscribe((state) => this.withdrawalState.approve(state.withdrawal.id));
  }

  getStatusColor(status: WALLET_TRANSACTION_STATUS) {
    if (status === WALLET_TRANSACTION_STATUS.PENDING) return 'amber';
    if (status === WALLET_TRANSACTION_STATUS.INITIATED) return 'brand';
    if (
      status === WALLET_TRANSACTION_STATUS.WAITING ||
      status === WALLET_TRANSACTION_STATUS.PROCESSING
    )
      return 'white';
    if (
      status === WALLET_TRANSACTION_STATUS.CANCELLED ||
      status === WALLET_TRANSACTION_STATUS.FAILED ||
      status === WALLET_TRANSACTION_STATUS.REJECTED
    )
      return 'danger';
    return 'success';
  }

  goToUserProfile() {
    this.withdrawalState.state
      .pipe(
        first(),
        map((state) => state?.withdrawal?.userId)
      )
      .subscribe((id) => {
        this.router.productNavigate(['users', id]);
      });
  }

  goBack() {
    this.router.goBack();
    // this.router.productNavigate(['withdrawals']);
  }

  get canApprove$() {
    return this.withdrawalState.state.pipe(
      map((state) => !state?.withdrawal?.authorizationStatus)
    );
  }

  private retrieveWithdrawal() {
    if (this.withdrawalId) {
      this.withdrawalState.retrieve(this.withdrawalId);
    } else {
      this.route.params
        .pipe(first())
        .subscribe((params) => this.withdrawalState.retrieve(params.id));
    }
  }

  ngOnInit() {
    this.retrieveWithdrawal();
  }

  ngOnChanges() {
    if (this.withdrawalId) {
      this.withdrawalState.retrieve(this.withdrawalId);
    }
  }

  ngOnDestroy() {
    this.withdrawalState.resetState();
  }
}
