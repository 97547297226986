<ng-container *ngIf="drawer.opened">
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Setup Mandate</span
        >
      </h3>
      <div class="card-toolbar">
        <button class="close" mat-button (click)="closeDrawer()">X</button>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div
        #scrollDiv
        style="height: 800px; overflow-y: auto"
        class="scrolling-div"
      >
        <lib-messages></lib-messages>

        <form [formGroup]="mandateForm" (ngSubmit)="save()">
          <div class="form-group" *ngIf="!selectedUser">
            <label>Search for a user <span class="text-danger">*</span></label>

            <ng-select
              [items]="(userList$.state | async).users"
              bindLabel="fullName"
              autofocus
              bindValue="userId"
              [formControl]="userControl"
              (input)="debounceSearchUser($event)"
              (change)="assignUser($event)"
            >
            </ng-select>
          </div>

          <div class="form-group" *ngIf="!!selectedUser">
            <!--begin::Item-->
            <div class="d-flex flex-wrap align-items-center mb-10">
              <!--begin::Symbol-->
              <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                <div
                  *ngIf="selectedUser?.profileImageUrl; else initials"
                  class="symbol symbol-circle symbol-lg-50"
                >
                  <img
                    [src]="selectedUser?.profileImageUrl"
                    [alt]="selectedUser?.fullName"
                  />
                </div>

                <ng-template #initials>
                  <div class="symbol symbol-lg-50 symbol-circle symbol-primary">
                    <span class="symbol-label font-size-h4">
                      {{
                        selectedUser?.firstName
                          ? selectedUser.firstName[0].toUpperCase()
                          : ""
                      }}
                      {{
                        selectedUser?.lastName
                          ? selectedUser.lastName[0].toUpperCase()
                          : ""
                      }}
                    </span>
                  </div>
                </ng-template>
              </div>

              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                <a
                  href="javascript:;"
                  class="
                    text-dark-75
                    font-weight-bold
                    text-hover-primary
                    font-size-lg
                    mb-1
                  "
                  >{{ selectedUser?.fullName }}</a
                >
                <span class="text-muted font-weight-bold">{{
                  selectedUser?.email
                }}</span>
              </div>
              <!--end::Title-->
              <!--begin::Section-->
              <div
                class="d-flex align-items-center mt-lg-0 mt-3"
                *ngIf="!fromUserComponent && !this.activeMandate"
              >
                <!--begin::Btn-->
                <a
                  style="cursor: pointer"
                  class="btn btn-icon btn-light btn-sm"
                  (click)="deleteUser()"
                >
                  <span class="svg-icon svg-icon-md">
                    <i class="fas fa-times"></i>
                  </span>
                </a>
                <!--end::Btn-->
              </div>
              <!--end::Section-->
            </div>
            <!--end::Item-->
          </div>

          <hr />

          <div class="form-row" *ngIf="!!selectedUser">
            <div class="col-md-6">
              <div class="form-group">
                <label
                  >Amount
                  <span
                    *ngIf="formMethods.isRequired('amount')"
                    class="text-danger"
                    >*</span
                  ></label
                >
                <input
                  class="form-control"
                  type="number"
                  formControlName="amount"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('amount')"
                >
                  <div
                    *ngIf="
                      formMethods.getControl('amount').hasError('required')
                    "
                  >
                    Required input.
                  </div>
                  <div
                    *ngIf="formMethods.getControl('amount').hasError('pattern')"
                  >
                    Amount must be a positive number
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  >Currency
                  <span
                    *ngIf="formMethods.isRequired('currency')"
                    class="text-danger"
                    >*</span
                  >
                </label>
                <ng-select
                  formControlName="currency"
                  [appendTo]="'body'"
                  autocomplete="false"
                >
                  <ng-option
                    [value]="currency.value"
                    *ngFor="let currency of currencyList"
                  >
                    {{ currency.description }}
                  </ng-option>
                </ng-select>
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('currency')"
                >
                  <div
                    *ngIf="
                      formMethods.getControl('currency').hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  >Notification Type
                  <span
                    *ngIf="formMethods.isRequired('notificationType')"
                    class="text-danger"
                    >*</span
                  ></label
                >
                <ng-select
                  formControlName="notificationType"
                  [appendTo]="'body'"
                >
                  <ng-option
                    [value]="notificationType.value"
                    *ngFor="let notificationType of notificationTypeList"
                  >
                    {{ notificationType.description }}
                  </ng-option>
                </ng-select>
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('notificationType')"
                >
                  <div
                    *ngIf="
                      formMethods
                        .getControl('notificationType')
                        .hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  >Source
                  <span
                    *ngIf="formMethods.isRequired('source')"
                    class="text-danger"
                    >*</span
                  ></label
                >
                <ng-select
                  formControlName="source"
                  [appendTo]="'body'"
                  (ngModelChange)="onSourceChange($event)"
                >
                  <ng-option
                    [value]="paymentSource.value"
                    *ngFor="let paymentSource of paymentSourceList"
                  >
                    {{ paymentSource.description }}
                  </ng-option>
                </ng-select>
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('source')"
                >
                  <div
                    *ngIf="
                      formMethods.getControl('source').hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-6" *ngIf="showCustomerId">
              <div class="form-group">
                <label
                  >Customer Card ID
                  <span
                    *ngIf="formMethods.isRequired('customerCardId')"
                    class="text-danger"
                    >*</span
                  ></label
                >

                <ng-select
                  [items]="cardList"
                  bindValue="id"
                  formControlName="customerCardId"
                  [appendTo]="'body'"
                >
                  <!-- <ng-option
                    [value]="card.id"
                    *ngFor="let card of cardList">
                    <strong class="pr-3">{{card.card_type}}</strong>
                    <span>Last 4 digits: <strong>{{'****'+card.last4 }}</strong></span>
                  </ng-option> -->

                  <ng-template ng-label-tmp let-item="item">
                    <strong class="pr-3">{{ item?.card_type }}</strong>
                    <span
                      >Last 4 digits:
                      <strong>{{ "****" + item?.last4 }}</strong></span
                    >
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <strong class="pr-3">{{ item?.card_type }}</strong>
                    <span
                      >Last 4 digits:
                      <strong>{{ "****" + item?.last4 }}</strong></span
                    >
                  </ng-template>
                </ng-select>

                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('customerCardId')"
                >
                  <div
                    *ngIf="
                      formMethods
                        .getControl('customerCardId')
                        .hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  >Frequency
                  <span
                    *ngIf="formMethods.isRequired('frequency')"
                    class="text-danger"
                    >*</span
                  ></label
                >
                <ng-select formControlName="frequency" [appendTo]="'body'">
                  <ng-option
                    [value]="frequency.value"
                    *ngFor="let frequency of frequencyList"
                  >
                    {{ frequency.description }}
                  </ng-option>
                </ng-select>
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('frequency')"
                >
                  <div
                    *ngIf="
                      formMethods.getControl('frequency').hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-6"></div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  >Start Date
                  <span
                    *ngIf="formMethods.isRequired('startDate')"
                    class="text-danger"
                    >*</span
                  >
                </label>
                <input
                  type="date"
                  class="form-control"
                  formControlName="startDate"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('startDate')"
                >
                  <div
                    *ngIf="
                      formMethods.getControl('startDate').hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label
                  >End Date
                  <span
                    *ngIf="formMethods.isRequired('endDate')"
                    class="text-danger"
                    >*</span
                  ></label
                >
                <input
                  type="date"
                  class="form-control"
                  formControlName="endDate"
                />
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('endDate')"
                >
                  <div
                    *ngIf="
                      formMethods.getControl('endDate').hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label
                  >Comment
                  <span
                    *ngIf="formMethods.isRequired('comment')"
                    class="text-danger"
                    >*</span
                  ></label
                >
                <textarea
                  class="form-control"
                  formControlName="comment"
                ></textarea>
                <small
                  class="form-text text-danger"
                  *ngIf="formMethods.touchedAndInvalid('comment')"
                >
                  <div
                    *ngIf="
                      formMethods.getControl('comment').hasError('required')
                    "
                  >
                    Required input.
                  </div>
                </small>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--end::Body-->

    <div class="card-footer d-flex justify-content-between">
      <!-- <button
        *ngIf="stepper.selectedIndex === 0"
        type="button"
        class="btn btn-light"
        (click)="nextStep()">Next</button>
 -->

      <button type="button" class="btn btn-primary" (click)="save()">
        Save
      </button>
    </div>
  </div>
</ng-container>
