import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KycListTableComponent } from './kyc-list-table.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMultiSortModule } from 'ngx-mat-multi-sort';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { PaginationModule } from 'projects/_shared/Pagination/pagination.module';

@NgModule({
  declarations: [KycListTableComponent],
  imports: [
    CommonModule,
    PaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatMultiSortModule,
    NgxSmartModalModule
  ],
  exports: [KycListTableComponent]

})
export class KycListTableModule { }
