import { ReconciliationListStateService } from './reconciliations-state';
import { Component, Input, OnInit } from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
import { PageEvent } from '../../../../shared/src/lib/interface';
import { FormControl } from '@angular/forms';
import { BankStateService } from '@console/user-managements/sections/user-kyc-editor/bank-state.service';
import { formatToCurrency } from '../../../../shared/src/lib/utils/formatter';

@Component({
  selector: 'app-reconciliations',
  templateUrl: './reconciliations.component.html',
  styleUrls: ['./reconciliations.component.scss']
})
export class ReconciliationsComponent implements OnInit {

  search = new FormControl('');
  maxDate = new Date();
  private filter: Record<string, any> = {
    pageSize: 10,
    bankId: 1
  };
  bankId: string;
  /**
   * show date filter
   */
  @Input() datesFilter = true;


  @Input() searchFilter = true;

  /**
   * show processors filter
   */
  @Input() processorFilter = true;

  /**
   * show transaction status filter
   */
  @Input() statusFilter = true;

  /**
   * amount od transactions to show
   */
  @Input() pageSize = 10;

  /**
   * total amount of data being returned
   */
  @Input() count = 0;

  formatToCurrency = formatToCurrency;


  constructor(
    private consoleRouter: ConsoleRouterService,
    public reconciliationList: ReconciliationListStateService,
    public banks: BankStateService,
  ) { }

  ngOnInit(): void {
    this.filter.pageSize = this.pageSize;
    this.query(this.filter);
    this.banks.list();
  }

  viewWithdraws(id) {
    this.consoleRouter.productNavigate(['reconciliation', id])
  }

  handleQuery(key, value, isDate = false) {
    if (key === 'q') {
      this.filter = {
        pageSize: this.filter.pageSize
      };;
    }
    if (isDate) {
      value = new Date(value).toISOString();
    }
    this.filter[key] = value;
    this.query(this.filter);
  }

  query($event: Record<string, any>) {
    if (this.bankId) {
      $event.bankId = this.bankId;
    }
    this.reconciliationList.find($event);
  }

  paginate($event: PageEvent) {
    this.handleQuery('pageNumber', $event.pageNumber);
  }

}

