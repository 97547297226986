import {  PrinterDocHeader } from './formater';


export interface KeysConfig {
  title: string;
  dataKey: string
}

export interface PrintData<T> {
  columns: KeysConfig[];
  rows: (T & { no: number })[]
}

export function setHeight(inital: number) {
  return (increaseBy: number) => {
    inital += increaseBy;
    return inital;
  };
}

export function tableFormat<T>(
  config: KeysConfig[],
  data: T[]
): PrintData<T & { no: number }> {
  return {
    columns: config,
    rows: data.map((v, index) => Object.assign(v, { no: index + 1 }))
  };
}

interface PrintDataConfig {
  map?: (doc: any, height: number) => any;
  startY?: number;
  margin?: number;
  filename: string
}

export function addTable<T>(
  { height, doc }: PrinterDocHeader,
  keys: KeysConfig[],
  data: T[],
  config: PrintDataConfig
) {
  const { columns, rows } = tableFormat(keys, data);
  if (config.map) {
    const mapped = config.map(doc, height);
    doc = mapped.doc;
    height = mapped.height;
  }
  doc.autoTable(columns, rows, {
    startY: config.startY || height,
    margin: config.margin || 10
  });
  return doc;
}

// TODO: Open pdf in electron pdf plugin and display the pdf preview before printing
export function printPdf(doc: any, config: PrintDataConfig) {
  // this.printPdfUrl(await fileBase64(doc.output('blob') as any));
  doc.autoPrint();
  doc.save(`${config.filename}.pdf`);
}
