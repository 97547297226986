import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@console/shared';
import { InstrumentSharedModule } from 'projects/view/src/lib/instrument/componenet/instrument-shared.module';
import { AccountInfoComponent } from '../account-info/account-info.component';
import { EquityPositionsTableComponent } from '../equity-positions-table/equity-positions-table.component';
import { EquityPostitionComponent } from '../equity-postition/equity-postition.component';
import { InstrumentListingComponent } from '../instrument-listing/instrument-listing.component';
import { OpenOrdersComponent } from '../open-orders/open-orders.component';
import { OrderFormComponent } from '../order-form/order-form.component';
import { PreOrderDisplayComponent } from '../pre-order-display/pre-order-display.component';
import { RecieptDisplayComponent } from '../reciept-display/reciept-display.component';
import { UserPortfolioOverviewComponent } from './user-portfolio-overview.component';

const components = [
  EquityPostitionComponent,
  InstrumentListingComponent,
  OpenOrdersComponent,
  OrderFormComponent,
  PreOrderDisplayComponent,
  RecieptDisplayComponent,
  UserPortfolioOverviewComponent,
  AccountInfoComponent,
  EquityPositionsTableComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InstrumentSharedModule,
    SharedModule,
  ],
  exports: components,
  declarations: components,
  providers: [],
})
export class PortfolioComponentsModule {}
