<ng-container *ngIf="messagesList$ | async as messageList">
  <ng-container *ngFor="let message of messageList" class="md-4">
    <!-- alert-custom alert-notice alert-light-primary -->
    <div
      *ngIf="message?.isOpened"
      [class]="'alert alert-custom alert-' + resolveType(message?.type)"
      role="alert"
    >
      <div class="alert-icon">
        <span
          [class]="
            'svg-icon svg-icon-' +
            resolveIconType(message?.type) +
            ' svg-icon-xl'
          "
        >
          <ng-container [ngSwitch]="message?.type">
            <svg
              *ngSwitchCase="messageTypes.success"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                <path
                  d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                />
              </g>
            </svg>

            <svg
              *ngSwitchCase="messageTypes.danger"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                <path
                  d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                  fill="#000000"
                />
              </g>
            </svg>

            <svg
              *ngSwitchCase="messageTypes.info"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                <path
                  d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z"
                  fill="#000000"
                />
              </g>
            </svg>

            <svg
              *ngSwitchCase="messageTypes.warning"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                <rect
                  fill="#000000"
                  x="11"
                  y="16"
                  width="2"
                  height="2"
                  rx="1"
                />
              </g>
            </svg>

            <svg
              *ngSwitchCase="messageTypes.loading"
              class="fa-spin"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g>
                  <polygon points="0 0 24 0 24 24 0 24" />
                </g>
                <path
                  d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                />
              </g>
            </svg>
          </ng-container>
        </span>
      </div>
      <div class="alert-text">{{ message?.message }}</div>

      <div class="alert-close">
        <button type="button" class="close" (click)="onClose(message.id)">
          <i class="far fa-times-circle"></i>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
