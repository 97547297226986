<div class="row">
  <div class="col-12">
    <form [formGroup]="biodataForm">
      <div class="form-row">
        <div class="col-lg-6 form-group">
          <label for="firstName">First Name</label>
          <input
            id="firstName"
            class="form-control"
            formControlName="firstName" />
        </div>
        <div class="col-lg-6 form-group">
          <label for="lastName">Last Name</label>
          <input
            id="lastName"
            class="form-control"
            formControlName="lastName" />
        </div>
        <div class="col-lg-6 form-group">
          <label for="lastName">Middle Name</label>
          <input class="form-control" formControlName="middleName" />
        </div>
        <div class="col-lg-6 form-group">
          <label for="disclosureName"> Disclosure Name </label>
          <input class="form-control" formControlName="disclosureName" />
        </div>

        <div class="col-lg-6 form-group">
          <!-- (valueChange)="onSelectChange('gender', $event)" -->
          <label for="gender">Gender</label>
          <ng-select formControlName="gender">
            <ng-option *ngFor="let gender of genders" [value]="gender.value"> {{ gender.name }}</ng-option>
          </ng-select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="phone"> Phone Number </label>
          <input
            id="phone"
            class="form-control"
            formControlName="phone"
            type="tel" />
        </div>
        <div class="col-lg-6 form-group">
          <label for="dob"> Date of birth (dd/mm/yyyy) </label>
          <input class="form-control" formControlName="dob" type="text" />
        </div>
        <div class="col-lg-6 form-group">
          <label
            for="exampleFormControlSelect1">
            Marital Status
          </label>
          <ng-select formControlName="maritalStatus">
            <ng-option *ngFor="let status of maritalStatus" [value]="status.value">
              {{ status.name }}
            </ng-option>
          </ng-select>

        </div>
      </div>
    </form>
  </div>
</div>
