<!--begin::Card-->
<div class="card card-custom gutter-b">
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">Orders List</h3>
    </div>
  </div>

  <div class="card-body">
    <app-orders-table
      [userId]="userId"
      (query)="query($event)"
      (order)="viewOrder($event)"></app-orders-table>
  </div>

</div>

<ngx-smart-modal
  #orderModal
  identifier="orderModal"
  ariaLabel="orderModal">
  <kt-orders-edit
    *ngIf="orderId"
    [orderId]="orderId"></kt-orders-edit>
</ngx-smart-modal>
