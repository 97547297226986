import { Component, Input, OnInit } from '@angular/core';
import { CURRENCY } from '@console/shared';
import { UserKycModel } from '@console/user-managements';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { PerformanceIntervals } from 'projects/view/src/lib/instrument/models/instrument.interface';
import { InstrumentGraphStateService } from 'projects/view/src/lib/instrument/states/graph-state.service';
import { InstrumentStateService } from 'projects/view/src/lib/instrument/states/single-instrument--state.service';
import { OrderStateService } from 'projects/view/src/lib/orders/orders-edit/order.state';
import { skip, first } from 'rxjs/operators';
import { WalletListStateService } from '../../accounts/states/wallets.state';
import { PortfolioStateService } from '../user-portfolio/user-portfolio.state';
import { PreOrderStateService } from './states/pre-order.state';
import { QuoteStateService } from './states/quote.state';
import { BuyInstrumentModel } from '../../../../../../view/src/lib/orders/services/orders.model';
import { ORDER_SIDE } from '../../../../../../shared/src/lib/_enums/order-side.enum';
import { ConsoleRouterService } from '../../../../../../shared/src/lib/services/console-router.service';


@Component({
  selector: 'app-instrument-order',
  templateUrl: './instrument-order.component.html',
  styleUrls: ['./instrument-order.component.scss'],
})
export class InstrumentOrderComponent implements OnInit {

  @Input() userId: string;
  @Input() symbol: string;

  performanceInterval = PerformanceIntervals.Week;

  currentView = 'order-form';

  private userOrder: Record<string, any>;

  constructor(
    public wallet: WalletListStateService,
    public quote: QuoteStateService,
    public preOrder: PreOrderStateService,
    public order: OrderStateService,
    public modal: NgxSmartModalService,
    public portfolio: PortfolioStateService,
    public instrument: InstrumentStateService,
    public graph: InstrumentGraphStateService,
    private console: ConsoleRouterService
  ) { }

  createPreOrder(form: any) {
    this.preOrder.reset();

    form.userId = this.userId;
    form.symbol = this.symbol;

    this.userOrder = form;

    this.preOrder.placePreOrder(form);
    this.preOrder.state.pipe(skip(1), first())
      .subscribe((state) => {
        // if (state.error) {
        //   this.currentView = 'order-form';
        //   return
        // }
        this.currentView = 'pre-order';
      });

  }

  changeGraphInterval(interval: PerformanceIntervals) {
    this.performanceInterval = interval;
    this.graph.loadChart({
      currency: CURRENCY.DOLLAR,
      interval: this.performanceInterval,
      symbol: this.symbol,
    });
  }

  preOrderAction(action: string) {

    if (action === 'ORDER') {
      this.placeOrder(this.userOrder);
      return;
    }

    this.currentView = 'order-form';

  }

  placeOrder(order: Record<string, string | number>) {
    this.order.resetState();

    if (this.userOrder.orderSide === ORDER_SIDE.BUY) {
      this.order.buy(order as any);
    } else {
      this.order.sell(order as any);
    }

    this.order.state.pipe(skip(1), first())
      .subscribe((state) => {
        if (state.error) {
          this.currentView = 'order-form';
          return
        }
        // this.currentView = 'order-reciept';
        this.console.productNavigate(['orders', state.order.id]);
      });
  }


  ngOnInit() {
    this.wallet.retrieve(this.userId);
    this.instrument.reset();
    this.quote.retrieve(this.symbol);
    this.instrument.loadInstrument(this.symbol);
    this.changeGraphInterval(this.performanceInterval);
  }
}
