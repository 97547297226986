import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { NewsContent } from './../../services/news.model';

@Component({
  selector: 'kt-content-news-grid',
  templateUrl: './news-grid.component.html',
  styleUrls: ['./news-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsGridComponent implements OnInit {
  @Input() news: NewsContent[] = [];

  @Output() newsClick = new EventEmitter<NewsContent>();

  constructor() {}

  ngOnInit() {}

}
