import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ExchangeStateService } from './exchanges-edit-state';
import { ConsoleRouterService } from './../../../../../shared/src/lib/services/console-router.service';
import { first, map } from "rxjs/operators";
import { ExchangesModel } from '../services/exchanges.model';

@Component({
  selector: "kt-exchanges-edit",
  templateUrl: "./exchanges-edit.component.html",
  styleUrls: ["./exchanges-edit.component.scss"],
})
export class ExchangesEditComponent implements OnInit {
  pageTitle: string = "View Exchange";

  @Input() activeExchange: ExchangesModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    public exchangeState: ExchangeStateService,
    private consoleRouter: ConsoleRouterService,
  ) { }

  get ex$() {
    return this.exchangeState.state.pipe(
      map((state => state.exchange))
    )
  }

  goToUserProfile() {
    this.exchangeState.state
      .pipe(
        first(),
        map(state => state?.exchange?.userId)
      )
      .subscribe((id) => {
        this.consoleRouter.productNavigate(['users', id]);
      })

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params) => {
        const id = params["id"];
        if (id) {
          this.exchangeState.findById(id);
        } else {
          this.consoleRouter.productNavigate(["exchange"]);
        }
      }
    );

  }

  approve(transactionId) {
    this.exchangeState.approve(transactionId);
  }

  refresh(transactionId): void {
    this.exchangeState.refresh(transactionId);
  }
}
