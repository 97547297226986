<div class="row">
  <div class="col-12">
    <form [formGroup]="addressForm" >
      <div class="form-row">
        <div class="col-lg-6 form-group">
          <label id="address"> Address </label>
          <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" id="address" class="form-control" formControlName="address" />
        </div>
        <div class="col-lg-6 form-group">
          <label id="address"> City </label>
          <input id="address" class="form-control" formControlName="city" />
        </div>

        <div class="col-lg-6 form-group">
          <label for="country">Country</label>
          <ng-select formControlName="country">
            <ng-option *ngFor="let country of countries" [value]="country.code">
              {{ country.name }} </ng-option>
         </ng-select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="lg">Local Government Area</label>
          <ng-select  formControlName="lg">
            <ng-option *ngFor="let lg of localGovts" [value]="lg.value"> {{ lg.label }}</ng-option>
         </ng-select>

        </div>

        <div class="col-lg-6 form-group">
          <label for="postalCode"> Postal Code </label>
          <input
            id="postalCode"
            class="form-control"
            formControlName="postalCode"
          />
        </div>
      </div>
    </form>
  </div>
</div>
