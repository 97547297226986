
import { MessagesService } from '../../../../../../shared/src/lib/components/messages/messages.service';

import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { MatSidenav } from '@angular/material/sidenav';
import { UserListStateService } from '@console/user-managements';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { AutoRebalanceListStateService } from '../../state/auto-rebalance-list-state.service';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { debounceCall } from '@console/shared/utils/debounceCall';

@Component({
  selector: 'auto-rebalance-create',
  templateUrl: './auto-rebalance-create.component.html',
  styleUrls: ['./auto-rebalance-create.component.scss'],
  providers: [MessagesService, UserListStateService]
})

export class AutoRebalanceCreateComponent implements OnInit, AfterViewChecked {

  constructor(
    private messagesService: MessagesService,
    public userList$: UserListStateService,
    private autoRebalanceState: AutoRebalanceListStateService
  ) { }

  autoPilotForm: FormGroup;
  formMethods;

  userControl: FormControl;

  title = 'Add Auto Rebalance';

  currencyList = ['USD', 'NGN'];

  @Input() drawer: MatSidenav;

  @ViewChild('scrollDiv') scrollDiv: ElementRef;

  @Output() saveSuccess = new EventEmitter();

  debounceSearchUser = debounceCall(function(event) {
    const { value } = event.target;

    if (value.length > 3) {
      this.userList$.find({ q: value, pageSize: 10 });
    }

  }, 1000);

  // searchUser(event): void {


  //   if (value.length > 3) {

  //   }

  // }

  selectedUsers: {
    id: number,
    userId: string,
    fullName: string,
    profileImageUrl: string,
    email: string,
    firstName: string,
    lastName: string
  }[] = [];

  ngOnInit(): void {
    this.setupForm();
  }

  private setupForm(): void {
    this.autoPilotForm = new FormGroup({
      currency: new FormControl('', Validators.required),
      forceRebalance: new FormControl(false),
      productType: new FormControl('ADVISORY', Validators.required),
      reviewOnly: new FormControl(false)
    });

    this.formMethods = formMethods(this.autoPilotForm);

    this.userControl = new FormControl(Validators.required);
  }

  assignUser($event): void {
    if (!$event) {
      return;
    }

    const { id, userId, email, fullName, profileImageUrl, firstName, lastName } = $event;

    if (this.selectedUsers.some((user) => id === user.id)) {
      return;
    }

    this.selectedUsers = [
      ...this.selectedUsers,
      { id, userId, email, fullName, profileImageUrl, firstName, lastName }
    ];

    this.userControl.reset();
  }

  deleteUser(id): void {
    this.selectedUsers = this.selectedUsers.filter(user => user.id !== id);
  }


  onAddUserId(): any {
    if (!this.autoPilotForm.get('userIds').valid) {
      this.autoPilotForm.get('userIds').markAllAsTouched();
      return;
    }

    const group = new FormGroup({
      userId: new FormControl('', Validators.required)
    });

    (this.autoPilotForm.get('userIds') as FormArray).push(group);
  }

  save(): void {

    if (!this.autoPilotForm.valid) {
      this.autoPilotForm.markAllAsTouched();
      return;
    }

    if (this.selectedUsers.length === 0) {
      this.messagesService.open(MessagesEnum.danger, 'Please select a user', {
        timeOut: 3000
      });

      return;
    }

    const message = 'Saving autopilot...';

    const { value } = this.autoPilotForm;

    const dataToSubmit = {
      ...value,
      userIds: this.selectedUsers.map(({ userId }) => userId)
    };

    this.messagesService.open(
      MessagesEnum.loading,
      message,
      { hideAll: true }
    );

    this.autoRebalanceState
      .saveRebalance(dataToSubmit)
      .subscribe({
        next: () => {
          this.closeDrawer();

          this.saveSuccess.emit();
        },
        error: (error: ChakaAPIError) => {
          const errorMessage = cleanChakaAPIError(error);

          this.messagesService.update(
            {
              type: MessagesEnum.danger,
              message: errorMessage
            }
          );
        }
      });
  }

  scrollToBottom(): void {
    try {
      this.scrollDiv.nativeElement.scrollTop = this.scrollDiv.nativeElement.scrollHeight;
    } catch (err) { }
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  closeDrawer(): void {
    this.messagesService.hideAll();

    this.drawer.close();
  }
}

