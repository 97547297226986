<div class="">
  <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">
    <div class="row mt-3 mb-3">
      <div class="col-sm-12 col-md-12 mb-12">
        <div class="kt-input-icon kt-input-icon--left">
          <input
            (keyup)="handleQuery('q', $event.target.value)"
            type="search"
            class="form-control border-top-0 border-left-0 border-right-0 border-primary"
            placeholder="Search All Feeds"
            id="generalSearch" />
          <span class="kt-input-icon__icon kt-input-icon__icon--left">
            <span><i class="la la-search"></i></span>
          </span>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 mb-3">
        <div id="dataTable_filter" class="dataTables_filter">
          <select (change)="handleQuery('currency',  $event.target.value)" id="inputState" class="form-control border-top-0 border-left-0 border-right-0 border-primary">
            <option *ngFor="let status of currencies" [value]="status.value"> {{ status.name }} </option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 mb-3">
        <div id="dataTable_filter" class="dataTables_filter">
          <select (change)="handleQuery('docType',  $event.target.value)" id="inputState" class="form-control border-top-0 border-left-0 border-right-0 border-primary">
            <option *ngFor="let status of statementTypes" [value]="status.value"> {{ status.name }} </option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 mb-2">
        <div id="dataTable_filter" class="dataTables_filter">
          <input
            (change)="handleQuery('from', $event.target.value, true)"
            type="date"
            class="form-control border-top-0 border-left-0 border-right-0 border-primary"
            placeholder="Start Date"
            id="generalSearch" />
        </div>
        <div class="kt-input-icon kt-input-icon--left"></div>
      </div>
      <div class="col-sm-12 col-md-2 mb-2">
        <div id="dataTable_filter" class="dataTables_filter">
          <input
            (change)="handleQuery('to', $event.target.value, true)"
            type="date"
            class="form-control border-top-0 border-left-0 border-right-0 border-primary"
            placeholder="End Date"
            id="generalSearch" />
        </div>
        <div class="kt-input-icon kt-input-icon--left"></div>
      </div>
      <div class="row mt-5 mb-5 d-none d-md-block">
        <div style="width: 68vw;" class="col-sm-12 table-responsive">
          <table class="table table-hover">
            <thead class="border-top-light">
              <tr class="" style="border-top: 0px !important">
                <th scope="col">
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1" />
                  </div>
                </th>
                <th scope="col" class="table-title">Display Name</th>
                <th scope="col" class="table-title">File Key</th>
                <th scope="col" class="table-title">Type</th>
                <th scope="col" class="table-title">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let statement of statements">
                <td scope="row">
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1" />
                  </div>
                </td>
                <td class="table-text">{{ statement.displayName || "-" }}</td>
                <td class="table-text">{{ statement.fileKey || "-" }}</td>
                <td class="table-text text-primary">
                  {{ statement.type || "-" }}
                </td>
                <td class="table-text px-4">
                  <a [href]="statement.downloadUrl" download rel="noopener noreferrer" target="_blank">
                    <i class="fas fa-location-arrow text-primary fa-lg"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mb-3 d-sm-block d-md-none">
        <div class="col-lg-12 mt-3">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1" />
                    </div>
                  </th>
                  <th scope="col">Type</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody *ngFor="let statement of statements">
                <tr
                  class="accordion-toggle collapsed"
                  id="accordion3"
                  data-toggle="collapse"
                  data-parent="#accordion3"
                  href="#collapseThree">
                  <td class="expand-button"></td>
                  <td>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        style="
                          background-color: #ccc !important;
                          color: #6b7188 !important;
                        "
                        id="exampleCheck1" />
                    </div>
                  </td>
                  <td>
                    <i class="fas fa-circle kt-badge--primary"></i>
                    <small class="kt-font-primary px-2">{{
                      statement.displayName
                    }}</small>
                  </td>
                  <td>
                    <i class="fas fa-cog flaticon2 px-2"></i><i class="fas fa-file flaticon2 px-2"></i><i class="fas fa-times flaticon2 px-2"></i>
                  </td>
                </tr>
                <tr class="hide-table-padding">
                  <td></td>
                  <td colspan="4">
                    <div id="collapseThree" class="collapse in p-3">
                      <div class="row">
                        <div class="col-sm-10 px-3">
                          <ul class="list-group">
                            <li
                              class="list-group-item d-flex justify-content-between align-items-center">
                              <span
                                class="mobile-table-list font-weight-bold">Display Name</span>
                              <span>{{ statement.displayName }}</span>
                            </li>
                            <li
                              class="list-group-item d-flex justify-content-between align-items-center">
                              <span
                                class="mobile-table-list font-weight-bold">File Key</span>
                              <span>{{ statement.fileKey || "-" }}</span>
                            </li>
                            <li
                              class="list-group-item d-flex justify-content-between align-items-center">
                              <span
                                class="mobile-table-list font-weight-bold"> Type</span>
                              <span>{{ statement.type || "-" }}</span>
                            </li>
                            <li
                              class="list-group-item d-flex justify-content-between align-items-center">
                              <span
                                class="mobile-table-list font-weight-bold">Action</span>
                              <a
                                [href]="statement.downloadUrl"
                                download
                                class="btn btn-sm btn-primary text-light"
                                style="border-radius: 20px">Download</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <lib-pagination
      (paginate)="paginate($event)"
      [count]="count"
      [pageSize]="pageSize"></lib-pagination>
  </div>
</div>
