

<div class="container">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="roleUpdateForm">
        <div class="form-row">
          <div class="col-md-12 form-group">
            <label for="symbol"> User Id </label>
            <input id="symbol" class="form-control" formControlName="userId" />
          </div>
        </div>

        <div class="form-row">
          <button
            class="btn btn-primary w-100"
            [disabled]="!roleUpdateForm.valid || loading"
            (click)="attach.emit(roleUpdateForm.value)"
          >
            {{ loading ? 'Updating.......': 'Attach User ' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
