<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <!-- <ngx-spinner
      [name]="statement.spinnerName"
      bdColor="#4e73df66"
      size="medium"
      color="#fff"
      type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">
        {{ (statement.state | async).message }}
      </p>
    </ngx-spinner> -->

    <div class="card-header bg-light py-3">
      <h6 class="m-0 font-weight-bold text-primary">Statements</h6>
    </div>
    <div class="card-body">
      <app-statements-table
        [count]="(statement.state | async).count"
        [loading]="(statement.state | async).loading"
        [statements]="(statement.state | async).statements"
        [error]="(statement.state | async).error"
        (query)="queryUserStatement($event)"></app-statements-table>
    </div>
  </div>
</div>
