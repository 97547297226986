<div class="form-row">
  <div class="col-lg-4">
    <lib-file-uploader
      [existingImage]="files.profileImageUrl.url"
      [userId]="userId"
      [loading]="files.profileImageUrl.loading"
      uploadType="PASSPORT"
      label="Profile Image"
      (upload)="upload.emit({ payload: $event, type: 'PASSPORT' })"
    ></lib-file-uploader>
  </div>
  <div class="col-lg-4 upload">
    <lib-file-uploader
      [existingImage]="files.addressProofUrl.url"
      [userId]="userId"
      [loading]="files.addressProofUrl.loading"
      uploadType="ADDRESS_PROOF"
      label="Address Proof"
      (upload)="upload.emit({ payload: $event, type: 'ADDRESS_PROOF' })"
    ></lib-file-uploader>
  </div>
  <div class="col-lg-4">
    <lib-file-uploader
      [existingImage]="files.photoIdUrl.url"
      [userId]="userId"
      [loading]="files.photoIdUrl.loading"
      uploadType="PHOTO_ID"
      label="Photo ID"
      (upload)="upload.emit({ payload: $event, type: 'PHOTO_ID' })"
    ></lib-file-uploader>
  </div>
</div>
