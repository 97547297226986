import { MerchantFeeSettingsComponent } from './components/merchant-fees/components/fee-settings.component';
import { FeeService } from './services/fee.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiModule } from '../../../../api/src/lib/api.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MerchantCardComponent } from './components/marchant-card/merchant-card.component';
import { MerchantGridComponent } from './components/marchant-grid/merchant-grid.component';
import { MerchantListComponent } from './merchant-list/merchant-list.component';
import { MerchantListStateService } from './merchant-list/merchant-list.state';
import { MerchantService } from './services/merchant.service';
import { SearchBarModule } from '../../../../_shared/search-bar/search-bar.module';
import { PaginationModule } from '../../../../_shared/Pagination/pagination.module';
import { MerchantConfigEditorComponent } from './components/merchant-config-editor/merchant-config-editor.component';
import { MerchantDisplayComponent } from './components/merchant-display/merchant-display.component';
import { MerchantEditorComponent } from './components/merchant-editor/merchant-editor.component';
import { MerchantDetailsComponent } from './merchant-details/merchant-details.component';
import { MerchantStateService } from './merchant-details/merchant-state.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '@console/shared';
import { MerchantFeesComponent } from './components/merchant-fees/merchant-fee.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ApiModule,
    ReactiveFormsModule,
    FormsModule,
    SearchBarModule,
    PaginationModule,
    NgxSpinnerModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    MerchantListComponent,
    MerchantCardComponent,
    MerchantGridComponent,
  ],
  declarations: [
    MerchantListComponent,
    MerchantConfigEditorComponent,
    MerchantDisplayComponent,
    MerchantEditorComponent,
    MerchantDetailsComponent,
    MerchantCardComponent,
    MerchantGridComponent,
    MerchantFeesComponent,
    MerchantFeeSettingsComponent
  ],
  providers: [
    MerchantListStateService,
    MerchantStateService,
    MerchantService,
    FeeService
  ]
})
export class MerchantModule { }
