import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Statement } from './interface';
import { StatementListStateService } from './statement-list.state';

@Component({
  selector: 'app-user-statements',
  templateUrl: './statements.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStatementsComponent implements OnInit, OnDestroy {
  @Input() userId: string;

  constructor(public statement: StatementListStateService) { }

  queryUserStatement(filter: object): void {
    (filter as any).userId = this.userId;
    this.statement.find(filter);
  }

  ngOnInit(): void {
    this.queryUserStatement({});
  }

  ngOnDestroy(): void {
    this.statement.reset();
  }
}
