<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">Auto-Rebalance List</h3>
        </div>

        <div class="card-toolbar">
          <button href="javascript:;" mat-button (click)="drawer.open()" class="btn btn-primary btn-sm">
            <span class="svg-icon svg-icon-md svg-icon-white">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
                  <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" />
                </g>
              </svg>

            </span>Add Auto Rebalance</button>
        </div>
      </div>

      <div class="card-body">

        <!--begin: Search Form-->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <form [formGroup]="searchForm">
                <div class="row align-items-center">

                  <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="form-group">
                      <label>Search all feeds</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="q"
                        trimmedInput>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-3 col-md-6">
                    <div class="form-group">
                      <label> Status</label>
                      <ng-select formControlName="status" [clearable]="false">
                        <ng-option [value]="status.value" *ngFor="let status of redemptionStatusList">{{ status.label }}</ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div
                    class="col-xl-2 col-lg-3 col-md-6"
                    *ngIf="isSuperAdmin">
                    <div class="form-group">
                      <label>Merchant</label>
                      <ng-select
                        formControlName="merchantId"
                        [clearable]="false"
                        [virtualScroll]="true"
                        [loading]="this.merchants?.loading">
                        <ng-option value="">All</ng-option>
                        <ng-option
                          [value]="merchant?.id"
                          *ngFor="let merchant of merchants?.list">
                          {{ merchant?.name }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-3 col-md-6">
                    <div class="form-group">
                      <label>From</label>
                      <input class="form-control" type="date" formControlName="from">
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-3 col-md-6">
                    <div class="form-group">
                      <label>To</label>
                      <input class="form-control" type="date" formControlName="to">
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-3 col-md-6">
                    <div class="form-group">
                      <label class="d-block">&nbsp;</label>
                      <button
                        type="button"
                        class="btn btn-light-primary font-weight-bold"
                        (click)="handleQuery()">Search</button>
                    </div>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
        <!--end::Search Form-->

        <!--begin: Datatable-->

        <lib-messages></lib-messages>

        <div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

          <div class="mt-5 mb-5">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold">
                Selected {{selectedItems.size}} {{selectedItems.size <= 1 ?'record': 'records'}}:
                <button
                  [disabled]="selectedItems.size <= 0"
                  (click)="downloadFile()"
                  class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
                  <i class="far fa-arrow-alt-circle-down"></i> Download
                </button>
              </div>
            </div>
          </div>


          <div class="table-container">
            <table mat-table [dataSource]="dataSource" matMultiSort aria-label="Redemption table">

              <ng-container matColumnDef="check" sticky>
                <th
                  mat-header-cell
                  *matHeaderCellDef="let row">
                  <span style="width: 20px;">
                    <label class="checkbox checkbox-single checkbox-all">
                      <input
                        (change)="onAllChecked($event.target.checked)"
                        type="checkbox"
                        [checked]="allSelected">&nbsp;<span></span>
                    </label>
                  </span>
                </th>

                <td
                  mat-cell
                  *matCellDef="let row">
                  <span style="width: 20px;">
                    <label class="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        (change)="onItemChecked($event.target.checked, row)"
                        [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
                    </label>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="riaID">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="riaID"> RIA ID </th>
                <td mat-cell *matCellDef="let row">
                  <a
                    href="javascript:void(0)"
                    (click)="viewAutoRebalance(row?.id)">
                    {{row?.riaID}}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="moneyMovement">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="moneyMovement"> Money Movement </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.moneyMovement}}
                </td>
              </ng-container>

              <ng-container matColumnDef="regularRebalance">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="regularRebalance"> Regular Rebalance </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.regularRebalance}}
                </td>
              </ng-container>

              <ng-container matColumnDef="reviewOnly">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="reviewOnly"> Review Only </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.reviewOnly}}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="status"> Status</th>
                <td mat-cell *matCellDef="let row">
                  {{row?.status}}
                </td>
              </ng-container>

              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="created"> Date </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.created | date:'mediumDate' || "-"}}
                </td>
              </ng-container>

              <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true">
              </tr>
              <tr mat-row
                *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
          </div>

          <mat-paginator
            class="mat-paginator-sticky"
            [length]="count"
            [pageSizeOptions]="[10, 50, 100, 150, 200]"
            (page)="pageEvent=$event; paginate($event)"
            showFirstLastButtons></mat-paginator>
        </div>

        <div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
          <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
          <p class="no-data-text">No data</p>
        </div>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::Card-->

  </div>
  <!--end::Container-->
</div>


<!-- drawer -->
<mat-drawer #drawer mode="over">
  <auto-rebalance-create
    [drawer]="drawer"
    (saveSuccess)="handleQuery()"></auto-rebalance-create>
</mat-drawer>
