import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LearnContent } from './../../services/learn.model';

@Component({
  selector: 'kt-learn-content-card',
  templateUrl: './learn-content-card.component.html',
  styleUrls: ['./learn-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnContentCardComponent implements OnInit {
  @Input() learn: LearnContent;

  @Output() btnClick = new EventEmitter<LearnContent>();

  constructor() { }

  get dateParts() {
    const date =
      this.learn && this.learn.dateCreated
        ? new Date(this.learn.dateCreated)
        : new Date();
    const parts = new Intl.DateTimeFormat('en-NG', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).formatToParts(date);
    return parts.filter((p) =>
      ['day', 'month', 'year'].includes(p.type.toLowerCase())
    );
  }

  // tslint:disable-next-line: typedef
  style() {
    return `url(${this.learn.imageUrl}) center / cover no-repeat`;
  }

  ngOnInit() { }
}
