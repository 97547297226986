import { NgModule } from '@angular/core';
import { EnterpriseAuthService } from './auth-api.service';
import { BaseApiService } from './base-api.service';
import { EnterpriseAPIService } from './enterprise.service';
import { HttpClientModule } from '@angular/common/http';
import { APICONFIG } from './api.constants';
import { serverRoutes } from 'src/app/route.config';

@NgModule({
  providers: [
    EnterpriseAuthService,
    BaseApiService,
    EnterpriseAPIService,
    APICONFIG,
    {
      provide: APICONFIG,
      useValue: {
        ...serverRoutes(),
      },
    }],
  imports: [HttpClientModule],
  exports: [HttpClientModule]
})
export class ApiModule {}
