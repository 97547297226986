import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CSCS_UPLOAD_STATUS } from '@console/shared/_enums/cscs-upload-status.enum';
import { CscsDetails } from './cssForm.interface';

@Component({
  selector: 'lib-user-css-form',
  templateUrl: './user-css-form.component.html',
  styleUrls: ['./user-css-form.component.scss'],
})
export class UserCssFormComponent implements OnInit {
  @Input() cssDetails: CscsDetails;
  @Input() disabled = false;

  cscsUploadStatus = [
    {
      name: 'Failed',
      value: CSCS_UPLOAD_STATUS.FAILED,
    },
    {
      name: 'Pending Upload',
      value: CSCS_UPLOAD_STATUS.PENDING_UPLOAD,
    },
    {
      name: 'Submission not Approved',
      value: CSCS_UPLOAD_STATUS.SUBMISSION_NOT_APPROVED,
    },
    {
      name: 'Submitted and Approved',
      value: CSCS_UPLOAD_STATUS.SUBMITTED_AND_APPROVED,
    },
  ];

  cscsForm: FormGroup;

  @Output() update = new EventEmitter();

  @Output() updateInfoware = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  createForm(cscsDetails: Partial<CscsDetails> = {}) {
    return this.fb.group({
      cscsNumber: [cscsDetails.cscsNumber || ''],
      cscsCHN: [cscsDetails.cscsCHN || ''],
      cscsSent: [!!cscsDetails.cscsSent],
      uploadStatus: [cscsDetails.uploadStatus || ''],
    });
  }

  submit(form: Partial<CscsDetails>) {
    this.update.emit({ ...this.cssDetails, ...form });
  }

  submitInfoware() {
    this.updateInfoware.emit({ ...this.cssDetails, ...this.cscsForm.value });
  }

  get showUpdateButton(): boolean {
    return (
      this.cssDetails?.uploadStatus ===
        CSCS_UPLOAD_STATUS.SUBMITTED_AND_APPROVED &&
      !!this.cscsForm.get('cscsNumber')?.value &&
      !!this.cscsForm.get('cscsCHN').value
    );
  }

  ngOnInit(): void {
    this.cscsForm = this.createForm(this.cssDetails);
    if (this.disabled) {
      this.cscsForm.disable();
    }
  }
}
