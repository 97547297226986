<ng-container *ngIf="drawer.opened">
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Holdings</span>
      </h3>
      <div class="card-toolbar">
        <button class="close" mat-button (click)="closeDrawer()">X</button>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="row">
        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Name:</span>
              <strong>
                {{activePortfolioHolding?.name || '_'}}
              </strong>
            </div>

            <div class="details-pane__content">
              <span>Type:</span>
              <strong>{{activePortfolioHolding?.type || '_'}}</strong>
            </div>

            <div class="details-pane__content">
              <span>Target:</span>
              <strong>{{activePortfolioHolding?.target || '_'}}</strong>
            </div>

            <div class="details-pane__content">
              <span>Actual:</span>
              <strong>{{activePortfolioHolding?.actual || '_'}}</strong>
            </div>

            <div class="details-pane__content">
              <span>Value:</span>
              <strong>{{formatToCurrency(tradeCurrency, activePortfolioHolding?.value) || '_'}}</strong>
            </div>

            <div class="details-pane__content">
              <span>Rebalance Required:</span>
              <strong>
                <i
                  [ngClass]="activePortfolioHolding?.rebalanceRequired ?
                                'fas fa-check text-success':
                                'far fa-window-close text-danger'"></i>
              </strong>
            </div>

          </div>
        </div>

      </div>

      <hr>

      <div class="row">
        <div class="col-12">
          <!--begin::Table-->
          <div class="table-responsive" *ngIf="portfolioChildHoldings.length else noData">
            <table class="table table-head-custom table-head-bg table-vertical-center table-borderless">
              <thead>
                <tr class="bg-gray-100 text-left">
                  <th>
                    <span class="text-dark-75">Symbol</span>
                  </th>

                  <th>
                    <span class="text-dark-75">Target</span>
                  </th>

                  <th>
                    <span class="text-dark-75">Actual</span>
                  </th>

                  <th>
                    <span class="text-dark-75">Value</span>
                  </th>

                  <th>
                    <span class="text-dark-75">Open Quantity</span>
                  </th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let portfolioChildHolding of portfolioChildHoldings">
                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{portfolioChildHolding?.symbol}}
                    </span>
                  </td>

                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{portfolioChildHolding?.target}}
                    </span>
                  </td>

                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{portfolioChildHolding?.actual}}
                    </span>
                  </td>

                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{portfolioChildHolding?.openQty}}
                    </span>
                  </td>

                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{portfolioChildHolding?.value}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
      </div>

      <ng-template #noData>
        <lib-no-data
          message="No holdings"></lib-no-data>
      </ng-template>

    </div>
    <!--end::Body-->
  </div>


</ng-container>
