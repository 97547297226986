import { AccountNameValidator } from './directives/account-name.validator';
import { SharedModule } from './../../../../shared/src/lib/shared.module';
import { AccountDialogComponent } from './components/account-dialog/account-dialog.component';
import { ChakaAccountService } from './service/chaka-account.service';
import { NgModule } from '@angular/core';
import { ChakaAccountsComponent } from './chaka-accounts.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BankStateService } from '@console/user-managements/sections/user-kyc-editor/bank-state.service';

@NgModule({
  declarations: [
    ChakaAccountsComponent,
    AccountDialogComponent,
    AccountNameValidator
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    ChakaAccountService,
    BankStateService
  ]
})

export class ChakaAccountsModule { }