<ngx-spinner
  [color]="'#ffffff'"
  [name]="cscs.spinnerName"
  [fullScreen]="false"
  type="ball-clip-rotate-multiple"
  size="medium"
>
  <p class="loading">{{ (cscs.state | async).message }}</p>
</ngx-spinner>

<lib-user-css-form
  *ngIf="!(cscs.state | async).loading"
  [cssDetails]="(cscs.state | async).cscs"
  (update)="submit($event)"
  (updateInfoware)="submitInfowareCscs($event)"
></lib-user-css-form>
