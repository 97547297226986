import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MerchantService } from '../../../projects/view/src/lib/merchants/services/merchant.service';
import { ReqSuccessResponse, ChakaAPIError } from 'projects/api/src/public-api';
export interface MerchantDetailState {
  merchant?: any;
  loading: boolean;
  error?: string;
  message?: string;
  deleted?: boolean;
}

const initalState = { loading: false };

@Injectable({ providedIn: 'root' })
export class MerchantDetailStateService {
  state = new BehaviorSubject<MerchantDetailState>(initalState);

  spinnerName = 'merchant-details-State';

  constructor(private merchantSvc: MerchantService) { }

  merchant() {
    this.state.next({ ...this.state.getValue(), loading: true, message: `Loading merchant details` });
    this.merchantSvc.getMerchantCredentials().subscribe({
      next: this.onMerchantLoaded.bind(this),
      error: this.onMerchantError.bind(this),
    });
  }

  reset() {
    this.state.next(initalState);
  }

  private onMerchantLoaded({ data }: ReqSuccessResponse<any>) {
    this.state.next({ merchant: data, loading: false, message: `successfully loaded ${data.name} details.` });
  }

  private onMerchantError({ message }: ChakaAPIError) {
    this.state.next({ loading: false, error: message, message });
  }
}

