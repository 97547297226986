<div class="row">
  <div class="col-md-8 mx-auto">
    <div *ngIf="merchant">
      <lib-messages></lib-messages>

      <form [formGroup]="merchantForm" (ngSubmit)="updateMerchant()">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Merchant Name <span class="text-danger">*</span></label>
              <input formControlName="name" class="form-control" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('name')">
                <div
                  *ngIf="formMethods.getControl('name').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Merchant Code <span class="text-danger">*</span></label>
              <input class="form-control" type="text" formControlName="code" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('code')">
                <div
                  *ngIf="formMethods.getControl('code').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Logo URL <span class="text-danger">*</span></label>
              <textarea
                class="form-control"
                formControlName="logoUrl"></textarea>
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('logoUrl')">
                <div
                  *ngIf="formMethods.getControl('logoUrl').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Contact Email</label>
              <input formControlName="contactEmail" class="form-control" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('contactEmail')">
                <div
                  *ngIf="
                    formMethods.getControl('contactEmail').hasError('email')
                  ">
                  Enter a valid email address
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Contact Phone</label>
              <input
                class="form-control"
                type="text"
                formControlName="contactPhone" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('contactPhone')">
                <div
                  *ngIf="
                    formMethods.getControl('contactPhone').hasError('pattern')
                  ">
                  Enter a valid phone number
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Telegram</label>
              <input
                class="form-control"
                type="text"
                formControlName="telegram" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Whatsapp Numbers</label>
              <input
                class="form-control"
                type="text"
                formControlName="whatsAppNumber" />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Link to FAQ</label>
              <input
                class="form-control"
                type="text"
                formControlName="linkToFaq" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Default Virtual Account Provider</label>
              <ng-select
                formControlName="defaultVirtualAccProvider">
                <ng-option
                  [value]="provider.value"
                  *ngFor="let provider of providerList">{{ provider.description }}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-12" *ngIf="!disabled">
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
