import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reciept-display',
  templateUrl: './reciept-display.component.html',
  styleUrls: ['./reciept-display.component.scss']
})
export class RecieptDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
