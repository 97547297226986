import { USER_VIEW_TYPE } from './../../../../shared/src/lib/_enums/user-view.enum';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { ConsoleRouterService } from '@console/shared';
import { UserListStateService } from './users-list.state';
import { debounceCall } from '@console/shared/utils/debounceCall';

@Component({
  selector: 'lib-user-managements',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPageListComponent implements OnInit {
  title = 'Users';

  childRoute = false;

  query: Record<string, string | number> = {
    pageSize: 8,
  };

  currentView: USER_VIEW_TYPE = USER_VIEW_TYPE.GRID;

  viewTypes = USER_VIEW_TYPE;

  constructor(
    public router: ConsoleRouterService,
    public userList: UserListStateService,
    public role: AuthRoleStateService
  ) {}

  editUser(userId: string): void {
    this.router.productNavigate(['users', userId]);
  }

  createNewUser(): void {
    this.router.productNavigate(['new-users']);
  }

  changeView(selectedView: USER_VIEW_TYPE): void {
    if (this.currentView === selectedView) {
      return;
    }

    switch (selectedView) {
      case USER_VIEW_TYPE.GRID:
        this.userList.find({ pageSize: 8 });
        break;
      case USER_VIEW_TYPE.LIST:
        this.userList.find({ pageSize: 10 });
        break;

      default:
        this.userList.find({ pageSize: 10 });
        break;
    }

    this.currentView = selectedView;
  }

  debounceSearchUser = debounceCall(function () {
    this.userList.find(this.query);
  }, 1000);

  search(phrase: string): void {
    if (this.childRoute) {
      this.router.productNavigate(['users']);
    }

    if (typeof phrase === 'string') {
      this.query.q = phrase;

      // ADD A DEBOUNCE HERE
      this.debounceSearchUser();
    }
  }

  /**
   * handles pagination events PageEvent
   */
  paginate($event: any): void {
    // ensure consistent page numbers across page and pageNumber is is not zeroth based
    ++$event.pageNumber;
    $event.pageSize = 8;

    this.userList.find({ ...this.query, ...$event });
  }

  ngOnInit(): void {
    this.role.hasPermission$('USER_VIEW').subscribe((authorized) => {
      if (!authorized) {
        this.router.productNavigate([]);
      }
      this.userList.find(this.query);
    });
  }
}
