import { Component, Input, OnInit } from '@angular/core';
import { MerchantsModel, MerchantCredentials } from '../../merchants.interface';

@Component({
  selector: 'kt-merchant-display',
  templateUrl: './merchant-display.component.html',
  styleUrls: ['./merchant-display.component.scss']
})
export class MerchantDisplayComponent implements OnInit {

  @Input() merchant: MerchantsModel;
  @Input() credentials: MerchantCredentials;

  constructor() { }

  ngOnInit() {
  }

}
