import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { NotificationService } from '@console/shared/services/notification-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Platform } from '../providers/payment-interface';
import {
  PayoutAccount,
  WalletTransaction,
  WalletWithdraw,
} from '../providers/wallet.interface';
import { WalletService } from '../providers/wallet.service';

export interface WalletWithdrawaltate {
  transaction?: WalletTransaction;
  loading: boolean;
  error?: string;
  // message?: string;
}

const initialState: WalletWithdrawaltate = { loading: false };

@Injectable({ providedIn: 'root' })
export class WalletWithdrawalStateService {
  state = new BehaviorSubject<WalletWithdrawaltate>(initialState);

  spinnerName = 'Wallet-Withdrawal-Spinner';

  constructor(
    private wallet: WalletService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService
  ) { }

  withdrawFromAccount(
    transfer: Pick<WalletWithdraw, 'amount' | 'from' | 'userId' | 'currency'>,
    account: PayoutAccount
  ) {
    this.loading();
    this.wallet
      .withdrawal({
        ...transfer,
        accountId: account.id,
        bankName: account.bankName,
        channel: Platform.WEB,
      })
      .subscribe({
        next: this.onUserWalletWithdrawalLoaded.bind(this),
        error: this.onUserWalletWithdrawalError.bind(this),
      });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      // message: 'Please wait, performing account withdrawal',
    });
    this.spinner.show(this.spinnerName);
  }

  private onUserWalletWithdrawalLoaded({
    data,
  }: ReqSuccessResponse<WalletTransaction>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error: undefined,
      transaction: data,
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.success(`wallet withdrawal ${data.reference} successful`);

  }

  private onUserWalletWithdrawalError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.error(error);
  }
}
