<section-search-header
	[section]="'Tags'"
	[actionText]="'New Tag'"
	(actionBtn)="navigate()"
	(onSearch)="search($event)"
	[count]="(tagList.state | async).count"
	[loading]="(tagList.state | async).loading"
></section-search-header>

<div>
	<router-outlet
		(activate)="childRoute = true"
		(deactivate)="parentNavigate()"
	></router-outlet>
</div>

<div class="row container-fluid chaka-route-content" *ngIf="!childRoute">
	<div class="col-12">
		<kt-tag-grid (tagClick)="navigate($event.id)" [tags]="(tagList.state|async).tags"></kt-tag-grid>
    <lib-pagination (paginate)="paginate($event)" [count]="(tagList.state | async).count"></lib-pagination>
	</div>
</div>
