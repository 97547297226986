import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EnterpriseAPIService, PaginatedList, ReqSuccessResponse } from '../../../../../../projects/api/src/public-api';
import { InstrumentChartModel, InstrumentChartPayload, InstrumentModel, LiteInstrument } from '../models/instrument.interface';
import { RequeryInstrument } from './instrument-interface';
import {
  InstrumentAnalyticsRating,
  InstrumentPerformanceData
} from './instrument-interface';

/**
 * Pagination query for instrument list items
 */
export interface InstrumentPagination {
  q: string;
  pageNumber: number;
  pageSize: number;
  searchByTag: string;
  category: string;
  sortBy: string;
}


export interface Graph {
  charts: InstrumentChartModel[];
  currentPrice: number;
  derivedPrice: number;
  derivedPricePercentage: number;
}

const API_INSTRUMENT = 'api/v1/instruments';

@Injectable()
export class InstrumentService {

  constructor(private https: HttpClient, private http: EnterpriseAPIService) { }

  find(searchParams: Record<string, any> = {}) {
    searchParams.currency = 'USD';
    return this.http.get<PaginatedList<InstrumentModel>>(
      `/api/v1/instruments`,
      searchParams
    ).pipe(map(res => {
      return {
        ...res,
        data: res.data.filter(instrument => instrument.currency === 'USD')
      }
    }))
  }

  getSimilarInstrument(symbol: string) {
    return this.http.get<ReqSuccessResponse<LiteInstrument[]>>(
      `/api/v1/instruments/similar/${symbol}`
    );
  }


  // tslint:disable-next-line: typedef
  getInstruments(searchParams?) {
    return this.http.get<PaginatedList<InstrumentModel>>(
      API_INSTRUMENT,
      searchParams
    );
  }

  fetchInstrumentsBySymbols(
    symbols: string,
    searchParams?
  ) {
    return this.http.get<ReqSuccessResponse<InstrumentModel>>(
      `${API_INSTRUMENT}/symbols`,
      { symbols, ...searchParams }
    );
  }

  getInstrumentById(id: string) {
    const url = API_INSTRUMENT + `/${id}`;
    return this.http.get<ReqSuccessResponse<InstrumentModel>>(url);
  }

  getInstrumentBySymbol(
    symbol: string
  ) {
    const url = `${API_INSTRUMENT}/symbol/${symbol}`;
    return this.http.get<ReqSuccessResponse<InstrumentModel>>(url);
  }

  addInstrumentBySymbol({ currencyCode, symbol }: RequeryInstrument) {
    const url = `${API_INSTRUMENT}/new?symbol=${symbol}&currencyCode=${currencyCode}`;
    return this.http.post<ReqSuccessResponse<InstrumentModel>>(url, { symbol, currencyCode });
  }


  getInstrumentChart(
    payload: InstrumentChartPayload
  ) {
    const url = `${API_INSTRUMENT}/charts`;
    return this.http.get<ReqSuccessResponse<Graph>>(url, payload);
  }

  analytics(symbol: string) {
    const url = `${API_INSTRUMENT}/analytics/${symbol}`;
    return this.http.get<ReqSuccessResponse<InstrumentAnalyticsRating>>(
      url,
      {}
    );
  }

  // tslint:disable-next-line: typedef
  performance(symbol: string) {
    const url = `${API_INSTRUMENT}/performance/${symbol}`;
    return this.http.get<ReqSuccessResponse<InstrumentPerformanceData>>(
      url,
      {}
    );
  }
}
