import { Input, OnChanges, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsoleRouterService } from '@console/shared';
import { map, first } from 'rxjs/operators';
import { DwTransaction } from '../../transactions/services/transactions.model';
import { TransactionStateService } from '../../transactions/transaction-edit/transaction-edit-state';

@Component({
  selector: 'app-dw-history-edit',
  templateUrl: './dw-history-edit.component.html',
  styleUrls: ['./dw-history-edit.component.scss'],
})
export class DwHistoryEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() transactionId: number;
  @Input() transaction: DwTransaction;
  private intialId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    public transactionState: TransactionStateService,
    private consoleRouter: ConsoleRouterService
  ) {}

  back() {
    return this.consoleRouter.goBack();
    // if (this.transactionId) {
    //   // return this.modal.close('transactionModal');
    // }
    // this.consoleRouter.productNavigate(['transaction']);
  }

  get tsn$() {
    return this.transactionState.state.pipe(map((state) => state.transaction));
  }

  retreiveById(transactionId: number) {
    if (transactionId !== this.intialId) {
      this.transactionState.reset();
      this.transactionState.findById(transactionId);
      this.intialId = transactionId;
    }
  }

  retrieveByRoute() {
    this.activatedRoute.params.pipe(first()).subscribe((params) => {
      const id = params['id'];
      if (id) {
        this.transactionState.findById(id);
      } else {
        this.consoleRouter.productNavigate(['transaction']);
      }
    });
  }

  ngOnInit() {
    console.log('how far', this.transaction);
    // if (this.transactionId) {
    //   this.retreiveById(this.transactionId);
    //   return;
    // }
    // this.retrieveByRoute();
  }

  ngOnChanges() {
    if (this.transactionId) {
      this.retreiveById(this.transactionId);
    }
  }

  ngOnDestroy() {
    this.transactionState.reset();
  }
}
