import { Component, OnInit } from '@angular/core';
import { ConsoleRouterService } from '@console/shared';
import { PageEvent } from '@console/shared/interface';
import { Subject } from 'rxjs';
import { ChannelListStateService } from './channels.state';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
})
export class ChannelsComponent implements OnInit {
  private filter: Record<string, any> = {
    pageSize: 20,
  };

  childRoute = false;

  componentDestroyed$: Subject<boolean> = new Subject();
  
  constructor(
    public channel: ChannelListStateService,
    private console: ConsoleRouterService
  ) {}

  search(query: string) {
    this.filter = {
      pageSize: this.filter.pageSize,
    };

    this.filter.q = query;
    this.channel.find(this.filter);

    if (this.childRoute) {
      this.console.productNavigate(['channels']);
    }
  }

  paginate($event: PageEvent) {
    this.channel.find({
      ...this.filter,
      ...$event,
    });
  }

  parentNavigate() {
    this.childRoute = false;
    this.channel.find(this.filter);
  }

  navigate(channelId?: string) {
    if (channelId) {
      this.console.productNavigate(['channels', channelId]);
      return;
    }

    this.console.productNavigate(['channels', 'create']);
  }

  handleQuery(key, value, isDate = false) {
    if (key === 'q') {
      this.filter = {
        pageSize: this.filter.pageSize,
      };
    }

    if (isDate) {
      value = new Date(value).toISOString();
    }

    this.filter[key] = value;
    this.channel.find(this.filter);
  }

  ngOnInit() {
    this.channel.find({});
  }
}
