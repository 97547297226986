import { CURRENCY } from '../../../../../../shared/src/lib/_enums/currency.enum';

export enum StatementTypes {
  STATEMENT = 'STATEMENT',
  TAX = 'TAX',
  CONFIRMATION = 'CONFIRMATION',
}

export interface StatementQueryParam {
  from: string;
  to: string;
  docType: StatementTypes;
  currency: CURRENCY;
}

export interface Statement {
  displayName: string;
  fileKey: string;
  downloadUrl: string;
  accountId: string;
  type: StatementTypes;
}
