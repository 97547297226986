import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsTableComponent } from './payments-table.component';
import { PaginationModule } from '../../../../../_shared/Pagination/pagination.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@console/shared';

@NgModule({
  imports: [
    CommonModule,
    PaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
  ],
  exports: [PaymentsTableComponent],
  declarations: [
    PaymentsTableComponent
  ]
})
export class PaymentsTableModule { }
