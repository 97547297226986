<app-top-bar-mobile></app-top-bar-mobile>

<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid">
    <!--begin::Aside-->
    <app-side-bar
      [productType]="consoleRouter.product"
      (link)="consoleRouter.productNavigate($event)"></app-side-bar>
    <!--end::Aside-->


    <!--begin::Wrapper-->
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

      <!--begin::Header-->
      <app-top-bar
        (product)="consoleRouter.changeProduct($event)"
        [loginProfile]="(authProfile.state | async).authProfile"
        [productType]="consoleRouter.product"></app-top-bar>
      <!--end::Header-->


      <!--begin::Content-->
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <router-outlet></router-outlet>
      </div>
      <!--end::Content-->


      <!--begin::Footer-->
      <app-footer></app-footer>
      <!--end::Footer-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Page-->
</div>

<app-quick-user
  [loginProfile]="(authProfile.state | async).authProfile"
  (logOut)="auth.logOut()"></app-quick-user>

<lib-loading></lib-loading>
