import { AuthProfileStateService } from '@console/authentication/profile-state';
import { AuthRoleStateService } from './../../../authentication/src/lib/auth-permission';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MerchantAnalytics, MerchantAnalyticState, MerchantAnalyticStateService } from './states/analytics.state';
import { DateUtil } from '@console/shared/utils/dateUtil';
import { ConsoleRouterService } from '@console/shared';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReportEnum } from './enums/ReportEnum';

@Component({
  selector: 'lib-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['analytics.component.scss'],
})

export class AnalyticsComponent implements OnInit, OnDestroy {
  private query = {
    from: DateUtil.subtractDaysFromDate(new Date(), 7).toISOString(),
    to: new Date().toISOString(),
  };

  componentDestroyed$: Subject<boolean> = new Subject();

  stats: MerchantAnalytics;

  reportLoading = false;
  tableData: unknown = [];
  activeReport: ReportEnum = ReportEnum.ORDERS;

  constructor(
    public analytics: MerchantAnalyticStateService,
    private consoleRouter: ConsoleRouterService,
    public role: AuthRoleStateService,
    public authProfile: AuthProfileStateService
  ) {}

  ngOnInit(): void {
    this.listenOnState();

    this.analytics.loadStatsRange(this.query);
  }

  private listenOnState(): void {
    // this.loading = true;
    this.analytics.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: MerchantAnalyticState) => {
          this.reportLoading = state.reportLoading;
          this.activeReport = state.activeReport;

          this.tableData = state.reports[this.activeReport];

          this.stats = state.stats;
        }
      );
  }

  get totalOrders(): number {
    try {
      return this.stats?.orders[0].buyCount + this.stats?.orders[0].sellCount;
    } catch (error) {
      return 0;
    }
  }

  get totalUsers(): number {
    try {
      return this.stats?.users[0].totalUser;
    } catch (error) {
      return 0;
    }
  }

  get totalPayments(): number {
    try {
      return this.stats?.payments[0].usdFundingCount + this.stats?.payments[0].ngnFundingCount;
    } catch (error) {
      return 0;
    }

  }

  get totalTransactions(): number {
    try {
      return this.stats?.transactions[0].totalLocalCreditCount +
        this.stats?.transactions[0].totalLocalDebitCount +
        this.stats?.transactions[0].totalLocalExchangeCount +
        this.stats?.transactions[0].totalLocalWithdrawalsCount +
        this.stats?.transactions[0].totalGlobalCreditCount +
        this.stats?.transactions[0].totalGlobalDebitCount +
        this.stats?.transactions[0].totalGlobalExchangeCount +
        this.stats?.transactions[0].totalGlobalWithdrawalsCount;
    } catch (error) {
      return 0;
    }
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  viewById(id: string): void {
    const referenceMap = {
      [ReportEnum.ORDERS]: 'orders',
      [ReportEnum.PAYMENTS]: 'payments',
      [ReportEnum.TRANSACTIONS]: 'transaction'
    }

    const link = referenceMap[this.activeReport];
    this.consoleRouter.productNavigate([link, id]);
  }

  navigate(link: string): void {
    this.consoleRouter.productNavigate([link]);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.analytics.resetState();
  }


  // handleQuery(key, value) {
  //   value = new Date(value).toISOString();
  //   this.query[key] = value;
  //   this.analytics.loadStatsRange(this.query);
  // }

  // get finance$() {
  //   return this.analytics.state.pipe(
  //     map((state) =>
  //       objectToChartData(state.stats.payments as any, [
  //         { key: 'ngnFundingValue', title: 'Naira Funding' },
  //         {
  //           key: 'usdFundingValue',
  //           title: 'Dollar Funding',
  //         },
  //         // {
  //         //   key: 'ngnFundingCount',
  //         //   title: 'Naira Counts',
  //         // },
  //         // {
  //         //   key: 'usdFundingCount',
  //         //   title: 'Dollar Counts',
  //         // },
  //       ])
  //     )
  //   );
  // }



}
