<section-search-header
  [section]="'Merchants'"
  (onSearch)="search($event)"
  [count]="(merchant.state | async).count"
  [loading]="(merchant.state | async).loading"
  (actionBtn)="navigate()"
  [actionText]="'Create Merchant'"></section-search-header>

<router-outlet
  (activate)="childRoute = true"
  (deactivate)="childRoute = false"></router-outlet>

<div class="d-flex flex-column-fluid" *ngIf="!childRoute">
  <!--begin::Container-->
  <div class="container-fluid">
    <!--begin::Notice-->

    <!--end::Notice-->
    <!--begin::Row-->

    <kt-merchant-grid
      (onSelect)="navigate($event.id)"
      [merchants]="(merchant.state | async).merchants">
    </kt-merchant-grid>
    <!--end::Container-->

    <div class="row">
      <div class="col-xl-12 text-left">
        <lib-pagination (paginate)="paginate($event)" [count]="(merchant.state | async).count"></lib-pagination>
      </div>
    </div>
  </div>
</div>
