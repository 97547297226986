import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { CHANNEL_TYPE_ENUM } from '@console/shared/_enums';
import { Currency } from '@console/shared/_enums/currency.enum';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ChannelSetting } from '../../services/settings.interface';
import { ChannelListStateService } from '../channels.state';
import { ChannelStateService } from './channel.state';

@Component({
  selector: 'app-channel-edit',
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.scss'],
})
export class ChannelEditComponent implements OnInit {
  channelForm: FormGroup;
  hasFormErrors = false;

  public formMethods;

  currencyList = [Currency.NGN, Currency.USD];

  channelTypeList: { label: string; value: string }[] = [
    {
      label: 'Account Transfer',
      value: CHANNEL_TYPE_ENUM.ACCOUNT_TRANSFER,
    },
  ];

  existingRanks = [];

  componentDestroyed$: Subject<boolean> = new Subject();

  imageLoaded: boolean = false;

  formCreated = false;

  isUpdateMode = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public channelState: ChannelStateService,
    public channelListState: ChannelListStateService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];

      this.channelListState.ranks$
        .pipe(
          takeUntil(this.componentDestroyed$),
          filter((ranks) => Boolean(ranks.length))
        )
        .subscribe((ranks) => {
          this.existingRanks = ranks;
        });

      if (id) {
        this.setupFormWithState(id);
      } else {
        this.createForm();
      }
    });
  }

  setupFormWithState(channelId: number) {
    this.channelState.retrieve(channelId);

    this.channelState.channel$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((channel) => Boolean(channel))
      )
      .subscribe((channel) => {
        if (channel?.rank && channel.rank !== 0) {
          this.existingRanks = this.existingRanks.filter(
            (rank) => channel.rank !== rank
          );
        }

        this.createForm(channel);
      });
  }

  forbiddenRank(control: FormControl): { [s: string]: boolean } {
    if (this.existingRanks.includes(control.value)) {
      return { rankIsTaken: true };
    }

    return null;
  }

  validImage(control: FormControl): { [s: string]: boolean } {
    if (this.imageLoaded === false && control.value) {
      return { invalidImage: true };
    }

    return null;
  }

  private createForm(channel?: Partial<ChannelSetting>) {
    this.channelForm = new FormGroup({
      channelType: new FormControl(
        channel?.channelType || '',
        Validators.required
      ),
      currencyCode: new FormControl(
        channel?.currencyCode || '',
        Validators.required
      ),
      description: new FormControl(
        channel?.description || ''
      ),
      imageUrl: new FormControl(channel?.imageUrl || '', [
        Validators.required,
        this.validImage.bind(this),
      ]),
      name: new FormControl(channel?.name || '', Validators.required),
      rank: new FormControl(channel?.rank || '', [
        Validators.required,
        Validators.min(1),
        this.forbiddenRank.bind(this),
      ]),
      status: new FormControl(channel?.status || false),
    });

    if (Boolean(channel)) {
      this.channelForm.addControl(
        'id',
        new FormControl(channel.id, Validators.required)
      );

      this.isUpdateMode = true;
    } else {
      this.isUpdateMode = false;
    }

    this.formMethods = formMethods(this.channelForm);

    this.formCreated = true;
  }

  delete() {
    const confirm = prompt('Are you sure you want to delete this channel?');

    if (!confirm) {
      return;
    }

    const { id } = this.channelForm.value;
    this.channelState.delete(id);
  }

  save() {
    if (!this.channelForm.valid) {
      this.channelForm.markAllAsTouched();
      return;
    }

    const { id } = this.channelForm.value;
    if (id) {
      this.channelState.update(this.channelForm.value);
    } else {
      this.channelState.create(this.channelForm.value);
    }
  }

  onRankChanged($event: string) {
    if (!String($event).includes('.')) {
      return;
    }

    const lhsRank = String($event).split('.')[0];

    this.channelForm.patchValue({
      rank: Number(lhsRank),
    });

    this.channelForm.get('rank').updateValueAndValidity();
  }

  imageError(event): void {
    this.imageLoaded = false;
    this.channelForm.get('imageUrl').updateValueAndValidity();
  }

  imageSuccess(event): void {
    this.imageLoaded = true;
    this.channelForm.get('imageUrl').updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
