import { Injectable } from '@angular/core';
import {
  PaginatedList,
  ReqFailureResponse
} from '../../../../api/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { InstrumentService } from './services/instrument.service';
import { InstrumentModel } from './models/instrument.interface';

export interface InstrumentSymbolsState {
  loading: boolean;
  count?: number;
  Instruments: InstrumentModel[];
  error?: string;
}

const initialState: InstrumentSymbolsState = {
  loading: false,
  Instruments: [],
};

@Injectable({ providedIn: 'root' })
export class InstrumentSymbolsStateService {
  state = new BehaviorSubject<InstrumentSymbolsState>(initialState);

  constructor(private InstrumentSvc: InstrumentService) { }

  fetchSymbols(symbols: string, query: Object = {}) {
    this.loading();
    this.InstrumentSvc
      .fetchInstrumentsBySymbols(symbols, query)
      .pipe(first())
      .subscribe({
        next: this.updateInstruments.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateInstruments(data: PaginatedList<InstrumentModel>) {
    this.state.next({
      loading: false,
      Instruments: data.data,
      count: data.count,
    });
  }

  private onError(error: ReqFailureResponse) {
    this.state.next({
      ...this.state.getValue(),
      error: error.message,
      loading: false,
    });
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
  }
}
