import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { th } from "date-fns/locale";
import { Permission } from '../services/roles.interface';

export interface ActionToggle {
	action: string;
	status: boolean;
	id?: number;
	initialStatus: boolean;
}

@Component({
	selector: "kt-permission-editor",
	templateUrl: "./permission-editor.component.html",
	styleUrls: ["./permission-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PermissionEditorComponent implements OnInit {
	@Input() actions: Permission[] = [];
	@Input() entity: string;
	@Input() disabled = false;

  @Input() public permissionIdMap: Record<string, boolean> = {};

	@Output() actionChange = new EventEmitter<Record<string, boolean>>();

	constructor() {}

  onChange() {
    this.actionChange.emit(this.permissionIdMap);
  }

	ngOnInit() {

  }
}
