import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { LoadingService } from '../../../../../shared/src/lib/components/loading/loading.service';
import { ICardList } from '../interface/ICardList';

import { IMandate } from '../interface/IMandate';

const MANDATE_URL = '/api/v1/mandates';
const CARD_URL = '/api/v1/cards';

@Injectable()
export class MandateService {

  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) { }

  fetchMandates(searchParams?): Observable<PaginatedList<IMandate>> {
    const mandates$ = this.http.get<PaginatedList<IMandate>>(
      MANDATE_URL,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(mandates$);
  }

  fetchMandateById(id: string): Observable<ReqSuccessResponse<IMandate>> {
    const mandate$ = this.http.get<ReqSuccessResponse<IMandate>>(
      `${MANDATE_URL}/${id}`
    );

    return this.loadingService.showLoaderUntilCompleted(mandate$);
  }

  deleteMandateById(id: string): Observable<ReqSuccessResponse<IMandate>> {
    return this.http.delete<ReqSuccessResponse<IMandate>>(
      `${MANDATE_URL}/${id}`
    );
  }

  fetchUserCards(userId: string): Observable<PaginatedList<ICardList>> {
    return this.http.get<PaginatedList<ICardList>>(
      CARD_URL,
      { userId }
    );
  }

  saveMandate(dataToSubmit): Observable<ReqSuccessResponse<IMandate>> {
    return this.http.post<ReqSuccessResponse<IMandate>>(MANDATE_URL, dataToSubmit);
  }

  updateMandate(dataToSubmit): Observable<ReqSuccessResponse<IMandate>> {
    return this.http.put<ReqSuccessResponse<IMandate>>(MANDATE_URL, dataToSubmit);
  }

}
