import { Router } from '@angular/router';
import { ConsoleRouterService } from '../../../../../../shared/src/lib/services/console-router.service';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BlogContent, BlogContentDetails } from '../services/blog.model';
import { BlogService } from '../services/blog.service';
import { ChakaAPIError, ReqSuccessResponse } from '../../../../../../api/src/public-api';
import { NgxSpinnerService } from 'ngx-spinner';

export enum CrudState {
  Retrieve = 'Retrieve',
  Retrieved = 'Retrieved',
  Create = 'Create',
  Created = 'Created',
  Update = 'Update',
  Updated = 'Updated',
  Delete = 'Delete',
  Deleted = 'Deleted',
  Initial = 'Initial',
  Error = 'Error',
}

export interface BlogEditorState {
  blog?: BlogContentDetails;
  // blogCont:blogContent;
  loading: boolean;
  error?: string;
  // status: CrudState;
  message?: string;
}

const initalState: BlogEditorState = {
  loading: false,
  // status: CrudState.Initial,
};


@Injectable({ providedIn: 'root' })
export class BlogEditorStateService {
  state = new BehaviorSubject<BlogEditorState>(initalState);

  constructor(private svc: BlogService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private console: ConsoleRouterService,
    private route:Router) { }

  create(blog: BlogContentDetails) {
    this.loading();
    this.svc.createBlogContent(blog).subscribe({
      next: this.saveblog.bind(this),
      error: this.onblogError.bind(this),
    });
  }

  retrieve(blogId: number) {
    this.loading();
    this.svc.getBlogContentById(blogId)
    .pipe(first())
    .subscribe({
      next: this.updateblog.bind(this),
      error: this.onblogError.bind(this),
    });
  }

  update(content: BlogContent) {
    this.loading();
    this.svc.updateBlogContent(content).subscribe({
      next: this.updateSavedblog.bind(this),
      error: this.onblogError.bind(this),
    });
  }

  delete(blogId: number) {
    this.loading();
    this.svc.deleteBlogContent(blogId).subscribe({
      next: this.deleteblog.bind(this),
      error: this.onblogError.bind(this),
    });
  }

  resetState() {
    this.state.next(initalState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
      // status: CrudState.Initial,
    });
  }

  get loading$() {
    return this.state.pipe(map(state => state.loading));
  }


  private updateblog(data: ReqSuccessResponse<BlogContentDetails>) {
    this.state.next({
      loading: false,
      blog: data.data,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private saveblog(data: ReqSuccessResponse<BlogContentDetails>) {
    this.state.next({
      loading: false,
      blog: data.data,
      message:"Loading Successful"
    });
    this.toastr.success('blog post Created Successfully','Success',{
      positionClass: 'toast-bottom-center',
      timeOut:3000
    })
    this.spinner.hide();

  }

  private updateSavedblog(data: ReqSuccessResponse<BlogContentDetails>) {
    this.state.next({
      loading: false,
      blog: data.data,
      message:"Post Updated Successfully",

    });
    this.toastr.success('Post Updated Successfully','Success',{
      positionClass: 'toast-bottom-center',
      timeOut:3000
    })
    this.spinner.hide();
    this.route.navigate(['/equity/blog']);

  }

  private deleteblog(data: ReqSuccessResponse<BlogContentDetails>) {
    this.state.next({
      loading: false,
      blog: data.data,
      message:"Post Deleted Successfully",

    });
    this.toastr.success('Post Deleted Successfully','Success',{
      positionClass: 'toast-bottom-center',
      timeOut:3000
    })
    this.spinner.hide();
    this.route.navigate(['/equity/blog']);

  }


  private onblogError(res: ChakaAPIError) {
    this.state.next({
      loading: false,
      error: res.error.message,
      // status: CrudState.Error,
    });
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading"
    });
  }
}
