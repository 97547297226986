<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <!--begin::Card-->
    <div [hidden]="activeExchange" class="card card-custom gutter-b">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">Exchanges</h3>
        </div>

        <!-- <div class="card-toolbar">
          <a href="javascript:;" (click)="downloadFile(filter)" class="btn btn-primary font-weight-bold">
            <i class="far fa-arrow-alt-circle-down"></i> Download</a>
        </div> -->
      </div>

      <div class="card-body">

        <!--begin: Search Form-->
        <div class="row align-items-center">
          <div class="col-lg-12">
            <form [formGroup]="searchForm">
              <div class="row align-items-center">
                <div class="col-md-2 my-2 my-md-0">
                  <div class="form-group">
                    <label>Search all feeds</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="q"
                      trimmedInput>
                  </div>
                </div>

                <div class="col-md-2 my-2 my-md-0">
                  <div class="form-group">
                    <label>Status</label>
                    <ng-select formControlName="status" [clearable]="false">
                      <ng-option [value]="status.value" *ngFor="let status of statusList">{{ status.name }}</ng-option>
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-2 my-2 my-md-0">
                  <div class="form-group">
                    <label>Order Side</label>
                    <ng-select formControlName="transactionType" [clearable]="false">
                      <ng-option [value]="order.value" *ngFor="let order of orderSides">{{ order.name }}</ng-option>
                    </ng-select>
                  </div>
                </div>

                <div
                  class="col-md-2 my-2 my-md-0"
                  *ngIf="isSuperAdmin">
                  <div class="form-group">
                    <label>Merchant</label>
                    <ng-select
                      formControlName="merchantId"
                      [clearable]="false"
                      [virtualScroll]="true"
                      [loading]="this.merchants?.loading">
                      <ng-option value="">All</ng-option>
                      <ng-option
                        [value]="merchant?.id"
                        *ngFor="let merchant of merchants?.list">
                        {{ merchant?.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-2 my-2 my-md-0">
                  <div class="form-group">
                    <label>From</label>
                    <input class="form-control" type="date" formControlName="from">
                  </div>
                </div>

                <div class="col-md-2 my-2 my-md-0">
                  <div class="form-group">
                    <label>To</label>
                    <input class="form-control" type="date" formControlName="to">
                  </div>
                </div>

                <div class="col-md-2 my-2 my-md-0">
                  <div class="form-group">
                    <label *ngIf="isSuperAdmin" class="d-block">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-light-primary font-weight-bold"
                      (click)="handleQuery()">Search</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
        <!--end::Search Form-->

        <lib-messages></lib-messages>

        <!--begin: Datatable-->
        <div
          [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

          <div class="mt-5 mb-5">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold">
                Selected {{selectedItems.size}} {{selectedItems.size<=1 ?'record': 'records'}}:

                <button [disabled]="selectedItems.size <= 0" (click)="downloadFile()" class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
                  <i class="far fa-arrow-alt-circle-down"></i> Download
                </button>

                <button
                  [disabled]="btnLoading.approve  || this.selectedItems.size < 1"
                  (click)="approveSelected()"
                  class="btn btn-success btn-sm font-weight-bold">
                  <i *ngIf="btnLoading.approve" class="fa fa-spinner fa-spin"></i>
                  <i *ngIf="!btnLoading.approve" class="far fa-check-circle"></i> Approve
                </button>
              </div>
            </div>
          </div>

          <div class="table-container">
            <table mat-table [dataSource]="dataSource" matMultiSort>

              <ng-container matColumnDef="check" sticky>
                <th
                  mat-header-cell
                  *matHeaderCellDef="let row">
                  <span style="width: 20px;">
                    <label class="checkbox checkbox-single checkbox-all">
                      <input
                        (change)="onAllChecked($event.target.checked)"
                        type="checkbox"
                        [checked]="allSelected">&nbsp;<span></span>
                    </label>
                  </span>
                </th>

                <td
                  mat-cell
                  *matCellDef="let row">
                  <span style="width: 20px;">
                    <label class="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        (change)="onItemChecked($event.target.checked, row)"
                        [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
                    </label>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let row; let i = index;">
                  {{ pageEvent.pageSize * (pageEvent.pageIndex) + i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdBy">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-multi-sort-header="createdBy"> User Name </th>
                <td mat-cell *matCellDef="let row">
                  <a
                    *ngIf="(!userId || !row?.userId) else noUser"
                    href="javascript:void(0)"
                    (click)="viewUser(row?.userId)">
                    {{row?.createdBy || "-"}}</a>

                  <ng-template #noUser>{{row?.createdBy || "-"}}</ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-multi-sort-header="amount"> Amount </th>
                <td mat-cell *matCellDef="let row"> ${{row?.amount | number: "1.1-2" || "-"}}</td>
              </ng-container>

              <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef> Reference </th>
                <td mat-cell *matCellDef="let row">
                  <a
                    href="javascript:void(0)"
                    (click)="openExchange(row)">
                    {{row?.transactionReference || "-"}}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="accountNumber">
                <th mat-header-cell *matHeaderCellDef> Account Number </th>
                <td mat-cell *matCellDef="let row"> {{row?.accountNo || "-"}}</td>
              </ng-container>

              <ng-container matColumnDef="approved">
                <th mat-header-cell *matHeaderCellDef> Approved </th>
                <td
                  mat-cell
                  *matCellDef="let row">
                  <span class="font-weight-bold"
                    [ngClass]="row?.authorizationStatus ? 'text-success': 'text-warning'">
                    {{row?.authorizationStatus ? "Yes" : "No" || "-"}}</span>

                </td>
              </ng-container>

              <ng-container matColumnDef="processed">
                <th mat-header-cell *matHeaderCellDef> Processed </th>
                <td
                  mat-cell
                  *matCellDef="let row">
                  <span class="font-weight-bold"
                    [ngClass]="row?.hasBeenProcessed ? 'text-success': 'text-warning'">
                    {{row?.hasBeenProcessed ? "Yes" : "No" || "-"}}</span>

                </td>
              </ng-container>

              <ng-container matColumnDef="approvalStatus">
                <th mat-header-cell *matHeaderCellDef> Approval Status </th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  [class]="row?.authorizationStatus ? 
                           'text-success' : 
                           'text-warning'">
                  {{row?.authorizationStatus ? "Approved" : "Pending" || "-"}}
                </td>
              </ng-container>

              <ng-container matColumnDef="dateCreated">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-multi-sort-header="dateCreated"> Date Created </th>
                <td mat-cell *matCellDef="let row"> {{row?.dateCreated | date:'mediumDate' || "-"}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="showNotAllowedRow(row) ? 'not-allowed-row':''">
              </tr>
            </table>
          </div>

          <mat-paginator
            class="mat-paginator-sticky"
            [length]="count"
            [pageSize]="pageEvent.pageSize"
            [pageSizeOptions]="[10, 50, 100, 150, 200]"
            (page)="pageEvent=$event; paginate($event)"
            showFirstLastButtons></mat-paginator>
        </div>

        <lib-no-data
          *ngIf="!loading && dataSource.data?.length <= 0"
          message="No Data"></lib-no-data>

        <!--end: Datatable-->
      </div>

    </div>

    <div [hidden]="!activeExchange" class="card shadow mb-4">

      <div class="card-header bg-light py-3">
        <button
          class="btn btn-success mr-2"
          style="color: white"
          href="javascript:;"
          matTooltip="Approve Exchange"
          (click)="approve(activeExchange?.id)"
          *ngIf="!(activeExchange?.authorizationStatus && activeExchange?.hasBeenProcessed)">
          <i class="fa fa-check"></i> {{activeExchange?.authorizationStatus? 'Process': 'Approve'}}
        </button>

        <button
          class="btn btn-primary"
          style="color: white"
          href="javascript:;"
          matTooltip="Approve Exchange"
          (click)="refresh(activeExchange?.id)"
          *ngIf="!activeExchange?.accountNo"><i class="fas fa-sync"></i> Refresh
        </button>

        <button
          (click)="onBackToSearch()"
          class="btn btn-secondary float-right">
          <i class="la la-arrow-left"></i>
          <span class="kt-hidden-mobile">Back</span>
        </button>
      </div>

      <div class="kt-form px-4 py-4">
        <div class="kt-form">
          <div class="kt-form__section kt-form__section--first">

            <div class="kt-form">
              <div class="form-group kt-form__group row pb-0">
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>User</h6>
                  <h5
                    (click)="viewUser(activeExchange?.userId)">
                    <a class="pretty-link"> {{ activeExchange?.createdBy || "-"}}</a>
                  </h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Account Number</h6>
                  <h5>{{ activeExchange?.accountNo || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Originating Bank</h6>
                  <h5>{{ activeExchange?.originatingBankName || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Bank Name</h6>
                  <h5>{{ activeExchange?.bankName || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Has Been Processed?</h6>
                  <h5>
                    {{ activeExchange?.hasBeenProcessed ? "Yes" : "No" }}
                  </h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Reference</h6>
                  <h5>{{ activeExchange?.transactionReference || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Batch Code</h6>
                  <h5>{{ activeExchange?.batchCode || "-" }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Amount</h6>
                  <h5>
                    {{ activeExchange?.amount | currency: "USD" }}
                  </h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Transaction Type</h6>
                  <h5>{{ activeExchange?.transactionType }}</h5>
                </div>
                <div class="col-sm-6 kt-margin-bottom-20">
                  <h6>Date</h6>
                  <h5>{{ activeExchange?.dateCreated | date: "medium" }}</h5>
                </div>
                <div class="col-12 kt-margin-bottom-20">
                  <h6>Comment</h6>
                  <h5>{{ activeExchange?.comment || "-" }}</h5>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


</div>
