<div
  class="container-fluid container-fluid-margin chaka-route-content"
  *ngIf="advisoryState.state | async as state"
>
  <div class="card shadow mb-4">
    <ngx-spinner
      bdColor="#4e73df66"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">
        {{ state.message }}
      </p>
    </ngx-spinner>
    <div class="card-header bg-light py-3">
      <div class="row">
        <div class="col-md-6">
          <h6 class="m-0 font-weight-bold text-primary">Advisory Fees</h6>
        </div>
        <div class="col-md-4">
          <h6 class="m-0 font-weight-bold text-primary"></h6>
        </div>
      </div>
    </div>
      <div class="card-body">
        <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">
          <div class="row mt-5 mb-5 d-none d-md-block">
            <div class="col-sm-12 table-responsive">
              <table class="table table-hover">
                <thead class="border-top-light">
                  <tr style="border-top: 0px !important">
                    <th scope="col" class="table-title">Charged At</th>
                    <th scope="col" class="table-title">Transaction ID</th>
                    <th scope="col" class="table-title">Fee</th>
                    <th scope="col" class="table-title">Net Asset Value</th>
                    <th scope="col" class="table-title">Status</th>
                    <!-- <th scope="col" class="table-title">Actions</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let fee of state.fees">
                    <td class="table-text" (click)="viewRate(fee.id)">
                      <a href="javascript:void(0)">{{ fee.createdAt |  date:'short' }}</a>
                    </td>
                    <td class="table-text">{{ fee.dailyFeePercentage }}%</td>
                    <td>{{ fee.dailyFee | currency }}</td>
                    <td>{{ fee.netAssetValue | currency }}</td>
                    <td class="table-text">
                      {{ fee.status }}
                    </td>
                    <!-- <td class="table-text px-4" (click)="viewRate(exchange?.id)"><i
                          class="fas fa-location-arrow text-primary fa-lg"></i></td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mb-3 d-sm-block d-md-none">
            <div class="col-lg-12 mt-3">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        <div class="form-group form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                          />
                        </div>
                      </th>
                      <th scope="col">Type</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="mb-0" *ngFor="let fee of state.fees">
                    <tr
                      class="accordion-toggle collapsed"
                      id="accordion3"
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapseThree"
                    >
                      <td class="expand-button"></td>
                      <td>
                        <div class="form-group form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            style="
                              background-color: #ccc !important;
                              color: #6b7188 !important;
                            "
                            id="exampleCheck1"
                          />
                        </div>
                      </td>
                      <td>
                        <i class="fas fa-circle kt-badge--primary"></i>
                        <small class="kt-font-primary px-2">{{
                          fee.createdBy
                        }}</small>
                      </td>
                      <td>
                        <i class="fas fa-cog flaticon2 px-2"></i
                        ><i class="fas fa-file flaticon2 px-2"></i
                        ><i class="fas fa-times flaticon2 px-2"></i>
                      </td>
                    </tr>
                    <tr class="hide-table-padding">
                      <td></td>
                      <td colspan=" 4">
                        <div id="collapseThree" class="collapse in p-3">
                          <div class="row">
                            <div class="col-sm-10 px-3">
                              <ul class="list-group">
                                <li
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span
                                    class="mobile-table-list font-weight-bold"
                                    >User Name</span
                                  >
                                  <span (click)="viewRate(fee.id)">{{
                                    fee.createdBy
                                  }}</span>
                                </li>
                                <li
                                  class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  <span
                                    class="mobile-table-list font-weight-bold"
                                    >Date</span
                                  >
                                  <span
                                    class="shaddow"
                                    style="
                                      border-radius: 1rem;
                                      background-color: #5867dd;
                                      font-size: 0.8rem;
                                      color: #fff;
                                    "
                                    >{{
                                      exchange?.dateCreated
                                        | date: "fullDate" || "-"
                                    }}</span
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <lib-pagination
            (paginate)="paginate($event)"
            [count]="state.count"
            [pageSize]="pageSize"
          ></lib-pagination>
        </div>
      </div>
  </div>
</div>
