import { PRODUCT_TYPE_ENUM } from '../_enums';

export const setProductTypeOnStorage = (productType: PRODUCT_TYPE_ENUM) => {
  return localStorage.setItem('productType', productType);
}

export const getProductTypeFromStorage = (): PRODUCT_TYPE_ENUM => {
  return localStorage.getItem('productType') as PRODUCT_TYPE_ENUM || PRODUCT_TYPE_ENUM.equity;
}

export const deleteProductTypeFromStorage = (): void => {
  localStorage.removeItem('productType');
}