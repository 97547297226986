import { MatMultiSort } from 'ngx-mat-multi-sort';
import {
  ICscsReportState,
  CscsReportStateService,
} from './state/cscs-report-state.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
import { FormControl, FormGroup } from '@angular/forms';
import {
  fileNameFormatter,
  exportAndDownload,
} from 'projects/_shared/csv-downloader/csv-downloader';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MerchantsModel } from '../merchants/merchants.interface';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import {
  MerchantListState,
  MerchantListStateService,
} from '../merchants/merchant-list/merchant-list.state';
import { ICscsReport } from './interface/ICscsReport';
import { CscsStatusEnum } from '@console/shared/_enums';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-cscs-report',
  templateUrl: './cscs-report.component.html',
  styleUrls: ['./cscs-report.component.scss'],
})
export class CscsReportComponent implements OnInit, OnDestroy {
  filter: Record<string, any> = {
    pageSize: 10,
    status: CscsStatusEnum.SUBMITTED_AND_APPROVED,
  };

  cscsStatusList = [
    {
      label: 'Submitted and Approved',
      value: CscsStatusEnum.SUBMITTED_AND_APPROVED,
    },
    {
      label: 'Submitted not Approved',
      value: CscsStatusEnum.SUBMISSION_NOT_APPROVED,
    },
    {
      label: 'Failed',
      value: CscsStatusEnum.FAILED,
    },
    {
      label: 'Pending Upload',
      value: CscsStatusEnum.PENDING_UPLOAD,
    },
  ];

  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'userId',
    'cscsNumber',
    'cscsCHN',
    'cscsSent',
    'uploadStatus',
    'cscsRequestRefNo',
    'dateCreated',
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  loading = false;

  count = 0;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  isSuperAdmin = false;

  // merchants: {
  //   list: MerchantsModel[];
  //   loading: boolean;
  //   total: number;
  //   pageSize: number;
  //   pageNumber: number;
  // } = {
  //   list: [],
  //   loading: false,
  //   total: 0,
  //   pageSize: 1000,
  //   pageNumber: 0,
  // };

  constructor(
    private consoleRouter: ConsoleRouterService,
    private cscsReportState: CscsReportStateService,
    private messagesService: MessagesService // private authProfile: AuthProfileStateService, // private merchantListState: MerchantListStateService
  ) {}

  ngOnInit(): void {
    this.filter.pageSize = this.pageEvent.pageSize;
    this.handleQuery();

    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    // this.listenOnAuthProfile();

    // this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      status: new FormControl(CscsStatusEnum.SUBMITTED_AND_APPROVED),
      merchantId: new FormControl(''),
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.cscsReportState.state$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: ICscsReportState) => {
        this.dataSource = new MatTableDataSource<ICscsReport>(
          state.cscsReports
        );
        this.dataSource.sort = this.sort;
        this.count = state.count;
        this.loading = state.loading;
      });
  }

  // listenOnAuthProfile(): void {
  //   this.authProfile.isSuperAdmin$
  //     .pipe(
  //       takeUntil(this.componentDestroyed$),
  //       filter((isSuperAdmin) => isSuperAdmin)
  //     )
  //     .subscribe((isSuperAdmin) => {
  //       this.isSuperAdmin = isSuperAdmin;

  //       this.isSuperAdmin
  //         ? this.merchantListState.find({
  //             pageSize: this.merchants.pageSize,
  //             pageNumber: this.merchants.pageNumber,
  //           })
  //         : null;
  //     });
  // }

  // listenOnMerchants(): void {
  //   this.merchantListState.state
  //     .pipe(
  //       takeUntil(this.componentDestroyed$),
  //       filter(() => this.isSuperAdmin)
  //     )
  //     .subscribe((state: MerchantListState) => {
  //       this.merchants.loading = state?.loading;
  //       this.merchants.total = state?.count;
  //       this.merchants.list = [...this.merchants.list, ...state?.merchants];
  //     });
  // }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.cscsReportState.find(this.filter);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach((withdrawal) => {
      this.updateCheckedSet(checked, withdrawal);
    });
  }

  onItemChecked(checked: boolean, withdrawal: ICscsReport): void {
    this.updateCheckedSet(checked, withdrawal);
  }

  updateCheckedSet(checked: boolean, withdrawal: ICscsReport): void {
    if (checked) {
      this.selectedItems.set(withdrawal.id, withdrawal);
    } else {
      this.selectedItems.delete(withdrawal.id);
    }
  }

  get selectedCscsReports(): ICscsReport[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Cscs Report',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedCscsReports);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.cscsReportState.reset();

    this.messagesService.hideAll();

    // this.merchantListState.reset();
  }
}
