<mat-dialog-content>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <lib-messages></lib-messages>
                </div>

                <h2 mat-dialog-title>Send Rejection Email</h2>

                <div class="table-responsive" [hidden]="(dataSource && dataSource.length <= 0)">

                    <div class="table-container">
                        <table class="table table-head-custom table-head-bg table-vertical-center table-borderless"
                            mat-table [dataSource]="dataSource">

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="text-dark-75">Status</span>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <span class="text-dark-75 d-block font-size-lg">{{row?.mappedStatus}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="sendgridTemplateId">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="text-dark-75">Send Grid Template ID</span>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <span class="text-dark-75 d-block font-size-lg">
                                        {{row.sendgridTemplateId.length > 20 ? (row.sendgridTemplateId | slice:0:19) +
                                        '...' : row.sendgridTemplateId}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="reasonForRejection">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="text-dark-75">Rejection Reason</span>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <span class="text-dark-75 d-block font-size-lg">
                                        {{row.reasonForRejection.length > 15 ? (row.reasonForRejection | slice:0:14) +
                                            '...' : row.reasonForRejection}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef class="text-center">
                                    <span class="text-dark-75">Action</span>
                                </th>
                                <td mat-cell *matCellDef="let row" class="text-center">
                                    <span style="overflow: visible; position: relative; width: 125px;">
                                        <button href="javascript:;" (click)="sendRejectionEmail(row)" type="button"
                                            class="btn btn-clean btn-primary mr-2">
                                            Send Email
                                        </button>
                                    </span>
                                </td>
                            </ng-container>

                            <thead>
                                <tr class="bg-gray-100 text-left" mat-header-row
                                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            </thead>

                            <tbody>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <mat-paginator class="mat-paginator-sticky" [length]="count"
                        [pageSizeOptions]="[10, 50, 100, 150, 200]" (page)="pageEvent=$event; paginate($event)"
                        showFirstLastButtons></mat-paginator>
                </div>

                <div *ngIf="dataSource.length <= 0 && !loading"
                    class="no-data-container d-flex flex-column justify-content-center align-items-center">
                    <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
                    <p class="no-data-text">No data</p>
                </div>
            </div>

        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions mat-dialog-actions class="d-flex align-items-center justify-content-between">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <!-- <button type="submit" class="btn btn-primary" (click)="">Send Email</button> -->
</mat-dialog-actions>