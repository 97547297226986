import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  PaginatedList,
  ChakaAPIError,
  cleanChakaAPIError,
} from '../../../../../api/src/lib/api.interface';
import { MerchantsModel } from '../merchants.interface';
import { MerchantService } from '../services/merchant.service';

export interface MerchantListState {
  loading: boolean;
  count?: number;
  merchants: MerchantsModel[];
  error?: string;
}

const initialState: MerchantListState = {
  loading: false,
  merchants: [],
};

@Injectable({ providedIn: 'root' })
export class MerchantListStateService {
  state = new BehaviorSubject<MerchantListState>(initialState);

  constructor(private merchants: MerchantService) { }

  find(query: object) {
    this.loading();
    this.merchants
      .getMerchants(query)
      .pipe(first())
      .subscribe({
        next: this.updateMerchants.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateMerchants(data: PaginatedList<MerchantsModel>) {
    this.state.next({
      loading: false,
      merchants: data.data,
      count: data.count,
    });
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
    });
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true
    });
  }
}
