<!--begin::Search Form-->
<div class="mb-4">
  <div class="row align-items-center">
    <div class="col-lg-12">
      <form [formGroup]="searchForm">
        <div class="row align-items-center">

          <div class="col-md-3 my-2 my-md-0">
            <div class="form-group">
              <label>Search all feeds</label>
              <input
                type="text"
                class="form-control"
                formControlName="q"
                trimmedInput>
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>Type</label>
              <ng-select formControlName="action" [clearable]="false">
                <ng-option [value]="type.value" *ngFor="let type of transactionTypes">{{ type.name }}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>Status</label>
              <ng-select formControlName="status" [clearable]="false">
                <ng-option [value]="status.value" *ngFor="let status of walletTransactionStatus">{{ status.name }}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>Wallet Type</label>
              <ng-select formControlName="walletType" [clearable]="false">
                <ng-option [value]="type.value" *ngFor="let type of walletTransactionType">{{ type.name }}</ng-option>
              </ng-select>
            </div>
          </div>

          <div
            class="col-md-3 my-2 my-md-0"
            *ngIf="isSuperAdmin">
            <div class="form-group">
              <label>Merchant</label>
              <ng-select
                formControlName="merchantId"
                [clearable]="false"
                [virtualScroll]="true"
                [loading]="this.merchants?.loading">
                <ng-option value="">All</ng-option>
                <ng-option
                  [value]="merchant?.id"
                  *ngFor="let merchant of merchants?.list">
                  {{ merchant?.name }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>From</label>
              <input class="form-control" type="date" formControlName="from">
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label>To</label>
              <input class="form-control" type="date" formControlName="to">
            </div>
          </div>

          <div class="col-md-2 my-2 my-md-0">
            <div class="form-group">
              <label *ngIf="isSuperAdmin" class="d-block">&nbsp;</label>
              <button
                type="button"
                class="btn btn-light-primary font-weight-bold"
                (click)="handleQuery()">Search</button>
            </div>
          </div>

        </div>
      </form>

    </div>
  </div>


</div>
<!--end::Search Form-->

<!--begin: Datatable-->

<lib-messages></lib-messages>

<div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

  <div class="mt-4 mb-4">
    <div class="d-flex align-items-center">
      <div class="font-weight-bold">
        Selected {{selectedItems.size}} {{selectedItems.size > 0 ?'record': 'records'}}:

        <button [disabled]="selectedItems.size <= 0" (click)="downloadFile()" class="btn btn-primary btn-sm font-weight-bold ml-4">
          <i class="far fa-arrow-alt-circle-down"></i> Download
        </button>
      </div>
    </div>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matMultiSort>

      <ng-container matColumnDef="check" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single checkbox-all">
              <input
                (change)="onAllChecked($event.target.checked)"
                type="checkbox"
                [checked]="allSelected">&nbsp;<span></span>
            </label>
          </span>
        </th>

        <td
          mat-cell
          *matCellDef="let row">
          <span style="width: 20px;">
            <label class="checkbox checkbox-single">
              <input
                type="checkbox"
                (change)="onItemChecked($event.target.checked, row)"
                [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
            </label>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="fullName"> User Name </th>
        <td mat-cell *matCellDef="let row">

          <a
            *ngIf="(!userId || !row?.userId) else noUser"
            href="javascript:void(0)"
            (click)="viewUser(row?.userId)">
            {{row?.fullName || "-"}}</a>

          <ng-template #noUser>{{row?.fullName || "-"}}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Reference </th>
        <td mat-cell *matCellDef="let row">
          <a
            href="javascript:void(0)"
            (click)="emit(row)">
            {{row?.reference || "-"}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let row"> {{row?.action || "-" | titlecase}}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="amount"> Amount </th>
        <td mat-cell *matCellDef="let row">
          {{ row?.amount ? formatToCurrency(row?.currencyCode, row?.amount) : "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="walletType">
        <th mat-header-cell *matHeaderCellDef> Wallet Type </th>
        <td
          mat-cell
          *matCellDef="let row">

          <span
            [ngClass]="row?.walletType == 'LOCAL' ? 'label-primary': 'label-success'"
            class="label  label-dot mr-2"></span>
          <span class="font-weight-bold"
            [ngClass]="row?.walletType == 'LOCAL' ? 'text-primary': 'text-success'">
            {{row?.walletType || "-" | titlecase}}</span>

        </td>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="dateCreated"> Date Created </th>
        <td mat-cell *matCellDef="let row">
          {{row?.dateCreated | date: 'medium' || "-"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td
          mat-cell
          *matCellDef="let row">

          <span class="font-weight-bold"
            [ngClass]="[row?.status == 'SUCCESSFUL' ? 'text-success' : '',
                        row?.status == 'PENDING' ? 'text-warning' : '']">
            {{row?.status || "-" | titlecase}}</span>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
        *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>

  <mat-paginator
    class="mat-paginator-sticky"
    [length]="count"
    [pageSizeOptions]="[10, 50, 100, 150, 200]"
    (page)="pageEvent=$event; paginate($event)"
    showFirstLastButtons></mat-paginator>
</div>


<div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
  <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
  <p class="no-data-text">No data</p>
</div>

<!--end: Datatable-->
