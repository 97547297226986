import { Component, Input } from '@angular/core';
import { AccountSummary } from '../../../../models/positions.interface';
import { formatToCurrency } from '../../../../../../../shared/src/lib/utils/formatter';
import { CURRENCY } from '../../../../../../../shared/src/lib/_enums/currency.enum';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent {

  @Input() portfolio: AccountSummary;
  @Input() currency = CURRENCY.DOLLAR;

  formatToCurrency = formatToCurrency;

  constructor() { }

}
