import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApiModule } from '../../../../api/src/lib/api.module';
import { ConsoleRouterService } from '../../../../shared/src/lib/services/console-router.service';
import { PaymentListStateService } from './payment-list/payment-state';
import { PaymentComponent } from './payment-list/payment.component';
import { PaymentStateService } from './payments-edit/payments-edit-state';
import { PaymentTxnService } from './services/payment.service';
import { PaymentsTableModule } from './payments-table/payments-table.module';
import { PaymentsEditComponent } from './payments-edit/payments-edit.component';
import { PaymentOtpModalComponent } from './payments-edit/components/payment-otp-modal/payment-otp-modal.component';
import { SharedModule } from '@console/shared';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ApiModule,
    NgxSpinnerModule,
    NgxSmartModalModule,
    RouterModule,
    PaymentsTableModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  exports: [PaymentComponent, PaymentsEditComponent],
  declarations: [
    PaymentComponent,
    PaymentsEditComponent,
    PaymentOtpModalComponent,
  ],
  providers: [
    PaymentListStateService,
    PaymentTxnService,
    PaymentStateService,
    ConsoleRouterService,
  ],
})
export class PaymentTxnModule {}
