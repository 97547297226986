import { Component, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import { ColorScheme } from '../config.interface';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent implements OnInit {
  option: any;

  constructor() { }

  createChart() {
    const option: any = {
      title: {
        text: '堆叠区域图',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      legend: {
        data: ['邮件营销', '联盟广告', '视频广告'],
      },
      grid: {
        left: '0%',
        right: '0%',
        top: '0%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三'],
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: '邮件营销',
          type: 'line',
          stack: '总量',
          areaStyle: {
            color: new (echarts as any).graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: ColorScheme.blue
            }, {
              offset: 1,
              color: ColorScheme.pink
            }])
          },
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: '联盟广告',
          type: 'line',
          stack: '总量',
          areaStyle: {
            color: new (echarts as any).graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: ColorScheme.blue
            }, {
              offset: 1,
              color: ColorScheme.green
            }])
          },
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: '视频广告',
          type: 'line',
          stack: '总量',
          areaStyle: {
            color: new (echarts as any).graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: ColorScheme.pink
            }, {
              offset: 1,
              color: ColorScheme.green
            }])
          },
          data: [150, 232, 201, 154, 190, 330, 410],
        },

      ],
    };
    option.xAxis = [
      {
        show: false,
        type: 'category',
        axisTick: { show: false },
        data: ['2012', '2013', '2014', '2015', '2016'],
      },
    ];

    option.yAxis = [
      {
        show: false,
        type: 'value',
      },
    ];

    return option;
  }

  ngOnInit(): void {
    this.option = this.createChart();
  }
}
