<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">Wallet Transactions</h3>
        </div>

      </div>

      <div class="card-body">

        <kt-transactions-table
          (query)="query($event)"
          (download)="downloadFile($event)"
          (transaction)="viewTransaction($event)"
          [userId]="userId"></kt-transactions-table>

      </div>
    </div>

  </div>
</div>
