<div *ngIf="order" class="row kt-widget5__data">
  <div class="col-md-6">
    <div class="kt-widget5__section">
      <a href="javascript:;" class="kt-widget5__title">
        <strong>{{ order.symbol }}</strong></a
      >
      <p class="kt-widget5__desc">
        <strong>{{ order.orderQty | number: "1.2-4" }} Units</strong>
      </p>
      <p class="kt-widget5__desc {{ order.side.toLowerCase() }}">
        {{ order.side }}
      </p>
      <p>{{ order.orderType }} ORDER</p>
    </div>
  </div>
  <div class="col-md-6">
    <p>
      <span class="kt-badge kt-badge--{{ status.color }} kt-badge--dot"></span>
      {{ status.data }}
    </p>
    <ng-container *ngIf="canRequery">
      <div class="mt-3">
        <button
          (click)="requery()"
          class="btn btn-primary"
        >
          Re-Query
        </button>
      </div>
      <div class="mt-3">
        <!-- <button
          (click)="cancel()"
          class="btn btn-danger"
        >
          Cancel
        </button> -->
      </div>
    </ng-container>
  </div>
</div>
