import { KYC_COUNTRY, KYC_STATUS } from '@console/shared';

export interface KYCListQueryParam {
  pageSize: number;
  pageNumber: number;
  status: KYC_STATUS;
  country: KYC_COUNTRY;
  merchantId: number;
}

export interface KYCData {
  firstName: string;
  lastName: string;
  email: null;
  phone: string;
  userId: string;
  dateCreated: null;
  country: string;
  status: string;
}

export interface ApproveKyc {
  kycCountry: KYC_COUNTRY;
  userId: string;
  kycStatus: string;
}

export interface DisableUserRequest {
  userId: string;
}

export interface BankModel {
  id: string;
  code: string;
  name: string;
  countryCode: string;
}

export enum UploadType {
  PASSPORT = 'PASSPORT',
  ADDRESS_PROOF = 'ADDRESS_PROOF',
  PHOTO_ID = 'PHOTO_ID',
  GENERIC = 'GENERIC',
}

export interface FileUpload {
  file: File;
  uploadType: UploadType;
  userId: string;
}

export interface FileUploadData {
  userId: string;
  uploadType: string;
  fileName: string;
  fileUrl: string;
  base64File: null;
}


export interface CSCSTokenUpload {
  userId: string;
  token: string;
}




export interface IBVNDetails {
  bvn: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  phoneNumber: string;
  gender: string;
  passport: string;
}

