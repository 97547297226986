export class DateUtil {

  static addDaysToDate(date: Date, days: number): Date {
    const realDate = new Date(date.valueOf());
    realDate.setDate(realDate.getDate() + days);
    return realDate;
  }

  static subtractDaysFromDate(date: Date, days: number): Date {
    const realDate = new Date(date.valueOf());
    realDate.setDate(realDate.getDate() - days);
    return new Date(realDate);
  }

  static onlyDate(): Date {
    const onlyDate = new Date().toISOString().substring(0, 10);

    return new Date(onlyDate);
  }

  static extractDate(dateString: Date): string {
    try {
      return dateString.toString().substring(0, 10);
    } catch (error) {
      return '';
    }
  }

  // static convertDateToString(date: Date): string {
  //   const month = (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  //   const day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
  //   return `${date.getFullYear()}-${month}-${day}`
  // }

  // static convertFormat(date: string, separator: string = "-"): string {
  //   if (date) {
  //     let dateArr: string[] = date.split("-");
  //     let [year, month, day] = dateArr;
  //     month = month.toString().padStart(2, "0");
  //     day = day.toString().padStart(2, "0");
  //     return `${year}${separator}${month}${separator}${day}`;
  //   }
  //   return null;

  // }


}