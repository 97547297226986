import { AccountManagerComponent } from './account-manager.component';
import { SharedModule } from '../../../../shared/src/lib/shared.module';
import { AccountManagerService } from './service/account-manager.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateAccountManagerComponent } from './components/account-manager-create/account-manager-create.component';
import { AccountManagerUsersComponent } from './components/account-manager-users/account-manager-users.component';

@NgModule({
  declarations: [
    AccountManagerComponent,
    CreateAccountManagerComponent,
    AccountManagerUsersComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    AccountManagerService
  ]
})

export class AccountManagerModule { }