export enum RedemptionStatusEnum {
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  OTHER = 'OTHER',
  RIA_PENDING = 'RIA_PENDING',
  RIA_APPROVED = 'RIA_APPROVED',
  RIA_REJECTED = 'RIA_REJECTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  On_Hold = 'On_Hold'
}
