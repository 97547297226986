import { CollectionsGridComponent } from './collections-grid/collections-grid.component';
import { CollectionsCardComponent } from './collections-card/collections-card.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    CollectionsCardComponent,
    CollectionsGridComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [CollectionsCardComponent
    , CollectionsGridComponent
    , RouterModule],
})
export class CollectionSharedModule { }
