<ng-template #backButton>
  <button
    (click)="goBack()"
    class="btn btn-sm btn-transparent border mr-4">
    <i class="fas fa-arrow-left"></i> Back
  </button>
</ng-template>

<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div
    class="container"
    *ngIf="(merchant.state | async)?.clientCredentials as clientCredentials else newMerchant">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title">
          <ng-container *ngTemplateOutlet="backButton"></ng-container>
          <h3 class="card-label">{{clientCredentials?.name}}</h3>
        </div>
        <div class="card-toolbar">
          <ul class="nav nav-light-primary nav-bold nav-pills">
            <li
              class="nav-item"
              *ngIf="(role.hasPermission$('MERCHANT_DELETE') && 
                      role.hasPermission$('MERCHANT_UPDATE'))">
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="this.currentTabIndex===0 ? 'active' : ''"
                (click)="setTab(0)">
                <span class="nav-icon">
                  <i class="fas fa-align-left"></i>
                </span>
                <span class="nav-text">Details</span>
              </a>
            </li>
            <li
              class="nav-item"
              *ngIf="role.hasPermission$('MERCHANT_UPDATE')">
              <a
                class="nav-link"
                href="javascript:;"
                [ngClass]="this.currentTabIndex===1 ? 'active' : ''"
                (click)="setTab(1)">
                <span class="nav-icon">
                  <i class="fas fa-cogs"></i>
                </span>
                <span class="nav-text">Configurations</span>
              </a>
            </li>
            <li
              class="nav-item"
              *ngIf="role.hasPermission$('MERCHANT_UPDATE')">
              <a
                class=" nav-link"
                href="javascript:;"
                [ngClass]="this.currentTabIndex===2 ? 'active' : ''"
                (click)="setTab(2)">
                <span class="nav-icon">
                  <i class="fas fa-hand-holding-usd"></i>
                </span>
                <span class="nav-text">Fees</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="card-body">
        <div class="tab-content">
          <div class="tab-pane fade active show" role="tabpanel">

            <ng-container [ngSwitch]="currentTabIndex">
              <kt-merchant-display
                *ngSwitchCase="0"
                [credentials]="clientCredentials"
                [merchant]="(merchant.state | async)?.merchant"></kt-merchant-display>

              <kt-merchant-editor
                *ngSwitchCase="1"
                (delete)="merchant.delete($event)"
                [disabled]="false"
                (save)="merchant.update($event)"
                [merchant]="(merchant.state | async)?.merchant"></kt-merchant-editor>

              <merchant-fee
                *ngSwitchCase="2"
                [merchant]="(merchant.state | async)?.merchant">
              </merchant-fee>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-template #newMerchant>
    <div class="container">
      <div class="card card-custom" *ngIf="role.hasPermission$('MERCHANT_UPDATE')">
        <div class="card-header">
          <div class="card-title">
            <ng-container *ngTemplateOutlet="backButton"></ng-container>
            <h3 class="card-label">New Merchant</h3>
          </div>
        </div>

        <div class="card-body">
          <div *ngIf="role.hasPermission$('MERCHANT_UPDATE')" class="row">
            <div class="col-md-12">
              <kt-merchant-editor
                (delete)="merchant.delete($event)"
                [disabled]="false"
                (save)="merchant.update($event)"
                [merchant]="(merchant.state | async)?.merchant"></kt-merchant-editor>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-template>

</div>
