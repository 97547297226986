import { RedemptionStatusEnum } from './enums/RedemptionStatus.enum';
import { MatMultiSort } from 'ngx-mat-multi-sort';
import {
  MandateListState,
  MandateListStateService,
} from './state/mandate-list-state.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
import { FormControl, FormGroup } from '@angular/forms';

import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';

import { MerchantsModel } from '../merchants/merchants.interface';
import {
  MerchantListState,
  MerchantListStateService,
} from '../merchants/merchant-list/merchant-list.state';
import { IMandate } from './interface/IMandate';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import {
  fileNameFormatter,
  exportAndDownload,
} from 'projects/_shared/csv-downloader/csv-downloader';
import { ClientUserModel } from '@console/user-managements';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-mandate-list',
  templateUrl: './mandate-list.component.html',
  styleUrls: ['./mandate-list.component.scss'],
})
export class MandateListComponent implements OnInit, OnDestroy {
  constructor(
    private consoleRouter: ConsoleRouterService,
    private mandateListState: MandateListStateService,
    private messagesService: MessagesService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService
  ) {}

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  get selectedRedemptions(): IMandate[] {
    return Array.from(this.selectedItems.values());
  }

  filter: Record<string, any> = {};

  @Input() userInfo: ClientUserModel;

  redemptionStatusList = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Started',
      value: RedemptionStatusEnum.STARTED,
    },
    {
      label: 'Pending',
      value: RedemptionStatusEnum.PENDING,
    },
    {
      label: 'Successful',
      value: RedemptionStatusEnum.SUCCESSFUL,
    },
    {
      label: 'Failed',
      value: RedemptionStatusEnum.FAILED,
    },
    {
      label: 'Other',
      value: RedemptionStatusEnum.OTHER,
    },
    {
      label: 'RIA Pending',
      value: RedemptionStatusEnum.RIA_PENDING,
    },
    {
      label: 'RIA Approved',
      value: RedemptionStatusEnum.RIA_APPROVED,
    },
    {
      label: 'RIA Rejected',
      value: RedemptionStatusEnum.RIA_REJECTED,
    },
    {
      label: 'Approved',
      value: RedemptionStatusEnum.APPROVED,
    },
    {
      label: 'Rejected',
      value: RedemptionStatusEnum.REJECTED,
    },
    {
      label: 'On Hold',
      value: RedemptionStatusEnum.On_Hold,
    },
  ];

  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'customerName',
    'customerPhone',
    'source',
    'notificationType',
    'currency',
    'frequency',
    'productType',
    'dateCreated',
    'action',
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  loading = false;

  count = 0;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[];
    loading: boolean;
    total: number;
    pageSize: number;
    pageNumber: number;
  } = {
    list: [],
    loading: false,
    total: 0,
    pageSize: 1000,
    pageNumber: 0,
  };

  activeMandate: IMandate;

  @ViewChild('drawer') drawer: MatDrawer;

  ngOnInit(): void {
    this.filter.pageSize = this.pageEvent.pageSize;
    this.handleQuery();

    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      q: new FormControl(''),
      status: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(),
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.mandateListState.state$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: MandateListState) => {
        this.dataSource = new MatTableDataSource<IMandate>(state.mandates);
        this.dataSource.sort = this.sort;
        this.count = state.count;
        this.loading = state.loading;
      });
  }

  listenOnAuthProfile(): void {
    this.authProfile.isSuperAdmin$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((isSuperAdmin) => isSuperAdmin)
      )
      .subscribe((isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;

        this.isSuperAdmin
          ? this.merchantListState.find({
              pageSize: this.merchants.pageSize,
              pageNumber: this.merchants.pageNumber,
            })
          : null;
      });
  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe((state: MerchantListState) => {
        this.merchants.loading = state?.loading;
        this.merchants.total = state?.count;
        this.merchants.list = [...this.merchants.list, ...state?.merchants];
      });
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  viewMandate(mandateId: number): void {
    this.consoleRouter.productNavigate(['mandate', mandateId]);
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  get fromUserComponent(): boolean {
    return this.userInfo && this.userInfo.hasOwnProperty('userId');
  }

  handleQuery(): void {
    if (this.fromUserComponent) {
      this.filter.userId = this.userInfo.userId;
    }

    this.mandateListState.find(this.filter);
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach((withdrawal) => {
      this.updateCheckedSet(checked, withdrawal);
    });
  }

  onItemChecked(checked: boolean, withdrawal: IMandate): void {
    this.updateCheckedSet(checked, withdrawal);
  }

  updateCheckedSet(checked: boolean, withdrawal: IMandate): void {
    if (checked) {
      this.selectedItems.set(withdrawal.id, withdrawal);
    } else {
      this.selectedItems.delete(withdrawal.id);
    }
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Redemptions',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedRedemptions);
  }

  openDrawer(mandate?: IMandate): void {
    // we want a copy of the object just to curb mutation in other component;
    // i.e assigning it to null elsewhere
    this.activeMandate = !!{ ...mandate } ? { ...mandate } : null;

    this.drawer.open();

    this.messagesService.hideAll();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();

    this.merchantListState.reset();
  }
}
