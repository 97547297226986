import { Injectable } from '@angular/core';
import {
  CollectionQuery,
  CollectionInput,
  Collection,
} from './content.interface';
import { EnterpriseAPIService, ReqSuccessResponse, PaginatedList } from '../../../../../../../projects/api/src/public-api';

@Injectable({ providedIn: 'root' })
export class CollectionsService {
  constructor(private http: EnterpriseAPIService) { }

  retrieve(collectionId: string) {
    return this.http.get<ReqSuccessResponse<Collection>>(
      `/api/v1/collections/${collectionId}`
    );
  }

  find(query: Partial<CollectionQuery>) {
    return this.http.get<PaginatedList<Collection>>(
      `/api/v1/collections`,
      query
    );
  }

  create(collection: CollectionInput) {
    const { image, ...theRest } = collection;

    const postData = new FormData();
    postData.append('file', image, theRest?.title);

    const inputBlob = new Blob([JSON.stringify(theRest)], {
      type: 'application/json',
    });

    postData.append('input', inputBlob);

    return this.http.post<ReqSuccessResponse<Collection>>(
      `/api/v1/collections`,
      postData
    );
  }

  update(collection: CollectionInput) {

    const { image, ...theRest } = collection;

    const postData = new FormData();

    if (image) {
      postData.append('file', image, theRest?.title);
    }

    const inputBlob = new Blob([JSON.stringify(theRest)], {
      type: 'application/json',
    });

    postData.append('input', inputBlob);

    return this.http.post<ReqSuccessResponse<Collection>>(
      `/api/v1/collections`,
      postData
    );
  }

  delete(collectionId: string) {
    return this.http.delete<ReqSuccessResponse<boolean>>(
      `/api/v1/collections/${collectionId}`
    );
  }
}
