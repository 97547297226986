import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConsoleRouterService } from '../../../../../../shared/src/public-api';
import { IMandate } from '../../interface/IMandate';
import {
  IMandateDetailsState,
  MandateDetailsStateService,
} from '../../state/mandate-details-state.service';

@Component({
  templateUrl: './mandate-details.component.html',
  styleUrls: ['./mandate-details.component.scss'],
})
export class MandateDetailsComponent implements OnInit, OnDestroy {
  pageTitle = 'View Mandate';

  mandate: IMandate;

  loading = false;

  errorMessage = '';

  mandateSub$: Subscription;

  currentClicked: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private consoleRouter: ConsoleRouterService,
    private mandateDetailsState: MandateDetailsStateService
  ) {}

  ngOnInit(): void {
    this.retrieveMandate();
  }

  retrieveMandate(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;

      this.mandateDetailsState.findById(id);

      this.listenOnState();
    });
  }

  private listenOnState(): void {
    this.mandateSub$ = this.mandateDetailsState.state$.subscribe(
      (state: IMandateDetailsState) => {
        this.mandate = state.mandate;
        this.loading = state.loading;
        this.errorMessage = state.error;
      }
    );
  }

  back(): void {
    this.consoleRouter.goBack();
  }

  gotUserProfile(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  deleteMandate(mandateId: string): void {
    const check = confirm('Do you want to delete this mandate?');

    if (check) {
      this.currentClicked = 'DELETE';
      this.mandateDetailsState.delete(mandateId);
    }
  }

  ngOnDestroy(): void {
    this.mandateSub$ && this.mandateSub$.unsubscribe();

    this.mandateDetailsState.reset();
  }
}
