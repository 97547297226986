import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { AreaChartComponent } from './area-chart/area-chart.component';

@NgModule({
  imports: [
    NgxEchartsModule.forRoot({
      echarts: import('echarts'),
    }),
    CommonModule,
  ],
  exports: [
    PieChartComponent,
    BarChartComponent,
    AreaChartComponent],
  declarations: [
    PieChartComponent,
    BarChartComponent,
    AreaChartComponent
  ],
  providers: [],
})
export class AnalyticComponentModule { }
