import { Component, Input, OnInit } from '@angular/core';
import { WALLET_PROVIDERS } from '@console/shared/_enums';
import { finalize } from 'rxjs/operators';
import { formatToCurrency } from '../../../../../../../shared/src/lib/utils/formatter';
import { Wallet } from '../../providers/wallet.interface';
import { WalletListStateService } from '../../states/wallets.state';

@Component({
  selector: 'kt-wallet-card',
  templateUrl: './wallet-card.component.html'
})
export class WalletCardComponent implements OnInit {
  @Input() data: Wallet;
  @Input() noPadding: boolean;
  nubanCheck = false;
  fetchingWallets = false;
  virtualAccountList = [];

  formatToCurrency = formatToCurrency;

  constructor(private walletState: WalletListStateService) {}

  ngOnInit(): void {
    this.data.VirtualAccount && this.fetchVirtualAccounts();
  }

  private fetchVirtualAccounts() {
    this.fetchingWallets = true;
    this.walletState
      .fetchVirtualAccounts(this.data.id)
      .pipe(finalize(() => (this.fetchingWallets = false)))
      .subscribe((response) => {
        this.virtualAccountList = response.data;

        console.log(this.virtualAccountList.length);
      });
  }

  clearPendingCash(): void {
    const { userId, provider } = this.data;

    const check = confirm(
      `Do want to clear the pending cash on the ${
        provider === WALLET_PROVIDERS.GLOBAL ? 'global' : 'local'
      } wallet`
    );

    if (!check) {
      return;
    }

    this.walletState.clearPendingCash(userId, provider as WALLET_PROVIDERS);
  }
}
