import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ClientUserModel } from '../../models/user.model';

@Component({
  selector: 'kt-settings-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent {
  @Input() users: ClientUserModel[] = [];
  @Output() selected = new EventEmitter<ClientUserModel>();

  constructor() { }

}
