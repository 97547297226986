import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { LiteInstrument } from '../../models/instrument.interface';

@Component({
  selector: 'app-similar-instruments',
  templateUrl: './similar-instruments.component.html',
  styleUrls: ['./similar-instruments.component.scss']
})
export class SimilarInstrumentsComponent implements OnInit {

  @Input() instruments: LiteInstrument[] = [];

  @Output() navigate = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

}
