import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../../../projects/api/src/public-api';
import { BlogContent, BlogContentDetails } from './blog.model';

const API_BLOG = 'api/v1/blogs';

@Injectable()
export class BlogService {
  constructor(private http: EnterpriseAPIService) {}

  getBlogContent(searchParams?) {
    return this.http.get<PaginatedList<BlogContent>>(API_BLOG, searchParams);
  }

  getBlogContentById(id: number): Observable<ReqSuccessResponse<BlogContent>> {
    const url = API_BLOG + `/${id}`;
    return this.http.get<ReqSuccessResponse<BlogContent>>(url);
  }

  updateBlogContent(
    content: BlogContent
  ): Observable<ReqSuccessResponse<BlogContent>> {
    return this.http.put<ReqSuccessResponse<BlogContent>>(API_BLOG, content);
  }

  createBlogContent(
    content: BlogContentDetails
  ): Observable<ReqSuccessResponse<BlogContent>> {
    return this.http.post<ReqSuccessResponse<BlogContent>>(API_BLOG, content);
  }

  deleteBlogContent(id: number): Observable<ReqSuccessResponse<BlogContent>> {
    const url = API_BLOG + `/${id}`;
    return this.http.delete<ReqSuccessResponse<BlogContent>>(url);
  }
}
