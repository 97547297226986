/**
 * UserService
 *  This service manages the user details like data retrieval
 * and administrative operations on the user. It should be noted that
 * this service does not update user KYC, except on disable
 * which can be batched in cases.
 *
 * Oyegoke Abiodun 2/9/2020
 */

import { Injectable } from '@angular/core';
import {
  BaseApiService,
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse
} from '@console/api';
import { AuthCredential, EnterpriseLogin } from '@console/authentication/authentication.interface';
import { ILoginData } from '@console/authentication/login/login.state';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { Observable } from 'rxjs';
import { ClientUserModel } from '../models/user.model';
import { DisableUserRequest } from './interface';

const API_CLIENT_USERS = '/api/v1/users';

@Injectable()
export class UserService {

  constructor(
    private http: EnterpriseAPIService,
    private base: BaseApiService,
    private loadingService: LoadingService
  ) {}

  /**
   * Returns a paginated list of users belonging to
   * a product.
   */
  getClientUsers(searchParams?) {
    if (searchParams) {
      searchParams.productType = this.base.getProductType();
    } else {
      searchParams = {};
      searchParams.productType = this.base.getProductType();
    }
    const clientUsers$ = this.http.get<PaginatedList<ClientUserModel>>(
      `/api/v1/users/product`,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(clientUsers$);
  }

  /**
   * returns the Kyc profile for a user
   * @param id
   */
  getClientUserProfile(id: string): Observable<ReqSuccessResponse<ClientUserModel>> {
    const url = `${API_CLIENT_USERS}/profile/${id}`;
    const user$ = this.http.get<ReqSuccessResponse<ClientUserModel>>(url);

    return this.loadingService.showLoaderUntilCompleted(user$);
  }

  /**
   * Validate admin login
   */
  validateLogin(payload: ILoginData): Observable<ReqSuccessResponse<boolean>> {
    const url = `${API_CLIENT_USERS}/validate-credential`;

    return this.http.login<ReqSuccessResponse<boolean>>(url, payload);
  }

  /**
  * Fetch OTP for user
  */
  sendAdminOtp(loginData: { clientId, email }): Observable<ReqSuccessResponse<{ code: string; message: string; success: boolean; }>> {
    const url = `${API_CLIENT_USERS}/send-admin-otp`;

    return this.http.login<ReqSuccessResponse<{
      code: string
      message: string,
      success: boolean
    }>>(url, {}, loginData);
  }

  /**
   * login a user
   */
  login(credential: AuthCredential): Observable<ReqSuccessResponse<EnterpriseLogin>> {
    const url = `${API_CLIENT_USERS}/admin/login`;
    return this.http.login<ReqSuccessResponse<EnterpriseLogin>>(url, credential);
  }

  /**
   * disable a user details on their KYC.
   */
  disableUser(body: DisableUserRequest): Observable<ReqSuccessResponse<ClientUserModel>> {
    const url = `${API_CLIENT_USERS}/disable`;
    return this.http.post<ReqSuccessResponse<ClientUserModel>>(url, body);
  }

  /**
   * deletes the user from the server totally.
   */
  deleteClientUser(id: string): any {
    const url = API_CLIENT_USERS + `/${id}`;
    return this.http.delete<ReqSuccessResponse<ClientUserModel>>(url);
  }
}
