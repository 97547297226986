import { TopBarMobileComponent } from './top-bar-mobile/top-bar-mobile.component';
import { Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { ConsoleRouterService } from '@console/shared';
import { AuthLoginStateService } from '../../../projects/authentication/src/lib/login/login.state';
import { AuthProfileStateService } from '../../../projects/authentication/src/lib/profile-state';
import { DashboardService } from './dashboard.service';
import { QuickUserComponent } from './quick-user/quick-user.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(QuickUserComponent) quickUserComponent: QuickUserComponent;
  
  @ViewChild(TopBarComponent) topBarComponent: TopBarComponent;

  @ViewChild(TopBarMobileComponent) topBarMobileComponent: TopBarMobileComponent;
  @ViewChild(SideBarComponent) sideBarComponent: SideBarComponent;

  destroyed$: Subject<boolean> = new Subject();

  constructor(
    public consoleRouter: ConsoleRouterService,
    public auth: AuthLoginStateService,
    public authProfile: AuthProfileStateService,
    private dashboardService: DashboardService,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.quickUserComponent.userLogoutDiv.nativeElement.contains(e.target) &&
        !this.topBarComponent.logoutToggleDiv.nativeElement.contains(e.target)
      ) {
        this.dashboardService.hideLogoutDiv();
      }

      if (
        !this.topBarMobileComponent.sidebarToggle.nativeElement.contains(e.target) &&
        !this.sideBarComponent.sideBar.nativeElement.contains(e.target)
      ) {
        this.dashboardService.hideMobileSidebar();
      }

      return;
    });
  }

  ngOnInit(): void {
    this.dashboardService.subHeader$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (event: boolean) => {
          event ? this.showSubHeader() : this.hideSubHeader();
        }
      );
  }

  showSubHeader(): void {
    const isShown = (document.body.classList.contains('subheader-enabled') &&
      document.body.classList.contains('subheader-fixed'));

    if (!isShown) {
      this.renderer.addClass(document.body, 'subheader-enabled');
      this.renderer.addClass(document.body, 'subheader-fixed');
    }
  }

  hideSubHeader(): void {
    const isShown = (document.body.classList.contains('subheader-enabled') &&
      document.body.classList.contains('subheader-fixed'));

    if (isShown) {
      this.renderer.removeClass(document.body, 'subheader-enabled');
      this.renderer.removeClass(document.body, 'subheader-fixed');
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
