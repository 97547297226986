import {
  IAdvisoryPortfolioDetailState,
  AdvisoryPortfolioDetailStateService,
} from '../../state/advisory-portfolio-details-state.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Subscription } from 'rxjs';
import { ConsoleRouterService } from '../../../../../../shared/src/public-api';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { Currency } from '@console/shared/_enums/currency.enum';
import { MatDrawer } from '@angular/material/sidenav';
import {
  IAdvisoryPortfolio,
  IAdvisoryPortfolioHolding,
} from '../../interfaces/IAdvisoryPortfolio';

@Component({
  selector: 'lib-advisory-portfolio-details',
  templateUrl: './advisory-portfolio-details.component.html',
  styleUrls: ['./advisory-portfolio-details.component.scss'],
})
export class AdvisoryPortfolioDetailsComponent implements OnInit, OnDestroy {
  pageTitle = 'View Portfolio Breakdown';

  advisoryPortfolio: IAdvisoryPortfolio;

  loading = false;

  portfolioSub$: Subscription;

  errorMessage: string;

  totals: {
    target: number;
    actual: number;
    value: number;
  };

  formatToCurrency = formatToCurrency;

  showPercent = false;

  activePortfolioHolding: IAdvisoryPortfolioHolding;

  @Input() userId;
  @Input() tradeCurrency = Currency.USD;

  activePortfolioId: string;

  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private activatedRoute: ActivatedRoute,
    private consoleRouter: ConsoleRouterService,
    private advisoryPortfolioDetailState: AdvisoryPortfolioDetailStateService,
    private messagesService: MessagesService
  ) {}

  ngOnInit(): void {
    if (this.userId && this.tradeCurrency) {
      this.retrieveUserPortfolio();
      return;
    }

    this.retrieveFromParams();
  }

  retrieveUserPortfolio() {
    this.advisoryPortfolioDetailState.findUsersPortfolio(
      this.userId,
      this.tradeCurrency
    );

    this.listenOnState();
  }

  retrieveFromParams(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;

      this.advisoryPortfolioDetailState.findById(id);

      this.listenOnState();
    });
  }

  private listenOnState(): void {
    this.portfolioSub$ = this.advisoryPortfolioDetailState.state$.subscribe(
      (state: IAdvisoryPortfolioDetailState) => {
        this.advisoryPortfolio = state.portfolio;
        this.loading = state.loading;
        this.errorMessage = state.error;

        if (this.advisoryPortfolio?.holdings.length > 0) {
          this.calculateTotals(this.advisoryPortfolio.holdings);
        }
      }
    );
  }

  calculateTotals(holdings: IAdvisoryPortfolioHolding[]) {
    const totalHolding = [...holdings].reduce((previousVal, currentVal) => {
      return {
        ...previousVal,
        target: previousVal.target + currentVal.target,
        actual: previousVal.actual + currentVal.actual,
        value: previousVal.value + currentVal.value,
      };
    });

    const { target, actual, value } = totalHolding;

    const precision = 4;

    this.totals = {
      target: Number(target.toFixed(precision)),
      actual: Number(actual.toFixed(precision)),
      value: Number(value.toFixed(precision)),
    };

    console.log('holding', this.totals);
  }

  get portfolioHoldings(): IAdvisoryPortfolioHolding[] {
    return this.advisoryPortfolio?.holdings || [];
  }

  back(): void {
    this.consoleRouter.goBack();
  }

  switchFormat(): void {
    this.showPercent = !this.showPercent;
  }

  gotUserProfile(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  openDrawer(
    activePortfolioId: string,
    portfolioHolding: IAdvisoryPortfolioHolding
  ): void {
    this.activePortfolioId = activePortfolioId;

    // we only want to send a copy because objects are accessed by reference
    this.activePortfolioHolding = !!{ ...portfolioHolding }
      ? { ...portfolioHolding }
      : null;

    this.drawer.open();
  }

  ngOnDestroy(): void {
    this.portfolioSub$ && this.portfolioSub$.unsubscribe();

    this.advisoryPortfolioDetailState.reset();

    this.messagesService.hideAll();
  }
}
