import { MessagesEnum } from './../../../../../../shared/src/lib/components/messages/enums/messages.enums';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MerchantsModel } from '../../merchants.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { MessagesService } from '@console/shared/components/messages/messages.service';

@Component({
  selector: 'kt-merchant-editor',
  templateUrl: './merchant-editor.component.html',
  styleUrls: ['./merchant-editor.component.scss'],
})
export class MerchantEditorComponent implements OnInit, OnDestroy {
  @Input() merchant: MerchantsModel;
  @Input() disabled = false;

  merchantForm: FormGroup;
  formMethods;

  providerList = [
    {
      description: '--Select--',
      value: '',
    },
    {
      description: 'PAYSTACK',
      value: 'PAYSTACK',
    },
    {
      description: 'INTERSWITCH',
      value: 'INTERSWITCH',
    },
    {
      description: 'FLUTTER WAVE',
      value: 'FLUTTER_WAVE',
    },
  ];

  @Output() save = new EventEmitter<MerchantsModel>();
  @Output() delete = new EventEmitter<number>();
  @Output() cancel = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private messagesService: MessagesService
  ) {}

  ngOnDestroy(): void {
    this.messagesService.hideAll();
  }

  /**
   * emits an updated merchant with model as payload
   */
  updateMerchant() {
    if (!this.merchantForm.valid) {
      this.merchantForm.markAllAsTouched();
      return;
    }

    const { value } = this.merchantForm;

    const deleteIfEmpty = [
      'contactEmail',
      'contactPhone',
      'telegram',
      'whatsAppNumber',
      'linkToFaq',
      'defaultVirtualAccProvider',
    ];

    deleteIfEmpty.forEach((item) => {
      if (Boolean(value[item]) === false || value[item] === '') {
        delete value[item];
      }
    });

    this.save.emit(value);
  }

  createForm(merchant: Partial<MerchantsModel> = {}): FormGroup {
    return this.fb.group({
      name: [merchant?.name || '', Validators.required],
      code: [merchant?.code || '', Validators.required],
      logoUrl: [merchant?.logoUrl || '', Validators.required],
      contactEmail: [merchant?.contactEmail || '', [Validators.email]],
      contactPhone: [
        merchant?.contactPhone || '',
        [Validators.pattern('^(\\+){0,1}[0-9]{8,20}$')],
      ],
      telegram: [merchant?.telegram || ''],
      whatsAppNumber: [merchant?.whatsAppNumber || ''],
      linkToFaq: [merchant?.linkToFaq || ''],
      defaultVirtualAccProvider: [merchant?.defaultVirtualAccProvider || ''],
    });
  }

  /**
   * Returns component title
   */
  get title(): string {
    if (this.isUpdateMode()) {
      return `Edit Merchant - ${this.merchant.name}`;
    }

    return 'New Merchant';
  }

  private isUpdateMode() {
    return Boolean(this.merchant && this.merchant.id);
  }

  ngOnInit() {
    this.merchantForm = this.createForm(this.merchant);

    this.formMethods = formMethods(this.merchantForm);
  }
}
