import { Component, OnInit } from '@angular/core';
import { ConsoleRouterService } from '@console/shared';
import { PageEvent } from '@console/shared/interface';
import { BankListStateService } from './bank-list-state.service';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.scss']
})
export class BanksComponent implements OnInit {
  private filter: Record<string, any> = {};

  childRoute = false;

  constructor(
    public bank: BankListStateService,
    private console: ConsoleRouterService
  ) { }

  search(query: string) {
    this.filter = {
      pageSize: this.filter.pageSize
    };
    this.filter.q = query;
    this.bank.find(this.filter);
    if (this.childRoute) {
      this.console.productNavigate(['merchants', 'view']);
    }
  }

  paginate($event: PageEvent) {
    this.bank.find({
      ...this.filter,
      ...$event,
    });
  }

  parentNavigate() {
    this.childRoute = false;
    this.bank.find(this.filter);
  }

  navigate(channelId?: string) {
    if (channelId) {
      this.console.productNavigate(['bank', channelId]);
      return;
    }
    this.console.productNavigate(['bank', 'create']);
  }

  handleQuery(key, value, isDate = false) {
    if (key === 'q') {
      this.filter = {
        pageSize: this.filter.pageSize
      };;
    }

    if (isDate) {
      value = new Date(value).toISOString();
    }

    this.filter[key] = value;
    this.bank.find(this.filter);
  }

  ngOnInit() {
    this.bank.find({})
  }
}
