import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-role-attachment',
  templateUrl: './role-attachment.component.html',
  styleUrls: ['./role-attachment.component.scss']
})
export class RoleAttachmentComponent implements OnInit {

  @Input() roleId: string;

  @Output() attach = new EventEmitter();

  @Input() loading = false;

  roleUpdateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  createForm() {
    return this.fb.group({
      roleId: [ this.roleId, Validators.required ],
      userId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.roleUpdateForm = this.createForm();

  }

}
