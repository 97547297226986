<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <div class="card-header bg-light py-3">Pre-Order</div>

    <div class="container container-fluid mt-4">
      <div class="kt-form">
        <div *ngIf="error" class="row">
          <div class="col-12">
            <p>{{ error }}</p>
            <button
              (click)="action.emit('CANCEL')"
              class="btn btn-danger btn-block"
            >
              Cancel
            </button>
          </div>
        </div>

        <div *ngIf="preOrder" class="row">
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Symbol</h6>
            <h5>{{ preOrder.symbol || "-" }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>OrderSide</h6>
            <!-- <h5>{{ preOrder.side || '-' }}</h5> -->
            <h5>{{ side || "-" }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Quantity</h6>
            <h5>{{ quantity }}</h5>
          </div>
          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>OrderType</h6>
            <h5>{{ preOrder.type || "-" }}</h5>
          </div>

          <div
            class="col-sm-6 kt-margin-bottom-20"
            *ngIf="!isLocalBuyAndMarketOrder"
          >
            <h6>Cost</h6>
            <h5>{{ cost }}</h5>
          </div>

          <div
            class="col-sm-6 kt-margin-bottom-20"
            *ngIf="isLocalBuyAndMarketOrder"
          >
            <h6>Estimated Cost</h6>
            <h5>{{ estimatedPrice }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Fees</h6>
            <h5>{{ fee }}</h5>
          </div>

          <div class="col-sm-6 kt-margin-bottom-20">
            <h6>Total Amount</h6>
            <h5>
              {{ estimatedTotal }}
            </h5>
          </div>

          <button
            (click)="action.emit('ORDER')"
            class="btn btn-primary btn-block"
          >
            Continue
          </button>

          <button
            (click)="action.emit('CANCEL')"
            class="btn btn-danger btn-block"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
