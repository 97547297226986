import { AutoRebalanceService } from '../services/auto-rebalance.service';
import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from '../../../../../api/src/public-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IAutoRebalance } from '../interface/IAutoRebalance';
import { MessagesService } from '../../../../../shared/src/lib/components/messages/messages.service';
import { MessagesEnum } from '../../../../../shared/src/lib/components/messages/enums/messages.enums';

export interface IAutoRebalanceDetailsState {
  loading: boolean;
  autoRebalance: IAutoRebalance;
  error?: string;
}

const initialState: IAutoRebalanceDetailsState = {
  loading: false,
  autoRebalance: null,
};

@Injectable()
export class AutoRebalanceDetailsStateService {
  private subject$ = new BehaviorSubject<IAutoRebalanceDetailsState>(initialState);

  state$ = this.subject$.asObservable();

  private messageId: number;

  constructor(
    private autoRebalanceService: AutoRebalanceService,
    private messagesService: MessagesService
  ) { }


  get loading$(): Observable<boolean> {
    return this.subject$.pipe(map((state) => state.loading));
  }

  findById(autoRebalanceId: string): void {
    this.loading();

    this.showMessage('Fetching auto-rebalance...');

    this.autoRebalanceService
      .fetchAutoRebalanceById(autoRebalanceId)
      .pipe(
        take(1),
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        )
      )
      .subscribe({
        next: this.onSuccess.bind(this)
      });
  }

  requery(autoRebalanceId: string): void {
    this.loading();

    this.showMessage('Querying auto-rebalance...');

    this.autoRebalanceService
      .requeryAutoRebalanceById(autoRebalanceId)
      .pipe(
        take(1),
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        )
      )
      .subscribe({
        next: this.onSuccess.bind(this)
      });
  }

  private onSuccess(data: ReqSuccessResponse<IAutoRebalance>): void {
    this.subject$.next({
      loading: false,
      autoRebalance: data.data
    });

    this.messagesService.update(
      {
        id: this.messageId,
        type: MessagesEnum.success,
        message: 'Successfully retrieved auto-rebalance.'
      },
      {
        timeOut: 3000
      }
    );
  }

  private handleError(res: ChakaAPIError): void {
    const errorMessage = cleanChakaAPIError(res) || 'An Error occurred';

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false
    });

    this.messagesService.update({
      id: this.messageId,
      type: MessagesEnum.danger,
      message: errorMessage
    });
  }

  reset(): void {
    this.subject$.next(initialState);
  }

  private showMessage(message: string): void {
    !!this.messageId && this.messagesService.hide(this.messageId);
    const { id } = this.messagesService.open(MessagesEnum.loading, message);
    this.messageId = id;
  }

  private loading(): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true
    });
  }
}
