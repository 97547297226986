import { VirtualAccountService } from './providers/virtual-account.service';
import { NgModule } from '@angular/core';
import { ApiModule } from '../../../../../api/src/lib/api.module';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaymentService } from './providers/payment-service';
import { WalletService } from './providers/wallet.service';
import { ExchangeRateStateService } from './states/exchange-rates.state';
import { PayoutAccountStateService } from './states/payout-account.state';
import { WalletListStateService } from './states/wallets.state';
import { WalletExchangeStateService } from './states/wallet-exchange.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAccountsExchangeComponent } from './components/exchange/exchange.component';
import { WalletCardComponent } from './components/wallet-card/wallet-card.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { WalletWithdrawalStateService } from './states/withdrawal.state';
import { WithdrawalComponent } from './components/withdraw/withdraw.component';
import { NipPaymentStateService } from './states/nip.state';
import { NipFundComponent } from './components/fund/fund.component';
import { AccountComponent } from './accounts-list.component';
import { UserAccountsRequeryComponent } from './components/requery/exchange.component';
import { RequeryPaymentStateService } from './states/confirm-payment.state';
import { AuthProvidersModule } from '@console/authentication/auth-providers.module';
import { FundReversalComponent } from './components/fund-reversal/fund-reversal.component';
import { SharedModule } from '@console/shared';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
    ApiModule,
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    AuthProvidersModule,
    SharedModule,
    MatCardModule,
    MatExpansionModule,
  ],
  exports: [
    UserAccountsExchangeComponent,
    WalletCardComponent,
    WalletComponent,
    WithdrawalComponent,
    NipFundComponent,
    AccountComponent,
    UserAccountsRequeryComponent,
    FundReversalComponent,
  ],
  declarations: [
    UserAccountsExchangeComponent,
    WalletCardComponent,
    WalletComponent,
    WithdrawalComponent,
    NipFundComponent,
    AccountComponent,
    UserAccountsRequeryComponent,
    FundReversalComponent,
  ],
  providers: [
    WalletService,
    PaymentService,
    ExchangeRateStateService,
    PaymentService,
    PayoutAccountStateService,
    WalletExchangeStateService,
    WalletListStateService,
    WalletWithdrawalStateService,
    NipPaymentStateService,
    RequeryPaymentStateService,
    VirtualAccountService,
  ],
})
export class AccountsModule {}
