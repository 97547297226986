import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApiModule } from '../../../../api/src/lib/api.module';
import { OrderListStateService } from './orders-list/orders-state';
import { OrdersComponent } from './orders-list/orders.component';
import { OrdersTableModule } from './orders-table/orders-table.module';
import { OrderTxnService } from './services/orders.service';
import { OrdersEditComponent } from './orders-edit/orders-edit.component';

@NgModule({
  imports: [
    CommonModule,
    ApiModule,
    NgxSmartModalModule,
    NgxSpinnerModule,
    OrdersTableModule,
  ],
  exports: [OrdersComponent, OrdersEditComponent],
  declarations: [OrdersComponent, OrdersEditComponent],
  providers: [OrderListStateService, OrderTxnService],
})
export class OrderTxnModule {}
