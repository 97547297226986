<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Audit Logs</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">View audit log</span>
        </h3>

      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <div class="row align-items-center mb-5">
          <div class="col-lg-8">
            <form [formGroup]="searchForm">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>From</label>
                    <input class="form-control" type="date" formControlName="from">
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>To</label>
                    <input class="form-control" type="date" formControlName="to">
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label class="d-block">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-light-primary font-weight-bold"
                      (click)="getAuditLogs()">Search</button>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label class="d-block">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-link font-weight-bold"
                      (click)="clearFilters();">Clear All</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>

        <lib-messages></lib-messages>

        <!--begin::Table-->
        <div class="row">
          <div class="col-12">
            <div
              class="table-responsive"
              [hidden]="(dataSource.data && dataSource.data?.length <= 0)">

              <div class="table-container">
                <table
                  class="table table-head-custom table-head-bg table-vertical-center table-borderless"
                  mat-table [dataSource]="dataSource">

                  <ng-container matColumnDef="userFullName">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">User Name</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">
                        <a
                          *ngIf="(row?.userId) else noUser"
                          href="javascript:void(0)"
                          (click)="viewUser(row?.userId)">
                          {{row?.userFullName || "-"}}</a>

                        <ng-template #noUser>{{row?.userFullName || "-"}}</ng-template>

                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Client Name</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.clientName}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="clientIpAddress">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">ClientIp Address</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.clientIpAddress}}</span>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="serviceName">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Service</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{row?.serviceName}}</span>
                    </td>
                  </ng-container>




                  <ng-container matColumnDef="merchantId">
                    <th mat-header-cell *matHeaderCellDef>
                      <span class="text-dark-75">Merchant</span>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span class="text-dark-75 d-block font-size-lg">{{
                        resolveMerchantName(row?.merchantId)  }}</span>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                      <span class="text-dark-75">Action</span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      <span style="overflow: visible; position: relative; width: 125px;">
                        <a href="javascript:;"
                          (click)="viewLog(row?.id)"
                          class="btn btn-sm btn-clean btn-icon mr-2">
                          <i class="far fa-eye"></i>
                        </a>
                      </span>
                    </td>
                  </ng-container>



                  <thead>
                    <tr class="bg-gray-100 text-left" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  </thead>

                  <tbody>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                  </tbody>
                </table>
              </div>

              <mat-paginator
                class="mat-paginator-sticky"
                [length]="count"
                [pageSizeOptions]="[10, 50, 100, 150, 200]"
                (page)="pageEvent=$event; paginate($event)"
                showFirstLastButtons></mat-paginator>


            </div>

            <div *ngIf="dataSource.data?.length <= 0 && !loading" class="no-data-container d-flex flex-column justify-content-center align-items-center">
              <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
              <p class="no-data-text">No data</p>
            </div>
          </div>

        </div>

        <!--end::Table-->
      </div>
      <!--end::Body-->
    </div>

  </div>
  <!--end::Container-->
</div>


<!-- drawer -->
<mat-drawer #drawer mode="over">
  <log-drawer
    [drawer]="drawer"
    [merchantList]="merchantList"
    [activeLogId]="activeLogId"></log-drawer>
</mat-drawer>
