<h2 mat-dialog-title>{{ title }}</h2>
<form [formGroup]="rejectionReasonForm" (ngSubmit)="save()">

  <mat-dialog-content>
    <div class="form-group mb-8">
      <lib-messages></lib-messages>
    </div>

    <div class="form-group">
      <label>Status <span class="text-danger">*</span></label>
      <!-- <input type="text" class="form-control" formControlName="status" /> -->

      <ng-select formControlName="status">
        <ng-option [value]="status" *ngFor="let status of statusList">{{ status }}</ng-option>
      </ng-select>
      <!-- <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('referrerAmount')">
        <div *ngIf="formMethods.getControl('referrerAmount').hasError('required')">
          Required input.
        </div>
        <div *ngIf="formMethods.getControl('referrerAmount').hasError('pattern')">Value must be greater than zero</div>
      </small> -->
    </div>

    <div class="form-group">
      <label>Template ID <span class="text-danger">*</span></label>
      <input type="text" class="form-control" formControlName="sendgridTemplateId" />
      <!-- <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('refereeAmount')">
        <div *ngIf="formMethods.getControl('refereeAmount').hasError('required')">
          Required input.
        </div>
        <div *ngIf="formMethods.getControl('refereeAmount').hasError('pattern')">Value must be greater than zero</div>
      </small> -->
    </div>

    <div class="form-group">
      <label>Reason for Rejection <span class="text-danger">*</span></label>
      <input type="text" class="form-control" formControlName="reasonForRejection" />
      <!-- <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('referrerAmount')">
        <div *ngIf="formMethods.getControl('referrerAmount').hasError('required')">
          Required input.
        </div>
        <div *ngIf="formMethods.getControl('referrerAmount').hasError('pattern')">Value must be greater than zero</div>
      </small> -->
    </div>

  </mat-dialog-content>

  <mat-dialog-actions mat-dialog-actions class="d-flex align-items-center justify-content-between">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button type="submit" class="btn btn-primary">Save</button>
  </mat-dialog-actions>
</form>