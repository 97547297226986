<div class="card-deck">
  <div *ngIf="post" class="card">
    <img [src]="post.imageUrl" class="card-img-top" alt="..." style="height:200px !important;">
    <div class="card-body">
      <h5 (click)="btnClick.emit(post)" class="card-title">{{ post.title.slice(0, 29) }}..</h5>

			<p class="card-text">{{ post.summary.slice(0, 38) }}..</p>
			<a (click)="btnClick.emit(post)" class="btn btn-primary text-light">Edit post</a>
    </div>
  </div>
</div>