import { formMethods } from '../../../../../shared/src/lib/utils/form-control-methods';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthSignUpStateService } from './register-state';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
  registerForm!: FormGroup;
  public formMethods;
  prefix = '234';

  constructor(
    private register: AuthSignUpStateService
  ) { }


  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.registerForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(7)
      ]),
      isAdmin: new FormControl(false),
      mobileNumber: new FormControl('', [
        Validators.pattern('^(\\+){0,1}[0-9]{8,20}$')
      ])
    });

    this.formMethods = formMethods(this.registerForm);
  }

  private get mobileNumberControl(): AbstractControl {
    return this.registerForm.get('mobileNumber');
  }

  onIsAdmin(isAdmin: boolean): void {
    const removeRequired = (): void => {
      this.mobileNumberControl.setValue('');
      this.mobileNumberControl.removeValidators(Validators.required);
    };

    isAdmin ?
      this.mobileNumberControl.addValidators(Validators.required) :
      removeRequired();

    this.mobileNumberControl.updateValueAndValidity();

  }

  private get isAdminControl(): AbstractControl {
    return this.registerForm.get('isAdmin');
  }

  signUp(): void {
    if (!this.registerForm.valid) {
      this.registerForm.markAllAsTouched();
      return;
    }

    const { mobileNumber, ...theRest } = this.registerForm.value;

    const dataToSubmit = this.isAdminControl.value ?
      { ...theRest, mobileNumber: this.prefix + mobileNumber } :
      { ...theRest };

    this.register.signUp(dataToSubmit);
  }
}
