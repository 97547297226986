import { Injectable } from '@angular/core';
import { RedemptionStatusEnum } from '@console/shared/_enums/RedemptionStatus.enum';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { LoadingService } from '../../../../../shared/src/lib/components/loading/loading.service';

import { ICscsReport } from '../interface/ICscsReport';

const CSCS_REPORT_URL = '/api/v1/cscs/find_by_status';

@Injectable()
export class CscsReportService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  findByStatus(searchParams?): Observable<PaginatedList<ICscsReport>> {
    const cscsReport$ = this.http.get<PaginatedList<ICscsReport>>(
      CSCS_REPORT_URL,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(cscsReport$);
  }
}
