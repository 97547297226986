import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { CrudState } from '@console/shared';
import { NotificationService } from '@console/shared/services/notification-service';
import { CscsModel } from '@console/user-managements/models/user.model';
import { UserCSCSService } from '@console/user-managements/services/cscs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CSCSTokenUpload, IBVNDetails } from '../../../services/interface';
import { ICscsLogin } from './../../../models/user.model';

export interface CSCSState {
  cscs?: CscsModel;
  loading: boolean;
  error?: string;
  status: CrudState;
  message?: string;
}

const initalState: CSCSState = {
  loading: false,
  status: CrudState.Initial,
};

@Injectable({ providedIn: 'root' })
export class CSCSStateService {
  state = new BehaviorSubject<CSCSState>(initalState);

  spinnerName = 'CSCS-Spinner';

  constructor(
    private svc: UserCSCSService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService
  ) {}

  tokenUpload(form: CSCSTokenUpload & ICscsLogin) {
    this.loading(CrudState.Create);
    this.svc
      .uploadCscsDetails(form)
      .pipe(map(() => ({ data: {} })))
      .subscribe({
        next: this.onCSCSLoaded.bind(this)(CrudState.Created),
        error: this.onCSCSError.bind(this),
      });
  }

  getBVNInfo(bvnNumber: string): Observable<ReqSuccessResponse<IBVNDetails>> {
    return this.svc.getBVN(bvnNumber);
  }

  verifyNinInfo(
    ninNumber: string,
    userId: string
  ): Observable<ReqSuccessResponse<string>> {
    return this.svc.verifyNin(ninNumber, userId);
  }

  retrieve(CSCSId: string) {
    this.loading(CrudState.Retrieve);
    this.svc.getCscsDetails(CSCSId).subscribe({
      next: this.onCSCSLoaded.bind(this)(CrudState.Retrieved),
      error: this.onCSCSError.bind(this),
    });
  }

  update(CSCS: Partial<CscsModel>) {
    // debugger;
    this.loading(CrudState.Update);

    // const action$ = (!CSCS.id) ? this.svc.createCscsDetails(CSCS) : this.svc.updateCscsDetails(CSCS);
    const action$ = this.svc.createCscsDetails(CSCS);
    // const action$ = this.svc.updateCscsDetails(CSCS);

    action$.subscribe({
      next: this.onCSCSLoaded.bind(this)(CrudState.Updated),
      error: this.onCSCSError.bind(this),
    });
  }

  updateInfowareCscs(CSCS: Partial<CscsModel>) {
    // debugger;
    this.loading(CrudState.Update);

    this.svc.updateInfowareCscsDetails(CSCS).subscribe({
      next: this.onCSCSLoaded.bind(this)(CrudState.Updated),
      error: this.onCSCSError.bind(this),
    });
  }

  resetState() {
    this.state.next(initalState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
      status: CrudState.Initial,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(status: CrudState) {
    this.state.next({
      ...this.state.getValue(),
      status,
      loading: true,
      // message: `Please wait, while we ${status.toLowerCase()} CSCS information`,
    });
    this.spinner.show(this.spinnerName);
  }

  private onCSCSLoaded(finalStatus: CrudState) {
    return ({ data }: ReqSuccessResponse<CscsModel>) => {
      this.state.next({
        cscs: data,
        loading: false,
        status: finalStatus,
      });

      this.spinner.hide(this.spinnerName);

      this.notificationService.success(
        `We have successfully ${finalStatus.toLowerCase()} CSCS information`
      );
    };
  }

  private onCSCSError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error,
      status: CrudState.Error,
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.error(error);
  }

  private hideSpinner() {
    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 3000);
  }
}
