import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { ConsoleRouterService } from '@console/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MerchantDetailStateService } from '../merchant-details.state';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarComponent implements OnInit, OnDestroy {
  @Input() productType: 'equity' | 'advisory' = 'equity';

  @Output() link = new EventEmitter<string[]>();
  activeRoute: string;

  destroyed$: Subject<boolean> = new Subject();

  @ViewChild('sideBar') sideBar: ElementRef;

  get isMinimized(): boolean {
    return document.body.classList.contains('aside-minimize');
  }

  constructor(
    public role: AuthRoleStateService,
    public merchant: MerchantDetailStateService,
    public consoleRouter: ConsoleRouterService,
    private renderer: Renderer2
  ) {}

  isDisabledIn(products: string[]): boolean {
    return !products.includes(this.productType);
  }

  showActiveClass(linkName: string): boolean {
    return !!linkName && this.activeRoute.includes(linkName);
  }

  ngOnInit(): void {
    this.merchant.merchant();

    this.listenOnConsoleRouter();
  }

  listenOnConsoleRouter(): void {
    this.consoleRouter.currentRoute$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentRoute: string) => {
        this.activeRoute = currentRoute;
      });
  }

  toggleSidebar(): void {
    this.isMinimized ? this.showDrawer() : this.hideDrawer();
  }

  private hideDrawer() {
    this.renderer.addClass(document.body, 'aside-minimize');
  }

  private showDrawer() {
    this.renderer.removeClass(document.body, 'aside-minimize');
  }

  onMouseEnter(): void {
    this.isMinimized && this.showWhileMouseIn();
  }

  private showWhileMouseIn() {
    this.renderer.addClass(document.body, 'aside-minimize-hover');
  }

  onMouseLeave(): void {
    this.isMinimized && this.hideWhileMouseOut();
  }

  private hideWhileMouseOut() {
    this.renderer.removeClass(document.body, 'aside-minimize-hover');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
