<div class="card card-custom mb-8 mb-lg-0 pointer" (click)="onView()">
  <div class="card-body">
    <div class="d-flex align-items-center">
      <div
        class="symbol symbol-circle symbol-lg-50 mr-5">
        <img [src]="merchant?.logoUrl" [alt]="merchant?.name +' logo'">
      </div>

      <div class="d-flex flex-column">
        <a
          href="javascript:;"
          class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3">
          {{merchant?.name}}</a>

        <div class="text-dark-75">
          <div class="d-flex flex-column">
            <div>
              <strong>Client ID:</strong>
              <p>{{merchant?.clientId}}</p>
            </div>

            <div>
              <strong>Code:</strong>
              <p>{{merchant?.code}}</p>
            </div>

            <div *ngIf="merchant?.createdOn">
              <strong>Date Created:</strong>
              <p>{{merchant?.createdOn}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
