import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WALLET_TYPES } from '@console/shared';
import { WalletListStateService } from '../../states/wallets.state';

@Component({
  selector: 'kt-user-accounts-wallet',
  templateUrl: './wallet.component.html',
  styles: [
    `
      .wallet-heading {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
      }
    `,
  ],
})
export class WalletComponent implements OnInit, OnDestroy {
  @Input() userId: string;

  constructor(public walletState: WalletListStateService) {}

  createLocalWallet(): void {
    this.walletState.createWallet(this.userId, [WALLET_TYPES.LOCAL]);
  }

  createGlobalWallet(): void {
    this.walletState.createWallet(this.userId, [WALLET_TYPES.GLOBAL]);
  }

  createAllWallets(): void {
    this.walletState.createWallet(this.userId, [
      WALLET_TYPES.LOCAL,
      WALLET_TYPES.GLOBAL,
    ]);
  }

  createVirtualAccount(): void {
    this.walletState.createVirtualAccount(this.userId);
  }

  ngOnInit() {
    this.walletState.retrieve(this.userId);
  }

  ngOnDestroy() {
    this.walletState.resetState();
  }
}
