import {
  ChangeDetectionStrategy, Component,
  OnDestroy, OnInit
} from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
import { InstrumentTagListStateService } from './tag-list.state';
import { PageEvent } from '../../../../shared/src/lib/interface';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent implements OnInit {

  childRoute = false;

  filter: Partial<any> = {
    pageSize: 12,
  };

  constructor(
    public tagList: InstrumentTagListStateService,
    private console: ConsoleRouterService
  ) { }

  search(query: string) {
    this.filter.q = query;
    this.tagList.find(this.filter);
    if (this.childRoute) {
      this.console.productNavigate(['content', 'collections']);
    }
  }

  paginate($event: PageEvent) {
    this.tagList.find({
      ...this.filter,
      ...$event
    });
  }

  parentNavigate() {
    this.childRoute = false;
    this.tagList.find(this.filter);
  }

  navigate(collectionId?: string) {
    if (collectionId) {
      this.console.productNavigate(['instruments', 'tags', 'edit', collectionId]);
      return;
    }
    this.console.productNavigate(['instruments', 'tags', 'edit']);
  }

  ngOnInit() {
    this.tagList.find(this.filter);
  }

  ngOnDestroy() {
    this.tagList.reset();
  }
}
