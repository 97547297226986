<!--begin::Card-->
<div class="card card-custom gutter-b">
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">DW History List</h3>
    </div>
  </div>

  <div class="card-body">
    <app-dw-history-table
      (query)="query($event)"
      (transaction)="viewTransaction($event)"
      [userId]="userId"></app-dw-history-table>
  </div>
</div>

<ngx-smart-modal
  #transactionModal
  identifier="dwHistoryModal"
  ariaLabel="DwHistoryModal">
  <app-dw-history-edit
    *ngIf="dwHistoryId"
    [transactionId]="dwHistoryId"
    [transaction]="dwTransaction"></app-dw-history-edit>
</ngx-smart-modal>
