import { Injectable } from '@angular/core';
import {
  APIResponse,
  EnterpriseAPIService,
  PaginatedList
} from '../../../../../api/src/public-api';
import { FeeSetting } from './settings.interface';

@Injectable()
export class FeeSettingService {
  constructor(private http: EnterpriseAPIService) {}

  find(searchParams?) {
    return this.http.get<PaginatedList<FeeSetting>>(
      `api/v1/fees`,
      searchParams
    );
  }

  retrieveById(id: number) {
    return this.http.get<APIResponse<FeeSetting>>(`api/v1/fees/${id}`);
  }

  updateFeeSetting(content: Partial<FeeSetting>) {
    return this.http.put<APIResponse<FeeSetting>>(`api/v1/fees`, content);
  }

  createFeeSetting(content: Partial<FeeSetting>) {
    return this.http.post<APIResponse<FeeSetting>>(`api/v1/fees`, content);
  }

  deleteFeeSetting(id: number) {
    return this.http.delete<APIResponse<FeeSetting>>(`api/v1/fees/${id}`);
  }
}
