import { ExchangeRateStateService } from './exchange-rates-edit-state';
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { map } from "rxjs/operators";
ExchangeRateStateService
@Component({
  selector: "kt-exchange-rates-edit",
  templateUrl: "./exchange-rates-edit.component.html",
  styleUrls: ["./exchange-rates-edit.component.scss"],
})
export class ExchangeRateEditComponent implements OnInit {
  pageTitle: string = "View Exchange";


  constructor(
    private activatedRoute: ActivatedRoute,
    public exchangeState: ExchangeRateStateService,
    private consoleRouter: ConsoleRouterService,
  ) { }

  get rate$() {
    return this.exchangeState.state.pipe(
      map((state => state.exchangerate))
    )
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params) => {
        const id = params["id"];
        if (id) {
          this.exchangeState.findById(id);
        } else {
          this.consoleRouter.productNavigate(["rates"]);
        }
      }
    );

  }




}
