import { ORDER_TYPE } from './../../../../../../../shared/src/lib/_enums/order-type.enum';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ORDER_SIDE } from '@console/shared';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { PreOrder } from 'projects/view/src/lib/orders/services/orders.model';
import { Currency } from '@console/shared/_enums/currency.enum';

@Component({
  selector: 'app-pre-order-display',
  templateUrl: './pre-order-display.component.html',
  styleUrls: ['./pre-order-display.component.scss'],
})
export class PreOrderDisplayComponent implements OnInit {
  @Input() preOrder: PreOrder;
  @Input() side: ORDER_SIDE;
  @Input() error: string;

  @Output() action = new EventEmitter();

  constructor() {}

  get cost() {
    if (!this.preOrder.currency || !this.preOrder.cost) return '-';

    return formatToCurrency(this.preOrder.currency, this.preOrder.cost);
  }

  get fee() {
    if (!this.preOrder.currency || !this.preOrder.fees) return '-';

    return formatToCurrency(this.preOrder.currency, this.preOrder.fees);
  }

  get estimatedTotal() {
    if (!this.preOrder.currency || !this.preOrder.estimatedTotal) return '-';

    return formatToCurrency(
      this.preOrder.currency,
      this.preOrder.estimatedTotal
    );
  }

  get quantity() {
    if (!this.preOrder.quantity) return '-';

    return `${this.preOrder.quantity?.toFixed(2)} ${
      this.preOrder.quantity === 1 ? 'share' : 'shares'
    }`;
  }

  get estimatedPrice() {
    if (!this.preOrder.estimatedPrice) return '-';

    return `${this.preOrder.estimatedPrice?.toFixed(2)}`;
  }

  get isLocalBuyAndMarketOrder(): boolean {
    return (
      this.preOrder.type === ORDER_TYPE.MARKET &&
      this.preOrder.side === ORDER_SIDE.BUY &&
      this.preOrder.currency === Currency.NGN
    );
  }

  ngOnInit() {}
}
