import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '../../../../../../api/src/lib/api.interface';
import { Statement } from './interface';
import { StatementService } from './statement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';

export interface UserListState {
  loading: boolean;
  count?: number;
  statements: Statement[];
  error?: string;
  message?: string
}

const initialState: UserListState = {
  loading: false,
  statements: [],
};

@Injectable({ providedIn: 'root' })
export class StatementListStateService {
  state = new BehaviorSubject<UserListState>(initialState);

  spinnerName = 'Statement-List';

  constructor(
    private statementSvc: StatementService,
    private spinner: NgxSpinnerService,
    private messagesService: MessagesService) { }

  find(query: object) {
    this.messagesService.open(MessagesEnum.loading, 'Fetching statements...');

    this.statementSvc
      .find(query)
      .pipe(first())
      .subscribe({
        next: this.updateStatements.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateStatements(data: PaginatedList<Statement>) {
    this.state.next({
      loading: false,
      statements: data.data,
      count: data.count,
      message: (data.count === 0) ? `User does not have any statements` : `Successfully load user statements`
    });


    // setTimeout(() => {
    //   this.spinner.hide(this.spinnerName);
    // }, 3000);
  }

  private onError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      ...this.state.getValue(),
      error,
      message: error,
      loading: false,
    });
    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 3000);
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
    });
    this.spinner.show(this.spinnerName);
  }
}
