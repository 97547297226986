import { IBankNameLookup } from './../interface/IBankNameLookup';
import { IBankNameSearch } from './../interface/IBankNameSearch';
import { Injectable } from '@angular/core';
import {
  APIResponse,
  ChakaAPIError,
  cleanChakaAPIError,
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '@console/api';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Observable, throwError } from 'rxjs';
import { IMerchantBankAccount } from '../interface/IChakaAccount';
import { catchError, map } from 'rxjs/operators';

const MERCHANT_ACCOUNT_URL = 'api/v1/settings/merchant-bank-account';
@Injectable()
export class ChakaAccountService {
  private messageIds = {
    retrieve: 0,
    delete: 0,
  };

  constructor(
    private messagesService: MessagesService,
    private http: EnterpriseAPIService
  ) {}

  public fetchAccounts(): Observable<PaginatedList<IMerchantBankAccount>> {
    !!this.messageIds.retrieve &&
      this.messagesService.hide(this.messageIds.retrieve);
    const { id } = this.messagesService.open(
      MessagesEnum.loading,
      'Retrieving account(s)...'
    );
    this.messageIds.retrieve = id;

    const accounts$ =
      this.http.get<PaginatedList<IMerchantBankAccount>>(MERCHANT_ACCOUNT_URL);

    return accounts$.pipe(
      catchError((error: ChakaAPIError) => {
        this.handleError(error);
        return throwError(error);
      }),
      map((response) => {
        const message = `Successfully retrieved ${response.count} ${
          response.count <= 1 ? 'account' : 'accounts'
        }`;

        this.messagesService.update(
          { type: MessagesEnum.success, message },
          { timeOut: 5000 }
        );

        return response;
      })
    );
  }

  public saveAccount(
    accountInfo: Partial<IMerchantBankAccount>
  ): Observable<ReqSuccessResponse<IMerchantBankAccount>> {
    const isUpdate = !!accountInfo.id;

    const saveAccount$ = isUpdate
      ? this.http.put<ReqSuccessResponse<IMerchantBankAccount>>(
          `${MERCHANT_ACCOUNT_URL}/`,
          accountInfo
        )
      : this.http.post<ReqSuccessResponse<IMerchantBankAccount>>(
          `${MERCHANT_ACCOUNT_URL}/`,
          accountInfo
        );

    return saveAccount$.pipe(
      // we handle error in the child component instance
      catchError((error) => throwError(error)),
      map((response) => {
        // We show the success message on the parent component instance and return the observable
        this.messagesService.open(
          MessagesEnum.success,
          `Successfully ${isUpdate ? 'updated' : 'added'} account`,
          { hideAll: true }
        );

        return response;
      })
    );
  }

  public deleteAccount(
    accountId: number
  ): Observable<ReqSuccessResponse<boolean>> {
    !!this.messageIds.delete &&
      this.messagesService.hide(this.messageIds.delete);
    const { id } = this.messagesService.open(
      MessagesEnum.loading,
      'Deleting account...'
    );
    this.messageIds.delete = id;

    const deleteAccount$ = this.http.delete<ReqSuccessResponse<boolean>>(
      `${MERCHANT_ACCOUNT_URL}/${accountId}`
    );

    return deleteAccount$.pipe(
      catchError((error: ChakaAPIError) => {
        this.handleError(error);
        return throwError(error);
      }),
      map((response: ReqSuccessResponse<boolean>) => {
        this.messagesService.update({
          type: MessagesEnum.success,
          message: 'Account deleted successfully',
        });

        return response;
      })
    );
  }

  getBankAccountName(
    searchParams?: IBankNameSearch
  ): Observable<APIResponse<IBankNameLookup>> {
    return this.http.get<APIResponse<IBankNameLookup>>(
      '/api/v1/banks/name-lookup',
      searchParams
    );
  }

  private handleError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);

    this.messagesService.update({
      type: MessagesEnum.danger,
      message,
    });
  }
}
