<section-search-header
	[section]="'Role Management'"
	(onSearch)="roleList.find({ q: $event})"
	[count]="(roleList.state | async).count"
  [loading]="(roleList.state | async).loading"
  [placeholder]="'search'"
  [disabled]="true"
  [actionText]="'Create New Role'"
  (actionBtn)="create()"
></section-search-header>

<ngx-spinner bdColor="#4e73df66" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{(roleList.state|async)?.message}}</p>
</ngx-spinner>

<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">

    <div class="card-body">
      <div>
        <ng-container *ngIf="createNewRate; else showRate">
        </ng-container>
      </div>
      <ng-template #showRate>
        <div class="">
          <div id="dataTable_wrapper" class="dataTables_wrapper dt-bootstrap4">

            <div class="row mt-5 mb-5 d-none d-md-block">
              <div class="col-sm-12 table-responsive">
                <table class="table table-hover">
                  <thead class="border-top-light">
                    <tr style="border-top: 0px !important;">
                      <th scope="col">
                        <div class="form-group form-check">
                          <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        </div>
                      </th>
                      <th scope="col" class="table-title">ID</th>
                      <th scope="col" class="table-title">Title</th>
                      <!-- <th scope="col" class="table-title">Sell Rate</th> -->
                      <!-- <th scope="col" class="table-title">Date</th> -->
                      <!-- <th scope="col" class="table-title">Date</th> -->
                      <th scope="col" class="table-title">Actions</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let roles of (roleList.state | async ).roles">
                      <td scope="row">
                        <div class="form-group form-check">
                          <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        </div>
                      </td>
                      <td class="table-text" (click)="viewRoles(roles?.id)"><a
                          href="javascript:void(0)">{{roles?.id || "-"}}</a></td>
                      <td class="table-text">{{roles?.name || "-"}}</td>
                      <!-- <td> {{roles?.sell || "-"}}</td>
                      <td class="table-text">{{roles?.dateCreated |date: 'medium' || "-"}}</td> -->
                      <td class="table-text px-4" (click)="viewRoles(roles?.id)"><i
                          class="fas fa-location-arrow text-primary fa-lg"></i>
                      </td>
                      <td class="table-text px-4"><i
                        class="fas fa-location-delete text-primary fa-lg"></i>
                    </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row  mb-3 d-sm-block d-md-none">
              <div class="col-lg-12 mt-3">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">
                          <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                          </div>
                        </th>
                        <th scope="col">Type</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody class="mb-0" *ngFor="let  roles of (roleList.state | async ).roles">

                      <tr class="accordion-toggle collapsed" id="accordion3" data-toggle="collapse"
                        data-parent="#accordion3" href="#collapseThree">
                        <td class="expand-button"></td>
                        <td>
                          <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input"
                              style="background-color: #ccc !important; color: #6b7188 !important;" id="exampleCheck1">
                          </div>
                        </td>
                        <td><i class="fas fa-circle kt-badge--primary"></i> <small
                            class="kt-font-primary px-2">{{roles.name}}</small></td>
                        <td><i class="fas fa-cog flaticon2 px-2"></i><i class="fas fa-file flaticon2 px-2"></i><i
                            class="fas fa-times flaticon2 px-2"></i></td>

                      </tr>
                      <tr class="hide-table-padding ">
                        <td></td>
                        <td colspan=" 4">
                          <div id="collapseThree" class="collapse in p-3">
                            <div class="row">
                              <div class="col-sm-10 px-3">
                                <ul class="list-group">
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="mobile-table-list font-weight-bold">ID</span>
                                    <span (click)="viewRoles(roles.id)">{{roles.id}}</span>
                                  </li>
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <span class="mobile-table-list font-weight-bold">Name</span>
                                    <span>{{roles?.name ||  "-"}}</span>
                                  </li>

                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>



            </div>
          </div>

          <lib-pagination
          (paginate)="paginate($event.pageNumber)"
          [count]="(roleList.state | async ).roles.length"
          [pageSize]="10"
        ></lib-pagination>
        </div>
      </ng-template>
    </div>
  </div>
</div>
