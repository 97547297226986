import { Component, OnInit } from '@angular/core';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { PageEvent } from '@console/shared/interface';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
import { RoleListStateService } from './roles-state';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  createNewRate: boolean = false;

  constructor(
    private role: AuthRoleStateService,
    private consoleRouter: ConsoleRouterService,
    public roleList: RoleListStateService
  ) { }

  viewRoles(id){
    this.consoleRouter.productNavigate(['roles',id])
  }

  create(){
    this.consoleRouter.productNavigate(['roles', 'create'])
  }

  paginate($event: PageEvent) {
    this.roleList.find({'pageNumber': $event.pageNumber});
  }

  ngOnInit(): void {
    this.role.hasPermission$('ROLE_VIEW')
    .subscribe((authorized) => {
      if (!authorized) {
        return this.consoleRouter.productNavigate([])
      }
      this.roleList.find({});
    })
  }

}
