import { Injectable } from '@angular/core';
import { EnterpriseAPIService, ReqSuccessResponse } from '@console/api';

import {
  PaymentReference,
  ExchangeRate,
} from './payment-interface';
import { PayoutAccount } from './wallet.interface';
import { NipPaymentRequest, NipPaymentResponse } from './payment-interface';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private fetch: EnterpriseAPIService) {}

  generateReference(body: PaymentReference) {
    return this.fetch.post<ReqSuccessResponse<Record<string, any>>>(
      'api/v1/payments/initialize',
      body
    );
  }

  nip(body: NipPaymentRequest) {
    return this.fetch.post<ReqSuccessResponse<NipPaymentResponse>>(
      'api/v1/payments/nip',
      body
    );
  }

  reverseViaNIP(body: NipPaymentRequest) {
    return this.fetch.post<ReqSuccessResponse<NipPaymentResponse>>(
      'api/v1/payments/reversal',
      body
    );
  }

  confirm(body: Record<string, any>) {
    return this.fetch.post<ReqSuccessResponse<NipPaymentResponse>>(
      'api/v1/payments/confirm',
      body
    );
  }

  exchangeRate() {
    return this.fetch.get<ReqSuccessResponse<ExchangeRate>>(
      'api/v1/rates/today'
    );
  }

  accounts(userId: string, query: Record<string, any> = {}) {
    query.userId = userId;
    return this.fetch.get<ReqSuccessResponse<PayoutAccount[]>>(
      `api/v1/payout/accounts`,
      query
    );
  }

  accountById(id: string) {
    return this.fetch.get<ReqSuccessResponse<PayoutAccount>>(
      `api/v1/payout/${id}`
    );
  }
}
