import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountSummary } from '@console/user-managements/models/positions.interface';
import { ORDER_SIDE } from '@console/shared';
import { CancelOpenOrderEvent, CreateOrderEvent } from './user-portfolio.interface';

@Component({
  selector: 'app-user-portfolio-overview',
  templateUrl: './user-portfolio-overview.component.html',
  styleUrls: ['./user-portfolio-overview.component.scss']
})
export class UserPortfolioOverviewComponent implements OnInit {

  @Input() account: AccountSummary;

  /**
   * Emits the event to create order
   */
  @Output() private orderReq = new EventEmitter<CreateOrderEvent>();

  /**
   * emits the event to cancel an open order
   */
  @Output() private cancelReq = new EventEmitter<CancelOpenOrderEvent>();

  constructor() { }

  buy(symbol: string) {
    this.orderReq.emit({ symbol, side: ORDER_SIDE.BUY });
  }

  sell(symbol: string) {
    this.orderReq.emit({ symbol, side: ORDER_SIDE.SELL });
  }

  cancel(order) {
    this.orderReq.emit(order.id);
  }

  ngOnInit() {
  }

}
