<!-- [color]="'#ffffff'" -->
<ngx-spinner
  [name]="editedState.spinnerName"
  [fullScreen]="false"
  type="ball-clip-rotate-multiple"
  size="medium"
>
  <p class="loading">{{ (editedState.state | async).message }}</p>
</ngx-spinner>

<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card merchant-card">
    <div class="card-header bg-white">
      <span class="card-header-title-text"
        >{{ (creatorState.state | async)?.role?.name }} Role
      </span>
      <span class="float-right">
        <span>
          <button
            [disabled]="(editedState.state | async).loading"
            (click)="saveRole()"
            class="btn btn-primary mr-3"
          >
            Save Role
          </button>

          <button
            *ngIf="(editedState.state | async)?.role?.id"
            (click)="openAttachModal()"
            class="btn btn-success mr-3"
          >
            Attach User
          </button>

          <a
            [routerLink]="['../']"
            class="btn btn-md btn-transparent border mr-3"
            ><i class="fas fa-arrow-left"></i> Back</a
          >
        </span>
      </span>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 form-group">
          <label> Role Name </label>
          <input
            [disabled]="(editedState.state | async).loading"
            [(ngModel)]="name"
            class="form-control"
          />
        </div>

        <div
          *ngIf="!(creatorState.state | async).loading"
          class="col-12 form-group"
        >
          <!-- (stateChange)="treeEvent($event)" -->
          <tree-root
            #tree
            (initialized)="onTreeInitalized($event)"
            id="tree"
            [(state)]="state"
            [nodes]="(creatorState.state | async)?.role?.permissionNodes || []"
            [options]="options"
          >
          </tree-root>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-smart-modal
  [identifier]="modalId"
  #kycActionModal
  identifier="attachuser"
  ariaLabel="attachuser"
>
  <div style="padding: 8px; background: white">
    <app-role-attachment
      *ngIf="(editedState.state | async)?.role?.id"
      [loading]="(editedState.state | async)?.loading"
      [roleId]="(editedState.state | async)?.role?.id"
      (attach)="editedState.attachUserToRole($event)"
    ></app-role-attachment>
  </div>
</ngx-smart-modal>
