/**
 * UserCSCSService
 * This service is only responsible for management of users
 * CSCS process like retriving CSCS details and admin upload of documents
 *
 * oyegoke abiodun 02-09-2020
 */
import { Injectable } from '@angular/core';
import { EnterpriseAPIService, ReqSuccessResponse } from '@console/api';
import { Observable } from 'rxjs';
import { CscsModel } from '../models/user.model';
import { LoadingService } from './../../../../shared/src/lib/components/loading/loading.service';
import { CSCSTokenUpload, IBVNDetails } from './interface';

const API_CSCS = '/api/v1/kyc/user/cscs';
const BVN_URL = '/api/v1/kyc/user/bvn/search';
// const NIN_URL = '/api/v1/kyc/user/verify-nin';
const NIN_URL = '/api/v1/kyc/user/nin-lookup';

const USER_URL = '/api/v1/kyc/user';

@Injectable()
export class UserCSCSService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  uploadCscsDetails(upload: CSCSTokenUpload) {
    const cscsDetails$ = this.http.post<ReqSuccessResponse<CscsModel>>(
      `/api/v1/kyc/user/kyc/submit-cscs`,
      upload
    );

    return this.loadingService.showLoaderUntilCompleted(cscsDetails$);
  }

  getCscsDetails(userId: string) {
    const url = `${API_CSCS}/${userId}`;
    return this.http.get<ReqSuccessResponse<CscsModel>>(url);
  }

  createCscsDetails(details: Partial<CscsModel>) {
    return this.http.post<ReqSuccessResponse<CscsModel>>(API_CSCS, details);
  }

  updateInfowareCscsDetails(details: Partial<CscsModel>) {
    return this.http.post<ReqSuccessResponse<CscsModel>>(
      USER_URL + '/update-iw-cscs/' + details.userId,
      details
    );
  }

  getBVN(bvnNumber): Observable<ReqSuccessResponse<IBVNDetails>> {
    const url = `${BVN_URL}/${bvnNumber}`;
    const $getBvn = this.http.get<ReqSuccessResponse<IBVNDetails>>(url);

    return this.loadingService.showLoaderUntilCompleted($getBvn);
  }

  verifyNin(
    nin: string,
    userId: string
  ): Observable<ReqSuccessResponse<string>> {
    const $ninInfo = this.http.put<ReqSuccessResponse<any>>(NIN_URL, {
      nin,
      userId,
    });

    return this.loadingService.showLoaderUntilCompleted($ninInfo);
  }
}
