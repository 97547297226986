<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <ngx-spinner
      [name]="instrument.spinnerName"
      bdColor="#4e73df66"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">
        {{ (instrument.state | async)?.message }}
      </p>
    </ngx-spinner>
    <div class="card-header bg-light py-3">
      <!-- (click)="requery()" -->
      <!-- *ngIf="canBeQueried$ | async" -->
      <!-- <a
          class="btn btn-danger"
          style="color: white"
          href="javascript:;"
          matTooltip="Re-Query Order"
        >
          <i class="fa fa-delete" aria-hidden="true"></i>
          <span class="kt-hidden-mobile">Delete</span>
        </a> -->
        <a
        style="color: white"
        (click)="goBack()"
        class="btn btn-primary float-right"
        matTooltip="Back to the users list"
      >
        <i class="la la-arrow-left"></i>
        <span style="color: white" class="kt-hidden-mobile">Back</span>
      </a>

    </div>

    <div class="container container-fluid mt-4">
      <div class="kt-form">
        <div class="banner-container">
          <app-stock-market-banner
            [instrument]="(instrument.state | async).instrument"
            [change]="(graph.state | async).priceChange"
            [changePercent]="(graph.state | async).pricePercentageChange"
            [currentPrice]="(graph.state | async).priceChange"
            [intervalText]="(graph.state | async).subtext"
          ></app-stock-market-banner>
        </div>

        <div class="chart-controls">
          <app-line-chart
            (intervalChanged)="changeGraphInterval($event)"
            [symbol]="(instrument.state | async).instrument?.symbol"
            [currency]="(instrument.state | async).instrument?.currency"
            [charts]="(graph.state | async).graphPoints"
          ></app-line-chart>

        </div>

        <div *ngIf="(instrument.state | async).instrument" class="stock-informations">

          <div class="stock-history">

            <div class="line-divider"></div>

            <app-market-information [instrument]="(instrument.state | async).instrument"></app-market-information>
            <app-company-information
            [instrument]="(instrument.state | async).instrument"
            ></app-company-information>
            <app-similar-instruments
            (navigate)="viewSimilarStock($event)"
            [instruments]="(similar.state | async).instruments"
            ></app-similar-instruments>

            <div class="similar-instruments">
            </div>

          </div>











          <div class="order-sections">

          </div>
        </div>


      </div>
    </div>
  </div>
</div>






