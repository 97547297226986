import { EventEmitter, Output, OnDestroy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  template: ``,
  selector: 'abstract-kyc'
})
export abstract class KYCFormControl<T> implements OnDestroy {
  private subscription?: Subscription;

  @Input() disabled = true;

  @Output() formUpdate = new EventEmitter<T>();

  listen(form: FormGroup, transformer?: (value: T) => T) {
    this.subscription = form.valueChanges.
      subscribe((value: T) => {
        console.log('here', value);
        value = transformer ? transformer(value) : value;
        this.formUpdate.emit(value);
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
