import { NewsEditorStateService } from './news-edit/news-edit.state';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ConsoleRouterService } from '../../../../../../projects/shared/src/public-api';
import { NewsListStateService } from './news-list.state';
import { PageEvent } from '../../../../../shared/src/lib/interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
NewsEditorStateService
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class NewsComponent implements OnInit {
  childRoute = false;

  filter: Record<string, any> = {
    pageSize: 12,
  };

  constructor(
    private router: ConsoleRouterService,
    public newsList: NewsListStateService,
    private newsState: NewsEditorStateService
  ) {}

  search(query: string) {
    this.filter.q = query;
    this.newsList.find(this.filter);
  }

  paginate($event: PageEvent) {
    this.newsList.find({ ...this.filter, ...$event });
  }

  newsForm = new FormGroup({
    title: new FormControl(''),
    category: new FormControl('', Validators.required),
    pageUrl: new FormControl('', Validators.required),
    imageUrl: new FormControl('', Validators.required),
    summary: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required)
  })

  parentNavigate() {
    this.childRoute = false;
    this.newsList.find(this.filter);
  }

  navigate(id?: string) {
    if (id) {
      this.router.productNavigate(['news', id]);
      return;
    }

    this.childRoute = true;
  }

  ngOnInit() {
    this.newsList.find(this.filter);
  }

  view() {
    this.childRoute = false;
    this.newsList.find(this.filter);
  }

  save() {
    this.newsState.create(this.newsForm.value);
    // setTimeout(() => {
    //   this.view();
    // }, 3000);
  }

  ngOnDestroy() {
    this.newsList.reset();
  }
}
