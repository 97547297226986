//HERE
export interface AccountType {
  id?: any;
  name: string;
  description: string;
}

export interface AccountMgmtType {
  id?: any;
  name: string;
  description: string;
}

export interface Status {
  id?: any;
  name: string;
  description: string;
}

export interface TradingType {
  id?: any;
  name: string;
  description: string;
}

export interface Bod {
  moneyMarket: number;
  equityValue: number;
  cashAvailableForWithdrawal: number;
  cashAvailableForTrading: number;
  updatedWhen: Date;
  cashBalance: number;
}

export interface DwAccount {
  id: string;
  accountNo: string;
  leverage: number;
  nickname: string;
  commissionID: string;
  userID: string;
  restricted: boolean;
  goodFaithViolations: number;
  patternDayTrades: number;
  freeTradeBalance: number;
  gfvPdtExempt: boolean;
  buyingPowerOverride: boolean;
  sweepInd: boolean;
  interestFree: boolean;
  createdWhen: Date;
  openedWhen: Date;
  accountType: AccountType;
  accountMgmtType: AccountMgmtType;
  status: Status;
  tradingType: TradingType;
  bod: Bod;
}
