<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card shadow mb-4">
    <div class="card-header bg-light py-3">
      Create Order (Owned: {{ owned }} shares)
    </div>

    <div class="container container-fluid mt-4">
      <div class="kt-form">
        <div class="row">
          <div class="col-12">
            <form [formGroup]="orderForm">
              <div class="form-row">
                <div *ngIf="!marketPriceEquivalent" class="col-6 form-group">
                  <label for="price">Price</label>
                  <input
                    id="price"
                    class="form-control"
                    min="1"
                    required
                    type="number"
                    formControlName="price"
                  />
                </div>

                <div class="col-6 form-group">
                  <label for="lastName">Quantity</label>
                  <input
                    id="quantity"
                    class="form-control"
                    min="0"
                    required
                    type="number"
                    formControlName="quantity"
                  />
                </div>

                <div
                  *ngIf="marketPriceEquivalent && !isLocalBuyMarketOrder"
                  class="col-6 form-group"
                >
                  <label for="price">Equivalent Order Price</label>
                  <input
                    disabled
                    id="price"
                    class="form-control"
                    [value]="marketPriceEquivalent"
                  />
                </div>

                <div class="col-6 form-group">
                  <label for="OrderType">OrderType</label>
                  <select
                    (valueChange)="onOrderTypeChange($event)"
                    formControlName="orderType"
                    class="form-control"
                    id="OrderType"
                  >
                    <option
                      *ngFor="let orderType of orderTypes"
                      [value]="orderType.value"
                    >
                      {{ orderType.value }}
                    </option>
                  </select>
                </div>

                <div class="col-6 form-group">
                  <label for="OrderSide">OrderSide</label>
                  <select
                    (valueChange)="onOrderSideChange($event)"
                    formControlName="orderSide"
                    class="form-control"
                    id="OrderSide"
                  >
                    <option
                      *ngFor="let orderSide of orderSides"
                      [value]="orderSide.value"
                    >
                      {{ orderSide.value }}
                    </option>
                  </select>
                </div>

                <div *ngIf="isASellOrder" class="col-12 form-group">
                  <label for="OrderSide">Sell All</label>
                  <input type="checkbox" (change)="onSelectAll($event)" />
                </div>

                <button
                  (click)="submitForm(orderForm.value)"
                  [disabled]="!orderForm.valid"
                  class="btn btn-primary btn-block"
                >
                  Pre-Order
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
