<!-- Overiew sections -->
<!-- Shows Equity value and equities and buy and sell action-->
<!-- Shows Open Orders and cancel open orders -->
<!-- Order Section -->
<!-- instrument list -->
<!-- Buy and Sell component -->
<div *ngIf="!symbol" class="row">
  <div class="col-md-3">
    <ul class="list-group">
      <!-- <li
        [class]="tabClass(section)"
        (click)="currentSection = section"
        *ngFor="let section of sections">
        {{ section }}
      </li> -->

      <li
        *ngIf="consoleRouterService.isDisabledIn(['equity'])"
        [ngClass]="this.currentTabIndex === tabList.OVERVIEW ? 'active' : ''"
        class="list-group-item"
        (click)="setTab(tabList.OVERVIEW)"
      >
        Overview
      </li>

      <li
        *ngIf="consoleRouterService.isDisabledIn(['advisory'])"
        [ngClass]="this.currentTabIndex === tabList.POSITIONS ? 'active' : ''"
        class="list-group-item"
        (click)="setTab(tabList.POSITIONS)"
      >
        Positions
      </li>

      <li
        *ngIf="consoleRouterService.isDisabledIn(['advisory'])"
        [ngClass]="this.currentTabIndex === tabList.OPEN_ORDERS ? 'active' : ''"
        class="list-group-item"
        (click)="setTab(tabList.OPEN_ORDERS)"
      >
        Open Orders
      </li>

      <li
        [ngClass]="
          this.currentTabIndex === tabList.ACCOUNT_SUMMARY ? 'active' : ''
        "
        class="list-group-item"
        (click)="setTab(tabList.ACCOUNT_SUMMARY)"
      >
        Account Summary
      </li>

      <li
        *ngIf="consoleRouterService.isDisabledIn(['advisory'])"
        [ngClass]="this.currentTabIndex === tabList.INSTRUMENTS ? 'active' : ''"
        class="list-group-item"
        (click)="setTab(tabList.INSTRUMENTS)"
      >
        Instruments
      </li>

      <li
        [ngClass]="
          this.currentTabIndex === tabList.GLOBAL_ACCOUNT ? 'active' : ''
        "
        class="list-group-item"
        (click)="setTab(tabList.GLOBAL_ACCOUNT)"
      >
        Global Account
      </li>
    </ul>
  </div>

  <div class="col-md-9">
    <div
      class="row"
      *ngIf="
        tabList.OVERVIEW === currentTabIndex &&
        !consoleRouterService.isDisabledIn(['advisory'])
      "
    >
      <div class="col-12">
        <!-- <app-advisory-overview
          [userId]="userId"
          [tradeCurrency]="tradeCurrency"></app-advisory-overview> -->

        <lib-advisory-portfolio-details
          [userId]="userId"
          [tradeCurrency]="tradeCurrency"
        >
        </lib-advisory-portfolio-details>
      </div>
    </div>

    <div
      *ngIf="
        tabList.POSITIONS === currentTabIndex &&
        !consoleRouterService.isDisabledIn(['equity'])
      "
      class="row"
    >
      <div class="col-12">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <button class="btn btn-primary w-25" (click)="switchCurrency()">
            {{
              tradeCurrency === "USD"
                ? "View LOCAL Portfolio"
                : "View GLOBAL Portfolio"
            }}
          </button>
          <button class="btn btn-success w-25" (click)="tableView = !tableView">
            Toggle
          </button>
          <button class="btn btn-danger w-25" (click)="downloadFile()">
            Download
          </button>
        </div>
      </div>

      <app-equity-positions-table
        *ngIf="tableView"
        [positions]="
          (portfolio.state | async)?.account?.equity?.equityPositions || []
        "
      ></app-equity-positions-table>
      <div class="row" *ngIf="!tableView">
        <div
          *ngFor="
            let equity of (portfolio.state | async)?.account?.equity
              ?.equityPositions || []
          "
          class="col-md-6"
        >
          <div class="row">
            <div class="col-md-12">
              <app-equity-postition
                [currency]="tradeCurrency"
                (trade)="symbol = $event"
                [equity]="equity"
              ></app-equity-postition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        tabList.OPEN_ORDERS === currentTabIndex &&
        !consoleRouterService.isDisabledIn(['equity'])
      "
      class="row"
    >
      <div
        *ngFor="let order of (portfolio.state | async)?.account?.orders || []"
        class="col-md-6"
      >
        <div class="row">
          <div class="col-12">
            <ngx-spinner
              [name]="openOrders.spinnerName"
              bdColor="#4e73df66"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
            >
              <p style="font-size: 20px; color: white">
                {{ (openOrders.state | async).message }}
              </p>
            </ngx-spinner>
            <app-open-orders
              (cancelReq)="cancel($event)"
              (requeryReq)="requery($event)"
              [order]="order"
            ></app-open-orders>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="tabList.ACCOUNT_SUMMARY === currentTabIndex" class="row">
      <app-account-info
        [portfolio]="(portfolio.state | async).account"
      ></app-account-info>
    </div>

    <div
      *ngIf="
        tabList.INSTRUMENTS === currentTabIndex &&
        !consoleRouterService.isDisabledIn(['equity'])
      "
    >
      <app-instrument-listing
        (onSearch)="instruments.find($event)"
        [instruments]="(instruments.state | async).Instruments"
        (trade)="symbol = $event"
      ></app-instrument-listing>
    </div>

    <div *ngIf="tabList.GLOBAL_ACCOUNT === currentTabIndex">
      <app-dw-account [userId]="userId"> </app-dw-account>
    </div>
  </div>
</div>

<app-instrument-order
  *ngIf="symbol"
  [userId]="userId"
  [symbol]="symbol"
></app-instrument-order>
<!-- <div  style="min-width: 78vw;" class="row">

</div> -->
