<div *ngIf="equity" class="row kt-widget5__item">
  <div class="col-md-6 kt-widget5__content">
    <div class="kt-widget5__section">
      <a
        href="javascript:;"
        (click)="buy.emit(equity.symbol)"
        class="kt-widget5__title">
        {{ equity.symbol }}</a>
      <p class="kt-widget5__desc">
        <strong>{{ equity.openQty | number: "1.2-4" }} Unit(s)</strong>
      </p>
      <p class="kt-widget5__desc">
        <strong>{{ formatToCurrency(currency, equity.marketValue) }}</strong>
      </p>
      <p
        class="kt-widget5__desc"
        [ngClass]="{
          green: equity.netEarningsPercentage >= 0,
          red: equity.netEarningsPercentage < 0
        }">
        {{ equity.netEarningsPercentage / 100 | percent: "1.2-2" }}
      </p>
    </div>
  </div>
  <div class="col-md-6 kt-widget5__action">
    <div class="mb-3">
      <button
        (click)="trade.emit(equity.symbol)"
        class="btn btn-outline-primary">
        Trade
      </button>
    </div>
  </div>
</div>
