import { WALLET_STATUS, WALLET_TYPES } from '@console/shared';
import { Currency, CURRENCY } from '../../../../../../shared/src/lib/_enums/currency.enum';

export interface Wallet {
  id: number;
  userId: string;
  totalBalance: number;
  availableBalance: number;
  availableToTrade: number;
  availableToWithdraw: number;
  pendingCash: number;
  equityValue?: number;
  merchantId: number;
  currency: CURRENCY;
  provider: string;
  identifier: any;
  providerUserId: any;
  modifiedBy: string;
  createdBy: string;
  dateCreated: string;
  dateModified: string;
  productType: string;
  VirtualAccount: VirtualAccount;
}

export interface VirtualAccount {
  accountName: string;
  accountNumber: string;
  accountReference: string;
  bankCode: string;
  bankName: string;
  contractCode: string;
  createdBy: string;
  currencyCode: CURRENCY;
  customerEmail: string;
  dateCreated: string;
  dateModified: string;
  id: number;
  modifiedBy: string;
  reservationReference: string;
  status: WALLET_STATUS;
  walletId: number;
}

export enum WalletTypes {
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
}

export interface WalletTransfer {
  from: WalletTypes;
  to: WalletTypes;
  amount: number;
  userId: string;
}

export enum WalletTransactionStatus {
  INITIATED = 'INITIATED',
  PROCESSING = 'PROCESSING',
  SUCCESSFUL = 'SUCCESSFUL',
  PENDING = 'PENDING',
  WAITING = 'WAITING',
  CANCELLED = 'CANCELLED',
  PENDING_CANCEL = 'PENDING_CANCEL',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
}

export enum SortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export interface WalletTransaction {
  status: WalletTransactionStatus;
  id?: number;
  reference: string;
  action: TransactionActions;
  description: string;
  amount: number;
  currencyCode: string;
  exchangeRate: number;
  openingBalance: number;
  sourceReference: string;
  destinationReference: string;
  comment: string;
}

export enum TransactionActions {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export interface WalletWithdrawal {
  id: string;
  reference: string;
  integration: string;
  domain: string;
  amount: string;
  currency: string;
  source: string;
  reason: string;
  recipient: string;
  status: string;
  transferCode: string;
  // Audit Properties
  dateCreated: string;
  dateUpdated: string;
  createdBy: string;
  modifiedBy: string;
}

export interface WalletWithdraw {
  accountId: number;
  amount: number;
  bankName: string;
  channel: string;
  from: WalletTypes;
  userId: string;
  currency: Currency
}

export interface PayoutAccount {
  id: number;
  active: boolean;
  currency: string;
  type: string;
  accountNumber: string;
  accountName: string;
  bankCode: string;
  bankName: string;
  dateCreated: string;
  modifiedDate: string;
  deleted: boolean;
}

export interface PasswordWithdrawRequest {
  password: string;
  transfer: Pick<WalletWithdraw, 'amount' | 'from'>;
  account: PayoutAccount;
}

export class CreateUserWallet {
  walletTypes: WALLET_TYPES[];
  userId: string;
}
