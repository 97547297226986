import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SubHeaderComponent } from './sub-header.component';


@NgModule({
  imports: [CommonModule],
  exports: [SubHeaderComponent],
  declarations: [SubHeaderComponent],
})
export class SearchBarModule { }
