import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { ToastrService } from 'ngx-toastr';
import { map, take } from 'rxjs/operators';
import { ConsoleRouterService } from '../../../../../../../projects/shared/src/public-api';
import { InstrumentSymbolsStateService } from '../../../instrument/instrument-symbols.state';
import { InstrumentListStateService } from '../../../instrument/instrument.state';
import { InstrumentModel } from '../../../instrument/models/instrument.interface';
import {
  CollectionEditorState,
  CollectionEditorStateService,
  CrudState
} from './collection-edit.state';

@Component({
  selector: 'kt-collections-edit',
  templateUrl: './collections-edit.component.html',
  styleUrls: ['./collection-edit.component.scss'],
})
export class CollectionsEditComponent implements OnInit, OnDestroy {
  collectionForm: FormGroup;
  // createCollectionForm:FormGroup;
  localState: CrudState = CrudState.Initial;
  create = true;

  public formMethods;

  imgURL: any;

  initialImageUrl: string;

  imageFile: File;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: ConsoleRouterService,
    public collection: CollectionEditorStateService,
    private form: FormBuilder,
    public instruments: InstrumentListStateService,
    public symbols: InstrumentSymbolsStateService,
    private consoleRouter: ConsoleRouterService,

  ) { }

  onInstrumentAdded() {
    const value = this.collectionForm
      .get('instruments')?.value || '';
    this.pickedInstruments(value);
  }

  // tslint:disable-next-line: typedef
  onRemoveInstrumentSelected(instrument: InstrumentModel) {
    const field = 'instruments';
    if (this.collectionForm && this.collectionForm.get(field)) {
      let currentValue: string =
        this.collectionForm.get(field).value || '';
      currentValue = currentValue
        .replace(' ', '')
        .replace(`${instrument.symbol},`, '')
        .replace(`${instrument.symbol}`, '');
      this.collectionForm.get(field).setValue(currentValue);
      this.pickedInstruments(currentValue);
    }
  }


  toggleEditMode() {
    this.localState = CrudState.Update;
  }

  get isEditModeDisabled() {
    if (!this.collectionForm) { return true; }
    const isDisabled = !(
      this.localState === CrudState.Create ||
      this.localState === CrudState.Update
    );
    if (isDisabled) {
      this.collectionForm.disable();
    } else {
      this.collectionForm.enable();
    }
    return isDisabled;
  }

  setupFormWithState(collectionId?: string) {
    this.createForm();

    if (!collectionId) {
      this.localState = CrudState.Create;
      return;
    }
    this.collection.retrieve(collectionId);
    this.collection.state
      .pipe(take(2))
      .subscribe(this.instanceFormWithData.bind(this));
  }

  private instanceFormWithData(state: CollectionEditorState) {
    if (state.collection) {

      this.createForm(state.collection);

      this.pickedInstruments(state.collection.instruments);
      this.localState = CrudState.Retrieved;
      this.collectionForm.disable();
    }
  }

  private createForm(collection: Partial<any> = {}) {

    this.initialImageUrl = collection.imageUrl;

    this.collectionForm = this.form.group({
      id: [collection.id],
      title: [collection.title, Validators.required],
      instruments: [collection.instruments, Validators.required],
      description: [collection.description, Validators.required],
      image: [''],
    });

    this.formMethods = formMethods(this.collectionForm);
  }

  pickedInstruments(symbols: string): void {
    if (typeof symbols === 'string' && symbols !== '') {
      this.symbols.fetchSymbols(symbols, { pageSize: 300 });
      return;
    }
    this.symbols.reset();
  }

  delete(): void {
    const { id } = this.collectionForm.value;
    this.collection.delete(id);
  }

  get collection$() {
    return this.collection.state.pipe(
      map((state => state.collection))
    );
  }

  updateCollection(): void {
    if (!this.collectionForm.valid) {
      this.collectionForm.updateValueAndValidity();
      return;
    }

    const dataToSubmit = {
      ...this.collectionForm.value,
      image: this.imageFile
    };

    this.collection.update(dataToSubmit);
  }

  preview(event): void {
    this.collectionForm.get('image').setErrors({ invalidImage: null });
    this.collectionForm.get('image').updateValueAndValidity();

    this.imgURL = '';
    this.imageFile = null;

    if (event.length === 0) {
      return;
    }

    if (event[0].type.split('/')[0] !== 'image') {
      this.collectionForm.get('image').setErrors({ invalidImage: true });
      return;
    }

    this.imageFile = (event as HTMLInputElement)[0];

    const reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }


  ngOnInit(): void {
    this.instruments.find({});
    this.activatedRoute.params.subscribe(
      (params) => {
        const id = params['id'];
        if (id) {
          this.collection.retrieve(id);
          this.setupFormWithState(id);
        } else {
          this.consoleRouter.productNavigate(['collections']);
        }
      }
    );

  }

  ngOnDestroy() {
    this.collection.resetState();
    this.instruments.reset();
    this.symbols.reset();
  }
}
