import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { ConsoleRouterService } from '../../../../../../../projects/shared/src/public-api';
import { CrudState, NewsEditorState, NewsEditorStateService } from './news-edit.state';
@Component({
  selector: 'kt-news-edit',
  templateUrl: './news-edit.component.html',
  styles: [
    `
			.dot--none {
				background-color: #c4c4c4;
			}
			.dot--pending {
				background-color: #ffb822;
			}
			.dot--approved {
				background-color: #22a4ef;
			}
		`,
  ],
})
export class NewsEditComponent implements OnInit {

  newsForm: FormGroup;
  hasFormErrors = false;
  localState: CrudState = CrudState.Initial;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private form: FormBuilder,
    public newsState: NewsEditorStateService,
    private consoleRouter: ConsoleRouterService,

  ) {}

  toggleEditMode() {
    this.localState = CrudState.Update;
  }

  get isEditModeDisabled() {
    if (!this.newsForm) { return true; }
    const isDisabled = !(
      this.localState === CrudState.Create ||
      this.localState === CrudState.Update
    );
    if (isDisabled) {
      this.newsForm.disable();
    } else {
      this.newsForm.enable();
    }
    return isDisabled;
  }

  setupFormWithState(newsId?: number) {
    this.newsForm = this.createForm();
    if (!newsId) {
      this.localState = CrudState.Create;
      return;
    }
    this.newsState.retrieve(newsId);
    this.newsState.state
      .pipe(take(2))
      .subscribe(this.instanceFormWithData.bind(this));
  }

  private instanceFormWithData(state: NewsEditorState) {
    if (state.news) {
      this.newsForm = this.createForm(state.news);
      // this.pickedInstruments(state.collection.instruments);
      this.localState = CrudState.Retrieved;
      this.newsForm.disable();
    }
  }

  private createForm(news: Partial<any> = {}) {
    return this.form.group({
      id: [news.id],
      title: [news.title, Validators.required],
      category: [news.category, Validators.required],
      pageUrl: [news.pageUrl, Validators.required],
      imageUrl: [news.imageUrl, Validators.required],
      summary: [news.summary, Validators.required],
      content: [news.content, Validators.required],

    });
  }

  delete() {
    const { id } = this.newsForm.value;
    this.newsState.delete(id);
  }

  get news$() {
    return this.newsState.state.pipe(
      map((state => state.news))
    )
  }

  updateNews() {
    this.newsState.update(this.newsForm.value)
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params) => {
        const id = params["id"];
        if (id) {
          this.newsState.retrieve(id);
          this.setupFormWithState(id)
        } else {
          this.consoleRouter.productNavigate(["news"]);
        }
      }
    );

  }

}
