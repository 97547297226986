import { RedemptionStatusEnum } from '../../../../shared/src/lib/_enums/RedemptionStatus.enum';
import { MatMultiSort } from 'ngx-mat-multi-sort';
import {
  IRedemptionState,
  RedemptionStateService,
} from './state/redemption-state.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
// import { PageEvent } from '../../../../shared/src/lib/interface';
import { FormControl, FormGroup } from '@angular/forms';
import {
  fileNameFormatter,
  exportAndDownload,
} from 'projects/_shared/csv-downloader/csv-downloader';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MerchantsModel } from '../merchants/merchants.interface';
import { AuthProfileStateService } from '@console/authentication/profile-state';
import {
  MerchantListState,
  MerchantListStateService,
} from '../merchants/merchant-list/merchant-list.state';
import { IRedemption } from './interface/IRedemption';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-redemption-list',
  templateUrl: './redemption-list.component.html',
  styleUrls: ['./redemption-list.component.scss'],
})
export class RedemptionListComponent implements OnInit, OnDestroy {
  // search = new FormControl('');
  // maxDate = new Date();
  filter: Record<string, any> = {
    pageSize: 10,
  };

  @Input() userId: string;

  redemptionStatusList = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Started',
      value: RedemptionStatusEnum.STARTED,
    },
    {
      label: 'Pending',
      value: RedemptionStatusEnum.PENDING,
    },
    {
      label: 'Successful',
      value: RedemptionStatusEnum.SUCCESSFUL,
    },
    {
      label: 'Failed',
      value: RedemptionStatusEnum.FAILED,
    },
    {
      label: 'Other',
      value: RedemptionStatusEnum.OTHER,
    },
    {
      label: 'RIA Pending',
      value: RedemptionStatusEnum.RIA_PENDING,
    },
    {
      label: 'RIA Approved',
      value: RedemptionStatusEnum.RIA_APPROVED,
    },
    {
      label: 'RIA Rejected',
      value: RedemptionStatusEnum.RIA_REJECTED,
    },
    {
      label: 'Approved',
      value: RedemptionStatusEnum.APPROVED,
    },
    {
      label: 'Rejected',
      value: RedemptionStatusEnum.REJECTED,
    },
    {
      label: 'On Hold',
      value: RedemptionStatusEnum.On_Hold,
    },
  ];

  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'accountNo',
    'reference',
    'amount',
    // 'category',
    'type',
    'status',
    'created',
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  loading = false;

  count = 0;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[];
    loading: boolean;
    total: number;
    pageSize: number;
    pageNumber: number;
  } = {
    list: [],
    loading: false,
    total: 0,
    pageSize: 1000,
    pageNumber: 0,
  };

  btnLoading = {
    approve: false,
    reject: false,
  };

  initialError = {
    approve: false,
    reject: false,
  };

  errors = this.initialError;

  errorIds = {
    approve: 0,
    reject: 0,
  };

  constructor(
    private consoleRouter: ConsoleRouterService,
    private redemptionState: RedemptionStateService,
    private messagesService: MessagesService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService
  ) {}

  ngOnInit(): void {
    this.filter.pageSize = this.pageEvent.pageSize;
    this.handleQuery();

    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      q: new FormControl(''),
      status: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(),
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.redemptionState.state$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: IRedemptionState) => {
        this.dataSource = new MatTableDataSource<IRedemption>(
          state.redemptions
        );
        this.dataSource.sort = this.sort;
        this.count = state.count;
        this.loading = state.loading;
      });
  }

  listenOnAuthProfile(): void {
    this.authProfile.isSuperAdmin$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((isSuperAdmin) => isSuperAdmin)
      )
      .subscribe((isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;

        this.isSuperAdmin
          ? this.merchantListState.find({
              pageSize: this.merchants.pageSize,
              pageNumber: this.merchants.pageNumber,
            })
          : null;
      });
  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe((state: MerchantListState) => {
        this.merchants.loading = state?.loading;
        this.merchants.total = state?.count;
        this.merchants.list = [...this.merchants.list, ...state?.merchants];
      });
  }

  viewRedemption(autoRebalanceId: number): void {
    this.consoleRouter.productNavigate(['redemptions', autoRebalanceId]);
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    if (this.userId) {
      this.filter.userId = this.userId;
    }

    this.redemptionState.find(this.filter);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach((withdrawal) => {
      this.updateCheckedSet(checked, withdrawal);
    });
  }

  onItemChecked(checked: boolean, withdrawal: IRedemption): void {
    this.updateCheckedSet(checked, withdrawal);
  }

  updateCheckedSet(checked: boolean, withdrawal: IRedemption): void {
    if (checked) {
      this.selectedItems.set(withdrawal.id, withdrawal);
    } else {
      this.selectedItems.delete(withdrawal.id);
    }
  }

  get selectedRedemptions(): IRedemption[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Redemptions',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedRedemptions);
  }

  showNotAllowedRow(row: IRedemption): boolean {
    if (this.errors.approve) {
      return (
        !this.redemptionState.canBeApproved(row?.status?.message) &&
        this.selectedItems.has(row?.id)
      );
    }

    if (this.errors.reject) {
      return (
        !this.redemptionState.canBeRejected(row?.status?.message) &&
        this.selectedItems.has(row?.id)
      );
    }

    return false;
  }

  approveOrRejectSelected(action: 'approve' | 'reject'): void {
    try {
      this.errors = { ...this.initialError };

      let cannotProcess = true;

      switch (action) {
        case 'approve':
          cannotProcess = this.selectedRedemptions.some(
            ({ status }) => !this.redemptionState.canBeApproved(status?.message)
          );
          break;

        case 'reject':
          this.selectedRedemptions.some(
            ({ status }) => !this.redemptionState.canBeRejected(status?.message)
          );
          break;

        default:
          throw new Error('Please choose an action');
          break;
      }

      if (cannotProcess) {
        this.errors[action] = true;

        const allowedStatus =
          action === 'approve'
            ? this.redemptionState.allowedApprovalStatus
            : this.redemptionState.allowedRejectionStatus;

        throw new Error(
          `You can ONLY ${action} redemptions with status: [${allowedStatus.join(
            ', '
          )}]`
        );
      }

      if (this.selectedItems.size > 200) {
        throw new Error(`You can only ${action} a maximum of 200 redemptions`);
      }

      const check = confirm(
        `Do you want to ${action} ${
          this.selectedItems.size === 1
            ? 'this redemption'
            : 'these redemptions'
        }?`
      );

      if (!check) {
        return;
      }

      this.btnLoading.approve = true;

      const status =
        action === 'approve'
          ? (RedemptionStatusEnum.RIA_APPROVED as RedemptionStatusEnum.RIA_APPROVED)
          : (RedemptionStatusEnum.RIA_REJECTED as RedemptionStatusEnum.RIA_REJECTED);

      const reqPayload = {
        redemptionIDs: this.selectedRedemptions.map(({ id }) => id),
        status,
      };

      this.messagesService.hideAll();

      this.redemptionState.batchApproveOrReject(
        reqPayload,
        (success: boolean) => {
          this.btnLoading[action] = false;

          if (success) {
            this.selectedItems.clear();
            this.handleQuery();
          }
        }
      );
    } catch (error) {
      this.messagesService.hideAll();

      if (Boolean(this.errorIds[action])) {
        this.messagesService.update({
          id: this.errorIds[action],
          message: error.message,
          isOpened: true,
        });

        return;
      }

      const { id } = this.messagesService.open(
        MessagesEnum.danger,
        error.message
      );

      this.errorIds[action] = id;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.redemptionState.reset();

    this.messagesService.hideAll();

    this.merchantListState.reset();
  }
}
