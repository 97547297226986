<form [formGroup]="configForm"  >
  <div class="row">
    <div class="col-12">
      <div class="form-group kt-form__group row">
        <div class="col-md-6 kt-margin-bottom-20">
          <input
            required
            class = "form-control"
            placeholder="Enter Config Name"
            formControlName="name"
          />
        </div>
        <div class="col-md-6 kt-margin-bottom-20">
          <input
            class = "form-control"
            required
            placeholder="Enter Config Value"
            formControlName="value"
          />
        </div>
        <div class="col-md-6 kt-margin-bottom-20-mobile">
          <select required formControlName="settingsGroup" class="form-control">
            <option value="{{ group.value }}"
            *ngFor="let group of settingsGroups"
            >{{ group.name }}</option>
          </select>
        </div>
        <div class="col-md-6 kt-margin-bottom-20-mobile">
          <textarea
            required
            class = "form-control"
            placeholder="Enter Description"
            formControlName="description"
          ></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <input required  type="checkbox" formControlName="encrypted" />
        </div>
      </div>
      </div>
      <div class="form-row">
        <div class="col-3">
          <div *ngIf="config.id">
            <button
              type="button"
              mat-raised-button
              (click)="delete.emit()"
              matTooltip="Delete Config"
              color="warn"
            >
              Delete Config
            </button>
          </div>
        </div>
        <div class="col-9">
          <div class="row text-right">
            <div class="col-lg-12">
              <button
                type="button"
                mat-raised-button
                (click)="cancel"
                matTooltip="Cancel changes"
              >
                Cancel</button
              >&nbsp;
              <button
                type="button"
                [disabled]="!configForm.valid"
                mat-raised-button
                color="primary"
                (click)="save.emit(config)"
                [disabled]="disabled"
                matTooltip="Save changes"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</form>
