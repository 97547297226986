import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import {
  ReqSuccessResponse,
  ChakaAPIError,
  cleanChakaAPIError,
} from '@console/api';
import {
  WalletTransaction,
  WalletTransfer,
} from '../providers/wallet.interface';
import { WalletService } from '../providers/wallet.service';
import { NotificationService } from '@console/shared/services/notification-service';

export interface WalletExchangetate {
  transaction?: WalletTransaction;
  loading: boolean;
  error?: string;
}

const initialState: WalletExchangetate = { loading: false };

@Injectable({ providedIn: 'root' })
export class WalletExchangeStateService {
  state = new BehaviorSubject<WalletExchangetate>(initialState);

  spinnerName = 'Wallet-Exchange-Spinner';

  constructor(
    private wallet: WalletService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService
  ) { }

  exchange(transfer: WalletTransfer) {
    this.loading();
    this.wallet.exchange(transfer).subscribe({
      next: this.onUserWalletExchangeLoaded.bind(this),
      error: this.onUserWalletExchangeError.bind(this),
    });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true
    });

    this.spinner.show(this.spinnerName);
  }

  private onUserWalletExchangeLoaded({
    data,
  }: ReqSuccessResponse<WalletTransaction>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      transaction: data,
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.success(`wallet exchange ${data.reference} successful`)

  }

  private onUserWalletExchangeError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false
    });

    this.spinner.hide(this.spinnerName);

    this.notificationService.error(error);
  }
}
