import { DashboardService } from './../dashboard.service';
import { MerchantDetailStateService } from 'src/app/dashboard/merchant-details.state';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-top-bar-mobile',
  templateUrl: './top-bar-mobile.component.html',
  styleUrls: ['./top-bar-mobile.component.scss']
})

export class TopBarMobileComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject();

  @ViewChild('sidebarToggle') sidebarToggle: ElementRef;

  constructor(
    private renderer: Renderer2,
    public merchant: MerchantDetailStateService,
    private dashboardService: DashboardService
  ) { }


  ngOnInit(): void {
    this.dashboardService.mobileSidebar$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: boolean) => {
        event ? this.showMobileSidebar() : this.hideMobileSidebar();
      });
  }

  private showMobileSidebar(): void {
    const attribute = 'data-offcanvas-aside';
    const asideElem = document.getElementById('kt_aside');

    this.renderer.setAttribute(document.body, attribute, 'on');
    this.renderer.addClass(asideElem, 'aside-on');

    // this.dashboardService.sideBarOverlay$.next(true);
  }

  private hideMobileSidebar(): void {
    const attribute = 'data-offcanvas-aside';
    const asideElem = document.getElementById('kt_aside');

    this.renderer.removeAttribute(document.body, attribute);
    this.renderer.removeClass(asideElem, 'aside-on');

    // this.dashboardService.sideBarOverlay$.next(false);
  }

  toggleAside(): void {
    const docBody = document.body;
    const attribute = 'data-offcanvas-aside';

    const isToggled = (docBody.hasAttribute(attribute) &&
      document.body.getAttribute(attribute) === 'on');

    if (isToggled) {
      this.dashboardService.hideMobileSidebar();
    } else {
      this.dashboardService.showMobileSidebar();
    }

  }

  toggleMobileTopBar(): void {
    const isMobileOn = document.body.classList.contains('topbar-mobile-on');

    isMobileOn ?
      this.renderer.removeClass(document.body, 'topbar-mobile-on') :
      this.renderer.addClass(document.body, 'topbar-mobile-on');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
