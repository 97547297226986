<h2 mat-dialog-title>{{ title }}</h2>

<form [formGroup]="supportedDocumentForm" (ngSubmit)="save()">
  <mat-dialog-content>
    <div class="form-group mb-8">
      <lib-messages></lib-messages>
    </div>

    <div class="form-group">
      <label for="name">Name <span class="text-danger">*</span></label>
      <input formControlName="name" class="form-control" id="name" />
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('name')"
      >
        <div *ngIf="formMethods.getControl('name').hasError('required')">
          Required input.
        </div>
      </small>
    </div>

    <div class="form-group">
      <label for="type">Type <span class="text-danger">*</span></label>
      <ng-select formControlName="documentType" id="type">
        <ng-option
          [value]="docType.value"
          *ngFor="let docType of documentTypes"
          >{{ docType.description }}</ng-option
        >
      </ng-select>
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('documentType')"
      >
        <div
          *ngIf="formMethods.getControl('documentType').hasError('required')"
        >
          Required input.
        </div>
      </small>
    </div>

    <div class="form-group">
      <label for="description"
        >Description <span class="text-danger">*</span></label
      >
      <input
        formControlName="description"
        class="form-control"
        id="description"
      />
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('description')"
      >
        <div *ngIf="formMethods.getControl('description').hasError('required')">
          Required input.
        </div>
      </small>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    mat-dialog-actions
    class="d-flex align-items-center justify-content-between"
  >
    <button type="button" class="btn btn-secondary" (click)="close()">
      Close
    </button>
    <button type="submit" class="btn btn-primary">Save</button>
  </mat-dialog-actions>
</form>
