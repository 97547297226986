import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ICscsLogin } from '@console/user-managements';
import { CscsSecondLoginComponent } from './cscs-second-login.component';


const CSCS_TOKEN_LOCATION = `cscs-token`;

@Injectable({ providedIn: 'root' })

export class CscsSecondLoginStateService {

  constructor(private dialog: MatDialog) { }
  // tslint:disable-next-line: variable-name

  public get loginDetails(): ICscsLogin | null {
    const details = localStorage.getItem(CSCS_TOKEN_LOCATION) || null;
    return details ? JSON.parse(atob(details)) : null;
  }

  public set loginDetails(value: ICscsLogin) {
    const valueBase64 = btoa(JSON.stringify(value));
    localStorage.setItem(CSCS_TOKEN_LOCATION, valueBase64);
  }

  public get isLoggedIn(): boolean {
    return (
      this.loginDetails &&
      !!this.loginDetails?.cscsUsername &&
      !!this.loginDetails?.cscsPassword
    );
  }

  public openModal(callback: (success: boolean) => void): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';

    const dialogRef = this.dialog.open(CscsSecondLoginComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(
        (result: boolean) => {
          !!callback && callback(result);
        });
  }

  public deleteLoginDetails(): void {
    localStorage.removeItem(CSCS_TOKEN_LOCATION);
  }
}

