<div class="d-flex flex-column-fluid">
  <div class="container" *ngIf="pay$ | async as payment">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-toolbar w-100 justify-content-between">
          <div>
            <button
              class="btn btn-success mr-3"
              (click)="approve(payment?.paymentReference)"
              [disabled]="loading$ | async"
              *ngIf="!payment.authorizationStatus && canApprovePayment$ | async">
              <i [ngClass]="(loading$ | async)  && currentClicked ==='APPROVE' ? 'fas fa-spinner fa-spin': 'fas fa-check'"></i> Approve
            </button>

            <button
              class="btn btn-primary mr-3"
              (click)="retry(payment?.paymentReference)"
              [disabled]="loading$ | async"
              *ngIf="payment.status !== 'SUCCESSFUL' && canApprovePayment$ | async">
              <i [ngClass]="(loading$ | async) && currentClicked ==='RETRY' ? 'fas fa-spinner fa-spin': 'fas fa-redo'"></i> Retry
            </button>

            <button
              class="btn btn-primary mr-3"
              (click)="requery(payment?.paymentReference, payment?.id)"
              [disabled]="loading$ | async"
              *ngIf="(['PENDING', 'FAILED'].includes(payment.status)) && 
                     payment.currencyCode === 'NGN' && 
                     canApprovePayment$ | async">
              <i [ngClass]="(loading$ | async) && currentClicked ==='REQUERY' ? 'fas fa-spinner fa-spin': 'fas fa-sync-alt'"></i> Re-query
            </button>


            <button
              class="btn btn-success mr-3"
              (click)="confirm(payment?.paymentReference, payment?.id)"
              [disabled]="loading$ | async"

              *ngIf="payment.status === 'PENDING' && 
                     payment.currencyCode === 'NGN' && 
                     canApprovePayment$ | async">
              <i [ngClass]="(loading$ | async) && currentClicked ==='CONFIRM' ? 'fas fa-spinner fa-spin': 'fas fa-check'"></i> Confirm
            </button>
          </div>

          <button
            (click)="back()"
            class="btn btn-transparent border">
            <i class="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div class="card-body">
        <lib-messages></lib-messages>
        <div class="row">
          <div class="col-12">
            <div class="details-pane">
              <p class="details-pane__content">
                <span>User:</span>
                <strong><a (click)="goToUserProfile()" class="pretty-link">{{ payment?.fullName || "-" }}</a></strong>
              </p>

              <p class="details-pane__content">
                <span>Email:</span>
                <strong>{{ payment?.email || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Processor:</span>
                <strong>{{ payment?.processor || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Channel:</span>
                <strong>{{ payment?.channel || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Product Type:</span>
                <strong>{{ payment?.productType || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Reference:</span>
                <strong>{{ payment?.paymentReference || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Source:</span>
                <strong>{{ payment?.source || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Amount:</span>
                <strong>{{payment?.amount | currency: payment?.currencyCode}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Fee:</span>
                <strong>{{payment?.fee | currency: payment?.currencyCode}}</strong>
              </p>

              <p class="details-pane__content">
                <span>Date:</span>
                <strong>{{ payment.dateCreated | date: "medium" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Status:</span>
                <strong [ngClass]="'text-'+getStatusColor(payment.status)">
                  <span
                    [ngClass]="'label-'+getStatusColor(payment.status)"
                    class="label label-dot mr-2"></span> {{ payment.status || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Card:</span>
                <strong>{{ payment.maskedCardPan || "-" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Description:</span>
                <strong>{{ payment.responseDescription || "-" }}</strong>
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
