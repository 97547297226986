import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InstrumentModel } from 'projects/view/src/lib/instrument/models/instrument.interface';

@Component({
  selector: 'app-instrument-listing',
  templateUrl: './instrument-listing.component.html',
  styleUrls: ['./instrument-listing.component.scss']
})
export class InstrumentListingComponent implements OnInit {

  @Input() instruments: InstrumentModel[] = [];
  @Output() onSearch = new EventEmitter();
  @Output() trade = new EventEmitter();

  constructor() { }

  search($event: Event) {
    const input: HTMLInputElement = $event.target as any;
    this.onSearch.emit({ q: input.value });
  }

  ngOnInit() {
  }

}
