import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { BlogContent } from './../../services/blog.model';

@Component({
  selector: 'kt-content-blog-grid',
  templateUrl: './blog-grid.component.html',
  styleUrls: ['./blog-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogGridComponent implements OnInit {
  @Input() posts: BlogContent[] = [];

  @Output() postClick = new EventEmitter<BlogContent>();

  constructor() { }

  dynamicBtnStyle(index: number) {
    const reminder = index / 3;
    if (reminder === 0) return "btn-success";
    if (reminder === 0) return "btn-danger";
    return "btn-brand";
  }

  ngOnInit() {
    // console.log(this.posts);
  }

}
