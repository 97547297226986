<div class="row">
  <ngx-spinner [color]="'#ffffff'" [name]="nip.spinnerName" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <!-- <p class="loading"> {{ (nip.state | async).message }} </p> -->
  </ngx-spinner>
  <div class="col-12">
    <form [formGroup]="fundForm">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="amount">Amount</label>
              <input
                id="amount"
                class="form-control"
                type="number"
                placeholder="Amount"
                formControlName="amount" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('amount')">
                <ng-container [ngSwitch]="true">
                  <div *ngSwitchCase="formMethods.getControl('amount').hasError('required')">
                    Amount is required
                  </div>
                  <div *ngSwitchCase="formMethods.getControl('amount').hasError('shouldBeGreaterThanZero')">
                    Amount must be greater than zero
                  </div>
                </ng-container>
              </small>
            </div>

            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="currency">Currency</label>
              <select
                formControlName="currency"
                class="form-control"
                id="currency">
                <option
                  [value]="currency.value"
                  *ngFor="let currency of currencyList">
                  {{currency.description}}
                </option>
              </select>
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('currency')">
                Currency is required
              </small>
            </div>

            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="payment-method">Payment Method</label>
              <select disabled value="nip" class="form-control" id="payment-method">
                <option value="nip">NIP</option>
              </select>
            </div>

            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="description">Description</label>
              <input
                id="description"
                type="text"
                class="form-control"
                formControlName="description" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('description')">
                Description is required
              </small>
            </div>
            <div
              class="col-12 kt-margin-bottom-20 kt-margin-top-20"
              *ngIf="(nip.state | async).reference">
              <p>
                Payment Reference is
                <strong class="kt-font-success">{{
                  (nip.state | async).reference
                }}</strong>
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4 kt-margin-bottom-20-mobile">
              <ng-container
                *ngTemplateOutlet="
                  (nip.state | async).reference ? pay : generate
                ">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<ng-template #generate>
  <div (mouseenter)="onEnterReference($event)">
    <button
      href="javascript:;"
      class="btn btn-success w-100"
      color="success"
      (click)="generateNipRef(fundForm.value)"
      [disabled]="!fundForm.valid">
      Generate Reference
    </button>
  </div>

</ng-template>

<ng-template #pay>
  <button
    href="javascript:;"
    class="btn btn-primary w-100"
    color="primary"
    (click)="createNip(fundForm.value)"
    [disabled]="!fundForm.valid">
    Fund Account
  </button>
</ng-template>
