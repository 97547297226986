import { OnDestroy } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { KYCData } from '../../kyc.interface';
import { PageEvent } from '@angular/material/paginator';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { of, Subject } from 'rxjs';
import { MatMultiSort } from 'ngx-mat-multi-sort';
import { KYCListState, KYCListStateService } from '../../kyc-list-state';
import { MatTableDataSource } from '@angular/material/table';
import { exportAndDownload, fileNameFormatter } from 'projects/_shared/csv-downloader/csv-downloader';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthProfileState, AuthProfileStateService } from '@console/authentication/profile-state';
import { KYC_STATUS_ENUM } from '../../enums/kyc-status.enum';
import { MerchantListState, MerchantListStateService } from 'projects/view/src/lib/merchants/merchant-list/merchant-list.state';
import { MerchantsModel } from 'projects/view/src/lib/merchants/merchants.interface';
import { ConsoleRouterService } from '@console/shared';
@Component({
  selector: 'app-kyc-list-table',
  templateUrl: './kyc-list-table.component.html',
  styleUrls: ['./kyc-list-table.component.scss']
})
export class KycListTableComponent implements OnInit, OnDestroy {

  @Input() authUsers = false;

  /**
   * total amount of data being returned
   */
  count = 0;

  loading = false;

  /**
   * Query emitter to parent component
   */
  @Output() query = new EventEmitter<object>();

  /**
   * emit query selected from the table
   */
  @Output() batchKyc = new EventEmitter<KYCData[]>();


  kycStatus: { name: string, value: string }[] = [
    { name: 'ALL', value: '' },
    { name: 'UPLOADED DOCUMENTS', value: KYC_STATUS_ENUM.UPLOADED_DOCUMENTS },
    { name: 'VERIFIED', value: KYC_STATUS_ENUM.VERIFIED },
    { name: 'STARTED', value: KYC_STATUS_ENUM.STARTED },
    { name: 'PENDING', value: KYC_STATUS_ENUM.PENDING },
    { name: 'PENDING REVALIDATION', value: KYC_STATUS_ENUM.PENDING_REVALIDATION },
    { name: 'PENDING APPROVAL', value: KYC_STATUS_ENUM.PENDING_APPROVAL },
    { name: 'REJECTED PHOTO ID', value: KYC_STATUS_ENUM.REJECTED_PHOTO_ID },
    { name: 'REJECTED ADDRESS PROOF', value: KYC_STATUS_ENUM.REJECTED_ADDRESS_PROOF },
    { name: 'FAILED CSCS TOKEN', value: KYC_STATUS_ENUM.FAILED_CSCS_TOKEN },
    { name: 'SUBMITTED', value: KYC_STATUS_ENUM.SUBMITTED }
  ];

  // kycStatus: { name: string, value: string }[] = [
  //   { name: 'ALL', value: '' },
  //   { name: 'PENDING', value: 'PENDING' },
  //   { name: 'PENDING REVALIDATION', value: 'PENDING_REVALIDATION' },
  //   { name: 'PENDING APPROVAL', value: 'PENDING_APPROVAL' },
  //   { name: 'STARTED', value: 'STARTED' },
  //   { name: 'UPLOADED DOCUMENTS', value: 'UPLOADED_DOCUMENTS' },
  //   { name: 'REJECTED PHOTO ID', value: 'REJECTED_PHOTO_ID' },
  //   { name: 'REJECTED ADDRESS PROOF', value: 'REJECTED_ADDRESS_PROOF' },
  //   { name: 'FAILED CSCS TOKEN', value: 'FAILED_CSCS_TOKEN' },
  //   { name: 'SUBMITTED', value: 'SUBMITTED' },
  //   { name: 'VERIFIED', value: 'VERIFIED' }
  // ];

  kycCountry: { name: string, value: string }[] = [
    { name: 'ALL', value: '' },
    { name: 'NGA', value: 'NGA' },
    { name: 'US', value: 'US' },
  ];


  checked: Record<string, boolean> = {};

  // from here
  searchForm: FormGroup;

  displayedColumns: string[] = [];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  private filter: Record<string, any> = {};

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[],
    loading: boolean,
    total: number,
    pageSize: number,
    pageNumber: number
  } = {
      list: [],
      loading: false,
      total: 0,
      pageSize: 1000,
      pageNumber: 0
    };

  constructor(
    public kycState: KYCListStateService,
    public modal: NgxSmartModalService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService,
    private consoleRouter: ConsoleRouterService,
  ) { }

  ngOnInit(): void {
    this.createForm();

    this.toggleDisplayColumns();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  listenOnAuthProfile(): void {
    this.authProfile.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: AuthProfileState) => {
          this.isSuperAdmin = state?.authProfile?.superAdmin;

          if (this.isSuperAdmin) {
            this.merchantListState.find({
              pageSize: this.merchants.pageSize,
              pageNumber: this.merchants.pageNumber
            });
          }
        }
      );
  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe(
        (state: MerchantListState) => {
          this.merchants.loading = state?.loading;
          this.merchants.total = state?.count;
          this.merchants.list = [...this.merchants.list, ...state?.merchants];
        }
      );
  }

  private toggleDisplayColumns(): void {
    if (!this.authUsers) {
      this.displayedColumns = [
        'check',
        'fullName',
        'email',
        'phoneNumber',
        'country',
        'status',
        'dateCreated',
        'dateModified'
      ];
    } else {
      this.displayedColumns = [
        'fullName',
        'email',
        'phoneNumber',
        'chakaId'
      ];

      this.authProfile.isSuperAdmin$
        .pipe(
          takeUntil(this.componentDestroyed$),
          filter((isSuperAdmin) => isSuperAdmin)
        )
        .subscribe((isSuperAdmin) => {
          if (isSuperAdmin) {
            this.displayedColumns = [...this.displayedColumns, 'action'];
          }
        });
    }
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      country: new FormControl(''),
      status: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(''),
      isHvu: new FormControl(false)
    });

    this.authUsers ?
      this.searchForm.addControl('q', new FormControl('')) :
      this.searchForm.addControl('search', new FormControl(''));
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues)
          };

          if (this.filter.hasOwnProperty('isHvu') && !this.filter?.isHvu) {
            delete this.filter.isHvu;
          }

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    this.kycState.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: KYCListState) => {
          this.dataSource = new MatTableDataSource<KYCData>(state.data);
          this.dataSource.sort = this.sort;
          this.count = state.count;
          this.loading = state.loading;
        }
      );
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = ++$event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.query.emit(this.filter);
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  formedUniqueId({ userId, country }): string {
    return userId + '-' + country;
  }

  get allSelected(): boolean {
    return this.dataSource.data.every((user) => this.selectedItems.has(this.formedUniqueId(user)));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach(user => {
      this.updateCheckedSet(checked, user);
    });

    this.batchKyc.emit(this.selectedUsers);
  }

  onItemChecked(checked: boolean, user: KYCData): void {
    this.updateCheckedSet(checked, user);

    this.batchKyc.emit(this.selectedUsers);
  }

  updateCheckedSet(checked: boolean, user: KYCData): void {
    checked ? this.selectedItems.set(this.formedUniqueId(user), user) : this.selectedItems.delete(this.formedUniqueId(user));
  }

  get selectedUsers(): KYCData[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Users',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedUsers);
  }

  toggleAccountLock(accountLocked: boolean | null, userId: string): void {

    const check = confirm(`Do you want to ${accountLocked ? 'unlock' : 'lock'} this account?`);

    if (check) {
      this.kycState.toggleAccountLock(userId, !accountLocked, this.filter);
    }
  }

  loadMoreMerchant(): void {
    // will be implemented later
    return;
    // console.log('scroll to end');
    if (this.merchants.total > (this.merchants.pageSize * this.merchants.pageNumber + 1)) {
      console.log('got here');
      return;
    }

    this.merchantListState.find({
      pageSize: this.merchants.pageSize,
      pageNumber: ++this.merchants.pageNumber
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

}
