import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { fileNameFormatter, exportAndDownload } from 'projects/_shared/csv-downloader/csv-downloader';
import { skip, first } from 'rxjs/operators';
import { ConsoleRouterService } from '../../../../../shared/src/public-api';
import { OrderTxnsModel } from '../services/orders.model';
import { OrderListStateService } from './orders-state';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit, OnDestroy {
  @Input() userId: string;

  filter: Record<string, any> = {
    pageSize: 10,
  };

  orderId: number;

  lastQuery: Record<string, any> = {};

  constructor(
    public console: ConsoleRouterService,
    public orderList: OrderListStateService,
    public role: AuthRoleStateService
  ) { }

  viewOrder(txn: OrderTxnsModel) {
    // if (this.userId) {
    //   this.orderId = txn.id as any;
    //   // return this.modal.open('orderModal');
    // }
    this.console.productNavigate(['orders', txn.id]);
  }

  query($event: Record<string, any>) {
    if (this.userId) {
      $event.userId = this.userId;
    }
    this.orderList.find($event);
  }

  downloadFile($event: Record<string, any>): void {
    this.query({ ...$event, pageSize: 50000000 });
    this.orderList.state.pipe(skip(1), first()).subscribe((state) => {
      const name = fileNameFormatter(
        this.userId || 'Orders',
        $event.from,
        $event.to
      );
      exportAndDownload(name, state.orders);
    });
  }

  ngOnInit(): void {
    this.role.hasPermission$('ORDER_VIEW')
      .subscribe((authorized) => {
        if (!authorized) {
          this.console.productNavigate(['users']);
        }
        this.query(this.filter);
      });
  }

  ngOnDestroy(): void {
    this.orderList.reset();
  }
}
