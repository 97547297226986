import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatMultiSortModule } from 'ngx-mat-multi-sort';
import { LoadingComponent } from './components/loading/loading.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { CleanPhoneNumberDirective } from './directive/clean-phone-number.directive';
import { DefaultImageDirective } from './directive/default-image.directive';
import { TrimmedInputDirective } from './directive/trimmed-input.directive';
import { DecimalToPercentPipe } from './pipes/decimai-to-percent-pipe';
import { FormatPhoneNumberPipe } from './pipes/format-phone-number-pipe';
import { PrettyJsonPipe } from './pipes/pretty-json';
import { ReplaceStringPipe } from './pipes/replace-string';
import { SafePipe } from './pipes/safe-url.pipe';
import { TruncateWordsPipe } from './pipes/truncate-words.pipe';
import { ConsoleRouterService } from './services/console-router.service';
import { SidenavService } from './services/sidenav.service';

@NgModule({
  declarations: [
    TrimmedInputDirective,
    CleanPhoneNumberDirective,
    LoadingComponent,
    MessagesComponent,
    SafePipe,
    TruncateWordsPipe,
    FormatPhoneNumberPipe,
    NoDataComponent,
    ReplaceStringPipe,
    DecimalToPercentPipe,
    PrettyJsonPipe,
    DefaultImageDirective,
  ],
  providers: [ConsoleRouterService, SidenavService],
  imports: [
    CommonModule,
    // start angular material
    MatProgressSpinnerModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatStepperModule,
    MatPaginatorModule,
    MatMultiSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatTooltipModule,
    // end angular material
    NgSelectModule,
  ],
  exports: [
    LoadingComponent,
    MessagesComponent,
    TrimmedInputDirective,
    CleanPhoneNumberDirective,
    // start angular material
    MatProgressSpinnerModule,
    MatIconModule,
    MatSidenavModule,
    MatDialogModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatStepperModule,
    MatPaginatorModule,
    MatMultiSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSortModule,
    MatTooltipModule,
    // end angular material
    NgSelectModule,
    SafePipe,
    TruncateWordsPipe,
    FormatPhoneNumberPipe,
    NoDataComponent,
    ReplaceStringPipe,
    DecimalToPercentPipe,
    PrettyJsonPipe,
    DefaultImageDirective,
  ],
})
export class SharedModule {}
