import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '@console/api';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BankSettingService } from '../services/banks.service';
import { BankSetting } from '../services/settings.interface';

export interface BankState {
  banks: BankSetting[];
  loading: boolean;
  error?: string;
}

const initalState: BankState = {
  loading: false,
  banks: [],
};

@Injectable({ providedIn: 'root' })
export class BankListStateService {
  state = new BehaviorSubject<BankState>(initalState);

  constructor(private banks: BankSettingService) {}

  find(query: Record<string, any> = {}) {
    this.loading();
    this.banks.getBankSettings(query).subscribe({
      next: this.onBankLoaded.bind(this),
      error: this.onBankError.bind(this),
    });
  }

  reset() {
    this.state.next(initalState);
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading() {
    this.state.next({ ...this.state.getValue(), loading: true });
  }

  private onBankLoaded({ data }: ReqSuccessResponse<BankSetting[]>) {
    this.state.next({
      banks: data,
      loading: false,
    });
  }

  private onBankError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
    });
  }
}
