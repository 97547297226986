<div *ngIf="instrument" class="market-info-container">
  <h3 class="content-title">Market Statistics </h3>
  <p class="range-infos">
    <span class="range-info"> Day Range </span>
    <span class="range-info value">
      {{ dayLowRange}}
    </span>
    <span class="range-info">
      <svg width="46" height="5" viewBox="0 0 46 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.559814" width="44.5238" height="0.999999" rx="0.499999" fill="#C4C4C4" fill-opacity="0.35" />
      </svg>
    </span>
    <span class="range-info value">
      {{ dayHighRange}}

    </span>
  </p>
  <p class="range-infos">
    <span class="range-info"> 52 Weeks Range </span>
    <span class="range-info value">
      {{ yearLowRange}}

    </span>
    <span class="range-info">
      <svg width="46" height="5" viewBox="0 0 46 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.559814" width="44.5238" height="0.999999" rx="0.499999" fill="#C4C4C4" fill-opacity="0.35" />
      </svg>
    </span>
    <span class="range-info value">
      {{ yearHighRange}}

    </span>
  </p>
  <p class="market-infos">
    <span class="market-info-label"> Close </span>
    <span class="market-info-value value">
      {{ close }}
    </span>
  </p>
  <p class="market-infos">
    <span class="market-info-label"> Open </span>
    <span class="market-info-value value">
      {{ open }}
    </span>
  </p>
</div>
