<div class="container-fluid container-fluid-margin">
  <div class="card shadow mb-4">
    <div class="card-header bg-light py-3">
      <button  [class]="'float-right btn btn-success' "> {{'Portfolio ' + (currency === 'USD' ? 'Global': 'Local')  + ' Account Summary' }} </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 table-responsive">
          <p *ngIf="!portfolio" class="text-center">
            Can't Retrieve {{ (currency === 'USD' ? 'Global': 'Local') }} portfolio for user
          </p>
          <table class="table table-hover">
            <thead class="border-top-light">
              <tr style="border-top: 0px !important">
                <th scope="col" class="table-title">Field</th>
                <th scope="col" class="table-title">Value</th>
              </tr>
            </thead>
            <tbody *ngIf="portfolio">
              <tr>
                <td class="table-text">Account Id</td>
                <td class="table-text">
                  {{ portfolio?.accountID }}
                </td>
              </tr>
              <tr>
                <td class="table-text">Account No</td>
                <td class="table-text">
                  {{ portfolio?.accountNo }}
                </td>
              </tr>
              <tr>
                <td class="table-text">Equity Value</td>
                <td class="table-text">
                  {{ formatToCurrency(currency, portfolio?.equity?.equityValue || 0) }}
                </td>
              </tr>
              <tr>
                <td class="table-text">Available to trade</td>
                <td class="table-text">
                  {{ formatToCurrency(currency, portfolio?.cash?.cashAvailableForTrade || 0) }}
                </td>
              </tr>
              <tr>
                <td class="table-text">Available to Withdraw</td>
                <td class="table-text">
                  {{
                    formatToCurrency(currency, portfolio?.cash?.cashAvailableForWithdrawal)
                  }}
                </td>
              </tr>
              <tr>
                <td class="table-text">Cash Balance</td>
                <td class="table-text">
                  {{ formatToCurrency(currency, portfolio?.cash?.cashBalance) }}
                </td>
              </tr>
              <tr *ngFor="let settlement of portfolio?.cash?.cashSettlement || []">
                <td class="table-text"> Cash Settlement At {{ settlement.utcTime  | date: 'medium' }} </td>
                <td class="table-text">
                  {{ formatToCurrency(currency, settlement.cash) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
