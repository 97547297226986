<mat-dialog-content class="content" *ngIf="resourceUrl">
  <iframe
    #iframeDiv
    [src]="resourceUrl"
    class="w-100"
    height="700px"
    *ngIf="isPdf else showImage"
    [title]="fileName"></iframe>

  <ng-template #showImage>
    <img [src]="resourceUrl" class="w-100" [alt]="fileName">
  </ng-template>


  <div class="text-center mt-3">
    <a class="text-center text-danger" href="javascript:;" mat-flat-button (click)="close()">Close</a>
  </div>

</mat-dialog-content>
