import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { LearnContent } from "./../../services/learn.model";

@Component({
  selector: "kt-learn-content-grid",
  templateUrl: "./learn-content-grid.component.html",
  styleUrls: ["./learn-content-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnContentGridComponent implements OnInit {
  @Input() learns: LearnContent[] = [];

  @Output() learnClick = new EventEmitter<LearnContent>();

  constructor() { }

  ngOnInit() { }
}
