import { ToastrService } from 'ngx-toastr';
import { ConsoleRouterService } from '@console/shared';
import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList, ReqSuccessResponse } from '../../../../../api/src/public-api';
import { 	ExchangeRateModel} from '../../transactions/services/transactions.model';
import { WalletTxnService } from '../../transactions/services/transactions.service';
import { BehaviorSubject } from 'rxjs';
import { first, map, timeout } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";


export interface ExchangerateState {
  loading: boolean;
  count?: number;
  exchangerate?: ExchangeRateModel;
  error?: string;
  message?: string;
}

const initialState: ExchangerateState = {
  loading: false,

};

@Injectable({ providedIn: 'root' })
export class ExchangeRateStateService {
  state = new BehaviorSubject<ExchangerateState>(initialState);

  spinnerName = 'Exchange_rate_service';

  constructor(private exchangerateSvc: WalletTxnService,
    private spinner: NgxSpinnerService,
    private console:ConsoleRouterService,
    private toastr: ToastrService) { }

  findById(id: number) {
    this.loading();
    this.exchangerateSvc
      .getRateById(id)
      .pipe(first())
      .subscribe({
        next: this.updateExchangerates.bind(this),
        error: this.onError.bind(this),
      });
  }

  create(buy: number, sell:number) {
    this.loading();
    this.exchangerateSvc
      .createRate({buy,sell})
      .pipe(first())
      .subscribe({
        next: this.updateSavedExchangerates.bind(this),
        error: this.onError.bind(this),
      });
  }


  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateExchangerates(data: ReqSuccessResponse<ExchangeRateModel>) {
    this.state.next({
      loading: false,
      exchangerate: data.data,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private updateSavedExchangerates(data: ReqSuccessResponse<ExchangeRateModel>) {
    this.state.next({
      loading: false,
      exchangerate: data.data,
      message:"Rates Created Successfully",

    });
    this.toastr.success('Rates Created Successfully','Success',{
      positionClass: 'toast-bottom-center',
      timeOut:3000
    })
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message:"An Error occurred"
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading..."
    });
  }
}
