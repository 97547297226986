import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PageEvent } from '../../../../../shared/src/lib/interface';
import { ConsoleRouterService } from '../../../../../shared/src/public-api';
import { PaymentTxnsModel } from '../services/payment.model';
import { PaymentListStateService } from './payment-state';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { first, skip } from 'rxjs/operators';
import { PaymentStateService } from '../payments-edit/payments-edit-state';
import {
  exportAndDownload,
  fileNameFormatter,
} from 'projects/_shared/csv-downloader/csv-downloader';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payment.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentComponent implements OnInit {
  @Input() userId: string;

  selectedPayments: PaymentTxnsModel[] = [];

  filter: Record<string, any> = {
    pageSize: 10,
  };

  lastQuery = {};

  paymentId: number;

  constructor(
    private role: AuthRoleStateService,
    private consoleRouter: ConsoleRouterService,
    public paymentList: PaymentListStateService,
    public paymentState: PaymentStateService,
    private modal: NgxSmartModalService
  ) {}

  batchApprove(items: PaymentTxnsModel[]): void {
    items
      .filter((i) => !i.status)
      .forEach((t) => {
        // this.paymentState.approve(t.paymentReference);
      });
  }

  deletePayments(items: PaymentTxnsModel[]): void {
    const paymentIds = items.map(({ id }) => id);
    this.paymentState.delete(paymentIds, this.lastQuery);
  }

  viewPayment(id: number) {
    // if (this.userId) {
    //   this.paymentId = id;
    //   this.modal.open('paymentModal');
    //   return
    // }
    this.consoleRouter.productNavigate(['payments', id]);
  }

  paginate(page: PageEvent) {
    const filter = this.filter;
    this.query({ ...filter, ...page });
  }

  query($event: Record<string, any>) {
    if (this.userId) {
      $event.userId = this.userId;
    }
    // this.lastQuery = $event;
    this.paymentList.find($event);
  }

  downloadFile($event: Record<string, any>) {
    this.query({ ...$event, pageSize: 50000000 });
    this.paymentList.state.pipe(skip(1), first()).subscribe((state) => {
      const name = fileNameFormatter(
        this.userId || 'payments',
        $event.from,
        $event.to
      );
      exportAndDownload(name, state.payments);
    });
  }

  private loadIntialPayments() {
    if (this.userId) {
      this.paymentList.reset();
    }
    this.query(this.filter);
  }

  ngOnInit(): void {
    if (this.userId) {
      this.paymentList.reset();
    }

    this.role.hasPermission$('PAYMENT_VIEW').subscribe((authorized) => {
      if (!authorized) {
        return this.consoleRouter.productNavigate(['']);
      }
      // this.loadIntialPayments();
      this.query(this.filter);
    });
  }
}
