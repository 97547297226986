import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { LoadingService } from '../../../../../shared/src/lib/components/loading/loading.service';

import { IAutoRebalance } from '../interface/IAutoRebalance';

const AUTO_RABALANCE_URL = '/api/v1/autopilot';

@Injectable()
export class AutoRebalanceService {

  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) { }

  fetchAutoRebalance(searchParams?): Observable<PaginatedList<IAutoRebalance>> {
    const autoRebalance$ = this.http.get<PaginatedList<IAutoRebalance>>(
      AUTO_RABALANCE_URL,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(autoRebalance$);
  }

  fetchAutoRebalanceById(id: string): Observable<ReqSuccessResponse<IAutoRebalance>> {
    const autoRebalance$ = this.http.get<ReqSuccessResponse<IAutoRebalance>>(
      `${AUTO_RABALANCE_URL}/${id}`
    );

    return this.loadingService.showLoaderUntilCompleted(autoRebalance$);
  }

  requeryAutoRebalanceById(id: string): Observable<ReqSuccessResponse<IAutoRebalance>> {
    return this.http.get<ReqSuccessResponse<IAutoRebalance>>(
      `${AUTO_RABALANCE_URL}/status/${id}`
    );
  }

  saveRebalance(dataToSubmit): Observable<ReqSuccessResponse<IAutoRebalance>> {
    return this.http.post<ReqSuccessResponse<IAutoRebalance>>(`${AUTO_RABALANCE_URL}/rebalance`, dataToSubmit);
  }

}
