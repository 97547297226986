import { Injectable } from '@angular/core';
import { Currency } from '@console/shared/_enums/currency.enum';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { LoadingService } from '../../../../../shared/src/lib/components/loading/loading.service';
import { IAdvisoryPortfolio } from '../interfaces/IAdvisoryPortfolio';

const ADVISORY_PORTFOLIO_URL = '/api/v1/portfolios';

@Injectable()
export class AdvisoryPortfolioService {
  constructor(
    private http: EnterpriseAPIService,
    private loadingService: LoadingService
  ) {}

  fetchPortfolios(
    searchParams?
  ): Observable<PaginatedList<IAdvisoryPortfolio>> {
    const redemptions$ = this.http.get<PaginatedList<IAdvisoryPortfolio>>(
      ADVISORY_PORTFOLIO_URL,
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(redemptions$);
  }

  fetchPortfolioById(
    id: string
  ): Observable<ReqSuccessResponse<IAdvisoryPortfolio>> {
    const redemption$ = this.http.get<ReqSuccessResponse<IAdvisoryPortfolio>>(
      `${ADVISORY_PORTFOLIO_URL}/${id}`
    );

    return this.loadingService.showLoaderUntilCompleted(redemption$);
  }

  findUsersPortfolio(
    userId: string,
    currencyCode: Currency
  ): Observable<ReqSuccessResponse<IAdvisoryPortfolio>> {
    const portfolios$ = this.http.get<ReqSuccessResponse<IAdvisoryPortfolio>>(
      `${ADVISORY_PORTFOLIO_URL}/user/${userId}`,
      { currencyCode }
    );

    return this.loadingService.showLoaderUntilCompleted(portfolios$);
  }
}
