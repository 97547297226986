import { TagModel } from './../../services/tags.model';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kt-tag-card',
  templateUrl: './tag-card.component.html',
  styleUrls: ['./tag-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagCardComponent implements OnInit {

  @Input() tag: TagModel;

  @Input() status = "success";

  @Output() btnClick = new EventEmitter<TagModel>();


  constructor() { }

  // tslint:disable-next-line: typedef
  ngOnInit() { }
}
