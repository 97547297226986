<div class="row">
  <div class="col-12">
    <form [formGroup]="fundForm" (ngSubmit)="createNipReversal(fundForm.value)">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="amount">Amount <span *ngIf="formMethods.isRequired('amount')">*</span></label>
              <input
                id="amount"
                class="form-control"
                type="number"
                placeholder="Amount"
                formControlName="amount" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('amount')">
                <ng-container [ngSwitch]="true">
                  <div *ngSwitchCase="formMethods.getControl('amount').hasError('required')">
                    Amount is required
                  </div>
                  <div *ngSwitchCase="formMethods.getControl('amount').hasError('shouldBeGreaterThanZero')">
                    Amount must be greater than zero
                  </div>
                </ng-container>
              </small>
            </div>

            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="currency">Currency <span *ngIf="formMethods.isRequired('currency')">*</span></label>
              <select
                formControlName="currency"
                class="form-control"
                id="currency">
                <option
                  [value]="currency.value"
                  *ngFor="let currency of currencyList">
                  {{currency.description}}
                </option>
              </select>
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('currency')">
                Currency is required
              </small>
            </div>

            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="payment-method">Payment Method</label>
              <select disabled value="reversal" class="form-control" id="payment-method">
                <option value="reversal">REVERSAL</option>
              </select>
            </div>

            <div class="col-lg-6 form-group kt-margin-bottom-20">
              <label for="description">Description <span *ngIf="formMethods.isRequired('description')">*</span></label>
              <input
                id="description"
                type="text"
                class="form-control"
                formControlName="description" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('description')">
                Description is required
              </small>
            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4 kt-margin-bottom-20-mobile">
              <button
                class="btn btn-primary w-100"
                color="primary">
                Reverse Fund
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
