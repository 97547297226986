<div
  class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
  id="kt_aside"
  #sideBar
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <!--begin::Brand-->
  <div class="brand flex-column-auto" id="kt_brand">
    <!--begin::Logo-->

    <a
      *ngIf="(merchant.state | async)?.merchant?.logoUrl as logoUrl"
      href="javascript:;"
      (click)="link.emit(['dashboard'])"
      class="brand-logo"
    >
      <img alt="Logo" width="100px" [src]="logoUrl" />
    </a>
    <!--end::Logo-->
    <!--begin::Toggle-->
    <button
      class="brand-toggle btn btn-sm px-0"
      id="kt_aside_toggle"
      (click)="toggleSidebar()"
    >
      <span class="svg-icon svg-icon svg-icon-xl">
        <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Angle-double-left.svg-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
              fill="#000000"
              fill-rule="nonzero"
              transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"
            ></path>
            <path
              d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
              fill="#000000"
              fill-rule="nonzero"
              opacity="0.3"
              transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"
            ></path>
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
    </button>
    <!--end::Toolbar-->
  </div>
  <!--end::Brand-->
  <!--begin::Aside Menu-->
  <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
    <!--begin::Menu Container-->
    <div id="kt_aside_menu" class="aside-menu my-4">
      <!--begin::Menu Nav-->
      <ul class="menu-nav">
        <li
          class="menu-item menu-item-active"
          [ngClass]="{ active: showActiveClass('dashboard') }"
        >
          <a (click)="link.emit(['dashboard'])" class="menu-link">
            <span class="svg-icon svg-icon-light menu-icon">
              <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Design/Layers.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                    fill="#000000"
                    opacity="0.3"
                  ></path>
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
            <span class="menu-text">Dashboard</span>
          </a>
        </li>

        <li class="menu-section">
          <h4 class="menu-text">USER MANAGEMENT</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('USER_VIEW') | async"
          [ngClass]="{ active: showActiveClass('users') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['users'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-users"></i>
            </span>
            <span class="menu-text">User</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('KYC_VIEW') | async"
          [ngClass]="{ active: showActiveClass('kyc') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['kyc'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-users-cog"></i>
            </span>
            <span class="menu-text">KYC</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('KYC_VIEW') | async"
          [ngClass]="{ active: showActiveClass('client-list') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['client-list'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-users-cog"></i>
            </span>
            <span class="menu-text">Auth Users</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('cscs-report') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="cscs-report"
          >
            <span class="menu-icon">
              <i class="fas fa-users-cog"></i>
            </span>
            <span class="menu-text">CSCS Report</span>
          </a>
        </li>

        <li class="menu-section" *ngIf="isDisabledIn(['advisory'])">
          <h4 class="menu-text">Instruments</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="isDisabledIn(['advisory'])"
          [ngClass]="{ active: showActiveClass('instruments') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['instruments'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-chart-bar"></i>
            </span>
            <span class="menu-text">Instruments</span>
          </a>
        </li>

        <li class="menu-section">
          <h4 class="menu-text">Transaction Management</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('PAYMENT_VIEW') | async"
          [ngClass]="{ active: showActiveClass('payments') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['payments'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-clipboard-list"></i>
            </span>
            <span class="menu-text">Payments</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('RECONCILLATION_VIEW') | async"
          [ngClass]="{ active: showActiveClass('reconcillations') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['reconcillations'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-file-signature"></i>
            </span>
            <span class="menu-text">Reconciliations</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('WALLET_VIEW') | async"
          [ngClass]="{ active: showActiveClass('transaction') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['transaction'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-money-check"></i>
            </span>
            <span class="menu-text">Transactions</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            (role.hasPermission$('EXCHANGE_VIEW') | async) &&
            (this.merchant.state | async)?.merchant?.clientId ===
              'chaka-service'
          "
          [ngClass]="{ active: showActiveClass('exchanges') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['exchanges'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-money-check-alt"></i>
            </span>
            <span class="menu-text">Exchanges</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            (role.hasPermission$('ORDER_VIEW') | async) &&
            isDisabledIn(['advisory'])
          "
          [ngClass]="{ active: showActiveClass('orders') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['orders'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-shopping-cart"></i>
            </span>
            <span class="menu-text">Orders</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            (role.hasPermission$('WITHDRAWAL_VIEW') | async) &&
            (this.merchant.state | async)?.merchant?.clientId ===
              'chaka-service'
          "
          [ngClass]="{ active: showActiveClass('withdrawals') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['withdrawals'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-shopping-cart"></i>
            </span>
            <span class="menu-text">Withdrawals</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            (role.hasPermission$('WALLET_VIEW') | async) &&
            (this.merchant.state | async)?.merchant?.clientId ===
              'chaka-service'
          "
          [ngClass]="{ active: showActiveClass('dw-history') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            (click)="link.emit(['dw-history'])"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-money-check"></i>
            </span>
            <span class="menu-text">DW Wallet History</span>
          </a>
        </li>

        <li
          *ngIf="isDisabledIn(['equity'])"
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('redemption') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="redemptions"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Redemptions</span>
          </a>
        </li>

        <li
          *ngIf="isDisabledIn(['equity'])"
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('auto-rebalance') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="auto-rebalance"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Auto Re-balance</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('mandate') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="mandate"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Mandate</span>
          </a>
        </li>

        <li
          *ngIf="isDisabledIn(['equity'])"
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('portfolios') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="portfolios"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Portfolios</span>
          </a>
        </li>

        <li
          class="menu-section"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('CONTENT_VIEW') | async)
          "
        >
          <h4 class="menu-text">Content Management</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('CONTENT_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('news') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="news"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-newspaper"></i>
            </span>
            <span class="menu-text">News</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('CONTENT_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('collection') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="collection"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-layer-group"></i>
            </span>
            <span class="menu-text">Collections</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('CONTENT_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('learn') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="learn"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-graduation-cap"></i>
            </span>
            <span class="menu-text">Learn</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('CONTENT_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('blog') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="blog"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-graduation-cap"></i>
            </span>
            <span class="menu-text">Blog</span>
          </a>
        </li>

        <li
          class="menu-section"
          *ngIf="role.hasPermission$('EXCHANGE_RATE_VIEW') | async"
        >
          <h4 class="menu-text">Rates</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('EXCHANGE_RATE_VIEW') | async"
          [ngClass]="{ active: showActiveClass('rates') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="rates"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-chart-line"></i>
            </span>
            <span class="menu-text">Exchange Rates</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('EXCHANGE_RATE_VIEW') | async"
          [ngClass]="{ active: showActiveClass('global-account') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="global-account"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-chart-line"></i>
            </span>
            <span class="menu-text">Global Account</span>
          </a>
        </li>

        <li
          *ngIf="isDisabledIn(['equity'])"
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('suitability-questions') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="suitability-questions"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Suitability Questions</span>
          </a>
        </li>

        <li
          class="menu-section"
          *ngIf="role.hasPermission$('MERCHANT_VIEW') | async"
        >
          <h4 class="menu-text">Merchants</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('MERCHANT_VIEW') | async"
          [ngClass]="{ active: showActiveClass('merchant') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="merchant"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-user-friends"></i>
            </span>
            <span class="menu-text">Merchants</span>
          </a>
        </li>

        <li
          class="menu-section"
          *ngIf="role.hasPermission$('SETTING_VIEW') | async"
        >
          <h4 class="menu-text">Settings</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('SETTING_VIEW') | async"
          [ngClass]="{ active: showActiveClass('settings') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="settings"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-cogs"></i>
            </span>
            <span class="menu-text">Application Settings</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('SETTING_VIEW') | async"
          [ngClass]="{ active: showActiveClass('channels') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="channels"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-cogs"></i>
            </span>
            <span class="menu-text">Channels</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="role.hasPermission$('SETTING_VIEW') | async"
          [ngClass]="{ active: showActiveClass('rejection-email-config') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="rejection-email-config"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-cogs"></i>
            </span>
            <span class="menu-text">Rejection Email Settings</span>
          </a>
        </li>

        <li
          *ngIf="
            (role.hasPermission$('WALLET_VIEW') | async) &&
            (this.merchant.state | async)?.merchant?.clientId ===
              'chaka-service'
          "
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('chaka-accounts') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="chaka-accounts"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Chaka Accounts</span>
          </a>
        </li>

        <li
          *ngIf="
            (role.hasPermission$('WALLET_VIEW') | async) &&
            (this.merchant.state | async)?.merchant?.clientId ===
              'chaka-service'
          "
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('referral-settings') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="referral-settings"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Referral Settings</span>
          </a>
        </li>

        <li
          *ngIf="
            (this.merchant.state | async)?.merchant?.clientId ===
            'chaka-service'
          "
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('account-manager') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="account-manager"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Account Manager</span>
          </a>
        </li>

        <li
          *ngIf="
            (this.merchant.state | async)?.merchant?.clientId ===
            'chaka-service'
          "
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('supported-documents') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="supported-documents"
          >
            <span class="menu-icon">
              <i class="fas fa-coins"></i>
            </span>
            <span class="menu-text">Support Documents</span>
          </a>
        </li>

        <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('ROLE_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('roles') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="roles"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-user"></i>
            </span>
            <span class="menu-text">Roles</span>
          </a>
        </li>

        <hr class="light" />

        <li
          *ngIf="
            (this.merchant.state | async)?.merchant?.clientId ===
            'chaka-service'
          "
          class="menu-item menu-item-submenu"
          [ngClass]="{ active: showActiveClass('audit-log') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="audit-log"
          >
            <span class="menu-icon">
              <i class="fas fa-assistive-listening-systems"></i>
            </span>
            <span class="menu-text">Logs</span>
          </a>
        </li>

        <!-- <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('SETTING_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('mobile-versions') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="mobile-versions"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-cogs"></i>
            </span>
            <span class="menu-text">Mobile Versions</span>
          </a>
        </li> -->

        <!-- <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('SETTING_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('banks') }"
        >
          <a
            href="javascript:;"
            class="menu-link menu-toggle"
            routerLink="banks"
          >
            <span class="menu-icon">
              <i class="fas fa-fw fa-cogs"></i>
            </span>
            <span class="menu-text">Banks</span>
          </a>
        </li> -->

        <!-- <li
          class="menu-section"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('SETTING_VIEW') | async)
          "
        >
          <h4 class="menu-text">Resources</h4>
          <i class="menu-icon fas fa-ellipsis-h icon-md"></i>
        </li> -->

        <!-- <li
          class="menu-item menu-item-submenu"
          *ngIf="
            isDisabledIn(['advisory']) &&
            (role.hasPermission$('SETTING_VIEW') | async)
          "
          [ngClass]="{ active: showActiveClass('holiday-maintenance') }"
        >
          <a href="javascript:;" class="menu-link menu-toggle">
            <span class="menu-icon">
              <i class="fas fa-fw fa-cogs"></i>
            </span>
            <span class="menu-text">Holiday Maintenance</span>
          </a>
        </li> -->
      </ul>
      <!--end::Menu Nav-->
    </div>
    <!--end::Menu Container-->
  </div>
  <!--end::Aside Menu-->
</div>
