<div class="row">
  <div class="col-12">
    <div echarts [options]="chartOption" class="demo-chart"></div>


    <a class="chart-interval" (click)="intervalChanged.emit(item.interval)" *ngFor="let item of intervals" >
      {{ item.label }}
    </a>
  </div>
</div>

<!-- [class]="intervalStyle(item.interval)" -->
