import { ReferralDialogComponent } from './components/referral-dialog/referral-dialog.component';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { IReferralPolicy } from './interface/IReferralPolicy';
import { ReferralService } from './service/referral-service.service';
import { MerchantListState, MerchantListStateService } from '../merchants/merchant-list/merchant-list.state';
import { MerchantsModel } from '../merchants/merchants.interface';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, takeUntil, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  templateUrl: './referral-settings.component.html',
  styleUrls: ['./referral-settings.component.scss'],
  providers: [MerchantListStateService]
})
export class ReferralSettingsComponent implements OnInit, OnDestroy {

  referralPolicies: IReferralPolicy[];
  merchantList: MerchantsModel[];

  componentDestroyed$: Subject<boolean> = new Subject();

  searchForm: FormGroup;

  dataSource = new MatTableDataSource<IReferralPolicy>();

  private filter: Record<string, any> = {};

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  displayedColumns: string[] = [
    'minCount',
    'maxCount',
    'merchantId',
    'refereeAmount',
    'referrerAmount',
    'dateCreated',
    'action'
  ];

  count = 0;

  loading = false;

  constructor(
    private referralService: ReferralService,
    private dialog: MatDialog,
    private messagesService: MessagesService,
    private merchantListState: MerchantListStateService
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.fetchReferralPolicies();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      from: new FormControl(),
      to: new FormControl()
    });

    this.listenOnSearchForm();
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          console.log(formValues);
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues)
          };

          this.fetchReferralPolicies();
        })
      )
      .subscribe();
  }

  clearFilters(): void {
    // this.filter = {};

    this.searchForm.reset();
  }

  fetchReferralPolicies(): void {
    this.loading = true;

    this.referralService
      .fetchReferralPolicies(this.filter)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource<IReferralPolicy>(response.data);
          this.count = response.count;
        }
      });
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.fetchReferralPolicies();
  }

  listenOnMerchants(): void {
    this.merchantListState.find({
      pageSize: 1000
    });

    this.merchantListState.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (state: MerchantListState) => {
          this.merchantList = state?.merchants;
        }
      );
  }


  resolveMerchantName(merchantId: number): string {
    try {
      return this.merchantList.find(({ id }) => id === merchantId).name;
    } catch (error) {
      return '';
    }
  }

  deleteReferralPolicy(referralPolicyId: number): void {

    const check = confirm('Do you want to delete this referral policy?');

    if (!check) {
      return;
    }

    this.referralService
      .deleteReferralPolicy(referralPolicyId)
      .subscribe({
        next: () => {
          //eradicate api call to retrieve referral policy
          this.referralPolicies = this.referralPolicies
            .filter(({ id }) => id === referralPolicyId);
        }
      });
  }

  openDialog(referralPolicy?: IReferralPolicy): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'custom-dialog-container';

    const data: { merchantList: MerchantsModel[], referralPolicy?: IReferralPolicy } = {
      merchantList: this.merchantList
    };

    if (referralPolicy) {
      data.referralPolicy = { ...referralPolicy };
    }

    dialogConfig.data = data;

    this.messagesService.hideAll();

    const dialogRef = this.dialog
      .open(ReferralDialogComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(
        (result: boolean) => {
          result && this.fetchReferralPolicies();
        });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();
  }

}
