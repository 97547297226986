import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { WALLET_TRANSACTION_STATUS } from '../../../../../shared/src/public-api';
import { ConsoleRouterService } from './../../../../../shared/src/lib/services/console-router.service';
import { PaymentStateService } from './payments-edit-state';
import { AuthRoleStateService } from '@console/authentication/auth-permission';
import { Observable } from 'rxjs';
import { PaymentOtpModalService } from './components/payment-otp-modal/payment-otp-modal.service';

@Component({
  selector: 'app-payments-edit',
  templateUrl: './payments-edit.component.html',
  styleUrls: ['./payments-edit.component.scss'],
})
export class PaymentsEditComponent implements OnInit {
  @Input() paymentId: number;

  initialId: number;

  pageTitle = 'View Payment';

  currentClicked = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public paymentState: PaymentStateService,
    private consoleRouter: ConsoleRouterService,
    private role: AuthRoleStateService,
    private paymentOtpModal: PaymentOtpModalService
  ) {}

  get pay$() {
    return this.paymentState.state.pipe(map((state) => state.payment));
  }

  get loading$() {
    return this.paymentState.state.pipe(map((state) => state.loading));
  }

  goToUserProfile() {
    this.paymentState.state
      .pipe(
        first(),
        map((state) => state?.payment?.userId)
      )
      .subscribe((id) => {
        this.consoleRouter.productNavigate(['users', id]);
      });
  }

  back() {
    this.consoleRouter.goBack();
  }

  private submitOtp(paymentReference: string): void {
    this.paymentOtpModal.openModal(paymentReference);
  }

  approve(paymentReference: string): void {
    this.currentClicked = 'APPROVE';

    this.submitOtp(paymentReference);
  }

  retry(paymentReference: string): void {
    this.currentClicked = 'RETRY';

    this.submitOtp(paymentReference);
  }

  requery({ paymentReference, id }: { paymentReference; id }): void {
    this.currentClicked = 'REQUERY';
    this.paymentState.requery(paymentReference, id);
  }

  confirm({ paymentReference, id }: { paymentReference; id }): void {
    this.currentClicked = 'CONFIRM';
    this.paymentState.confirm(paymentReference, id);
  }

  retryPayment(): void {
    this.currentClicked = 'RETRY';
    this.paymentState.retryPayment();
  }

  getStatusColor(status: WALLET_TRANSACTION_STATUS): string {
    switch (status) {
      case WALLET_TRANSACTION_STATUS.PENDING:
        return 'warning';

      case WALLET_TRANSACTION_STATUS.INITIATED:
        return 'primary';

      case WALLET_TRANSACTION_STATUS.WAITING:
      case WALLET_TRANSACTION_STATUS.PROCESSING:
        return 'secondary';

      case WALLET_TRANSACTION_STATUS.CANCELLED:
      case WALLET_TRANSACTION_STATUS.FAILED:
      case WALLET_TRANSACTION_STATUS.REJECTED:
        return 'danger';

      default:
        return 'success';
    }
  }

  retrieveById(paymentId: number): void {
    if (paymentId !== this.initialId) {
      this.paymentState.reset();
      this.paymentState.findById(paymentId);
      this.initialId = paymentId;
    }
  }

  retrieveByRoute(): void {
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id) {
        this.paymentState.findById(id);
      } else {
        this.consoleRouter.productNavigate(['payment']);
      }
    });
  }

  get canApprovePayment$(): Observable<boolean> {
    return this.role.hasPermission$('PAYMENT_UPDATE');
  }

  get canDeletePayment$(): Observable<boolean> {
    return this.role.hasPermission$('PAYMENT_DELETE');
  }

  private loadInitialPayments(): void {
    if (this.paymentId) {
      this.retrieveById(this.paymentId);
      return;
    }
    this.retrieveByRoute();
  }

  ngOnInit(): void {
    this.paymentState.reset();
    // this.loadInitialPayments();
    this.role.hasPermission$('PAYMENT_VIEW').subscribe((authorized) => {
      if (!authorized) {
        return this.consoleRouter.productNavigate([]);
      }
      this.loadInitialPayments();
    });
  }

  // ngOnChanges(): void {
  //   if (this.paymentId) {
  //     this.retrieveById(this.paymentId);
  //   }
  // }
}
