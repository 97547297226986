import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '@console/api';
import { first, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { WalletTxnService } from '../../transactions/services/transactions.service';
import { IAdvisoryDailyFees } from './advisory-fees.model';

export interface AdvisoryFeesState {
  loading: boolean;
  count?: number;
  fees: IAdvisoryDailyFees[];
  error?: string;
  message?: string;
}

const initialState: AdvisoryFeesState = {
  loading: false,
  fees: [],
};

@Injectable({
  providedIn: 'root'
})
export class AdvisoryFeeStateService {
  state = new BehaviorSubject<AdvisoryFeesState>(initialState);
  constructor(    private advisoryService: WalletTxnService,
    private spinner: NgxSpinnerService) { }

  find(userId:any,query: object) {
    this.loading();
    this.advisoryService
      .getAdvisoryDailyFees(userId, query)
      .pipe(first())
      .subscribe({
        next: this.updateFees.bind(this),
        error: this.onError.bind(this),
      });
  }


  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateFees(data: PaginatedList<IAdvisoryDailyFees>) {
    this.state.next({
      loading: false,
      fees: data.data,
      count: data.count,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading"
    });
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message:"Error occurred"
    });
    this.spinner.hide();
  }
}
