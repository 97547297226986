import { RedemptionDetailsComponent } from './components/redemption-details/redemption-details.component';
import { RedemptionStateService } from './state/redemption-state.service';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@console/shared';
import { RedemptionListComponent } from './redemption-list.component';
import { RedemptionService } from './services/redemption.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RedemptionListComponent, RedemptionDetailsComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  providers: [RedemptionService, RedemptionStateService],
  exports: [RedemptionListComponent],
})
export class RedemptionModule {}
