export enum ORDER_STATUS {
  ALL = "",
  FILLED = "FILLED",
  PARTIALLY_FILLED = "PARTIAL_FILL",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  NEW = "NEW",
  PENDING = "PENDING",
  PENDING_CANCEL = "PENDING_CANCEL",
}
