import {
  ChangeDetectionStrategy, Component,
  EventEmitter,
  Input, OnInit,
  Output
} from "@angular/core";
import { BlogContent } from "../../services/blog.model";

@Component({
  selector: "kt-content-blog-card",
  templateUrl: "./blog-card.component.html",
  styleUrls: ["./blog-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogCardComponent implements OnInit {
  @Input() post: BlogContent;

  @Output() btnClick = new EventEmitter<BlogContent>();

  constructor() { }

  get date() {
    const date = (this.post && this.post.dateCreated) ? new Date(this.post.dateCreated) : new Date();
    return new Intl.DateTimeFormat('en-NG', {
      year: 'numeric', month: 'short', day: 'numeric'
    }).format((date))
  }


  ngOnInit() { 
    // console.log(this.post)
  }
}
