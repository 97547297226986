<div class="card-body">
  <div
    class="d-flex w-100 justify-content-between"
    *ngIf="!loading && categories.length"
  >
    <h3>Responses</h3>

    <div>
      <button mat-button (click)="toggleAccordion()">
        {{ allExpanded ? "Collapse" : "Expand" }} All
      </button>
    </div>
  </div>

  <lib-messages></lib-messages>

  <ng-container *ngIf="categories.length; else noData">
    <mat-accordion multi expanded="true">
      <mat-expansion-panel
        class="expansion-card"
        *ngFor="let category of categories"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ category | replaceString: "_":" " }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div
          class="expansion-card-content"
          *ngFor="let response of mappedResponse[category]; let i = index"
        >
          <p>{{ response.questionText }}</p>

          <strong>{{ response?.optionText }}</strong>

          <!-- <div *ngIf="false" class="form-group">
            <div class="radio-list">
              <label class="radio"
                [ngClass]="response.optionId !== option.id ? 'radio-disabled':''"
                *ngFor="let option of response.options">
                <input
                  type="radio"
                  [checked]="response.optionId === option.id ? 'checked':''"
                  [name]="'radios'+i"
                  disabled="disabled">
                <span></span>{{ option?.text}}
              </label>

            </div>
          </div> -->
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-template #noData>
    <lib-no-data
      *ngIf="!loading"
      message="No suitability response."
    ></lib-no-data>
  </ng-template>
</div>
