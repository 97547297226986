/**
 * UserKYCComponentsModule
 * This module, contains the re-usable components for the users kyc forms
 * management module
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";


import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { UserAddressFormComponent } from './user-address-form/user-address-form.component';
import { UserBankFormComponent } from './user-bank-form/user-bank-form.component';
import { UserBiodataFormComponent } from './user-biodata-form/user-biodata-form.component';
import { UserCssFormComponent } from './user-css-form/user-css-form.component';
import { UserEmploymentFormComponent } from './user-employment-form/user-employment-form.component';
import { UserFilesComponent } from './user-files/user-files.component';
import { UserInvestmentFormComponent } from './user-investment-form/user-investment-form.component';
import { UserKinFormComponent } from './user-kin-form/user-kin-form.component';
import { UserPepStatusFormComponent } from './user-pep-status-form/user-pep-status-form.component';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    GooglePlaceModule
  ],
  exports: [
    UserBiodataFormComponent,
    UserAddressFormComponent,
    UserKinFormComponent,
    UserInvestmentFormComponent,
    UserEmploymentFormComponent,
    UserPepStatusFormComponent,
    UserCssFormComponent,
    UserBankFormComponent,
    FileUploaderComponent,
    UserFilesComponent,
  ],
  declarations: [
    UserBiodataFormComponent,
    UserAddressFormComponent,
    UserKinFormComponent,
    UserInvestmentFormComponent,
    UserEmploymentFormComponent,
    UserPepStatusFormComponent,
    UserCssFormComponent,
    UserBankFormComponent,
    FileUploaderComponent,
    UserFilesComponent,
  ],
})
export class UserKYCComponentsModule { }
