import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, merge } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BuyInstrumentModel, OrderTxnsModel } from '../services/orders.model';
import { OrderTxnService } from '../services/orders.service';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from '@console/api/api.interface';
import { CURRENCY } from '../../../../../shared/src/lib/_enums/currency.enum';
import { ORDER_STATUS } from '@console/shared';
import { AuthRoleStateService } from '@console/authentication/auth-permission';

export interface OpenOrderState {
  order?: OrderTxnsModel;
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: OpenOrderState = { loading: false };

@Injectable({ providedIn: 'root' })
export class OrderStateService {

  state = new BehaviorSubject<OpenOrderState>(initialState);

  spinnerName = 'Order-State';

  constructor(
    private order: OrderTxnService, private spinner: NgxSpinnerService,
    private role: AuthRoleStateService
  ) { }

  retrieve(orderId: string) {
    this.loading(`Please wait, while we retrieve order`);
    this.order.getOrderTxnById(orderId).subscribe({
      next: this.onUserOpenOrderSLoaded(`Order successfully retrieved`).bind(this),
      error: this.onUserOpenOrderSError.bind(this),
    });
  }

  buy(query: BuyInstrumentModel) {
    this.loading(`Please wait, Buy Order is currently been placed`);
    this.order
      .buyInstrument(query)
      .pipe(first())
      .subscribe({
        next: this.onUserOpenOrderSLoaded(`BUY Order, successfully Created.`).bind(this),
        error: this.onUserOpenOrderSError.bind(this),
      });
  }

  sell(query: BuyInstrumentModel) {
    this.loading(`Please wait, Sell Order is currently been placed`);
    this.order
      .sellInstrument(query)
      .pipe(first())
      .subscribe({
        next: this.onUserOpenOrderSLoaded(`SELL Order, successfully Created.`).bind(this),
        error: this.onUserOpenOrderSError.bind(this),
      });
  }


  cancel(orderId: string) {
    this.loading(`Please wait, while we cancel the open orders`);
    this.order.cancelOrder(orderId).subscribe({
      next: this.onUserOpenOrderSLoaded(`Thanks, Order cancelling is in progress.`).bind(this),
      error: this.onUserOpenOrderSError.bind(this),
    });
  }

  requery(orderId: string, currency: CURRENCY) {
    this.loading(`Please wait, Order requerying is inprogress`);
    this.order.reQueryOrder({ currency, orderId }).subscribe({
      next: this.onUserOpenOrderSLoaded(`Order was successfully requeryed`).bind(this),
      error: this.onUserOpenOrderSError.bind(this),
    });
  }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private loading(message: string) {
    this.state.next({ ...this.state.getValue(), loading: true, message, error: undefined });
    this.spinner.show(this.spinnerName);
  }

  private onUserOpenOrderSLoaded(message: string) {
    return ({ data }: ReqSuccessResponse<OrderTxnsModel>) => {
      this.state.next({
        ...this.state.getValue(),
        loading: false,
        order: data,
        message
      });
      this.spinner.hide(this.spinnerName);
    }
  }

  private onUserOpenOrderSError(res: ChakaAPIError) {
    const error = cleanChakaAPIError(res);
    this.state.next({
      error,
      loading: false,
      message: error
    });
    setTimeout(() => {
      this.spinner.hide(this.spinnerName);
    }, 2000);
  }

  get canBeQueried$() {
    const { FILLED, CANCELED } = ORDER_STATUS;
    const canApprove$ = this.role.hasPermission$('ORDER_UPDATE');
    const shouldBeApproved$ = this.state
      .pipe(map(state => state.order?.status === FILLED || state.order?.status === CANCELED))
      .pipe(map(approved => !approved));

    return combineLatest([canApprove$, shouldBeApproved$]).pipe(
      map(([canApprove, shouldApprove]) => canApprove && shouldApprove));
  }

  get canBeCancel$() {
    const { FILLED, CANCELED } = ORDER_STATUS;
    const canApprove$ = this.role.hasPermission$('ORDER_UPDATE');
    const shouldBeApproved$ = this.state
      .pipe(
        tap(state => console.log(state)),
        map(
          state => (
            state.order?.status === FILLED ||
            state.order?.status === CANCELED
          )
        )
      )
      .pipe(map(approved => !approved));

    return combineLatest([canApprove$, shouldBeApproved$])
      .pipe(map(([canApprove, shouldApprove]) => {
        console.log('here can');
        console.log('can', canApprove);
        console.log('app', shouldApprove);
        return canApprove && shouldApprove;
      }));
  }
}
