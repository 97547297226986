import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { CURRENCY } from '../../../../../shared/src/lib/_enums/currency.enum';
import { PerformanceIntervals } from '../models/instrument.interface';
import { InstrumentGraphStateService } from '../states/graph-state.service';
import { SimilarInstrumentListStateService } from '../states/similar-instrument.state';
import { InstrumentStateService } from '../states/single-instrument--state.service';

@Component({
  selector: 'app-instrument-edit',
  templateUrl: './instrument-edit.component.html',
  styleUrls: ['./instrument-edit.component.scss'],
})
export class InstrumentEditComponent implements OnInit {
  performanceInterval = PerformanceIntervals.Week;

  @Input() symbol: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: ConsoleRouterService,
    public instrument: InstrumentStateService,
    public graph: InstrumentGraphStateService,
    public similar: SimilarInstrumentListStateService
  ) {}

  loadPageItems(symbol: string) {
    this.symbol = symbol;
    this.instrument.loadInstrument(this.symbol);
    this.changeGraphInterval(this.performanceInterval);
    this.similar.retrive(this.symbol);
  }

  changeGraphInterval(interval: PerformanceIntervals) {
    this.performanceInterval = interval;
    this.graph.loadChart({
      currency: CURRENCY.DOLLAR as any,
      interval: this.performanceInterval,
      symbol: this.symbol,
    });
  }

  goBack() {
    this.router.goBack();
    // this.router.productNavigate(['orders']);
  }

  viewSimilarStock(symbol: string) {
    this.router.productNavigate(['instruments', 'symbol', symbol]);
    const logo = document.querySelector('.brand-container') as HTMLDivElement;
    if (logo) {
      logo.scrollIntoView();
    }
  }

  intervalStyle(interval: string) {
    const styleClassName = 'chart-control ';
    if (interval !== this.performanceInterval) return styleClassName;
    return styleClassName + 'active';
  }

  ngOnInit() {
    this.instrument.reset();

    if (this.symbol) {
      this.loadPageItems(this.symbol);
      return;
    }

    this.route.params
      .pipe(map((params) => params.symbol))
      .subscribe((symbol) => this.loadPageItems(symbol));
  }
}
