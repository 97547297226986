<div class="row">
  <div class="col-12">
    <form [formGroup]="employmentForm">
      <div class="form-row">
        <div class="col-lg-6 form-group">
          <label for="employmentStatus">Employment Status</label>
          <!-- onSelectChange('employmentStatus', $event) -->
          <ng-select formControlName="employmentStatus">
            <ng-option
              *ngFor="let status of employmentStatus"
              [value]="status.value"
            >
              {{ status.name }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="employmentCompany"> Company Name </label>
          <input
            id="employmentCompany"
            class="form-control"
            formControlName="employmentCompany"
          />
        </div>

        <div class="col-lg-6 form-group">
          <!-- (valueChange)="onSelectChange('employmentType', $event)" -->
          <label for="employmentType"> Employment Type </label>
          <ng-select formControlName="employmentType">
            <ng-option
              *ngFor="let type of employmentTypes"
              [value]="type.value"
            >
              {{ type.name }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="employmentPosition"> Employment Position </label>
          <ng-select formControlName="employmentPosition">
            <ng-option
              *ngFor="let type of employmentPositions"
              [value]="type.value"
            >
              {{ type.name }}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="employedByBroker">Employment by broker?</label>
          <!-- (valueChange)="onSelectChange('employedByBroker', $event)" -->
          <select
            formControlName="employedByBroker"
            class="form-control"
            id="employedByBroker"
          >
            <option *ngFor="let item of employedByBroker" [value]="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 form-group">
          <label for="directorOfPublicCo">Director of Public Co?</label>
          <!-- onSelectChange('directorOfPublicCo', $event) -->
          <select
            formControlName="directorOfPublicCo"
            class="form-control"
            id="directorOfPublicCo"
          >
            <option *ngFor="let item of directors" [value]="item.value">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</div>
