import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Component, Input, OnInit } from '@angular/core';
import { AuthProfileStateService } from '@console/authentication/profile-state';

@Component({
  selector: 'kt-user-accounts',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss'],
  providers: [
    // MessagesService
  ]
})
export class AccountComponent implements OnInit {
  @Input() userId: string;
  currentIndex = 0;

  tabList = {
    WALLET: 0,
    FUND: 1,
    EXCHANGE: 2,
    WITHDRAW: 3,
    REQUERY: 4,
    REVERSAL: 5
  };

  constructor(
    public auth: AuthProfileStateService,
    private messagesService: MessagesService
  ) { }

  changeIndex(index: number) {
    this.currentIndex = index;
    this.messagesService.hideAll();
  }

  ngOnInit() { }
}
