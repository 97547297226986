import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, ReqSuccessResponse } from '@console/api';
import { BehaviorSubject } from 'rxjs';
import { EmailSettingsService } from '../services/email-settings.service';
import { EmailSetting } from '../services/settings.interface';

export interface EmailSettingState {
  data: EmailSetting[];
  loading: boolean;
  error?: string;
  message?: string;
}

const initialState: EmailSettingState = { loading: false, data: [] };

@Injectable({
  providedIn: 'root'
})
export class EmailSettingsStateService {

  state = new BehaviorSubject<EmailSettingState>(initialState);

  constructor(public emailSettingsService: EmailSettingsService) {}

  // find(query: Record<string, any> = {}) {
  //   this.loading();
  //   this.emailSettingsService.find(query).subscribe({
  //     next: this.onUserChannelLoaded.bind(this),
  //     error: this.onUserChannelError.bind(this),
  //   });
  // }

  resetState() {
    this.state.next(initialState);
  }

  resetError() {
    this.state.next({
      ...this.state.getValue(),
      error: undefined,
    });
  }

  private loading() {
    this.state.next({ ...this.state.getValue(), loading: true });
  }

  private onUserChannelLoaded({ data }: ReqSuccessResponse<EmailSetting[]>) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      data: data,
    });

    console.log(data)
  }

  private onUserChannelError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      loading: false,
      error: cleanChakaAPIError(res),
    });
  }


}
