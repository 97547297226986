import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PAYMENT_PROCESSOR } from '@console/shared';
import { WalletTypes } from '../../providers/wallet.interface';
import { RequeryPaymentStateService } from '../../states/confirm-payment.state';
import { CURRENCY } from '../../../../../../../shared/src/lib/_enums/currency.enum';

@Component({
  selector: 'kt-user-accounts-requery',
  templateUrl: './exchange.component.html',
})
export class UserAccountsRequeryComponent implements OnInit, OnDestroy {
  transferForm: FormGroup;

  @Input() private userId: string;

  wallets = [
    {
      label: 'Local Wallet (NGN)',
      value: CURRENCY.NAIRA,
    },
    {
      label: 'Dollar Wallet (USD)',
      value: CURRENCY.DOLLAR,
    },
  ];

  processors = [
		{
			name: "All",
			value: PAYMENT_PROCESSOR.ALL,
		},
		{
			name: "Interswitch",
			value: PAYMENT_PROCESSOR.INTERSWITCH,
		},
		{
			name: "Paystack",
			value: PAYMENT_PROCESSOR.PAYSTACK,
		},
		{
			name: "Monnify",
			value: PAYMENT_PROCESSOR.MONNIFY,
		},
		{
			name: "Okra",
			value: PAYMENT_PROCESSOR.OKRA,
		},
		{
			name: "NIP",
			value: PAYMENT_PROCESSOR.NIP,
		},
  ];

  constructor(
    private userFB: FormBuilder,
    public requery: RequeryPaymentStateService
  ) {}

  createForm() {
    this.transferForm = this.userFB.group({
      source: ['', Validators.required],
      currency: [WalletTypes.GLOBAL, Validators.required],
      transactionReference: ['', Validators.required]
    });
  }

  onSubmit() {
    const form = this.transferForm.value;
    form.userId = this.userId;
    form.status = true;
    form.statusCode = "success";
    form.amount = 0;
    this.requery.confirm(form);
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.requery.resetState();
  }
}
