import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserKycStateService } from '../sections/user-kyc-editor/user-kyc.state';
import { ActivatedRoute } from '@angular/router';
import { map, first, filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { KYCVerificationStateService } from './kyc-verification.state';
import { ConsoleRouterService } from '../../../../shared/src/lib/services/console-router.service';
import { AuthRoleStateService } from '../../../../authentication/src/lib/auth-permission';
import { NotificationService } from '@console/shared/services/notification-service';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManageHVUComponent } from '../sections/user-portfolio/components/manage-hvu/manage-hvu.component';
import { ClientUserModel } from '@console/user-managements';
import { AuthProfileStateService } from '@console/authentication/profile-state';

@Component({
  selector: 'lib-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  userId: string;

  currentTabIndex = 0;

  tabList = {
    KYC: 0,
    WALLET: 1,
    PORTFOLIO: 2,
    CSCS: 3,
    ORDERS: 4,
    REDEMPTION: 5,
    MANDATE: 6,
    PAYMENTS: 7,
    TRANSACTIONS: 8,
    DW_HISTORY: 9,
    STATEMENTS: 10,
    VERIFICATION: 11,
    SUITABILITY_RESPONSE: 12,
    ADVISORY_FEES: 13
  };
  isSuperAdmin = false;

  constructor(
    public router: ConsoleRouterService,
    private activatedRoute: ActivatedRoute,
    private role: AuthRoleStateService,
    public kyc: UserKycStateService,
    public verificationState: KYCVerificationStateService,
    private messagesService: MessagesService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private authProfile: AuthProfileStateService
  ) {}

  streamState(): void {
    this.subscription = this.kyc.state.subscribe((state) => {
      if (state.kyc) {
        this.verificationState.presetKyc(state.kyc);
      }
    });
  }

  ngOnInit(): void {
    this.role.hasPermission$('USER_VIEW').subscribe((authorized) => {
      if (!authorized) {
        this.router.productNavigate(['users']);
      }
      this.loadKycInformation();
    });
  }

  listenOnAuthProfile(): void {
    this.authProfile.isSuperAdmin$
      .pipe(
        take(1),
        filter((isSuperAdmin) => isSuperAdmin)
      )
      .subscribe((isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;
      });
  }

  hasPermissionFor$(entity: string) {
    return this.role.hasPermission$(entity);
  }

  loadKycInformation(): void {
    this.activatedRoute.params
      .pipe(map((params) => params.id))
      .pipe(first())
      .subscribe((userId) => {
        this.userId = userId;
        this.kyc.retrieve(userId);
      });
    this.streamState();
  }

  goBack(): void {
    this.router.goBack();
  }

  setTab(index: number): void {
    this.currentTabIndex = index;

    this.messagesService.hideAll();
    this.notificationService.hideAll();
  }

  toggleHVU(hvuStatus: boolean | null): void {
    const message = !!hvuStatus
      ? 'Remove this user as an HVU?'
      : 'Make this user an HVU?';

    const check = confirm(message);

    if (!check) {
      return;
    }

    this.kyc.update({
      hvu: !hvuStatus,
      userId: this.userId,
    });
  }

  openHVUModal(kycState: ClientUserModel): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';

    dialogConfig.data = kycState;

    const dialogRef = this.dialog.open(ManageHVUComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      // !!callback && callback(result);
    });
  }

  ngOnDestroy(): void {
    this.kyc.resetState();
    this.verificationState.reset();
    this.subscription.unsubscribe();
  }
}
