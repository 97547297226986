<ng-container *ngIf="drawer.opened">
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Setup Rebalance</span>
      </h3>
      <div class="card-toolbar">
        <button class="close" mat-button (click)="closeDrawer()">X</button>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">

      <div #scrollDiv style="height:800px; overflow-y: auto;" class="scrolling-div">
        <lib-messages></lib-messages>

        <form [formGroup]="autoPilotForm" (ngSubmit)="save()">
          <div class="form-group">
            <label>Currency <span class="text-danger">*</span></label>
            <ng-select formControlName="currency" [appendTo]="'body'">
              <ng-option
                [value]="currency"
                *ngFor="let currency of currencyList">
                {{ currency }}
              </ng-option>
            </ng-select>
            <small
              class="form-text text-danger"
              *ngIf="formMethods.touchedAndInvalid('currency')">
              <div *ngIf="formMethods.getControl('currency').hasError('required')">
                Required input.
              </div>
            </small>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Force Rebalance?</label>
            <div class="col-8">
              <span class="switch">
                <label>
                  <input type="checkbox" formControlName="forceRebalance">
                  <span></span>
                </label>
              </span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Review Only?</label>
            <div class="col-8">
              <span class="switch">
                <label>
                  <input type="checkbox" formControlName="reviewOnly">
                  <span></span>
                </label>
              </span>
            </div>
          </div>

          <hr>

          <h3>Add Users</h3>

          <div class="form-group">
            <label>Search for a user <span class="text-danger">*</span></label>

            <ng-select [items]="(userList$.state | async).users"
              bindLabel="fullName"
              autofocus
              bindValue="userId"
              [formControl]="userControl"
              (input)="debounceSearchUser($event)"
              (change)="assignUser($event)">
            </ng-select>

            <small
              class="form-text text-danger"
              *ngIf="!userControl.valid && userControl.touched">
              <div *ngIf="userControl.hasError('required')">Required input</div>
            </small>
          </div>

          <div class="form-group pb-5">
            <!--begin::Item-->
            <div class="d-flex flex-wrap align-items-center mb-10" *ngFor="let user of selectedUsers">
              <!--begin::Symbol-->
              <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                <div *ngIf="user?.profileImageUrl else initials" class="symbol symbol-circle symbol-lg-50">
                  <img [src]="user?.profileImageUrl" [alt]="user?.fullName">
                </div>

                <ng-template #initials>
                  <div class="symbol symbol-lg-50 symbol-circle symbol-primary">
                    <span class="symbol-label font-size-h4">
                      {{user?.firstName ? user.firstName[0].toUpperCase() : "" }}
                      {{user?.lastName ? user.lastName[0].toUpperCase() : ""}}
                    </span>
                  </div>
                </ng-template>
              </div>

              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                <a href="javascript:;" class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{{user?.fullName}}</a>
                <span class="text-muted font-weight-bold">{{user?.email}}</span>
              </div>
              <!--end::Title-->
              <!--begin::Section-->
              <div class="d-flex align-items-center mt-lg-0 mt-3">
                <!--begin::Btn-->
                <a
                  style="cursor: pointer;"
                  class="btn btn-icon btn-light btn-sm"
                  (click)="this.deleteUser(user?.id)">
                  <span class="svg-icon svg-icon-md">
                    <i class="fas fa-times"></i>
                  </span>
                </a>
                <!--end::Btn-->
              </div>
              <!--end::Section-->
            </div>
            <!--end::Item-->
          </div>




        </form>




      </div>

    </div>
    <!--end::Body-->

    <div class="card-footer d-flex justify-content-between">
      <!-- <button
        *ngIf="stepper.selectedIndex === 0"
        type="button"
        class="btn btn-light"
        (click)="nextStep()">Next</button>
 -->

      <button
        type="button"
        class="btn btn-primary"
        (click)="save()">Save</button>
    </div>
  </div>


</ng-container>
