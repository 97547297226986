import differenceInWeeks from 'date-fns/differenceInWeeks';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import parse from 'date-fns/parse';
import { PerformanceIntervals, InstrumentChartModel } from '../../models/instrument.interface';


export interface GraphPoint {
  label: string;
  price: number;
  date: Date;
  open: number;
  high: number;
  low: number;
  close: number;
  minute: string;
  volume: number;
  averagePrice: number;
}

const {
  Day,
  FiveYear,
  Month,
  Quarter,
  Week,
  Year,
  HalfYear,
} = PerformanceIntervals;

export function cleanDataPoints(
  points: InstrumentChartModel[],
  interval: PerformanceIntervals
) {
  switch (interval) {
    case Day:
      return points
        .map(infoToPoint)
        .filter(pointFilter)
        // .filter((_, i) => i % 5 === 0);
    case Week:
      return points
        // .filter((_, i) => i % 5 === 0)
        .map((e) => ({
          ...e,
          label: format(new Date(`${e.date} ${e.minute}`), 'h:m aaaa d MMM'),
        }))
        .map(infoToPoint)
        .filter(pointFilter);
    case Month:
      return points
        .map(infoToPoint)
        .filter(pointFilter)
        // .filter((_, i) => i % 2 === 0);
    case Quarter:
      return points
        .map(infoToPoint)
        .filter(pointFilter)
        // .filter((_, i) => i % 10 === 0);
    case HalfYear:
      return points
        .map(infoToPoint)
        .filter(pointFilter)
        // .filter((_, i) => i % 10 === 0);
    case Year:
    case FiveYear:
      return points
        .map(infoToPoint)
        .filter(pointFilter)
        // .filter((_, i) => i % 10 === 0);
  }
}

const parseDate = (date: string) => parse(date, 'yyyy-MM-dd', new Date());
export const infoToPoint = (info: InstrumentChartModel) => {
  return {
    date: parseDate(info.date),
    label: info.label,
    open: info.open,
    high: info.high,
    low: info.low,
    close: info.close,
    minute: (info.minute as any) as string,
    volume: info.volume,
    price: info.marketClose ? info.marketClose : info.close,
    averagePrice: info.marketAverage ? info.marketAverage : info.close,
  } as GraphPoint;
};

const pointFilter = (point: GraphPoint) => point.price;

export function day(points: InstrumentChartModel[]) {
  // if (points.length > 60) {
  //    points = points.slice(points.length - 60)
  // }
  return points.map(infoToPoint);
}

export function week(points: InstrumentChartModel[]) {
  const today = new Date();
  const graphPoints: GraphPoint[] = [];
  for (const point of points) {
    const parsed = parseDate(point.date);
    const diff = differenceInWeeks(today, parsed);
    if (diff < 2) {
      graphPoints.push(infoToPoint(point));
    }
  }
  return graphPoints;
}

export function yearData(points: InstrumentChartModel[]) {
  const map = new Map<number, GraphPoint>();
  for (const point of points) {
    const parsed = parseDate(point.date);
    if (map.has(parsed.getMonth())) {
      const prev = map.get(parsed.getMonth()) as GraphPoint;
      if (isAfter(prev.date, parsed)) {
        map.set(parsed.getMonth(), infoToPoint(point));
      }
    } else {
      map.set(parsed.getMonth(), infoToPoint(point));
    }
  }
  return Array.from(map.entries()).map(([, p]) => p);
}

export function fiveYearData(points: InstrumentChartModel[]) {
  const map = new Map<string, GraphPoint>();
  const keyMap = (date: Date) => `${date.getFullYear()}:${date.getMonth()}`;
  for (const point of points) {
    const parsed = parseDate(point.date);
    if (map.has(keyMap(parsed))) {
      const prev = map.get(keyMap(parsed)) as GraphPoint;
      if (isAfter(prev.date, parsed)) {
        map.set(keyMap(parsed), infoToPoint(point));
      }
    } else {
      map.set(keyMap(parsed), infoToPoint(point));
    }
  }
  return Array.from(map.entries()).map(([, p]) => p);
}

export interface IntervalChanges {
  text: string;
  changes: number;
}

export function intervalText(interval: PerformanceIntervals) {
  switch (interval) {
    case Day:
      return 'Today';
    case Week:
      return 'from last week';
    case Month:
      return 'from last month';
    case Quarter:
      return 'from 3 months';
    case HalfYear:
      return 'from 6 months';
    case Year:
      return 'from last year';
    case FiveYear:
      return 'from 5YRS';
  }
}

export const percentChange = (currentPrice: number, prevPrice: number) => {
  return ((currentPrice - prevPrice) / prevPrice) * 100;
};

export function pickPropertyPrice(charts: GraphPoint[]) {
  return charts.map((c) =>  c.close ?? (c as any).marketClose);
}


export function pickProperty<T>(charts: T[], key: keyof T) {
  return charts.map((c) =>  c[key]);
}

export function pickProperties(
  charts: GraphPoint[],
  keys: (keyof GraphPoint)[]
) {
  return charts.map((c) => keys.map((k) => c[k]));
}
