import { Component, Input, OnInit } from '@angular/core';
import { formatToCurrency } from '@console/shared/utils/formatter';
import { UserEquityPosition } from '@console/user-managements/models/positions.interface';

@Component({
  selector: 'app-equity-positions-table',
  templateUrl: './equity-positions-table.component.html',
  styleUrls: ['./equity-positions-table.component.scss']
})
export class EquityPositionsTableComponent implements OnInit {

  @Input() positions: UserEquityPosition[] = [];

  constructor() { }

  formatAmount(amount: number) {
    if (!amount) return '-';
    return formatToCurrency('USD', Number(amount.toFixed(2)));
  }

  formatNumber(quantity: number) {
    if (!quantity) return '-';
    return quantity.toFixed(2);
  }

  ngOnInit() {
  }

}
