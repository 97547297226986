<!-- Chart Section with interval -->
<ngx-spinner [name]="preOrder.spinnerName" [fullScreen]="false" type="ball-clip-rotate-multiple" size="small">
  <p class="loading"> {{ (preOrder.state | async).message }} </p>
</ngx-spinner>

<ngx-spinner [bdColor]="'transparent'" [color]="'blue'"
  [name]="order.spinnerName" [fullScreen]="false" type="ball-spin" size="medium">
</ngx-spinner>

<div class="row">
  <div class="col-md-12">
    <app-stock-market-banner
    [instrument]="(instrument.state | async).instrument"
    [change]="(graph.state | async).priceChange"
    [changePercent]="(graph.state | async).pricePercentageChange"
    [currentPrice]="(graph.state | async).priceChange"
    [intervalText]="(graph.state | async).subtext"
    ></app-stock-market-banner>
  </div>

  <!-- Performance Chart -->
  <div class="col-12">

    <app-line-chart
        (intervalChanged)="changeGraphInterval($event)"
        [symbol]="(instrument.state | async).instrument?.symbol"
        [currency]="(instrument.state | async).instrument?.currency"
        [charts]="(graph.state | async).graphPoints"
    ></app-line-chart>

  </div>

  <div class="col-12">
    <div class="row">
      <div class="col-md-6">

        <app-market-information [instrument]="(instrument.state | async).instrument"></app-market-information>
        <app-company-information
        [instrument]="(instrument.state | async).instrument"
        ></app-company-information>

      </div>

      <!-- Transaction details here -->
      <div class="col-md-6">

        <app-order-form
        *ngIf="currentView === 'order-form'"
        [currency]="(instrument.state | async)?.instrument?.currency"
        [marketPrice]="(instrument.state | async)?.instrument?.marketPrice"
        [owned]="portfolio.equityShares$(symbol) | async"
        (preOrder)="createPreOrder($event)"
        ></app-order-form>

        <!-- Preorder input -->
        <app-pre-order-display
        *ngIf="currentView === 'pre-order'"
        [side]="userOrder.orderSide"
        [error]="(preOrder.state | async)?.error"
        [preOrder]="(preOrder.state | async)?.order"
        (action)="preOrderAction($event)"
        ></app-pre-order-display>

        <!-- Reciept display -->

      </div>
    </div>
  </div>
</div>
