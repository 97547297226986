<!--begin::Card-->
<div class="card card-custom gutter-b">
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">Redemption List</h3>
    </div>
  </div>

  <div class="card-body">

    <!--begin: Search Form-->
    <div class="mb-5">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <form [formGroup]="searchForm">
            <div class="row align-items-center">

              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>Search all feeds</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="q"
                    trimmedInput>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label> Status</label>
                  <ng-select formControlName="status" [clearable]="false">
                    <ng-option [value]="status.value" *ngFor="let status of redemptionStatusList">{{ status.label }}</ng-option>
                  </ng-select>
                </div>
              </div>

              <div
                class="col-xl-2 col-lg-4 col-md-6"
                *ngIf="isSuperAdmin">
                <div class="form-group">
                  <label>Merchant</label>
                  <ng-select
                    formControlName="merchantId"
                    [clearable]="false"
                    [virtualScroll]="true"
                    [loading]="this.merchants?.loading">
                    <ng-option value="">All</ng-option>
                    <ng-option
                      [value]="merchant?.id"
                      *ngFor="let merchant of merchants?.list">
                      {{ merchant?.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>From</label>
                  <input class="form-control" type="date" formControlName="from">
                </div>
              </div>

              <div class="col-xl-2 col-lg-4 col-md-6">
                <div class="form-group">
                  <label>To</label>
                  <input class="form-control" type="date" formControlName="to">
                </div>
              </div>

              <div class="col-xl-1 col-lg-4 col-md-6">
                <div class="form-group">
                  <label class="d-block">&nbsp;</label>
                  <button
                    type="button"
                    class="btn btn-light-primary font-weight-bold"
                    (click)="handleQuery()">Search</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--end::Search Form-->

    <!--begin: Datatable-->

    <lib-messages></lib-messages>

    <div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

      <div class="mt-5 mb-5">
        <div class="d-flex align-items-center">
          <div class="font-weight-bold">
            Selected {{selectedItems.size}} {{selectedItems.size <= 1 ?'record': 'records'}}:
            <button
              [disabled]="selectedItems.size <= 0"
              (click)="downloadFile()"
              class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
              <i class="far fa-arrow-alt-circle-down"></i> Download
            </button>

            <button
            *ngIf="false"
              [disabled]="btnLoading.approve  || this.selectedItems.size < 1"
              (click)="approveOrRejectSelected('approve')"
              class="btn btn-success btn-sm font-weight-bold mr-3">
              <i *ngIf="btnLoading.approve" class="fa fa-spinner fa-spin"></i>
              <i *ngIf="!btnLoading.approve" class="far fa-check-circle"></i> Approve
            </button>

            <button
              [disabled]="btnLoading.reject  || this.selectedItems.size < 1"
              (click)="approveOrRejectSelected('reject')"
              class="btn btn-danger btn-sm font-weight-bold">
              <i *ngIf="btnLoading.reject" class="fa fa-spinner fa-spin"></i>
              <i *ngIf="!btnLoading.reject" class="fas fa-ban"></i> Reject
            </button>
          </div>
        </div>
      </div>


      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matMultiSort aria-label="Redemption table">

          <ng-container matColumnDef="check" sticky>
            <th
              mat-header-cell
              *matHeaderCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single checkbox-all">
                  <input
                    (change)="onAllChecked($event.target.checked)"
                    type="checkbox"
                    [checked]="allSelected">&nbsp;<span></span>
                </label>
              </span>
            </th>

            <td
              mat-cell
              *matCellDef="let row">
              <span style="width: 20px;">
                <label class="checkbox checkbox-single">
                  <input
                    type="checkbox"
                    (change)="onItemChecked($event.target.checked, row)"
                    [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
                </label>
              </span>
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="userFullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                <td mat-cell *matCellDef="let row">
                  <a
                    *ngIf="(!userId || !row?.userId) else noUser"
                    href="javascript:void(0)"
                    (click)="viewUser(row?.userId)">
                    {{row?.userFullName || "-"}}</a>

                  <ng-template #noUser>{{row?.userFullName || "-"}}</ng-template>
                </td>
              </ng-container> -->

          <ng-container matColumnDef="accountNo">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="accountNo"> Account No. </th>
            <td mat-cell *matCellDef="let row">
              {{row?.accountNo}}
            </td>
          </ng-container>

          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="reference"> Reference </th>
            <td mat-cell *matCellDef="let row">
              <a
                href="javascript:void(0)"
                (click)="viewRedemption(row?.id)">
                {{row?.reference}}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="amount"> Amount</th>
            <td mat-cell *matCellDef="let row">
              {{row?.amount}}
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="category"> Category </th>
            <td mat-cell *matCellDef="let row">
              {{row?.category}}
            </td>
          </ng-container> -->

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="type"> Type </th>
            <td mat-cell *matCellDef="let row">{{row?.type}}</td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row">{{row?.status?.message}}</td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="created"> Date </th>
            <td mat-cell *matCellDef="let row">
              {{row?.created | date:'mediumDate' || "-"}}
            </td>
          </ng-container>

          <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true">
          </tr>
          <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="showNotAllowedRow(row) ? 'not-allowed-row':''">
          </tr>
        </table>
      </div>

      <mat-paginator
        class="mat-paginator-sticky"
        [length]="count"
        [pageSizeOptions]="[10, 50, 100, 150, 200]"
        (page)="pageEvent=$event; paginate($event)"
        showFirstLastButtons></mat-paginator>
    </div>

    <lib-no-data
      *ngIf="!loading && dataSource.data?.length <= 0"
      message="No Data"></lib-no-data>

    <!--end: Datatable-->
  </div>
</div>
<!--end::Card-->
