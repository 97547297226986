import { Component, Input, OnInit } from '@angular/core';
import { ExchangeRateListStateService } from './exchange-rates-state';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
import { FormControl, NgForm } from '@angular/forms';
import { ExchangeRateStateService } from './exchange-rates-edit/exchange-rates-edit-state';
import { PageEvent } from '../../../../shared/src/lib/interface';
import { skip, first } from 'rxjs/operators';

@Component({
  selector: 'app-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrls: ['./exchange-rates.component.scss']
})
export class ExchangeRatesComponent implements OnInit {
  createNewRate: boolean = false;
  RateForm:NgForm;
  newRate = {
    buy: undefined,
    sell: undefined
  };
  search = new FormControl('');
  maxDate = new Date();
  private filter: Record<string, any> = {
    pageSize: 10,
  };

  userId: string;
  /**
   * show date filter
   */
  @Input() datesFilter = true;


  @Input() searchFilter = true;

  /**
   * show processors filter
   */
  @Input() processorFilter = true;

  /**
   * show transaction status filter
   */
  @Input() statusFilter = true;

  /**
   * amount od transactions to show
   */
  @Input() pageSize = 10;

  /**
   * total amount of data being returned
   */
  @Input() count = 0;

  constructor(
    private consoleRouter: ConsoleRouterService,
    public exchangeRateList: ExchangeRateListStateService,
    public exchangeState: ExchangeRateStateService,

  ) { }

  ngOnInit(): void {
    this.filter.pageSize = this.pageSize;
    this.query(this.filter);
  }

  viewRate(id){
    this.consoleRouter.productNavigate(['rates',id])
  }

  create(){
    this.createNewRate = true;
  }

  allRates(){
    this.createNewRate = false;
    this.exchangeRateList.find({});
  }

  createRate(){
    this.exchangeState.create(this.newRate.buy,this.newRate.sell);

    this.exchangeState.state.pipe(skip(1), first())
      .subscribe(state => {
        if (state.exchangerate) {
          this.viewRate(state.exchangerate.id);
        }
      })
  }

  handleQuery(key, value, isDate = false) {
    if (isDate) {
      value = value.toISOString();
    }
    this.filter[key] = value;
    this.query(this.filter);
  }

  query($event: Record<string, any>) {
    if (this.userId) {
      $event.userId = this.userId;
    }
    this.exchangeRateList.find($event);
  }

  paginate($event: PageEvent) {
    this.handleQuery('pageNumber', $event.pageNumber);
  }

}
