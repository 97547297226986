<div class="container-fluid container-fluid-margin chaka-route-content">
  <div class="card">
    <div class="card-header bg-white">
      <span class="card-header-title-text">Retrieved
      </span>
      <span class="float-right">
        <span>
          <a [routerLink]="['../']" class="btn btn-sm  btn-transparent border mr-3"><i class="fas fa-arrow-left"></i> Back</a>
        </span>
        <span
          *ngIf="
            (collection.state | async).collection &&
            (collection.state | async).collection.id
          ">
          <a (click)="delete()" class="btn btn-sm  btn-danger text-light border-0 mr-3"><i class="fas fa-trash-alt"></i> Delete</a>
        </span>
        <span
          *ngIf="
            (collection.state | async).collection &&
            (collection.state | async).collection.id
            && isEditModeDisabled
            ">
          <a (click)="toggleEditMode()" class="btn btn-sm  btn-primary text-light border-0 mr-3"><i class="fas fa-trash-alt"></i> Edit</a>
        </span>
        <span>
          <a *ngIf="!isEditModeDisabled"
            href="javascript:;"
            class="btn btn-success kt-margin-r-10"
            color="primary"
            (click)="updateCollection()"
            matTooltip="Save & Continue">Save</a>
        </span>
      </span>
    </div>
    <div class="card-body">
      <form [formGroup]="collectionForm">
        <div class="alert alert-primary"
          *ngIf="(collection.state | async).error"
          type="warn"
          (close)="collection.resetError()">
          {{ (collection.state | async).error }}
        </div>

        <div class="row border-bottom py-4">

          <div class="col-md-6">
            <div class="form-group">
              <label>Title <small class="text-danger">*</small></label>
              <input type="text" class="form-control" placeholder="Title" formControlName="title">
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('title')">
                <div *ngIf="formMethods.getControl('title').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Description <small class="text-danger">*</small></label>
              <input type="textarea" class="form-control" placeholder="Description" formControlName="description">
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('description')">
                <div *ngIf="formMethods.getControl('description').hasError('required')">
                  Required input
                </div>
              </small>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Image <small class="text-danger">*</small></label>
              <input
                type="file"
                class="form-control"
                formControlName="image"
                #imagePath
                (change)="preview(imagePath.files)">
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('image')">
                <div *ngIf="formMethods.getControl('image').hasError('required')">
                  Required input
                </div>
                <div *ngIf="formMethods.getControl('image').hasError('invalidImage')">
                  Please provide a valid image
                </div>
              </small>
            </div>

            <div
              class="image-preview"
              *ngIf="imgURL || initialImageUrl">
              <img [src]="!!imgURL ? imgURL : initialImageUrl | safe" [alt]="collectionForm?.value?.title">
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Symbols <small class="text-danger">*</small> <small>seperated by comma (,)</small></label>
              <input (change)="onInstrumentAdded()" type="text" class="form-control" formControlName="instruments" />
              <small
                class="form-text text-danger"
                *ngIf="formMethods.touchedAndInvalid('instruments')">
                <div *ngIf="formMethods.getControl('instruments').hasError('required')">
                  Required input
                </div>
              </small>

            </div>
          </div>
        </div>

      </form>
    </div>

    <kt-settings-instrument-list (view)="onRemoveInstrumentSelected($event)" [instruments]="(symbols.state | async).Instruments"></kt-settings-instrument-list>
  </div>
</div>
