import { CurrencyPipe } from '@angular/common';
import { Currency } from '@console/shared/_enums/currency.enum';
import { AccountSummary } from 'projects/user-managements/src/lib/models/positions.interface';
import { documentHeader, DocumentHeaderParam } from './formater';
import { KeysConfig, setHeight, tableFormat } from './table-printer';

// No: i + 1,
// Symbol: p.symbol,
// Quantity: p.availableForTradingQty,
// 'Current Value': formatToCurrency('USD', p.marketValue),
// 'Net Earnings': formatToCurrency('USD', p.unrealizedPL),
// 'Earnings %': p.netEarningsPercentage.toFixed(2),
// 'Stock Price': formatToCurrency('USD', p.mktPrice),
// 'Average Price': formatToCurrency('USD', p.avgPrice)

const portfolioKeys: KeysConfig[] = [
  {
    title: 'NO',
    dataKey: 'no',
  },
  {
    title: 'Symbol',
    dataKey: 'symbol',
  },
  {
    title: 'Market Price',
    dataKey: 'price',
  },
  {
    title: 'Quantity',
    dataKey: 'quantity',
  },
  {
    title: 'Current Value',
    dataKey: 'value',
  },
  {
    title: 'Earnings%',
    dataKey: 'earningsPercentage',
  },
  {
    title: 'Net Earnings',
    dataKey: 'earnings',
  },
];

const formatCurrency = (currency, amount) => {
  return new CurrencyPipe('en-US', 'USD').transform(amount, currency, 'symbol');
  // return new Intl.NumberFormat('en-NG', {
  //   style: 'currency',
  //   currency: currency,
  // }).format(amount);
};

export function portFolioPdfExport(
  header: DocumentHeaderParam,
  account: AccountSummary,
  currency: Currency
) {
  const { doc, height } = documentHeader(header);
  // const { doc, height } = datagramField(headerDoc, sheet);
  const startheight = setHeight(height);

  const dateExport = (account?.equity?.equityPositions || []).map((p, i) => {
    return {
      no: i + 1,
      symbol: p.symbol,
      price: formatCurrency(currency, p.mktPrice),
      quantity: p.availableForTradingQty,
      value: formatCurrency(currency, p.marketValue),
      earningsPercentage: p.netEarningsPercentage.toFixed(2),
      earnings: formatCurrency(currency, p.unrealizedPL),
    };
  });

  const { columns, rows } = tableFormat(portfolioKeys, dateExport);
  doc.autoTable(columns, rows, {
    startY: startheight(3),
    margin: 10,
    showHeader: 'firstPage',
  });
  doc.autoPrint();
  doc.save(
    `${header.user.fullName}_portfolio_${new Date().toISOString()}_Term.pdf`
  );
}

// export function datagramField(
//   { doc, height }: PrinterDocHeader,
//   sheet: StudentReportSheet
// ): PrinterDocHeader {
//   const startheight = setHeight(height);
//   doc.setFontSize(14).text('SCORE CARD', 10, startheight(0));
//   doc
//     .setFontSize(8)
//     .text(
//       `${schoolTermValueToKey(sheet.term)} Term  of ${
//         sheet.session
//       } Academic Year`,
//       10,
//       startheight(3)
//     );
//   doc.setFontSize(9).text(`Pupil's information`, 10, startheight(3));
//   let line = startheight(3);
//   doc.line(10, line, 200, line);
//   doc.setFontSize(12);
//   line = startheight(5);
//   doc
//     .text(`Name: ${sheet.biodata.name}`, 10, line)
//     .text(`Admission No: ${sheet.biodata.admissionNo}`, 130, line);
//   line = startheight(3);
//   doc.line(10, line, 200, line);
//   line = startheight(5);
//   doc
//     .text(`Sex: ${sheet.biodata.gender}`, 10, line)
//     .text(`Number In Class: ${sheet.totalStudents}`, 130, line);
//   line = startheight(3);
//   doc.line(10, line, 200, line);
//   line = startheight(5);
//   doc
//     .text(`Date of Birth: ${format(sheet.biodata.dob, DateFormat)}`, 10, line)
//     .text(`Class:  ${schoolClassValueToKey(sheet.level)}`, 130, line);
//   line = startheight(3);
//   doc.line(10, line, 200, line);
//   line = startheight(5);
//   doc
//     .text(`Cumulative Grade:  ${sheet.cumulative.grade}`, 10, line)
//     // .text(`Cumulative Grade:  ${sheet.cumulative.grade}`, 65, line)
//     .text(`Cumulative Average: ${sheet.cumulative.average}`, 130, line);
//   line = startheight(2);
//   doc.line(10, line, 200, line);
//   line = startheight(2);
//   doc.line(10, line, 200, line);
//   return {
//     doc,
//     height: line
//   };
// }
