<div class="d-flex flex-column-fluid">
  <div class="container" *ngIf="redemption">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-toolbar w-100 justify-content-between">
          <div>
            <button
              *ngIf="canBeRequeried"
              (click)="requery(redemption?.id)"
              class="btn btn-dark"
              [disabled]="loading"
            >
              <i
                [ngClass]="
                  loading && currentClicked === 'REQUERY'
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-sync-alt'
                "
              ></i>
              Re-query
            </button>

            <!-- <button
              *ngIf="canBeApproved"
              (click)="approveOrReject(redemption?.id, 'APPROVE')"
              class="btn btn-primary ml-3"
              [disabled]="loading">
              <i [ngClass]="loading && currentClicked ==='APPROVE' ? 'fas fa-spinner fa-spin': 'fas fa-check'"></i> Approve
            </button> -->

            <button
              *ngIf="canBeRejected"
              (click)="approveOrReject(redemption?.id, 'REJECT')"
              class="btn btn-primary ml-3"
              [disabled]="loading"
            >
              <i
                [ngClass]="
                  loading && currentClicked === 'REJECT'
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-check'
                "
              ></i>
              Reject
            </button>
          </div>

          <button (click)="back()" class="btn btn-transparent border">
            <i class="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div class="card-body">
        <lib-messages></lib-messages>

        <div class="row">
          <div class="col-12">
            <div class="details-pane">
              <p class="details-pane__content">
                <span>User:</span>
                <strong>
                  <a
                    (click)="gotUserProfile(redemption?.userDetails?.userID)"
                    class="pretty-link"
                  >
                    {{ fullName || "_" }}
                  </a></strong
                >
              </p>

              <p class="details-pane__content">
                <span>Account ID:</span>
                <strong>{{
                  redemption?.accountDetails?.accountID || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Account Management Type Description:</span>
                <strong>{{
                  redemption?.accountDetails?.accountManagementType
                    ?.description || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Account Management Type Name:</span>
                <strong>{{
                  redemption?.accountDetails?.accountManagementType?.name || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Account Type Description:</span>
                <strong>{{
                  redemption?.accountDetails?.accountType?.description || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Account Type Name:</span>
                <strong>{{
                  redemption?.accountDetails?.accountType?.name || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Account No:</span>
                <strong>{{ redemption?.accountNo || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Amount:</span>
                <strong>{{ amount || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Category:</span>
                <strong>{{ redemption?.category || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Created:</span>
                <strong>{{
                  (redemption?.created | date: "medium") || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Beneficiary Account No.:</span>
                <strong>{{
                  redemption?.details?.beneficiaryAccountNumber || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Beneficiary Account Type:</span>
                <strong>{{
                  redemption?.details?.beneficiaryAccountType || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Beneficiary Country's Bank:</span>
                <strong>{{
                  redemption?.details?.beneficiaryBankCountry || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Beneficiary Bank Name:</span>
                <strong>{{
                  redemption?.details?.beneficiaryBankName || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Beneficiary Name:</span>
                <strong>{{
                  redemption?.details?.beneficiaryName || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Beneficiary Swift Code:</span>
                <strong>{{
                  redemption?.details?.beneficiarySwiftABA || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>ID:</span>
                <strong>{{ redemption?.id || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Reference:</span>
                <strong>{{ redemption?.reference || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Status:</span>
                <strong>{{ redemption?.status?.message || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Transaction Code:</span>
                <strong>{{ redemption?.transactionCode?.code || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Transaction Description:</span>
                <strong>{{
                  redemption?.transactionCode?.description || "_"
                }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Transaction Type:</span>
                <strong>{{ redemption?.transactionCode?.type || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>Type:</span>
                <strong>{{ redemption?.type || "_" }}</strong>
              </p>

              <p class="details-pane__content">
                <span>wlpFinTranTypeID:</span>
                <strong>{{ redemption?.wlpFinTranTypeID || "_" }}</strong>
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-6">
            <h3>Status History</h3>

            <mat-vertical-stepper [linear]="true" orientation="vertical">
              <mat-step *ngFor="let statusHistory of redemption?.statusHistory">
                <ng-template matStepLabel>
                  <strong>{{ statusHistory?.statusMessage }}</strong>
                </ng-template>
                <div class="d-flex">
                  <strong class="mr-2">Comment:</strong>
                  <p>{{ statusHistory?.comment }}</p>
                </div>
                <div class="d-flex">
                  <strong class="mr-2">Updated By:</strong>
                  <p>{{ statusHistory?.updatedBy }}</p>
                </div>
                <div class="d-flex">
                  <strong class="mr-2">Date Created:</strong>
                  <p>{{ statusHistory?.created }}</p>
                </div>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
