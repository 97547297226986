import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MerchantListStateService } from './merchant-list.state';
import { PageEvent } from '../../../../../shared/src/lib/interface';
import { ConsoleRouterService } from '../../../../../shared/src/lib/services/console-router.service';
import { AuthRoleStateService } from '../../../../../authentication/src/lib/auth-permission';

@Component({
  selector: 'kt-merchants-list',
  templateUrl: './merchant-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MerchantListComponent implements OnInit, OnDestroy {
  childRoute = false;

  filter: Record<string, any> = {
    pageSize: 15,
  };

  constructor(
    public merchant: MerchantListStateService,
    private console: ConsoleRouterService,
    private role: AuthRoleStateService
  ) {}

  search(query: string): void {
    this.filter.q = query;
    this.merchant.find(this.filter);
    if (this.childRoute) {
      this.console.productNavigate(['merchants', 'view']);
    }
  }

  paginate($event: PageEvent): void {
    this.merchant.find({
      ...this.filter,
      ...$event,
    });
  }

  parentNavigate() {
    this.childRoute = false;
    this.merchant.find(this.filter);
  }

  navigate(merchantId?: string) {
    if (merchantId) {
      this.console.productNavigate(['merchant', merchantId]);
      return;
    }

    this.console.productNavigate(['merchant', 'create']);
  }

  ngOnInit() {
    this.role.hasPermission$('MERCHANT_VIEW').subscribe((authorized) => {
      if (!authorized) {
        this.console.productNavigate(['users']);
      }
      this.merchant.find(this.filter);
    });
  }

  ngOnDestroy() {
    this.merchant.reset();
  }
}
