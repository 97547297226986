import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NextOfKin } from './kin.interace';
import { FormGroup, FormBuilder } from '@angular/forms';
import { KYCFormControl } from '../kyc-form-control';

@Component({
  selector: 'lib-user-kin-form',
  templateUrl: './user-kin-form.component.html',
  styleUrls: ['./user-kin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserKinFormComponent extends KYCFormControl<NextOfKin> implements OnInit {
  @Input() private NextOfKin: NextOfKin;

  employmentForm: FormGroup;

  constructor(private fb: FormBuilder) {
    super();
  }

  private createForm(details: Partial<NextOfKin> = {}) {
    return this.fb.group({
      nextOfKin: [details.nextOfKin || ''],
      nextOfKinPhone: [details.nextOfKinPhone || ''],
      maidenName: [details.maidenName || ''],
    });
  }

  ngOnInit(): void {
    this.employmentForm = this.createForm(this.NextOfKin);
    if (this.disabled) {
      this.employmentForm.disable()
    }
    this.listen(this.employmentForm);
  }

}
