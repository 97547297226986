import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '@console/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { WalletTxnDetailsModel } from '../services/transactions.model';
import { WalletTxnService } from '../services/transactions.service';


export interface TransactionListState {
  loading: boolean;
  count?: number;
  transactions: WalletTxnDetailsModel[];
  error?: string;
  message?: string;
}

const initialState: TransactionListState = {
  loading: false,
  transactions: [],
};

@Injectable({ providedIn: 'root' })
export class TransactionListStateService {
  state = new BehaviorSubject<TransactionListState>(initialState);

  constructor(
    private transactionSvc: WalletTxnService,
    private spinner: NgxSpinnerService
  ) { }

  find(query: object) {
    this.loading();
    this.transactionSvc
      .getWalletTxns(query)
      .pipe(first())
      .subscribe({
        next: this.updateTransactions.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateTransactions(data: PaginatedList<WalletTxnDetailsModel>) {
    this.state.next({
      loading: false,
      transactions: data.data,
      count: data.count,
      message: 'Loading Successful',
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: cleanChakaAPIError(res),
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading...',
    });
  }
}
