import { IAccountManager } from './../../../../../../../view/src/lib/account-manager/interface/IAccountManager';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { ClientUserModel } from '@console/user-managements';
import { AccountManagerService } from 'projects/view/src/lib/account-manager/service/account-manager.service';
import { finalize } from 'rxjs/operators';
import { UserKycStateService } from '@console/user-managements/sections/user-kyc-editor/user-kyc.state';
@Component({
  templateUrl: './manage-hvu.component.html',
  styleUrls: ['./manage-hvu.component.scss']
})

export class ManageHVUComponent implements OnInit {

  hvuForm: FormGroup;

  formMethods;
  loadingAccountManagers: boolean;

  accountManagers: IAccountManager[] = [];

  kycState: ClientUserModel;

  constructor(
    private dialogRef: MatDialogRef<ManageHVUComponent>,
    @Inject(MAT_DIALOG_DATA) componentData: ClientUserModel,
    private accountManagerService: AccountManagerService,
    private kyc: UserKycStateService) {
    this.kycState = componentData;
  }

  ngOnInit(): void {
    this.hvuForm = new FormGroup({
      hvu: new FormControl(this.kycState.hvu),
      accountManagerId: new FormControl('')
    });

    this.formMethods = formMethods(this.hvuForm);

    this.kycState.hvu && this.fetchAccountManagers();
  }

  isHVUChanged($event): void {
    if ($event) {
      this.fetchAccountManagers();
    }
  }

  fetchAccountManagers(): void {
    if (this.accountManagers.length > 0) {
      return;
    }

    this.loadingAccountManagers = true;

    this.accountManagerService
      .fetchAccountManagers()
      .pipe(
        finalize(() => this.loadingAccountManagers = false)
      )
      .subscribe({
        next: (response) => {
          this.accountManagers = response.data.filter(({ enabled }) => enabled);

          this.hvuForm.get('accountManagerId').setValue(this.kycState.accountManagerId || '');

        }
      });
  }


  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }

  saveHVU(): void {
    const { hvu, accountManagerId } = this.hvuForm.value;

    this.kyc.update(
      {
        hvu,
        accountManagerId: hvu ? accountManagerId : '',
        userId: this.kycState.userId
      }
    );
  }

  get showAccountManager(): boolean {
    return this.hvuForm.get('hvu').value === true;
  }

}
