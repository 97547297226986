import { AuthRoleStateService } from './../../../../../../../authentication/src/lib/auth-permission';
import { ConsoleRouterService } from './../../../../../../../shared/src/lib/services/console-router.service';
import { KYCListStateService } from './../../kyc-list-state';
import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { PageEvent } from '@angular/material/paginator';
import { KYCData } from '../../kyc.interface';

@Component({
  selector: 'app-auth-users',
  templateUrl: './auth-users.component.html',
  styleUrls: ['./auth-users.component.scss'],
})
export class AuthUsersComponent implements OnInit {

  filter: any = {
    q: '',
    pageSize: 10,
    pageNumber: 0,
    country: '',
    status: '',
  };

  constructor(
    public kycState: KYCListStateService,
    private console: ConsoleRouterService,
    private role: AuthRoleStateService,
    public modal: NgxSmartModalService
  ) { }

  onSearch(value: string): void {
    this.filter.q = value;
    this.kycState.loadAuthServer(this.filter);
  }

  editUser(userId: string): void {
    console.log(userId);
    this.console.productNavigate(['users', userId]);
  }

  paginate($event: PageEvent): void {
    this.kycState.loadAuthServer({ ...this.filter, ...$event });
  }

  mapKycDataToTable(data: KYCData[]): any[] {
    const mapped = data.map((d) => {
      return {
        ...d,
        fullName: `${d.firstName} ${d.lastName}`,
        phoneNo: d.phone,
        action: '',
      };
    });
    return mapped;
  }

  ngOnInit(): void {
    this.kycState.reset();
    this.role.hasPermission$('KYC_VIEW').subscribe((authorized) => {
      if (!authorized) {
        return this.console.productNavigate(['']);
      }
      this.kycState.loadAuthServer(this.filter);
    });
  }
}
