<div class="row">
  <div class="col-md-2 block-sm-none">
    <ul class="list-group">
      <li
        [class]="tabClass(kyc.userId)"
        (click)="selected = kyc.userId" *ngFor="let kyc of kycList">
        {{ kyc.firstName }} {{ kyc.lastName }}
      </li>
    </ul>
  </div>
  <div class="col-md-9 col-sm-12">
    <div class="row">
      <div *ngIf="selected" class="col-md-12">
        <app-kyc-verification [userId]="selected"></app-kyc-verification>
      </div>
    </div>
  </div>
</div>
