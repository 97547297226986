import {
  ChangeDetectionStrategy, Component,
  EventEmitter, Input, OnInit,
  Output
} from '@angular/core';
import { PageEvent } from '../../shared/src/lib/interface';

@Component({
  selector: 'lib-pagination',
  templateUrl: './pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit {
  /**
   * total number of items
   */
  @Input() count: number = 0;

  /**
   * amount of items perpage
   */
  @Input() pageSize: number = 10;

  @Input() currentPage: number = 1;

  @Output() paginate = new EventEmitter<PageEvent>();

  constructor() { }

  emit(pageNumber: number) {
    this.currentPage = pageNumber ? pageNumber : 0;
    this.paginate.emit({ pageNumber: this.currentPage < 1 ? 0 : this.currentPage - 1, pageSize: 10 });
  }

  get lastPage() {
    if (this.count === 0 || this.pageSize === 0) return 0;
    const addRemainderPage = ((this.count % this.pageSize) === 0) ? 0 : 1;
    return (Math.floor(this.count / this.pageSize) + addRemainderPage) - 1;
  }

  get paginatedIndexes() {
    const indexes: number[] = [];
    if (this.count === 0) return indexes;
    for (let i = 0; i < this.lastPage; i++) {
      indexes.push(i + 1);
    }
    return this.filterIndexes(indexes, this.lastPage);
  }

  private filterIndexes(indexes: number[], lastPage: number) {
    return indexes.filter((pageIndex) => {
      const isWithinRange =
        pageIndex < this.currentPage + 3 && pageIndex > this.currentPage - 3;
      const isNotBoundIndexes = pageIndex != 0 && pageIndex != lastPage;
      return isWithinRange && isNotBoundIndexes;
    });
  }

  ngOnInit(): void { }
}
