import { AutoRebalanceService } from '../services/auto-rebalance.service';
import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList, ReqSuccessResponse } from '../../../../../api/src/public-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { IAutoRebalance } from '../interface/IAutoRebalance';

export interface AutoRebalanceListState {
  loading: boolean;
  count?: number;
  autoRebalance: IAutoRebalance[];
  error?: string;
}

const initialState: AutoRebalanceListState = {
  loading: false,
  autoRebalance: [],
};

@Injectable()
export class AutoRebalanceListStateService {

  private subject$ = new BehaviorSubject<AutoRebalanceListState>(initialState);

  state$ = this.subject$.asObservable();

  private messageId: number;

  constructor(
    private autoRebalanceService: AutoRebalanceService,
    private messagesService: MessagesService
  ) { }


  get loading$(): Observable<boolean> {
    return this.subject$.pipe(map((state) => state.loading));
  }

  find(query: object): void {
    this.loading();

    this.showMessage('Fetching auto-rebalance...');

    this.autoRebalanceService
      .fetchAutoRebalance(query)
      .pipe(
        take(1),
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        )
      )
      .subscribe({
        next: this.updateRedemption.bind(this)
      });
  }


  private updateRedemption(data: PaginatedList<IAutoRebalance>): void {
    this.subject$.next({
      loading: false,
      autoRebalance: data.data,
      count: data.count
    });

    this.messagesService.update(
      {
        id: this.messageId,
        type: MessagesEnum.success,
        message: 'Successfully retrieved auto-rebalance...'
      },
      {
        timeOut: 3000
      });
  }

  public saveRebalance(dataToSubmit): Observable<ReqSuccessResponse<IAutoRebalance>> {
    return this.autoRebalanceService.saveRebalance(dataToSubmit)
      .pipe(
        // we handle error in the child component instance
        catchError(error => throwError(error)),
        map(response => {
          // We show the success message on the parent component instance and return the observable
          this.messagesService
            .open(
              MessagesEnum.success,
              `Successfully saved auto pilot.`,
              { hideAll: true }
            );

          return response;
        })
      );
  }

  private handleError(res: ChakaAPIError): void {
    const errorMessage = cleanChakaAPIError(res) || 'An Error occurred';

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false
    });

    this.messagesService.update(
      {
        id: this.messageId,
        type: MessagesEnum.danger,
        message: errorMessage
      });
  }

  reset(): void {
    this.subject$.next(initialState);
  }

  private showMessage(message: string): void {
    !!this.messageId && this.messagesService.hide(this.messageId);
    const { id } = this.messagesService.open(MessagesEnum.loading, message);
    this.messageId = id;
  }


  private loading(): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true
    });

  }
}
