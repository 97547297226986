export enum ColorScheme {
  green = '#61BCA4',
  blue = '#607CF7',
  pink = '#EA4E93',
}

export interface ChartConfig<T> {
  title: Record<keyof T, string>;
  colors: Record<keyof T, ColorScheme>;
  legend: boolean | Record<keyof T, string>;
}

export interface ChartDataKey {
  title: string;
  key: string;
}

export interface ChartData {
  title: string;
  values: number[];
}

export function objectToChartData(
  datas: Record<string, number>[],
  keys: ChartDataKey[]
): ChartData[] {

  const allowedKeys = keys.reduce((p, c) => {
    p[c.key] = c.title
    return p;
  }, {});

  const chartData: Record<string, number[]> = {};

  for (const data of datas) {
    Object.entries(data).forEach(([k, v]) => {
      if (chartData[k]) {
        chartData[k] = [...chartData[k], v];
      } else {
        chartData[k] = [v];
      }
    });
  }

  return Object.entries(chartData)
    .filter(([k, v]) => allowedKeys[k])
    .reduce((p, c) => {
      p.push({ title: allowedKeys[c[0]], values: c[1] });
      return p;
    }, []);
}
