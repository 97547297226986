<div class="row">
  <ngx-spinner [color]="'#ffffff'" [name]="withdrawalState.spinnerName" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <!-- <p class="loading"> {{ (withdrawalState.state | async).message }} </p> -->
  </ngx-spinner>
  <div class="col-12">
    <form
      [formGroup]="withdrawalForm"
      class="kt-form kt-form--group-seperator-dashed w-100">
      <div class="kt-form__section kt-form__section--first">
        <div class="row">
          <div class="col-lg-12 kt-margin-bottom-20 form-group">
            <label for="amount"> Amount </label>
            <input
              id="amount"
              class="form-control"
              placeholder="Amount(N)"
              type="number"
              formControlName="amount" />
          </div>
          <div class="col-lg-12 kt-margin-bottom-20 form-group">
            <label for="from"> From </label>
            <select value="GLOBAL" formControlName="from" class="form-control" id="from">
              <option *ngFor="let wallet of wallets" [value]="wallet.value">
                {{ wallet.label }}
              </option>
            </select>
          </div>

          <div
            *ngIf="(payout.state | async)?.account"
            class="col-lg-12 kt-margin-bottom-20">
            <p>
              User account number is
              <strong>
                {{ (payout.state | async)?.account?.bankName }} </strong>,
              <strong>
                {{ (payout.state | async)?.account?.accountNumber }} </strong>,
              <strong>
                {{ (payout.state | async)?.account?.accountName }}
              </strong>
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 kt-margin-bottom-20-mobile">
            <button
              href="javascript:;"
              class="btn btn-primary w-100"
              (click)="onSubmit()">
              Withdraw
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
