import { AdvisoryPortfolioService } from '../services/advisory-portfolio.service';
import { Injectable } from '@angular/core';
import {
  ChakaAPIError,
  cleanChakaAPIError,
  ReqSuccessResponse,
} from '../../../../../api/src/public-api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { MessagesService } from '../../../../../shared/src/lib/components/messages/messages.service';
import { MessagesEnum } from '../../../../../shared/src/lib/components/messages/enums/messages.enums';
import { Currency } from '@console/shared/_enums/currency.enum';
import { IAdvisoryPortfolio } from '../interfaces/IAdvisoryPortfolio';

export interface IAdvisoryPortfolioDetailState {
  loading: boolean;
  portfolio: IAdvisoryPortfolio;
  error?: string;
}

const initialState: IAdvisoryPortfolioDetailState = {
  loading: false,
  portfolio: null,
};

@Injectable()
export class AdvisoryPortfolioDetailStateService {
  private subject$ = new BehaviorSubject<IAdvisoryPortfolioDetailState>(
    initialState
  );

  state$ = this.subject$.asObservable();

  private messageId: number;

  constructor(
    private advisoryPortfolioService: AdvisoryPortfolioService,
    private messagesService: MessagesService
  ) {}

  get loading$(): Observable<boolean> {
    return this.subject$.pipe(map((state) => state.loading));
  }

  findById(portfolioId: string): void {
    this.loading();

    this.showMessage('Fetching portfolio...');

    this.advisoryPortfolioService
      .fetchPortfolioById(portfolioId)
      .pipe(
        take(1),
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        })
      )
      .subscribe({
        next: this.onSuccess.bind(this),
      });
  }

  findUsersPortfolio(userId: string, currencyCode: Currency): void {
    this.loading();

    this.showMessage('Fetching portfolio...');

    this.advisoryPortfolioService
      .findUsersPortfolio(userId, currencyCode)
      .pipe(
        take(1),
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        })
      )
      .subscribe({
        next: this.onSuccess.bind(this),
      });
  }

  private onSuccess(response: ReqSuccessResponse<IAdvisoryPortfolio>): void {
    this.subject$.next({
      loading: false,
      portfolio: response.data,
    });

    this.messagesService.update(
      {
        id: this.messageId,
        type: MessagesEnum.success,
        message: 'Successfully retrieved portfolio',
      },
      {
        timeOut: 3000,
      }
    );
  }

  private handleError(res: ChakaAPIError): void {
    const errorMessage =
      res.error.status === 404
        ? 'Portfolio not yet provisioned'
        : cleanChakaAPIError(res);

    this.subject$.next({
      ...this.subject$.getValue(),
      error: errorMessage,
      loading: false,
    });

    this.messagesService.update({
      id: this.messageId,
      type: MessagesEnum.danger,
      message: errorMessage,
    });
  }

  reset(): void {
    this.subject$.next(initialState);
  }

  private showMessage(message: string): void {
    !!this.messageId && this.messagesService.hide(this.messageId);
    const { id } = this.messagesService.open(MessagesEnum.loading, message);
    this.messageId = id;
  }

  private loading(): void {
    this.subject$.next({
      ...this.subject$.getValue(),
      loading: true,
    });
  }
}
