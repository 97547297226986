import { Injectable } from '@angular/core';
import { EnterpriseAPIService, PaginatedList, ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

export type CountrySearchQuery = Partial<
  Record<'q' | 'pageNumber' | 'pageSize', string | number>
>;

export interface Country {
  name: string;
  code: string;
}


export interface CountriesListState {
  loading: boolean;
  countries: Country[];
  error?: string;
  message?: string;
  page?: number;
}

const initialState: CountriesListState = {
  loading: false,
  countries: [],
};

@Injectable({ providedIn: 'root' })
export class CountriesListStateService {
  state = new BehaviorSubject<CountriesListState>(initialState);

  spinnerName = 'countries-List';

  constructor(
    private client: EnterpriseAPIService,
    private spinner: NgxSpinnerService
  ) {}

  find(query: CountrySearchQuery = {}) {
    this.loading();
    this
      .countries(query)
      .pipe(first())
      .subscribe({
        next: this.updateSectors.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateSectors({ data, pageNumber }: PaginatedList<Country>) {
    this.state.next({
      loading: false,
      countries: [...this.state.getValue().countries, ...data],
      page: pageNumber,
      message: 'Loading Successful',
    });
    this.spinner.hide(this.spinnerName);
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: cleanChakaAPIError(res),
    });
    this.spinner.hide(this.spinnerName);
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading...',
    });
    this.spinner.show(this.spinnerName);
  }

  private  countries(query: CountrySearchQuery = {}) {
    return this.client.get<PaginatedList<Country>>(
      '/api/v1/language/countries',
      query
    );
  }
}
