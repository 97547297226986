<section-search-header
  [section]="'Application Settings'"
  (onSearch)="search($event)"
  [count]="count"
  [loading]="loading"
  (actionBtn)="createSetting()"
  [actionText]="'Create Setting'"
></section-search-header>

<div>
  <router-outlet
    (activate)="childRoute = true"
    (deactivate)="childRoute = false; search()"
  ></router-outlet>
</div>

<div class="d-flex flex-column-fluid" *ngIf="!childRoute">
  <!--begin::Container-->
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-xl-3 col-lg-4 col-md-6 mb-5"
        style="word-break: break-word"
        *ngFor="let _setting of settings"
      >
        <!--begin::Iconbox-->
        <div class="card card-custom mb-8 mb-lg-0">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ _setting?.name }}</h3>
            </div>

            <div class="card-toolbar">
              <a
                disabled
                href="javascript:;"
                class="btn btn-sm btn-icon btn-circle"
                [ngClass]="_setting?.id ? 'btn-light-primary' : ''"
                data-toggle="tooltip"
                title="Edit"
                data-placement="bottom"
                (click)="viewSetting(_setting?.id)"
              >
                <i class="fas fa-pen"></i>
              </a>
            </div>
          </div>

          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column text-dark-75">
                <div>
                  <strong>Setting Group:</strong>
                  <p>{{ _setting?.settingsGroup }}</p>
                </div>

                <div>
                  <strong>Value:</strong>
                  <p>{{ _setting?.value }}</p>
                </div>

                <ng-container *ngIf="expandedIds.has(_setting?.id)">
                  <div>
                    <strong>Encrypted:</strong>
                    <p
                      class="font-weight-bold"
                      [NgClass]="
                        setting.encrypted ? 'text-success' : 'text-danger'
                      "
                    >
                      {{ _setting?.encrypted }}
                    </p>
                  </div>

                  <div>
                    <strong>Description:</strong>
                    <p>{{ _setting?.description }}</p>
                  </div>
                </ng-container>

                <a
                  href="javascript:;"
                  (click)="toggleExpanded(_setting?.id, $event)"
                >
                  {{ expandedIds.has(_setting?.id) ? "Collapse" : "Expand" }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--end::Iconbox-->
      </div>
      <!--end::Row-->
    </div>

    <div class="row">
      <mat-paginator
        class="mat-paginator-sticky"
        [length]="count"
        [pageSizeOptions]="[8, 32, 64, 128, 256]"
        (page)="pageEvent = $event; paginate($event)"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
