<button class="close" mat-button (click)="close()">X</button>

<h2 mat-dialog-title>CSCS Login</h2>
<form [formGroup]="cscsLoginForm" (ngSubmit)="loginCscs()">

  <mat-dialog-content>
    <div class="form-group">
      <label>User Name <span class="text-danger">*</span></label>
      <input
        type="text"
        class="form-control"
        formControlName="cscsUsername">
      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('cscsUsername')">
        <div *ngIf="formMethods.getControl('cscsUsername').hasError('required')">
          Required input.
        </div>
      </small>
    </div>

    <div class="form-group">
      <label>Password <span class="text-danger">*</span></label>

      <div class="input-group mb-3">
        <input
          [type]="showPassword ? 'text' : 'password'"
          class="form-control"
          formControlName="cscsPassword">
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="showPassword = !showPassword">
            <i class="far" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
          </button>
        </div>
      </div>

      <small
        class="form-text text-danger"
        *ngIf="formMethods.touchedAndInvalid('cscsPassword')">
        <div *ngIf="formMethods.getControl('cscsPassword').hasError('required')">
          Required input.
        </div>
      </small>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions mat-dialog-actions class="d-flex justify-content-center">
    <button type="submit" class="btn btn-primary btn-block">Login</button>
  </mat-dialog-actions>
</form>
