import { Injectable } from '@angular/core';
import {
  PaginatedList,
  ReqFailureResponse,
  ChakaAPIError, cleanChakaAPIError
} from '../../../../../../projects/api/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Collection, CollectionQuery } from './services/content.interface';
import { CollectionsService } from './services/collections.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface CollectionListState {
  loading: boolean;
  count?: number;
  collections: Collection[];
  error?: string;
  message?: string;

}

const initialState: CollectionListState = {
  loading: false,
  collections: [],
};

@Injectable({ providedIn: 'root' })
export class CollectionListStateService {
  state = new BehaviorSubject<CollectionListState>(initialState);

  constructor(private collectionSvc: CollectionsService,
    private spinner: NgxSpinnerService,) { }


  find(query: Partial<CollectionQuery>) {
    this.loading();
    this.collectionSvc
      .find(query)
      .pipe(first())
      .subscribe({
        next: this.updateCollections.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map(state => state.loading));
  }

  private updateCollections(data: PaginatedList<Collection>) {
    this.state.next({
      loading: false,
      collections: data.data,
      count: data.count,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }


  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message:"An Error occurred"
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading..."
    });
  }
}
