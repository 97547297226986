import { OpenOrdersModel } from './open-orders.interface';

/**
 * DW account summary for the user.
 */
export interface AccountSummary {
  accountNo: string;
  accountID: string;
  tradingType: string;

  /**
   * Cash break down for the user from DW.
   */
  cash: CashAccount;
  /**
   * Equity summary and amount ehld by the user
   */
  equity: EquitySummary;
  /**
   * Dw open orders, it can be empty
   */
  orders: OpenOrdersModel[];
  /**
   * transaction history of the user from DW.
   */
  transactions: UserTransactions[];
}

export interface CashAccount {
  /**
   * amount the user can use to trade
   */
  cashAvailableForTrade: number;
  /**
   * amount that the user can withdrawal at the moment
   */
  cashAvailableForWithdrawal: number;
  /**
   * total balance availabe for the user including unsettled cash
   */
  cashBalance: number;
  /**
   * tranaction cash that has ot settled from dw.
   */
  cashSettlement: CashSettlements[];
}

/**
 * Equity summary of the user account
 */
export interface EquitySummary {
  /**
   * the total worth of assets held in dollars
   */
  equityValue: number;
  /**
   * various assets held and their worth
   */
  equityPositions: UserEquityPosition[];
}

/**
 * Entry for cash that has not yet settled in dw.
 */
export class CashSettlements {
  /**
   *  time of settlement
   */
  utcTime: string;
  /**
   * cash to be settled
   */
  cash: number;
}

/**
 * Details about the assests held by the user
 */
export class UserEquityPosition {
  /**
   * symbol for the instrument
   */
  symbol: string;
  /**
   * instrument ID on dw.
   */
  instrumentID: string;
  /**
   * amount owned by the user same as availableForTradingQty
   */
  openQty: number;
  costBasis: number;
  /**
   * current market value for the instrument
   */
  marketValue: number;
  /**
   * side where the order was execupted for the user
   * B = BUY, S = SELL
   */
  side: 'B' | 'S';
  /**
   * prior close for the instrument
   */
  priorClose: number;
  /**
   * quantity of shares held by the user.
   */
  availableForTradingQty: number;
  avgPrice: number;
  mktPrice: number;
  /**
   * cash profit and loss for the user since buying
   */
  unrealizedPL: number;
  /**
   * percentage profit and loss for the user since buying
   */
  unrealizedDayPLPercent: number;
  unrealizedDayPL: number;

  netEarningsPercentage: number;
  performancePercentage: number;
}

export class UserTransactions {
  orderID: string;
  orderNo: string;
  symbol: string;
  cumQty: number;
  orderStatus: string;
  orderType: string;
  orderQty: number;
  limitPrice: number;
  stopPrice: number;
  executedPrice: number;
  side: string;
  createdWhen: string;
  updatedWhen: string;
  updatedReason: string;
  commission: number;
  commissionDesc: string;
  isoTimeRestingOrderExpires: string;
  executedWhen: string;
  realizedPL: number;
  orderCashAmt: number;
}

