import {
  WithdrawalListState,
  WithdrawalListStateService,
} from './withdrawals-state';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsoleRouterService } from '../../../../shared/src/public-api';
// import { PageEvent } from '../../../../shared/src/lib/interface';
import { FormControl, FormGroup } from '@angular/forms';
import { WalletTxnDetailsModel } from '../transactions/services/transactions.model';
import {
  fileNameFormatter,
  exportAndDownload,
} from 'projects/_shared/csv-downloader/csv-downloader';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
  takeUntil,
  map,
} from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { extractSearchParams } from '@console/shared/utils/extractSearchParams';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { MerchantsModel } from '../merchants/merchants.interface';
import {
  AuthProfileState,
  AuthProfileStateService,
} from '@console/authentication/profile-state';
import {
  MerchantListState,
  MerchantListStateService,
} from '../merchants/merchant-list/merchant-list.state';
import { MatMultiSort } from 'ngx-mat-multi-sort';

@Component({
  selector: 'app-withdrawals',
  templateUrl: './withdrawals.component.html',
  styleUrls: ['./withdrawals.component.scss'],
})
export class WithdrawalsComponent implements OnInit, OnDestroy {
  // search = new FormControl('');
  // maxDate = new Date();
  filter: Record<string, any> = {
    pageSize: 10,
  };

  approvalStatus = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Approved',
      value: true,
    },
    {
      label: 'Pending',
      value: false,
    },
  ];

  paymentOutStatus = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Successful',
      value: 'SUCCESSFUL',
    },
    {
      label: 'Pending',
      value: 'PENDING',
    },
    {
      label: 'Failed',
      value: 'FAILED',
    },
    {
      label: 'Partial Success',
      value: 'PARTIAL_SUCCESS',
    },
    {
      label: 'Fraudulent',
      value: 'SUSPICIOUS_FRAUD',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ];

  marketTypes = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Global',
      value: 'USD',
    },
    {
      label: 'Local',
      value: 'NGN',
    },
  ];

  searchForm: FormGroup;

  displayedColumns: string[] = [
    'check',
    'userFullName',
    'amount',
    'reference',
    'source',
    'approved',
    'processed',
    'dateCreated',
  ];

  private readonly defaultPageEvent = {
    pageSize: 10,
    pageIndex: 0,
  };

  pageEvent: Partial<PageEvent> = this.defaultPageEvent;

  componentDestroyed$: Subject<boolean> = new Subject();

  selectedItems = new Map();

  dataSource;

  loading = false;

  count = 0;

  @ViewChild(MatMultiSort, { static: false }) sort: MatMultiSort;

  btnLoading = {
    approve: false,
  };

  withdrawalErrorsId: { approve: number } = {
    approve: 0,
  };

  isSuperAdmin = false;

  merchants: {
    list: MerchantsModel[];
    loading: boolean;
    total: number;
    pageSize: number;
    pageNumber: number;
  } = {
    list: [],
    loading: false,
    total: 0,
    pageSize: 1000,
    pageNumber: 0,
  };

  constructor(
    private consoleRouter: ConsoleRouterService,
    private withdrawalList: WithdrawalListStateService,
    private messagesService: MessagesService,
    private authProfile: AuthProfileStateService,
    private merchantListState: MerchantListStateService
  ) {}

  ngOnInit(): void {
    this.filter.pageSize = this.pageEvent.pageSize;
    this.query(this.filter);

    this.createForm();

    this.listenOnSearchForm();

    this.listenOnState();

    this.listenOnAuthProfile();

    this.listenOnMerchants();
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      q: new FormControl(''),
      status: new FormControl(''),
      authStatus: new FormControl(''),
      merchantId: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(),
    });
  }

  private listenOnSearchForm(): void {
    this.searchForm.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((formValues) => {
          this.filter = {
            pageSize: this.filter.pageSize || this.pageEvent.pageSize,
            ...extractSearchParams(formValues),
          };

          this.handleQuery();
        })
      )
      .subscribe();
  }

  private listenOnState(): void {
    // this.loading = true;
    this.withdrawalList.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: WithdrawalListState) => {
        this.dataSource = new MatTableDataSource<WalletTxnDetailsModel>(
          state.withdrawals
        );
        this.dataSource.sort = this.sort;
        this.count = state.count;
        this.loading = state.loading;
      });
  }

  listenOnAuthProfile(): void {
    this.authProfile.state
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((state: AuthProfileState) => {
        this.isSuperAdmin = state?.authProfile?.superAdmin;

        this.isSuperAdmin
          ? this.merchantListState.find({
              pageSize: this.merchants.pageSize,
              pageNumber: this.merchants.pageNumber,
            })
          : null;
      });
  }

  listenOnMerchants(): void {
    this.merchantListState.state
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(() => this.isSuperAdmin)
      )
      .subscribe((state: MerchantListState) => {
        this.merchants.loading = state?.loading;
        this.merchants.total = state?.count;
        this.merchants.list = [...this.merchants.list, ...state?.merchants];
      });
  }

  viewWithdraws(withdrawalId: number): void {
    this.consoleRouter.productNavigate(['withdrawals', withdrawalId]);
  }

  viewUser(userId: string): void {
    this.consoleRouter.productNavigate(['users', userId]);
  }

  paginate($event: PageEvent): void {
    this.filter.pageSize = $event.pageSize;
    this.filter.pageNumber = $event.pageIndex;

    this.handleQuery();
  }

  handleQuery(): void {
    this.query(this.filter);
  }

  query($event: Record<string, any>): void {
    this.withdrawalList.find($event);
  }

  get allSelected(): boolean {
    return this.dataSource.data.every(({ id }) => this.selectedItems.has(id));
  }

  onAllChecked(checked: boolean): void {
    this.dataSource.data.forEach((withdrawal) => {
      this.updateCheckedSet(checked, withdrawal);
    });
  }

  onItemChecked(checked: boolean, withdrawal: WalletTxnDetailsModel): void {
    this.updateCheckedSet(checked, withdrawal);
  }

  updateCheckedSet(checked: boolean, withdrawal: WalletTxnDetailsModel): void {
    if (checked) {
      this.selectedItems.set(withdrawal.id, withdrawal);
    } else {
      this.selectedItems.delete(withdrawal.id);
    }
  }

  get selectedWithdrawals(): WalletTxnDetailsModel[] {
    return Array.from(this.selectedItems.values());
  }

  downloadFile(): void {
    const name = fileNameFormatter(
      'Withdrawals',
      this.filter.from,
      this.filter.to
    );

    exportAndDownload(name, this.selectedWithdrawals);
  }

  approveSelected(): void {
    try {
      const cannotProcess = this.selectedWithdrawals.some(
        ({ hasBeenProcessed }) => !!hasBeenProcessed
      );

      if (cannotProcess) {
        throw new Error(
          'You can ONLY approve withdrawals that are not yet processed.'
        );
      }

      if (this.selectedItems.size > 10) {
        throw new Error('You can only approve a maximum of 10 withdrawals');
      }

      const check = confirm(
        `Do you want to approve ${
          this.selectedItems.size === 1
            ? 'this withdrawal'
            : 'these withdrawals'
        }? `
      );

      if (check) {
        this.btnLoading.approve = true;

        const transactionIds = this.selectedWithdrawals.map(({ id }) => ({
          transactionId: id,
        }));

        this.withdrawalList.batchApprove(
          transactionIds,
          this.filter,
          (success: boolean) => {
            this.btnLoading.approve = false;

            success && this.selectedItems.clear();
          }
        );
      }
    } catch (error) {
      !!this.withdrawalErrorsId.approve &&
        this.messagesService.hide(this.withdrawalErrorsId.approve);
      const { id } = this.messagesService.open(
        MessagesEnum.danger,
        error.message
      );
      this.withdrawalErrorsId.approve = id;
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

    this.messagesService.hideAll();

    this.merchantListState.reset();
  }
}
