<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">Withdrawal List</h3>
        </div>

      </div>


      <div class="card-body">

        <!--begin: Search Form-->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <form [formGroup]="searchForm">
                <div class="row align-items-center">

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="form-group">
                      <label>Search all feeds</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="q"
                        trimmedInput>
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="form-group">
                      <label>Status</label>
                      <ng-select formControlName="status" [clearable]="false">
                        <ng-option [value]="item.value" *ngFor="let item of paymentOutStatus">{{ item.label }}</ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="form-group">
                      <label>Authorization Status</label>
                      <ng-select formControlName="authStatus" [clearable]="false">
                        <ng-option [value]="status.value" *ngFor="let status of approvalStatus">{{ status.label }}</ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div
                    class="col-md-2 my-2 my-md-0"
                    *ngIf="isSuperAdmin">
                    <div class="form-group">
                      <label>Merchant</label>
                      <ng-select
                        formControlName="merchantId"
                        [clearable]="false"
                        [virtualScroll]="true"
                        [loading]="this.merchants?.loading">
                        <ng-option value="">All</ng-option>
                        <ng-option
                          [value]="merchant?.id"
                          *ngFor="let merchant of merchants?.list">
                          {{ merchant?.name }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="form-group">
                      <label>From</label>
                      <input class="form-control" type="date" formControlName="from">
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="form-group">
                      <label>To</label>
                      <input class="form-control" type="date" formControlName="to">
                    </div>
                  </div>

                  <div class="col-md-2 my-2 my-md-0">
                    <div class="form-group">
                      <label class="d-block">&nbsp;</label>
                      <button
                        type="button"
                        class="btn btn-light-primary font-weight-bold"
                        (click)="handleQuery()">Search</button>
                    </div>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
        <!--end::Search Form-->

        <!--begin: Datatable-->

        <lib-messages></lib-messages>

        <div [hidden]="(dataSource.data && dataSource.data?.length <= 0)" class="table-responsive">

          <div class="mt-5 mb-5">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold">
                Selected {{selectedItems.size}} {{selectedItems.size===1 ?'record': 'records'}}:
                <button
                  [disabled]="selectedItems.size <= 0"
                  (click)="downloadFile()"
                  class="btn btn-primary btn-sm font-weight-bold ml-4 mr-3">
                  <i class="far fa-arrow-alt-circle-down"></i> Download
                </button>

                <button
                  [disabled]="btnLoading.approve  || this.selectedItems.size < 1"
                  (click)="approveSelected()"
                  class="btn btn-success btn-sm font-weight-bold">
                  <i class="far fa-check-circle"></i> Approve
                </button>
              </div>
            </div>
          </div>


          <div class="table-container">
            <table mat-table [dataSource]="dataSource" matMultiSort>

              <ng-container matColumnDef="check" sticky>
                <th
                  mat-header-cell
                  *matHeaderCellDef="let row">
                  <span style="width: 20px;">
                    <label class="checkbox checkbox-single checkbox-all">
                      <input
                        (change)="onAllChecked($event.target.checked)"
                        type="checkbox"
                        [checked]="allSelected">&nbsp;<span></span>
                    </label>
                  </span>
                </th>

                <td
                  mat-cell
                  *matCellDef="let row">
                  <span style="width: 20px;">
                    <label class="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        (change)="onItemChecked($event.target.checked, row)"
                        [checked]="selectedItems.has(row?.id)">&nbsp;<span></span>
                    </label>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="userFullName">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="userFullName"> User Name </th>
                <td mat-cell *matCellDef="let row">
                  <a
                    *ngIf="(!row?.userId) else noUser"
                    href="javascript:void(0)"
                    (click)="viewUser(row?.userId)">
                    {{row?.userFullName || "-"}}</a>

                  <ng-template #noUser>{{row?.userFullName || "-"}}</ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="amount"> Amount </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.amount |currency:row?.currency || "-"}}
                </td>
              </ng-container>

              <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef> Reference </th>
                <td mat-cell *matCellDef="let row">
                  <a
                    href="javascript:void(0)"
                    (click)="viewWithdraws(row?.id)">
                    {{row?.reference || "-"}}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Source </th>
                <td mat-cell *matCellDef="let row">{{row.source || "-"}}</td>
              </ng-container>

              <ng-container matColumnDef="approved">
                <th mat-header-cell *matHeaderCellDef> Approved </th>
                <td
                  mat-cell
                  *matCellDef="let row">
                  <span class="font-weight-bold"
                    [ngClass]="row?.authorizationStatus ? 'text-success': 'text-warning'">
                    {{row?.authorizationStatus ? "Yes" : "No" || "-"}}</span>

                </td>
              </ng-container>

              <ng-container matColumnDef="processed">
                <th mat-header-cell *matHeaderCellDef> Processed </th>
                <td
                  mat-cell
                  *matCellDef="let row">
                  <span class="font-weight-bold"
                    [ngClass]="row?.hasBeenProcessed ? 'text-success': 'text-warning'">
                    {{row?.hasBeenProcessed ? "Yes" : "No" || "-"}}</span>

                </td>
              </ng-container>

              <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef mat-multi-sort-header="dateCreated"> Date Created </th>
                <td mat-cell *matCellDef="let row">
                  {{row?.dateCreated |date:'medium' || "-"}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row
                *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
          </div>

          <mat-paginator
            class="mat-paginator-sticky"
            [length]="count"
            [pageSizeOptions]="[10, 50, 100, 150, 200]"
            (page)="pageEvent=$event; paginate($event)"
            showFirstLastButtons></mat-paginator>
        </div>

        <div *ngIf="!loading && dataSource.data?.length <= 0" class="no-data-container d-flex flex-column justify-content-center align-items-center">
          <img class="no-data-img" src="assets/images/empty.svg" alt="empty" />
          <p class="no-data-text">No data</p>
        </div>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::Card-->

  </div>
  <!--end::Container-->
</div>
