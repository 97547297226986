import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { MessagesService } from '../../../../../../shared/src/lib/components/messages/messages.service';
import { ISupportedDocument } from '../../interface/ISupportedDocuments';
import { SupportedDocumentsService } from '../../service/support-documents.service';

@Component({
  templateUrl: './supported-document-dialog.component.html',
  styleUrls: ['./supported-document-dialog.component.scss'],
  providers: [MessagesService, LoadingService],
})
export class SupportedDocumentDialogComponent {
  supportedDocumentForm: FormGroup;
  formMethods;

  title: string;

  documentTypes = [
    { description: '--Select--', value: '' },
    { description: 'Identity', value: 'IDENTITY' },
    { description: 'Proof of Address', value: 'PROOF_OF_ADDRESS' },
  ];

  constructor(
    private dialogRef: MatDialogRef<SupportedDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    componentData: ISupportedDocument,
    private supportedDocumentsService: SupportedDocumentsService,
    private messagesService: MessagesService
  ) {
    this.title = `${componentData?.id ? 'Update' : 'Add'} Supported Document`;

    this.supportedDocumentForm = new FormGroup({
      name: new FormControl(componentData?.name || '', Validators.required),
      documentType: new FormControl(
        componentData?.documentType || '',
        Validators.required
      ),
      description: new FormControl(
        componentData?.description || '',
        Validators.required
      ),
    });

    !!componentData?.id &&
      this.supportedDocumentForm.addControl(
        'id',
        new FormControl(componentData?.id)
      );

    this.formMethods = formMethods(this.supportedDocumentForm);
  }

  save(): void {
    if (!this.supportedDocumentForm.valid) {
      this.supportedDocumentForm.markAllAsTouched();
      return;
    }

    const { value } = this.supportedDocumentForm;
    const message = `${value.id ? 'Updating' : 'Saving'} supported document...`;

    this.messagesService.open(MessagesEnum.loading, message, { hideAll: true });

    this.supportedDocumentsService.saveSupportedDocument(value).subscribe({
      next: () => {
        this.close(true);
      },
      error: (error: ChakaAPIError) => {
        const errorMessage = cleanChakaAPIError(error);

        this.messagesService.update({
          type: MessagesEnum.danger,
          message: errorMessage,
        });
      },
    });
  }

  close(success: boolean = false): void {
    this.dialogRef.close(success);
  }
}
