<!-- <section-search-header
  [section]="'News'"
  *ngIf="childRoute"
  [actionText]="'View All News'"
  (click)="view()"
  (actionBtn)="navigate()"
  (onSearch)="search($event)"
  [count]="(newsList.state | async).count"
  [loading]="(newsList.state | async).loading"></section-search-header> -->

<section-search-header
  [section]="'News'"
  *ngIf="!childRoute"
  [actionText]="'Create News'"
  (actionBtn)="navigate()"
  (onSearch)="search($event)"
  [count]="(newsList.state | async).count"
  [loading]="(newsList.state | async).loading"></section-search-header>

<div>
  <router-outlet (activate)="childRoute = true" (deactivate)="parentNavigate()"></router-outlet>
</div>

<div class="row container-fluid chaka-route-content" *ngIf="!childRoute">
  <ngx-spinner bdColor="#4e73df66" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{(newsList.state|async)?.message}}</p>
  </ngx-spinner>
  <div class="col-12">
    <kt-content-news-grid (newsClick)="navigate($event.id)" [news]="(newsList.state | async).collections">
    </kt-content-news-grid>
    <lib-pagination (paginate)="paginate($event)" [count]="(newsList.state | async).count"></lib-pagination>
  </div>
</div>

<!-- Create collection -->

<div *ngIf="childRoute" class="card card-custom">
  <div class="card-header">
    <div class="card-title">
    </div>
    <div class="card-toolbar">
      <a (click)="view()" class="btn btn-md  btn-transparent border mr-3"><i class="fas fa-arrow-left"></i>
        Back</a>

      <a (click)="save()" class="btn btn-md  btn-success border mr-3">Save</a>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="newsForm">
      <div class="alert alert-primary"
        *ngIf="(newsState.state | async).error"
        type="warn"
        (close)="newsState.resetError()">
        {{ (newsState.state | async).error }}
      </div>

      <div class="row border-bottom py-4">
        <div class="col">
          <input type="text" class="form-control" placeholder="Title" formControlName="title">
          <small align="start" class="text-primary" style="font-size: 14px;">
            <strong>Title</strong> is required
          </small>
        </div>
        <div class="col">
          <input type="textarea" class="form-control" placeholder="category" formControlName="category">
          <small align="start" class="text-primary" style="font-size: 14px;">
            <strong>Please</strong> enter category
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="Page url" formControlName="pageUrl">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>pageUrl</strong>
          </small>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Image url" formControlName="imageUrl">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>imageUrl</strong>
          </small>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="summary" formControlName="summary">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>summary</strong>
          </small>
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Content" formControlName="content">
          <small align="start" class="text-primary" style="font-size: 14px;">Please enter
            <strong>content</strong>
          </small>
        </div>
      </div>
    </form>
  </div>

</div>
