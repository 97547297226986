import { NgModule } from '@angular/core';
import { ApiModule } from '@console/api';
import { UserCSCSService } from './cscs.service';
import { UserAssetService } from './portfolio.service';
import { UserKYCService } from './user-kyc.service';
import { UserService } from './user.service';

@NgModule({
  imports: [ApiModule],
  exports: [],
  providers: [
    UserCSCSService,
    UserAssetService,
    UserKYCService,
    UserService,
  ],
})
export class UserProvidersModule {}
