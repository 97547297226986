import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TagModel } from '../services/tags.model';
import { EnterpriseAPIService, ReqSuccessResponse, PaginatedList } from '../../../../../api/src/public-api';


const API_TAGS = 'api/v1/tags';

@Injectable()
export class TagService {
  constructor(private https: HttpClient, private http: EnterpriseAPIService) { }

  getTags(searchParams?) {
    return this.http.get<PaginatedList<TagModel>>(API_TAGS, searchParams);
  }

  getTagById(id: number) {
    const url = API_TAGS + `/${id}`;
    return this.http.get<ReqSuccessResponse<TagModel>>(url);
  }

  updateTag(tag: TagModel) {
    return this.http.put<ReqSuccessResponse<TagModel>>(API_TAGS, tag);
  }

  createTag(tag: TagModel) {
    return this.http.post<ReqSuccessResponse<TagModel>>(API_TAGS, tag);
  }

  deleteTag(id: number) {
    const url = API_TAGS + `/${id}`;
    return this.http.delete<ReqSuccessResponse<TagModel>>(url);
  }
}
