import { DashboardService } from './../dashboard.service';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { AuthUserDetails } from '@console/authentication/authentication.interface';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarComponent {
  @Input() loginProfile: AuthUserDetails;

  @Output() product = new EventEmitter<string>();

  @Input() productType: 'equity' | 'advisory' = 'equity';

  @ViewChild('logoutToggleDiv') logoutToggleDiv: ElementRef;

  constructor(public dashboardService: DashboardService) {}

  getFirstName(): string {
    try {
      const getFromEmail = () => this.loginProfile?.email.split('@')[0];

      const firstName = this.loginProfile?.firstName || getFromEmail();
      return firstName;
    } catch (error) {
      return '';
    }
  }

  getFirstLetter(): string {
    try {
      return this.getFirstName().charAt(0);
    } catch (error) {
      return '';
    }
  }

  switchProduct(product: string): void {
    this.product.emit(product);
  }

}
