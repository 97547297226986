<div [class.container]="!userId" *ngIf="advisoryPortfolio; else noAdvisoryData">
  <div [ngClass]="!userId ? 'card card-custom' : ''">
    <div class="card-header" *ngIf="!userId">
      <div class="card-toolbar w-100 justify-content-between">
        <div></div>

        <button (click)="back()" class="btn btn-transparent border">
          <i class="fas fa-arrow-left"></i> Back
        </button>
      </div>
    </div>

    <div [ngClass]="!userId ? 'card-body' : ''">
      <lib-messages></lib-messages>

      <div class="row">
        <div class="col-12">
          <div class="details-pane">
            <div class="details-pane__content">
              <span>Name:</span>
              <strong>{{ advisoryPortfolio?.name || "_" }}</strong>
            </div>

            <div class="details-pane__content">
              <span>Equity:</span>
              <strong>{{
                formatToCurrency(tradeCurrency, advisoryPortfolio?.equity)
              }}</strong>
            </div>

            <div class="details-pane__content pointer" (click)="switchFormat()">
              <span>Total Drift:</span>
              <strong>
                {{
                  showPercent
                    ? (advisoryPortfolio?.totalDrift | decimalToPercent)
                    : advisoryPortfolio?.totalDrift
                }}
              </strong>
            </div>

            <div class="details-pane__content">
              <span>Rebalance Required:</span>
              <strong>
                <i
                  [ngClass]="
                    advisoryPortfolio?.rebalanceRequired
                      ? 'fas fa-check text-success'
                      : 'far fa-window-close text-danger'
                  "
                ></i>
              </strong>
            </div>

            <div class="details-pane__content">
              <span>Last Rebalance Date:</span>
              <strong>{{
                advisoryPortfolio?.lastPortfolioRebalance | date: "medium"
              }}</strong>
            </div>

            <div class="details-pane__content">
              <span>Next Rebalance Date:</span>
              <strong>{{
                advisoryPortfolio?.nextPortfolioRebalance | date: "medium"
              }}</strong>
            </div>

            <div class="details-pane__content">
              <span>Description:</span>
              <strong>{{ advisoryPortfolio?.description }}</strong>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <!--begin::Table-->
      <div class="row">
        <div class="col-12">
          <!--begin::Table-->
          <div
            class="table-responsive"
            *ngIf="portfolioHoldings.length; else noData"
          >
            <table
              class="
                table
                table-head-custom
                table-head-bg
                table-vertical-center
                table-borderless
              "
            >
              <thead>
                <tr class="bg-gray-100 text-left">
                  <th>
                    <span class="text-dark-75">Name</span>
                  </th>

                  <th>
                    <span class="text-dark-75">Type</span>
                  </th>

                  <th (click)="switchFormat()" class="pointer">
                    <span class="text-dark-75">Target</span>
                  </th>

                  <th (click)="switchFormat()" class="pointer">
                    <span class="text-dark-75">Actual</span>
                  </th>

                  <th>
                    <span class="text-dark-75">Value</span>
                  </th>

                  <th class="text-center">
                    <span class="text-dark-75">Rebalance Required</span>
                  </th>

                  <th class="text-center">
                    <span class="text-dark-75">Action</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="let portfolioHolding of portfolioHoldings"
                  [ngClass]="
                    portfolioHolding?.id === activePortfolioId && drawer.opened
                      ? 'selected-row'
                      : ''
                  "
                >
                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{ portfolioHolding?.name }}
                    </span>
                  </td>

                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{ portfolioHolding?.type }}
                    </span>
                  </td>

                  <td (click)="switchFormat()" class="pointer">
                    <span class="text-dark-75 d-block font-size-lg">
                      {{
                        showPercent
                          ? (portfolioHolding?.target | decimalToPercent)
                          : portfolioHolding?.target
                      }}
                    </span>
                  </td>

                  <td (click)="switchFormat()" class="pointer">
                    <span class="text-dark-75 d-block font-size-lg">
                      {{
                        showPercent
                          ? (portfolioHolding?.actual | decimalToPercent)
                          : portfolioHolding?.actual
                      }}
                    </span>
                  </td>

                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      {{
                        formatToCurrency(tradeCurrency, portfolioHolding?.value)
                      }}
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-dark-75 d-block font-size-lg">
                      <i
                        [ngClass]="
                          portfolioHolding?.rebalanceRequired
                            ? 'fas fa-check text-success'
                            : 'far fa-window-close text-danger'
                        "
                      ></i>
                    </span>
                  </td>

                  <td class="text-center">
                    <a
                      href="javascript:;"
                      (click)="
                        openDrawer(portfolioHolding?.id, portfolioHolding)
                      "
                      class="btn btn-sm btn-clean btn-icon mr-2"
                    >
                      <i class="far fa-eye"></i>
                    </a>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td></td>
                  <td></td>

                  <td (click)="switchFormat()" class="pointer">
                    <span class="text-dark-75 d-block font-size-lg">
                      <strong>{{
                        showPercent
                          ? (totals?.target | decimalToPercent)
                          : totals?.target
                      }}</strong>
                    </span>
                  </td>
                  <td (click)="switchFormat()" class="pointer">
                    <span class="text-dark-75 d-block font-size-lg">
                      <strong>{{
                        showPercent
                          ? (totals?.actual | decimalToPercent)
                          : totals?.actual
                      }}</strong>
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 d-block font-size-lg">
                      <strong>{{
                        formatToCurrency(tradeCurrency, totals.value)
                      }}</strong>
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!--end::Table-->

          <ng-template #noData>
            <lib-no-data
              *ngIf="!loading"
              message="No Portfolio Holdings"
            ></lib-no-data>
          </ng-template>
        </div>
      </div>
      <!--end::Table-->
    </div>
  </div>
</div>

<ng-template #noAdvisoryData>
  <lib-no-data
    *ngIf="errorMessage && !loading"
    [message]="errorMessage"
  ></lib-no-data>
</ng-template>

<mat-drawer #drawer mode="over">
  <portfolio-holding-drawer
    [drawer]="drawer"
    [activePortfolioHolding]="activePortfolioHolding"
    [tradeCurrency]="tradeCurrency"
  ></portfolio-holding-drawer>
</mat-drawer>
