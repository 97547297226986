import { Injectable } from '@angular/core';
import { EnterpriseAuthService } from '@console/api';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import {
  PaginatedList, ReqSuccessResponse
} from '../../../../../api/src/lib/api.interface';
import { EnterpriseAPIService } from '../../../../../api/src/lib/enterprise.service';
import { UserKycStatus } from '../../../../../user-managements/src/lib/models/user.model';
import { KYCData, KYCListQueryParam } from './kyc.interface';

@Injectable({ providedIn: 'root' })
export class UsersKycService {
  constructor(
    private client: EnterpriseAPIService,
    private auth: EnterpriseAuthService,
    private loadingService: LoadingService
  ) { }

  KycLists(searchParams: Partial<KYCListQueryParam> = {}) {
    const kycList$ = this.client.get<PaginatedList<KYCData[]>>(
      'api/v1/kyc/details',
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(kycList$);
  }

  updateKycStatus(kyc: UserKycStatus) {
    return this.client.put<ReqSuccessResponse<string>>(
      `/api/v1/kyc/user/status`,
      kyc
    );
  }

  authUsers(searchParams: Partial<KYCListQueryParam> = {}) {
    const authUsers$ = this.auth.get<PaginatedList<KYCData[]>>(
      'api/v1/users/client-list',
      searchParams
    );

    return this.loadingService.showLoaderUntilCompleted(authUsers$);
  }

}
