<div class="container-fluid container-fluid-margin chaka-route-content">

  <div class="card">
    <div class="card-header bg-white">
      <span class="card-header-title-text">Bank {{  (bankState.state | async)?.bank?.id }}
      </span>
      <span class="float-right">
        <span>
          <a [routerLink]="['../']" class="btn btn-sm  btn-transparent border mr-3"><i class="fas fa-arrow-left"></i> Back</a>
        </span>
        <span
        *ngIf="
            (bankState.state | async).bank &&
            (bankState.state | async).bank.id
          ">
          <a (click)="delete()" class="btn btn-sm  btn-danger text-light border-0 mr-3"><i class="fas fa-trash-alt"></i> Delete</a>
        </span>
        <span
        *ngIf="
            (bankState.state | async).bank &&
            (bankState.state | async).bank.id
            && isEditModeDisabled
            ">
          <a (click)="toggleEditMode()" class="btn btn-sm  btn-primary text-light border-0 mr-3"><i class="fas fa-trash-alt"></i> Edit</a>
        </span>
         <span >
          <a 	*ngIf="!isEditModeDisabled"
          href="javascript:;"
          class="btn btn-success kt-margin-r-10"
          color="primary"
          (click)="update(bankForm.value)"
          matTooltip="Save & Continue">Save</a>
        </span>
      </span>
    </div>
    <div class="card-body">
      <form [formGroup]="bankForm">
        <div class="alert alert-primary"
        *ngIf="(bankState.state | async).error"
        type="warn"
        (close)="bankState.resetError()"
      >
      {{ (bankState.state | async).error }}
      </div>

        <div class="row border-bottom py-4">
          <div class="col-12">
            <input type="text" class="form-control" placeholder="Name" formControlName="name">
            <small align="start" class="text-primary" style="font-size: 14px;">
              <strong>Name</strong> is required
            </small>
          </div>
          <div class="col-12">
            <input type="textarea" class="form-control" placeholder="Bank Code"  formControlName="code">
            <small align="start" class="text-primary" style="font-size: 14px;">
              <strong>Please</strong> enter bank code
            </small>
          </div>
          <div class="col-12">
            <input type="text" class="form-control" placeholder="Country Code"  formControlName="countryCode">
            <small align="start" class="text-primary" style="font-size: 14px;"
                    >Please enter
                    <strong>country code</strong>
                  </small>
          </div>
        </div>
      </form>
    </div>

  </div>

</div>
