import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EnterpriseAPIService,
  PaginatedList,
  ReqSuccessResponse,
} from '../../../../../../../projects/api/src/public-api';
import { NewsContent, NewsContentDetails } from './news.model';

const API_NEWS = 'api/v1/news';

@Injectable()
export class NewsService {
  constructor(private http: EnterpriseAPIService) {}

  getNewsContent(searchParams?) {
    return this.http.get<PaginatedList<NewsContent>>(API_NEWS, searchParams);
  }

  getNewsContentById(id: number): Observable<ReqSuccessResponse<NewsContent>> {
    const url = API_NEWS + `/${id}`;
    return this.http.get<ReqSuccessResponse<NewsContent>>(url);
  }

  updateNewsContent(
    content: NewsContent
  ): Observable<ReqSuccessResponse<NewsContent>> {
    return this.http.put<ReqSuccessResponse<NewsContent>>(API_NEWS, content);
  }

  createNewsContent(
    content: NewsContentDetails
  ): Observable<ReqSuccessResponse<NewsContent>> {
    return this.http.post<ReqSuccessResponse<NewsContent>>(API_NEWS, content);
  }

  deleteNewsContent(id: number): Observable<ReqSuccessResponse<NewsContent>> {
    const url = API_NEWS + `/${id}`;
    return this.http.delete<ReqSuccessResponse<NewsContent>>(url);
  }
}
