<div #userLogoutDiv id="kt_quick_user" class="offcanvas offcanvas-right p-10">
  <!--begin::Header-->
  <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5" kt-hidden-height="40">
    <h3 class="font-weight-bold m-0">User Profile</h3>
    <a
      href="javascript:;"
      (click)="dashboardService.hideLogoutDiv()"
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      id="kt_quick_user_close">
      <i class="fas fa-times icon-xs text-muted"></i>
    </a>

  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div
    class="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y"
    style="height: 385px; overflow: hidden;">
    <!--begin::Header-->
    <div class="d-flex align-items-center mt-5">

      <!-- {{loginProfile | json}} -->
      <!-- <div class="symbol symbol-100 mr-5">
        <div class="symbol-label" [style]="'background-image:url('+loginProfile?.photoIdUrl+')'"></div>
        <i class="symbol-badge bg-success"></i>
      </div> -->

      <div class="d-flex flex-column">
        <a
          href="javascript:;"
          class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
          {{ getFullName() | titlecase }}</a>

        <div class="text-muted mt-1">{{ getRole() | titlecase }}</div>

        <div class="navi mt-2">
          <a
            (mouseenter)="resetToolTipText()"
            href="javascript:;"
            (click)="copyEmail(); tooltip.show()"
            class="navi-item"
            #tooltip="matTooltip"
            [matTooltip]="tooltipText"
            matTooltipPosition="after">
            <span class="navi-link p-0 pb-2">
              <span class="navi-text text-muted text-hover-primary">{{ getEmail() | lowercase }} </span>

              <span class="navi-icon ml-2">
                <i class="far fa-copy"></i>
              </span>
            </span>
          </a>

          <a
            href="javascript:;"
            (click)="logOut.emit()"
            class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</a>
        </div>
      </div>
    </div>
  </div>
  <!--end::Content-->
</div>


<div *ngIf="showOverlay" class="offcanvas-overlay"></div>
