import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse, ChakaAPIError, cleanChakaAPIError, PaginatedList, ReqSuccessResponse } from '@console/api';
import { EnterpriseAPIService } from '@console/api/enterprise.service';
import { LoadingService } from '@console/shared/components/loading/loading.service';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { MessagesService } from '@console/shared/components/messages/messages.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { EmailSetting } from './settings.interface';

@Injectable({
  providedIn: 'root'
})
export class EmailSettingsService {

  private messageIds = {
    retrieve: 0,
    delete: 0
  };

  REJECTION_REASONS_API = '/api/v1/document_rejection_reasons';

  SEND_REJECTION_EMAIL_API = '/api/v1/document_rejection_reasons/send_rejected_email';

  constructor(
    private http: EnterpriseAPIService,
    private messagesService: MessagesService,
    private loadingService: LoadingService) { }

  public getRejectionEmails(searchQuery?: object): Observable<PaginatedList<any>> {
    const rejectionEmails$ = this.http.get<PaginatedList<any>>(this.REJECTION_REASONS_API, searchQuery);

    return this.loadingService
      .showLoaderUntilCompleted(rejectionEmails$)
      .pipe(
        catchError(
          (error: ChakaAPIError) => {
            this.handleError(error);
            return throwError(error);
          }
        ));
  }

  createRejectionReason(content: Partial<any>) {
    const createRejectionReason$ = this.http.post<APIResponse<any>>(
      this.REJECTION_REASONS_API,
      content,
      {},
      true
    );

    return this.loadingService.showLoaderUntilCompleted(createRejectionReason$);
  }


  public saveRejectionReason(rejectionReason: Partial<EmailSetting>): Observable<ReqSuccessResponse<EmailSetting>> {
    const isUpdate = !!rejectionReason.id;

    const saveRejectionReason$ = isUpdate ?
      this.http.put<ReqSuccessResponse<EmailSetting>>(`${this.REJECTION_REASONS_API}/`, rejectionReason, {},
      true) :
      this.http.post<ReqSuccessResponse<EmailSetting>>(`${this.REJECTION_REASONS_API}/`, rejectionReason, {},
      true);

    return saveRejectionReason$
      .pipe(
        // we handle error in the child component instance
        catchError(error => throwError(error)),
        map(response => {
          // We show the success message on the parent component instance and return the observable
          this.messagesService
            .open(
              MessagesEnum.success,
              `Successfully ${isUpdate ? 'updated' : 'added'} rejection reason.`,
              { hideAll: true }
            );

          return response;
        })
      );
  }

  public sendRejectionEmail(rejectionReason: Partial<any>): Observable<ReqSuccessResponse<any>> {
    const sendRejectionEmail$ = this.http.post<ReqSuccessResponse<any>>(
      `${this.SEND_REJECTION_EMAIL_API}/`,
      rejectionReason,
      {},
      true
    );

    return sendRejectionEmail$
      .pipe(
        // we handle error in the child component instance
        catchError(error => throwError(error)),
        map(response => {
          // We show the success message on the parent component instance and return the observable
          this.messagesService
            .open(
              MessagesEnum.success,
              `Successfully sent rejection email.`,
              { hideAll: true }
            );

          return response;
        })
      );
  }

  public deleteRejectionReason(reasonId: number): Observable<ReqSuccessResponse<boolean>> {
    !!this.messageIds.delete && this.messagesService.hide(this.messageIds.delete);

    const { id } = this.messagesService.open(MessagesEnum.loading, 'Deleting rejection reason...');

    const deleteReason$ = this.http.delete<ReqSuccessResponse<boolean>>(`${this.REJECTION_REASONS_API}/${reasonId}`);

    return deleteReason$
      .pipe(
        catchError((error: ChakaAPIError) => {
          this.handleError(error);
          return throwError(error);
        }),
        map((response: ReqSuccessResponse<boolean>) => {
          this.messagesService
            .update({
              type: MessagesEnum.success,
              message: 'Rejection reason deleted successfully.'
            });

          return response;
        }),
        finalize(() => this.messagesService.hide(id))
      );
  }


  // retrieve(id: number) {
  //   const channel$ = this.http.get<APIResponse<EmailSetting>>(
  //     this.REJECTION_REASONS_API + `/${id}`
  //   );

  //   return this.loadingService.showLoaderUntilCompleted(channel$);
  // }

  // update(content: Partial<EmailSetting>) {
  //   const updatedChannel$ = this.http.put<APIResponse<EmailSetting>>(
  //     this.REJECTION_REASONS_API,
  //     content
  //   );

  //   return this.loadingService.showLoaderUntilCompleted(updatedChannel$);
  // }

  // remove(id: number) {
  //   return this.http.delete<APIResponse<EmailSetting>>(
  //     this.REJECTION_REASONS_API + `/${id}`
  //   );
  // }

  private handleError(error: ChakaAPIError): void {
    const message = cleanChakaAPIError(error);

    this.messagesService.open(MessagesEnum.danger, message);
  }
}





  // public saveReferralPolicy(accountInfo: Partial<IReferralPolicy>): Observable<ReqSuccessResponse<IReferralPolicy>> {
  //   const isUpdate = !!accountInfo.id;

  //   const saveAccount$ = isUpdate ?
  //     this.http.put<ReqSuccessResponse<IReferralPolicy>>(`${REFERRAL_POLICY_URL}/`, accountInfo) :
  //     this.http.post<ReqSuccessResponse<IReferralPolicy>>(`${REFERRAL_POLICY_URL}/`, accountInfo);

  //   return saveAccount$
  //     .pipe(
  //       // we handle error in the child component instance
  //       catchError(error => throwError(error)),
  //       map(response => {
  //         // We show the success message on the parent component instance and return the observable
  //         this.messagesService
  //           .open(
  //             MessagesEnum.success,
  //             `Successfully ${isUpdate ? 'updated' : 'added'} referral policy.`,
  //             { hideAll: true }
  //           );

  //         return response;
  //       })
  //     );
  // }