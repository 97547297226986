<section-search-header
  [section]="'Instrument'"
  (onSearch)="onSearch($event)"
  [count]="(instrument.state | async).count"
  [loading]="(instrument.state | async).loading"
  [actionText]="'Add/Requery'"
  (actionBtn)="openRequeryModal()"
></section-search-header>

<div>
  <router-outlet
    (activate)="childRoute = true"
    (deactivate)="childRoute = false"
  ></router-outlet>
</div>

<ngx-spinner
  [name]="singleInstrument.spinnerName"
  bdColor="#4e73df66"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen]="true"
>
  <p style="font-size: 20px; color: white">
    {{ (singleInstrument.state | async)?.message }}
  </p>
</ngx-spinner>

<div class="container-fluid chaka-route-content" *ngIf="!childRoute">
  <kt-settings-instrument-list
    (view)="navigateToInstrument($event.symbol)"
    [instruments]="(instrument.state | async).Instruments"
  >
  </kt-settings-instrument-list>

  <div class="" *ngIf="(instrument.state | async).Instruments.length < 1">
    No records found
  </div>
  <lib-pagination
    [pageSize]="query.pageSize"
    [currentPage]="(instrument.state | async).page"
    (paginate)="paginate($event)"
    [count]="(instrument.state | async).count"
  ></lib-pagination>
</div>

<ngx-smart-modal
[identifier]="requeryModalName"
#kycActionModal
identifier="kycActionModal"
ariaLabel="kycActionModal"
>
<app-requery-instrument></app-requery-instrument>
</ngx-smart-modal>

