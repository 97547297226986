import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { debounceCall } from '../utils/debounceCall';
import { removeNumberPrefix } from '../utils/removeNumberPrefix';

@Directive({
  selector: '[appCleanPhoneNumber]',
})
export class CleanPhoneNumberDirective {
  @Input() appCleanPhoneNumber = '';

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  debounceSearchUser = debounceCall(function (event) {
    let value = this.elRef.nativeElement.value;

    if (!value || !this.appCleanPhoneNumber) {
      return;
    }

    value = removeNumberPrefix(value, this.appCleanPhoneNumber.toString());

    this.renderer.setProperty(this.elRef.nativeElement, 'value', value);
  }, 500);

  @HostListener('blur')
  @HostListener('paste')
  @HostListener('input')
  handleEvent(): void {
    this.debounceSearchUser();
  }
}
