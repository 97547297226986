<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-fluid">

    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Setup Suitability Questions</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Add, edit and update suitability questions</span>
        </h3>
        <div class="card-toolbar">
          <button href="javascript:;" mat-button (click)="openDrawer()" class="btn btn-primary font-weight-bolder font-size-sm">
            <span class="svg-icon svg-icon-md svg-icon-white">

              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
                  <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" />
                </g>
              </svg>

            </span>Add Question</button>
        </div>
      </div>

      <div class="card-body  pt-0 pb-3">
        <lib-messages></lib-messages>

        <div class="table-responsive">
          <table class="table table-head-custom table-head-bg table-vertical-center table-borderless">
            <thead>
              <tr class="bg-gray-100 text-left">
                <th>
                  <span class="text-dark-75">Category</span>
                </th>
                <th>
                  <span class="text-dark-75">Question</span>
                </th>
                <th>
                  <span class="text-dark-75">Subject Name</span>
                </th>
                <th style="width: 100px;" class="text-center">
                  <span class="text-dark-75">Action</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let suitabilityQuestion of suitabilityQuestions">
                <td>
                  {{suitabilityQuestion?.category }}
                </td>
                <td>
                  {{suitabilityQuestion?.question }}
                </td>
                <td>
                  {{suitabilityQuestion?.subjectName }}
                </td>
                <td class="text-center">
                  <a href="javascript:;"
                    (click)="openDrawer(suitabilityQuestion)"
                    class="btn btn-sm btn-clean btn-icon mr-2">
                    <i class="far fa-edit"></i>
                  </a>

                  <a href="javascript:;" (click)="deleteSuitabilityQuestion(suitabilityQuestion?.id)" class="btn btn-sm btn-clean btn-icon">
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- drawer -->
<mat-drawer #drawer mode="over">
  <question-drawer
    [drawer]="drawer"
    [categories]="categories"
    [activeQuestion]="activeQuestion"
    (saveSuccess)="onSaveSuccess()"></question-drawer>
</mat-drawer>
