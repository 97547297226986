export const employmentStatus = [
  {
    name: 'Employed',
    value: 'EMPLOYED',
  },
  {
    name: 'Unemployed',
    value: 'UNEMPLOYED',
  },
  {
    name: 'Self-Employed',
    value: 'SELF_EMPLOYED',
  },
];

export const investmentExperiences = [
  'YRS_1_2',
  'YRS_3_5',
  'YRS_5_10',
  'YRS_10_',
];

export const investmentObjectives = [
  'LONG_TERM',
  'INFREQUENT',
  'FREQUENT',
  'ACTIVE_DAILY',
  'NEW',
];

export const riskTolerances = ['LOW', 'MODERATE', 'SPECULATION', 'HIGH'];

export const employedByBroker = [
  {
    name: 'Yes',
    value: 'true',
  },
  {
    name: 'No',
    value: 'false',
  },
];

export const directors = [
  {
    name: 'Yes',
    value: true,
  },
  {
    name: 'No',
    value: false,
  },
];

export const employmentTypes = [
  {
    value: 'AGRICULTURE',
    name: 'Agriculture, Forestry, Fishing and Hunting',
  },
  {
    value: 'MINING',
    name: 'Mining, Quarrying, and Oil and Gas Extraction',
  },
  {
    value: 'UTILITIES',
    name: 'Utilities',
  },
  {
    value: 'CONSTRUCTION',
    name: 'Construction',
  },
  {
    value: 'MANUFACTURING',
    name: 'Manufacturing',
  },
  {
    value: 'WHOLESALE',
    name: 'Wholesale Trade',
  },
  {
    value: 'RETAIL',
    name: 'Retail Trade',
  },
  {
    value: 'TRANSPORT',
    name: 'Transportation and Warehousing',
  },
  {
    value: 'INFORMATION',
    name: 'Information',
  },
  {
    value: 'FINANCE',
    name: 'Finance and Insurance',
  },
  {
    value: 'REAL_ESTATE',
    name: 'Real Estate and Rental and Leasing',
  },
  {
    value: 'PROFESSIONAL',
    name: 'Professional, Scientific, and Technical Services',
  },
  {
    value: 'MANAGEMENT',
    name: 'Management of Companies and Enterprises',
  },
  {
    value: 'EDUCATION',
    name: 'Educational Services',
  },
  {
    value: 'HEALTH',
    name: 'Health Care and Social Assistance',
  },
  {
    value: 'ART',
    name: 'Arts, Entertainment, and Recreation',
  },
  {
    value: 'FOOD',
    name: 'Accommodation and Food Services',
  },
  {
    value: 'PUBLIC',
    name: 'Public Administration',
  },
  {
    value: 'WASTE',
    name:
      'Administrative and Support and Waste Management and Remediation Services',
  },
];

export const employmentPositions = [
  {
    value: 'ACCOUNTANT',
    name: 'Accountant/CPA/Bookkeeper/Controller',
  },
  {
    value: 'ACTUARY',
    name: 'Actuary',
  },
  {
    value: 'ADJUSTER',
    name: 'Adjuster',
  },
  {
    value: 'ADMINISTRATOR',
    name: 'Administrator',
  },
  {
    value: 'ADVERTISER',
    name: 'Advertiser/Marketer/PR Professional',
  },
  {
    value: 'AGENT',
    name: 'Agent',
  },
  {
    value: 'ATC',
    name: 'Air Traffic Controller',
  },
  {
    value: 'AMBASSADOR',
    name: 'Ambassador/Consulate Professional',
  },
  {
    value: 'ANALYST',
    name: 'Analyst',
  },
  {
    value: 'APPRAISER',
    name: 'Appraiser',
  },
  {
    value: 'ARCHITECT',
    name: 'Architect/Designer',
  },
  {
    value: 'ARTIST',
    name: 'Artist/Performer/Actor/Dancer',
  },
  {
    value: 'ASSISTANT',
    name: 'Assistant',
  },
  {
    value: 'ATHLETE',
    name: 'Athlete',
  },
  {
    value: 'ATTENDANT',
    name: 'Attendant',
  },
  {
    value: 'ATTORNEY',
    name: 'Attorney/Judge/Legal Professional',
  },
  {
    value: 'AUCTIONEER',
    name: 'Auctioneer',
  },
  {
    value: 'AUDITOR',
    name: 'Auditor',
  },
  {
    value: 'BARBER',
    name: 'Barber/Beautician/Hairstylist',
  },
  {
    value: 'BROKER',
    name: 'Broker',
  },
  {
    value: 'BUSINESS_EXEC',
    name: 'Business Executive (VP, Director, etc.)',
  },
  {
    value: 'BUSINESS_OWNER',
    name: 'Business Owner',
  },
  {
    value: 'CAREGIVER',
    name: 'Caregiver',
  },
  {
    value: 'CARPENTER',
    name: 'Carpenter/Construction Worker',
  },
  {
    value: 'CASHIER',
    name: 'Cashier',
  },
  {
    value: 'CHEF',
    name: 'Chef/Cook',
  },
  {
    value: 'CHIROPRACTOR',
    name: 'Chiropractor',
  },
  {
    value: 'CIVIL',
    name: 'Civil Servant',
  },
  {
    value: 'CLERGY',
    name: 'Clergy',
  },
  {
    value: 'CLERK',
    name: 'Clerk',
  },
  {
    value: 'COMPLIANCE',
    name: 'Compliance/Regulatory Professional',
  },
  {
    value: 'CONSULTANT',
    name: 'Consultant',
  },
  {
    value: 'CONTRACTOR',
    name: 'Contractor',
  },
  {
    value: 'COUNSELOR',
    name: 'Counselor/Therapist',
  },
  {
    value: 'CUSTOMER_SERVICE',
    name: 'Customer Service Representative',
  },
  {
    value: 'DEALER',
    name: 'Dealer',
  },
  {
    value: 'DEVELOPER',
    name: 'Developer',
  },
  {
    value: 'DISTRIBUTOR',
    name: 'Distributor',
  },
  {
    value: 'DOCTOR',
    name: 'Doctor/Dentist/Veterinarian/Surgeon',
  },
  {
    value: 'DRIVER',
    name: 'Driver',
  },
  {
    value: 'ENGINEER',
    name: 'Engineer',
  },
  {
    value: 'EXAMINER',
    name: 'Examiner',
  },
  {
    value: 'EXTERMINATOR',
    name: 'Exterminator',
  },
  {
    value: 'FACTORY',
    name: 'Factory/Warehouse Worker',
  },
  {
    value: 'FARMER',
    name: 'Farmer/Rancher',
  },
  {
    value: 'FINANCIAL',
    name: 'Financial Planner',
  },
  {
    value: 'FISHERMAN',
    name: 'Fisherman',
  },
  {
    value: 'FLIGHT',
    name: 'Flight Attendant',
  },
  {
    value: 'HR',
    name: 'Human Resources Professional',
  },
  {
    value: 'IMPEX',
    name: 'Importer/Exporter',
  },
  {
    value: 'INSPECTOR',
    name: 'Inspector/Investigator',
  },
  {
    value: 'INTERN',
    name: 'Intern',
  },
  {
    value: 'INVESTMENT',
    name: 'Investment Advisor/Investment Manager',
  },
  {
    value: 'INVESTOR',
    name: 'Investor',
  },
  {
    value: 'IT',
    name: 'IT Professional/IT Associate',
  },
  {
    value: 'JANITOR',
    name: 'Janitor',
  },
  {
    value: 'JEWELER',
    name: 'Jeweler',
  },
  {
    value: 'LABORER',
    name: 'Laborer',
  },
  {
    value: 'LANDSCAPER',
    name: 'Landscaper',
  },
  {
    value: 'LENDING',
    name: 'Lending Professional',
  },
  {
    value: 'MANAGER',
    name: 'Manager',
  },
  {
    value: 'MECHANIC',
    name: 'Mechanic',
  },
  {
    value: 'MILITARY',
    name: 'Military, Officer or Associated',
  },
  {
    value: 'MORTICIAN',
    name: 'Mortician/Funeral Director',
  },
  {
    value: 'NURSE',
    name: 'Nurse',
  },
  {
    value: 'NUTRITIONIST',
    name: 'Nutritionist',
  },
  {
    value: 'OFFICE',
    name: 'Office Associate',
  },
  {
    value: 'PHARMACIST',
    name: 'Pharmacist',
  },
  {
    value: 'PHYSICAL',
    name: 'Physical Therapist',
  },
  {
    value: 'PILOT',
    name: 'Pilot',
  },
  {
    value: 'POLICE',
    name: 'Police Officer/Firefighter/Law Enforcement Professional',
  },
  {
    value: 'POLITICIAN',
    name: 'Politician',
  },
  {
    value: 'PM',
    name: 'Project Manager',
  },
  {
    value: 'REP',
    name: 'Registered Rep',
  },
  {
    value: 'RESEARCHER',
    name: 'Researcher',
  },
  {
    value: 'SAILOR',
    name: 'Sailor/Seaman',
  },
  {
    value: 'SALES',
    name: 'Salesperson',
  },
  {
    value: 'SCIENTIST',
    name: 'Scientist',
  },
  {
    value: 'SEAMSTRESS',
    name: 'Seamstress/Tailor',
  },
  {
    value: 'SECURITY',
    name: 'Security Guard',
  },
  {
    value: 'SOCIAL',
    name: 'Social Worker',
  },
  {
    value: 'TEACHER',
    name: 'Teacher/Professor',
  },
  {
    value: 'TECHNICIAN',
    name: 'Technician',
  },
  {
    value: 'TELLER',
    name: 'Teller',
  },
  {
    value: 'TRADESPERSON',
    name: 'Tradesperson/Craftsperson',
  },
  {
    value: 'TRAINER',
    name: 'Trainer/Instructor',
  },
  {
    value: 'TRANSPORTER',
    name: 'Transporter',
  },
  {
    value: 'UNDERWRITER',
    name: 'Underwriter',
  },
  {
    value: 'WRITER',
    name: 'Writer/Journalist/Editor',
  },
];
