<p>file-uploader works!</p>
<div *ngIf="editImage; else showImage">
	<div class="upload__input--box">
		<input
			class="upload__input"
			accept="image/jpeg,image/png"
			type="file"
			(input)="uploadFile($event)"
		/>
		<div class="upload--box">
			<svg
				width="26"
				height="26"
				viewBox="0 0 26 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0)">
					<path
						d="M22.3276 24.4709L12.1014 19.0564C11.8349 18.915 11.5099 18.9345 11.2581 19.1051L3.43857 24.5196C3.14607 24.7227 3.01932 25.0916 3.12657 25.4312C3.23057 25.7676 3.54582 26 3.90007 26H21.9457C22.3178 26 22.6444 25.7465 22.7338 25.3825C22.8248 25.0201 22.6558 24.6447 22.3276 24.4709Z"
						fill="#C1D1E3"
					/>
					<path
						d="M25.7496 22.9271L23.3446 12.0998C23.2861 11.8349 23.0992 11.6188 22.8474 11.5196C22.5939 11.4205 22.3095 11.4546 22.0885 11.6074L14.2674 17.0219C14.0334 17.1844 13.9017 17.4558 13.9196 17.7369C13.9359 18.0213 14.1 18.2748 14.3502 18.4064L24.5747 23.8209C24.695 23.8843 24.825 23.9168 24.955 23.9168C25.1289 23.9168 25.3027 23.8615 25.4474 23.7494C25.6992 23.5576 25.8179 23.2375 25.7496 22.9271Z"
						fill="#C1D1E3"
					/>
					<path
						d="M20.091 10.3204C19.9837 9.98075 19.6701 9.75 19.3142 9.75H11.8977C10.549 12.1924 8.55348 14.5275 7.73285 15.4407C7.27135 15.9542 6.60998 16.25 5.91935 16.25C5.22873 16.25 4.56735 15.9542 4.10585 15.4407C3.78897 15.0881 3.29823 14.5243 2.73598 13.8255L0.769725 22.6753C0.698225 23.0019 0.8331 23.3383 1.10935 23.5251C1.24585 23.6178 1.4051 23.6632 1.56272 23.6632C1.72522 23.6632 1.8861 23.6161 2.02585 23.5186L19.7757 11.2287C20.0699 11.0289 20.195 10.66 20.091 10.3204Z"
						fill="#C1D1E3"
					/>
					<path
						d="M5.91931 0C2.78306 0 0.231812 2.55125 0.231812 5.6875C0.231812 8.606 4.79481 13.7751 5.31481 14.3552C5.46918 14.5259 5.68856 14.625 5.91931 14.625C6.15006 14.625 6.36943 14.5259 6.52381 14.3552C7.04381 13.7751 11.6068 8.606 11.6068 5.6875C11.6068 2.55125 9.05556 0 5.91931 0ZM5.91931 8.125C4.57381 8.125 3.48181 7.033 3.48181 5.6875C3.48181 4.342 4.57381 3.25 5.91931 3.25C7.26481 3.25 8.35681 4.342 8.35681 5.6875C8.35681 7.033 7.26481 8.125 5.91931 8.125Z"
						fill="#9FB0C3"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="26" height="26" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
		<p class="hint">
			Please select <strong>{{ label }} </strong>
		</p>
	</div>
</div>

<ng-template #showImage>
	<div *ngIf="image">
		<img [src]="image" alt="Address Proof" class="upload__image" />
	</div>
	<div class="upload__change">
		<a
			href="javascript:;"
			(click)="changeImage(true)"
			class="link underline"
			>Change</a
		>
		<button
			*ngIf="isNewImage"
			(click)="uploadImage()"
			class="btn btn-success"
			[ngClass]="{
				'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
			}"
		>
			Upload
		</button>
	</div>
	<p class="hint">
		<strong>{{ label }} Image</strong>
	</p>
</ng-template>
