import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InstrumentModel } from '../../models/instrument.interface';

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyInformationComponent implements OnInit {

  @Input() instrument: InstrumentModel;

  constructor() { }

  get description() {
    return this.instrument?.description || '';
  }

  get CEO() {
    return 'John Doe'
  }

  get founded() {
    return '1996'
  }

  get headquarters() {
    return 'California'
  }


  ngOnInit() {
  }

}
