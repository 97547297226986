export enum WALLET_TRANSACTION_STATUS {
	ALL = "",
	INITIATED = "INITIATED",
	PROCESSING = "PROCESSING",
	PENDING = "PENDING",
	SUCCESSFUL = "SUCCESSFUL",
	CANCELLED = "CANCELLED",
	FAILED = "FAILED",
	WAITING = "WAITING",
	REJECTED = "REJECTED",
}
