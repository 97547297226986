import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ChakaAPIError, cleanChakaAPIError } from '@console/api';
import { MessagesEnum } from '@console/shared/components/messages/enums/messages.enums';
import { DateUtil } from '@console/shared/utils/dateUtil';
import { debounceCall } from '@console/shared/utils/debounceCall';
import { formMethods } from '@console/shared/utils/form-control-methods';
import { nonNegativeNumber } from '@console/shared/utils/nonNegativeNumber';
import { CURRENCY, Currency } from '@console/shared/_enums/currency.enum';
import { MandateFrequencyEnum } from '@console/shared/_enums/mandate-frequency.enum';
import { MandateNotificationTypeEnum } from '@console/shared/_enums/mandate-notification-type.enum';
import { PaymentSourceEnum } from '@console/shared/_enums/payment-source.enum';
import {
  ClientUserModel,
  UserListStateService,
} from '@console/user-managements';
import { take, tap } from 'rxjs/operators';
import { MessagesService } from '../../../../../../shared/src/lib/components/messages/messages.service';
import { ICardList } from '../../interface/ICardList';
import { IMandate } from '../../interface/IMandate';
import { MandateListStateService } from '../../state/mandate-list-state.service';

@Component({
  selector: 'lib-mandate-edit-drawer',
  templateUrl: './mandate-edit.component.html',
  styleUrls: ['./mandate-edit.component.scss'],
  providers: [MessagesService, UserListStateService],
})
export class MandateEditDrawerComponent implements OnInit, AfterViewChecked {
  constructor(
    private messagesService: MessagesService,
    public userList$: UserListStateService,
    private mandateListStateService: MandateListStateService
  ) {}

  mandateForm: FormGroup;
  formMethods;

  userControl: FormControl;

  title = 'Add Auto Rebalance';

  @Input() drawer: MatSidenav;
  @Input() activeMandate: IMandate;

  @ViewChild('scrollDiv') scrollDiv: ElementRef;

  @Output() saveSuccess = new EventEmitter();

  currencyList: { description: string; value: string }[] = [
    { description: '--Select--', value: '' },
    { description: CURRENCY.DOLLAR, value: Currency.USD },
    { description: CURRENCY.NAIRA, value: Currency.NGN },
  ];

  frequencyList: { description: string; value: string }[] = [
    { description: '--Select--', value: '' },
    { description: 'Daily', value: MandateFrequencyEnum.DAILY },
    { description: 'Weekly', value: MandateFrequencyEnum.WEEKLY },
    { description: 'Monthly', value: MandateFrequencyEnum.MONTHLY },
    { description: 'Quarterly', value: MandateFrequencyEnum.QUARTERLY },
    { description: 'Yearly', value: MandateFrequencyEnum.YEARLY },
  ];

  notificationTypeList: { description: string; value: string }[] = [
    { description: '--Select--', value: '' },
    { description: 'SMS', value: MandateNotificationTypeEnum.SMS },
    { description: 'Email', value: MandateNotificationTypeEnum.EMAIL },
    { description: 'Both', value: MandateNotificationTypeEnum.BOTH },
  ];

  paymentSourceList: { description: string; value: string }[] = [
    { description: '--Select--', value: '' },
    { description: 'Card', value: PaymentSourceEnum.CARD },
    { description: 'Bank Account', value: PaymentSourceEnum.BANK_ACCOUNT },
    { description: 'QR', value: PaymentSourceEnum.QR },
    { description: 'Transfer', value: PaymentSourceEnum.TRANSFER },
    { description: 'USSD', value: PaymentSourceEnum.USSD },
    { description: 'Broker', value: PaymentSourceEnum.BROKER },
  ];

  debounceSearchUser = debounceCall(function (event) {
    const { value } = event.target;

    if (value.length > 2) {
      this.userList$.find({ q: value, pageSize: 10 });
    }
  }, 1000);

  selectedUser: {
    id: number;
    userId: string;
    fullName: string;
    profileImageUrl: string;
    email: string;
    firstName: string;
    lastName: string;
  } = null;

  cardList: ICardList[] = [];

  @Input() userInfo: ClientUserModel;

  ngOnInit(): void {
    this.setupForm();

    this.drawer.openedChange.subscribe((isOpened) => {
      if (isOpened) {
        this.activeMandate && this.fillForms();
      } else {
        this.mandateForm.reset();
        this.selectedUser = null;
        this.userList$.reset();
        this.activeMandate = null;
        this.cardList = [];
      }
    });

    // from the user profile component
    if (this.fromUserComponent) {
      this.assignUser(this.userInfo);
    }
  }

  private setupForm(): void {
    this.mandateForm = new FormGroup({
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern(nonNegativeNumber),
      ]),
      comment: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required),
      frequency: new FormControl('', Validators.required),
      notificationType: new FormControl('', Validators.required),
      source: new FormControl('', Validators.required),
      customerCardId: new FormControl(''),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      productType: new FormControl('ADVISORY', Validators.required),
    });

    this.formMethods = formMethods(this.mandateForm);

    this.userControl = new FormControl(Validators.required);
  }

  fillForms(): void {
    this.userList$.find({ q: this.activeMandate.userId });

    this.userList$.state
      .pipe(
        take(2),
        tap((userListState$) => {
          if (userListState$.users.length === 1) {
            this.assignUser(userListState$.users[0]);
          }
        })
      )
      .subscribe();

    this.mandateForm.addControl('id', new FormControl(this.activeMandate?.id));

    this.mandateForm.patchValue({
      id: this.activeMandate.id,
      amount: this.activeMandate?.amount,
      comment: this.activeMandate?.comment,
      currency: this.activeMandate?.currency,
      frequency: this.activeMandate?.frequency,
      notificationType: this.activeMandate?.notificationType,
      source: this.activeMandate?.source,
      startDate: DateUtil.extractDate(this.activeMandate?.startDate),
      endDate: DateUtil.extractDate(this.activeMandate?.endDate),
      productType: 'ADVISORY',
    });
  }

  get fromUserComponent(): boolean {
    return this.userInfo && this.userInfo.hasOwnProperty('userId');
  }

  assignUser($event: ClientUserModel): void {
    if (!$event) {
      return;
    }

    const {
      id,
      userId,
      email,
      fullName,
      profileImageUrl,
      firstName,
      lastName,
    } = $event;

    this.selectedUser = {
      id,
      userId,
      email,
      fullName,
      profileImageUrl,
      firstName,
      lastName,
    };

    this.getUserCards(userId);

    this.userControl.reset();
  }

  getUserCards(userId: any): void {
    this.mandateListStateService.getUserCards(userId).subscribe((response) => {
      this.cardList = response.data;

      if (
        !!this.activeMandate &&
        this.activeMandate.source === PaymentSourceEnum.CARD
      ) {
        // we are in update mode
        this.mandateForm.patchValue({
          customerCardId: this.activeMandate.customerCardId,
        });
      }
    });
  }

  deleteUser(): void {
    this.selectedUser = null;
  }

  onAddUserId(): any {
    if (!this.mandateForm.get('userIds').valid) {
      this.mandateForm.get('userIds').markAllAsTouched();
      return;
    }

    const group = new FormGroup({
      userId: new FormControl('', Validators.required),
    });

    (this.mandateForm.get('userIds') as FormArray).push(group);
  }

  get showCustomerId(): boolean {
    return (
      this.mandateForm.get('source').value === PaymentSourceEnum.CARD &&
      !!this.selectedUser
    );
  }

  get customerCardIdControl(): AbstractControl {
    return this.mandateForm.get('customerCardId');
  }

  onSourceChange(paymentSource: string): void {
    if (paymentSource === PaymentSourceEnum.CARD) {
      this.customerCardIdControl.addValidators(Validators.required);

      this.userControl.markAsTouched();
    } else {
      this.customerCardIdControl.removeValidators(Validators.required);
      this.customerCardIdControl.setValue('');
    }

    this.customerCardIdControl.updateValueAndValidity();
  }

  save(): void {
    if (!this.selectedUser) {
      this.messagesService.open(MessagesEnum.danger, 'Please select a user', {
        timeOut: 3000,
        hideAll: true,
      });

      return;
    }

    if (!this.mandateForm.valid) {
      this.mandateForm.markAllAsTouched();
      return;
    }

    const dataToSubmit = {
      ...this.mandateForm.value,
      userId: this.selectedUser.userId,
    };

    if (!!dataToSubmit.startDate) {
      dataToSubmit.startDate = new Date(dataToSubmit.startDate).toISOString();
    }

    if (!!dataToSubmit.endDate) {
      dataToSubmit.endDate = new Date(dataToSubmit.endDate).toISOString();
    }

    const isUpdate = dataToSubmit.hasOwnProperty('id');

    const message = `${isUpdate ? 'Updating' : 'Saving'} mandate...`;

    this.messagesService.open(MessagesEnum.loading, message, { hideAll: true });

    this.mandateListStateService.saveMandate(dataToSubmit).subscribe({
      next: () => {
        this.closeDrawer();

        this.saveSuccess.emit();
      },
      error: (error: ChakaAPIError) => {
        const errorMessage = cleanChakaAPIError(error);

        this.messagesService.update({
          type: MessagesEnum.danger,
          message: errorMessage,
        });
      },
    });
  }

  scrollToBottom(): void {
    try {
      this.scrollDiv.nativeElement.scrollTop =
        this.scrollDiv.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  closeDrawer(): void {
    this.drawer.close();
  }
}
