import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { WalletTxnsModel } from '../services/transactions.model';
import { TransactionListStateService } from './transaction-state';

import { skip, first } from 'rxjs/operators';
import { ConsoleRouterService } from '@console/shared';
import {
  exportAndDownload,
  fileNameFormatter,
} from '../../../../../_shared/csv-downloader/csv-downloader';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  @Input() userId: string;

  filter: Record<string, any> = {
    pageSize: 10,
  };

  transactionId: number;

  constructor(
    public console: ConsoleRouterService,
    public transactionList: TransactionListStateService,
    public modal: NgxSmartModalService
  ) { }

  viewTransaction(payment: WalletTxnsModel) {
    // if (this.userId) {
    //   this.transactionId = payment.id;
    //   return this.modal.open('transactionModal');
    // }
    this.console.productNavigate(['transaction', payment.id]);
  }

  query($event: Record<string, any>) {
    if (this.userId) {
      $event.userId = this.userId;
    }
    this.transactionList.find($event);
  }

  downloadFile($event: Record<string, any>) {
    this.query({ ...$event, pageSize: 50000000 });
    this.transactionList.state.pipe(skip(1), first()).subscribe((state) => {
      const name = fileNameFormatter(
        this.userId || 'Wallet_Transactions',
        $event.from,
        $event.to
      );
      exportAndDownload(name, state.transactions);
    });
  }

  ngOnInit() {
    if (this.transactionId) {
      this.transactionList.reset();
    }
    this.query(this.filter);
  }
}
