import { Injectable } from '@angular/core';
import { LearnContent } from './services/learn.model';
import { LearnService } from './services/learn.service';
import { CollectionQuery } from './../collections/services/content.interface';
import { PaginatedList, ReqFailureResponse } from '../../../../../../projects/api/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

export interface LearnContentListState {
  loading: boolean;
  count?: number;
  collections: LearnContent[];
  error?: string;
  message?: string;

}

const initialState: LearnContentListState = {
  loading: false,
  collections: [],
};

@Injectable({ providedIn: 'root' })
export class LearnContentListStateService {
  state = new BehaviorSubject<LearnContentListState>(initialState);

  constructor(private learnSvc: LearnService,
    private spinner: NgxSpinnerService,) { }


  find(query: Partial<CollectionQuery>) {
    this.loading();
    this.learnSvc
      .getLearnContent(query)
      .pipe(first())
      .subscribe({
        next: this.updateCollections.bind(this),
        error: this.onError.bind(this),
      });
  }

  private updateCollections(data: PaginatedList<LearnContent>) {
    this.state.next({
      loading: false,
      collections: data.data,
      count: data.count,
      message:"Loading Successful"
    });
    this.spinner.hide();
  }

  private onError(error: ReqFailureResponse) {
    this.state.next({
      ...this.state.getValue(),
      error: error.message,
      loading: false,
      message:"An Error occurred"
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message:"Loading..."
    });
  }
}
