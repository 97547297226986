import { Injectable } from '@angular/core';
import { ChakaAPIError, cleanChakaAPIError, PaginatedList } from '../../../../api/src/public-api';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ReconciliationsModel } from './services/reconciliation.model';
import { ReconciliationService } from './services/reconciliations.service';


export interface ReconciliationListState {
  loading: boolean;
  count?: number;
  reconciliations: ReconciliationsModel[];
  error?: string;
  message?: string;
}

const initialState: ReconciliationListState = {
  loading: false,
  reconciliations: [],
};

@Injectable({ providedIn: 'root' })
export class ReconciliationListStateService {
  state = new BehaviorSubject<ReconciliationListState>(initialState);

  constructor(
    private reconciliationSvc: ReconciliationService,
    private spinner: NgxSpinnerService
  ) {}

  find(query: object) {
    this.loading();
    this.reconciliationSvc
      .getReconciliations(query)
      .pipe(first())
      .subscribe({
        next: this.updateReconciliations.bind(this),
        error: this.onError.bind(this),
      });
  }

  get loading$() {
    return this.state.pipe(map((state) => state.loading));
  }

  private updateReconciliations(data: PaginatedList<ReconciliationsModel>) {
    this.state.next({
      loading: false,
      reconciliations: data.data,
      count: data.count,
      message: 'Loading Successful',
    });
    this.spinner.hide();
  }

  private onError(res: ChakaAPIError) {
    this.state.next({
      ...this.state.getValue(),
      error: cleanChakaAPIError(res),
      loading: false,
      message: 'An Error occurred',
    });
    this.spinner.hide();
  }

  reset() {
    this.state.next(initialState);
  }

  private loading() {
    this.spinner.show();
    this.state.next({
      ...this.state.getValue(),
      loading: true,
      message: 'Loading...',
    });
  }
}
