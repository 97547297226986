<div class="d-flex flex-column-fluid">
  <div class="container" *ngIf="tsn$ | async as txn">
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-toolbar w-100 justify-content-between">
          <div>
            <button
              *ngIf="txn.status === statusEnum.PENDING"
              class="btn btn-danger"
              (click)="openDeleteDialog(deleteTemplate)">Delete</button>
          </div>

          <button
            (click)="back()"
            class="btn btn-transparent border">
            <i class="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="details-pane">
              <p class="details-pane__content">
                <span>Type:</span>
                <strong>{{ txn.action || "-" }}</strong>
              </p>
              <p class="details-pane__content">
                <span>Created By:</span>
                <strong><a (click)="goToUserProfile()" class="pretty-link">{{ txn.fullName || "-" }}</a></strong>
              </p>
              <p class="details-pane__content">
                <span>Source:</span>
                <strong>{{ txn.source || "-" }}</strong>
              </p>
              <p class="details-pane__content">
                <span>Reference:</span>
                <strong>{{ txn.reference || "-" }}</strong>
              </p>
              <p class="details-pane__content">
                <span>Exchange Rate:</span>
                <strong>{{ txn.exchangeRate || "-" }}</strong>
              </p>
              <p class="details-pane__content">
                <span>Date:</span>
                <strong>{{ txn.dateModified | date: "medium" }}</strong>
              </p>
              <p class="details-pane__content">
                <span>Status:</span>
                <strong [ngClass]="'text-'+getStatusColor(txn.status)">
                  <span
                    [ngClass]="'label-'+getStatusColor(txn.status)"
                    class="label label-dot mr-2"></span>
                  {{ txn.status || "-" }}
                </strong>
              </p>
              <p class="details-pane__content">
                <span>Comment:</span>
                <strong>{{ txn?.comment || "-" }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #deleteTemplate>
      <button class="close" mat-button (click)="closeDeleteDialog()">X</button>

      <h3>Confirm Delete?</h3>

      <mat-dialog-content>

        <div class="form-group mb-5">
          <lib-messages></lib-messages>
        </div>

        <div class="form-group">
          <label>Please enter reason for rejection <span class="text-danger">*</span></label>
          <textarea
            type="text"
            class="form-control"
            [formControl]="reasonControl"
            rows="3"
            wrap="hard"
            style="min-height: 76px;"></textarea>
          <small
            class="form-text text-danger"
            *ngIf="!reasonControl.valid && reasonControl.touched">
            <div *ngIf="reasonControl.hasError('required')">
              Required input.
            </div>
            <div *ngIf="reasonControl.hasError('minlength')">
              Reason must be greater than or equal to {{reasonControl?.errors?.minlength?.requiredLength }} characters.
            </div>
            <div *ngIf="reasonControl.hasError('maxlength')">
              Reason must be less than or equal to {{reasonControl?.errors?.maxlength?.requiredLength }} characters.
            </div>
          </small>
        </div>

      </mat-dialog-content>

      <mat-dialog-actions mat-dialog-actions class="d-flex justify-content-center mb-2">
        <button
          type="button"
          class="btn btn-danger btn-block"
          (click)="deleteTransaction(txn.id)"
          [disabled]="btnLoading.delete">Delete</button>
      </mat-dialog-actions>

    </ng-template>

  </div>

</div>
