import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';


interface INotificationMessages {
  id: number,
  message: string;
  title: string;
  type: string
}
@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private toastr: ToastrService) { }

  private toastMessages: INotificationMessages[] = [];

  private defaultOptions = {
    timeOut: 20000
  };

  generateUID = (() => {
    let id = 0;
    return () => ++id;
  })();

  private logToast({ id, message, title, type }: { id: number; message: string; title: string; type: string; }): void {
    this.toastMessages = [
      ...this.toastMessages,
      {
        id, message, title, type
      }
    ];
  }

  error(message: string, title?): number {
    try {
      this.toastr.error(message, title, this.defaultOptions);
      const id = this.generateUID();
      this.logToast({ id, message, title, type: 'error' });
      return id;
    } catch (error) {
      return -1;
    }
  }

  success(message: string, title?): number {
    try {
      this.toastr.success(message, title, this.defaultOptions);
      const id = this.generateUID();
      this.logToast({ id, message, title, type: 'success' });
      return id;
    } catch (error) {
      return -1;
    }
  }

  info(message: string, title?): number | false {
    try {
      const id = this.toastr.info(message, title, this.defaultOptions).toastId;
      this.logToast({ id, message, title, type: 'success' });
      return id;
    } catch (error) {
      return -1;
    }
  }

  remove(id): void {
    this.toastr.remove(id);
  }

  hideAll(): void {
    this.toastMessages
      .forEach(({ id }) => {
        this.toastr.remove(id);
      });
  }
}