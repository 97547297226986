
import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountManagerService } from './service/account-manager.service';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateAccountManagerComponent } from './components/account-manager-create/account-manager-create.component';
import { IAccountManager } from './interface/IAccountManager';
import { ConsoleRouterService } from '@console/shared';
@Component({
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.scss'],
})
export class AccountManagerComponent implements OnInit {

  accountManagers: IAccountManager[];

  componentDestroyed$: Subject<boolean> = new Subject();

  loading = false;

  constructor(
    private accountManagerService: AccountManagerService,
    private dialog: MatDialog,
    private consoleRouter: ConsoleRouterService
  ) { }

  ngOnInit(): void {
    this.fetchAccountManagers();
  }

  fetchAccountManagers(): void {
    this.loading = true;

    this.accountManagerService
      .fetchAccountManagers(true)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe({
        next: (response) => {
          this.accountManagers = response.data;
        }
      });
  }

  deleteAccountManager(accountManagerId: number): void {
    const check = confirm('Do you want to delete this account manager?');

    if (!check) {
      return;
    }

    this.accountManagerService
      .deleteAccountManager(accountManagerId)
      .subscribe({
        next: () => {
          this.fetchAccountManagers();
        }
      });
  }


  toggleAccountManager(accountManagerId: number, isEnabled: boolean): void {
    const check = confirm(`Do you want to ${isEnabled ? 'disable' : 'enable'} this account manager?`);

    if (!check) {
      return;
    }

    this.accountManagerService
      .toggleAccountManager(accountManagerId, isEnabled)
      .subscribe({
        next: () => {
          this.fetchAccountManagers();
        }
      });
  }

  viewUsers(accountManagerId: number): void {
    this.consoleRouter.productNavigate(['account-manager', accountManagerId]);
  }

  openDialog(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    dialogConfig.height = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';

    const dialogRef = this.dialog
      .open(CreateAccountManagerComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(
        (result: boolean) => {
          result && this.fetchAccountManagers();
        });
  }

}
