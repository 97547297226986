import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '../../../../shared/src/lib/interface';
import { AdvisoryFeeStateService } from './services/advisory-fee-state.service';

@Component({
  selector: 'app-advisory-fees',
  templateUrl: './advisory-fees.component.html',
})
export class AdvisoryFeesComponent implements OnInit {
  @Input() userId: string;
  createNewRate: boolean = false;
  private filter: Record<string, any> = {
    pageSize: 10,
  };
  /**
   * amount od transactions to show
   */
  pageSize = 10;

  /**
   * total amount of data being returned
   */
  count = 0;
  constructor(public advisoryState: AdvisoryFeeStateService) {}

  ngOnInit(): void {
    this.filter.pageSize = this.pageSize;
    this.query(this.filter);
  }


  handleQuery(key, value, isDate = false) {
    if (isDate) {
      value = value.toISOString();
    }
    this.filter[key] = value;
    this.query(this.filter);
  }

  query($event: Record<string, any>) {
    this.advisoryState.find(this.userId, $event);
  }

  paginate($event: PageEvent) {
    this.handleQuery('pageNumber', $event.pageNumber);
  }
}
