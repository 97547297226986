
export const investmentExperiences = [
  'YRS_1_2',
  'YRS_3_5',
  'YRS_5_10',
  'YRS_10_',
];

export const investmentObjectives = [
  'LONG_TERM',
  'INFREQUENT',
  'FREQUENT',
  'ACTIVE_DAILY',
  'NEW',
];

export const portFolioTypes = ["CONSERVATIVE", "BALANCED", "GROWTH"];


export const riskTolerances = ['LOW', 'MODERATE', 'SPECULATION', 'HIGH'];

export const incomeBrackets = [
  { label: '<500K', value: '500000' },
  { label: '500K - 5M', value: '5000000' },
  { label: '5 - 50M', value: '2500000' },
  { label: '50M +', value: '5100000' },
 ]

