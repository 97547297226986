<div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
  <!--begin::Container-->
  <div class="container-fluid d-flex align-items-center justify-content-center">
    <!--begin::Copyright-->
    <div class="text-dark order-md-1">
      <span class="text-dark-75">Copyright &copy; Chaka 2020</span>
    </div>
    <!--end::Copyright-->
  </div>
  <!--end::Container-->
</div>
