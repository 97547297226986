import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InstrumentListStateService } from 'projects/view/src/lib/instrument/instrument.state';
import { exportAndDownload, fileNameFormatter } from 'projects/_shared/csv-downloader/csv-downloader';
import { first, skip } from 'rxjs/operators';
import { formatToCurrency } from '../../../../../../view/src/lib/instrument/states/graph-utils/graph-settings';
import { UserKycStateService } from '../../user-kyc-editor/user-kyc.state';
import { OpenOrderStateService } from './user-open-order.state';
import { PortfolioStateService } from './user-portfolio.state';
import { MerchantDetailStateService } from 'src/app/dashboard/merchant-details.state';
import { portFolioPdfExport } from 'projects/_shared/printer/report-card';
import { Currency } from '@console/shared/_enums/currency.enum';
import { ConsoleRouterService } from '@console/shared';
import { PRODUCT_TYPE_ENUM } from '@console/shared/_enums';

@Component({
  selector: 'app-user-portfolio',
  templateUrl: './user-portfolio.component.html',
  styleUrls: ['./user-portfolio.component.scss']
})
export class UserPortfolioComponent implements OnInit, OnDestroy {

  sections = [
    'Overview',
    'Positions',
    'Open Orders',
    'Account Summary',
    'Instruments',
    'Global Account'
  ];

  currentTabIndex: number;

  tabList = {
    OVERVIEW: 0,
    POSITIONS: 1,
    OPEN_ORDERS: 2,
    ACCOUNT_SUMMARY: 3,
    INSTRUMENTS: 4,
    GLOBAL_ACCOUNT: 5
  };

  tradeCurrency = Currency.USD;

  tableView = false;

  symbol: string;

  @Input() userId: string;

  constructor(
    public portfolio: PortfolioStateService,
    public instruments: InstrumentListStateService,
    public openOrders: OpenOrderStateService,
    private merchant: MerchantDetailStateService,
    private kyc: UserKycStateService,
    public consoleRouterService: ConsoleRouterService
  ) {}

  switchCurrency(): void {
    this.tradeCurrency = (this.tradeCurrency === Currency.USD) ? Currency.NGN : Currency.USD;
    this.portfolio.retrieve(this.userId, this.tradeCurrency);
  }

  // toggleCurrency(currentCurrency: Currency): void {
  //   this.tradeCurrency = currentCurrency;
  //   this.portfolio.retrieve(this.userId, this.tradeCurrency);
  // }

  downloadFile(): void {
    const portfolio = this.portfolio.state.getValue()?.account;
    const merchant = this.merchant.state.getValue()?.merchant;
    const user = this.kyc.state.getValue()?.kyc;
    console.log(this.tradeCurrency)
    portFolioPdfExport({ merchant, user }, portfolio, this.tradeCurrency);
  }

  csvDownloadFile(): void {
    this.portfolio.state.pipe(first())
      .subscribe((state) => {

        const name = fileNameFormatter(
          this.userId, 'positions', new Date().toISOString()
        );

        const positions = state.account?.equity?.equityPositions || [];

        const dateExport = positions.map((p, i) => {
          return {
            No: i + 1,
            Symbol: p.symbol,
            Quantity: p.availableForTradingQty,
            'Current Value': formatToCurrency(this.tradeCurrency, p.marketValue),
            'Net Earnings': formatToCurrency('USD', p.unrealizedPL),
            'Earnings %': p.netEarningsPercentage.toFixed(2),
            'Stock Price': formatToCurrency(this.tradeCurrency, p.mktPrice),
            'Average Price': formatToCurrency(this.tradeCurrency, p.avgPrice)
          };
        });

        exportAndDownload(name, dateExport);

      });
  }

  cancel(orderId: string): void {
    this.openOrders.cancel(orderId);
    this.openOrders.state
      .pipe(skip(1), first())
      .subscribe((state) => {
        if (state.order) {
          this.openOrders.resetState();
          this.portfolio.retrieve(this.userId);
        }
      });
  }

  requery(orderId: string): void {
    this.openOrders.requery(orderId);
    this.openOrders.state
      .pipe(skip(1), first())
      .subscribe((state) => {
        if (state.order) {
          this.openOrders.resetState();
          // this.portfolio.retrieve(this.userId);
        }
      });
  }

  ngOnInit(): void {
    if (!this.userId) {
      throw new Error(`Chaka Error: UserPortfolioComponent component requires user ID input`);
    }

    this.determineFirstTab();

    this.portfolio.retrieve(this.userId, this.tradeCurrency);
    this.instruments.find({});
  }

  private determineFirstTab(): void {
    switch (this.consoleRouterService.product) {
      case PRODUCT_TYPE_ENUM.equity:
        this.currentTabIndex = this.tabList.POSITIONS;
        break;
      case PRODUCT_TYPE_ENUM.advisory:
        this.currentTabIndex = this.tabList.OVERVIEW;
        break;
      default:
        this.currentTabIndex = this.tabList.POSITIONS;
        break;
    }
  }

  setTab(index: number): void {
    this.currentTabIndex = index;
  }

  ngOnDestroy(): void {
    this.portfolio.resetState();
    this.instruments.reset();
  }

}
